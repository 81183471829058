.how-it-works {

    &__col {
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
            &:nth-last-child(-n + 2) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 80px;

            &:last-child {
                margin-bottom:0;
            }
        }
    }

    &__panels {
        padding-top: 20px;
    }

    &__panel {
        height: 100%;
        max-width: 510px;
        padding: 35px;
        margin: 0 auto;
        border-radius: 10px;
        background: $white;

        @include media-breakpoint-down(xs) {
            text-align: center;
        }

        table {
            width: 100%;
            margin-bottom: 15px;

            tr {
                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }

            td {
                padding: 10px 5px;
                line-height: 1.2;
                border-bottom: 1px solid $grey;

                @include media-breakpoint-down(xs) {
                    display: block;
                    width: 100%;
                    border-bottom: none;

                    &:first-child {
                        padding-top: 20px;
                    }

                    &:last-child {
                        padding-top: 0;
                        padding-bottom: 20px;
                        border-bottom: 1px solid $grey;
                    }
                }

                &:first-child {

                    padding-left: 0;
                    font-weight: bold;
                }

                &:last-child {
                    max-width: 65px;
                    padding-right: 0;
                    font-weight: bold;
                    color: $color-secondary;

                    @include media-breakpoint-down(xs) {
                        max-width: 100%;
                    }
                }
            }
        }
    }

}
.pagename-top-up-prize-draw {
    .how-it-works__col:last-child {
        table {
            border-top:28px solid white;
        }
    }
}
