.search-listing {
    padding: 15px 0 80px;

    &__content {
        max-width: $column-content-width;
    }

    &__found {
        display: block;
        margin-bottom: 40px;
    }


    .search-item {
        margin-bottom: 45px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__back-button {
        padding-top: 50px;
    }
}
