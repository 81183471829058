.styled-table {

  &__inner {
    position: relative;
    padding-left: 15px;
    @include media-breakpoint-down(sm) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -2px;
        right: 0;
        z-index: 3;
        width: 30px;
        height: calc(100% + 2px);
        border-right: 10px solid $color-quinary;
        background: linear-gradient(to left, rgba($color-quinary, 1), rgba($color-quinary, 0));
      }
    }
  }

  &__outer {
    position: relative;
    border-radius: 10px;
    overflow-x: hidden;


  }

  &__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0 15px 0 200px;
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
      padding: 0 15px 0 130px;
    }
  }

  table {
    width: 100%;

    border-radius: 10px;


      th,td {
      min-width: 160px;
      padding: 15px;
      text-align: center;
      vertical-align: middle;
      line-height: 1.5;
      background: $color-quinary;

      @include media-breakpoint-down(sm) {
        min-width: 130px;
        text-align: left;

        &:last-child {
          padding-right: 50px;
        }
      }

      &:first-child {
        position: absolute;
        left: 0;
        z-index: 2;
        width: calc(100% + 1px);
        max-width: 200px;
        font-weight: bold;
        text-align: left;
        @include media-breakpoint-down(sm) {
          max-width: 130px;
        }
      }

      .icon {
        display: block;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
          margin: 0;
        }
      }
    }

    thead {
      th {
        font-weight: bold;
        background: $color-primary;
        color: $white;

        &:first-child {
          top: 0;
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }

        .icon--tick {
          background: url(../images/tick-bullet.svg) center no-repeat;
          background-size: contain;
        }
        .icon--cross {
          background: url(../images/cross-white.svg) center no-repeat;
          background-size: contain;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            top: auto;
          }
        }

        &:not(.highlight-row):not(:last-child) {
          td {
            border-bottom: 1px solid $grey;
          }
        }
      }


    }

    tr {
      &.highlight-row {
        td {
          font-size: 2rem;
          font-weight: bold;
          background: $color-secondary;
          color: $white;

          .icon {
            width: 35px;
            height: 35px;
          }

          .icon--tick {
            background: url(../images/tick-bullet.svg) center no-repeat;
            background-size: contain;
          }
          .icon--cross {
            background: url(../images/cross-white.svg) center no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

}

/* cookies policy table */

#tablepress-10 {
  @include media-breakpoint-down(sm) {
    display: block;
    overflow-x: auto;
    border-radius: 0px;
    th:first-of-type {
      border-top-left-radius: 10px;
    }
    th:last-of-type {
      border-top-right-radius: 10px;
    }
  }
}
