.search-item {
    a {
        text-decoration: none;
    }

    &__heading {
        margin-bottom: 5px;
    }

    &__date {
        display: block;
        font-weight: 500;
        margin-bottom: 10px;
        color: $color-quaternary;
    }

    &__excerpt {
        p,li {
            margin-bottom: 15px;
        }
    }
}
