.suggested-posts {
    &__col {
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            &:nth-last-child(-n + 3) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
