.features {

    &__item {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 55px;

        @include media-breakpoint-down(sm) {
            display: block;
            text-align: center;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__icon, &__content {
        padding: 0 10px;
    }

    &__icon {
        flex: 0 0 120px;
        max-width: 120px;

        @include media-breakpoint-down(sm) {
            margin: 0 auto 15px;
        }

        img {
            position: relative;
            top: -35px;

            @include media-breakpoint-down(sm) {
                top: 0;
            }
        }
    }

    &__content {
        flex: 1 0;
    }

    &__heading {
        margin-bottom: 10px;
    }
}
