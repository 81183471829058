.product-select {
  $yellow-border: 1px solid $color-secondary;
  padding: 0 20px !important;

  .hidden {
    display: none;
  }

  .choose-investment {
    margin-top: 70px;
    width: 100%;
  }

  .choose-investment__title {
    margin-bottom: 70px;
    text-align: center;
  }

  .choose-product {
    align-items: center;
    border-radius: 5px;
    border: $yellow-border;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px 70px;
    text-align: center;
    width: 463px;
    cursor: pointer;
  }

  .choose-product__title {
    color: $color-primary;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
  }

  .found-choice {
    display: flex;
    gap: 15px;
    justify-content: center;
    width: 100%;
  }


    input[type=radio] {
    left: 0px;
    margin: 10px 0 !important;
    opacity: 1;
    position: relative;
  }

  .checkbox-round {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    border-radius: 50% !important;
    border: $yellow-border;
    height: 2.5em !important;
    margin-bottom: 20px;
    outline: none;
    vertical-align: middle;
    width: 2.5em;
  }

  .checkbox-round:checked {
    content: none;
    padding: 0;
  }

  .checkbox-round:checked::before {
    align-items: center;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.217' height='21.861' viewBox='0 0 22.217 21.861'%3E%3Cpath id='Path_2074' data-name='Path 2074' d='M-2463.385-6119.135l7.054,8.962,12.378-19.481' transform='translate(2464.789 6131.034)' fill='none' stroke='%2313243a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
    display: flex;
    justify-content: center;
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
  }

  #product_details,
  #product_details--active {
    margin: 1rem 0;
  }

  .important-information-section {
    margin-top: -30px;
  }

  /* Can be simplified with SASS */
  .documents {
    border-radius: 5px;
    border: 1px solid $color-quaternary;
    margin-bottom: 25px;
    opacity: 1;
    padding: 20px 40px;
  }

  .documents__header {
    color: $color-quaternary;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .documents__content {
    margin-bottom: 0;
  }

  .documents__key-info {
    margin-bottom: 15px;
  }

  /* The same classes with the main css file just updated to fit for this project */
  .list-unstyled {
    padding-top: 30px;
  }

  .error-message {
    display: none;
    position: relative;
    padding: 1rem 1rem !important;
    border-radius: 5px;
    background: #ba2b2b;
    border: 2px solid #ba2b2b;
    text-align: left;
    font-family: "Brandon Text", Arial;
    color: #ffffff;
    font-size: 1.6rem;

    p {
      margin-bottom: 0 !important;
      color: #ffffff;
    }
  }

  /* The same classes with the main css file just updated to fit for this project */
  .disabled,
  .disabled:hover {
        background: #ECEDED !important;
    color: $color-quaternary !important;
  }

  /* Class for change background on click */
  .highlight {
        background: #F7AA0033;
  }

  @media screen and (max-width: 980px) {
    .choose-product {
      align-items: stretch;
      justify-content: space-between;
    }

        input[type=radio] {
      margin: 10px auto !important;
    }
  }
  @media screen and (max-width: 768px) {
    .choose-product {
      padding: 0 10px;
    }

    .found-choice {
      align-items: stretch;
    }

    .important-information-section {
      margin-top: -10px;
    }
  }

  @media (max-width: 575.98px) {
    /* The same classes with the main css file just updated to fit for this project */

    .container {
      padding: 0 20px;
    }

    .choose-investment {
      padding: 0 0px !important;
      width: 100% !important;
      margin-top: 50px;
    }

    .choose-investment__title {
      margin-bottom: 50px;
    }

    .found-choice {
      align-items: stretch;
    }

    .choose-product {
      align-items: stretch;
      justify-content: space-between;
      padding: 0 7px !important;
      width: 50%;
    }

    .choose-product__title {
      font-size: 20px !important;
    }


        input[type=radio] {
      margin: 10px auto !important;
    }

    .choose-product p {
      font-size: 14px;
    }

    .documents {
      padding: 10px;
    }

    .download > a {
      justify-content: center;
    }

    .download__icon {
      margin-right: 0;
      width: 100%;
    }

    .list-unstyled {
      display: flex;
      text-align: center;
    }
  }
}
