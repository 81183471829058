.sticky-content-block-checkbox
{
  display:none;
}

input.sticky-content-block-checkbox[type=checkbox]:checked~.sticky-content-block
{
  display:none;
}



.sticky-content-block
{
    position: -webkit-sticky; /* Safari */
    position:sticky; 
    z-index:200;
    top:0px;
     padding-top:20px; 
     padding-bottom:20px; 

     h4
     {
        margin-bottom:0px;
     }

     label.sticky-content-block-checkbox-label
     {
        display:none;
      padding-left:40px; 
       align-self: center;
       font-size:2.4rem;
       line-height:0px;
       cursor:pointer;
     }

     &.dismissable
     {
        label.sticky-content-block-checkbox-label
        {
            display:block;
        }
     }

}