.page-header {
  &__outer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 0;
    min-height: 140px;

    @include media-breakpoint-down(sm) {
      min-height: 90px;
      padding: 20px 0;
    }
  }

  &--breadcrumbs {
    + section {
      @include media-breakpoint-down(sm) {
        padding-top: 50px;
      }
    }
  }

  &__title {
    @include media-breakpoint-down(sm) {
      font-size: 2.4rem;
    }
  }

  &__icon {
    @include media-breakpoint-down(sm) {
      max-width: 68px;
    }
  }

  .breadcrumbs {
    padding: 20px 0;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.single {
  .page-header__title {
    display: block;
    margin-bottom: 0;
  }
}
