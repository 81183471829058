.hero {
  position: relative;
  overflow-x: clip;
  z-index: 2;

  &__hide-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__heading {
    &--short {
      max-width: 400px;
    }

    @include media-breakpoint-down(sm) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__copy {
    max-width: 450px;
  }

  &--overflow-image {
    .hero__image {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;

      @include media-breakpoint-down(md) {
        right: -60px;
      }

      @include media-breakpoint-down(sm) {
        position: relative;
        right: 0;
      }
    }
  }

  .buttons {
    margin-bottom: 65px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
    }
  }

  .buttons.buttons-smaller-margin {
    margin-bottom: 25px;
  }

  .copy-under-buttons {
    margin-bottom: 45px;
  }

  .white-border-box {
    border: 3px solid $white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    p {
      margin-bottom: 0px;
    }
  }

  &__image {
    text-align: center;

    @include media-breakpoint-down(sm) {
      max-width: 260px;

      margin: 0 auto 20px;

      img {
        max-height: 250px;
      }
    }
  }

  &__awards {
    display: flex;
    margin: 0 -7px;
    font-size: 0;
  }

  &__award {
    padding: 0 7px;

    img {
      display: block;
      max-height: 100%;
    }
  }

  &__links {
    padding-top: 35px;

    text-align: left;

    ul {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 35px;
      margin: 40px -35px -50px;
      background: $color-quinary;

      .link {
        &:before {
          border: none;
          background: url(../images/arrow-hollow.svg) center no-repeat;
          background-size: contain;
        }
      }
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc($grid-gutter-width / 2 * -1);

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    > * {
      padding: 0 calc($grid-gutter-width / 2);
    }
  }

  &__review,
  &__full-review {
    position: relative;
    z-index: 10;
  }
  &__review {
    @media (max-width: 767px) {
      padding: 0;
    }
  }
}

.bounty--hero {
  .inner {
    max-width: unset;
  }

  @include media-breakpoint-down(md) {
    .row > div:first-child {
      width: 60%;
    }
  }

  @include media-breakpoint-down(sm) {
    .row {
      flex-wrap: nowrap;
      flex-direction: row-reverse;
    }

    .row > div:first-child {
      width: unset;
      padding: 5px;
    }
  }

  .hero__heading--short {
    max-width: unset;
    font-size: 4.4rem;

    @include media-breakpoint-down(md) {
      text-align: left;
    }

    @include media-breakpoint-down(sm) {
      font-size: 3rem;
    }
  }

  .hero__copy {
    max-width: 481px;

    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }

  .copy-l p {
    font-size: 2.4rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  .hero__image {
    top: -60px;

    @include media-breakpoint-down(lg) {
      top: -80px;
      right: -130px;
    }

    @include media-breakpoint-down(sm) {
      position: absolute;
      right: 0;
      top: -30px;
    }

    @include media-breakpoint-down(xs) {
      right: -60px;
      top: -30px;
    }
  }
}
