@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.member-showcase {
  &__copy {
    margin: 0 auto;
    width: 100%;
    max-width: 72rem;
    text-align: center;
    font-weight: 500;

    @media (min-width: map-get($sizes, sm)) {
      font-size: 2rem;
    }
  }

  &__row {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 7rem;
    padding-bottom: 6.3rem;
    width: 100%;
    gap: 6.5rem;

    @media (min-width: map-get($sizes, sm)) {
      flex-direction: row;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}
