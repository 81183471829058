.downloads-box {
    padding: 30px 40px;
    margin-bottom: 50px;
    border-radius: 10px;
    background: $white;

    @include media-breakpoint-down(sm) {
        padding: 30px 25px;
    }

    .downloads__list {
        padding: 20px 0 0;
        justify-content: flex-start;

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            margin: 0 -15px;
        }
    }
}
