.contact-info {

    &__col {
        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__icon {
        margin-bottom: 10px;
    }

    &__footnote {
        padding-top: 45px;
    }

    a[href^="mailto:"] {

        @include media-breakpoint-down(md) {
            word-break: break-all;
        }
    }

}
