.featured-posts {

    &__heading {
        margin-bottom: 50px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }

    &__col {
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
            &:nth-last-child(-n + 2) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

  .card {
    > .card__thumbnail {
      @include media-breakpoint-up(md) {
        height: 195px;

        img {
          height: 195px;
        }
      }

      @include media-breakpoint-up(lg) {
        height: 250px;
        max-height: 340px;

        img {
          height: 250px;
          max-height: 340px;
          object-fit: cover;
        }
      }
    }
  }
}
