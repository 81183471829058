.search-header {
    padding: 40px 0;
    background: $color-senary;


    &__title {
        margin-bottom: 0;
        max-width: 420px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }
    }

    .live-search-wrapper {
        form {

            label {
                display: block;
            }
            input {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

}
