.sub-navigation {
    padding: 40px 0;

    ul {
        margin: 0 -15px;
        font-size: 0;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    li {
        display: inline-block;
        padding: 0 15px;
        margin-bottom: 15px;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    a {
        border-color: transparent;

        &:hover, &:focus, &.active {
            color: $color-primary!important;
            border-color: $color-tertiary;
        }
    }

}
