@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-login-content {
  .container {
    @media (max-width: map-get($sizes, sm)) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }

  &__left-mobile-images {
    @media (max-width: 420px) {
      flex-direction: column;
    }
  }

  &__left {
    &-image-right {
      position: relative;
      z-index: 4;
      right: -100px;

      @media (min-width: map-get($sizes, lg)) {
        position: absolute;
        right: -50px;
        bottom: -8px;
      }

      @media (min-width: map-get($sizes, xl)) {
        right: 14px;
        bottom: 0;
      }
    }

    &-image {
      position: absolute;
      bottom: -1px;
      z-index: 3;

      @media (min-width: map-get($sizes, lg)) {
        bottom: 0;
      }
    }
  }
}
