.benefits {
  &__container {
    position: relative;
    overflow-x: clip;
    z-index: 1;
  }
}
.benefits-row {
  position: relative;
  margin-bottom: 3.6rem;
  z-index: 1;

  &__copy {
    margin: 0 auto;
    width: 100%;
    max-width: 72rem;
    font-weight: 500;
    text-align: center;
    font-size: 2rem;
  }

  &__image {
    display: none;
    @media (min-width: map-get($sizes, lg)) {
      display: block;
      position: absolute;
      bottom: -5rem;
      right: 0rem;
      z-index: 2;
      transform: translateY(50%);
    }
  }

  .benefits {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 4.7rem;

      @media (min-width: map-get($sizes, lg)) {
        width: 100%;
        max-width: 108.8rem;
        flex-direction: row;
        gap: 5.4rem;
      }
  }
}
