.tab-filter-menu {
    padding: 0px 0px 45px 0px;

    .toggle-menu {
        display: none;
    }

    @include media-breakpoint-down(md) {
        .toggle-menu {
            display: block;
            position: absolute;
            right: 0%;
            width: 60px;
            height: 48px;
            opacity: 1;
            z-index: 1;
        }
    }
    .tab-content {
        display: flex;
        width: 100%;
        p {
            font-weight: bold;
            flex-grow: 1;
            text-align: right;
        }

        p,
        .tab-link {
            margin: 0px 5%;
            color: $color-primary;
            padding: 0px;
            text-decoration: none;
        }

        .tab-link {
            padding: 5px;
            &--active {
                font-weight: bold;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    height: 3px;
                    width: 100%;
                    background-color: $color-tertiary;
                }
            }
        }

        .tab-link:not(.first-tab) {
            &:hover {
                color: $color-primary;
                text-shadow: 0px 0px 1px $color-primary;
            }
        }

        .tab-link.mobile {
            display: none;
        }

        .tabs {
            flex-grow: 2;
        }

        .first-tab {
            position: relative;
            font-weight: bold;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3px;
                background-color: $color-tertiary;
            }
        }

        @include media-breakpoint-down(md) {
            background-color: white;
            padding: 10px;
            border-radius: 5px;
            position: relative;
            height: 40px;
            .tabs {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: white;
                border-radius: 5px;
            }

            p,
            .tab-link {
                display: none;
                padding: 10px 0px;
            }

            .tab-link.desktop {
                display: none;
            }
            
            .tab-link.mobile.visible {
                display: block;
            }

            .first-tab {
                &:before {
                    content: none;
                }
            }

            .tab-link.mobile.first-tab {
                display: block;
                font-weight: bold;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url(../images/arrow-hollow.svg) center no-repeat;
                    background-size: contain;
                    pointer-events: none;
                    transform: translateY(-50%);
                    transform: rotate(90deg) translateY(0) translateX(-50%);
                }

                &.clicked {
                    &:after {
                        background: url(../images/close-button.svg) center no-repeat;
                        transform: rotate(0) translateY(-50%) translateX(0);
                    }
                }
            }
        }
    }
}

