.team {

  &__posts {
    padding-top: 40px;
  }

  &__row {
    justify-content: space-between;
  }

  &__col {
    max-width: 290px;
    margin-bottom: 45px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 0 10px;
      margin-bottom: 30px;

    }
  }

}
