.card {
    max-width: 450px;
    margin: 0 auto;

    &.card--small {
        .card__title {
            padding-top: 15px;
            line-height: 1.2;
        }

        .link-wrap {
            padding-top: 0;
        }
    }

  &__thumbnail {
    @include media-breakpoint-up(md) {
      height: auto;
      max-height: 120px;
    }

    @include media-breakpoint-up(lg) {
      max-height: 200px;
    }

    img {
      width: 100%;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        height: 120px; 
        max-height: 120px;
      }

      @include media-breakpoint-up(lg) {
        height: 145px;
        max-height: 145px;
      }

      @include media-breakpoint-up(xl) {
        height: 200px;
        max-height: 200px;
      }
    }

        &:hover {
            + .card__content, + .card__details + .card__content {
                .link {
                    color: $color-secondary;
                }
            }
        }
    }

    &__title {
        margin-bottom: 15px;
    }

    &__content {
        .link-wrap {
            padding-top: 10px;
        }

        a {
            text-decoration: none;
        }
    }

    &__details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0 10px;
        margin: 0 -5px;
        line-height: 1.4;

        > * {
            padding: 0 5px;
        }
    }


    &__categories {
        margin: 0 -5px;
        font-size: 0;

        > .btn-wrap {
            display: inline-block;
            padding: 0 5px;
            margin-bottom: 10px;
        }
    }    
}
