.sitemap-section {
    z-index: 1;
    &__container {
        position: relative;
        padding: {
            left: 0;
            right: 0;
            bottom: 3rem;
        }
      }

    &__title {
        font-weight: 400;
        border-bottom: 1px solid $grey;
        padding-bottom: 2rem;
    }

    &__title-link {
        text-decoration: none;
    }

    &__sub-title {
        font-weight: 900;
    }

    &__sub-title-link {
        text-decoration: none;
    }

    &__sub-section-links {
        list-style-type: none;
        padding-left: 0;
    }

    &__sub-section-link {
        margin-bottom: 2.5rem;
    }
}