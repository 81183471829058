/* Colours
========== */

$black: #000;
$white: #fff;

$darkgrey: #222;
$grey: #707070;
$lightgrey: #eee;

/* Brand Colours
================ */

$color-primary: #13243a;
$color-secondary: #f7aa00;
$color-tertiary: #87d0f1;
$color-tertiary-light: #e1f3fb;
$color-form: #e1f3fc;
$color-quaternary: #454e50;
$color-quinary: #fff6e5;
$color-senary: #f3fbfd;

/* Element Colours
================== */

$offwhite: #f1f5f7;

/* Misc Colours
================ */

$selection: #b3d4fc;

$fail: #ba2b2b;
$success: #4caf50;

$facebook: #3b5995;
$twitter: #1da1fa;
$linkedin: #007bb5;
$instagram: #e1306c;

/* Fonts
======== */

$font-heading: "Brandon Text", serif;
$font-body: "Brandon Text", sans-serif;

/* Transitions
============= */

$transition: 300ms;

/* Breakpoints
============== */

$site-width: 1440px;
$content-width: 920px;
$centred-width: 540px;
$wide-copy: 720px;
$column-content-width: 630px;

$br-laptop: 1350px;
$br-desktop: 1650px;
