.section-heading {
  max-width: 540px;
  margin: 0 auto 50px;

  &__inner {
    padding: 20px 5px;

    @include media-breakpoint-down(sm) {
      padding: 20px 0;
    }

    * {
      margin: 0;
    }
  }

  h4 {
    line-height: 1.5;
  }

  &--left {
    margin: 0 0 50px;

    & > div {
      padding: 0;
    }

    h2 {
      text-align: left;
    }
  }
}
