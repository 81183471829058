.copy-background {
  .container {
    position: relative;
  }

  &--fix-image-top {
    .copy-background__image {
      width: 50%;
    }
  }

  &--centre-image {
    .copy-background__image {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--centre-content {
    .copy-background__background {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 730px;
    }
  }

  &__background {
    min-height: 1000px;
    @include media-breakpoint-down(md) {
      padding-bottom: 55vw;
      min-height: 0;
      background-size: 150% auto;
      background-position: right bottom;
    }

    &:before {
      content: "";
      font-size: 0;
      min-height: inherit;
    }

    &--lg {
      @media (min-width: map-get($sizes, xxl)) {
        min-height: 1100px;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__content {
    .btn-wrap {
      padding-top: 25px;
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}
