.post-content {

    &__content {
        max-width: $column-content-width;

        img {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }

        h5 {
            color: $color-quaternary;
        }
        li.blocks-gallery-item {
            list-style-type:none;
        }
        .articleSubheader {
            font-size: 24px;
        }
    }

    .sidebar {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-width: 170px;
        height: 100%;
        padding-top: 180px;
        margin-left: auto;

        @include media-breakpoint-down(md) {
            .share__item {
                margin: 0 6px;
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &__item {
            &--sticky {
                flex: 1 0;
            }
        }

        &__posts {
            padding-top: 60px;
            padding-bottom: 40px;

            .card {
                margin-bottom: 35px;
            }
        }
    }

    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 75px;
        margin: 0 -10px;

        @include media-breakpoint-down(sm) {
            padding-top: 30px;
            padding-bottom: 30px;

            .link {
                padding: 0;
                padding-left: 0px;

                &:before, {
                    // display: block;
                    // margin-bottom: 10px;
                    left: -10px;    
                    top: 0px;
                    position: relative;
                }

                &--after {
                    text-align: right;

                    &:before {
                        margin-left: auto;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }

        > * {
            padding: 0 10px;
        }
    }

    .post-content__nav {

        @include media-breakpoint-down(sm) {
            display: none;
        }

    }
}
