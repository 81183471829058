.landing-form {
  position: relative;
  z-index: 4;

  @media only screen and (max-width: 980px) {
    .right-content {
      padding-top: 40px;
    }
  }

  @media only screen and (max-width: 768px) {
    .full-row{
    width: 100vw;
    margin-left: calc((100% - 100vw)/2);
    }

    .left-content {
      margin-top: -50px;
    }
  }
}
