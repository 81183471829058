.key-info {

  &--copy {
    .section-heading {
      margin-bottom: 30px;
    }
  }

  &.white-card {
    .container {
      background-color: white;
      padding: 30px;
      border-radius: 10px;

      .stepped-border {
        display: none;
      }

      h2 {
        color: $color-secondary;
      }

      .key-info__copy {
        max-width: 600px;
        p {
          font-weight: bold;
        }
      }

      ul {
        li {
          list-style: none;
          margin-bottom: 10px;
          position: relative;
          text-align: left;
          padding-left: 40px;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 30px;
            width: 30px;
            background: url('https://shepherdsfriendly.blob.core.windows.net/image-media/icon-tick.svg') 50% no-repeat;
          }
        }
      }

      .read-more-button,
      .read-more-text {
        display: none;
      }
    }
    @include media-breakpoint-down(sm) {
      .container {
        max-width: 95%;
        position: relative;
        padding-bottom: 60px;
        margin: 0 auto;
        .row.half-gutters {
          display: none;
        }

        .read-more-text {
          display: block;
          font-weight: bold;
          position: absolute;
          left: 50%;
          bottom: 30px;
          transform: translateX(-50%);
          &:before {
                        content: 'Read more +';
          }
        }

        .read-more-button {
          display: block;
          width: 100px;
          height: 20px;
          opacity: 0;
          left: 50%;
          bottom: 30px;
          transform: translateX(-50%);
          z-index: 1;
        }

        .read-more-button:checked + .read-more-text {
          &:before {
                        content: 'Read less -';
          }
        }
        .read-more-button:checked ~ .row.half-gutters {
          display: block;
        }
      }
    }
  }

  &--joined {
    .key-info__content {
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;

        .key-info__text {
          position: relative;
          flex: 1 0;
          padding: 0 10px 0 40px;
          text-align: left;
        }

        .key-info__heading {
          line-height: 1.2;
        }

        .key-info__icon {
          position: relative;
          top: -15px;
                    height: 100%!important;
          align-items: flex-start;

          @media only screen and (max-width: 370px) {
            max-width: 65px;
          }
        }

        .join {
          position: absolute;
          top: 5px;
          left: 12px;
          height: 100%;
          margin: 0;

          &__dot {
            width: 17px;
            height: 17px;
          }

          &__line {
            top: 25px;
            width: 1px;
            height: calc(100% + -15px);
            transform: translateY(0) translateX(-50%);

            &:before {
              display: none;
            }
          }

          &__mark {
            display: none;
          }
        }
      }
    }
  }

  &__col {
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    &:last-child {
      .join__line {
        display: none;
      }
    }

    &--without-margin {
      margin-bottom: 0px;

      @include media-breakpoint-down(sm) {
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  &__content {
    max-width: 320px;
    margin: 0 auto;

        &.grouped
        {
            padding:30px;
            height:100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      p {
                font-size:14px;
      }

            .key-info__card-icon{
        display: flex;
        justify-content: center;
        align-items: stretch;
        margin-bottom: 0;
      }

      .key-info__card-icon img {
        max-width: 15rem;
      }

      .key-info__text {
        margin-bottom: auto;
      }
    }
  }

  &__icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    margin-bottom: 15px;
  }

  &__heading {
    max-width: 265px;
    margin: 0 auto 10px;

    @include media-breakpoint-down(sm) {
            height: auto!important;
    }
  }

  &__footer {
    text-align: center;
  }

  &__link {
    @include media-breakpoint-up(lg) {
      margin-top: 2.5rem;
    }
  }

  .join {
    margin: 25px calc($grid-gutter-width / 4 * -1);

    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__copy {
    margin-bottom: 40px;

    &--full {
      max-width: 920px;
      margin: 0 auto;
    }
  }

  .row--slider {
    display: block;

    .key-info__col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .slick-arrow {
    top: auto;
    bottom: -35px;
    z-index: 10;
    transform: translateY(0);

    &.slick-next {
      right: 0;
    }

    &.slick-prev {
      left: 0;
    }
  }
}
