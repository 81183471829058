@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";
.o-modal {
  &--benefit {
    background: #fff6e5;
    border-radius: 1rem;
    box-shadow: none;
    text-align: left;
    flex-direction: row;
    width: 100%;
    max-width: 90.4rem;
    height: auto;
    padding: 0 9.4rem 4.5rem 3.3rem;
    display: flex;
    flex-direction: column;
  }

  &__content {
    &--benefit {
      width: 100%;
      max-width: 62.8rem;
      padding-bottom: 5.8rem;
    }
  }

  &__heading {
    &--benefit {
      color: #f7aa00;
      border-bottom: 0.1rem solid $grey;
      padding-bottom: 1rem;
      padding-top: 10.7rem;
    }
  }
}

.benefit-accordion {
  &__list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    gap: 2.5rem;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  &__item {
    font-weight: bold;
    margin-bottom: 0;
  }

  &__item-link {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: unset;
      text-decoration: none;
    }

    &--active {
      padding-bottom: 0.3rem;
      border-bottom: 0.5rem solid #87d0f1;
    }
  }
}
