.social {

    ul {
        @include list-unstyled;
        font-size: 0;
        margin: 0 -10px;

        li {
            display: inline-block;
            margin: 0 10px;
            vertical-align: middle;
            font-size: 2.6rem;
        }

        a {
            text-decoration: none;
        }
    }

}
