.page-tabs {

    .container {
        @include media-breakpoint-down(md) {
            max-width: 100%;
            padding: 0;
        }
    }

    ul {
        margin: 0 -3px;
        font-size: 0;
    }

    &__tab {
        display: inline-block;
        padding: 0 3px;
        margin: 0;

        @include media-breakpoint-down(md) {
            display: block;
            padding: 0;
            margin-bottom: 5px;
        }

        &.active {
            font-weight: bold;

            a {
                background: rgba($color-tertiary, 0.25);
            }

            .page-tabs__number {
                border-color: $white;
                background: $color-tertiary;
                color: $white;
            }
        }

        a {
            display: block;
            padding: 11px 20px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            background: rgba($color-quaternary, 0.1);
            text-decoration: none;

            @include media-breakpoint-down(md) {
                border-radius: 0;
            }

            &:hover, &:focus {
                color: $color-primary;
                background: rgba($color-tertiary, 0.25);
            }


            * {
                transition: none;
            }
        }
    }

    &__item-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media-breakpoint-down(md) {
            max-width: 720px;
            padding: 0 15px;
            margin: 0 auto;
        }
    }

    &__number {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        line-height: 1;
        margin-right: 5px;
        border: 1px solid $color-quaternary;
        border-radius: 50%;
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: $color-quaternary;
    }

}
