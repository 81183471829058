p,
li {
    font-size: 1.6rem;
    color: $color-quaternary;
}

.copy-s {
    font-size: 1.4rem;

    p,li {
        font-size: 1.4rem;
    }
}

.copy-xs {
    font-size: 1.2rem;

    p,li {
        margin-bottom: 20px;
        font-size: 1.2rem;
    }

    &__terms{
        font-weight: 300;
        opacity: .7;
    }

}

.copy-l {
    line-height: 1.5;
    font-size: 2rem;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
    }

    &--normal {
        font-weight: 400;
    }

    &--strong {
        font-weight: bold;
    }

    p,li {
        font-size: 2rem;

        @include media-breakpoint-down(sm) {
            font-size: 1.6rem;
        }
    }
}

.copy-xl {
    line-height: 1.5;
    font-size: 2.4rem;
    font-weight: 500;



    p,li {
        font-size: 2.4rem;
    }
}

.font-weight-medium {
    font-weight: 500;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 25px;
    line-height: 1.75;
    font-weight: 500;
    color: $color-primary;
}

h1 {
    line-height: 1.125;
    font-size: 3.6rem;

    @include media-breakpoint-down(sm) {
        font-size: 3.2rem;
    }

    &.larger {
        line-height: 1.2;
        font-size: 5rem;

        @include media-breakpoint-down(sm) {
            font-size: 3.2rem;
        }
    }
}

h2 {
    line-height: 1.25;
    font-size: 3.2rem;
}

h3 {
    line-height: 1.42;
    font-size: 2.8rem;
    font-weight: bold;
}

h4 {
    line-height: 1.1666;
    font-size: 2.4rem;
}

h5 {
    line-height: 1.4;
    font-size: 2rem;
    font-weight: bold;
}

h6 {
    margin-bottom: 20px;
    font-size: 1.6rem;
}

.base {
    font-size: 1.6rem;
}

.milli {
    font-size: 1.4rem;
}

.micro {
    font-size: 1.2rem;
}

.nano {
    font-size: 1rem;
}
