.column-content {
  &__copy {
    &--box {
      padding: 25px;
      border-radius: 10px;
      background: rgba($color-tertiary, 0.25);

      @include media-breakpoint-down(xs) {
        margin: 0 -12px;
      }
    }

    &--no-border-radius {
      border-radius: 0;
    }

    &--less-padding {
      padding: 1.7rem;
    }
  }
}
