.benefit-card {
  width: 100%;
  max-width: 31.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__link {
    cursor: pointer;
    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      max-width: 2.4rem;
      height: 2.4rem;
      vertical-align: bottom;
      padding-left: 3.5rem;
      background: url(../images/arrow-solid.svg) 50% no-repeat;
      background-size: contain;
    }
  }

  &__heading {
    margin-bottom: 1.2rem;
  }

  &__icon {
    padding-bottom: 1.5rem;
  }
}
