@keyframes loadingFadeOut {
  0% {opacity: 1;}
  99% {opacity: 0;}
  100% {display: none;}
}

.page-loading-animation {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background: $white;
}

html.loaded .page-loading-animation--fadeout {
  animation-name: loadingFadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

a {
  color: $color-quaternary;
  text-decoration: underline;
}

a:hover,
a:focus {
  color: $color-secondary;
  text-decoration: underline;
}

.btn,
.wp-block-button__link {
  display: inline-block;
  position: relative;
  min-width: 145px;
  padding: 18px 35px;
  border-radius: 30px;
  border: 2px solid $white;
  text-align: center;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: bold;
  background: $color-secondary;
  color: $color-primary;
  text-decoration: none;
  cursor: pointer;
  transition: $transition;
  outline: 0 !important;

  &:hover,
  &:focus {
    text-decoration: none;
    background: $color-primary;
    color: $white;
  }

  &--small {
    padding: 11px 20px;
    border-radius: 22px;
  }

  &--secondary {
    color: $white;
    background: $color-primary;

    &:hover,
    &:focus {
      background: $color-secondary;
    }
  }

  &--hero-secondary {
    &:hover,
    &:focus {
      background: $color-primary;
    }
  }

  &--hollow {
    background: transparent;
    color: $white;

    &:hover,
    &:focus {
      background: $color-secondary;
    }
  }

  &--hollow-secondary {
    background: transparent;
    border: 2px solid $color-secondary;
    color: $color-secondary;

    &:hover,
    &:focus {
      border-color: $color-primary;
      background: $color-primary;
    }
  }

  &--no-border {
    padding: 20px 35px;
    border: none;

    &.btn--small {
      padding: 13px 20px;
    }
  }

  &--black-border {
    border: 2px solid #13243a;
    color: #13243a;
    &:hover,
    &:focus {
      border-color: #ffffff;
      background-color: #13243a !important;
      color: #fff;
    }
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }

  &.loading {
    cursor: wait !important;
  }
}

.link {
  display: inline-flex;
  position: relative;
  padding-left: 35px;
  font-weight: 500;
  line-height: 1.5;
  color: $color-quaternary;
  transition: $transition;

  span {
    transition: none;
    text-decoration: underline !important;
  }

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background: url(../images/arrow-solid.svg) center no-repeat;
    background-size: contain;
  }

  &--after {
    &:before {
      display: none;
    }

    &:after {
      content: "";
      display: inline-block;
      position: relative;
      top: -1px;
      width: 24px;
      height: 24px;
      margin-left: 10px;
      vertical-align: middle;
      background: url(../images/arrow-solid.svg) center no-repeat;
      background-size: contain;
    }
  }

  &--hollow {
    &:before {
      background: url(../images/arrow-hollow.svg) center no-repeat;
      background-size: contain;
    }

    &.link--after {
      &:after {
        background: url(../images/arrow-hollow.svg) center no-repeat;
        background-size: contain;
      }
    }
  }

  &--prev {
    &:before {
      background: url(../images/arrow-solid-left.svg) center no-repeat;
      background-size: contain;
    }

    &.link--hollow {
      &:before {
        background: url(../images/arrow-hollow-left.svg) center no-repeat;
        background-size: contain;
      }
    }
  }

  &--outline {
    &:before {
      border-radius: 50%;
      border: 2px solid $white;
    }
  }

  &:hover,
  &:focus {
    color: $color-secondary;
  }
}

.tag-btn {
  display: inline-block;
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.2;
  border-radius: 12px;
  padding: 5px 10px;
  border: 1px solid lighten($color-tertiary, 18%);
  background: lighten($color-tertiary, 18%);

  &:hover,
  &:focus {
    text-decoration: none;
    background: $color-tertiary;
    border-color: $color-tertiary;
    color: $white;
  }

  &--hollow {
    border-color: $color-secondary;
    background: transparent;

    &:hover,
    &:focus {
      border-color: $color-secondary;
      background: $color-secondary;
    }
  }

  &.active {
    background: $color-tertiary;
    border-color: $color-tertiary;
    color: $white;
  }
}

a,
.card-team {
  &:hover,
  &:focus {
    .link {
      color: $color-secondary;
    }
  }
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 15px;
  margin: auto;
  display: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-top: 4px solid $black;
  border-right: 4px solid $black;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation: spin 500ms infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  padding: 100px 35px;
  background: rgba($color-quaternary, 0.9);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &__inner {
    position: relative;
    max-width: 900px;
    padding: 55px 100px 55px 55px;
    margin: 0 auto;
    background: $color-quinary;
    box-shadow: 0 0 40px rgba($darkgrey, 0.5);

    @include media-breakpoint-down(md) {
      padding: 60px 30px 30px;
    }
  }

  &__close {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: $transition;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background: $color-quaternary;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      transition: $transition;
    }

    &:after {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }

    &:hover,
    &:focus {
      &:before,
      &:after {
        background: $color-secondary;
      }
    }
  }
}

.close-btn {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: $transition;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: $color-quaternary;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transition: $transition;
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &:hover,
  &:focus {
    &:before,
    &:after {
      background: $color-secondary;
    }
  }
}

.outer {
  max-width: $site-width;
  padding: 0 80px;
  margin: 0 auto;

  @media only screen and (max-width: $br-laptop) {
    padding: 0 40px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 ($grid-gutter-width * 0.5);
  }
}

.inner {
  max-width: $content-width;
  margin: 0 auto;
}

.centred-width {
  max-width: $centred-width;
  margin: 0 auto;
}

.column-content-width {
  max-width: $column-content-width;
}

.plus {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 100%;
    background: $color-primary;
    transform: translateX(-50%) translateY(-50%);
    transition: $transition;
  }

  &:after {
    width: 100%;
    height: 2px;
  }
}

.no-results {
  padding: 60px 0;
}

/* Google Maps
============== */

.acf-map {
  width: 100%;
  height: 630px;
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}

.site-outer {
  position: relative;
  // overflow: hidden;
}

.stepped-border {
  display: block;
  border-top: 1px solid $color-quaternary;

  &:before {
    content: "";
    display: block;
    width: 100%;
    max-width: 80px;
    margin: 10px auto;
    border-bottom: 1px solid $color-quaternary;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    max-width: 40px;
    margin: 0 auto;
    border-bottom: 1px solid $color-quaternary;
  }

  &--up {
    border-bottom: 1px solid $color-quaternary;
    border-top: none;

    &:before {
      max-width: 40px;
      margin: 0 auto;
      border-top: 1px solid $color-quaternary;
      border-bottom: none;
    }

    &:after {
      max-width: 80px;
      margin: 10px auto;
      border-top: 1px solid $color-quaternary;
      border-bottom: none;
    }
  }
}

.slick-slider {
  * {
    outline: 0;
  }
}

.slick-slide {
  transition: opacity 0.3s;
}

.slick-arrow {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid $color-quaternary;
  transition: $transition;

  @include media-breakpoint-down(xs) {
    width: 42px;
    height: 42px;
    border-width: 1px;
  }

  @media only screen and (max-width: 370px) {
    width: 35px;
    height: 35px;
  }

  &:hover {
    background: $color-secondary;
  }

  &:before {
    display: none;
  }

  &.slick-next,
  &.slick-prev {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 53%;
      width: 26px;
      height: 26px;
      background: url(../images/chevron-right-large.svg) center no-repeat;
      background-size: contain;
      transform: translateX(-50%) translateY(-50%);

      @media only screen and (max-width: 370px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.slick-prev {
    &:after {
      left: 47%;
      background: url(../images/chevron-left-large.svg) center no-repeat;
      background-size: contain;
    }
  }

  &--product-card-carousel,
  &--article-carousel
   {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 3;
    margin: 0 12rem;
    transform: translateY(0) translateX(0);

    @include media-breakpoint-down(sm) {
      margin: 0 2rem;
    }

    @include media-breakpoint-down(xs) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.slick-prev {
        left: 2rem;

        @media only screen and (max-width: 400px) {
          left: 0;
        }
      }

      &.slick-next {
        right: 2rem;
        left: auto;

        @media only screen and (max-width: 400px) {
          right: 0;
        }
      }
    }
  }
}

.slick-dots {
  li {
    button {
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        opacity: 1;
        border: 1px solid $grey;
      }
    }

    &.slick-active {
      opacity: 1;

      button {
        &:before {
          background: $color-tertiary;
        }
      }
    }
  }
}

.label {
  display: block;
  margin-bottom: 25px;
  font-size: 2rem;
  font-weight: bold;
  color: $color-primary;

  &:after {
    content: "";
    display: block;
    width: 100%;
    max-width: 25px;
    height: 1px;
    margin-top: 5px;
    background: $color-quaternary;
  }
}

.post-image {
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.border-underline {
  font-weight: 500;
  padding: 3px 0;
  border-bottom: 4px solid $color-tertiary;

  &:hover,
  &:focus {
    color: $color-tertiary;
  }
}

.section-heading {
  margin-bottom: 50px;
}

.wrap-match {
  font-weight: bold;
}

.sidebar {
  position: relative;
  left: 45px;

  @include media-breakpoint-down(lg) {
    left: 0;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 40px;
  }

  &__panel {
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 45px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wp-block-gallery {
  padding: 15px 0;
}

.round-top-borders {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.round-image-borders {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  img {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.wp-block-table {
  max-width: 470px;
  margin: 0 auto;
  text-align: center;

  table {
    width: auto;
    margin: 0 auto;
    text-align: center;

    td {
      min-width: 175px;
      padding: 5px 0;
      border-bottom: 1px solid $grey;

      @include media-breakpoint-down(xs) {
        min-width: 135px;
      }

      @media only screen and (max-width: 370px) {
        min-width: 105px;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  td {
    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  figcaption {
    padding-bottom: 60px;
    padding-top: 40px;
    text-align: center;
  }
}
.has-text-align-center {
  text-align: center;
}
.wp-block-social-links {
  margin: 0 -2px;
  text-align: center;
  padding-left: 0;
}

.wp-social-link {
  text-align: center;
  margin: 0 2px 10px;
  background: none !important;
  list-style-type: none;
  display: inline;

  svg {
    width: 26px;
    height: 26px;
  }

  a {
    color: $color-primary;
    background: none;

    &:hover,
    &:focus {
      color: $color-secondary;
    }
  }
}

.buttons {
  font-size: 0;
  margin: 0 -7px;

  > * {
    display: inline-block;
    padding: 0 7px;
    margin-bottom: 10px;

    @include media-breakpoint-down(xs) {
      .btn {
        min-width: 240px;
      }
    }
  }
}

.tick-bullets {
  ul {
    padding-left: 0;
    list-style: none;
    text-align: left !important;

    li {
      position: relative;
      font-weight: 500;
      padding-left: 40px;
      margin-bottom: 12px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 25px;
        height: 25px;
        background: url(../images/tick-bullet.svg) center no-repeat;
        background-size: contain;
      }
    }

    .tick-bullets--location {
      &::before {
        background: url(../images/location.svg) center no-repeat;
      }
    }

    .tick-bullets--mobile {
      &::before {
        background: url(../images/mobile-phone.svg) center no-repeat;
      }
    }

    .tick-bullets--what3words {
      &::before {
        background: url(../images/location-what3words.svg) center no-repeat;
      }
    }
  }

  &--grey {
    ul {
      li {
        &:before {
          background: url(../images/tick-bullet-grey.svg) center no-repeat;
          background-size: contain;
        }
      }
    }
  }

  &--orange {
    ul {
      li {
        &:before {
          background: url(../images/tick-bullet-orange.svg) center no-repeat;
          background-size: contain;
        }
      }
    }
  }

  &--large {
    ul {
      li {
        font-size: 2rem;
        font-weight: bold;

        @include media-breakpoint-down(sm) {
          font-size: 1.6rem;
        }

        &:before {
          top: 6px;

          @include media-breakpoint-down(sm) {
            top: 2px;
          }
        }
      }
    }
  }
  &--product-card {
    ul {
      li {
        font-weight: 400;
        padding: {
          top: 0.9rem;
        }
        border-top: 1px solid $grey;

        &:before {
          top: 1.4rem;
          width: 2rem;
          height: 2rem;
          background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24.746' height='24.746' viewBox='0 0 24.746 24.746'><g id='Tick_icon' data-name='Tick icon' transform='translate(-662 -558.5)'><g id='Ellipse_52' data-name='Ellipse 52' transform='translate(662 558.5)' fill='none' stroke='#454E50' stroke-width='1.3'><circle cx='12.373' cy='12.373' r='12.373' stroke='none'/><circle cx='12.373' cy='12.373' r='11.723' fill='none'/></g><path id='Tick' d='M6884.319-13723.594l3.736,4.656,6.542-10.153' transform='translate(-6214.839 14294.862)' fill='none' stroke='#454E50' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/></g></svg>")
            center no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

.join {
  display: block;
  position: relative;
  margin: 0 -15px;

  &__dot {
    display: block;
    position: relative;
    z-index: 2;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid $color-secondary;
  }
  &__line {
    display: block;
    position: absolute;
    top: 50%;
    left: 60%;
    width: 80%;
    height: 1px;
    background: $color-primary;
    transform: translateY(-50%);

    @include media-breakpoint-down(sm) {
      left: 50%;
    }
  }
}


.wide-copy {
  max-width: $wide-copy;
  margin: 0 auto;
}

.icon {
  display: inline-block;
  width: 25px;
  height: 25px;

  background: url(../images/tick-bullet-dark.svg) center no-repeat;
  background-size: contain;

  &--tick {
    background: url(../images/tick-bullet-dark.svg) center no-repeat;
    background-size: contain;
  }

  &--cross {
    background: url(../images/cross.svg) center no-repeat;
    background-size: contain;
  }
}

.link-list {
  max-width: 380px;
  margin: 0 auto;
  text-align: left;
}

.breadcrumbs {
  margin: 0 0 25px;

  a,
  span {
    color: rgba($color-quaternary, 0.5);
  }

  a {
    &:hover,
    &:focus {
      color: $color-secondary;
    }
  }

  &__chevron {
    padding: 0 8px;
  }
}

.download-link {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  margin-bottom: 10px;
  font-weight: 500;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 33px;
    height: 41px;
    background: url(../images/pdf-icon-grey.svg) center no-repeat;
    background-size: contain;
    transform: translateY(-50%);
  }
}

.list-two-col {
  columns: 2;
  column-gap: 50px;
  text-align: left;

  @include media-breakpoint-down(sm) {
    columns: 1;
  }

  > * {
    break-inside: avoid-column;
  }
}

.block-heading {
  text-align: center;
  margin-bottom: 50px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 35px;
  }
}

.arrow {
  &:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background: url(../images/arrow-solid.svg) center no-repeat;
    background-size: contain;
    border-radius: 50%;
    border: 2px solid $white;
  }

  &--down {
    &:before {
      transform: rotate(90deg);
    }
  }
}

.dataTables_wrapper {
  @include media-breakpoint-down(xs) {
    margin-right: -25px;
  }
}

.dataTables_scroll {
  @include media-breakpoint-down(xs) {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -2px;
      right: 0;
      z-index: 3;
      width: 30px;
      height: calc(100% + 2px);
      border-right: 10px solid $white;
      background: linear-gradient(to left, rgba($white, 1), rgba($white, 0));
    }
  }
}

.dataTables_scrollHead {
  position: relative;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  table.tablepress {
    position: static;
    overflow: visible;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    thead {
      th {
        font-size: 1.6rem;
      }
    }

    th,
    td {
      &:first-child {
        font-weight: bold;
      }
    }
  }
}

.dataTables_scrollHeadInner,
.dataTables_scrollBody {
  position: relative;
  min-width: 100%;
}

table.tablepress {
  width: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  &.highlight-row-one {
    tbody {
      tr {
        &:first-child {
          td {
            border-bottom: 0;
            font-weight: 500;
            background: $color-secondary;
            color: $white;
          }
        }
      }
    }
  }

  &.highlight-row-two {
    tbody {
      tr {
        &:nth-child(2) {
          td {
            border-bottom: 0;
            font-weight: 500;
            background: $color-secondary;
            color: $white;
          }
        }
      }
    }
  }

  &.highlight-row-three {
    tbody {
      tr {
        &:nth-child(3) {
          td {
            border-bottom: 0;
            font-weight: 500;
            background: $color-secondary;
            color: $white;
          }
        }
      }
    }
  }

  &.highlight-row-four {
    tbody {
      tr {
        &:nth-child(4) {
          td {
            border-bottom: 0;
            font-weight: 500;
            background: $color-secondary;
            color: $white;
          }
        }
      }
    }
  }

  &.highlight-row-five {
    tbody {
      tr {
        &:nth-child(5) {
          td {
            border-bottom: 0;
            font-weight: 500;
            background: $color-secondary;
            color: $white;
          }
        }
      }
    }
  }

  thead {
    th {
      font-size: 2rem;
      font-weight: bold;
      border-bottom: 0;
      white-space: nowrap;
      background-color: $color-primary;
      color: $white;

      @include media-breakpoint-down(md) {
        white-space: normal;
        font-size: 1.6rem;
      }
    }
  }

  th,
  td {
    line-height: 1.5;
    padding: 10px 15px;
    min-width: 130px;

    @include media-breakpoint-down(md) {
      min-width: 90px;
      padding: 10px;
    }
  }

  tbody td,
  tfoot th {
    border-top: 0;
    border-bottom: 1px solid $grey;
  }

  tbody tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  &.table-3-1 {
    .column-1 {
      width: 70%;
    }
  }
}

.application-wrap {
  max-width: 450px;
  margin: 0 auto;
}

.container {
  @include media-breakpoint-down(xs) {
    padding-right: 35px;
    padding-left: 35px;
  }
}

.after-sub {
  &.block-space--no-top {
    @include media-breakpoint-down(sm) {
      padding-top: 35px;
    }
  }

  &:not(.block-space) {
    padding-top: 35px;
  }
}

@include media-breakpoint-down(xs) {
  .blocks-gallery-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.site-outer {
  opacity: 0;
  transition: $transition;

  &.loaded {
    opacity: 1;
  }
}

.LoginContainer {
  padding: 40px;
  background-color: #ffffff;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
}

.LoginContainer form {
  height: 100%;
  position: relative;
}

.LoginContainer h2 {
  font-family: 'Brandon Text', Arial, sans-serif !important;
  color: #13243a !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  margin-top: 0px !important;
  margin-bottom: 30px !important;
  text-align: center;
}

.LoginContainer button[type="submit"] {
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
}

.LoginContainer label {
  font-family: 'Brandon Text', Arial, sans-serif;
  color: #454e50;
  font-size: 16px;
  font-weight: bold;
}

.LoginContainer a {
  font-family: 'Brandon Text', Arial, sans-serif;
  color: #454e50;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 30px;
  display: inline-block;
}

.LoginContainer input {
  border: 1px solid #454e50 !important;
  border-top-color: rgb(69, 78, 80) !important;
  border-top-style: solid !important;
  border-top-width: 1px !important;
  border-right-color: rgb(69, 78, 80) !important;
  border-right-style: solid !important;
  border-right-width: 1px !important;
  border-bottom-color: rgb(69, 78, 80) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-left-color: rgb(69, 78, 80) !important;
  border-left-style: solid !important;
  border-left-width: 1px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
  border-radius: 5px !important;
  margin-bottom: 30px;
  color: #454e50 !important;
  font-family: 'Brandon Text', Arial, sans-serif !important;
  font-size: 16px !important;
}

.LoginContainer .Error {
  color: red;
}

.flex-container-white {
  padding: 40px;
  background-color: #ffffff;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;

  border-radius: 10px;
}

.flex-container-white div {
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}

.flex-row {
  flex-direction: row;
}

.flex-space-between {
  justify-content: space-between;
}

.before-arrow-right {
  position: relative;
  padding-left: 50px;
}

.before-arrow-right::before {
  background: url("https://shepherdsfriendly.blob.core.windows.net/image-media/icon-arrow-right-filled.svg")
    no-repeat;
  width: 26px;
  height: 26px;
  line-height: 26px;
  position: absolute;
  left: 0;
  content: "    ";
}

.white-block {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center !important;
  font-weight: bold;
}

.shadow {
  box-shadow: 3px 3px 8px #0000001a;
}

.no-margin-bottom-list {
  li {
    margin-bottom: 0;
  }
}
