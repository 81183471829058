@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.accordion {
  position: relative;
  overflow: hidden;

    &--two {
        .accordion__dropdowns {
            columns: 1;
        }

        .accordion__content {
            max-width: 540px;

            @include media-breakpoint-down(md) {
                margin: 0 auto;
            }
        }

        .accordion__list {
            flex: 0 0 100%;
            max-width: 100%;
        }

    }


    &--three{
        .accordion{
            &__list{
                flex: 0 0 100%;
                max-width: 100%;
                margin: 0;
                font-size: 0;
                padding: 0 15px;

                @include media-breakpoint-down(md){
                    padding: 0 10px;
                }
                @include media-breakpoint-down(sm){
                    padding: 0  5px;
                }
            }
        }
        .dropdown {
            border-color: $color-secondary !important;

            &__trigger{

                border-bottom: none;
            }

            &.active{

                border-bottom: none !important;

            }
        }

        .accordion__dropdowns>ul:first-child {
            border-bottom: none;
        }

    }


    &.display-full-width {
        .accordion__content {
            max-width: 100%;
        }

        .section-heading {
            max-width: fit-content;
            margin: 0px;

            .section-heading__inner {
                padding: 0px;

                h2 {
                    color: $color-secondary;
                    margin-bottom: 30px;
                    font-size: 24px;
                }
            }

            .stepped-border {
                display: none;
            }
        }
    }

    &__outer {
        position: relative;
        z-index: 1;
    }

    &__heading {
        margin-bottom: 35px;
    }

  &__dropdowns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -35px;

    > ul {
      > li {
        border-top: 1px solid $color-quaternary;

        &:last-child {
          border-bottom: 1px solid $color-quaternary;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      margin: 0 calc($grid-gutter-width / 2 * -1);
    }

    @include media-breakpoint-down(sm) {
      > ul {
        &:nth-child(2) {
          > li {
            &:first-child {
              border-top: none;
            }
          }
        }
      }
    }
  }

    &__list {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 35px;
        margin: 0;
        font-size: 0;

        &--full-width {
            max-width: 100%;
            min-width: 100%;
        }

        @include media-breakpoint-down(lg) {
            padding: 0 calc($grid-gutter-width / 2);
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        li {
            margin-bottom: 0;

            &:last-child {
                .dropdown {
                    border-bottom: none;
                }
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__copy {
        padding-top: 45px;
    }
}

.c-content-list {
    &--accordion {
        margin: 0 auto;
        padding: 0;
        @media (min-width: map-get($sizes, lg)) {
            padding: 0 3.6rem;
        }

    }
}

.dropdown {
  &--accordion {
    padding: 0 4rem 0 0;
    margin-bottom: 0;

    &--white {
      border-bottom: none;
      border-radius: 0.6rem;
      padding: 1.2rem 1.6rem;
      width: 100%;
      background: $white;

      h2 {
        font-size: 1.8rem;
      }
    }
  }

  &__trigger--accordion {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    border-top: none;
    border-bottom: none;

    &--white {
      justify-content: space-between;
      padding: 0;

      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="%23F5F6F6"/><path d="M16 10.1667V21.8334" stroke="%2313243A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.1665 16H21.8332" stroke="%2313243A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        background-size: contain;
        transform: translateY(-50%) rotate(90deg);
        transition: 0.2s;
      }
    }

    &:after {
      right: 0;
    }
  }

  &__copy {
    &--accordion {
      padding-bottom: 15px;
      transition: 0.5s ease opacity;

      &--white {
        padding-top: 1.2rem;
        padding-bottom: 0;
      }
    }
  }
}
