@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-vacancies {
  &__card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    margin-top: 5.8rem;

    @media (min-width: map-get($sizes, lg)) {
      width: 100%;
      max-width: 108.8rem;
      flex-direction: row;
      gap: 1rem;
      margin-top: 7.9rem;
    }
  }

  &__card-link {
    padding-bottom: 2.4rem;
    text-decoration: underline !important;

    &::before {
      content: "";
      display: inline-block;
      width: 100%;
      max-width: 2.4rem;
      height: 2.4rem;
      vertical-align: bottom;
      padding-left: 3.5rem;
      background: url(../images/arrow-solid.svg) 50% no-repeat;
      background-size: contain;
    }
  }

  &__no-suitable {
    width: 100%;
    max-width: 75.2rem;
    margin: 0 auto;
    padding: 5.9rem 1rem;

    @media (min-width: map-get($sizes, lg)) {
      text-align: center;
      padding: 8.8rem 0;
    }
  }

  &__no-suitable-header {
    margin-bottom: 0;
  }
}
.c-card {
  &--vacancies {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: $white;
    border: 0.1rem solid $color-secondary;
    border-radius: 1rem;
    text-align: center;
    width: 100%;

    @media (min-width: map-get($sizes, lg)) {
      max-width: 35.2rem;
      min-height: 31.5rem;
    }
  }

  &__header {
    &--vacancies {
      justify-content: center;
      font-size: 2rem;
      font-weight: bold;
      margin: 0;
      padding: 1.7rem 1.1rem 2.7rem 1.1rem;

      @media (min-width: map-get($sizes, lg)) {
        padding: 0 3.5rem 1.8rem 3.5rem;
      }
    }

    &--no-vacancies {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__content {
    &--vacancies {
      display: none;

      @media (min-width: map-get($sizes, md)) {
        display: block;
        margin-bottom: 0;
        padding-bottom: 3.1rem;
      }
    }

    &--no-vacancies {
      margin-bottom: 0;
    }
  }

  &--no-vacancies {
    background: $white;
    border: 0.1rem solid $color-secondary;
    border-radius: 1rem;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @media (min-width: map-get($sizes, md)) {
      max-width: 55.4rem;
      min-height: 27.1rem;
    }
  }
}
