.c-card {
  &--signpost-product {
    flex: 1 0;
    min-width: 28.6rem;
    max-width: 35rem;

    @include media-breakpoint-up(lg) {
      max-width: 44rem;
    }
  }
}
