.expandable-content {

    .copy-l {
        font-weight: 400;
    }

    li {
        margin-bottom: 5px;
    }

    .column-content-width, .dropdown__trigger {
        max-width: $centred-width;
    }

    .dropdown__trigger {
        border-top: 1px solid $color-quaternary;
        border-bottom: 1px solid $color-quaternary;
    }

    &__row {
        margin-bottom: 25px;
    }

    hr {
        margin-bottom: 45px;
    }
}
