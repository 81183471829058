.signpost {
  position: relative;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-wrap: wrap;
  }

  @include media-breakpoint-down(sm) {
    justify-content: center;
    text-align: center;
  }

  &__heading {
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0px;
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    max-width: 240px;
    transform: translateY(-50%);
    text-align: right;

    @include media-breakpoint-down(md) {
      order: -1;
      position: relative;
      top: 0;
      transform: translateY(0);
      margin-bottom: 20px;
      text-align: center;
    }

    img {
      max-width: 180px;
    }
  }

  &__copy {
    width: 75%;
    max-width: 280px;
    min-height: 85px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .buttons {
    padding-top: 20px;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }
}
