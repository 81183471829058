.pagination {
    padding-top: 80px;
    text-align: center;

    &__prev-next, .next, .prev {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0;
        margin-right: 30px;

        @include media-breakpoint-down(sm) {
            margin-right: 15px;
        }

        @include media-breakpoint-down(xs) {
            margin-right: 5px;
        }

        &:before {
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: url(../images/arrow-hollow-thin-left.svg) center no-repeat;
            background-size: contain;
        }

        &.next, &--next {
            margin-right: 0;
            margin-left: 30px;

            @include media-breakpoint-down(sm) {
                margin-left: 15px;
            }

            @include media-breakpoint-down(xs) {
                margin-left: 5px;
            }

            &:before {
                background: url(../images/arrow-hollow-thin-right.svg) center no-repeat;
                background-size: contain;
            }
        }
    }

    &__links {
        display: inline-block;
        line-height: 1;
        font-size: 0;
        vertical-align: middle;

        > * {
            font-size: 1.6rem;
        }

        a {
            text-decoration: none;
        }

        .page-numbers {
            display: block;
            position: relative;
            min-width: 20px;
            padding: 10px 4px;
            margin: 0 8px;
            text-align: center;

            @include media-breakpoint-down(sm) {
                padding: 10px 2px;
            }

            @include media-breakpoint-down(xs) {
                margin: 0 4px;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: transparent;
            }

            &.dots {
                + .page-numbers {
                    &:not(.next) {
                        display: none;
                    }
                }
            }

            &.next, &.prev {
                padding: 0;

                &:after {
                    display: none;
                }

                &:hover, &:focus {
                    opacity: 0.75;
                }
            }

            &.current {
                font-weight: bold;

                &:after {
                    background: $color-tertiary;
                }
            }


        }


    }

    &__links-inner {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        align-items: center;
    }


}
