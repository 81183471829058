.mastfoot {
  padding: 55px 0;

  @include media-breakpoint-down(xs) {
    padding: 40px 0;
  }

  &--sticky-footer {
    @include media-breakpoint-down(md) {
      padding-bottom: 100px;
    }
  }

  &__top-links {
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
  }

  &__contact {
    padding: 15px 0;

    ul {
      margin: 0;
    }

    li {
      margin: 0 0 5px;
    }

    strong {
      margin-right: 10px;
    }
  }

  &__links {
    padding-top: 5px;
  }

  &__nav {
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      justify-content: space-between;

      > li {
        padding: 0 10px;

        @include media-breakpoint-down(sm) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @include media-breakpoint-down(xs) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 0;
        }

        &:first-child {
          flex: 0 0 50%;
          max-width: 50%;

          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }

          .sub-menu {
            columns: 2;
            column-gap: 20px;

            @include media-breakpoint-down(xs) {
              columns: 1;
              column-gap: 0;
            }
          }
        }

        &.clicked {
          @include media-breakpoint-down(xs) {
            > a {
              &:after {
                background: url(../images/close-button.svg) center no-repeat;
                background-size: contain;
                transform: translateY(-50%) rotate(0);
              }
            }

            .sub-menu {
              @include media-breakpoint-down(xs) {
                display: block;
                padding-top: 15px;
              }
            }
          }
        }

        > a {
          display: inline-block;
          margin-bottom: 18px;
          font-size: 2rem;
          font-weight: bold;
          color: $color-secondary !important;

          @include media-breakpoint-down(xs) {
            display: block;
            position: relative;
            padding: 10px 40px 10px 0;
            border-bottom: 1px solid $white;
            margin-bottom: 0;
            line-height: 1.5;
            color: $white !important;
            cursor: pointer;

            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              top: 50%;
              right: 0;
              width: 24px;
              height: 24px;
              margin-left: 10px;
              vertical-align: middle;
              background: url(../images/arrow-hollow.svg) center no-repeat;
              background-size: contain;
              transform: translateY(-50%) rotate(90deg);
              transition: $transition;
            }
          }
        }

        .sub-menu {
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
      }
    }

    a[href="#"] {
      cursor: default;
    }

    ul {
      @include list-unstyled;
      margin-bottom: 0;
    }

    .sub-menu {
      li {
        line-height: 1.5;
        margin-bottom: 8px;

        a {
          position: relative;
          display: inline-block;
          padding-left: 20px;

          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 7px;
            left: 0;
            width: 10px;
            height: 12px;
            margin-right: 10px;
            background: url(../images/chevron-right-secondary.svg) center no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  &__bottom {
    opacity: 0.7;
    padding-top: 35px;

    .mastfoot__copyright {
      margin-bottom: 20px;

      @include media-breakpoint-down(xs) {
        padding-top: 20px;
      }
    }
  }

  &__app-store-buttons {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 32px;

    @include media-breakpoint-down(md) {
      padding-top: 17px;
    }

    @include media-breakpoint-down(xs) {
      flex-direction: row;
      gap: 16px;
    }

    @media only screen and (max-width: 375px) {
      flex-direction: column;
    }

    &--apple-store {
      margin-bottom: 16px;

      @include media-breakpoint-down(xs) {
        margin-bottom: 0;
      }

      svg {
        border-radius: 6px;
      }
    }

    &--google-play {
      svg {
        border-radius: 6px;
      }
    }
  }
}
