.masthead {
  position: relative;
  z-index: 400;
  width: 100%;

  .container {
    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding-right: 35px;
      padding-left: 35px;
    }
  }

  &.loaded {
    position: relative;
    top: 0;
    left: 0;

    @include media-breakpoint-down(md) {
      position: relative;

      &.masthead--page-top {
        position: relative;
      }
    }
  }

  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background: rgba($black, 0.5);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: $transition;
  }

  &__inner {
    padding: 15px 0 0 0;
    background: $white;

    @include media-breakpoint-down(md) {
      padding: 12px 0;
    }
  }

  &.active-hovered {
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  &__logo {
    @include media-breakpoint-down(lg) {
      max-width: 155px;
    }

    @include media-breakpoint-down(md) {
      max-width: 165px;
    }
  }

  &__top {
    padding-bottom: 15px;

    ul {
      margin: 0 -8px;
      font-size: 0;

      li {
        display: inline-block;
        margin: 0 8px;
      }
    }

    &--divide {
      ul {
        margin: 0 -15px;
        li {
          position: relative;
          padding: 0 15px;
          margin: 0;

          &:first-child {
            &:before {
              display: none;
            }
          }

          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 5px;
            left: 0;
            vertical-align: middle;
            width: 2px;
            height: 20px;
            background: $grey;

            @include media-breakpoint-down(xs) {
              display: none;
            }
          }
        }
      }
    }
  }

  &__main {
    text-align: right;
  }

  &__navigation {
    .label {
      line-height: 1.1;
      flex: 0 0 100%;
      max-width: 100%;
      text-align: left;
      &:after {
        margin-top: 10px;
      }
    }

    .mobile-item {
      display: none !important;
    }

    > ul {
      margin: 0 -10px;

      @include media-breakpoint-down(lg) {
        margin: 0 -7px;
      }

      > li {
        margin: 0 10px;

        @include media-breakpoint-down(lg) {
          margin: 0 7px;
        }

        > .featured-menu-item {
          display: none;
        }

        &.current-menu-ancestor,
        &.current-page-ancestor,
        &.current-page-parent,
        &.current-menu-parent,
        &.current-menu-item {
          > a {
            &:after {
              bottom: 15px !important;
              opacity: 1 !important;

              @include media-breakpoint-down(lg) {
                bottom: 10px !important;
              }
            }
          }
        }

        &.active {
          > a {
            &:after {
              opacity: 1 !important;
              bottom: 0 !important;
            }
          }

          .sub-menu-wrapper {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
          }
        }

        &.menu-item-has-children {
          > a {
            position: static;
          }
        }

        > a {
          text-decoration: none;

          &:before {
            display: none;
          }

          &:not(.btn) {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding-top: 4px;
            padding-bottom: 30px;

            @include media-breakpoint-down(lg) {
              padding-bottom: 20px;
            }

            &:after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 5px;
              background: $color-tertiary;
              opacity: 0;
              pointer-events: none;
              transition: $transition;
            }

            &:hover,
            &:focus {
              text-decoration: none;
              color: $color-primary;

              &:after {
                opacity: 1;
              }
            }
          }
        }

        &.masthead__login {
          vertical-align: top;
        }

        &.separator {
          > a {
            margin-left: 20px;

            @include media-breakpoint-down(lg) {
              margin-left: 14px;
            }

            &:before {
              content: "";
              display: inline-block;
              position: absolute;
              top: 3px;
              left: -20px;
              vertical-align: middle;
              width: 1px;
              height: 30px;
              margin-right: 20px;
              background: $grey;

              @include media-breakpoint-down(lg) {
                left: -14px;
              }
            }
          }
        }
      }
    }

    ul {
      @include list-unstyled;
      font-size: 0;

      li {
        display: inline-block;
        font-weight: 500;
      }
    }

    .btn {
      min-width: 120px;
      padding: 9px 20px 9px 40px;

      i {
        position: absolute;
        top: 52%;
        left: 20px;
        margin-right: 6px;
        font-size: 2.2rem;
        transform: translateY(-50%);
      }
    }

    .sub-menu-wrapper {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 99;
      width: 100%;
      background: $color-quinary;
      text-align: left;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: $transition;
    }

    .sub-menu-inner {
      display: flex;
      flex-wrap: wrap;
      max-width: map-get($container-max-widths, xs);
      padding: 50px calc($grid-gutter-width / 2);
      margin: 0 auto;

      & > * {
        &:last-child {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-up(sm) {
        max-width: 100%;
      }

      @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, lg);
      }

      @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, xl);
      }
    }

    .sub-menu-main-links {
      flex: 1 0;
    }

    .nav-unstyled-link {
      text-decoration: none;
      &:hover,
      &:focus {
        color: $color-primary !important;
      }
    }

    .sub-menu-0 {
      columns: 2;
      > li {
        display: block;
        break-inside: avoid-column;
        &.nav-subtitle,
        &.nav-subtitle > a {
          cursor: default;
          height: -moz-fit-content;
        }
      }

      a {
        color: $color-quaternary;
        text-decoration: none;
      }

      li {
        margin-bottom: 15px;
      }
    }

    .sub-menu-1 {
      columns: 1;
      > li {
        display: block;
        break-inside: avoid-column;
        > a {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    .featured-menu-item {
      flex: 0 0 400px;
      max-width: 400px;
      padding-left: 50px;
      border-left: 1px solid $color-quaternary;

      @include media-breakpoint-up(xl) {
        margin-left: 10%;
      }

      a {
        text-decoration: none;
      }

      &__header {
        color: $color-secondary;
        line-height: 1.25;
        font-size: 3.2rem;
      }

      .copy-l {
        margin-bottom: 40px;
        line-height: 1.3;
        font-weight: bold;

        * {
          line-height: 1.3;
          font-weight: bold;
        }
      }
    }
  }
}

.blog {
  .blog-item {
    > a {
      &:after {
        bottom: 15px !important;
        opacity: 1 !important;

        @include media-breakpoint-down(lg) {
          bottom: 10px !important;
        }
      }
    }
  }
}

.post-type-archive-news,
.single-news {
  .news-item {
    > a {
      &:after {
        bottom: 15px !important;
        opacity: 1 !important;

        @include media-breakpoint-down(lg) {
          bottom: 10px !important;
        }
      }
    }
  }
}
