.image-content {
    position: relative;
    z-index: 1;
  

    &--reverse {
        .image-content__image {
            text-align: right;
            right: 0;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }



    }

    &--overflow-image {
        .image-content__image {
            position: absolute;
            bottom: -110px;

            @include media-breakpoint-down(sm) {
                display: none;
                position: relative;
                bottom: 0;
            }
        }
    }

    &.large-gutter {
        .flex-row-reverse {
            .col-md-5 {
                @include media-breakpoint-up(lg) {
                    padding-left: 40px;
                }
            }
            .col-md-7 {
                @include media-breakpoint-up(lg) {
                    padding-right: 40px;
                }
            }

        }

        .col-md-5 {
            @include media-breakpoint-up(lg) {
                padding-right: 40px;
            }
        }
        .col-md-7 {
            @include media-breakpoint-up(lg) {
                padding-left: 40px;
            }
        }

        p {
            text-align: left;
        }

        ul:not(.list-unstyled) {
            padding-left: 0px;
            li {
                list-style: none;
                margin-bottom: 10px;
                position: relative;
                text-align: left;
                padding-left: 40px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 30px;
                    width: 30px;
                    background: url('https://shepherdsfriendly.blob.core.windows.net/image-media/icon-tick.svg') 50% no-repeat;
                }
            }
        }
    }

    &__col {
        &:first-child {
            @include media-breakpoint-down(sm) {
                order: 2;
                text-align: center;
            }
        }
    }

    &__image {
        width:100%;
        @include media-breakpoint-down(sm) {
            padding-top: 40px;
            max-width: 260px;
            margin: 0 auto;
        }
    }

    .section-heading {
        margin-bottom: 30px;
    }

    .btn-wrap {
        padding-top: 25px;
    }

    .link-list {
        padding-top: 15px;
    }

    &.white-card {
        .container {
            background-color: white;
            padding: 30px;
            border-radius: 10px;

        }
    }

    &.image-content-card {
        .container {
            padding: 50px;

            .inner
            {
                margin:unset;
                max-width: unset;
            }

            .btn-wrap
            {
                text-align: left;
            }


        }

        &.block-space {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }    
}
.pagename-about {
    #putting-members-first {
        background:#fff6e5!important;
    }
}

.pagename-income-protection {
    #dont-edit {
        background:#fff6e5!important;
    }
}