.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100%;
    padding: 15px 0;
    background: $white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-100%);
    transition: $transition;
    box-shadow: 0 3px 6px rgba($black, 0.16);

    @include media-breakpoint-down(md) {
        top: auto;
        bottom: 0;
        transform: translateY(100%);
    }

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateY(0);
    }

    &__logo {
        max-width: 150px;
    }

    .buttons {
        margin: 0 -10px;

        > * {
            padding: 0 10px;
            margin: 0;
        }
    }

    .container {
        @include media-breakpoint-down(xs) {
            padding: 0 15px;

            .btn-wrap {
                padding: 0 5px;
            }

            .btn {
                min-width: 120px;
                padding-right: 25px;
                padding-left: 25px;
            }
        }
    }
}
