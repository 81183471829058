.app-content {
    padding-top: 75px;

    @include media-breakpoint-down(sm) {
        padding-top: 10px;
    }

    &__body {
        padding: 60px 0;
        background: rgba($color-tertiary, 0.25);
    }

    &__intro {
        margin-bottom: 50px;
    }

    &__text-box {
        padding: 35px 40px;
        margin-bottom: 50px;
        border-radius: 10px;
        background: $white;

        @include media-breakpoint-down(sm) {
            padding: 30px 25px;
        }

        h1,h2,h3,h4 {
            color: $color-secondary;
        }
    }

    &__logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        margin: 0 -10px;

        @include media-breakpoint-down(sm) {
            justify-content: center;
        }
    }

    &__logo {
        padding: 10px;

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }

    .btn-wrap {
        margin-bottom: 35px;
    }

    &__copy {
        margin-bottom: 40px;
    }

    hr {
        margin: 45px 0;
    }
}

