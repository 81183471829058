@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.email-resend {
  background-color: #e1f3fc;
  border-radius: 1rem;
  padding: 1rem 2rem 2rem 1.5rem;
  margin: 6.5rem 0;
  text-align: left;
  display: flex;

  @media (max-width: map-get($sizes, lg)) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__content {
    margin-right: 1.5rem;

    @media (max-width: map-get($sizes, md)) {
      margin: 0;
    }
  }

  &__icon {
    margin: 0.2rem 0.5rem 0 0;

    @media (max-width: map-get($sizes, md)) {
      margin: 0.5rem 0 0 0;
      width: 3.5rem;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: map-get($sizes, lg)) {
      display: flex;
      flex-direction: row;
    }

    @media (max-width: map-get($sizes, md)) {
      position: relative;
      flex-direction: row-reverse;
      justify-content: flex-end;
      flex: 1;
    }
  }

  &__header-text {
    font-weight: 500;
    font-size: 2.4rem;
    padding-top: 0;
    padding-left: 1rem;
    text-align: left;
    margin-bottom: 0;

    @media (max-width: map-get($sizes, md)) {
      padding: 1rem 0 0 1rem;
      width: 85%;
    }
  }

  &__copy {
    font-size: 1.6rem;
    text-align: left;
    padding-left: 4.2rem;
    margin: 0.5rem 0 0 0;

    @media (max-width: map-get($sizes, lg)) {
      margin: 1rem 0 0 0;
    }

    @media (max-width: map-get($sizes, md)) {
      padding: 0 3.5rem 0 1rem;
    }
  }
}

.email-resend-button {
  &__row {
    flex-grow: 1;
    margin: 0.5rem 0 0 1rem;

    @media (max-width: map-get($sizes, lg)) {
      margin: 1rem 0 0 1rem;
      text-align: center;
    }
  }

  &__link {
    padding: 0.5rem 1rem;
    width: fit-content;
    border-radius: 3rem !important;

    @media (max-width: map-get($sizes, lg)) {
      padding: 0.5rem 0;
      width: 323px;
    }

    @media (max-width: map-get($sizes, md)) {
      padding: 0.5rem 0;
      max-width: 323px;
      width: 100% !important;
    }
  }

  &__group {
    flex-direction: row;
    flex: 1;
  }

  &__message {
    color: #454e50;
    font-size: 1.7rem;
    font-weight: 500;
  }
}
