@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-vulnerable-user-information {
  &__content-item {
    margin-bottom: 0;
    width: 100%;
    @media (min-width: map-get($sizes, lg)) {
      height: 134px;
      width: 670px;
    }
  }

  &__icon {
    text-align: center;
  }

  &__icon-item {
    height: 130px;
    width: 130px;
  }
}

.c-card {
  &--vulnerable-user-information {
    /* This applies from 992px */
    @media (min-width: map-get($sizes, lg)) {
      width: 904px;
      height: 235px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
