.filter {
    position: relative;
    padding: 25px 0;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        width: 30px;
        height: 100%;
        border-right: 10px solid $white;
        background: linear-gradient(to left, rgba($white, 1), rgba($white, 0));
    }

    &__col {
        @include media-breakpoint-down(md) {
            &:last-child {
                margin-bottom: 15px;
            }
        }
    }

    &__categories {
        margin: 0 -8px;
        font-size: 0;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        @include media-breakpoint-down(xs) {
            margin: 0 -35px;
            padding: 0 27px;
        }

        li {
            display: inline-block;
            font-size: 0;
            padding: 0 8px;
            margin-bottom: 5px;
        }


    }

    &__input-wrapper {
        position: relative;
    }

    &__search-form {
        position: relative;
        border-radius: 5px;
        margin-bottom: 5px;

        label {
            display: none;
        }

        input {
            flex: 1 0;
            padding: 0 40px 0 12px;
            border: 1px solid $color-quaternary;

            &:focus {
                border: 2px solid $color-tertiary;
            }
        }

        button {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 100%;
            transition: $transition;

            &:hover {
                opacity: 0.75;
            }
        }
    }
}


.live-search-wrapper {
    position: relative;

    .search-results-wrapper {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 15;
        width: 100%;
        padding: 25px 20px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background: $color-senary;

        @include media-breakpoint-down(xs) {
            padding: 25px 15px;
        }

        &.active {
            display: block;
        }

        .link-wrap {
            padding-top: 20px;
        }
    }

    .live-search-results {
        padding: 0;
        margin: 0;
        list-style: none;

        &__title {
            display: block;
            margin-bottom: 10px;
        }

        li {
            margin-bottom: 15px;
            line-height: 1.5;

            a {
                span {
                    transition: none;
                }
            }
        }

        &__not-found {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 15;
            width: 100%;
            padding: 25px 20px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: $color-senary;
            font-weight: bold;

            &.active {
                display: block;
            }
        }
    }
}

