/* Gravity Forms
================ */

.gform_wrapper {
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .gform_body {
    position: relative;
  }

  .gform_fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }

  .gfield {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 8px;
    margin-bottom: 16px;

    &.field-medium {
      flex: 0 0 50%;
      max-width: 50%;

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &.field-small {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &.show-label {
      .gfield_label {
        display: block;
      }
    }
  }

  .gfield_label {
    display: none;
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-primary;
  }

  .gfield_required {
    display: none;
  }

  .gfield_radio,
  .gfield_checkbox {
    > li {
      margin-bottom: 10px;
    }
  }

  .gform_footer {
    padding-top: 20px;
    text-align: center;
  }

  .gform_validation_container {
    display: none;
  }

  .validation_error {
    display: block;
    text-align: center;
    padding: 10px 0;
    font-size: 1.6rem;
    font-weight: 700;
    color: $fail;

    i {
      margin-right: 10px;
      vertical-align: middle;
      font-size: 2rem;
      color: $white;
    }
  }

  .validation_message {
    display: block;
    position: relative;
    z-index: 1;
    margin-top: 10px;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 1px 20px;
    border-radius: 5px;
    border: 2px solid $fail;
    color: $white;
    background: #d96d22;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -7px;
      left: 20px;
      z-index: -1;
      width: 12px;
      height: 12px;
      border-top: 2px solid $fail;
      border-right: 2px solid $fail;
      background: #d96d22;
      transform: rotate(-45deg);
    }
  }
}

.gform_confirmation_wrapper {
  max-width: 280px;
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
  line-height: 1.4;
  font-size: 2rem;
  font-weight: bold;
  color: $color-primary;

  &:before {
    content: "";
    display: block;
    width: 42px;
    height: 42px;
    margin: 0 auto 20px;
    background: url(../images/tick.svg) center no-repeat;
    background-size: contain;
  }
}

.bg-primary {
  .gform_confirmation_wrapper {
    color: $white;
  }
}

.bg-tertiary {
  .gform_confirmation_wrapper {
    color: $color-quaternary;
  }
}

.gform_ajax_spinner {
  display: none;
}

input {
  width: 100%;
  height: 45px;
  padding: 0 20px;
  border-radius: 5px !important;
  border: 2px solid $white;
  font-size: 1.6rem;
  box-sizing: border-box;
  outline: 0 !important;
  box-shadow: none !important;
  background: $white;
  appearance: none;
  color: $color-quaternary;
}

textarea {
  width: 100%;
  height: 135px;
  padding: 12px 20px;
  border-radius: 5px !important;
  border: 2px solid $white;
  font-size: 1.6rem;
  box-shadow: none !important;
  box-sizing: border-box;
  outline: 0 !important;
  background: $white;
  appearance: none;
  color: $color-quaternary;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  left: -999999px;
  cursor: pointer;
  height: auto;
  appearance: none;

  + label {
    display: block;
    position: relative;
    padding-left: 35px;
    box-sizing: border-box;
    line-height: 1.4;
    font-size: 1.6rem;
    color: $color-primary;
    cursor: pointer;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 21px;
      height: 21px;
      border: 1px solid $white;
      border-radius: 50%;
      background: $white;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  &:checked {
    + label {
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 7px;
        top: 7px;
        margin: 0;
        width: 7px;
        height: 7px;
        background: $color-secondary;
        border-radius: 50%;
        vertical-align: middle;
      }

      &:before {
        border-color: $white;
      }
    }
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  left: -999999px;
  cursor: pointer;
  height: auto;

  + label {
    display: block;
    position: relative;
    padding-left: 35px;
    box-sizing: border-box;
    font-size: 1.6rem;
    line-height: 1.4;
    color: $color-primary;
    cursor: pointer;

    &:not(.input-helper):before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0px;
      left: 0;
      width: 21px;
      height: 21px;
      border: 1px solid $white;
      border-radius: 0;
      box-sizing: border-box;
      cursor: pointer;
      background: $white;
    }
  }

  &:checked {
    + label {
      &:not(.input-helper):after {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: auto;
        margin: auto 0;
        left: 7px;
        top: 3px;
        width: 7px;
        height: 13px;
        border: solid $color-secondary;
        border-width: 0 3px 3px 0;

        transform: rotate(45deg);
      }

      &:before {
        border-color: $white;
      }
    }
  }
}

select {
  padding: 0 45px 0 15px;
  margin: 0;
  text-overflow: "";
  font-size: 1.6rem;
  border: 2px solid $white;
  border-radius: 5px !important;
  width: 100%;
  height: 45px;
  outline: 0 !important;
  appearance: none;
  background-image: url(../images/select-arrow.svg);
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-color: $white;
  color: $color-quaternary;
}

select::-ms-expand {
  display: none;
}

.bounty-form-container {
  padding: 40px;
  background-color: #ffffff;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;

  @include media-breakpoint-down(sm) {
    border-radius: 0 0 10px 10px;
  }

  &.form-width {
    max-width: 450px;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }

  form {
    height: 100%;
    position: relative;
  }

  button[type="submit"] {
    display: block;
    position: relative;
    bottom: 0;
    left: 0;
  }

  label {
    font-family: 'Brandon Text', Arial, sans-serif;
    color: #454e50;
    font-size: 16px;
    font-weight: bold;

    &.input-helper {
      display: inline;
      float: right;
      cursor: pointer;

      &::after {
        border: none;
      }

      i {
        font-size: 21px;
      }
    }
  }

  input.input-helper {
    display: none;

    ~ label.input-helper {
      div {
        display: none;
        width: 300px;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        position: absolute;
        right: 0;
        bottom: 30px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
          rgba(0, 0, 0, 0.23) 0px 6px 6px;
        background-color: white;
        border: 1px solid black;
      }
    }

    &:checked ~ label.input-helper {
      div {
        display: block;
      }
    }
  }

  a {
    font-family: 'Brandon Text', Arial, sans-serif;;
    color: #454e50;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
    display: inline-block;

    &.btn {
      color: white;
    }
  }

  input {
    border: 1px solid #454e50;
    border-top-color: rgb(69, 78, 80);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(69, 78, 80);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(69, 78, 80);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(69, 78, 80);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 5px;
    margin-bottom: 30px;
    color: #454e50;
    font-family: 'Brandon Text', Arial, sans-serif;
    font-size: 16px;
  }

  .Error {
    color: red;
  }

  .helptext {
    font-weight: 500;
  }

  .legal {
    img {
      padding-right: 20px;
    }
  }
}
