.company-structure {

    .block-heading {
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }

    .link-list {
        padding-left: 40px;

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            max-width: 100%;
        }
    }

    &__tabs {
        position: relative;
        z-index: 1;
        padding: 40px 15px 0;

        > h4 {
            margin-bottom: 50px;
        }

        &--mobile {
            .company-structure__tab-row {
                max-width: 420px;
                margin: 0 auto;
            }
        }
    }

    &__tab-row {
        justify-content: center;
    }

    &__tab-col {
        position: static;
        flex: 0 0 20%;
        max-width: 200px;
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
            margin-bottom: 15px;
        }

        &.indent-2 {
            padding-left: 35px;

            @include media-breakpoint-down(md) {
                .expand-tab {
                    &:not(.expand-tab--link-to-tab) {
                        .expand-tab__arrow--top {


                            &:after {
                                width: 30px;
                            }
                        }
                    }


                    &:before {
                        left: -29px;
                    }
                }
            }
        }

        &.indent-3 {
            padding-left: 70px;

            @include media-breakpoint-down(md) {
                .expand-tab {
                    &:not(.expand-tab--link-to-tab) {
                        .expand-tab__arrow--top {

                            &:after {
                                width: 65px;
                            }
                        }
                    }


                    &:before {
                        left: -64px;
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &:last-child {
                .expand-tab--join-line {
                    &:before {
                        display: none;
                    }

                }
            }
        }
    }
}
