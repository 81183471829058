.page-id-1038 .countdown-timer {
  margin-top: 0px;
  background-color: $color-secondary;
}

.page-id-1038 .countdown-timer.expired {
  display: none;
}

.countdown-timer {
  margin-top: 30px;
  padding: 10px 0px;
  background-color: $color-primary;
  &.expired {
    opacity: 0;
    margin-top: 0px;
    padding: 0px;
  }
  @media only screen and (min-width: 992px) {
    padding: 20px 0px 5px 0px;
    margin-top: 60px;
  }
  .countdown-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    & > div {
      margin: 0px 5px;
    }
    @media only screen and (min-width: 992px) {
      flex-direction: row;
    }
  }
  .countdown-row {
    display: flex;
    justify-content: center;
  }
  .text {
    font-size: 1.7rem;
    color: white;
    font-weight: 400;
    line-height: 2;
    margin: 0px;
    @media only screen and (min-width: 992px) {
      font-size: 2.5rem;
      margin-bottom: 25px;
    }
  }
  .slot > div:first-of-type span {
    width: 3rem;
    height: 3rem;
    background-color: white;
    display: block;
    margin: 2px;
    border-radius: 5px;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 500;
    @media only screen and (min-width: 992px) {
      width: 3.5rem;
      height: 3.5rem;
      font-size: 3rem;
      line-height: 1.15;
      font-weight: 500;
    }
  }
  .smalltext {
    font-size: 12px;
    color: white;
  }
  .space {
    line-height: 1.4;
    font-size: 2rem;
    color: white;
    @media only screen and (min-width: 992px) {
      font-size: 30px;
    }
  }
  .sm-days:before {
    content: "D";
    @media only screen and (min-width: 992px) {
      content: "Days";
    }
  }
  .sm-hours:before {
    content: "H";
    @media only screen and (min-width: 992px) {
      content: "Hours";
    }
  }
  .sm-minutes:before {
    content: "M";
    @media only screen and (min-width: 992px) {
      content: "Minutes";
    }
  }
  .sm-seconds:before {
    content: "S";
    @media only screen and (min-width: 992px) {
      content: "Seconds";
    }
  }
}
