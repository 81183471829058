.reviews {
  .section-heading {
    margin-bottom: 30px;
  }

  &__embed {
    /*Imported CSS style from reviews.io (<link rel="stylesheet" href="https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2022011214">)*/
    .CarouselWidget-prefix .CarouselWidget {
      --base-font-size: 16px;
      --base-maxwidth: 100%;
      --reviewsio-logo-style: var(--logo-normal);
      --common-star-color: #f7aa00;
      --common-star-disabled-color: #ffffff;
      --medium-star-size: 26px;
      --small-star-size: 19px;
      --x-small-star-size: 16px;
      --x-small-star-display: inline-flex;
      --header-order: 1;
      --header-width: 250px;
      --header-bg-start-color: transparent;
      --header-bg-end-color: transparent;
      --header-gradient-direction: 135deg;
      --header-padding: 1em;
      --header-border-width: 0px;
      --header-border-color: rgba(0, 0, 0, 0.1);
      --header-border-radius: 0px;
      --header-shadow-size: 0px;
      --header-shadow-color: rgba(0, 0, 0, 0.1);
      --header-star-color: #f7aa00;
      --header-disabled-star-color: #ffffff;
      --header-heading-text-color: #454e50;
      --header-heading-font-size: 26px;
      --header-heading-mobile-font-size: 30px;
      --header-heading-font-weight: inherit;
      --header-heading-line-height: inherit;
      --header-heading-text-transform: inherit;
      --header-subheading-text-color: #454e50;
      --header-subheading-font-size: 14px;
      --header-subheading-font-weight: ;
      --header-subheading-line-height: 1.4;
      --header-subheading-text-transform: capitalize;
      --item-maximum-columns: 3;
      --item-background-start-color: transparent;
      --item-background-end-color: transparent;
      --item-gradient-direction: 135deg;
      --item-padding: 0.5em;
      --item-border-width: 0px;
      --item-border-color: rgba(0, 0, 0, 0.1);
      --item-border-radius: 0px;
      --item-shadow-size: 0px;
      --item-shadow-color: rgba(0, 0, 0, 0.1);
      --heading-text-color: #454e50;
      --heading-text-font-weight: 600;
      --heading-text-font-family: inherit;
      --heading-text-line-height: 1.4;
      --heading-text-letter-spacing: 0;
      --heading-text-transform: none;
      --body-text-color: #454e50;
      --body-text-font-weight: 400;
      --body-text-font-family: inherit;
      --body-text-line-height: 1.4;
      --body-text-letter-spacing: 0;
      --body-text-transform: none;
      --scroll-button-icon-color: #9b9b9b;
      --scroll-button-icon-size: 10px;
      --scroll-button-bg-color: transparent;
      --scroll-button-border-width: 1px;
      --scroll-button-border-color: #9b9b9b;
      --scroll-button-border-radius: 50px;
      --scroll-button-shadow-size: 0px;
      --scroll-button-shadow-color: rgba(0, 0, 0, 0.1);
      --scroll-button-horizontal-position: 0px;
      --scroll-button-vertical-position: 0px;
      --badge-icon-color: #0e1311;
      --badge-icon-font-size: 15px;
      --badge-text-color: #0e1311;
      --badge-text-font-size: inherit;
      --badge-text-letter-spacing: inherit;
      --badge-text-transform: inherit;
      --author-font-size: 14px;
      --author-font-weight: inherit;
      --author-text-transform: inherit;
      --photo-video-thumbnail-size: 60px;
      --photo-video-thumbnail-border-radius: 0px;
      --popup-backdrop-color: rgba(0, 0, 0, 0.75);
      --popup-color: #ffffff;
      --popup-star-color: inherit;
      --popup-disabled-star-color: inherit;
      --popup-heading-text-color: inherit;
      --popup-body-text-color: inherit;
      --popup-badge-icon-color: inherit;
      --popup-badge-icon-font-size: 19px;
      --popup-badge-text-color: inherit;
      --popup-badge-text-font-size: 14px;
      --popup-border-width: 0px;
      --popup-border-color: rgba(0, 0, 0, 0.1);
      --popup-border-radius: 0px;
      --popup-shadow-size: 0px;
      --popup-shadow-color: rgba(0, 0, 0, 0.1);
      --popup-icon-color: #0e1311;
      --tooltip-bg-color: #0e1311;
      --tooltip-text-color: #ffffff;
    }

    .R-ReviewsList__item--body {
      -webkit-line-clamp: var(--body-max-lines, 6) !important;
    }
    .CarouselWidget-prefix {
      text-align: center;
    }

    .CarouselWidget-prefix .R-flex-wrapper {
      box-sizing: border-box;
      max-width: 1240px;
      margin: 0 auto;
    }

    .CarouselWidget-prefix .R-flex-container-fluid {
      margin-right: auto;
      margin-left: auto;
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .CarouselWidget-prefix .R-flex-row {
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
    }
    .CarouselWidget-prefix .R-flex-row--noMargin {
      margin-left: 0;
      margin-right: 0;
    }
    .CarouselWidget-prefix .R-flex-row.R-flex-reverse {
      flex-direction: row-reverse;
    }
    .CarouselWidget-prefix .R-flex-col.R-flex-reverse {
      flex-direction: column-reverse;
    }
    .CarouselWidget-prefix .R-flex-col-xs {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: auto;
    }

    .CarouselWidget-prefix .R-flex-col-xs-1 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 8.33333%;
      max-width: 8.33333%;
    }

    .CarouselWidget-prefix .R-flex-col-xs-2 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 16.66667%;
      max-width: 16.66667%;
    }

    .CarouselWidget-prefix .R-flex-col-xs-3 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 25%;
      max-width: 25%;
    }

    .CarouselWidget-prefix .R-flex-col-xs-4 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
    }

    .CarouselWidget-prefix .R-flex-col-xs-5 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 41.66667%;
      max-width: 41.66667%;
    }

    .CarouselWidget-prefix .R-flex-col-xs-6 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 50%;
      max-width: 50%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-7 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 58.33333%;
      max-width: 58.33333%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-8 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 66.66667%;
      max-width: 66.66667%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-9 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 75%;
      max-width: 75%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-10 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 83.33333%;
      max-width: 83.33333%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-11 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 91.66667%;
      max-width: 91.66667%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-12 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 100%;
      max-width: 100%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-0 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 0;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-1 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 8.33333%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-2 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 16.66667%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-3 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 25%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-4 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 33.33333%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-5 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 41.66667%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-6 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 50%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-7 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 58.33333%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-8 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 66.66667%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-9 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 75%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-10 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 83.33333%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-11 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 91.66667%;
    }
    .CarouselWidget-prefix .R-flex-col-xs-offset-12 {
      box-sizing: border-box;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-left: 100%;
    }
    .CarouselWidget-prefix .R-flex-col-xs {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
    .CarouselWidget-prefix .R-flex-start-xs {
      justify-content: flex-start;
      text-align: left;
    }
    .CarouselWidget-prefix .R-flex-center-xs {
      justify-content: center;
      text-align: center;
    }
    .CarouselWidget-prefix .R-flex-end-xs {
      justify-content: flex-end;
      text-align: right;
    }
    .CarouselWidget-prefix .R-flex-top-xs {
      align-items: flex-start;
    }
    .CarouselWidget-prefix .R-flex-middle-xs {
      align-items: center;
    }
    .CarouselWidget-prefix .R-flex-bottom-xs {
      align-items: flex-end;
    }
    .CarouselWidget-prefix .R-flex-baseline-xs {
      align-items: baseline;
    }
    .CarouselWidget-prefix .R-flex-around-xs {
      justify-content: space-around;
    }
    .CarouselWidget-prefix .R-flex-between-xs {
      justify-content: space-between;
    }
    .CarouselWidget-prefix .R-flex-first-xs {
      order: -1;
    }
    .CarouselWidget-prefix .R-flex-last-xs {
      order: 1;
    }

    @media only screen and (min-width: 12.5em) {
      .CarouselWidget-prefix .R-flex-container {
        width: 11.98rem;
      }
      .CarouselWidget-prefix .R-flex-col-xxs {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: auto;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 25%;
        max-width: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 50%;
        max-width: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 75%;
        max-width: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 100%;
        max-width: 100%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-0 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 0;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xxs-offset-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 100%;
      }
      .CarouselWidget-prefix .R-flex-start-xxs {
        justify-content: flex-start;
        text-align: left;
      }
      .CarouselWidget-prefix .R-flex-center-xxs {
        justify-content: center;
        text-align: center;
      }
      .CarouselWidget-prefix .R-flex-end-xxs {
        justify-content: flex-end;
        text-align: right;
      }
      .CarouselWidget-prefix .R-flex-top-xxs {
        align-items: flex-start;
      }
      .CarouselWidget-prefix .R-flex-middle-xxs {
        align-items: center;
      }
      .CarouselWidget-prefix .R-flex-bottom-xxs {
        align-items: flex-end;
      }
      .CarouselWidget-prefix .R-flex-baseline-xxs {
        align-items: baseline;
      }
      .CarouselWidget-prefix .R-flex-around-xxs {
        justify-content: space-around;
      }
      .CarouselWidget-prefix .R-flex-between-xxs {
        justify-content: space-between;
      }
      .CarouselWidget-prefix .R-flex-first-xxs {
        order: -1;
      }
      .CarouselWidget-prefix .R-flex-last-xxs {
        order: 1;
      }
    }
    @media only screen and (min-width: 30em) {
      .CarouselWidget-prefix .R-flex-container {
        width: 28.75rem;
      }
      .CarouselWidget-prefix .R-flex-col-xs {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: auto;
      }
      .CarouselWidget-prefix .R-flex-col-xs-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 25%;
        max-width: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 50%;
        max-width: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 75%;
        max-width: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 100%;
        max-width: 100%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-0 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 0;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-xs-offset-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 100%;
      }
      .CarouselWidget-prefix .R-flex-start-xs {
        justify-content: flex-start;
        text-align: left;
      }
      .CarouselWidget-prefix .R-flex-center-xs {
        justify-content: center;
        text-align: center;
      }
      .CarouselWidget-prefix .R-flex-end-xs {
        justify-content: flex-end;
        text-align: right;
      }
      .CarouselWidget-prefix .R-flex-top-xs {
        align-items: flex-start;
      }
      .CarouselWidget-prefix .R-flex-middle-xs {
        align-items: center;
      }
      .CarouselWidget-prefix .R-flex-bottom-xs {
        align-items: flex-end;
      }
      .CarouselWidget-prefix .R-flex-baseline-xs {
        align-items: baseline;
      }
      .CarouselWidget-prefix .R-flex-around-xs {
        justify-content: space-around;
      }
      .CarouselWidget-prefix .R-flex-between-xs {
        justify-content: space-between;
      }
      .CarouselWidget-prefix .R-flex-first-xs {
        order: -1;
      }
      .CarouselWidget-prefix .R-flex-last-xs {
        order: 1;
      }
    }

    @media only screen and (min-width: 48em) {
      .CarouselWidget-prefix .R-flex-container {
        width: 46rem;
      }
      .CarouselWidget-prefix .R-flex-col-sm {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: auto;
      }
      .CarouselWidget-prefix .R-flex-col-sm-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 25%;
        max-width: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 50%;
        max-width: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 75%;
        max-width: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 100%;
        max-width: 100%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-0 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 0;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-sm-offset-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 100%;
      }
      .CarouselWidget-prefix .R-flex-start-sm {
        justify-content: flex-start;
        text-align: left;
      }
      .CarouselWidget-prefix .R-flex-center-sm {
        justify-content: center;
        text-align: center;
      }
      .CarouselWidget-prefix .R-flex-end-sm {
        justify-content: flex-end;
        text-align: right;
      }
      .CarouselWidget-prefix .R-flex-top-sm {
        align-items: flex-start;
      }
      .CarouselWidget-prefix .R-flex-middle-sm {
        align-items: center;
      }
      .CarouselWidget-prefix .R-flex-bottom-sm {
        align-items: flex-end;
      }
      .CarouselWidget-prefix .R-flex-baseline-sm {
        align-items: baseline;
      }
      .CarouselWidget-prefix .R-flex-around-sm {
        justify-content: space-around;
      }
      .CarouselWidget-prefix .R-flex-between-sm {
        justify-content: space-between;
      }
      .CarouselWidget-prefix .R-flex-first-sm {
        order: -1;
      }
      .CarouselWidget-prefix .R-flex-last-sm {
        order: 1;
      }
    }
    @media only screen and (min-width: 62em) {
      .CarouselWidget-prefix .R-flex-container {
        width: 61rem;
      }
      .CarouselWidget-prefix .R-flex-col-md {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: auto;
      }
      .CarouselWidget-prefix .R-flex-col-md-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 25%;
        max-width: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-md-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 50%;
        max-width: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-md-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 75%;
        max-width: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-md-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 100%;
        max-width: 100%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-0 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 0;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-md-offset-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 100%;
      }
      .CarouselWidget-prefix .R-flex-start-md {
        justify-content: flex-start;
        text-align: left;
      }
      .CarouselWidget-prefix .R-flex-center-md {
        justify-content: center;
        text-align: center;
      }
      .CarouselWidget-prefix .R-flex-end-md {
        justify-content: flex-end;
        text-align: right;
      }
      .CarouselWidget-prefix .R-flex-top-md {
        align-items: flex-start;
      }
      .CarouselWidget-prefix .R-flex-middle-md {
        align-items: center;
      }
      .CarouselWidget-prefix .R-flex-bottom-md {
        align-items: flex-end;
      }
      .CarouselWidget-prefix .R-flex-baseline-md {
        align-items: baseline;
      }
      .CarouselWidget-prefix .R-flex-around-md {
        justify-content: space-around;
      }
      .CarouselWidget-prefix .R-flex-between-md {
        justify-content: space-between;
      }
      .CarouselWidget-prefix .R-flex-first-md {
        order: -1;
      }
      .CarouselWidget-prefix .R-flex-last-md {
        order: 1;
      }
    }

    @media only screen and (min-width: 75em) {
      .CarouselWidget-prefix .R-flex-container {
        width: 71rem;
      }
      .CarouselWidget-prefix .R-flex-col-lg {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: auto;
      }
      .CarouselWidget-prefix .R-flex-col-lg-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 8.33333%;
        max-width: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 16.66667%;
        max-width: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 25%;
        max-width: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 50%;
        max-width: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 58.33333%;
        max-width: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 66.66667%;
        max-width: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 75%;
        max-width: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 83.33333%;
        max-width: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 91.66667%;
        max-width: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-basis: 100%;
        max-width: 100%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-0 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 0;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-1 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 8.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-2 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 16.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-3 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 25%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-4 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 33.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-5 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 41.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-6 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 50%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-7 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 58.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-8 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 66.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-9 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 75%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-10 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 83.33333%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-11 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 91.66667%;
      }
      .CarouselWidget-prefix .R-flex-col-lg-offset-12 {
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-left: 100%;
      }
      .CarouselWidget-prefix .R-flex-start-lg {
        justify-content: flex-start;
        text-align: left;
      }
      .CarouselWidget-prefix .R-flex-center-lg {
        justify-content: center;
        text-align: center;
      }
      .CarouselWidget-prefix .R-flex-end-lg {
        justify-content: flex-end;
        text-align: right;
      }
      .CarouselWidget-prefix .R-flex-top-lg {
        align-items: flex-start;
      }
      .CarouselWidget-prefix .R-flex-middle-lg {
        align-items: center;
      }
      .CarouselWidget-prefix .R-flex-bottom-lg {
        align-items: flex-end;
      }
      .CarouselWidget-prefix .R-flex-baseline-lg {
        align-items: baseline;
      }
      .CarouselWidget-prefix .R-flex-around-lg {
        justify-content: space-around;
      }
      .CarouselWidget-prefix .R-flex-between-lg {
        justify-content: space-between;
      }
      .CarouselWidget-prefix .R-flex-first-lg {
        order: -1;
      }
      .CarouselWidget-prefix .R-flex-last-lg {
        order: 1;
      }
    }
    .CarouselWidget-prefix .u-marginBottom--none {
      margin-bottom: 0 !important;
    }
    .CarouselWidget-prefix .u-marginBottom--xs {
      margin-bottom: 5px !important;
    }
    .CarouselWidget-prefix .u-marginBottom--sm {
      margin-bottom: 10px !important;
    }
    .CarouselWidget-prefix .u-marginBottom--md {
      margin-bottom: 20px !important;
    }
    .CarouselWidget-prefix .u-marginBottom--lg {
      margin-bottom: 30px !important;
    }
    .CarouselWidget-prefix .u-marginBottom--xlg {
      margin-bottom: 50px !important;
    }
    .CarouselWidget-prefix .u-marginBottom--xxlg {
      margin-bottom: 70px !important;
    }
    .CarouselWidget-prefix .u-marginBottom--xxxlg {
      margin-bottom: 100px !important;
    }
    .CarouselWidget-prefix .u-marginTop--none {
      margin-top: 0 !important;
    }
    .CarouselWidget-prefix .u-marginTop--xs {
      margin-top: 5px !important;
    }
    .CarouselWidget-prefix .u-marginTop--sm {
      margin-top: 10px !important;
    }
    .CarouselWidget-prefix .u-marginTop--md {
      margin-top: 20px !important;
    }
    .CarouselWidget-prefix .u-marginTop--lg {
      margin-top: 30px !important;
    }

    @media screen and (min-width: 993px) {
      .CarouselWidget-prefix .u-marginTop--lg--desktop--sm {
        margin-top: 30px !important;
      }
    }
    .CarouselWidget-prefix .u-marginTop--xlg {
      margin-top: 50px !important;
    }
    .CarouselWidget-prefix .u-marginTop--xxlg {
      margin-top: 70px !important;
    }
    .CarouselWidget-prefix .u-marginTop--xxxlg {
      margin-top: 100px !important;
    }
    .CarouselWidget-prefix .u-marginRight--none {
      margin-right: 0 !important;
    }
    .CarouselWidget-prefix .u-marginRight--xs {
      margin-right: 5px !important;
    }
    .CarouselWidget-prefix .u-marginRight--sm {
      margin-right: 10px !important;
    }
    .CarouselWidget-prefix .u-marginRight--md {
      margin-right: 20px !important;
    }
    .CarouselWidget-prefix .u-marginRight--lg {
      margin-right: 30px !important;
    }
    .CarouselWidget-prefix .u-marginLeft--neg--xxs {
      margin-left: -3px;
    }
    .CarouselWidget-prefix .u-marginLeft--neg--xs {
      margin-left: -5px;
    }
    .CarouselWidget-prefix .u-marginLeft--none {
      margin-left: 0 !important;
    }
    .CarouselWidget-prefix .u-marginLeft--xs {
      margin-left: 5px !important;
    }
    .CarouselWidget-prefix .u-marginLeft--sm {
      margin-left: 10px !important;
    }
    .CarouselWidget-prefix .u-marginLeft--md {
      margin-left: 20px !important;
    }
    .CarouselWidget-prefix .u-marginLeft--lg {
      margin-left: 30px !important;
    }
    .CarouselWidget-prefix .u-paddingBottom--none {
      padding-bottom: 0 !important;
    }
    .CarouselWidget-prefix .u-paddingBottom--sm {
      padding-bottom: 10px !important;
    }
    .CarouselWidget-prefix .u-paddingBottom--md {
      padding-bottom: 20px !important;
    }
    .CarouselWidget-prefix .u-paddingBottom--lg {
      padding-bottom: 30px !important;
    }
    .CarouselWidget-prefix .u-paddingBottom--xlg {
      padding-bottom: 50px !important;
    }
    .CarouselWidget-prefix .u-paddingBottom--xxlg {
      padding-bottom: 70px !important;
    }
    .CarouselWidget-prefix .u-paddingBottom--xxxlg {
      padding-bottom: 100px !important;
    }
    .CarouselWidget-prefix .u-paddingTop--none {
      padding-top: 0 !important;
    }
    .CarouselWidget-prefix .u-paddingTop--sm {
      padding-top: 10px !important;
    }
    .CarouselWidget-prefix .u-paddingTop--md {
      padding-top: 20px !important;
    }
    .CarouselWidget-prefix .u-paddingTop--lg {
      padding-top: 30px !important;
    }
    .CarouselWidget-prefix .u-paddingTop--xlg {
      padding-top: 50px !important;
    }
    .CarouselWidget-prefix .u-paddingTop--xxlg {
      padding-top: 70px !important;
    }
    .CarouselWidget-prefix .u-paddingTop--xxxlg {
      padding-top: 100px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--none {
      padding-right: 0 !important;
    }
    .CarouselWidget-prefix .u-paddingRight--xxs {
      padding-right: 2px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--xs {
      padding-right: 5px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--sm {
      padding-right: 10px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--md {
      padding-right: 20px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--lg {
      padding-right: 30px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--xlg {
      padding-right: 50px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--xxlg {
      padding-right: 70px !important;
    }
    .CarouselWidget-prefix .u-paddingRight--xxxlg {
      padding-right: 100px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--none {
      padding-left: 0 !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--xxs {
      padding-left: 2px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--xs {
      padding-left: 5px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--sm {
      padding-left: 10px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--md {
      padding-left: 20px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--lg {
      padding-left: 30px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--xlg {
      padding-left: 50px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--xxlg {
      padding-left: 70px !important;
    }
    .CarouselWidget-prefix .u-paddingLeft--xxxlg {
      padding-left: 100px !important;
    }
    .CarouselWidget-prefix .u-textLeft {
      text-align: left !important;
    }
    .CarouselWidget-prefix .u-textCenter {
      text-align: center !important;
    }
    .CarouselWidget-prefix .u-textCenter--all {
      text-align: center !important;
    }

    @media (min-width: 993px) {
      .CarouselWidget-prefix .u-textCenter--desktop--sm {
        text-align: center !important;
      }
    }

    @media (max-width: 992px) {
      .CarouselWidget-prefix .u-textCenter--tablet--lg {
        text-align: center !important;
      }
    }
    @media (max-width: 768px) {
      .CarouselWidget-prefix .u-textCenter--tablet--md {
        text-align: center !important;
      }
    }
    @media (max-width: 767px) {
      .CarouselWidget-prefix .u-textCenter--tablet--sm {
        text-align: center !important;
      }
    }
    @media (max-width: 640px) {
      .CarouselWidget-prefix .u-textCenter--phone--lg {
        text-align: center !important;
      }
    }
    @media (max-width: 480px) {
      .CarouselWidget-prefix .u-textCenter--phone--md {
        text-align: center !important;
      }
    }
    .CarouselWidget-prefix .u-textRight {
      text-align: right !important;
    }
    .CarouselWidget-prefix .u-textRight--all {
      text-align: right !important;
    }
    .CarouselWidget-prefix .u-textLeft--all {
      text-align: left !important;
    }
    .CarouselWidget-prefix .u-hidden--all {
      display: none !important;
    }
    @media (max-width: 640px) {
      .CarouselWidget-prefix .u-hidden--phone--lg {
        display: none !important;
      }
    }
    @media (max-width: 768px) {
      .CarouselWidget-prefix .u-hidden--tablet--md {
        display: none !important;
      }
    }
    @media (max-width: 992px) {
      .CarouselWidget-prefix .u-hidden--tablet--lg {
        display: none !important;
      }
    }
    .CarouselWidget-prefix .u-visible--phone--lg {
      display: none !important;
    }
    @media (max-width: 640px) {
      .CarouselWidget-prefix .u-visible--phone--lg {
        display: inline-block !important;
      }
    }
    .CarouselWidget-prefix .u-visible--phone--lg--block {
      display: none !important;
    }
    @media (max-width: 640px) {
      .CarouselWidget-prefix .u-visible--phone--lg--block {
        display: block !important;
      }
    }
    .CarouselWidget-prefix .u-visible--tablet--md {
      display: none !important;
    }
    @media (max-width: 768px) {
      .CarouselWidget-prefix .u-visible--tablet--md {
        display: inline-block !important;
      }
    }
    .CarouselWidget-prefix .u-visible--tablet--lg {
      display: none !important;
    }
    @media (max-width: 992px) {
      .CarouselWidget-prefix .u-visible--tablet--lg {
        display: inline-block !important;
      }
    }
    .CarouselWidget-prefix .u-visible--desktop--sm {
      display: none !important;
    }
    @media (min-width: 993px) {
      .CarouselWidget-prefix .u-visible--desktop--sm {
        display: inline-block !important;
      }
    }
    .CarouselWidget-prefix .u-whiteSpace--prewrap {
      white-space: pre-wrap;
      word-break: break-word;
    }
    .CarouselWidget-prefix .u-overflow--hidden {
      overflow: hidden !important;
    }
    .CarouselWidget-prefix .u-textCapitalized {
      text-transform: capitalize;
    }
    .CarouselWidget-prefix .u-textLowercase {
      text-transform: lowercase;
    }
    .CarouselWidget-prefix .u-cursorPointer {
      cursor: pointer !important;
    }
    .CarouselWidget-prefix .u-cursorDefault {
      cursor: default !important;
    }
    .CarouselWidget-prefix .u-wordBreak--wordBreak {
      word-break: break-word !important;
    }
    .CarouselWidget-prefix .u-wordBreak--wordAll {
      word-break: break-all !important;
    }
    .CarouselWidget-prefix .u-textOverflow--elipsis {
      text-overflow: ellipsis;
    }
    .CarouselWidget-prefix .u-positionRelative {
      position: relative !important;
    }
    .CarouselWidget-prefix .u-positionStatic {
      position: static !important;
    }
    .CarouselWidget-prefix .u-hr {
      display: block;
      height: 1px;
      border-width: var(--common-border-width, 1px);
      border-style: solid;
      border-color: var(--common-border-color, rgba(0, 0, 0, 0.15));
      margin-top: 10px;
      margin-bottom: 10px;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .u-hr {
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.15);
      }
    }
    .CarouselWidget-prefix .u-hr--dashed {
      border-width: var(--common-border-width, 1px);
      border-style: dashed;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .u-hr--dashed {
        border-width: 1px;
      }
    }
    .CarouselWidget-prefix .u-hr--margin-sm {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .CarouselWidget-prefix .u-hr--margin-md {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .CarouselWidget-prefix .u-hr--margin-lg {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .CarouselWidget-prefix .u-displayFlex {
      display: flex;
    }
    .CarouselWidget-prefix .u-displayInlineFlex {
      display: inline-flex;
    }
    .CarouselWidget-prefix .u-flexJustifyContent--center,
    .CarouselWidget-prefix .u-justifyContent--center {
      justify-content: center !important;
    }
    .CarouselWidget-prefix .u-flexJustifyContent--between {
      justify-content: space-between !important;
    }
    .CarouselWidget-prefix .u-flexJustifyContent--start {
      justify-content: flex-start !important;
    }
    .CarouselWidget-prefix .u-flexAlignItems--stretch {
      align-items: stretch !important;
    }
    .CarouselWidget-prefix .u-flexAlignItems--center {
      align-items: center !important;
    }
    .CarouselWidget-prefix .u-flexDirection--row {
      flex-direction: row !important;
    }
    .CarouselWidget-prefix .u-flexDirection--column {
      flex-direction: column !important;
    }
    .CarouselWidget-prefix .u-flexWrap--wrap {
      flex-wrap: wrap;
    }
    .CarouselWidget-prefix .u-flexWrap--noWrap {
      flex-wrap: nowrap;
    }
    .CarouselWidget-prefix .u-flexGrow {
      flex-grow: 1;
    }
    .CarouselWidget-prefix .u-height--100 {
      height: 100%;
    }
    .CarouselWidget-prefix .u-width--100 {
      width: 100%;
    }
    .CarouselWidget-prefix .u-maxWidth--240 {
      width: 100%;
      max-width: 240px;
    }
    .CarouselWidget-prefix .u-maxWidth--320 {
      width: 100%;
      max-width: 320px;
    }
    .CarouselWidget-prefix .u-maxWidth--380 {
      width: 100%;
      max-width: 380px;
    }
    .CarouselWidget-prefix .u-maxWidth--480 {
      width: 100%;
      max-width: 480px;
    }
    .CarouselWidget-prefix .u-maxWidth--640 {
      width: 100%;
      max-width: 640px;
    }
    .CarouselWidget-prefix .u-maxWidth--768 {
      width: 100%;
      max-width: 768px;
    }

    @media screen and (min-width: 768px) {
      .CarouselWidget-prefix .u-scrollableMaxHeight--480--desktop {
        max-height: 480px;
        overflow-y: auto;
      }
    }
    .CarouselWidget-prefix .u-displayBlock {
      display: block !important;
    }
    .CarouselWidget-prefix .u-inlineBlock,
    .CarouselWidget-prefix .u-displayInlineBlock {
      display: inline-block;
    }
    .CarouselWidget-prefix .u-verticalAlign--middle {
      vertical-align: middle !important;
    }
    .CarouselWidget-prefix .u-verticalAlign--top {
      vertical-align: top !important;
    }
    .CarouselWidget-prefix .u-pointerEvents--none {
      pointer-events: none !important;
    }
    .CarouselWidget-prefix .u-floatRight {
      float: right;
    }
    .CarouselWidget-prefix .u-floatLeft {
      float: left;
    }
    .CarouselWidget-prefix .u-removeWhitespace {
      font-size: 0;
    }
    .CarouselWidget-prefix .u-isDisabled {
      filter: grayscale(1);
      opacity: 0.3;
      cursor: default;
    }
    .CarouselWidget-prefix .u-textDecoration--underline {
      text-decoration: underline;
    }
    @keyframes GlobalLoaderAnimation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .CarouselWidget-prefix .R-GlobalLoader {
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 0;
    border-radius: 100%;
    border: 3px solid;
    border-top-color: rgba(0, 0, 0, 0.15);
    border-bottom-color: transparent;
    border-left-color: rgba(0, 0, 0, 0.15);
    border-right-color: transparent;
    animation: GlobalLoaderAnimation 0.7s ease-in-out infinite;
  }
  .CarouselWidget-prefix .R-GlobalLoader--sm {
    width: 25px;
    height: 25px;
    border-width: 3px;
  }
  .CarouselWidget-prefix .R-StarLoader {
    display: inline-block;
    max-width: 320px;
    width: 100%;
    position: relative;
    text-align: center;
    display: none;
    margin-top: 50px;
    min-height: 170px;
  }
  .CarouselWidget-prefix .R-StarLoader.isActive {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-StarLoader .R-StarLoader__svg {
    width: 60px;
  }
  .CarouselWidget-prefix .R-StarLoader .R-StarLoader__description {
    margin-top: -5px;
    display: none;
  }
  .CarouselWidget-prefix
    .R-StarLoader
    .R-StarLoader__messageSlider
    .messageSlider__item {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.25s, opacity 0.25s;
  }
  .CarouselWidget-prefix
    .R-StarLoader
    .R-StarLoader__messageSlider
    .messageSlider__item.isVisible {
    max-height: 80px;
    opacity: 1;
    transition: max-height 0.25s, opacity 1s;
  }
  .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link {
    position: relative;
    display: inline-block;
    background-color: rgba(17, 177, 167, 0.2);
    font-weight: 500;
    color: #11b1a7;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link .link__icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    margin-left: 0;
  }
  .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link--underline {
    padding-bottom: 2px;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
  .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link--underline:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #11b1a7;
  }
  .CarouselWidget-prefix .R-StarLoader .R-StarLoader__emoji {
    font-size: 18px;
    position: relative;
    top: 2px;
  }
  .CarouselWidget-prefix .R-StarLoader .outer {
    fill: transparent;
    stroke: #fff;
    stroke-width: 6px;
    stroke-dasharray: 200;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: R-draw 4s infinite;
  }
  .CarouselWidget-prefix .R-StarLoader .inner {
    fill: #fff;
    transform-origin: center;
    animation: R-fadeIn 4s infinite;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-StarLoader .inner {
      display: none;
    }
  }
  @keyframes R-fadeIn {
    0% {
      opacity: 1;
      transform: scale(4.95) translate(44%, 43.9%);
    }
    50% {
      opacity: 0.8;
      transform: scale(4.25) translate(44%, 43.9%);
    }
    100% {
      opacity: 1;
      transform: scale(4.95) translate(44%, 43.9%);
    }
  }
  @keyframes R-draw {
    0% {
      transform: scale(0.9);
      stroke-dashoffset: 0;
    }
    50% {
      opacity: 0.8;
      transform: scale(0.8);
      stroke-dashoffset: 1000;
    }
    100% {
      transform: scale(0.9);
      stroke-dashoffset: 0;
    }
  }
  .CarouselWidget-prefix .R-StarLoader--dark .outer {
    stroke: #0e1311;
  }
  .CarouselWidget-prefix .R-StarLoader--dark .inner {
    fill: #0e1311;
  }
  .CarouselWidget-prefix .R-StarLoader--autoHeight {
    min-height: auto;
    margin-top: 0;
  }
  @keyframes ColouredProgressBar {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: 0;
    }
  }
  .CarouselWidget-prefix .R-ColouredProgressBar {
    width: 100%;
    max-width: 260px;
    height: 8px;
    background: rgba(22, 23, 43, 0.15);
    border-radius: 3px;
    overflow: hidden;
  }
  .CarouselWidget-prefix .R-ColouredProgressBar__inner {
    display: block;
    height: 100%;
    background: linear-gradient(
      90deg,
      #5ddab1,
      #18c1b6 34%,
      #7563e1 51%,
      #5ddab1 68%,
      #18c1b6
    );
    background-size: 300% 100%;
    animation: ColouredProgressBar 2s linear infinite;
  }
  .CarouselWidget-prefix .R-TextHeading {
    display: block;
    font-family: var(--heading-text-font-family, inherit);
    font-size: 1.75em;
    line-height: var(--heading-text-line-height, 1.4);
    font-weight: var(--heading-text-font-weight, 600);
    letter-spacing: var(--heading-text-letter-spacing, 0);
    text-transform: var(--heading-text-transform, none);
    color: var(--heading-text-color, #0e1311);
    text-align: left;
    margin-top: 0;
    margin-bottom: 1.25em;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-TextHeading {
      font-family: inherit;
      font-weight: 600;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-TextHeading--xxxxxs {
    font-size: 0.6875em;
    margin-bottom: 0.3125em;
  }
  .CarouselWidget-prefix .R-TextHeading--xxxxs {
    font-size: 0.875em;
    margin-bottom: 0.3125em;
  }
  .CarouselWidget-prefix .R-TextHeading--xxxs {
    font-size: 0.875em;
    margin-bottom: 0.3125em;
  }
  .CarouselWidget-prefix .R-TextHeading--xxs {
    font-size: 1.5em;
    margin-bottom: 0.3125em;
  }
  .CarouselWidget-prefix .R-TextHeading--xs {
    font-size: 1.125em;
    margin-bottom: 0.625em;
  }
  @media (max-width: 640px) {
    .CarouselWidget-prefix .R-TextHeading--xs {
      font-size: 1em;
    }
  }
  .CarouselWidget-prefix .R-TextHeading--sm {
    font-size: 1.25em;
  }
  @media (max-width: 640px) {
    .CarouselWidget-prefix .R-TextHeading--sm {
      font-size: 1.125em;
    }
  }
  .CarouselWidget-prefix .R-TextHeading--md {
    font-size: 1.625em;
  }
  @media (max-width: 640px) {
    .CarouselWidget-prefix .R-TextHeading--md {
      font-size: 1.375em;
    }
  }
  .CarouselWidget-prefix .R-TextHeading--lg {
    font-size: 2.25em;
  }
  @media (max-width: 640px) {
    .CarouselWidget-prefix .R-TextHeading--lg {
      font-size: 1.75em;
    }
  }
  .CarouselWidget-prefix .R-TextHeading--xlg {
    font-size: 2.625em;
  }
  .CarouselWidget-prefix .R-TextHeading--inline {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-TextHeading--white {
    color: #fff;
  }
  .CarouselWidget-prefix .R-TextHeading--light {
    color: rgba(255, 255, 255, 0.85);
  }
  .CarouselWidget-prefix .R-TextHeading--semiBold {
    font-weight: 600;
  }
  .CarouselWidget-prefix .R-TextBody {
    display: block;
    font-family: var(--body-text-font-family, inherit);
    font-size: 1.125em;
    line-height: var(--body-text-line-height, 1.4);
    font-weight: var(--body-text-font-weight, 400);
    letter-spacing: var(--body-text-letter-spacing, 0);
    color: var(--body-text-color, #0e1311);
    text-transform: var(--body-text-transform, none);
    text-align: left;
    margin-top: 0;
    margin-bottom: 1.25em;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-TextBody {
      font-family: inherit;
      font-weight: 400;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-TextBody--xxxxs {
    font-size: 0.6875em;
    margin-bottom: 0.25em;
  }
  .CarouselWidget-prefix .R-TextBody--xxxs {
    font-size: 0.75em;
    margin-bottom: 0.3125em;
  }
  .CarouselWidget-prefix .R-TextBody--xxs {
    font-size: 0.875em;
    margin-bottom: 0.625em;
    text-transform: capitalize;
  }
  .CarouselWidget-prefix .R-TextBody--xs {
    font-size: 0.9375em;
    margin-bottom: 0.9375em;
  }
  .CarouselWidget-prefix .R-TextBody--sm {
    font-size: 1em;
  }
  .CarouselWidget-prefix .R-TextBody--md {
    font-size: 1.125em;
  }
  .CarouselWidget-prefix .R-TextBody--lg {
    font-size: 1.375em;
  }
  .CarouselWidget-prefix .R-TextBody--xlg {
    font-size: 1.625em;
  }
  .CarouselWidget-prefix .R-TextBody--inline {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-TextBody--white {
    color: #fff;
  }
  .CarouselWidget-prefix .R-TextBody--light {
    color: rgba(255, 255, 255, 0.85);
  }
  .CarouselWidget-prefix .R-TextBody--colourDanger {
    color: #e7656e;
  }
  .CarouselWidget-prefix .TextList {
    list-style-type: disc;
    padding: 0 0 0 20px;
  }
  .CarouselWidget-prefix .TextList .TextList__item {
    position: relative;
  }
  .CarouselWidget-prefix .TextList .TextList__item--noBullet {
    list-style-type: none;
  }
  .CarouselWidget-prefix .TextList .R-TextBody--sm {
    margin-bottom: 15px;
  }
  .CarouselWidget-prefix .TextList--withCheckmarks {
    padding-left: 0;
  }
  .CarouselWidget-prefix .TextList--withCheckmarks .TextList__item {
    padding-left: 30px;
    list-style-type: none;
  }
  .CarouselWidget-prefix .TextList--withCheckmarks .TextList__item .item__icon {
    position: absolute;
    left: 3px;
    top: 3px;
    color: #fff;
    background-color: #11b1a7;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 60px;
  }
  .CarouselWidget-prefix .TextList--numbered {
    list-style-type: decimal;
  }
  .CarouselWidget-prefix .UnderlineText {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
  }
  .CarouselWidget-prefix .UnderlineText:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: #11b1a7;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  }
  .CarouselWidget-prefix .UnderlineText--black:after {
    background-color: #0e1311;
  }
  .CarouselWidget-prefix .R-Button {
    display: inline-block;
    vertical-align: top;
    font-family: var(--common-button-font-family, inherit);
    font-size: var(--common-button-font-size, 16px);
    font-weight: var(--common-button-font-weight, 500);
    line-height: 1.6;
    letter-spacing: var(--common-button-letter-spacing, 0);
    color: var(--primary-button-text-color, #fff);
    background-color: var(--primary-button-bg-color, #0e1311);
    padding: var(--common-button-vertical-padding)
      var(--common-button-horizontal-padding);
    border: var(--common-button-border-width) solid
      var(--primary-button-border-color);
    border-radius: var(--common-button-border-radius);
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
    text-transform: var(--common-button-text-transform);
    text-decoration: none;
    transition: all 0.15s ease-in-out;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-Button {
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0;
      color: #fff;
      background-color: #0e1311;
      padding: 10px 25px;
      border: 2px solid #0e1311;
      border-radius: 0;
      text-transform: none;
    }
  }
  .CarouselWidget-prefix .R-Button:hover,
  .CarouselWidget-prefix .R-Button:active,
  .CarouselWidget-prefix .R-Button:focus {
    background-color: var(--primary-button-bg-color);
    color: var(--primary-button-text-color);
    text-decoration: none;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-Button:hover,
    .CarouselWidget-prefix .R-Button:active,
    .CarouselWidget-prefix .R-Button:focus {
      background-color: #0e1311;
      color: #fff;
    }
  }
  .CarouselWidget-prefix .R-Button .R-Button__text {
    display: inline-block;
    vertical-align: top;
  }
  .CarouselWidget-prefix .R-Button .R-Button__icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
  }
  .CarouselWidget-prefix .R-Button .R-Button__icon--left {
    margin-left: 0;
    margin-right: 3px;
  }
  .CarouselWidget-prefix .R-Button--xxs {
    font-size: 11px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
  .CarouselWidget-prefix .R-Button--xs {
    font-size: 12px;
    padding: 8px 15px;
    margin-bottom: 10px;
  }
  .CarouselWidget-prefix .R-Button--sm {
    font-size: 14px;
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  .CarouselWidget-prefix .R-Button--md {
    font-size: 16px;
    font-size: var(--common-button-font-size);
    padding: 10px 25px;
    padding: var(--common-button-vertical-padding)
      var(--common-button-horizontal-padding);
    margin-bottom: 20px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-Button--md {
      font-size: 16px;
      padding: 10px 25px;
    }
  }
  .CarouselWidget-prefix .R-Button--lg {
    font-size: 20px;
    padding: 12px 30px;
    margin-bottom: 20px;
  }
  .CarouselWidget-prefix .R-Button--primary {
    background-color: var(--primary-button-bg-color);
    color: var(--primary-button-text-color);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-Button--primary {
      background-color: #0e1311;
      color: #fff;
    }
  }
  .CarouselWidget-prefix .R-Button--secondary {
    background-color: var(--secondary-button-bg-color);
    border-color: var(--secondary-button-border-color);
    color: var(--secondary-button-text-color);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-Button--secondary {
      background-color: transparent;
      border-color: #0e1311;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-Button--secondary:hover,
  .CarouselWidget-prefix .R-Button--secondary:active,
  .CarouselWidget-prefix .R-Button--secondary:focus {
    background-color: var(--secondary-button-bg-color);
    color: var(--secondary-button-text-color);
    border-color: var(--secondary-button-border-color);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-Button--secondary:hover,
    .CarouselWidget-prefix .R-Button--secondary:active,
    .CarouselWidget-prefix .R-Button--secondary:focus {
      background-color: transparent;
      border-color: #0e1311;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-Button--secondary--thin {
    border-width: 1px;
  }
  .CarouselWidget-prefix .R-Button--secondary--light {
    border-color: rgba(255, 255, 255, 0.85);
    color: rgba(255, 255, 255, 0.85);
  }
  .CarouselWidget-prefix .R-Button--secondary--light:hover,
  .CarouselWidget-prefix .R-Button--secondary--light:active,
  .CarouselWidget-prefix .R-Button--secondary--light:focus {
    border-color: #fff;
    color: #fff;
  }
  .CarouselWidget-prefix .R-Button--fw,
  .CarouselWidget-prefix .R-Button--fullwidth {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: 480px) {
    .CarouselWidget-prefix .R-Button--fw-phone-md {
      width: 100%;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  @media (max-width: 640px) {
    .CarouselWidget-prefix .R-Button--fw-phone-lg {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media (max-width: 768px) {
    .CarouselWidget-prefix .R-Button--fw-tablet-md {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media (max-width: 992px) {
    .CarouselWidget-prefix .R-Button--fw-tablet-lg {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media (min-width: 993px) {
    .CarouselWidget-prefix .R-Button--fw-desktop-sm {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
  .CarouselWidget-prefix .R-Button--desaturated {
    filter: grayscale(100%);
  }
  .CarouselWidget-prefix .R-Button--isLoading,
  .CarouselWidget-prefix .R-Button.isLoading {
    position: relative;
    padding-right: 30px;
    opacity: 0.5;
    cursor: not-allowed;
  }
  .CarouselWidget-prefix .R-Button--isLoading:before,
  .CarouselWidget-prefix .R-Button.isLoading:before {
    content: "";
    position: absolute;
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid rgba(255, 255, 255, 0.4);
    border-top: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    height: 12px;
    width: 12px;
    right: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    animation: CircleSpin 0.4s infinite linear;
  }
  .CarouselWidget-prefix .R-Button--isLoading.R-Button--secondary:before,
  .CarouselWidget-prefix .R-Button.isLoading.R-Button--secondary:before {
    border-right: 2px solid rgba(0, 0, 0, 0.7);
    border-top: 2px solid rgba(0, 0, 0, 0.7);
  }
  .CarouselWidget-prefix .R-Button--disabled,
  .CarouselWidget-prefix .R-Button.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .CarouselWidget-prefix .R-Button:disabled,
  .CarouselWidget-prefix .R-Button[disabled] {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: not-allowed;
  }
  .CarouselWidget-prefix .R-IconButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #0e1311;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
  }
  .CarouselWidget-prefix .R-IconButton .ricon-thumbsup {
    color: var(--badge-icon-color, #0e1311);
  }
  .CarouselWidget-prefix .R-IconButton .R-IconButton__icon {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-IconButton .R-IconButton__icon--image {
    max-width: 100%;
  }
  .CarouselWidget-prefix .R-IconButton--xs {
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 18px;
  }
  .CarouselWidget-prefix .R-IconButton--sm {
    font-size: 14px;
    height: 20px;
    width: 20px;
    line-height: 22px;
  }
  .CarouselWidget-prefix .R-IconButton--sm .R-IconButton__icon--close {
    font-size: 12px;
    font-weight: 800;
  }
  .CarouselWidget-prefix .R-IconButton--md {
    font-size: 16px;
    height: 22px;
    width: 22px;
    line-height: 24px;
  }
  .CarouselWidget-prefix .R-IconButton--lg {
    font-size: 20px;
    height: 26px;
    width: 26px;
    line-height: 28px;
  }
  .CarouselWidget-prefix .R-IconButton--xlg {
    font-size: 24px;
    height: 30px;
    width: 30px;
    line-height: 32px;
  }
  .CarouselWidget-prefix .R-IconButton--xxlg {
    font-size: 28px;
    height: 34px;
    width: 34px;
    line-height: 36px;
  }
  .CarouselWidget-prefix .R-IconButton--xxxxlg {
    font-size: 36px;
    height: 46px;
    width: 46px;
    line-height: 52px;
  }
  .CarouselWidget-prefix .R-IconButton--xxxxlg.R-IconButton--play {
    font-size: 32px;
  }
  .CarouselWidget-prefix
    .R-IconButton--xxxxlg.R-IconButton--play
    .R-IconButton__icon {
    margin-left: -1px;
  }
  .CarouselWidget-prefix .R-IconButton--xxxxlg.R-IconButton--pause {
    font-size: 28px;
  }
  .CarouselWidget-prefix .R-IconButton--black {
    background-color: #0e1311;
    color: #fff;
    border-radius: 50px;
  }
  .CarouselWidget-prefix .R-IconButton--outline {
    border-radius: 50px;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #0e1311;
    color: #0e1311;
  }
  .CarouselWidget-prefix .R-IconButton--lightIcon {
    color: #fff;
  }
  .CarouselWidget-prefix .R-IconButton--matchTextColor {
    color: var(--heading-text-color, #0e1311);
  }
  .CarouselWidget-prefix .R-IconButton--noRadius {
    border-radius: 0;
  }
  .CarouselWidget-prefix .R-IconButton--isDisabled {
    filter: grayscale(1);
    opacity: 0.3;
    cursor: default;
  }
  .CarouselWidget-prefix .R-IconButton.isActive {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .CarouselWidget-prefix .R-ToggleControl {
    position: relative;
    display: inline-block;
    max-width: 40px;
    height: 20px;
    width: 100%;
    transition: all 0.25s ease-in-out;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    cursor: pointer;
  }
  .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__circle {
    position: absolute;
    top: 2px;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    left: calc(100% - 18px);
    transition: all 0.25s ease-in-out;
    background-color: #fff;
    text-align: center;
    line-height: 16px;
  }
  .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__icon {
    font-size: 10px;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }
  .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__icon--active {
    transform: scale(0.6, 0.6);
  }
  .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__tooltip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
  }
  .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__tooltip--active {
    display: none;
  }
  .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__tooltip--inactive {
    display: block;
  }
  .CarouselWidget-prefix .R-ToggleControl--md {
    max-width: 55px;
    height: 25px;
  }
  .CarouselWidget-prefix .R-ToggleControl--md .R-ToggleControl__circle {
    top: 2px;
    width: 20px;
    height: 20px;
    left: calc(100% - 22px);
    line-height: 20px;
  }
  .CarouselWidget-prefix .R-ToggleControl--md .R-ToggleControl__icon {
    font-size: 12px;
  }
  .CarouselWidget-prefix
    .R-ToggleControl.isActive
    .R-ToggleControl__tooltip--active,
  .CarouselWidget-prefix
    .R-ToggleControl.isSelected
    .R-ToggleControl__tooltip--active {
    display: block;
  }
  .CarouselWidget-prefix
    .R-ToggleControl.isActive
    .R-ToggleControl__tooltip--inactive,
  .CarouselWidget-prefix
    .R-ToggleControl.isSelected
    .R-ToggleControl__tooltip--inactive {
    display: none;
  }
  .CarouselWidget-prefix .R-ToggleControl.isActive .R-ToggleControl__circle,
  .CarouselWidget-prefix .R-ToggleControl.isSelected .R-ToggleControl__circle {
    left: 2px;
    background-color: #11b1a7;
  }
  .CarouselWidget-prefix .R-ToggleControl.isActive .R-ToggleControl__icon,
  .CarouselWidget-prefix .R-ToggleControl.isSelected .R-ToggleControl__icon {
    opacity: 1;
    color: #fff;
    font-weight: 500;
  }
  .CarouselWidget-prefix
    .R-ToggleControl.isActive
    .R-ToggleControl__icon--active,
  .CarouselWidget-prefix
    .R-ToggleControl.isSelected
    .R-ToggleControl__icon--active {
    transform: scale(1, 1);
  }
  .CarouselWidget-prefix .R-ToggleControl--darkActive.isActive,
  .CarouselWidget-prefix .R-ToggleControl--darkActive.isSelected {
    background-color: rgba(255, 255, 255, 0.95);
  }
  .CarouselWidget-prefix
    .R-ToggleControl--darkActive.isActive
    .R-ToggleControl__circle,
  .CarouselWidget-prefix
    .R-ToggleControl--darkActive.isSelected
    .R-ToggleControl__circle {
    background-color: rgba(14, 19, 17, 0.6);
  }
  .CarouselWidget-prefix * {
    box-sizing: border-box;
  }
  .CarouselWidget-prefix a {
    text-decoration: none;
  }
  .CarouselWidget-prefix .R-PlatformIcon {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    width: auto;
  }
  .CarouselWidget-prefix .R-PlatformIcon--xxs {
    height: 20px;
  }
  .CarouselWidget-prefix .R-PlatformIcon--xs {
    height: 25px;
  }
  .CarouselWidget-prefix .R-PlatformIcon--sm {
    height: 30px;
  }
  .CarouselWidget-prefix .R-PlatformIcon--md {
    height: 40px;
  }
  .CarouselWidget-prefix .R-PlatformLogo {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    width: auto;
  }
  .CarouselWidget-prefix .R-PlatformLogo--xxs {
    height: 20px;
  }
  .CarouselWidget-prefix .R-PlatformLogo--xs {
    height: 25px;
  }
  .CarouselWidget-prefix .R-PlatformLogo--sm {
    height: 30px;
  }
  .CarouselWidget-prefix .R-PlatformLogo--md {
    height: 40px;
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    width: auto;
    max-height: var(--photo-video-thumbnail-size, 80px);
    border-radius: var(--photo-video-thumbnail-border-radius, 0);
    overflow: hidden;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-PhotoVideoThumbnail {
      height: 80px;
      border-radius: 0;
    }
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail .R-PhotoVideoThumbnail__image {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    max-height: var(--photo-video-thumbnail-size, 80px);
  }
  .CarouselWidget-prefix
    .R-PhotoVideoThumbnail
    .R-PhotoVideoThumbnail__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--photo-video-thumbnail-size) * (14 / 50));
    line-height: calc(var(--photo-video-thumbnail-size) * (14 / 50));
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-PhotoVideoThumbnail
      .R-PhotoVideoThumbnail__overlay {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail--xs {
    height: 25px;
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail--sm {
    height: 30px;
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail--md {
    height: 40px;
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail--lg {
    height: 60px;
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail--xlg {
    height: 80px;
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail--xxlg {
    height: 100px;
  }
  .CarouselWidget-prefix .R-PhotoVideoThumbnail--fullwidth {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .CarouselWidget-prefix
    .R-PhotoVideoThumbnail--fullwidth
    .R-PhotoVideoThumbnail__image {
    height: auto;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail {
    position: relative;
    display: inline-block;
    max-width: var(--avatar-thumbnail-size, 60px);
    max-height: var(--avatar-thumbnail-size, 60px);
    width: 100%;
    border-radius: var(--avatar-thumbnail-border-radius, 0);
    overflow: hidden;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-AvatarThumbnail {
      height: 60px;
      width: 60px;
      border-radius: 100px;
    }
  }
  .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 100%;
    background-color: var(--avatar-thumbnail-bg-color, rgba(0, 0, 0, 0.1));
    color: var(--avatar-thumbnail-text-color, #0e1311);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__inner {
      background-color: rgba(0, 0, 0, 0.1);
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-AvatarThumbnail
    .R-AvatarThumbnail__inner
    .R-TextBody,
  .CarouselWidget-prefix
    .R-AvatarThumbnail
    .R-AvatarThumbnail__inner
    .R-TextHeading {
    color: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin-top: auto !important;
    margin-bottom: auto !important;
    height: 30px;
    line-height: 30px;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-size: cover;
    background-position: center center;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--xs {
    width: 25px;
    height: 25px;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--sm {
    width: 30px;
    height: 30px;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--md {
    width: 40px;
    height: 40px;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--lg {
    width: 60px;
    height: 60px;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--xlg {
    width: 80px;
    height: 80px;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--xxlg {
    width: 100px;
    height: 100px;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--fullwidth {
    max-width: 100%;
  }
  .CarouselWidget-prefix .R-AvatarThumbnail--round {
    border-radius: 200px;
  }
  .CarouselWidget-prefix .R-TabControls {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-TabControls .R-TabControls__item {
    display: inline-block;
    font-size: var(--pagination-tab-text-font-size, 16px);
    font-weight: var(--pagination-tab-text-font-weight, 600);
    text-transform: var(--pagination-tab-text-transform, none);
    letter-spacing: var(--pagination-tab-text-letter-spacing, 0);
    color: var(--pagination-tab-text-color, #0e1311);
    border-bottom: var(--pagination-tab-border-width, 3px) solid transparent;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-TabControls .R-TabControls__item {
      font-size: 16px;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0;
      color: #0e1311;
      border-bottom: 3px solid transparent;
    }
  }
  .CarouselWidget-prefix .R-TabControls .R-TabControls__item > .R-TextBody,
  .CarouselWidget-prefix .R-TabControls .R-TabControls__item > .R-TextHeading {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    color: inherit;
    margin-bottom: 5px;
  }
  .CarouselWidget-prefix .R-TabControls .R-TabControls__item.isActive {
    font-weight: var(--pagination-tab-active-text-font-weight, 600);
    color: var(--pagination-tab-active-text-color, #0e1311);
    border-bottom-color: var(--pagination-tab-active-border-color, #0e1311);
    cursor: default;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-TabControls .R-TabControls__item.isActive {
      font-weight: 600;
      color: #0e1311;
      border-bottom-color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-TabControls
    .R-TabControls__item.isActive
    > .R-TextBody,
  .CarouselWidget-prefix
    .R-TabControls
    .R-TabControls__item.isActive
    > .R-TextHeading {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    color: inherit;
  }
  .CarouselWidget-prefix .R-PaginationControls {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item {
    display: inline-flex;
    font-size: var(--pagination-tab-text-font-size, 16px);
    font-weight: var(--pagination-tab-text-font-weight, 600);
    color: var(--pagination-tab-text-color, #0e1311);
    text-transform: var(--pagination-tab-text-transform, none);
    letter-spacing: var(--pagination-tab-text-letter-spacing, 0);
    border-top: var(--pagination-tab-border-width, 3px) solid transparent;
    border-bottom: var(--pagination-tab-border-width, 3px) solid transparent;
    padding: 2px 8px;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item {
      font-size: 16px;
      font-weight: 600;
      color: #0e1311;
      text-transform: none;
      letter-spacing: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }
  }
  .CarouselWidget-prefix
    .R-PaginationControls
    .R-PaginationControls__item
    > .R-TextBody,
  .CarouselWidget-prefix
    .R-PaginationControls
    .R-PaginationControls__item
    > .R-TextHeading {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    color: inherit;
    margin-bottom: 5px;
  }
  .CarouselWidget-prefix
    .R-PaginationControls
    .R-PaginationControls__item.isActive {
    font-weight: var(--pagination-tab-active-text-font-weight, 600);
    color: var(--pagination-tab-active-text-color, #0e1311);
    border-bottom-color: var(--pagination-tab-active-border-color, #0e1311);
    cursor: default;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-PaginationControls
      .R-PaginationControls__item.isActive {
      font-weight: 600;
      color: #0e1311;
      border-bottom-color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-PaginationControls
    .R-PaginationControls__item
    .item__arrowIcon {
    line-height: 1.5;
  }
  .CarouselWidget-prefix .R-ContentSlider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    min-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  .CarouselWidget-prefix .R-ContentSlider::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  .CarouselWidget-prefix .R-ContentSlider .R-ContentSlider__item {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-ContentSlider .R-ContentSlider__item div {
      flex: 1 1 auto !important;
    }
  }
  .CarouselWidget-prefix .R-DotIndicator {
    position: relative;
    display: inline-flex;
  }
  .CarouselWidget-prefix .R-DotIndicator .R-DotIndicator__item {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    vertical-align: middle;
  }
  .CarouselWidget-prefix .R-DotIndicator .R-DotIndicator__item:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .CarouselWidget-prefix .R-DotIndicator .R-DotIndicator__item.isActive {
    background-color: #0e1311;
    cursor: default;
  }
  .CarouselWidget-prefix .R-DotIndicator--sm .R-DotIndicator__item {
    width: 5px;
    height: 5px;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu-container {
    display: inline-block;
    position: relative;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu {
    position: absolute;
    padding: 10px 0;
    left: 0;
    top: 90%;
    z-index: 15;
    background-color: var(--dropupdown-bg-color, #fff);
    box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transform: scale(0.94, 0.94) translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__inner {
    padding: 5px 0;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__column--width-md {
    width: 180px;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__item {
    padding: 7px 15px;
    cursor: pointer;
  }
  .CarouselWidget-prefix
    .R-DropUpDownMenu
    .R-DropUpDownMenu__item
    .item__activeIcon {
    display: none;
  }
  .CarouselWidget-prefix
    .R-DropUpDownMenu
    .R-DropUpDownMenu__item.isActive
    .item__activeIcon {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__item.isDisabled {
    cursor: default;
    opacity: 0.2;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu--sm {
    padding: 5px 0;
    width: 140px;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu--sm .R-DropUpDownMenu__item {
    padding: 5px 10px;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu--md {
    width: 200px;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu--lg {
    width: 240px;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu--auto {
    width: auto;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu--above {
    top: auto;
    bottom: 100%;
  }
  .CarouselWidget-prefix
    .R-DropUpDownMenu--maxHeight380
    .R-DropUpDownMenu__inner {
    max-height: 380px;
    overflow-y: auto;
  }
  .CarouselWidget-prefix .R-DropUpDownMenu.isActive {
    opacity: 1;
    transform: scale(1, 1) translateY(0px);
    pointer-events: auto;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon {
    display: inline-block;
    width: 30px;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon svg {
    width: 100%;
    vertical-align: top;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color1,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color2,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color3,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color4,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color5 {
    fill: var(--icon-accent-color) !important;
    transform-origin: center;
    transition: all 0.15s ease-in-out;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color1,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color2,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color3,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color4,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color5,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color6,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color7,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color8,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color9,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color10,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color11,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color12,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color13,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color14,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color15,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color16,
  .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color17 {
    stroke: var(--icon-base-color) !important;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon img {
    width: 100%;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon--xxs {
    width: 25px;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon--xs {
    width: 30px;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon--sm {
    width: 35px;
  }
  .CarouselWidget-prefix .R-EmbeddedIcon--md {
    width: 40px;
  }
  .CarouselWidget-prefix
    .R-EmbeddedIcon-animationTrigger:hover
    .R-EmbeddedIcon
    svg
    #dynamic-color,
  .CarouselWidget-prefix
    .R-EmbeddedIcon-animationTrigger:hover
    .R-EmbeddedIcon
    svg
    #dynamic-color1,
  .CarouselWidget-prefix
    .R-EmbeddedIcon-animationTrigger:hover
    .R-EmbeddedIcon
    svg
    #dynamic-color3,
  .CarouselWidget-prefix
    .R-EmbeddedIcon-animationTrigger:hover
    .R-EmbeddedIcon
    svg
    #dynamic-color4 {
    animation-name: R-shakeAnimation--md;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 2;
  }
  .CarouselWidget-prefix .R-VideoPlayer {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 100%;
    max-width: 100%;
    max-height: 480px;
    background-color: rgba(15, 13, 24, 0.015);
    outline: none;
  }
  .CarouselWidget-prefix .R-VideoPlayer video {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    max-height: 480px;
    outline: none;
  }
  .CarouselWidget-prefix .R-VideoPlayer--nativeSize {
    max-height: none;
  }
  .CarouselWidget-prefix .R-VideoPlayer--nativeSize video {
    max-height: none;
  }
  .CarouselWidget-prefix .R-VideoPlayer--rounded {
    border-radius: 20px;
  }
  .CarouselWidget-prefix .R-MaskedVideoPhoto {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 320px;
  }
  .CarouselWidget-prefix .R-MaskedVideoPhoto .R-MaskedVideoPhoto__video {
    display: inline-block;
    position: absolute;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 100%;
    outline: none;
    left: 0;
    top: 0;
  }
  .CarouselWidget-prefix .R-MaskedVideoPhoto .R-MaskedVideoPhoto__overlay {
    --photo-video-thumbnail-size: 60px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--photo-video-thumbnail-size) * (14 / 25));
    line-height: calc(var(--photo-video-thumbnail-size) * (14 / 25));
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-MaskedVideoPhoto .R-MaskedVideoPhoto__overlay {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .CarouselWidget-prefix .R-RatingStars {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__score {
    display: block;
    font-family: var(--heading-text-font-family, inherit);
    font-size: 1.75em;
    line-height: var(--heading-text-line-height, 1.4);
    font-weight: var(--heading-text-font-weight, 600);
    letter-spacing: var(--heading-text-letter-spacing, 0);
    text-transform: var(--heading-text-transform, none);
    color: var(--heading-text-color, #0e1311);
    text-align: left;
    margin-top: 0;
    margin-bottom: 1.25em;
    font-size: 1em;
    margin-bottom: 0.3125em;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-RatingStars .R-RatingStars__score {
      font-family: inherit;
      font-weight: 600;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon {
    color: var(--common-star-color);
    font-size: var(--medium-star-size, 22px);
    line-height: 1;
  }
  .CarouselWidget-prefix
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--disabled {
    color: var(--common-star-disabled-color);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon {
      color: #0e1311;
      font-size: 22px;
    }
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--0 {
    color: var(--common-star-disabled-color);
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--25 {
    background-image: linear-gradient(
      to right,
      var(--common-star-color) 38%,
      var(--common-star-disabled-color) 38.1%
    );
    background-size: 100%;
    -webkit-background-clip: text;
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--50 {
    background-image: linear-gradient(
      to right,
      var(--common-star-color) 50%,
      var(--common-star-disabled-color) 50.1%
    );
    background-size: 100%;
    -webkit-background-clip: text;
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--75 {
    background-image: linear-gradient(
      to right,
      var(--common-star-color) 60%,
      var(--common-star-disabled-color) 60.1%
    );
    background-size: 100%;
    -webkit-background-clip: text;
  }
  .CarouselWidget-prefix
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--100 {
    background-image: linear-gradient(
      to right,
      var(--common-star-color) 99.9%,
      var(--common-star-color) 100%
    );
    background-size: 100%;
    -webkit-background-clip: text;
  }
  .CarouselWidget-prefix .R-RatingStars .R-RatingStars__count {
    display: block;
    font-family: var(--body-text-font-family, inherit);
    font-size: 1.125em;
    line-height: var(--body-text-line-height, 1.4);
    font-weight: var(--body-text-font-weight, 400);
    letter-spacing: var(--body-text-letter-spacing, 0);
    color: var(--body-text-color, #0e1311);
    text-transform: var(--body-text-transform, none);
    text-align: left;
    margin-top: 0;
    margin-bottom: 1.25em;
    font-size: 0.875em;
    margin-bottom: 0.625em;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-RatingStars .R-RatingStars__count {
      font-family: inherit;
      font-weight: 400;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-RatingStars--xlg .R-RatingStars__score {
    font-size: 1.375em;
    line-height: 1.5em;
  }
  .CarouselWidget-prefix
    .R-RatingStars--xlg
    .R-RatingStars__stars
    .stars__icon {
    font-size: var(--x-large-star-size, 28px);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-RatingStars--xlg
      .R-RatingStars__stars
      .stars__icon {
      font-size: 28px;
    }
  }
  .CarouselWidget-prefix .R-RatingStars--xlg .R-RatingStars__count {
    font-size: 1.375em;
    line-height: 1.5em;
  }
  .CarouselWidget-prefix .R-RatingStars--lg .R-RatingStars__score {
    font-size: 1.125em;
    line-height: 1.5em;
  }
  .CarouselWidget-prefix .R-RatingStars--lg .R-RatingStars__stars .stars__icon {
    font-size: var(--large-star-size, 24px);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-RatingStars--lg
      .R-RatingStars__stars
      .stars__icon {
      font-size: 24px;
    }
  }
  .CarouselWidget-prefix .R-RatingStars--lg .R-RatingStars__count {
    font-size: 1.125em;
    line-height: 1.5em;
  }
  .CarouselWidget-prefix .R-RatingStars--md .R-RatingStars__score {
    font-size: 1em;
    line-height: 1.7em;
  }
  .CarouselWidget-prefix .R-RatingStars--md .R-RatingStars__stars .stars__icon {
    font-size: var(--medium-star-size, 22px);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-RatingStars--md
      .R-RatingStars__stars
      .stars__icon {
      font-size: 22px;
    }
  }
  .CarouselWidget-prefix .R-RatingStars--md .R-RatingStars__count {
    font-size: 1em;
    line-height: 1.7em;
  }
  .CarouselWidget-prefix .R-RatingStars--sm .R-RatingStars__score {
    font-size: 0.875em;
    line-height: 1.6;
    margin-right: 3px;
  }
  .CarouselWidget-prefix .R-RatingStars--sm .R-RatingStars__stars .stars__icon {
    font-size: var(--small-star-size, 19px);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-RatingStars--sm
      .R-RatingStars__stars
      .stars__icon {
      font-size: 19px;
    }
  }
  .CarouselWidget-prefix .R-RatingStars--sm .R-RatingStars__count {
    font-size: 0.875em;
    line-height: 1.6;
  }
  .CarouselWidget-prefix .R-RatingStars--xs .R-RatingStars__score {
    font-size: 0.75em;
    line-height: 1.5;
    margin-right: 4px;
  }
  .CarouselWidget-prefix .R-RatingStars--xs .R-RatingStars__stars .stars__icon {
    font-size: var(--x-small-star-size, 16px);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-RatingStars--xs
      .R-RatingStars__stars
      .stars__icon {
      font-size: 16px;
    }
  }
  .CarouselWidget-prefix .R-RatingStars--xs .R-RatingStars__count {
    font-size: 0.75em;
    line-height: 1.5;
  }
  .CarouselWidget-prefix .R-RatingStars--inlineBlock {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-RatingStars--white .R-RatingStars__score,
  .CarouselWidget-prefix .R-RatingStars--white .R-RatingStars__count {
    color: #fff;
  }
  .CarouselWidget-prefix
    .R-RatingStars--white
    .R-RatingStars__stars
    .stars__icon {
    color: #fff;
  }
  .CarouselWidget-prefix .R-RatingStars--black .R-RatingStars__score,
  .CarouselWidget-prefix .R-RatingStars--black .R-RatingStars__count {
    color: #0e1311;
  }
  .CarouselWidget-prefix
    .R-RatingStars--black
    .R-RatingStars__stars
    .stars__icon {
    color: #0e1311;
  }
  .CarouselWidget-prefix .R-ReviewsioLogo {
    display: inline-block;
    color: transparent;
  }
  .CarouselWidget-prefix .R-ReviewsioLogo .R-ReviewsioLogo__image {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    vertical-align: top;
  }
  .CarouselWidget-prefix .R-ReviewsioLogo--xs {
    width: 100px;
  }
  .CarouselWidget-prefix .R-ReviewsioLogo--sm {
    width: 120px;
  }
  .CarouselWidget-prefix .R-RatingSelection {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
    margin-left: -5px;
    margin-right: -5px;
  }
  .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item {
    text-align: center;
    padding: 0;
  }
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item
    .R-RatingSelection__button {
    display: inline-block;
    color: var(--common-star-color, #0e1311);
    font-size: 32px;
    line-height: 26px;
    padding: 7px 2px;
    line-height: 14px;
    cursor: pointer;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-RatingSelection
      .R-RatingSelection__item
      .R-RatingSelection__button {
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item
    .star__icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item
    .star__icon--empty {
    display: inline-block;
  }
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item
    .star__icon--full {
    display: none;
  }
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item
    .R-RatingSelection__button:hover
    .star__icon--empty,
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item.isSelected
    .R-RatingSelection__button
    .star__icon--empty {
    display: none;
  }
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item
    .R-RatingSelection__button:hover
    .star__icon--full,
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item.isSelected
    .R-RatingSelection__button
    .star__icon--full {
    display: inline-block;
  }
  .CarouselWidget-prefix
    .R-RatingSelection
    .R-RatingSelection__item--small
    .R-RatingSelection__button {
    padding: 0 2px;
    font-size: 24px;
    line-height: 22px;
  }
  .CarouselWidget-prefix .R-BadgeElement {
    font-size: var(--base-font-size, 16px);
    flex-wrap: nowrap;
  }
  .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__icon {
    font-size: 1.25em;
    height: calc(var(--badge-icon-font-size, 20px) * 1.2);
    width: calc(var(--badge-icon-font-size, 20px) * 1.2);
    line-height: var(--badge-icon-font-size, 28px);
    margin-right: 0.1em;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__icon {
      font-size: 20px;
      height: 26px;
      width: 26px;
      line-height: 28px;
    }
  }
  .CarouselWidget-prefix
    .R-BadgeElement
    .R-BadgeElement__icon
    .cssVar-badgeElement__icon {
    font-size: var(--badge-icon-font-size, inherit);
    color: var(--badge-icon-color, #0e1311);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-BadgeElement
      .R-BadgeElement__icon
      .cssVar-badgeElement__icon {
      font-size: inherit;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-BadgeElement
    .R-BadgeElement__text
    .cssVar-badgeElement__text {
    font-size: var(--badge-text-font-size, inherit);
    color: var(--badge-text-color, #0e1311);
    letter-spacing: var(--badge-text-letter-spacing, inherit);
    text-transform: var(--badge-text-transform, inherit);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-BadgeElement
      .R-BadgeElement__text
      .cssVar-badgeElement__text {
      font-size: inherit;
      color: #0e1311;
      letter-spacing: 0;
      text-transform: none;
    }
  }
  .CarouselWidget-prefix .R-SliderIndicator-group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-left: -10px;
    margin-right: -10px;
  }
  .CarouselWidget-prefix .R-SliderIndicator-group .R-SliderIndicator {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0;
  }
  .CarouselWidget-prefix .R-SliderIndicator {
    display: inline-block;
    vertical-align: bottom;
    font-size: var(--base-font-size, 16px);
    max-width: var(--slider-indicator-width, 170px);
    width: 100%;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 30px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-SliderIndicator {
      max-width: 170px;
      font-size: 16px;
    }
  }
  .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__inner {
    position: relative;
    margin-bottom: 4px;
  }
  .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__bg {
    background-color: var(--slider-indicator-bg-color, rgba(0, 0, 0, 0.1));
    height: 4px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__bg {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__button {
    position: absolute;
    width: 5px;
    height: 10px;
    background-color: var(--slider-indicator-button-color, #0e1311);
    top: -3px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__button {
      background-color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-SliderIndicator .R-TextBody {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .CarouselWidget-prefix .R-SliderIndicator--sm {
    max-width: calc(var(--slider-indicator-width, 190px) - 10px);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-SliderIndicator--sm {
      max-width: 140px;
    }
  }
  .CarouselWidget-prefix
    .R-SliderIndicator--style-filledbar
    .R-SliderIndicator__inner {
    overflow: hidden;
  }
  .CarouselWidget-prefix
    .R-SliderIndicator--style-filledbar
    .R-SliderIndicator__bg {
    background-color: var(--slider-indicator-button-color, #0e1311);
    height: 5px;
  }
  .CarouselWidget-prefix
    .R-SliderIndicator--style-filledbar
    .R-SliderIndicator__button {
    width: 120%;
    background-color: var(--slider-indicator-bg-color, rgba(0, 0, 0, 0.1));
  }
  .CarouselWidget-prefix .R-MediaSlider {
    position: relative;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--mediaslider-scroll-button-icon-color, #0e1311);
    text-align: center;
    cursor: pointer;
    z-index: 3;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 24px;
    height: 100%;
    width: 30px;
    line-height: 32px;
    background-color: var(
      --mediaslider-scroll-button-bg-color,
      rgba(255, 255, 255, 0.85)
    );
    backdrop-filter: blur(3px);
    transition: all 0.25s ease-in-out;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton {
      color: #0e1311;
      background-color: rgba(255, 255, 255, 0.85);
    }
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__scrollButton
    .scrollButton__icon {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton--left {
    left: 0;
    right: auto;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton--right {
    right: 0;
    left: auto;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    min-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__inner::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: var(--mediaslider-item-size, 110px);
    width: var(--mediaslider-item-size, 110px);
    height: var(--mediaslider-item-size, 110px);
    flex-shrink: 0;
    scroll-snap-align: start;
    margin-right: 10px;
    box-sizing: border-box;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item {
      flex-basis: 110px;
      width: 110px;
      height: 110px;
    }
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__image {
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 5px;
    background-size: cover;
    background-position: center center;
    border-radius: var(--photo-video-thumbnail-border-radius, 0);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__image {
      border-radius: 0;
    }
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .image__src {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    object-fit: cover;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    text-align: center;
    color: var(--mediaslider-overlay-text-color, #fff);
    background-color: var(--mediaslider-overlay-bg-color, rgba(0, 0, 0, 0.8));
    padding: 5px;
    margin: 0;
    border-radius: 0;
    transition: all 0.2s ease-in-out 0s;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__overlay {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item
    .item__overlay
    .R-TextBody {
    font-size: calc(var(--mediaslider-item-size) * (14 / 140));
    color: inherit;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-MediaSlider
      .R-MediaSlider__item
      .item__overlay
      .R-TextBody {
      font-size: 11px;
    }
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item
    .item__playOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: none;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item
    .playOverlay__button {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: var(--mediaslider-overlay-text-color, #fff);
    font-size: calc(var(--mediaslider-item-size) * (14 / 60));
    line-height: calc(var(--mediaslider-item-size) * (14 / 36));
    text-align: center;
    background-color: transparent;
    width: calc(var(--mediaslider-item-size) * (14 / 40));
    height: calc(var(--mediaslider-item-size) * (14 / 40));
    border-radius: var(--photo-video-thumbnail-border-radius, 0);
    transition: all 0.3s ease-in-out;
    opacity: 0.9;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-MediaSlider
      .R-MediaSlider__item
      .playOverlay__button {
      font-size: 26px;
      color: #fff;
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 0;
    }
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item
    .playOverlay__button
    .button__icon {
    display: inline-block;
    margin-right: -1px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__review {
    display: none;
    transform: scale(0.9, 0.9);
    opacity: 0;
    transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
  }
  .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__stars {
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item
    .overlay__stars
    .stars__icon {
    font-size: calc(var(--mediaslider-item-size) * (14 / 120));
    margin: 0 1px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-MediaSlider
      .R-MediaSlider__item
      .overlay__stars
      .stars__icon {
      font-size: 14px;
    }
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item
    .overlay__instagram {
    display: none;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item
    .overlay__instagram
    .instagram__icon {
    font-size: 16px;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item--reviewPhoto
    .overlay__review {
    display: block;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item--reviewVideo
    .item__playOverlay {
    display: flex;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item--reviewVideo
    .overlay__review {
    display: block;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item--reviewVideo:hover
    .item__playOverlay
    .playOverlay__button {
    transform: scale(5);
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item--reviewVideo:hover
    .item__playOverlay
    .playOverlay__button
    .button__icon {
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item--instagramPhoto
    .overlay__instagram {
    display: block;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item:hover
    .item__overlay {
    opacity: 1;
    pointer-events: auto;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item:hover
    .overlay__instagram {
    transform: scale(1, 1);
    opacity: 1;
  }
  .CarouselWidget-prefix
    .R-MediaSlider
    .R-MediaSlider__item:hover
    .overlay__review {
    transform: scale(1, 1);
    opacity: 1;
  }
  .CarouselWidget-prefix .R-ProductPreviewList {
    margin-bottom: 20px;
  }
  .CarouselWidget-prefix .R-ProductPreviewList .R-ProductPreviewList__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: -5px;
    margin-right: -5px;
  }
  .CarouselWidget-prefix
    .R-ProductPreviewList
    .R-ProductPreviewList__inner
    .R-ProductPreviewList__item {
    max-width: 170px;
    width: 100%;
    padding: 0 5px;
    margin-bottom: 10px;
  }
  .CarouselWidget-prefix
    .R-ProductPreviewList
    .R-ProductPreviewList__inner
    .R-ProductPreviewList__item
    .item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: var(--common-border-width, 1px) solid
      var(--common-border-color, rgba(0, 0, 0, 0.15));
    padding: 10px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-ProductPreviewList
      .R-ProductPreviewList__inner
      .R-ProductPreviewList__item
      .item__content {
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
  .CarouselWidget-prefix
    .R-XSM
    .R-ProductPreviewList
    .R-ProductPreviewList__inner
    .R-ProductPreviewList__item {
    max-width: 50%;
  }
  .CarouselWidget-prefix .R-ContentCard {
    background-color: var(--card-bg-color, #fff);
    box-shadow: 0 calc(var(--card-shadow-size) / 4) var(--card-shadow-size) -1px
      var(--card-shadow-color);
    padding-top: var(--card-vertical-padding, 15px);
    padding-bottom: var(--card-vertical-padding, 15px);
    padding-left: var(--card-horizontal-padding, 10px);
    padding-right: var(--card-horizontal-padding, 10px);
    border-radius: var(--card-border-radius, 6px);
    text-align: left;
    border: var(--card-border-width, 1px) solid
      var(--card-border-color, rgba(0, 0, 0, 0.1));
  }
  .CarouselWidget-prefix .R-LabelTag {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--heading-text-font-family, inherit);
    font-size: 0.75em;
    line-height: 1.4;
    font-weight: 400;
    text-transform: none;
    color: var(--body-text-color, #0e1311);
    padding: 2px 6px;
    border-radius: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid var(--common-border-color, rgba(0, 0, 0, 0.15));
    vertical-align: middle;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-LabelTag {
      color: #0e1311;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-family: inherit;
    }
  }
  .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon {
    display: inline-block;
    margin-right: 2px;
  }
  .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon--left {
    margin-left: 0;
    margin-right: 2px;
  }
  .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon--clickable {
    cursor: pointer;
  }
  .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon--selectedIcon {
    display: none;
  }
  .CarouselWidget-prefix .R-LabelTag .R-LabelTag__text + .R-LabelTag__icon {
    margin-right: 0;
    margin-left: 2px;
  }
  .CarouselWidget-prefix .R-LabelTag .R-LabelTag__iconImage {
    max-width: 16px;
    width: 100%;
    display: inline-block;
  }
  .CarouselWidget-prefix .R-LabelTag--xs {
    font-size: 0.6875em;
    padding: 1px 4px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
  .CarouselWidget-prefix .R-LabelTag--sm {
    font-size: 0.75em;
    padding: 2px 6px;
  }
  .CarouselWidget-prefix .R-LabelTag--md {
    font-size: 0.875em;
    padding: 3px 8px;
  }
  .CarouselWidget-prefix .R-LabelTag--lg {
    font-size: 1em;
    padding: 5px 10px;
  }
  .CarouselWidget-prefix .R-LabelTag--success {
    border-color: #11b1a7;
    color: #11b1a7;
  }
  .CarouselWidget-prefix .R-LabelTag--danger {
    border-color: #e7656e;
    color: #e7656e;
  }
  .CarouselWidget-prefix .R-LabelTag--warning {
    border-color: #ffb846;
    color: #ffb846;
  }
  .CarouselWidget-prefix .R-LabelTag--neutral {
    border-color: rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.4);
  }
  .CarouselWidget-prefix .R-LabelTag--black {
    border-color: #0e1311;
    color: #0e1311;
  }
  .CarouselWidget-prefix .R-LabelTag--filled-success {
    background-color: #11b1a7;
    border-color: #11b1a7;
    color: #fff;
  }
  .CarouselWidget-prefix .R-LabelTag--filled-danger {
    background-color: #e7656e;
    border-color: #e7656e;
    color: #fff;
  }
  .CarouselWidget-prefix .R-LabelTag--filled-warning {
    background-color: #ffb846;
    border-color: #ffb846;
    color: #fff;
  }
  .CarouselWidget-prefix .R-LabelTag--filled-neutral {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.05);
    color: #fff;
  }
  .CarouselWidget-prefix .R-LabelTag--filled-black {
    background-color: #0e1311;
    border-color: #0e1311;
    color: #fff;
  }
  .CarouselWidget-prefix .R-LabelTag.isSelected {
    border-color: var(--primary-button-bg-color, #0e1311);
    color: var(--primary-button-bg-color, #0e1311);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-LabelTag.isSelected {
      border-color: #0e1311;
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-LabelTag.isSelected
    .R-LabelTag__icon--selectedIcon {
    display: inline-block;
  }
  .CarouselWidget-prefix .R-IconLabel {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .CarouselWidget-prefix .R-IconLabel .R-IconLabel__iconImage {
    max-width: 16px;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  .CarouselWidget-prefix .R-IconLabel--sm .R-IconLabel__iconImage {
    max-width: 18px;
    height: 18px;
  }
  .CarouselWidget-prefix .R-IconLabel--md .R-IconLabel__iconImage {
    max-width: 25px;
    height: 25px;
  }
  .CarouselWidget-prefix .R-GlobalModal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5990;
    text-align: center;
    background-color: var(--popup-backdrop-color, rgba(0, 0, 0, 0.75));
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    max-height: 0;
    transition: max-height 0.8s ease-in-out, opacity 0.1s ease-in-out;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-GlobalModal {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
    position: relative;
    top: 5%;
    background-color: var(--popup-color, #fff);
    border: var(--popup-border-width, 0px) solid
      var(--popup-border-color, rgba(0, 0, 0, 0.1));
    border-radius: var(--popup-border-radius, 0px);
    box-shadow: 0 0 var(--popup-shadow-size, 0px) 0
      var(--popup-shadow-color, rgba(0, 0, 0, 0.1));
    transform: scale(0.8, 0.8);
    transition: transform 0.25s ease-in-out;
    padding: 20px;
    margin-bottom: 40px;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
      background-color: #fff;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
  @media screen and (min-width: 1640px) {
    .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
      top: 8%;
    }
  }
  @media (max-width: 640px) {
    .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
      top: 0;
    }
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__inner {
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    .CarouselWidget-prefix
      .R-GlobalModal
      .R-GlobalModal__dialog
      .dialog__inner {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    .dialog__inner--spaceBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-GlobalModal
      .R-GlobalModal__dialog
      .R-TextBody.u-whiteSpace--prewrap.u-scrollableMaxHeight--480--desktop {
      max-height: none !important;
      overflow-y: visible !important;
    }
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    .controls__button,
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    a.controls__button {
    color: var(--popup-icon-color, #0e1311);
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-GlobalModal
      .R-GlobalModal__dialog
      .controls__button,
    .CarouselWidget-prefix
      .R-GlobalModal
      .R-GlobalModal__dialog
      a.controls__button {
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    .controls__button
    .button__icon,
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    a.controls__button
    .button__icon {
    font-size: 24px;
    line-height: 32px;
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    .controls__button
    .button__icon--sm,
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    a.controls__button
    .button__icon--sm {
    font-size: 18px;
    line-height: 32px;
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    .controls__button:hover,
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    a.controls__button:hover {
    color: var(--popup-icon-color, #0e1311);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-GlobalModal
      .R-GlobalModal__dialog
      .controls__button:hover,
    .CarouselWidget-prefix
      .R-GlobalModal
      .R-GlobalModal__dialog
      a.controls__button:hover {
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__header {
    margin-bottom: 20px;
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    .dialog__closeButton {
    display: inline-block;
    background-color: #000;
    position: absolute;
    opacity: 1;
    color: #fff;
    text-shadow: none;
    width: 32px;
    height: 32px;
    line-height: 38px;
    font-size: 22px;
    border-radius: 100px;
    right: -15px;
    top: -15px;
    box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-GlobalModal__dialog
    .dialog__controls {
    margin-bottom: 20px;
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width1140 {
    max-width: 1140px;
    width: 100%;
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width1040 {
    max-width: 1040px;
    width: 100%;
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width960 {
    max-width: 960px;
    width: 100%;
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width768 {
    max-width: 768px;
    width: 100%;
  }
  .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width640 {
    max-width: 640px;
    width: 100%;
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon {
    --override-popup-star-color: var(
      --popup-star-color,
      var(--common-star-color)
    );
    --override-popup-star-disabled-color: var(
      --popup-disabled-star-color,
      var(--common-star-disabled-color)
    );
    color: var(--override-popup-star-color);
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--disabled {
    color: var(--override-popup-star-disabled-color);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .R-GlobalModal
      .R-RatingStars
      .R-RatingStars__stars
      .stars__icon {
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--0 {
    color: var(--override-popup-star-disabled-color);
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--25 {
    background-image: linear-gradient(
      to right,
      var(--override-popup-star-color) 38%,
      var(--override-popup-star-disabled-color) 38.1%
    );
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--50 {
    background-image: linear-gradient(
      to right,
      var(--override-popup-star-color) 50%,
      var(--override-popup-star-disabled-color) 50.1%
    );
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--75 {
    background-image: linear-gradient(
      to right,
      var(--override-popup-star-color) 60%,
      var(--override-popup-star-disabled-color) 60.1%
    );
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-TextHeading
    .cssVar-popup-heading-text {
    color: var(--popup-heading-text-color, inherit);
  }
  .CarouselWidget-prefix .R-GlobalModal .R-TextBody .cssVar-popup-body-text {
    color: var(--popup-body-text-color, inherit);
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-IconButton
    .cssVar-popup-message-icon {
    color: var(--popup-heading-text-color, inherit);
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-BadgeElement
    .R-BadgeElement__icon
    .cssVar-popup-badgeElement__icon:before {
    font-size: var(--popup-badge-icon-font-size, inherit);
    color: var(--popup-badge-icon-color, inherit);
  }
  .CarouselWidget-prefix
    .R-GlobalModal
    .R-BadgeElement
    .R-BadgeElement__text
    .cssVar-popup-badgeElement__text {
    font-size: var(--popup-badge-text-font-size, inherit);
    color: var(--popup-badge-text-color, inherit);
  }
  .CarouselWidget-prefix .R-GlobalModal .u-hr {
    border-color: var(--popup-body-text-color, inherit);
    opacity: 0.15;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-GlobalModal .u-hr {
      border-color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .R-GlobalModal.hideScroll
    .controls__button--scrollLeft,
  .CarouselWidget-prefix
    .R-GlobalModal.hideScroll
    .controls__button--scrollRight {
    display: none !important;
  }
  .CarouselWidget-prefix .R-GlobalModal.isActive {
    overflow: auto;
    pointer-events: auto;
    opacity: 1;
    max-height: 1600px;
    transition: max-height 0.01s ease-in-out, opacity 0.5s ease-in-out;
  }
  .CarouselWidget-prefix .R-GlobalModal.isActive .R-GlobalModal__dialog {
    transform: scale(1, 1);
  }
  .CarouselWidget-prefix .R-ImageContainer {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 400px;
    transition: 0.5s filter linear;
  }
  .CarouselWidget-prefix .R-ImageContainer.isLoading {
    filter: blur(4px);
  }
  .CarouselWidget-prefix .R-ImageContainer.isLoaded {
    filter: blur(0px);
  }
  @media (max-width: 768px) {
    .CarouselWidget-prefix .R-ImageContainer {
      min-height: auto;
      height: auto;
    }
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-ImageContainer {
      height: auto;
      min-height: auto;
    }
  }
  .CarouselWidget-prefix .R-ImageContainer .R-ImageContainer__src {
    max-width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    outline: none;
  }
  @media (max-width: 768px) {
    .CarouselWidget-prefix .R-ImageContainer .R-ImageContainer__src {
      position: relative;
      height: auto;
    }
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .R-ImageContainer .R-ImageContainer__src {
      position: relative;
      height: auto;
    }
  }
  .CarouselWidget-prefix [r-tooltip] {
    position: relative;
  }
  .CarouselWidget-prefix [r-tooltip]::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px 6px 0;
    border-style: solid;
    border-color: var(--tooltip-bg-color, #0e1311) transparent transparent
      transparent;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    cursor: default;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix [r-tooltip]::before {
      border-color: #0e1311 transparent transparent transparent;
    }
  }
  .CarouselWidget-prefix [r-tooltip]::after {
    content: attr(r-tooltip);
    position: absolute;
    left: 50%;
    top: -6px;
    transform: translateX(-50%) translateY(-100%);
    background: var(--tooltip-bg-color, #0e1311);
    text-align: center;
    color: var(--tooltip-text-color, #fff);
    padding: 8px 10px;
    font-size: 12px;
    line-height: 16px;
    font-family: inherit;
    font-weight: 400;
    text-transform: none;
    min-width: 80px;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    z-index: 120;
    cursor: default;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix [r-tooltip]::after {
      background: #0e1311;
    }
  }
  .CarouselWidget-prefix [r-tooltip]:hover::after,
  .CarouselWidget-prefix [r-tooltip]:hover::before {
    opacity: 1;
    transition-delay: 0s;
    pointer-events: auto;
  }
  .CarouselWidget-prefix [r-tooltip-position="left"]::before {
    left: 0%;
    top: 50%;
    margin-left: -12px;
    transform: translatey(-50%) rotate(-90deg);
  }
  .CarouselWidget-prefix [r-tooltip-position="left"]::after {
    left: 0%;
    top: 50%;
    margin-left: -8px;
    transform: translateX(-100%) translateY(-50%);
  }
  .CarouselWidget-prefix [r-tooltip-position="top"]::before {
    left: 50%;
  }
  .CarouselWidget-prefix [r-tooltip-position="top"]::after {
    left: 50%;
  }
  .CarouselWidget-prefix [r-tooltip-position="bottom"]::before {
    top: 100%;
    margin-top: 8px;
    transform: translateX(-50%) translatey(-100%) rotate(-180deg);
  }
  .CarouselWidget-prefix [r-tooltip-position="bottom"]::after {
    top: 100%;
    margin-top: 8px;
    transform: translateX(-50%) translateY(0%);
  }
  .CarouselWidget-prefix [r-tooltip-position="right"]::before {
    left: 100%;
    top: 50%;
    margin-left: 1px;
    transform: translatey(-50%) rotate(90deg);
  }
  .CarouselWidget-prefix [r-tooltip-position="right"]::after {
    left: 100%;
    top: 50%;
    margin-left: 8px;
    transform: translateX(0%) translateY(-50%);
  }
  .CarouselWidget-prefix [r-tooltip-align="left"]::after {
    text-align: left;
    left: 0;
    transform: translateX(0%) translateY(-100%);
  }
  .CarouselWidget-prefix [r-tooltip-position-align="right"]::after {
    left: auto;
    right: -6px;
    transform: none;
  }
  .CarouselWidget-prefix [r-tooltip-position-align="left"]::after {
    left: -6px;
    right: auto;
    transform: none;
  }
  .CarouselWidget-prefix [r-tooltip-position-align="topRight"]::before {
    left: auto;
    right: 10%;
    transform: none;
  }
  .CarouselWidget-prefix [r-tooltip-position-align="topRight"]::after {
    left: auto;
    right: -6px;
    transform: translateX(0%) translateY(-100%);
  }
  .CarouselWidget-prefix [r-tooltip-position-align="topLeft"]::before {
    left: 10%;
    transform: none;
  }
  .CarouselWidget-prefix [r-tooltip-position-align="topLeft"]::after {
    left: -6px;
    right: auto;
    transform: translateX(0%) translateY(-100%);
  }
  .CarouselWidget-prefix [r-tooltip-position-align="bottomRight"]::after {
    left: auto;
    right: -6px;
    transform: translateX(0%) translateY(-100%);
  }
  .CarouselWidget-prefix [r-tooltip-position-align="bottomLeft"]::after {
    left: -6px;
    right: auto;
    transform: translateX(0%) translateY(-100%);
  }
  .CarouselWidget-prefix [r-tooltip-size="280"]::after {
    min-width: 280px;
  }
  .CarouselWidget-prefix [r-tooltip-size="180"]::after {
    min-width: 180px;
  }
  .CarouselWidget-prefix [r-tooltip-size="160"]::after {
    min-width: 160px;
  }
  .CarouselWidget-prefix [r-tooltip-size="140"]::after {
    min-width: 140px;
  }
  .CarouselWidget-prefix [r-tooltip-size="100"]::after {
    min-width: 100px;
  }
  .CarouselWidget-prefix [r-tooltip-size="80"]::after {
    min-width: 80px;
  }
  .CarouselWidget-prefix [r-tooltip-size="60"]::after {
    min-width: 60px;
  }
  .CarouselWidget-prefix [r-tooltip-fontsize="xxxxs"]::after {
    font-size: 11px;
    line-height: 16px;
  }
  .CarouselWidget-prefix [r-tooltip-fontsize="xxxs"]::after {
    font-size: 12px;
    line-height: 18px;
  }
  .CarouselWidget-prefix .isTooltipHidden:after,
  .CarouselWidget-prefix .isTooltipHidden:before {
    display: none;
  }
  @keyframes CircleSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @-webkit-keyframes CircleSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @keyframes staticZoomInOut--sm {
    0% {
      transform: translatey(0px) scale(0.92);
    }
    50% {
      transform: translatey(0px) scale(1);
    }
    100% {
      transform: translatey(0px) scale(0.92);
    }
  }
  @keyframes staticZoomInOut--md {
    0% {
      transform: translatey(0px) scale(0.8);
    }
    50% {
      transform: translatey(0px) scale(1);
    }
    100% {
      transform: translatey(0px) scale(0.8);
    }
  }
  @keyframes R-shakeAnimation--md {
    0% {
      transform: rotateZ(-6deg);
    }
    25% {
      transform: rotateZ(6deg);
    }
    50% {
      transform: rotateZ(-6deg);
    }
    75% {
      transform: rotateZ(6deg);
    }
    100% {
      transform: rotateZ(0);
    }
  }

  .CarouselWidget-prefix .CarouselWidget {
    font-size: var(--base-font-size, 16px);
    display: inline-block;
    max-width: var(--base-maxwidth, 100%);
    width: 100%;
    margin-bottom: 20px;
    --logo-normal: url(https://assets.reviews.io/img/all-global-assets/logo/reviewsio-logo.svg);
    --logo-inverted: url(https://assets.reviews.io/img/all-global-assets/logo/reviewsio-logo--inverted.svg);
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .R-ReviewsioLogo
    .R-ReviewsioLogo__image {
    content: var(--reviewsio-logo-style);
  }
  .CarouselWidget-prefix .CarouselWidget .CarouselWidget__inner {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header {
    flex-basis: var(--header-width, 160px);
    width: var(--header-width, 160px);
    flex-shrink: 0;
    order: var(--header-order, 1);
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header {
      flex-basis: 160px;
      width: 160px;
      order: 1;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .CarouselWidget__header
    .header__inner {
    background-color: var(--header-bg-start-color, transparent);
    background-image: linear-gradient(
      var(--header-gradient-direction, 135deg),
      var(--header-bg-start-color, transparent),
      var(--header-bg-end-color, transparent)
    );
    padding: var(--header-padding, 0.5em);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: var(--header-border-width, 0) solid
      var(--header-border-color, rgba(0, 0, 0, 0.1));
    border-radius: var(--header-border-radius, 0px);
    box-shadow: 0 calc(var(--header-shadow-size, 0px) * 0.5)
      var(--header-shadow-size, 0px) -4px var(--header-shadow-color, rgba(0, 0, 0, 0.1));
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .CarouselWidget
      .CarouselWidget__header
      .header__inner {
      background-color: transparent;
      padding: 0.5em;
      box-shadow: 0;
      border-radius: 0;
    }
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .CarouselWidget__header
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon {
    --override-header-star-color: var(
      --header-star-color,
      var(--common-star-color)
    );
    --override-header-star-disabled-color: var(
      --header-disabled-star-color,
      var(--common-star-disabled-color)
    );
    color: var(--override-header-star-color);
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .CarouselWidget__header
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--disabled {
    color: var(--override-header-star-disabled-color);
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .CarouselWidget
      .CarouselWidget__header
      .R-RatingStars
      .R-RatingStars__stars
      .stars__icon {
      color: #0e1311;
    }
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .CarouselWidget__header
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--0 {
    color: var(--override-header-star-disabled-color);
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .CarouselWidget__header
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--25 {
    background-image: linear-gradient(
      to right,
      var(--override-header-star-color) 38%,
      var(--override-header-star-disabled-color) 38.1%
    );
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .CarouselWidget__header
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--50 {
    background-image: linear-gradient(
      to right,
      var(--override-header-star-color) 50%,
      var(--override-header-star-disabled-color) 50.1%
    );
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .CarouselWidget__header
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon--75 {
    background-image: linear-gradient(
      to right,
      var(--override-header-star-color) 60%,
      var(--override-header-star-disabled-color) 60.1%
    );
  }
  .CarouselWidget-prefix .CarouselWidget .CarouselWidget__list {
    flex-basis: calc(100% - var(--header-width, 160px));
    width: calc(100% - var(--header-width, 160px));
    order: 1;
  }

  .CarouselWidget-prefix
    .CarouselWidget
    .R-ReviewsList-container
    .R-ReviewsList__controlButton--right {
    right: var(--scroll-button-horizontal-position, 0px);
    left: auto;
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .R-ReviewsList-container
    .R-ReviewsList__controlButton--left {
    right: var(--scroll-button-horizontal-position, 0px);
    left: 235px;
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .R-ReviewsList-container
    .R-ReviewsList__controlButton {
    position: absolute;
    display: inline-flex;
    height: calc(var(--scroll-button-icon-size, 24px) * 2);
    width: calc(var(--scroll-button-icon-size, 24px) * 2);
    line-height: calc(var(--scroll-button-icon-size, 24px) * 2);
    top: var(--scroll-button-vertical-position, 0);
    bottom: -100px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: var(--scroll-button-icon-size, 24px);
    color: var(--scroll-button-icon-color, #0e1311);
    text-align: center;
    text-decoration: none;
    background-color: var(--scroll-button-bg-color, transparent);
    justify-content: center;
    align-items: center;
    border: var(--scroll-button-border-width, 0px) solid
      var(--scroll-button-border-color, rgba(0, 0, 0, 0.1));
    border-radius: var(--scroll-button-border-radius, 60px);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    z-index: 5;
    box-shadow: 0 0 var(--scroll-button-shadow-size, 0px) 0
      var(--scroll-button-shadow-color, rgba(0, 0, 0, 0.2));
    cursor: pointer;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .CarouselWidget .CarouselWidget__list {
      flex-basis: calc(100% - 160px);
      width: calc(100% - 160px);
    }
  }
  .CarouselWidget-prefix .CarouselWidget .R-ReviewsList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    min-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  .CarouselWidget-prefix .CarouselWidget .R-ReviewsList::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .CarouselWidget .R-ReviewsList {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    flex-basis: calc(100% / var(--item-maximum-columns, 5));
    width: calc(100% / var(--item-maximum-columns, 5));
    flex-shrink: 0;
    scroll-snap-align: start;
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .R-ReviewsList
    .R-ReviewsList__item--body {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--body-max-lines, 3);
    -webkit-box-orient: vertical;
  }
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
      flex-basis: calc(100% / 5);
      width: calc(100% / 5);
      max-width: calc(100% / 5);
    }
  }
  .CarouselWidget-prefix
    .CarouselWidget
    .R-ReviewsList
    .R-ReviewsList__item
    .item__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    flex-basis: 100%;
    padding: var(--item-padding, 0.5em);
    background-color: var(--item-background-start-color, transparent);
    background-image: linear-gradient(
      var(--item-gradient-direction, 135deg),
      var(--item-background-start-color, transparent),
      var(--item-background-end-color, transparent)
    );
    border: var(--item-border-width, 0) solid
      var(--item-border-color, rgba(0, 0, 0, 0.1));
    border-radius: var(--item-border-radius, 0px);
    box-shadow: 0 calc(var(--item-shadow-size, 0px) * 0.25)
      var(--item-shadow-size, 0px) -4px var(--item-shadow-color, rgba(0, 0, 0, 0.2));
  }

  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList
      .R-ReviewsList__item
      .item__inner {
      padding: 0.5em;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
  @media only screen and (-ms-high-contrast: active) and (max-width: 1919px),
    screen and (-ms-high-contrast: none) and (max-width: 1919px) {
    .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
      flex-basis: calc(100% / 5);
      width: calc(100% / 5);
      max-width: calc(100% / 5);
    }

    @media only screen and (-ms-high-contrast: active) and (max-width: 1440px),
      screen and (-ms-high-contrast: none) and (max-width: 1440px) {
      .CarouselWidget-prefix
        .CarouselWidget
        .R-ReviewsList
        .R-ReviewsList__item {
        flex-basis: calc(100% / 4);
        width: calc(100% / 4);
        max-width: calc(100% / 4);
      }
    }
    @media only screen and (-ms-high-contrast: active) and (max-width: 1140px),
      screen and (-ms-high-contrast: none) and (max-width: 1140px) {
      .CarouselWidget-prefix
        .CarouselWidget
        .R-ReviewsList
        .R-ReviewsList__item {
        flex-basis: calc(100% / 3);
        width: calc(100% / 3);
        max-width: calc(100% / 3);
      }
    }
    @media only screen and (-ms-high-contrast: active) and (max-width: 992px),
      screen and (-ms-high-contrast: none) and (max-width: 992px) {
      .CarouselWidget-prefix
        .CarouselWidget
        .R-ReviewsList
        .R-ReviewsList__item {
        flex-basis: 50%;
        width: 50%;
      }
    }
    @media only screen and (-ms-high-contrast: active) and (max-width: 768px),
      screen and (-ms-high-contrast: none) and (max-width: 768px) {
      .CarouselWidget-prefix
        .CarouselWidget
        .R-ReviewsList
        .R-ReviewsList__item {
        flex-basis: 100%;
        width: 100%;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList
      .R-ReviewsList__item--fullwidth {
      flex-basis: 100%;
      width: 100%;
    }
    .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container {
      position: relative;
      padding-left: calc(
        (var(--scroll-button-icon-size, 24px) * 2) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 2) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
      margin-right: -10px;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container {
        padding-left: 48px;
        padding-right: 48px;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList-container
      .R-ReviewsList__controlButton {
      position: absolute;
      display: inline-flex;
      height: calc(var(--scroll-button-icon-size, 24px) * 2);
      width: calc(var(--scroll-button-icon-size, 24px) * 2);
      line-height: calc(var(--scroll-button-icon-size, 24px) * 2);
      top: var(--scroll-button-vertical-position, 0);
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      font-size: var(--scroll-button-icon-size, 24px);
      color: var(--scroll-button-icon-color, #0e1311);
      text-align: center;
      text-decoration: none;
      background-color: var(--scroll-button-bg-color, transparent);
      justify-content: center;
      align-items: center;
      border: var(--scroll-button-border-width, 0px) solid
        var(--scroll-button-border-color, rgba(0, 0, 0, 0.1));
      border-radius: var(--scroll-button-border-radius, 60px);
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      z-index: 5;
      box-shadow: 0 0 var(--scroll-button-shadow-size, 0px) 0
        var(--scroll-button-shadow-color, rgba(0, 0, 0, 0.2));
      cursor: pointer;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix
        .CarouselWidget
        .R-ReviewsList-container
        .R-ReviewsList__controlButton {
        height: 48px;
        width: 48px;
        line-height: 48px;
        top: 0;
        font-size: 24px;
        color: #0e1311;
        background-color: transparent;
        border: 0;
        border-radius: 60px;
        box-shadow: none;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList-container
      .R-ReviewsList__controlButton
      .controlButton__icon {
      display: inline-block;
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList-container
      .R-ReviewsList__controlButton:hover {
      transform: scale(1.15, 1.15);
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList-container
      .R-ReviewsList__controlButton--left {
      left: var(--scroll-button-horizontal-position, 0px);
      right: auto;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix
        .CarouselWidget
        .R-ReviewsList-container
        .R-ReviewsList__controlButton--left {
        left: 0;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList-container
      .R-ReviewsList__controlButton--left
      .controlButton__icon {
      margin-left: -0.1em;
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList-container
      .R-ReviewsList__controlButton--right {
      right: var(--scroll-button-horizontal-position, 0px);
      left: auto;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix
        .CarouselWidget
        .R-ReviewsList-container
        .R-ReviewsList__controlButton--right {
        right: 0;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .R-ReviewsList-container
      .R-ReviewsList__controlButton--right
      .controlButton__icon {
      margin-right: -0.1em;
    }
    .CarouselWidget-prefix .CarouselWidget .cssVar-starDisplay {
      display: var(--x-small-star-display, inline-flex);
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .CarouselWidget .cssVar-starDisplay {
        display: inline-flex;
      }
    }
    .CarouselWidget-prefix .CarouselWidget .cssVar-authorName {
      font-size: var(--author-font-size, inherit);
      font-weight: var(--author-font-weight, inherit);
      text-transform: var(--author-text-transform, inherit);
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .CarouselWidget .cssVar-authorName {
        font-size: inherit;
        font-weight: inherit;
        text-transform: inherit;
      }
    }
    .CarouselWidget-prefix .CarouselWidget .cssVar-header__heading {
      color: var(--header-heading-text-color, inherit);
      font-size: var(--header-heading-font-size, inherit);
      font-weight: var(--header-heading-font-weight, inherit);
      line-height: var(--header-heading-line-height, inherit);
      text-transform: var(--header-heading-text-transform, inherit);
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .CarouselWidget .cssVar-header__heading {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        line-height: inherit;
        text-transform: none;
      }
    }
    .CarouselWidget-prefix .CarouselWidget .cssVar-header__subheading {
      color: var(--header-subheading-text-color, inherit);
      font-size: var(--header-subheading-font-size, inherit);
      font-weight: var(--header-subheading-font-weight, inherit);
      line-height: var(--header-subheading-line-height, inherit);
      text-transform: var(--header-subheading-text-transform, inherit);
    }
    .CarouselWidget-prefix
      .CarouselWidget
      .cssVar-header__subheading
      .cssVar-subheading__number {
      font-weight: var(--header-subheading-font-weight, inherit);
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .CarouselWidget .R-PhotoVideoThumbnail {
        max-height: 60px;
      }
    }
    .CarouselWidget-prefix .CarouselWidget--sideHeader--withcards {
      --reviewsio-logo-style: var(--logo-inverted);
      --header-width: 280px;
      --header-bg-start-color: #0e1311;
      --header-bg-end-color: #0e1311;
      --header-padding: 1.5em;
      --header-shadow-size: 10px;
      --header-shadow-color: rgba(0, 0, 0, 0.05);
      --header-star-color: #ffffff;
      --header-heading-text-color: #ffffff;
      --header-subheading-text-color: #ffffff;
      --header-subheading-font-weight: 300;
      --item-maximum-columns: 5;
      --item-background-start-color: #ffffff;
      --item-background-end-color: #ffffff;
      --item-padding: 1.5em;
      --item-shadow-size: 10px;
      --item-shadow-color: rgba(0, 0, 0, 0.05);
      --scroll-button-horizontal-position: 3px;
    }
    .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__inner {
      flex-direction: column;
    }
    .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__header {
      flex-basis: 100%;
      width: 100%;
      flex-shrink: 1;
      padding: 0;
      margin-bottom: 20px;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix
        .CarouselWidget--topHeader
        .CarouselWidget__header {
        flex: 1 1 auto !important;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget--topHeader
      .CarouselWidget__header
      .header__inner {
      padding: 0;
      flex-direction: row;
      align-items: baseline;
      flex-wrap: wrap;
    }
    .CarouselWidget-prefix
      .CarouselWidget--topHeader
      .CarouselWidget__header
      .R-RatingStars {
      position: relative;
      bottom: calc((var(--medium-star-size, 22px) / 9) * -1);
    }
    .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__list {
      flex-basis: 100%;
      width: 100%;
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__list {
        flex: 1 1 auto !important;
      }
    }
    .CarouselWidget-prefix .CarouselWidget--topHeader .R-ReviewsList-container {
      margin-left: -10px;
    }
    .CarouselWidget-prefix .CarouselWidget--topHeader .R-ReviewsList {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
    .CarouselWidget-prefix .CarouselWidget--topHeader--withcards {
      --item-maximum-columns: 5;
      --item-background-start-color: #ffffff;
      --item-background-end-color: #ffffff;
      --item-padding: 1.5em;
      --item-shadow-size: 10px;
      --item-shadow-color: rgba(0, 0, 0, 0.05);
    }
    .CarouselWidget-prefix
      .CarouselWidget:not(.CarouselWidget--scrollButtons-top)
      .CarouselWidget__footer {
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 2) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
    }
    .CarouselWidget-prefix .CarouselWidget--scrollButtons-coloured {
      --scroll-button-bg-color: #ffffff;
      --scroll-button-shadow-size: 20px;
      --scroll-button-shadow-color: rgba(0, 0, 0, 0.05);
      --scroll-button-horizontal-position: 12px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--sideHeader--withcards):not(.CarouselWidget--scrollButtons-top)
      .R-ReviewsList-container {
      padding-left: calc(
        (var(--scroll-button-icon-size, 24px) * 3.5) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 3.5) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
    }
    .CarouselWidget-prefix
      .CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--sideHeader--withcards):not(.CarouselWidget--scrollButtons-top)
      .CarouselWidget__footer {
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 3.5) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
    }
    .CarouselWidget-prefix .CarouselWidget--scrollButtons-top {
      --scroll-button-vertical-position: -15px;
      --scroll-button-horizontal-position: 0px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--scrollButtons-top
      .R-ReviewsList-container {
      padding-left: 10px;
      padding-right: 0;
    }
    .CarouselWidget-prefix
      .CarouselWidget--scrollButtons-top
      .R-ReviewsList-container
      .R-ReviewsList__controlButton {
      font-size: calc(var(--scroll-button-icon-size, 24px) * 0.8);
      height: calc(var(--scroll-button-icon-size, 24px) * 1.2);
      width: calc(var(--scroll-button-icon-size, 24px) * 1.2);
      line-height: calc(var(--scroll-button-icon-size, 24px) * 1.2);
      bottom: auto;
      top: var(--scroll-button-vertical-position, -15px);
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix
        .CarouselWidget--scrollButtons-top
        .R-ReviewsList-container
        .R-ReviewsList__controlButton {
        top: -15px;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget--scrollButtons-top
      .R-ReviewsList-container
      .R-ReviewsList__controlButton--left {
      left: auto;
      right: calc(
        (var(--scroll-button-icon-size, 24px) * 1.4) + 10px +
          var(--scroll-button-horizontal-position, 0)
      );
    }
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      .CarouselWidget-prefix
        .CarouselWidget--scrollButtons-top
        .R-ReviewsList-container
        .R-ReviewsList__controlButton--left {
        right: 44px;
      }
    }
    .CarouselWidget-prefix
      .CarouselWidget--scrollButtons-top
      .R-ReviewsList-container
      .R-ReviewsList__controlButton--right {
      right: 10px;
      left: auto;
    }
    .CarouselWidget-prefix
      .CarouselWidget--scrollButtons-top.CarouselWidget--topHeader
      .R-ReviewsList-container {
      padding-left: 0;
      padding-right: 0;
      margin-left: -10px;
    }
    .CarouselWidget-prefix .CarouselWidget--bulky {
      --base-font-size: 18px;
      --base-maxwidth: 768px;
      --medium-star-size: 28px;
      --x-small-star-size: 16px;
      --header-heading-font-size: 1.3em;
      --item-maximum-columns: 1;
      --badge-icon-font-size: 20px;
      --badge-text-font-size: 1.2em;
      --author-font-size: 1.2em;
      flex-basis: calc(100% / (var(--item-maximum-columns, 1)));
      width: calc(100% / (var(--item-maximum-columns, 1)));
    }
    .CarouselWidget-prefix .CarouselWidget--bulky--withcards {
      --item-background-start-color: #ffffff;
      --item-background-end-color: #ffffff;
      --item-padding: 1.5em;
      --item-shadow-size: 10px;
      --item-shadow-color: rgba(0, 0, 0, 0.05);
    }
    .CarouselWidget-prefix .CarouselWidget--photocarousel {
      --item-maximum-columns: 8;
    }
    .CarouselWidget-prefix .CarouselWidget--videocarousel {
      --item-maximum-columns: 5;
    }
    .CarouselWidget-prefix .R-XXXXLG .R-ReviewsList .R-ReviewsList__item {
      flex-basis: calc(100% / (var(--item-maximum-columns, 5) - 1));
      width: calc(100% / (var(--item-maximum-columns, 5) - 1));
    }
    .CarouselWidget-prefix .R-XXXLG .R-ReviewsList .R-ReviewsList__item {
      flex-basis: calc(100% / (var(--item-maximum-columns, 5) - 1));
      width: calc(100% / (var(--item-maximum-columns, 5) - 1));
    }
    .CarouselWidget-prefix .R-XXLG .R-ReviewsList .R-ReviewsList__item {
      flex-basis: calc(100% / (var(--item-maximum-columns, 5) - 2));
      width: calc(100% / (var(--item-maximum-columns, 5) - 2));
    }
    .CarouselWidget-prefix .R-XLG .R-ReviewsList .R-ReviewsList__item {
      flex-basis: 50%;
      width: 50%;
    }
    .CarouselWidget-prefix
      .R-XLG.CarouselWidget--bulky
      .R-ReviewsList
      .R-ReviewsList__item,
    .CarouselWidget-prefix
      .R-XLG.CarouselWidget--bulky--withcards
      .R-ReviewsList
      .R-ReviewsList__item {
      flex-basis: 100%;
      width: 100%;
    }
    .CarouselWidget-prefix .R-LG .R-ReviewsList .R-ReviewsList__item {
      flex-basis: 100%;
      width: 100%;
    }
    .CarouselWidget-prefix .R-MD .CarouselWidget__inner {
      flex-direction: column;
    }
    .CarouselWidget-prefix .R-MD .CarouselWidget__header {
      flex-basis: 100%;
      width: 100%;
      flex-shrink: 1;
      margin-bottom: 20px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: calc(
        (var(--scroll-button-icon-size, 24px) * 2) -
          (var(--scroll-button-horizontal-position, 0px) - 5px)
      );
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 2) -
          (var(--scroll-button-horizontal-position, 0px) - 5px)
      );
    }
    .CarouselWidget-prefix .R-MD .CarouselWidget__list {
      flex-basis: 100%;
      width: 100%;
    }
    .CarouselWidget-prefix .R-MD .R-ReviewsList-container {
      margin-right: 0;
      margin-left: 0;
    }
    .CarouselWidget-prefix
      .R-MD.CarouselWidget--scrollButtons-top
      .CarouselWidget__header {
      padding-left: 10px;
      padding-right: 10px;
    }
    .CarouselWidget-prefix
      .R-MD.CarouselWidget--scrollButtons-top
      .R-ReviewsList-container {
      padding-left: 0;
      padding-right: 0;
    }
    .CarouselWidget-prefix
      .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top)
      .CarouselWidget__header {
      padding-left: calc(
        (var(--scroll-button-icon-size, 24px) * 2.2) -
          (var(--scroll-button-horizontal-position, 0px) - 5px)
      );
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 2.2) -
          (var(--scroll-button-horizontal-position, 0px) - 5px)
      );
    }
    .CarouselWidget-prefix
      .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top)
      .R-ReviewsList-container {
      padding-left: calc(
        (var(--scroll-button-icon-size, 24px) * 2.2) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 2.2) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
    }
    .CarouselWidget-prefix
      .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top):not(.CarouselWidget--sideHeader--withcards)
      .CarouselWidget__header {
      padding-left: calc(
        (var(--scroll-button-icon-size, 24px) * 3.5) -
          (var(--scroll-button-horizontal-position, 0px) - 5px)
      );
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 3.5) -
          (var(--scroll-button-horizontal-position, 0px) - 5px)
      );
    }
    .CarouselWidget-prefix
      .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top):not(.CarouselWidget--sideHeader--withcards)
      .R-ReviewsList-container {
      padding-left: calc(
        (var(--scroll-button-icon-size, 24px) * 3.5) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
      padding-right: calc(
        (var(--scroll-button-icon-size, 24px) * 3.5) -
          (var(--scroll-button-horizontal-position, 0px) + 5px)
      );
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList__controlButton {
      top: -10px;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList {
      padding-top: 40px;
      padding-bottom: 40px;
      perspective: 1440px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item
      .SocialProofBanner {
      width: 100%;
      box-shadow: 0 4px 15px -4px rgba(0, 0, 0, 0.1);
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item.isLeftThird {
      z-index: 14;
      transform: rotateY(75deg) scale(0.7, 0.7);
      margin-right: -160px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item.isLeftSecond {
      z-index: 16;
      transform: rotateY(60deg) scale(0.8, 0.8);
      margin-right: -120px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item.isLeftFirst {
      z-index: 18;
      transform: rotateY(45deg) scale(0.9, 0.9);
      margin-right: -80px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item.isCenter {
      z-index: 20;
      transform: rotateY(0deg);
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item.isRightFirst {
      z-index: 18;
      transform: rotateY(-45deg) scale(0.9, 0.9);
      margin-left: -80px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item.isRightSecond {
      z-index: 16;
      transform: rotateY(-60deg) scale(0.8, 0.8);
      margin-left: -120px;
    }
    .CarouselWidget-prefix
      .CarouselWidget--socialProofBanners
      .CarouselWidget__list
      .R-ReviewsList-container
      .R-ReviewsList
      .R-ReviewsList__item.isRightThird {
      z-index: 14;
      transform: rotateY(-75deg) scale(0.7, 0.7);
      margin-left: -160px;
    }
  }
}

/* CSS for tablet and mobile */
@media screen and (max-width: 768px) {
  .R-ReviewsList-container {
    display: none;
  }
  .reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__inner {
    flex-direction: column;
    align-items: center;
  }
  .cssVar-header__heading {
    font-size: 30px;
  }
  .reviews
    .CarouselWidget-prefix
    .R-RatingStars
    .R-RatingStars__stars
    .stars__icon {
    font-size: 34px;
  }

  .reviews .CarouselWidget-prefix .R-TextBody--xxs {
    font-size: 16px;
  }
  .reviews .CarouselWidget-prefix .R-ReviewsioLogo .R-ReviewsioLogo__image {
    margin-top: 10px;
  }
}
