@charset "UTF-8";
/* Colours
========== */
/* Brand Colours
================ */
/* Element Colours
================== */
/* Misc Colours
================ */
/* Fonts
======== */
/* Transitions
============= */
/* Breakpoints
============== */
@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css";
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #13243a;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: black;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1150px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1110px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1150px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #13243a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #060c14 !important;
}

.bg-secondary {
  background-color: #f7aa00 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #c48700 !important;
}

.bg-success {
  background-color: #4caf50 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #13243a !important;
}

.border-secondary {
  border-color: #f7aa00 !important;
}

.border-success {
  border-color: #4caf50 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1150px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1150px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1150px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1150px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1150px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #13243a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: black !important;
}

.text-secondary {
  color: #f7aa00 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #ab7500 !important;
}

.text-success {
  color: #4caf50 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #357a38 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* base styles: opinionated defaults */
* {
  box-sizing: border-box;
}

html,
body {
  background: #fff;
}

html {
  color: #454e50;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-size: 1.6em;
  font-weight: 400;
  font-family: "Brandon Text", sans-serif;
  line-height: 1.75;
  color: #454e50;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

::placeholder {
  color: #454e50;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #454e50;
}

::-ms-input-placeholder {
  color: #454e50;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #454e50;
  margin: 15px 0;
  padding: 0;
}

audio,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

a,
input[type=submit],
a span,
.button {
  transition: 300ms;
  border-radius: 0;
}

p {
  margin: 0 0 25px;
}

ul,
ol {
  padding-left: 18px;
  margin: 0 0 25px;
}

li {
  margin-bottom: 25px;
}

a,
ul,
li {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
  height: auto;
  aspect-ratio: auto;
}

strong {
  font-weight: 700;
}

blockquote {
  position: relative;
  margin: 60px 0;
  padding: 30px 50px;
  border-top: 1px solid #f7aa00;
  border-bottom: 1px solid #f7aa00;
  color: #f7aa00;
}
@media (max-width: 575.98px) {
  blockquote {
    padding: 30px;
  }
}
blockquote p, blockquote li {
  line-height: 1.25;
  font-size: 2.4rem;
  font-weight: 500;
  color: #f7aa00;
}
@media (max-width: 575.98px) {
  blockquote p, blockquote li {
    font-size: 2rem;
  }
}
blockquote > *:last-child {
  margin-bottom: 0;
}
blockquote:before {
  content: "“";
  position: absolute;
  top: 30px;
  left: 15px;
  font-size: 4rem;
  line-height: 1;
}
@media (max-width: 575.98px) {
  blockquote:before {
    left: 5px;
  }
}
blockquote:after {
  content: "”";
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-size: 4rem;
  line-height: 1;
}
@media (max-width: 575.98px) {
  blockquote:after {
    right: 5px;
  }
}

.full-width {
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
}

.block-space,
.wp-block-group__inner-container {
  padding-top: 85px;
  padding-bottom: 85px;
}
@media (max-width: 767.98px) {
  .block-space,
.wp-block-group__inner-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.block-space--no-top,
.wp-block-group__inner-container--no-top {
  padding-top: 0;
}
.block-space--no-bottom,
.wp-block-group__inner-container--no-bottom {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .block-space--2x-bottom,
.wp-block-group__inner-container--2x-bottom {
    padding-bottom: 170px;
  }
}
@media (min-width: 768px) {
  .block-space--2x-top,
.wp-block-group__inner-container--2x-top {
    padding-top: 170px;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain;
  background-position: 100%;
  background-repeat: no-repeat;
}

.bg-white {
  background: #fff;
}

.bg-grey {
  background: #eee;
}

.bg-black {
  background: #000;
}

.bg-primary h1,
.bg-primary h2,
.bg-primary h3,
.bg-primary h4,
.bg-primary h5,
.bg-primary h6,
.bg-primary p,
.bg-primary li {
  color: #fff;
}
.bg-primary a:not(.btn) {
  color: #fff;
}
.bg-primary a:not(.btn):hover, .bg-primary a:not(.btn):focus {
  color: #f7aa00;
}
.bg-primary .btn--secondary:not(.btn--hollow) {
  background-color: #f7aa00;
}
.bg-primary .btn--secondary:hover, .bg-primary .btn--secondary:focus {
  background-color: #13243a;
}
.bg-primary .stepped-border,
.bg-primary .stepped-border:before,
.bg-primary .stepped-border:after {
  border-color: #fff;
}

.bg-secondary h1,
.bg-secondary h2,
.bg-secondary h3,
.bg-secondary h4,
.bg-secondary h5,
.bg-secondary h6,
.bg-secondary p,
.bg-secondary li {
  color: #13243a;
}
.bg-secondary a:not(.btn) {
  color: #13243a;
}
.bg-secondary a:not(.btn):hover, .bg-secondary a:not(.btn):focus {
  color: rgba(19, 36, 58, 0.75);
}
.bg-secondary .btn:not(.btn--hollow) {
  background-color: #13243a;
  color: #fff;
}
.bg-secondary .btn:hover, .bg-secondary .btn:focus {
  background-color: #f7aa00;
}
.bg-secondary .link:before {
  background: url(../images/arrow-solid-white.svg) center no-repeat;
  background-size: contain;
}
.bg-secondary .stepped-border,
.bg-secondary .stepped-border:before,
.bg-secondary .stepped-border:after {
  border-color: #13243a;
}

.bg-tertiary h1,
.bg-tertiary h2,
.bg-tertiary h3,
.bg-tertiary h4,
.bg-tertiary h5,
.bg-tertiary h6 {
  color: #fff;
}
@media only screen and (min-width: 600px) {
  .bg-tertiary a:not(.btn):hover, .bg-tertiary a:not(.btn):focus {
    color: #fff;
  }
}
.bg-tertiary .btn:not(.btn--hollow) {
  background-color: #f7aa00;
  color: #13243a;
}
.bg-tertiary .btn:hover, .bg-tertiary .btn:focus {
  background-color: #13243a;
  color: #fff;
}

.bg-secondary-fade {
  background-color: #fff6e5;
}

.bg-secondary-fade-darker {
  background-color: rgba(247, 170, 0, 0.25);
}

.bg-tertiary {
  background-color: #87d0f1;
}

.bg-tertiary-fade {
  background-color: #f3fbfd;
}

.bg-tertiary-fade-darker {
  background-color: rgba(135, 208, 241, 0.25);
}

.bg-tertiary-fade-light {
  background-color: #e1f3fb;
}

.bg-form {
  background-color: #e1f3fc;
}

.bg-form-fade {
  background-color: #e1f3fc;
}

.bg-form-fade-darker {
  background-color: rgba(225, 243, 252, 0.25);
}

.bg-quaternary {
  background-color: #454e50;
}

.half-margin {
  margin-bottom: 10px;
}

.theme-dark {
  color: #fff;
}
.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark h6,
.theme-dark p,
.theme-dark li {
  color: #fff;
}
.theme-dark a:not(.btn) {
  color: #fff;
}
.theme-dark a:not(.btn):hover, .theme-dark a:not(.btn):focus {
  color: #f7aa00;
}

.remove-underlines {
  text-decoration: none !important;
}
.remove-underlines * {
  text-decoration: none !important;
}

.remove-bullets li:before {
  display: none;
}

.last-margin > *:last-child {
  margin-bottom: 0;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

@media (max-width: 575.98px) {
  .desktop-copy {
    display: none;
  }
}

.no-padding {
  padding: 0 !important;
}

.primary-links a:not(.btn) {
  color: #13243a;
}
.primary-links a:not(.btn):hover, .primary-links a:not(.btn):focus {
  color: #f7aa00;
}

.half-gutters {
  margin: 0 -7.5px;
}
.half-gutters > * {
  padding: 0 7.5px;
}

.btn-no-style {
  border: none;
  background: transparent;
}

.no-margin {
  margin: 0;
}

.u-margin-top-medium-n {
  margin-top: -30px;
}

.reduce-margin {
  margin-bottom: 20px;
}

.reduce-margin-bottom {
  margin-bottom: 25px;
}

.sf-border-radius {
  border-radius: 0.6rem;
}

.bold-links a:not(.btn) {
  font-weight: bold;
}

.reduce-list-margin li {
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.white-space-prewrap {
  white-space: pre-wrap;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-BoldItalic.woff2") format("woff2"), url("../fonts/BrandonText-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-MediumItalic.woff2") format("woff2"), url("../fonts/BrandonText-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Light.woff2") format("woff2"), url("../fonts/BrandonText-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Bold.woff2") format("woff2"), url("../fonts/BrandonText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Medium.woff2") format("woff2"), url("../fonts/BrandonText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Regular.woff2") format("woff2"), url("../fonts/BrandonText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Thin.woff2") format("woff2"), url("../fonts/BrandonText-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-ThinItalic.woff2") format("woff2"), url("../fonts/BrandonText-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-Black.woff2") format("woff2"), url("../fonts/BrandonText-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-LightItalic.woff2") format("woff2"), url("../fonts/BrandonText-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-RegularItalic.woff2") format("woff2"), url("../fonts/BrandonText-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Brandon Text";
  src: url("../fonts/BrandonText-BlackItalic.woff2") format("woff2"), url("../fonts/BrandonText-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fas,
.fass,
.far,
.fasr,
.fal,
.fasl,
.fat,
.fast,
.fad,
.fadr,
.fadl,
.fadt,
.fasds,
.fasdr,
.fasdl,
.fasdt,
.fab,
.fa-solid,
.fa-regular,
.fa-light,
.fa-thin,
.fa-brands,
.fa-classic,
.fa-duotone,
.fa-sharp,
.fa-sharp-duotone,
.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas::before,
.fass::before,
.far::before,
.fasr::before,
.fal::before,
.fasl::before,
.fat::before,
.fast::before,
.fad::before,
.fadr::before,
.fadl::before,
.fadt::before,
.fasds::before,
.fasdr::before,
.fasdl::before,
.fasdt::before,
.fab::before,
.fa-solid::before,
.fa-regular::before,
.fa-light::before,
.fa-thin::before,
.fa-brands::before,
.fa-classic::before,
.fa-duotone::before,
.fa-sharp::before,
.fa-sharp-duotone::before,
.fa::before {
  content: var(--fa);
  content: var(--fa)/"";
}

.fad::after,
.fa-duotone.fa-solid::after,
.fa-duotone::after,
.fadr::after,
.fa-duotone.fa-regular::after,
.fadl::after,
.fa-duotone.fa-light::after,
.fadt::after,
.fa-duotone.fa-thin::after,
.fasds::after,
.fa-sharp-duotone.fa-solid::after,
.fa-sharp-duotone::after,
.fasdr::after,
.fa-sharp-duotone.fa-regular::after,
.fasdl::after,
.fa-sharp-duotone.fa-light::after,
.fasdt::after,
.fa-sharp-duotone.fa-thin::after {
  content: var(--fa--fa);
  content: var(--fa--fa)/"";
}

.fa-classic.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fa-sharp {
  font-weight: 900;
}

.fad,
.fa-duotone {
  font-weight: 900;
}

.fasds,
.fa-sharp-duotone {
  font-weight: 900;
}

.fa-classic,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fa-duotone,
.fad,
.fadr,
.fadl,
.fadt {
  font-family: "Font Awesome 6 Duotone";
}

.fa-brands,
.fab {
  font-family: "Font Awesome 6 Brands";
}

.fa-sharp,
.fass,
.fasr,
.fasl,
.fast {
  font-family: "Font Awesome 6 Sharp";
}

.fa-sharp-duotone,
.fasds,
.fasdr,
.fasdl,
.fasdt {
  font-family: "Font Awesome 6 Sharp Duotone";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0 {
  --fa: "\30 ";
  --fa--fa: "\30 \30 ";
}

.fa-1 {
  --fa: "\31 ";
  --fa--fa: "\31 \31 ";
}

.fa-2 {
  --fa: "\32 ";
  --fa--fa: "\32 \32 ";
}

.fa-3 {
  --fa: "\33 ";
  --fa--fa: "\33 \33 ";
}

.fa-4 {
  --fa: "\34 ";
  --fa--fa: "\34 \34 ";
}

.fa-5 {
  --fa: "\35 ";
  --fa--fa: "\35 \35 ";
}

.fa-6 {
  --fa: "\36 ";
  --fa--fa: "\36 \36 ";
}

.fa-7 {
  --fa: "\37 ";
  --fa--fa: "\37 \37 ";
}

.fa-8 {
  --fa: "\38 ";
  --fa--fa: "\38 \38 ";
}

.fa-9 {
  --fa: "\39 ";
  --fa--fa: "\39 \39 ";
}

.fa-fill-drip {
  --fa: "\f576";
  --fa--fa: "\f576\f576";
}

.fa-arrows-to-circle {
  --fa: "\e4bd";
  --fa--fa: "\e4bd\e4bd";
}

.fa-circle-chevron-right {
  --fa: "\f138";
  --fa--fa: "\f138\f138";
}

.fa-chevron-circle-right {
  --fa: "\f138";
  --fa--fa: "\f138\f138";
}

.fa-wagon-covered {
  --fa: "\f8ee";
  --fa--fa: "\f8ee\f8ee";
}

.fa-line-height {
  --fa: "\f871";
  --fa--fa: "\f871\f871";
}

.fa-bagel {
  --fa: "\e3d7";
  --fa--fa: "\e3d7\e3d7";
}

.fa-transporter-7 {
  --fa: "\e2a8";
  --fa--fa: "\e2a8\e2a8";
}

.fa-at {
  --fa: "\@";
  --fa--fa: "\@\@";
}

.fa-rectangles-mixed {
  --fa: "\e323";
  --fa--fa: "\e323\e323";
}

.fa-phone-arrow-up-right {
  --fa: "\e224";
  --fa--fa: "\e224\e224";
}

.fa-phone-arrow-up {
  --fa: "\e224";
  --fa--fa: "\e224\e224";
}

.fa-phone-outgoing {
  --fa: "\e224";
  --fa--fa: "\e224\e224";
}

.fa-trash-can {
  --fa: "\f2ed";
  --fa--fa: "\f2ed\f2ed";
}

.fa-trash-alt {
  --fa: "\f2ed";
  --fa--fa: "\f2ed\f2ed";
}

.fa-circle-l {
  --fa: "\e114";
  --fa--fa: "\e114\e114";
}

.fa-head-side-goggles {
  --fa: "\f6ea";
  --fa--fa: "\f6ea\f6ea";
}

.fa-head-vr {
  --fa: "\f6ea";
  --fa--fa: "\f6ea\f6ea";
}

.fa-text-height {
  --fa: "\f034";
  --fa--fa: "\f034\f034";
}

.fa-user-xmark {
  --fa: "\f235";
  --fa--fa: "\f235\f235";
}

.fa-user-times {
  --fa: "\f235";
  --fa--fa: "\f235\f235";
}

.fa-face-hand-yawn {
  --fa: "\e379";
  --fa--fa: "\e379\e379";
}

.fa-gauge-simple-min {
  --fa: "\f62d";
  --fa--fa: "\f62d\f62d";
}

.fa-tachometer-slowest {
  --fa: "\f62d";
  --fa--fa: "\f62d\f62d";
}

.fa-stethoscope {
  --fa: "\f0f1";
  --fa--fa: "\f0f1\f0f1";
}

.fa-coffin {
  --fa: "\f6c6";
  --fa--fa: "\f6c6\f6c6";
}

.fa-message {
  --fa: "\f27a";
  --fa--fa: "\f27a\f27a";
}

.fa-comment-alt {
  --fa: "\f27a";
  --fa--fa: "\f27a\f27a";
}

.fa-salad {
  --fa: "\f81e";
  --fa--fa: "\f81e\f81e";
}

.fa-bowl-salad {
  --fa: "\f81e";
  --fa--fa: "\f81e\f81e";
}

.fa-info {
  --fa: "\f129";
  --fa--fa: "\f129\f129";
}

.fa-robot-astromech {
  --fa: "\e2d2";
  --fa--fa: "\e2d2\e2d2";
}

.fa-ring-diamond {
  --fa: "\e5ab";
  --fa--fa: "\e5ab\e5ab";
}

.fa-fondue-pot {
  --fa: "\e40d";
  --fa--fa: "\e40d\e40d";
}

.fa-theta {
  --fa: "\f69e";
  --fa--fa: "\f69e\f69e";
}

.fa-face-hand-peeking {
  --fa: "\e481";
  --fa--fa: "\e481\e481";
}

.fa-square-user {
  --fa: "\e283";
  --fa--fa: "\e283\e283";
}

.fa-down-left-and-up-right-to-center {
  --fa: "\f422";
  --fa--fa: "\f422\f422";
}

.fa-compress-alt {
  --fa: "\f422";
  --fa--fa: "\f422\f422";
}

.fa-explosion {
  --fa: "\e4e9";
  --fa--fa: "\e4e9\e4e9";
}

.fa-file-lines {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c";
}

.fa-file-alt {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c";
}

.fa-file-text {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c";
}

.fa-wave-square {
  --fa: "\f83e";
  --fa--fa: "\f83e\f83e";
}

.fa-ring {
  --fa: "\f70b";
  --fa--fa: "\f70b\f70b";
}

.fa-building-un {
  --fa: "\e4d9";
  --fa--fa: "\e4d9\e4d9";
}

.fa-dice-three {
  --fa: "\f527";
  --fa--fa: "\f527\f527";
}

.fa-tire-pressure-warning {
  --fa: "\f633";
  --fa--fa: "\f633\f633";
}

.fa-wifi-fair {
  --fa: "\f6ab";
  --fa--fa: "\f6ab\f6ab";
}

.fa-wifi-2 {
  --fa: "\f6ab";
  --fa--fa: "\f6ab\f6ab";
}

.fa-calendar-days {
  --fa: "\f073";
  --fa--fa: "\f073\f073";
}

.fa-calendar-alt {
  --fa: "\f073";
  --fa--fa: "\f073\f073";
}

.fa-mp3-player {
  --fa: "\f8ce";
  --fa--fa: "\f8ce\f8ce";
}

.fa-anchor-circle-check {
  --fa: "\e4aa";
  --fa--fa: "\e4aa\e4aa";
}

.fa-tally-4 {
  --fa: "\e297";
  --fa--fa: "\e297\e297";
}

.fa-rectangle-history {
  --fa: "\e4a2";
  --fa--fa: "\e4a2\e4a2";
}

.fa-building-circle-arrow-right {
  --fa: "\e4d1";
  --fa--fa: "\e4d1\e4d1";
}

.fa-volleyball {
  --fa: "\f45f";
  --fa--fa: "\f45f\f45f";
}

.fa-volleyball-ball {
  --fa: "\f45f";
  --fa--fa: "\f45f\f45f";
}

.fa-sun-haze {
  --fa: "\f765";
  --fa--fa: "\f765\f765";
}

.fa-text-size {
  --fa: "\f894";
  --fa--fa: "\f894\f894";
}

.fa-ufo {
  --fa: "\e047";
  --fa--fa: "\e047\e047";
}

.fa-fork {
  --fa: "\f2e3";
  --fa--fa: "\f2e3\f2e3";
}

.fa-utensil-fork {
  --fa: "\f2e3";
  --fa--fa: "\f2e3\f2e3";
}

.fa-arrows-up-to-line {
  --fa: "\e4c2";
  --fa--fa: "\e4c2\e4c2";
}

.fa-mobile-signal {
  --fa: "\e1ef";
  --fa--fa: "\e1ef\e1ef";
}

.fa-barcode-scan {
  --fa: "\f465";
  --fa--fa: "\f465\f465";
}

.fa-sort-down {
  --fa: "\f0dd";
  --fa--fa: "\f0dd\f0dd";
}

.fa-sort-desc {
  --fa: "\f0dd";
  --fa--fa: "\f0dd\f0dd";
}

.fa-folder-arrow-down {
  --fa: "\e053";
  --fa--fa: "\e053\e053";
}

.fa-folder-download {
  --fa: "\e053";
  --fa--fa: "\e053\e053";
}

.fa-circle-minus {
  --fa: "\f056";
  --fa--fa: "\f056\f056";
}

.fa-minus-circle {
  --fa: "\f056";
  --fa--fa: "\f056\f056";
}

.fa-face-icicles {
  --fa: "\e37c";
  --fa--fa: "\e37c\e37c";
}

.fa-shovel {
  --fa: "\f713";
  --fa--fa: "\f713\f713";
}

.fa-door-open {
  --fa: "\f52b";
  --fa--fa: "\f52b\f52b";
}

.fa-films {
  --fa: "\e17a";
  --fa--fa: "\e17a\e17a";
}

.fa-right-from-bracket {
  --fa: "\f2f5";
  --fa--fa: "\f2f5\f2f5";
}

.fa-sign-out-alt {
  --fa: "\f2f5";
  --fa--fa: "\f2f5\f2f5";
}

.fa-face-glasses {
  --fa: "\e377";
  --fa--fa: "\e377\e377";
}

.fa-nfc {
  --fa: "\e1f7";
  --fa--fa: "\e1f7\e1f7";
}

.fa-atom {
  --fa: "\f5d2";
  --fa--fa: "\f5d2\f5d2";
}

.fa-soap {
  --fa: "\e06e";
  --fa--fa: "\e06e\e06e";
}

.fa-icons {
  --fa: "\f86d";
  --fa--fa: "\f86d\f86d";
}

.fa-heart-music-camera-bolt {
  --fa: "\f86d";
  --fa--fa: "\f86d\f86d";
}

.fa-microphone-lines-slash {
  --fa: "\f539";
  --fa--fa: "\f539\f539";
}

.fa-microphone-alt-slash {
  --fa: "\f539";
  --fa--fa: "\f539\f539";
}

.fa-closed-captioning-slash {
  --fa: "\e135";
  --fa--fa: "\e135\e135";
}

.fa-calculator-simple {
  --fa: "\f64c";
  --fa--fa: "\f64c\f64c";
}

.fa-calculator-alt {
  --fa: "\f64c";
  --fa--fa: "\f64c\f64c";
}

.fa-bridge-circle-check {
  --fa: "\e4c9";
  --fa--fa: "\e4c9\e4c9";
}

.fa-sliders-up {
  --fa: "\f3f1";
  --fa--fa: "\f3f1\f3f1";
}

.fa-sliders-v {
  --fa: "\f3f1";
  --fa--fa: "\f3f1\f3f1";
}

.fa-location-minus {
  --fa: "\f609";
  --fa--fa: "\f609\f609";
}

.fa-map-marker-minus {
  --fa: "\f609";
  --fa--fa: "\f609\f609";
}

.fa-pump-medical {
  --fa: "\e06a";
  --fa--fa: "\e06a\e06a";
}

.fa-fingerprint {
  --fa: "\f577";
  --fa--fa: "\f577\f577";
}

.fa-ski-boot {
  --fa: "\e3cc";
  --fa--fa: "\e3cc\e3cc";
}

.fa-standard-definition {
  --fa: "\e28a";
  --fa--fa: "\e28a\e28a";
}

.fa-rectangle-sd {
  --fa: "\e28a";
  --fa--fa: "\e28a\e28a";
}

.fa-h1 {
  --fa: "\f313";
  --fa--fa: "\f313\f313";
}

.fa-hand-point-right {
  --fa: "\f0a4";
  --fa--fa: "\f0a4\f0a4";
}

.fa-magnifying-glass-location {
  --fa: "\f689";
  --fa--fa: "\f689\f689";
}

.fa-search-location {
  --fa: "\f689";
  --fa--fa: "\f689\f689";
}

.fa-message-bot {
  --fa: "\e3b8";
  --fa--fa: "\e3b8\e3b8";
}

.fa-forward-step {
  --fa: "\f051";
  --fa--fa: "\f051\f051";
}

.fa-step-forward {
  --fa: "\f051";
  --fa--fa: "\f051\f051";
}

.fa-face-smile-beam {
  --fa: "\f5b8";
  --fa--fa: "\f5b8\f5b8";
}

.fa-smile-beam {
  --fa: "\f5b8";
  --fa--fa: "\f5b8\f5b8";
}

.fa-light-ceiling {
  --fa: "\e016";
  --fa--fa: "\e016\e016";
}

.fa-message-exclamation {
  --fa: "\f4a5";
  --fa--fa: "\f4a5\f4a5";
}

.fa-comment-alt-exclamation {
  --fa: "\f4a5";
  --fa--fa: "\f4a5\f4a5";
}

.fa-bowl-scoop {
  --fa: "\e3de";
  --fa--fa: "\e3de\e3de";
}

.fa-bowl-shaved-ice {
  --fa: "\e3de";
  --fa--fa: "\e3de\e3de";
}

.fa-square-x {
  --fa: "\e286";
  --fa--fa: "\e286\e286";
}

.fa-building-memo {
  --fa: "\e61e";
  --fa--fa: "\e61e\e61e";
}

.fa-utility-pole-double {
  --fa: "\e2c4";
  --fa--fa: "\e2c4\e2c4";
}

.fa-flag-checkered {
  --fa: "\f11e";
  --fa--fa: "\f11e\f11e";
}

.fa-chevrons-up {
  --fa: "\f325";
  --fa--fa: "\f325\f325";
}

.fa-chevron-double-up {
  --fa: "\f325";
  --fa--fa: "\f325\f325";
}

.fa-football {
  --fa: "\f44e";
  --fa--fa: "\f44e\f44e";
}

.fa-football-ball {
  --fa: "\f44e";
  --fa--fa: "\f44e\f44e";
}

.fa-user-vneck {
  --fa: "\e461";
  --fa--fa: "\e461\e461";
}

.fa-school-circle-exclamation {
  --fa: "\e56c";
  --fa--fa: "\e56c\e56c";
}

.fa-crop {
  --fa: "\f125";
  --fa--fa: "\f125\f125";
}

.fa-angles-down {
  --fa: "\f103";
  --fa--fa: "\f103\f103";
}

.fa-angle-double-down {
  --fa: "\f103";
  --fa--fa: "\f103\f103";
}

.fa-users-rectangle {
  --fa: "\e594";
  --fa--fa: "\e594\e594";
}

.fa-people-roof {
  --fa: "\e537";
  --fa--fa: "\e537\e537";
}

.fa-square-arrow-right {
  --fa: "\f33b";
  --fa--fa: "\f33b\f33b";
}

.fa-arrow-square-right {
  --fa: "\f33b";
  --fa--fa: "\f33b\f33b";
}

.fa-location-plus {
  --fa: "\f60a";
  --fa--fa: "\f60a\f60a";
}

.fa-map-marker-plus {
  --fa: "\f60a";
  --fa--fa: "\f60a\f60a";
}

.fa-lightbulb-exclamation-on {
  --fa: "\e1ca";
  --fa--fa: "\e1ca\e1ca";
}

.fa-people-line {
  --fa: "\e534";
  --fa--fa: "\e534\e534";
}

.fa-beer-mug-empty {
  --fa: "\f0fc";
  --fa--fa: "\f0fc\f0fc";
}

.fa-beer {
  --fa: "\f0fc";
  --fa--fa: "\f0fc\f0fc";
}

.fa-carpool {
  --fa: "\e69c";
  --fa--fa: "\e69c\e69c";
}

.fa-car-people {
  --fa: "\e69c";
  --fa--fa: "\e69c\e69c";
}

.fa-crate-empty {
  --fa: "\e151";
  --fa--fa: "\e151\e151";
}

.fa-diagram-predecessor {
  --fa: "\e477";
  --fa--fa: "\e477\e477";
}

.fa-transporter {
  --fa: "\e042";
  --fa--fa: "\e042\e042";
}

.fa-calendar-circle-user {
  --fa: "\e471";
  --fa--fa: "\e471\e471";
}

.fa-arrow-up-long {
  --fa: "\f176";
  --fa--fa: "\f176\f176";
}

.fa-long-arrow-up {
  --fa: "\f176";
  --fa--fa: "\f176\f176";
}

.fa-person-carry-box {
  --fa: "\f4cf";
  --fa--fa: "\f4cf\f4cf";
}

.fa-person-carry {
  --fa: "\f4cf";
  --fa--fa: "\f4cf\f4cf";
}

.fa-fire-flame-simple {
  --fa: "\f46a";
  --fa--fa: "\f46a\f46a";
}

.fa-burn {
  --fa: "\f46a";
  --fa--fa: "\f46a\f46a";
}

.fa-person {
  --fa: "\f183";
  --fa--fa: "\f183\f183";
}

.fa-male {
  --fa: "\f183";
  --fa--fa: "\f183\f183";
}

.fa-laptop {
  --fa: "\f109";
  --fa--fa: "\f109\f109";
}

.fa-file-csv {
  --fa: "\f6dd";
  --fa--fa: "\f6dd\f6dd";
}

.fa-menorah {
  --fa: "\f676";
  --fa--fa: "\f676\f676";
}

.fa-union {
  --fa: "\f6a2";
  --fa--fa: "\f6a2\f6a2";
}

.fa-chevrons-left {
  --fa: "\f323";
  --fa--fa: "\f323\f323";
}

.fa-chevron-double-left {
  --fa: "\f323";
  --fa--fa: "\f323\f323";
}

.fa-circle-heart {
  --fa: "\f4c7";
  --fa--fa: "\f4c7\f4c7";
}

.fa-heart-circle {
  --fa: "\f4c7";
  --fa--fa: "\f4c7\f4c7";
}

.fa-truck-plane {
  --fa: "\e58f";
  --fa--fa: "\e58f\e58f";
}

.fa-record-vinyl {
  --fa: "\f8d9";
  --fa--fa: "\f8d9\f8d9";
}

.fa-bring-forward {
  --fa: "\f856";
  --fa--fa: "\f856\f856";
}

.fa-square-p {
  --fa: "\e279";
  --fa--fa: "\e279\e279";
}

.fa-face-grin-stars {
  --fa: "\f587";
  --fa--fa: "\f587\f587";
}

.fa-grin-stars {
  --fa: "\f587";
  --fa--fa: "\f587\f587";
}

.fa-sigma {
  --fa: "\f68b";
  --fa--fa: "\f68b\f68b";
}

.fa-camera-movie {
  --fa: "\f8a9";
  --fa--fa: "\f8a9\f8a9";
}

.fa-bong {
  --fa: "\f55c";
  --fa--fa: "\f55c\f55c";
}

.fa-clarinet {
  --fa: "\f8ad";
  --fa--fa: "\f8ad\f8ad";
}

.fa-truck-flatbed {
  --fa: "\e2b6";
  --fa--fa: "\e2b6\e2b6";
}

.fa-spaghetti-monster-flying {
  --fa: "\f67b";
  --fa--fa: "\f67b\f67b";
}

.fa-pastafarianism {
  --fa: "\f67b";
  --fa--fa: "\f67b\f67b";
}

.fa-arrow-down-up-across-line {
  --fa: "\e4af";
  --fa--fa: "\e4af\e4af";
}

.fa-arrows-rotate-reverse {
  --fa: "\e630";
  --fa--fa: "\e630\e630";
}

.fa-leaf-heart {
  --fa: "\f4cb";
  --fa--fa: "\f4cb\f4cb";
}

.fa-house-building {
  --fa: "\e1b1";
  --fa--fa: "\e1b1\e1b1";
}

.fa-cheese-swiss {
  --fa: "\f7f0";
  --fa--fa: "\f7f0\f7f0";
}

.fa-spoon {
  --fa: "\f2e5";
  --fa--fa: "\f2e5\f2e5";
}

.fa-utensil-spoon {
  --fa: "\f2e5";
  --fa--fa: "\f2e5\f2e5";
}

.fa-jar-wheat {
  --fa: "\e517";
  --fa--fa: "\e517\e517";
}

.fa-envelopes-bulk {
  --fa: "\f674";
  --fa--fa: "\f674\f674";
}

.fa-mail-bulk {
  --fa: "\f674";
  --fa--fa: "\f674\f674";
}

.fa-file-circle-exclamation {
  --fa: "\e4eb";
  --fa--fa: "\e4eb\e4eb";
}

.fa-bow-arrow {
  --fa: "\f6b9";
  --fa--fa: "\f6b9\f6b9";
}

.fa-cart-xmark {
  --fa: "\e0dd";
  --fa--fa: "\e0dd\e0dd";
}

.fa-hexagon-xmark {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee";
}

.fa-times-hexagon {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee";
}

.fa-xmark-hexagon {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee";
}

.fa-circle-h {
  --fa: "\f47e";
  --fa--fa: "\f47e\f47e";
}

.fa-hospital-symbol {
  --fa: "\f47e";
  --fa--fa: "\f47e\f47e";
}

.fa-merge {
  --fa: "\e526";
  --fa--fa: "\e526\e526";
}

.fa-pager {
  --fa: "\f815";
  --fa--fa: "\f815\f815";
}

.fa-cart-minus {
  --fa: "\e0db";
  --fa--fa: "\e0db\e0db";
}

.fa-address-book {
  --fa: "\f2b9";
  --fa--fa: "\f2b9\f2b9";
}

.fa-contact-book {
  --fa: "\f2b9";
  --fa--fa: "\f2b9\f2b9";
}

.fa-pan-frying {
  --fa: "\e42c";
  --fa--fa: "\e42c\e42c";
}

.fa-grid {
  --fa: "\e195";
  --fa--fa: "\e195\e195";
}

.fa-grid-3 {
  --fa: "\e195";
  --fa--fa: "\e195\e195";
}

.fa-football-helmet {
  --fa: "\f44f";
  --fa--fa: "\f44f\f44f";
}

.fa-hand-love {
  --fa: "\e1a5";
  --fa--fa: "\e1a5\e1a5";
}

.fa-trees {
  --fa: "\f724";
  --fa--fa: "\f724\f724";
}

.fa-strikethrough {
  --fa: "\f0cc";
  --fa--fa: "\f0cc\f0cc";
}

.fa-page {
  --fa: "\e428";
  --fa--fa: "\e428\e428";
}

.fa-k {
  --fa: "K";
  --fa--fa: "KK";
}

.fa-diagram-previous {
  --fa: "\e478";
  --fa--fa: "\e478\e478";
}

.fa-gauge-min {
  --fa: "\f628";
  --fa--fa: "\f628\f628";
}

.fa-tachometer-alt-slowest {
  --fa: "\f628";
  --fa--fa: "\f628\f628";
}

.fa-folder-grid {
  --fa: "\e188";
  --fa--fa: "\e188\e188";
}

.fa-eggplant {
  --fa: "\e16c";
  --fa--fa: "\e16c\e16c";
}

.fa-excavator {
  --fa: "\e656";
  --fa--fa: "\e656\e656";
}

.fa-ram {
  --fa: "\f70a";
  --fa--fa: "\f70a\f70a";
}

.fa-landmark-flag {
  --fa: "\e51c";
  --fa--fa: "\e51c\e51c";
}

.fa-lips {
  --fa: "\f600";
  --fa--fa: "\f600\f600";
}

.fa-pencil {
  --fa: "\f303";
  --fa--fa: "\f303\f303";
}

.fa-pencil-alt {
  --fa: "\f303";
  --fa--fa: "\f303\f303";
}

.fa-backward {
  --fa: "\f04a";
  --fa--fa: "\f04a\f04a";
}

.fa-caret-right {
  --fa: "\f0da";
  --fa--fa: "\f0da\f0da";
}

.fa-comments {
  --fa: "\f086";
  --fa--fa: "\f086\f086";
}

.fa-paste {
  --fa: "\f0ea";
  --fa--fa: "\f0ea\f0ea";
}

.fa-file-clipboard {
  --fa: "\f0ea";
  --fa--fa: "\f0ea\f0ea";
}

.fa-desktop-arrow-down {
  --fa: "\e155";
  --fa--fa: "\e155\e155";
}

.fa-code-pull-request {
  --fa: "\e13c";
  --fa--fa: "\e13c\e13c";
}

.fa-pumpkin {
  --fa: "\f707";
  --fa--fa: "\f707\f707";
}

.fa-clipboard-list {
  --fa: "\f46d";
  --fa--fa: "\f46d\f46d";
}

.fa-pen-field {
  --fa: "\e211";
  --fa--fa: "\e211\e211";
}

.fa-chart-sine {
  --fa: "\e69d";
  --fa--fa: "\e69d\e69d";
}

.fa-blueberries {
  --fa: "\e2e8";
  --fa--fa: "\e2e8\e2e8";
}

.fa-truck-ramp-box {
  --fa: "\f4de";
  --fa--fa: "\f4de\f4de";
}

.fa-truck-loading {
  --fa: "\f4de";
  --fa--fa: "\f4de\f4de";
}

.fa-note {
  --fa: "\e1ff";
  --fa--fa: "\e1ff\e1ff";
}

.fa-arrow-down-to-square {
  --fa: "\e096";
  --fa--fa: "\e096\e096";
}

.fa-user-check {
  --fa: "\f4fc";
  --fa--fa: "\f4fc\f4fc";
}

.fa-cloud-xmark {
  --fa: "\e35f";
  --fa--fa: "\e35f\e35f";
}

.fa-vial-virus {
  --fa: "\e597";
  --fa--fa: "\e597\e597";
}

.fa-book-blank {
  --fa: "\f5d9";
  --fa--fa: "\f5d9\f5d9";
}

.fa-book-alt {
  --fa: "\f5d9";
  --fa--fa: "\f5d9\f5d9";
}

.fa-golf-flag-hole {
  --fa: "\e3ac";
  --fa--fa: "\e3ac\e3ac";
}

.fa-message-arrow-down {
  --fa: "\e1db";
  --fa--fa: "\e1db\e1db";
}

.fa-comment-alt-arrow-down {
  --fa: "\e1db";
  --fa--fa: "\e1db\e1db";
}

.fa-face-unamused {
  --fa: "\e39f";
  --fa--fa: "\e39f\e39f";
}

.fa-sheet-plastic {
  --fa: "\e571";
  --fa--fa: "\e571\e571";
}

.fa-circle-9 {
  --fa: "\e0f6";
  --fa--fa: "\e0f6\e0f6";
}

.fa-blog {
  --fa: "\f781";
  --fa--fa: "\f781\f781";
}

.fa-user-ninja {
  --fa: "\f504";
  --fa--fa: "\f504\f504";
}

.fa-pencil-slash {
  --fa: "\e215";
  --fa--fa: "\e215\e215";
}

.fa-bowling-pins {
  --fa: "\f437";
  --fa--fa: "\f437\f437";
}

.fa-person-arrow-up-from-line {
  --fa: "\e539";
  --fa--fa: "\e539\e539";
}

.fa-down-right {
  --fa: "\e16b";
  --fa--fa: "\e16b\e16b";
}

.fa-scroll-torah {
  --fa: "\f6a0";
  --fa--fa: "\f6a0\f6a0";
}

.fa-torah {
  --fa: "\f6a0";
  --fa--fa: "\f6a0\f6a0";
}

.fa-webhook {
  --fa: "\e5d5";
  --fa--fa: "\e5d5\e5d5";
}

.fa-blinds-open {
  --fa: "\f8fc";
  --fa--fa: "\f8fc\f8fc";
}

.fa-fence {
  --fa: "\e303";
  --fa--fa: "\e303\e303";
}

.fa-up {
  --fa: "\f357";
  --fa--fa: "\f357\f357";
}

.fa-arrow-alt-up {
  --fa: "\f357";
  --fa--fa: "\f357\f357";
}

.fa-broom-ball {
  --fa: "\f458";
  --fa--fa: "\f458\f458";
}

.fa-quidditch {
  --fa: "\f458";
  --fa--fa: "\f458\f458";
}

.fa-quidditch-broom-ball {
  --fa: "\f458";
  --fa--fa: "\f458\f458";
}

.fa-drumstick {
  --fa: "\f6d6";
  --fa--fa: "\f6d6\f6d6";
}

.fa-square-v {
  --fa: "\e284";
  --fa--fa: "\e284\e284";
}

.fa-face-awesome {
  --fa: "\e409";
  --fa--fa: "\e409\e409";
}

.fa-gave-dandy {
  --fa: "\e409";
  --fa--fa: "\e409\e409";
}

.fa-dial-off {
  --fa: "\e162";
  --fa--fa: "\e162\e162";
}

.fa-toggle-off {
  --fa: "\f204";
  --fa--fa: "\f204\f204";
}

.fa-face-smile-horns {
  --fa: "\e391";
  --fa--fa: "\e391\e391";
}

.fa-box-archive {
  --fa: "\f187";
  --fa--fa: "\f187\f187";
}

.fa-archive {
  --fa: "\f187";
  --fa--fa: "\f187\f187";
}

.fa-grapes {
  --fa: "\e306";
  --fa--fa: "\e306\e306";
}

.fa-person-drowning {
  --fa: "\e545";
  --fa--fa: "\e545\e545";
}

.fa-dial-max {
  --fa: "\e15e";
  --fa--fa: "\e15e\e15e";
}

.fa-circle-m {
  --fa: "\e115";
  --fa--fa: "\e115\e115";
}

.fa-calendar-image {
  --fa: "\e0d4";
  --fa--fa: "\e0d4\e0d4";
}

.fa-circle-caret-down {
  --fa: "\f32d";
  --fa--fa: "\f32d\f32d";
}

.fa-caret-circle-down {
  --fa: "\f32d";
  --fa--fa: "\f32d\f32d";
}

.fa-arrow-down-9-1 {
  --fa: "\f886";
  --fa--fa: "\f886\f886";
}

.fa-sort-numeric-desc {
  --fa: "\f886";
  --fa--fa: "\f886\f886";
}

.fa-sort-numeric-down-alt {
  --fa: "\f886";
  --fa--fa: "\f886\f886";
}

.fa-face-grin-tongue-squint {
  --fa: "\f58a";
  --fa--fa: "\f58a\f58a";
}

.fa-grin-tongue-squint {
  --fa: "\f58a";
  --fa--fa: "\f58a\f58a";
}

.fa-shish-kebab {
  --fa: "\f821";
  --fa--fa: "\f821\f821";
}

.fa-spray-can {
  --fa: "\f5bd";
  --fa--fa: "\f5bd\f5bd";
}

.fa-alarm-snooze {
  --fa: "\f845";
  --fa--fa: "\f845\f845";
}

.fa-scarecrow {
  --fa: "\f70d";
  --fa--fa: "\f70d\f70d";
}

.fa-truck-monster {
  --fa: "\f63b";
  --fa--fa: "\f63b\f63b";
}

.fa-gift-card {
  --fa: "\f663";
  --fa--fa: "\f663\f663";
}

.fa-w {
  --fa: "W";
  --fa--fa: "WW";
}

.fa-code-pull-request-draft {
  --fa: "\e3fa";
  --fa--fa: "\e3fa\e3fa";
}

.fa-square-b {
  --fa: "\e264";
  --fa--fa: "\e264\e264";
}

.fa-elephant {
  --fa: "\f6da";
  --fa--fa: "\f6da\f6da";
}

.fa-earth-africa {
  --fa: "\f57c";
  --fa--fa: "\f57c\f57c";
}

.fa-globe-africa {
  --fa: "\f57c";
  --fa--fa: "\f57c\f57c";
}

.fa-rainbow {
  --fa: "\f75b";
  --fa--fa: "\f75b\f75b";
}

.fa-circle-notch {
  --fa: "\f1ce";
  --fa--fa: "\f1ce\f1ce";
}

.fa-tablet-screen-button {
  --fa: "\f3fa";
  --fa--fa: "\f3fa\f3fa";
}

.fa-tablet-alt {
  --fa: "\f3fa";
  --fa--fa: "\f3fa\f3fa";
}

.fa-paw {
  --fa: "\f1b0";
  --fa--fa: "\f1b0\f1b0";
}

.fa-message-question {
  --fa: "\e1e3";
  --fa--fa: "\e1e3\e1e3";
}

.fa-cloud {
  --fa: "\f0c2";
  --fa--fa: "\f0c2\f0c2";
}

.fa-trowel-bricks {
  --fa: "\e58a";
  --fa--fa: "\e58a\e58a";
}

.fa-square-3 {
  --fa: "\e258";
  --fa--fa: "\e258\e258";
}

.fa-face-flushed {
  --fa: "\f579";
  --fa--fa: "\f579\f579";
}

.fa-flushed {
  --fa: "\f579";
  --fa--fa: "\f579\f579";
}

.fa-hospital-user {
  --fa: "\f80d";
  --fa--fa: "\f80d\f80d";
}

.fa-microwave {
  --fa: "\e01b";
  --fa--fa: "\e01b\e01b";
}

.fa-chf-sign {
  --fa: "\e602";
  --fa--fa: "\e602\e602";
}

.fa-tent-arrow-left-right {
  --fa: "\e57f";
  --fa--fa: "\e57f\e57f";
}

.fa-cart-circle-arrow-up {
  --fa: "\e3f0";
  --fa--fa: "\e3f0\e3f0";
}

.fa-trash-clock {
  --fa: "\e2b0";
  --fa--fa: "\e2b0\e2b0";
}

.fa-reflect-both {
  --fa: "\e66f";
  --fa--fa: "\e66f\e66f";
}

.fa-gavel {
  --fa: "\f0e3";
  --fa--fa: "\f0e3\f0e3";
}

.fa-legal {
  --fa: "\f0e3";
  --fa--fa: "\f0e3\f0e3";
}

.fa-sprinkler-ceiling {
  --fa: "\e44c";
  --fa--fa: "\e44c\e44c";
}

.fa-browsers {
  --fa: "\e0cb";
  --fa--fa: "\e0cb\e0cb";
}

.fa-trillium {
  --fa: "\e588";
  --fa--fa: "\e588\e588";
}

.fa-table-cells-unlock {
  --fa: "\e692";
  --fa--fa: "\e692\e692";
}

.fa-music-slash {
  --fa: "\f8d1";
  --fa--fa: "\f8d1\f8d1";
}

.fa-truck-ramp {
  --fa: "\f4e0";
  --fa--fa: "\f4e0\f4e0";
}

.fa-binoculars {
  --fa: "\f1e5";
  --fa--fa: "\f1e5\f1e5";
}

.fa-microphone-slash {
  --fa: "\f131";
  --fa--fa: "\f131\f131";
}

.fa-box-tissue {
  --fa: "\e05b";
  --fa--fa: "\e05b\e05b";
}

.fa-circle-c {
  --fa: "\e101";
  --fa--fa: "\e101\e101";
}

.fa-star-christmas {
  --fa: "\f7d4";
  --fa--fa: "\f7d4\f7d4";
}

.fa-chart-bullet {
  --fa: "\e0e1";
  --fa--fa: "\e0e1\e0e1";
}

.fa-motorcycle {
  --fa: "\f21c";
  --fa--fa: "\f21c\f21c";
}

.fa-tree-christmas {
  --fa: "\f7db";
  --fa--fa: "\f7db\f7db";
}

.fa-tire-flat {
  --fa: "\f632";
  --fa--fa: "\f632\f632";
}

.fa-sunglasses {
  --fa: "\f892";
  --fa--fa: "\f892\f892";
}

.fa-badge {
  --fa: "\f335";
  --fa--fa: "\f335\f335";
}

.fa-message-pen {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4";
}

.fa-comment-alt-edit {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4";
}

.fa-message-edit {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4";
}

.fa-bell-concierge {
  --fa: "\f562";
  --fa--fa: "\f562\f562";
}

.fa-concierge-bell {
  --fa: "\f562";
  --fa--fa: "\f562\f562";
}

.fa-pen-ruler {
  --fa: "\f5ae";
  --fa--fa: "\f5ae\f5ae";
}

.fa-pencil-ruler {
  --fa: "\f5ae";
  --fa--fa: "\f5ae\f5ae";
}

.fa-file-mp3 {
  --fa: "\e648";
  --fa--fa: "\e648\e648";
}

.fa-arrow-progress {
  --fa: "\e5df";
  --fa--fa: "\e5df\e5df";
}

.fa-chess-rook-piece {
  --fa: "\f448";
  --fa--fa: "\f448\f448";
}

.fa-chess-rook-alt {
  --fa: "\f448";
  --fa--fa: "\f448\f448";
}

.fa-square-root {
  --fa: "\f697";
  --fa--fa: "\f697\f697";
}

.fa-album-collection-circle-plus {
  --fa: "\e48e";
  --fa--fa: "\e48e\e48e";
}

.fa-people-arrows {
  --fa: "\e068";
  --fa--fa: "\e068\e068";
}

.fa-people-arrows-left-right {
  --fa: "\e068";
  --fa--fa: "\e068\e068";
}

.fa-sign-post {
  --fa: "\e624";
  --fa--fa: "\e624\e624";
}

.fa-face-angry-horns {
  --fa: "\e368";
  --fa--fa: "\e368\e368";
}

.fa-mars-and-venus-burst {
  --fa: "\e523";
  --fa--fa: "\e523\e523";
}

.fa-tombstone {
  --fa: "\f720";
  --fa--fa: "\f720\f720";
}

.fa-square-caret-right {
  --fa: "\f152";
  --fa--fa: "\f152\f152";
}

.fa-caret-square-right {
  --fa: "\f152";
  --fa--fa: "\f152\f152";
}

.fa-scissors {
  --fa: "\f0c4";
  --fa--fa: "\f0c4\f0c4";
}

.fa-cut {
  --fa: "\f0c4";
  --fa--fa: "\f0c4\f0c4";
}

.fa-list-music {
  --fa: "\f8c9";
  --fa--fa: "\f8c9\f8c9";
}

.fa-sun-plant-wilt {
  --fa: "\e57a";
  --fa--fa: "\e57a\e57a";
}

.fa-toilets-portable {
  --fa: "\e584";
  --fa--fa: "\e584\e584";
}

.fa-hockey-puck {
  --fa: "\f453";
  --fa--fa: "\f453\f453";
}

.fa-mustache {
  --fa: "\e5bc";
  --fa--fa: "\e5bc\e5bc";
}

.fa-hyphen {
  --fa: "\-";
  --fa--fa: "\-\-";
}

.fa-table {
  --fa: "\f0ce";
  --fa--fa: "\f0ce\f0ce";
}

.fa-user-chef {
  --fa: "\e3d2";
  --fa--fa: "\e3d2\e3d2";
}

.fa-message-image {
  --fa: "\e1e0";
  --fa--fa: "\e1e0\e1e0";
}

.fa-comment-alt-image {
  --fa: "\e1e0";
  --fa--fa: "\e1e0\e1e0";
}

.fa-users-medical {
  --fa: "\f830";
  --fa--fa: "\f830\f830";
}

.fa-sensor-triangle-exclamation {
  --fa: "\e029";
  --fa--fa: "\e029\e029";
}

.fa-sensor-alert {
  --fa: "\e029";
  --fa--fa: "\e029\e029";
}

.fa-magnifying-glass-arrow-right {
  --fa: "\e521";
  --fa--fa: "\e521\e521";
}

.fa-tachograph-digital {
  --fa: "\f566";
  --fa--fa: "\f566\f566";
}

.fa-digital-tachograph {
  --fa: "\f566";
  --fa--fa: "\f566\f566";
}

.fa-face-mask {
  --fa: "\e37f";
  --fa--fa: "\e37f\e37f";
}

.fa-pickleball {
  --fa: "\e435";
  --fa--fa: "\e435\e435";
}

.fa-star-sharp-half {
  --fa: "\e28c";
  --fa--fa: "\e28c\e28c";
}

.fa-users-slash {
  --fa: "\e073";
  --fa--fa: "\e073\e073";
}

.fa-clover {
  --fa: "\e139";
  --fa--fa: "\e139\e139";
}

.fa-meat {
  --fa: "\f814";
  --fa--fa: "\f814\f814";
}

.fa-reply {
  --fa: "\f3e5";
  --fa--fa: "\f3e5\f3e5";
}

.fa-mail-reply {
  --fa: "\f3e5";
  --fa--fa: "\f3e5\f3e5";
}

.fa-star-and-crescent {
  --fa: "\f699";
  --fa--fa: "\f699\f699";
}

.fa-empty-set {
  --fa: "\f656";
  --fa--fa: "\f656\f656";
}

.fa-house-fire {
  --fa: "\e50c";
  --fa--fa: "\e50c\e50c";
}

.fa-square-minus {
  --fa: "\f146";
  --fa--fa: "\f146\f146";
}

.fa-minus-square {
  --fa: "\f146";
  --fa--fa: "\f146\f146";
}

.fa-helicopter {
  --fa: "\f533";
  --fa--fa: "\f533\f533";
}

.fa-bird {
  --fa: "\e469";
  --fa--fa: "\e469\e469";
}

.fa-compass {
  --fa: "\f14e";
  --fa--fa: "\f14e\f14e";
}

.fa-square-caret-down {
  --fa: "\f150";
  --fa--fa: "\f150\f150";
}

.fa-caret-square-down {
  --fa: "\f150";
  --fa--fa: "\f150\f150";
}

.fa-heart-half-stroke {
  --fa: "\e1ac";
  --fa--fa: "\e1ac\e1ac";
}

.fa-heart-half-alt {
  --fa: "\e1ac";
  --fa--fa: "\e1ac\e1ac";
}

.fa-file-circle-question {
  --fa: "\e4ef";
  --fa--fa: "\e4ef\e4ef";
}

.fa-truck-utensils {
  --fa: "\e628";
  --fa--fa: "\e628\e628";
}

.fa-laptop-code {
  --fa: "\f5fc";
  --fa--fa: "\f5fc\f5fc";
}

.fa-joystick {
  --fa: "\f8c5";
  --fa--fa: "\f8c5\f8c5";
}

.fa-grill-fire {
  --fa: "\e5a4";
  --fa--fa: "\e5a4\e5a4";
}

.fa-rectangle-vertical-history {
  --fa: "\e237";
  --fa--fa: "\e237\e237";
}

.fa-swatchbook {
  --fa: "\f5c3";
  --fa--fa: "\f5c3\f5c3";
}

.fa-prescription-bottle {
  --fa: "\f485";
  --fa--fa: "\f485\f485";
}

.fa-bars {
  --fa: "\f0c9";
  --fa--fa: "\f0c9\f0c9";
}

.fa-navicon {
  --fa: "\f0c9";
  --fa--fa: "\f0c9\f0c9";
}

.fa-keyboard-left {
  --fa: "\e1c3";
  --fa--fa: "\e1c3\e1c3";
}

.fa-people-group {
  --fa: "\e533";
  --fa--fa: "\e533\e533";
}

.fa-hourglass-end {
  --fa: "\f253";
  --fa--fa: "\f253\f253";
}

.fa-hourglass-3 {
  --fa: "\f253";
  --fa--fa: "\f253\f253";
}

.fa-heart-crack {
  --fa: "\f7a9";
  --fa--fa: "\f7a9\f7a9";
}

.fa-heart-broken {
  --fa: "\f7a9";
  --fa--fa: "\f7a9\f7a9";
}

.fa-face-beam-hand-over-mouth {
  --fa: "\e47c";
  --fa--fa: "\e47c\e47c";
}

.fa-droplet-percent {
  --fa: "\f750";
  --fa--fa: "\f750\f750";
}

.fa-humidity {
  --fa: "\f750";
  --fa--fa: "\f750\f750";
}

.fa-square-up-right {
  --fa: "\f360";
  --fa--fa: "\f360\f360";
}

.fa-external-link-square-alt {
  --fa: "\f360";
  --fa--fa: "\f360\f360";
}

.fa-face-kiss-beam {
  --fa: "\f597";
  --fa--fa: "\f597\f597";
}

.fa-kiss-beam {
  --fa: "\f597";
  --fa--fa: "\f597\f597";
}

.fa-corn {
  --fa: "\f6c7";
  --fa--fa: "\f6c7\f6c7";
}

.fa-roller-coaster {
  --fa: "\e324";
  --fa--fa: "\e324\e324";
}

.fa-photo-film-music {
  --fa: "\e228";
  --fa--fa: "\e228\e228";
}

.fa-radar {
  --fa: "\e024";
  --fa--fa: "\e024\e024";
}

.fa-sickle {
  --fa: "\f822";
  --fa--fa: "\f822\f822";
}

.fa-film {
  --fa: "\f008";
  --fa--fa: "\f008\f008";
}

.fa-coconut {
  --fa: "\e2f6";
  --fa--fa: "\e2f6\e2f6";
}

.fa-ruler-horizontal {
  --fa: "\f547";
  --fa--fa: "\f547\f547";
}

.fa-shield-cross {
  --fa: "\f712";
  --fa--fa: "\f712\f712";
}

.fa-cassette-tape {
  --fa: "\f8ab";
  --fa--fa: "\f8ab\f8ab";
}

.fa-square-terminal {
  --fa: "\e32a";
  --fa--fa: "\e32a\e32a";
}

.fa-people-robbery {
  --fa: "\e536";
  --fa--fa: "\e536\e536";
}

.fa-lightbulb {
  --fa: "\f0eb";
  --fa--fa: "\f0eb\f0eb";
}

.fa-caret-left {
  --fa: "\f0d9";
  --fa--fa: "\f0d9\f0d9";
}

.fa-comment-middle {
  --fa: "\e149";
  --fa--fa: "\e149\e149";
}

.fa-trash-can-list {
  --fa: "\e2ab";
  --fa--fa: "\e2ab\e2ab";
}

.fa-block {
  --fa: "\e46a";
  --fa--fa: "\e46a\e46a";
}

.fa-circle-exclamation {
  --fa: "\f06a";
  --fa--fa: "\f06a\f06a";
}

.fa-exclamation-circle {
  --fa: "\f06a";
  --fa--fa: "\f06a\f06a";
}

.fa-school-circle-xmark {
  --fa: "\e56d";
  --fa--fa: "\e56d\e56d";
}

.fa-arrow-right-from-bracket {
  --fa: "\f08b";
  --fa--fa: "\f08b\f08b";
}

.fa-sign-out {
  --fa: "\f08b";
  --fa--fa: "\f08b\f08b";
}

.fa-face-frown-slight {
  --fa: "\e376";
  --fa--fa: "\e376\e376";
}

.fa-circle-chevron-down {
  --fa: "\f13a";
  --fa--fa: "\f13a\f13a";
}

.fa-chevron-circle-down {
  --fa: "\f13a";
  --fa--fa: "\f13a\f13a";
}

.fa-sidebar-flip {
  --fa: "\e24f";
  --fa--fa: "\e24f\e24f";
}

.fa-unlock-keyhole {
  --fa: "\f13e";
  --fa--fa: "\f13e\f13e";
}

.fa-unlock-alt {
  --fa: "\f13e";
  --fa--fa: "\f13e\f13e";
}

.fa-temperature-list {
  --fa: "\e299";
  --fa--fa: "\e299\e299";
}

.fa-cloud-showers-heavy {
  --fa: "\f740";
  --fa--fa: "\f740\f740";
}

.fa-headphones-simple {
  --fa: "\f58f";
  --fa--fa: "\f58f\f58f";
}

.fa-headphones-alt {
  --fa: "\f58f";
  --fa--fa: "\f58f\f58f";
}

.fa-sitemap {
  --fa: "\f0e8";
  --fa--fa: "\f0e8\f0e8";
}

.fa-pipe-section {
  --fa: "\e438";
  --fa--fa: "\e438\e438";
}

.fa-space-station-moon-construction {
  --fa: "\e034";
  --fa--fa: "\e034\e034";
}

.fa-space-station-moon-alt {
  --fa: "\e034";
  --fa--fa: "\e034\e034";
}

.fa-circle-dollar-to-slot {
  --fa: "\f4b9";
  --fa--fa: "\f4b9\f4b9";
}

.fa-donate {
  --fa: "\f4b9";
  --fa--fa: "\f4b9\f4b9";
}

.fa-memory {
  --fa: "\f538";
  --fa--fa: "\f538\f538";
}

.fa-face-sleeping {
  --fa: "\e38d";
  --fa--fa: "\e38d\e38d";
}

.fa-road-spikes {
  --fa: "\e568";
  --fa--fa: "\e568\e568";
}

.fa-fire-burner {
  --fa: "\e4f1";
  --fa--fa: "\e4f1\e4f1";
}

.fa-squirrel {
  --fa: "\f71a";
  --fa--fa: "\f71a\f71a";
}

.fa-arrow-up-to-line {
  --fa: "\f341";
  --fa--fa: "\f341\f341";
}

.fa-arrow-to-top {
  --fa: "\f341";
  --fa--fa: "\f341\f341";
}

.fa-flag {
  --fa: "\f024";
  --fa--fa: "\f024\f024";
}

.fa-face-cowboy-hat {
  --fa: "\e36e";
  --fa--fa: "\e36e\e36e";
}

.fa-hanukiah {
  --fa: "\f6e6";
  --fa--fa: "\f6e6\f6e6";
}

.fa-chart-scatter-3d {
  --fa: "\e0e8";
  --fa--fa: "\e0e8\e0e8";
}

.fa-display-chart-up {
  --fa: "\e5e3";
  --fa--fa: "\e5e3\e5e3";
}

.fa-square-code {
  --fa: "\e267";
  --fa--fa: "\e267\e267";
}

.fa-feather {
  --fa: "\f52d";
  --fa--fa: "\f52d\f52d";
}

.fa-volume-low {
  --fa: "\f027";
  --fa--fa: "\f027\f027";
}

.fa-volume-down {
  --fa: "\f027";
  --fa--fa: "\f027\f027";
}

.fa-xmark-to-slot {
  --fa: "\f771";
  --fa--fa: "\f771\f771";
}

.fa-times-to-slot {
  --fa: "\f771";
  --fa--fa: "\f771\f771";
}

.fa-vote-nay {
  --fa: "\f771";
  --fa--fa: "\f771\f771";
}

.fa-box-taped {
  --fa: "\f49a";
  --fa--fa: "\f49a\f49a";
}

.fa-box-alt {
  --fa: "\f49a";
  --fa--fa: "\f49a\f49a";
}

.fa-comment-slash {
  --fa: "\f4b3";
  --fa--fa: "\f4b3\f4b3";
}

.fa-swords {
  --fa: "\f71d";
  --fa--fa: "\f71d\f71d";
}

.fa-cloud-sun-rain {
  --fa: "\f743";
  --fa--fa: "\f743\f743";
}

.fa-album {
  --fa: "\f89f";
  --fa--fa: "\f89f\f89f";
}

.fa-circle-n {
  --fa: "\e118";
  --fa--fa: "\e118\e118";
}

.fa-compress {
  --fa: "\f066";
  --fa--fa: "\f066\f066";
}

.fa-wheat-awn {
  --fa: "\e2cd";
  --fa--fa: "\e2cd\e2cd";
}

.fa-wheat-alt {
  --fa: "\e2cd";
  --fa--fa: "\e2cd\e2cd";
}

.fa-ankh {
  --fa: "\f644";
  --fa--fa: "\f644\f644";
}

.fa-hands-holding-child {
  --fa: "\e4fa";
  --fa--fa: "\e4fa\e4fa";
}

.fa-asterisk {
  --fa: "\*";
  --fa--fa: "\*\*";
}

.fa-key-skeleton-left-right {
  --fa: "\e3b4";
  --fa--fa: "\e3b4\e3b4";
}

.fa-comment-lines {
  --fa: "\f4b0";
  --fa--fa: "\f4b0\f4b0";
}

.fa-luchador-mask {
  --fa: "\f455";
  --fa--fa: "\f455\f455";
}

.fa-luchador {
  --fa: "\f455";
  --fa--fa: "\f455\f455";
}

.fa-mask-luchador {
  --fa: "\f455";
  --fa--fa: "\f455\f455";
}

.fa-square-check {
  --fa: "\f14a";
  --fa--fa: "\f14a\f14a";
}

.fa-check-square {
  --fa: "\f14a";
  --fa--fa: "\f14a\f14a";
}

.fa-shredder {
  --fa: "\f68a";
  --fa--fa: "\f68a\f68a";
}

.fa-book-open-cover {
  --fa: "\e0c0";
  --fa--fa: "\e0c0\e0c0";
}

.fa-book-open-alt {
  --fa: "\e0c0";
  --fa--fa: "\e0c0\e0c0";
}

.fa-sandwich {
  --fa: "\f81f";
  --fa--fa: "\f81f\f81f";
}

.fa-peseta-sign {
  --fa: "\e221";
  --fa--fa: "\e221\e221";
}

.fa-square-parking-slash {
  --fa: "\f617";
  --fa--fa: "\f617\f617";
}

.fa-parking-slash {
  --fa: "\f617";
  --fa--fa: "\f617\f617";
}

.fa-train-tunnel {
  --fa: "\e454";
  --fa--fa: "\e454\e454";
}

.fa-heading {
  --fa: "\f1dc";
  --fa--fa: "\f1dc\f1dc";
}

.fa-header {
  --fa: "\f1dc";
  --fa--fa: "\f1dc\f1dc";
}

.fa-ghost {
  --fa: "\f6e2";
  --fa--fa: "\f6e2\f6e2";
}

.fa-face-anguished {
  --fa: "\e369";
  --fa--fa: "\e369\e369";
}

.fa-hockey-sticks {
  --fa: "\f454";
  --fa--fa: "\f454\f454";
}

.fa-abacus {
  --fa: "\f640";
  --fa--fa: "\f640\f640";
}

.fa-film-simple {
  --fa: "\f3a0";
  --fa--fa: "\f3a0\f3a0";
}

.fa-film-alt {
  --fa: "\f3a0";
  --fa--fa: "\f3a0\f3a0";
}

.fa-list {
  --fa: "\f03a";
  --fa--fa: "\f03a\f03a";
}

.fa-list-squares {
  --fa: "\f03a";
  --fa--fa: "\f03a\f03a";
}

.fa-tree-palm {
  --fa: "\f82b";
  --fa--fa: "\f82b\f82b";
}

.fa-square-phone-flip {
  --fa: "\f87b";
  --fa--fa: "\f87b\f87b";
}

.fa-phone-square-alt {
  --fa: "\f87b";
  --fa--fa: "\f87b\f87b";
}

.fa-user-beard-bolt {
  --fa: "\e689";
  --fa--fa: "\e689\e689";
}

.fa-cart-plus {
  --fa: "\f217";
  --fa--fa: "\f217\f217";
}

.fa-gamepad {
  --fa: "\f11b";
  --fa--fa: "\f11b\f11b";
}

.fa-border-center-v {
  --fa: "\f89d";
  --fa--fa: "\f89d\f89d";
}

.fa-circle-dot {
  --fa: "\f192";
  --fa--fa: "\f192\f192";
}

.fa-dot-circle {
  --fa: "\f192";
  --fa--fa: "\f192\f192";
}

.fa-clipboard-medical {
  --fa: "\e133";
  --fa--fa: "\e133\e133";
}

.fa-face-dizzy {
  --fa: "\f567";
  --fa--fa: "\f567\f567";
}

.fa-dizzy {
  --fa: "\f567";
  --fa--fa: "\f567\f567";
}

.fa-egg {
  --fa: "\f7fb";
  --fa--fa: "\f7fb\f7fb";
}

.fa-up-to-line {
  --fa: "\f34d";
  --fa--fa: "\f34d\f34d";
}

.fa-arrow-alt-to-top {
  --fa: "\f34d";
  --fa--fa: "\f34d\f34d";
}

.fa-house-medical-circle-xmark {
  --fa: "\e513";
  --fa--fa: "\e513\e513";
}

.fa-watch-fitness {
  --fa: "\f63e";
  --fa--fa: "\f63e\f63e";
}

.fa-clock-nine-thirty {
  --fa: "\e34d";
  --fa--fa: "\e34d\e34d";
}

.fa-campground {
  --fa: "\f6bb";
  --fa--fa: "\f6bb\f6bb";
}

.fa-folder-plus {
  --fa: "\f65e";
  --fa--fa: "\f65e\f65e";
}

.fa-jug {
  --fa: "\f8c6";
  --fa--fa: "\f8c6\f8c6";
}

.fa-futbol {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3";
}

.fa-futbol-ball {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3";
}

.fa-soccer-ball {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3";
}

.fa-snow-blowing {
  --fa: "\f761";
  --fa--fa: "\f761\f761";
}

.fa-paintbrush {
  --fa: "\f1fc";
  --fa--fa: "\f1fc\f1fc";
}

.fa-paint-brush {
  --fa: "\f1fc";
  --fa--fa: "\f1fc\f1fc";
}

.fa-lock {
  --fa: "\f023";
  --fa--fa: "\f023\f023";
}

.fa-arrow-down-from-line {
  --fa: "\f345";
  --fa--fa: "\f345\f345";
}

.fa-arrow-from-top {
  --fa: "\f345";
  --fa--fa: "\f345\f345";
}

.fa-gas-pump {
  --fa: "\f52f";
  --fa--fa: "\f52f\f52f";
}

.fa-signal-bars-slash {
  --fa: "\f694";
  --fa--fa: "\f694\f694";
}

.fa-signal-alt-slash {
  --fa: "\f694";
  --fa--fa: "\f694\f694";
}

.fa-monkey {
  --fa: "\f6fb";
  --fa--fa: "\f6fb\f6fb";
}

.fa-rectangle-pro {
  --fa: "\e235";
  --fa--fa: "\e235\e235";
}

.fa-pro {
  --fa: "\e235";
  --fa--fa: "\e235\e235";
}

.fa-house-night {
  --fa: "\e010";
  --fa--fa: "\e010\e010";
}

.fa-hot-tub-person {
  --fa: "\f593";
  --fa--fa: "\f593\f593";
}

.fa-hot-tub {
  --fa: "\f593";
  --fa--fa: "\f593\f593";
}

.fa-globe-pointer {
  --fa: "\e60e";
  --fa--fa: "\e60e\e60e";
}

.fa-blanket {
  --fa: "\f498";
  --fa--fa: "\f498\f498";
}

.fa-map-location {
  --fa: "\f59f";
  --fa--fa: "\f59f\f59f";
}

.fa-map-marked {
  --fa: "\f59f";
  --fa--fa: "\f59f\f59f";
}

.fa-house-flood-water {
  --fa: "\e50e";
  --fa--fa: "\e50e\e50e";
}

.fa-comments-question-check {
  --fa: "\e14f";
  --fa--fa: "\e14f\e14f";
}

.fa-tree {
  --fa: "\f1bb";
  --fa--fa: "\f1bb\f1bb";
}

.fa-arrows-cross {
  --fa: "\e0a2";
  --fa--fa: "\e0a2\e0a2";
}

.fa-backpack {
  --fa: "\f5d4";
  --fa--fa: "\f5d4\f5d4";
}

.fa-square-small {
  --fa: "\e27e";
  --fa--fa: "\e27e\e27e";
}

.fa-folder-arrow-up {
  --fa: "\e054";
  --fa--fa: "\e054\e054";
}

.fa-folder-upload {
  --fa: "\e054";
  --fa--fa: "\e054\e054";
}

.fa-bridge-lock {
  --fa: "\e4cc";
  --fa--fa: "\e4cc\e4cc";
}

.fa-crosshairs-simple {
  --fa: "\e59f";
  --fa--fa: "\e59f\e59f";
}

.fa-sack-dollar {
  --fa: "\f81d";
  --fa--fa: "\f81d\f81d";
}

.fa-pen-to-square {
  --fa: "\f044";
  --fa--fa: "\f044\f044";
}

.fa-edit {
  --fa: "\f044";
  --fa--fa: "\f044\f044";
}

.fa-square-sliders {
  --fa: "\f3f0";
  --fa--fa: "\f3f0\f3f0";
}

.fa-sliders-h-square {
  --fa: "\f3f0";
  --fa--fa: "\f3f0\f3f0";
}

.fa-car-side {
  --fa: "\f5e4";
  --fa--fa: "\f5e4\f5e4";
}

.fa-message-middle-top {
  --fa: "\e1e2";
  --fa--fa: "\e1e2\e1e2";
}

.fa-comment-middle-top-alt {
  --fa: "\e1e2";
  --fa--fa: "\e1e2\e1e2";
}

.fa-lightbulb-on {
  --fa: "\f672";
  --fa--fa: "\f672\f672";
}

.fa-knife {
  --fa: "\f2e4";
  --fa--fa: "\f2e4\f2e4";
}

.fa-utensil-knife {
  --fa: "\f2e4";
  --fa--fa: "\f2e4\f2e4";
}

.fa-share-nodes {
  --fa: "\f1e0";
  --fa--fa: "\f1e0\f1e0";
}

.fa-share-alt {
  --fa: "\f1e0";
  --fa--fa: "\f1e0\f1e0";
}

.fa-display-chart-up-circle-dollar {
  --fa: "\e5e6";
  --fa--fa: "\e5e6\e5e6";
}

.fa-wave-sine {
  --fa: "\f899";
  --fa--fa: "\f899\f899";
}

.fa-heart-circle-minus {
  --fa: "\e4ff";
  --fa--fa: "\e4ff\e4ff";
}

.fa-circle-w {
  --fa: "\e12c";
  --fa--fa: "\e12c\e12c";
}

.fa-circle-calendar {
  --fa: "\e102";
  --fa--fa: "\e102\e102";
}

.fa-calendar-circle {
  --fa: "\e102";
  --fa--fa: "\e102\e102";
}

.fa-hourglass-half {
  --fa: "\f252";
  --fa--fa: "\f252\f252";
}

.fa-hourglass-2 {
  --fa: "\f252";
  --fa--fa: "\f252\f252";
}

.fa-microscope {
  --fa: "\f610";
  --fa--fa: "\f610\f610";
}

.fa-sunset {
  --fa: "\f767";
  --fa--fa: "\f767\f767";
}

.fa-sink {
  --fa: "\e06d";
  --fa--fa: "\e06d\e06d";
}

.fa-calendar-exclamation {
  --fa: "\f334";
  --fa--fa: "\f334\f334";
}

.fa-truck-container-empty {
  --fa: "\e2b5";
  --fa--fa: "\e2b5\e2b5";
}

.fa-hand-heart {
  --fa: "\f4bc";
  --fa--fa: "\f4bc\f4bc";
}

.fa-bag-shopping {
  --fa: "\f290";
  --fa--fa: "\f290\f290";
}

.fa-shopping-bag {
  --fa: "\f290";
  --fa--fa: "\f290\f290";
}

.fa-arrow-down-z-a {
  --fa: "\f881";
  --fa--fa: "\f881\f881";
}

.fa-sort-alpha-desc {
  --fa: "\f881";
  --fa--fa: "\f881\f881";
}

.fa-sort-alpha-down-alt {
  --fa: "\f881";
  --fa--fa: "\f881\f881";
}

.fa-mitten {
  --fa: "\f7b5";
  --fa--fa: "\f7b5\f7b5";
}

.fa-reply-clock {
  --fa: "\e239";
  --fa--fa: "\e239\e239";
}

.fa-reply-time {
  --fa: "\e239";
  --fa--fa: "\e239\e239";
}

.fa-person-rays {
  --fa: "\e54d";
  --fa--fa: "\e54d\e54d";
}

.fa-right {
  --fa: "\f356";
  --fa--fa: "\f356\f356";
}

.fa-arrow-alt-right {
  --fa: "\f356";
  --fa--fa: "\f356\f356";
}

.fa-circle-f {
  --fa: "\e10e";
  --fa--fa: "\e10e\e10e";
}

.fa-users {
  --fa: "\f0c0";
  --fa--fa: "\f0c0\f0c0";
}

.fa-face-pleading {
  --fa: "\e386";
  --fa--fa: "\e386\e386";
}

.fa-eye-slash {
  --fa: "\f070";
  --fa--fa: "\f070\f070";
}

.fa-flask-vial {
  --fa: "\e4f3";
  --fa--fa: "\e4f3\e4f3";
}

.fa-police-box {
  --fa: "\e021";
  --fa--fa: "\e021\e021";
}

.fa-cucumber {
  --fa: "\e401";
  --fa--fa: "\e401\e401";
}

.fa-head-side-brain {
  --fa: "\f808";
  --fa--fa: "\f808\f808";
}

.fa-hand {
  --fa: "\f256";
  --fa--fa: "\f256\f256";
}

.fa-hand-paper {
  --fa: "\f256";
  --fa--fa: "\f256\f256";
}

.fa-person-biking-mountain {
  --fa: "\f84b";
  --fa--fa: "\f84b\f84b";
}

.fa-biking-mountain {
  --fa: "\f84b";
  --fa--fa: "\f84b\f84b";
}

.fa-utensils-slash {
  --fa: "\e464";
  --fa--fa: "\e464\e464";
}

.fa-print-magnifying-glass {
  --fa: "\f81a";
  --fa--fa: "\f81a\f81a";
}

.fa-print-search {
  --fa: "\f81a";
  --fa--fa: "\f81a\f81a";
}

.fa-turn-right {
  --fa: "\e639";
  --fa--fa: "\e639\e639";
}

.fa-folder-bookmark {
  --fa: "\e186";
  --fa--fa: "\e186\e186";
}

.fa-arrow-turn-left-down {
  --fa: "\e633";
  --fa--fa: "\e633\e633";
}

.fa-om {
  --fa: "\f679";
  --fa--fa: "\f679\f679";
}

.fa-pi {
  --fa: "\f67e";
  --fa--fa: "\f67e\f67e";
}

.fa-flask-round-potion {
  --fa: "\f6e1";
  --fa--fa: "\f6e1\f6e1";
}

.fa-flask-potion {
  --fa: "\f6e1";
  --fa--fa: "\f6e1\f6e1";
}

.fa-face-shush {
  --fa: "\e38c";
  --fa--fa: "\e38c\e38c";
}

.fa-worm {
  --fa: "\e599";
  --fa--fa: "\e599\e599";
}

.fa-house-circle-xmark {
  --fa: "\e50b";
  --fa--fa: "\e50b\e50b";
}

.fa-plug {
  --fa: "\f1e6";
  --fa--fa: "\f1e6\f1e6";
}

.fa-calendar-circle-exclamation {
  --fa: "\e46e";
  --fa--fa: "\e46e\e46e";
}

.fa-square-i {
  --fa: "\e272";
  --fa--fa: "\e272\e272";
}

.fa-chevron-up {
  --fa: "\f077";
  --fa--fa: "\f077\f077";
}

.fa-face-saluting {
  --fa: "\e484";
  --fa--fa: "\e484\e484";
}

.fa-gauge-simple-low {
  --fa: "\f62c";
  --fa--fa: "\f62c\f62c";
}

.fa-tachometer-slow {
  --fa: "\f62c";
  --fa--fa: "\f62c\f62c";
}

.fa-face-persevering {
  --fa: "\e385";
  --fa--fa: "\e385\e385";
}

.fa-circle-camera {
  --fa: "\e103";
  --fa--fa: "\e103\e103";
}

.fa-camera-circle {
  --fa: "\e103";
  --fa--fa: "\e103\e103";
}

.fa-hand-spock {
  --fa: "\f259";
  --fa--fa: "\f259\f259";
}

.fa-spider-web {
  --fa: "\f719";
  --fa--fa: "\f719\f719";
}

.fa-circle-microphone {
  --fa: "\e116";
  --fa--fa: "\e116\e116";
}

.fa-microphone-circle {
  --fa: "\e116";
  --fa--fa: "\e116\e116";
}

.fa-book-arrow-up {
  --fa: "\e0ba";
  --fa--fa: "\e0ba\e0ba";
}

.fa-popsicle {
  --fa: "\e43e";
  --fa--fa: "\e43e\e43e";
}

.fa-command {
  --fa: "\e142";
  --fa--fa: "\e142\e142";
}

.fa-blinds {
  --fa: "\f8fb";
  --fa--fa: "\f8fb\f8fb";
}

.fa-stopwatch {
  --fa: "\f2f2";
  --fa--fa: "\f2f2\f2f2";
}

.fa-saxophone {
  --fa: "\f8dc";
  --fa--fa: "\f8dc\f8dc";
}

.fa-square-2 {
  --fa: "\e257";
  --fa--fa: "\e257\e257";
}

.fa-field-hockey-stick-ball {
  --fa: "\f44c";
  --fa--fa: "\f44c\f44c";
}

.fa-field-hockey {
  --fa: "\f44c";
  --fa--fa: "\f44c\f44c";
}

.fa-arrow-up-square-triangle {
  --fa: "\f88b";
  --fa--fa: "\f88b\f88b";
}

.fa-sort-shapes-up-alt {
  --fa: "\f88b";
  --fa--fa: "\f88b\f88b";
}

.fa-face-scream {
  --fa: "\e38b";
  --fa--fa: "\e38b\e38b";
}

.fa-square-m {
  --fa: "\e276";
  --fa--fa: "\e276\e276";
}

.fa-camera-web {
  --fa: "\f832";
  --fa--fa: "\f832\f832";
}

.fa-webcam {
  --fa: "\f832";
  --fa--fa: "\f832\f832";
}

.fa-comment-arrow-down {
  --fa: "\e143";
  --fa--fa: "\e143\e143";
}

.fa-lightbulb-cfl {
  --fa: "\e5a6";
  --fa--fa: "\e5a6\e5a6";
}

.fa-window-frame-open {
  --fa: "\e050";
  --fa--fa: "\e050\e050";
}

.fa-face-kiss {
  --fa: "\f596";
  --fa--fa: "\f596\f596";
}

.fa-kiss {
  --fa: "\f596";
  --fa--fa: "\f596\f596";
}

.fa-bridge-circle-xmark {
  --fa: "\e4cb";
  --fa--fa: "\e4cb\e4cb";
}

.fa-period {
  --fa: "\.";
  --fa--fa: "\.\.";
}

.fa-face-grin-tongue {
  --fa: "\f589";
  --fa--fa: "\f589\f589";
}

.fa-grin-tongue {
  --fa: "\f589";
  --fa--fa: "\f589\f589";
}

.fa-up-to-dotted-line {
  --fa: "\e457";
  --fa--fa: "\e457\e457";
}

.fa-thought-bubble {
  --fa: "\e32e";
  --fa--fa: "\e32e\e32e";
}

.fa-skeleton-ribs {
  --fa: "\e5cb";
  --fa--fa: "\e5cb\e5cb";
}

.fa-raygun {
  --fa: "\e025";
  --fa--fa: "\e025\e025";
}

.fa-flute {
  --fa: "\f8b9";
  --fa--fa: "\f8b9\f8b9";
}

.fa-acorn {
  --fa: "\f6ae";
  --fa--fa: "\f6ae\f6ae";
}

.fa-video-arrow-up-right {
  --fa: "\e2c9";
  --fa--fa: "\e2c9\e2c9";
}

.fa-grate-droplet {
  --fa: "\e194";
  --fa--fa: "\e194\e194";
}

.fa-seal-exclamation {
  --fa: "\e242";
  --fa--fa: "\e242\e242";
}

.fa-chess-bishop {
  --fa: "\f43a";
  --fa--fa: "\f43a\f43a";
}

.fa-message-sms {
  --fa: "\e1e5";
  --fa--fa: "\e1e5\e1e5";
}

.fa-coffee-beans {
  --fa: "\e13f";
  --fa--fa: "\e13f\e13f";
}

.fa-hat-witch {
  --fa: "\f6e7";
  --fa--fa: "\f6e7\f6e7";
}

.fa-face-grin-wink {
  --fa: "\f58c";
  --fa--fa: "\f58c\f58c";
}

.fa-grin-wink {
  --fa: "\f58c";
  --fa--fa: "\f58c\f58c";
}

.fa-clock-three-thirty {
  --fa: "\e357";
  --fa--fa: "\e357\e357";
}

.fa-ear-deaf {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-deaf {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-deafness {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-hard-of-hearing {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-alarm-clock {
  --fa: "\f34e";
  --fa--fa: "\f34e\f34e";
}

.fa-eclipse {
  --fa: "\f749";
  --fa--fa: "\f749\f749";
}

.fa-face-relieved {
  --fa: "\e389";
  --fa--fa: "\e389\e389";
}

.fa-road-circle-check {
  --fa: "\e564";
  --fa--fa: "\e564\e564";
}

.fa-dice-five {
  --fa: "\f523";
  --fa--fa: "\f523\f523";
}

.fa-octagon-minus {
  --fa: "\f308";
  --fa--fa: "\f308\f308";
}

.fa-minus-octagon {
  --fa: "\f308";
  --fa--fa: "\f308\f308";
}

.fa-square-rss {
  --fa: "\f143";
  --fa--fa: "\f143\f143";
}

.fa-rss-square {
  --fa: "\f143";
  --fa--fa: "\f143\f143";
}

.fa-face-zany {
  --fa: "\e3a4";
  --fa--fa: "\e3a4\e3a4";
}

.fa-tricycle {
  --fa: "\e5c3";
  --fa--fa: "\e5c3\e5c3";
}

.fa-land-mine-on {
  --fa: "\e51b";
  --fa--fa: "\e51b\e51b";
}

.fa-square-arrow-up-left {
  --fa: "\e263";
  --fa--fa: "\e263\e263";
}

.fa-i-cursor {
  --fa: "\f246";
  --fa--fa: "\f246\f246";
}

.fa-chart-mixed-up-circle-dollar {
  --fa: "\e5d9";
  --fa--fa: "\e5d9\e5d9";
}

.fa-salt-shaker {
  --fa: "\e446";
  --fa--fa: "\e446\e446";
}

.fa-stamp {
  --fa: "\f5bf";
  --fa--fa: "\f5bf\f5bf";
}

.fa-file-plus {
  --fa: "\f319";
  --fa--fa: "\f319\f319";
}

.fa-draw-square {
  --fa: "\f5ef";
  --fa--fa: "\f5ef\f5ef";
}

.fa-toilet-paper-under-slash {
  --fa: "\e2a1";
  --fa--fa: "\e2a1\e2a1";
}

.fa-toilet-paper-reverse-slash {
  --fa: "\e2a1";
  --fa--fa: "\e2a1\e2a1";
}

.fa-stairs {
  --fa: "\e289";
  --fa--fa: "\e289\e289";
}

.fa-drone-front {
  --fa: "\f860";
  --fa--fa: "\f860\f860";
}

.fa-drone-alt {
  --fa: "\f860";
  --fa--fa: "\f860\f860";
}

.fa-glass-empty {
  --fa: "\e191";
  --fa--fa: "\e191\e191";
}

.fa-dial-high {
  --fa: "\e15c";
  --fa--fa: "\e15c\e15c";
}

.fa-user-helmet-safety {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c";
}

.fa-user-construction {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c";
}

.fa-user-hard-hat {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c";
}

.fa-i {
  --fa: "I";
  --fa--fa: "II";
}

.fa-hryvnia-sign {
  --fa: "\f6f2";
  --fa--fa: "\f6f2\f6f2";
}

.fa-hryvnia {
  --fa: "\f6f2";
  --fa--fa: "\f6f2\f6f2";
}

.fa-arrow-down-left-and-arrow-up-right-to-center {
  --fa: "\e092";
  --fa--fa: "\e092\e092";
}

.fa-pills {
  --fa: "\f484";
  --fa--fa: "\f484\f484";
}

.fa-face-grin-wide {
  --fa: "\f581";
  --fa--fa: "\f581\f581";
}

.fa-grin-alt {
  --fa: "\f581";
  --fa--fa: "\f581\f581";
}

.fa-tooth {
  --fa: "\f5c9";
  --fa--fa: "\f5c9\f5c9";
}

.fa-basketball-hoop {
  --fa: "\f435";
  --fa--fa: "\f435\f435";
}

.fa-objects-align-bottom {
  --fa: "\e3bb";
  --fa--fa: "\e3bb\e3bb";
}

.fa-v {
  --fa: "V";
  --fa--fa: "VV";
}

.fa-sparkles {
  --fa: "\f890";
  --fa--fa: "\f890\f890";
}

.fa-squid {
  --fa: "\e450";
  --fa--fa: "\e450\e450";
}

.fa-leafy-green {
  --fa: "\e41d";
  --fa--fa: "\e41d\e41d";
}

.fa-circle-arrow-up-right {
  --fa: "\e0fc";
  --fa--fa: "\e0fc\e0fc";
}

.fa-calendars {
  --fa: "\e0d7";
  --fa--fa: "\e0d7\e0d7";
}

.fa-bangladeshi-taka-sign {
  --fa: "\e2e6";
  --fa--fa: "\e2e6\e2e6";
}

.fa-bicycle {
  --fa: "\f206";
  --fa--fa: "\f206\f206";
}

.fa-hammer-war {
  --fa: "\f6e4";
  --fa--fa: "\f6e4\f6e4";
}

.fa-circle-d {
  --fa: "\e104";
  --fa--fa: "\e104\e104";
}

.fa-spider-black-widow {
  --fa: "\f718";
  --fa--fa: "\f718\f718";
}

.fa-staff-snake {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-rod-asclepius {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-rod-snake {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-staff-aesculapius {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-pear {
  --fa: "\e20c";
  --fa--fa: "\e20c\e20c";
}

.fa-head-side-cough-slash {
  --fa: "\e062";
  --fa--fa: "\e062\e062";
}

.fa-file-mov {
  --fa: "\e647";
  --fa--fa: "\e647\e647";
}

.fa-triangle {
  --fa: "\f2ec";
  --fa--fa: "\f2ec\f2ec";
}

.fa-apartment {
  --fa: "\e468";
  --fa--fa: "\e468\e468";
}

.fa-truck-medical {
  --fa: "\f0f9";
  --fa--fa: "\f0f9\f0f9";
}

.fa-ambulance {
  --fa: "\f0f9";
  --fa--fa: "\f0f9\f0f9";
}

.fa-pepper {
  --fa: "\e432";
  --fa--fa: "\e432\e432";
}

.fa-piano {
  --fa: "\f8d4";
  --fa--fa: "\f8d4\f8d4";
}

.fa-gun-squirt {
  --fa: "\e19d";
  --fa--fa: "\e19d\e19d";
}

.fa-wheat-awn-circle-exclamation {
  --fa: "\e598";
  --fa--fa: "\e598\e598";
}

.fa-snowman {
  --fa: "\f7d0";
  --fa--fa: "\f7d0\f7d0";
}

.fa-user-alien {
  --fa: "\e04a";
  --fa--fa: "\e04a\e04a";
}

.fa-shield-check {
  --fa: "\f2f7";
  --fa--fa: "\f2f7\f2f7";
}

.fa-mortar-pestle {
  --fa: "\f5a7";
  --fa--fa: "\f5a7\f5a7";
}

.fa-road-barrier {
  --fa: "\e562";
  --fa--fa: "\e562\e562";
}

.fa-chart-candlestick {
  --fa: "\e0e2";
  --fa--fa: "\e0e2\e0e2";
}

.fa-briefcase-blank {
  --fa: "\e0c8";
  --fa--fa: "\e0c8\e0c8";
}

.fa-school {
  --fa: "\f549";
  --fa--fa: "\f549\f549";
}

.fa-igloo {
  --fa: "\f7ae";
  --fa--fa: "\f7ae\f7ae";
}

.fa-bracket-round {
  --fa: "\(";
  --fa--fa: "\(\(";
}

.fa-parenthesis {
  --fa: "\(";
  --fa--fa: "\(\(";
}

.fa-joint {
  --fa: "\f595";
  --fa--fa: "\f595\f595";
}

.fa-horse-saddle {
  --fa: "\f8c3";
  --fa--fa: "\f8c3\f8c3";
}

.fa-mug-marshmallows {
  --fa: "\f7b7";
  --fa--fa: "\f7b7\f7b7";
}

.fa-filters {
  --fa: "\e17e";
  --fa--fa: "\e17e\e17e";
}

.fa-bell-on {
  --fa: "\f8fa";
  --fa--fa: "\f8fa\f8fa";
}

.fa-angle-right {
  --fa: "\f105";
  --fa--fa: "\f105\f105";
}

.fa-dial-med {
  --fa: "\e15f";
  --fa--fa: "\e15f\e15f";
}

.fa-horse {
  --fa: "\f6f0";
  --fa--fa: "\f6f0\f6f0";
}

.fa-q {
  --fa: "Q";
  --fa--fa: "QQ";
}

.fa-monitor-waveform {
  --fa: "\f611";
  --fa--fa: "\f611\f611";
}

.fa-monitor-heart-rate {
  --fa: "\f611";
  --fa--fa: "\f611\f611";
}

.fa-link-simple {
  --fa: "\e1cd";
  --fa--fa: "\e1cd\e1cd";
}

.fa-whistle {
  --fa: "\f460";
  --fa--fa: "\f460\f460";
}

.fa-g {
  --fa: "G";
  --fa--fa: "GG";
}

.fa-wine-glass-crack {
  --fa: "\f4bb";
  --fa--fa: "\f4bb\f4bb";
}

.fa-fragile {
  --fa: "\f4bb";
  --fa--fa: "\f4bb\f4bb";
}

.fa-slot-machine {
  --fa: "\e3ce";
  --fa--fa: "\e3ce\e3ce";
}

.fa-notes-medical {
  --fa: "\f481";
  --fa--fa: "\f481\f481";
}

.fa-car-wash {
  --fa: "\f5e6";
  --fa--fa: "\f5e6\f5e6";
}

.fa-escalator {
  --fa: "\e171";
  --fa--fa: "\e171\e171";
}

.fa-comment-image {
  --fa: "\e148";
  --fa--fa: "\e148\e148";
}

.fa-temperature-half {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-temperature-2 {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-thermometer-2 {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-thermometer-half {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-dong-sign {
  --fa: "\e169";
  --fa--fa: "\e169\e169";
}

.fa-donut {
  --fa: "\e406";
  --fa--fa: "\e406\e406";
}

.fa-doughnut {
  --fa: "\e406";
  --fa--fa: "\e406\e406";
}

.fa-capsules {
  --fa: "\f46b";
  --fa--fa: "\f46b\f46b";
}

.fa-poo-storm {
  --fa: "\f75a";
  --fa--fa: "\f75a\f75a";
}

.fa-poo-bolt {
  --fa: "\f75a";
  --fa--fa: "\f75a\f75a";
}

.fa-tally-1 {
  --fa: "\e294";
  --fa--fa: "\e294\e294";
}

.fa-file-vector {
  --fa: "\e64c";
  --fa--fa: "\e64c\e64c";
}

.fa-face-frown-open {
  --fa: "\f57a";
  --fa--fa: "\f57a\f57a";
}

.fa-frown-open {
  --fa: "\f57a";
  --fa--fa: "\f57a\f57a";
}

.fa-square-dashed {
  --fa: "\e269";
  --fa--fa: "\e269\e269";
}

.fa-bag-shopping-plus {
  --fa: "\e651";
  --fa--fa: "\e651\e651";
}

.fa-square-j {
  --fa: "\e273";
  --fa--fa: "\e273\e273";
}

.fa-hand-point-up {
  --fa: "\f0a6";
  --fa--fa: "\f0a6\f0a6";
}

.fa-money-bill {
  --fa: "\f0d6";
  --fa--fa: "\f0d6\f0d6";
}

.fa-arrow-up-big-small {
  --fa: "\f88e";
  --fa--fa: "\f88e\f88e";
}

.fa-sort-size-up {
  --fa: "\f88e";
  --fa--fa: "\f88e\f88e";
}

.fa-barcode-read {
  --fa: "\f464";
  --fa--fa: "\f464\f464";
}

.fa-baguette {
  --fa: "\e3d8";
  --fa--fa: "\e3d8\e3d8";
}

.fa-bowl-soft-serve {
  --fa: "\e46b";
  --fa--fa: "\e46b\e46b";
}

.fa-face-holding-back-tears {
  --fa: "\e482";
  --fa--fa: "\e482\e482";
}

.fa-square-up {
  --fa: "\f353";
  --fa--fa: "\f353\f353";
}

.fa-arrow-alt-square-up {
  --fa: "\f353";
  --fa--fa: "\f353\f353";
}

.fa-train-subway-tunnel {
  --fa: "\e2a3";
  --fa--fa: "\e2a3\e2a3";
}

.fa-subway-tunnel {
  --fa: "\e2a3";
  --fa--fa: "\e2a3\e2a3";
}

.fa-square-exclamation {
  --fa: "\f321";
  --fa--fa: "\f321\f321";
}

.fa-exclamation-square {
  --fa: "\f321";
  --fa--fa: "\f321\f321";
}

.fa-semicolon {
  --fa: "\;";
  --fa--fa: "\;\;";
}

.fa-bookmark {
  --fa: "\f02e";
  --fa--fa: "\f02e\f02e";
}

.fa-fan-table {
  --fa: "\e004";
  --fa--fa: "\e004\e004";
}

.fa-align-justify {
  --fa: "\f039";
  --fa--fa: "\f039\f039";
}

.fa-battery-low {
  --fa: "\e0b1";
  --fa--fa: "\e0b1\e0b1";
}

.fa-battery-1 {
  --fa: "\e0b1";
  --fa--fa: "\e0b1\e0b1";
}

.fa-credit-card-front {
  --fa: "\f38a";
  --fa--fa: "\f38a\f38a";
}

.fa-brain-arrow-curved-right {
  --fa: "\f677";
  --fa--fa: "\f677\f677";
}

.fa-mind-share {
  --fa: "\f677";
  --fa--fa: "\f677\f677";
}

.fa-umbrella-beach {
  --fa: "\f5ca";
  --fa--fa: "\f5ca\f5ca";
}

.fa-helmet-un {
  --fa: "\e503";
  --fa--fa: "\e503\e503";
}

.fa-location-smile {
  --fa: "\f60d";
  --fa--fa: "\f60d\f60d";
}

.fa-map-marker-smile {
  --fa: "\f60d";
  --fa--fa: "\f60d\f60d";
}

.fa-arrow-left-to-line {
  --fa: "\f33e";
  --fa--fa: "\f33e\f33e";
}

.fa-arrow-to-left {
  --fa: "\f33e";
  --fa--fa: "\f33e\f33e";
}

.fa-bullseye {
  --fa: "\f140";
  --fa--fa: "\f140\f140";
}

.fa-sushi {
  --fa: "\e48a";
  --fa--fa: "\e48a\e48a";
}

.fa-nigiri {
  --fa: "\e48a";
  --fa--fa: "\e48a\e48a";
}

.fa-message-captions {
  --fa: "\e1de";
  --fa--fa: "\e1de\e1de";
}

.fa-comment-alt-captions {
  --fa: "\e1de";
  --fa--fa: "\e1de\e1de";
}

.fa-trash-list {
  --fa: "\e2b1";
  --fa--fa: "\e2b1\e2b1";
}

.fa-bacon {
  --fa: "\f7e5";
  --fa--fa: "\f7e5\f7e5";
}

.fa-option {
  --fa: "\e318";
  --fa--fa: "\e318\e318";
}

.fa-raccoon {
  --fa: "\e613";
  --fa--fa: "\e613\e613";
}

.fa-hand-point-down {
  --fa: "\f0a7";
  --fa--fa: "\f0a7\f0a7";
}

.fa-arrow-up-from-bracket {
  --fa: "\e09a";
  --fa--fa: "\e09a\e09a";
}

.fa-head-side-gear {
  --fa: "\e611";
  --fa--fa: "\e611\e611";
}

.fa-trash-plus {
  --fa: "\e2b2";
  --fa--fa: "\e2b2\e2b2";
}

.fa-file-cad {
  --fa: "\e672";
  --fa--fa: "\e672\e672";
}

.fa-objects-align-top {
  --fa: "\e3c0";
  --fa--fa: "\e3c0\e3c0";
}

.fa-folder {
  --fa: "\f07b";
  --fa--fa: "\f07b\f07b";
}

.fa-folder-blank {
  --fa: "\f07b";
  --fa--fa: "\f07b\f07b";
}

.fa-face-anxious-sweat {
  --fa: "\e36a";
  --fa--fa: "\e36a\e36a";
}

.fa-credit-card-blank {
  --fa: "\f389";
  --fa--fa: "\f389\f389";
}

.fa-file-waveform {
  --fa: "\f478";
  --fa--fa: "\f478\f478";
}

.fa-file-medical-alt {
  --fa: "\f478";
  --fa--fa: "\f478\f478";
}

.fa-microchip-ai {
  --fa: "\e1ec";
  --fa--fa: "\e1ec\e1ec";
}

.fa-mug {
  --fa: "\f874";
  --fa--fa: "\f874\f874";
}

.fa-plane-up-slash {
  --fa: "\e22e";
  --fa--fa: "\e22e\e22e";
}

.fa-radiation {
  --fa: "\f7b9";
  --fa--fa: "\f7b9\f7b9";
}

.fa-pen-circle {
  --fa: "\e20e";
  --fa--fa: "\e20e\e20e";
}

.fa-bag-seedling {
  --fa: "\e5f2";
  --fa--fa: "\e5f2\e5f2";
}

.fa-chart-simple {
  --fa: "\e473";
  --fa--fa: "\e473\e473";
}

.fa-crutches {
  --fa: "\f7f8";
  --fa--fa: "\f7f8\f7f8";
}

.fa-circle-parking {
  --fa: "\f615";
  --fa--fa: "\f615\f615";
}

.fa-parking-circle {
  --fa: "\f615";
  --fa--fa: "\f615\f615";
}

.fa-mars-stroke {
  --fa: "\f229";
  --fa--fa: "\f229\f229";
}

.fa-leaf-oak {
  --fa: "\f6f7";
  --fa--fa: "\f6f7\f6f7";
}

.fa-square-bolt {
  --fa: "\e265";
  --fa--fa: "\e265\e265";
}

.fa-vial {
  --fa: "\f492";
  --fa--fa: "\f492\f492";
}

.fa-gauge {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-dashboard {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-gauge-med {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-tachometer-alt-average {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-wand-magic-sparkles {
  --fa: "\e2ca";
  --fa--fa: "\e2ca\e2ca";
}

.fa-magic-wand-sparkles {
  --fa: "\e2ca";
  --fa--fa: "\e2ca\e2ca";
}

.fa-lambda {
  --fa: "\f66e";
  --fa--fa: "\f66e\f66e";
}

.fa-e {
  --fa: "E";
  --fa--fa: "EE";
}

.fa-pizza {
  --fa: "\f817";
  --fa--fa: "\f817\f817";
}

.fa-bowl-chopsticks-noodles {
  --fa: "\e2ea";
  --fa--fa: "\e2ea\e2ea";
}

.fa-h3 {
  --fa: "\f315";
  --fa--fa: "\f315\f315";
}

.fa-pen-clip {
  --fa: "\f305";
  --fa--fa: "\f305\f305";
}

.fa-pen-alt {
  --fa: "\f305";
  --fa--fa: "\f305\f305";
}

.fa-bridge-circle-exclamation {
  --fa: "\e4ca";
  --fa--fa: "\e4ca\e4ca";
}

.fa-badge-percent {
  --fa: "\f646";
  --fa--fa: "\f646\f646";
}

.fa-rotate-reverse {
  --fa: "\e631";
  --fa--fa: "\e631\e631";
}

.fa-user {
  --fa: "\f007";
  --fa--fa: "\f007\f007";
}

.fa-sensor {
  --fa: "\e028";
  --fa--fa: "\e028\e028";
}

.fa-comma {
  --fa: "\,";
  --fa--fa: "\,\,";
}

.fa-school-circle-check {
  --fa: "\e56b";
  --fa--fa: "\e56b\e56b";
}

.fa-toilet-paper-under {
  --fa: "\e2a0";
  --fa--fa: "\e2a0\e2a0";
}

.fa-toilet-paper-reverse {
  --fa: "\e2a0";
  --fa--fa: "\e2a0\e2a0";
}

.fa-light-emergency {
  --fa: "\e41f";
  --fa--fa: "\e41f\e41f";
}

.fa-arrow-down-to-arc {
  --fa: "\e4ae";
  --fa--fa: "\e4ae\e4ae";
}

.fa-dumpster {
  --fa: "\f793";
  --fa--fa: "\f793\f793";
}

.fa-van-shuttle {
  --fa: "\f5b6";
  --fa--fa: "\f5b6\f5b6";
}

.fa-shuttle-van {
  --fa: "\f5b6";
  --fa--fa: "\f5b6\f5b6";
}

.fa-building-user {
  --fa: "\e4da";
  --fa--fa: "\e4da\e4da";
}

.fa-light-switch {
  --fa: "\e017";
  --fa--fa: "\e017\e017";
}

.fa-square-caret-left {
  --fa: "\f191";
  --fa--fa: "\f191\f191";
}

.fa-caret-square-left {
  --fa: "\f191";
  --fa--fa: "\f191\f191";
}

.fa-highlighter {
  --fa: "\f591";
  --fa--fa: "\f591\f591";
}

.fa-wave-pulse {
  --fa: "\f5f8";
  --fa--fa: "\f5f8\f5f8";
}

.fa-heart-rate {
  --fa: "\f5f8";
  --fa--fa: "\f5f8\f5f8";
}

.fa-key {
  --fa: "\f084";
  --fa--fa: "\f084\f084";
}

.fa-arrow-left-to-bracket {
  --fa: "\e669";
  --fa--fa: "\e669\e669";
}

.fa-hat-santa {
  --fa: "\f7a7";
  --fa--fa: "\f7a7\f7a7";
}

.fa-tamale {
  --fa: "\e451";
  --fa--fa: "\e451\e451";
}

.fa-box-check {
  --fa: "\f467";
  --fa--fa: "\f467\f467";
}

.fa-bullhorn {
  --fa: "\f0a1";
  --fa--fa: "\f0a1\f0a1";
}

.fa-steak {
  --fa: "\f824";
  --fa--fa: "\f824\f824";
}

.fa-location-crosshairs-slash {
  --fa: "\f603";
  --fa--fa: "\f603\f603";
}

.fa-location-slash {
  --fa: "\f603";
  --fa--fa: "\f603\f603";
}

.fa-person-dolly {
  --fa: "\f4d0";
  --fa--fa: "\f4d0\f4d0";
}

.fa-globe {
  --fa: "\f0ac";
  --fa--fa: "\f0ac\f0ac";
}

.fa-synagogue {
  --fa: "\f69b";
  --fa--fa: "\f69b\f69b";
}

.fa-file-chart-column {
  --fa: "\f659";
  --fa--fa: "\f659\f659";
}

.fa-file-chart-line {
  --fa: "\f659";
  --fa--fa: "\f659\f659";
}

.fa-person-half-dress {
  --fa: "\e548";
  --fa--fa: "\e548\e548";
}

.fa-folder-image {
  --fa: "\e18a";
  --fa--fa: "\e18a\e18a";
}

.fa-calendar-pen {
  --fa: "\f333";
  --fa--fa: "\f333\f333";
}

.fa-calendar-edit {
  --fa: "\f333";
  --fa--fa: "\f333\f333";
}

.fa-road-bridge {
  --fa: "\e563";
  --fa--fa: "\e563\e563";
}

.fa-face-smile-tear {
  --fa: "\e393";
  --fa--fa: "\e393\e393";
}

.fa-message-plus {
  --fa: "\f4a8";
  --fa--fa: "\f4a8\f4a8";
}

.fa-comment-alt-plus {
  --fa: "\f4a8";
  --fa--fa: "\f4a8\f4a8";
}

.fa-location-arrow {
  --fa: "\f124";
  --fa--fa: "\f124\f124";
}

.fa-c {
  --fa: "C";
  --fa--fa: "CC";
}

.fa-tablet-button {
  --fa: "\f10a";
  --fa--fa: "\f10a\f10a";
}

.fa-person-dress-fairy {
  --fa: "\e607";
  --fa--fa: "\e607\e607";
}

.fa-rectangle-history-circle-user {
  --fa: "\e4a4";
  --fa--fa: "\e4a4\e4a4";
}

.fa-building-lock {
  --fa: "\e4d6";
  --fa--fa: "\e4d6\e4d6";
}

.fa-chart-line-up {
  --fa: "\e0e5";
  --fa--fa: "\e0e5\e0e5";
}

.fa-mailbox {
  --fa: "\f813";
  --fa--fa: "\f813\f813";
}

.fa-sign-posts {
  --fa: "\e625";
  --fa--fa: "\e625\e625";
}

.fa-truck-bolt {
  --fa: "\e3d0";
  --fa--fa: "\e3d0\e3d0";
}

.fa-pizza-slice {
  --fa: "\f818";
  --fa--fa: "\f818\f818";
}

.fa-money-bill-wave {
  --fa: "\f53a";
  --fa--fa: "\f53a\f53a";
}

.fa-chart-area {
  --fa: "\f1fe";
  --fa--fa: "\f1fe\f1fe";
}

.fa-area-chart {
  --fa: "\f1fe";
  --fa--fa: "\f1fe\f1fe";
}

.fa-house-flag {
  --fa: "\e50d";
  --fa--fa: "\e50d\e50d";
}

.fa-circle-three-quarters-stroke {
  --fa: "\e5d4";
  --fa--fa: "\e5d4\e5d4";
}

.fa-person-circle-minus {
  --fa: "\e540";
  --fa--fa: "\e540\e540";
}

.fa-scalpel {
  --fa: "\f61d";
  --fa--fa: "\f61d\f61d";
}

.fa-ban {
  --fa: "\f05e";
  --fa--fa: "\f05e\f05e";
}

.fa-cancel {
  --fa: "\f05e";
  --fa--fa: "\f05e\f05e";
}

.fa-bell-exclamation {
  --fa: "\f848";
  --fa--fa: "\f848\f848";
}

.fa-circle-bookmark {
  --fa: "\e100";
  --fa--fa: "\e100\e100";
}

.fa-bookmark-circle {
  --fa: "\e100";
  --fa--fa: "\e100\e100";
}

.fa-egg-fried {
  --fa: "\f7fc";
  --fa--fa: "\f7fc\f7fc";
}

.fa-face-weary {
  --fa: "\e3a1";
  --fa--fa: "\e3a1\e3a1";
}

.fa-uniform-martial-arts {
  --fa: "\e3d1";
  --fa--fa: "\e3d1\e3d1";
}

.fa-camera-rotate {
  --fa: "\e0d8";
  --fa--fa: "\e0d8\e0d8";
}

.fa-sun-dust {
  --fa: "\f764";
  --fa--fa: "\f764\f764";
}

.fa-comment-text {
  --fa: "\e14d";
  --fa--fa: "\e14d\e14d";
}

.fa-spray-can-sparkles {
  --fa: "\f5d0";
  --fa--fa: "\f5d0\f5d0";
}

.fa-air-freshener {
  --fa: "\f5d0";
  --fa--fa: "\f5d0\f5d0";
}

.fa-signal-bars {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-signal-alt {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-signal-alt-4 {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-signal-bars-strong {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-diamond-exclamation {
  --fa: "\e405";
  --fa--fa: "\e405\e405";
}

.fa-star {
  --fa: "\f005";
  --fa--fa: "\f005\f005";
}

.fa-dial-min {
  --fa: "\e161";
  --fa--fa: "\e161\e161";
}

.fa-repeat {
  --fa: "\f363";
  --fa--fa: "\f363\f363";
}

.fa-cross {
  --fa: "\f654";
  --fa--fa: "\f654\f654";
}

.fa-page-caret-down {
  --fa: "\e429";
  --fa--fa: "\e429\e429";
}

.fa-file-caret-down {
  --fa: "\e429";
  --fa--fa: "\e429\e429";
}

.fa-box {
  --fa: "\f466";
  --fa--fa: "\f466\f466";
}

.fa-venus-mars {
  --fa: "\f228";
  --fa--fa: "\f228\f228";
}

.fa-clock-seven-thirty {
  --fa: "\e351";
  --fa--fa: "\e351\e351";
}

.fa-arrow-pointer {
  --fa: "\f245";
  --fa--fa: "\f245\f245";
}

.fa-mouse-pointer {
  --fa: "\f245";
  --fa--fa: "\f245\f245";
}

.fa-clock-four-thirty {
  --fa: "\e34b";
  --fa--fa: "\e34b\e34b";
}

.fa-signal-bars-good {
  --fa: "\f693";
  --fa--fa: "\f693\f693";
}

.fa-signal-alt-3 {
  --fa: "\f693";
  --fa--fa: "\f693\f693";
}

.fa-cactus {
  --fa: "\f8a7";
  --fa--fa: "\f8a7\f8a7";
}

.fa-lightbulb-gear {
  --fa: "\e5fd";
  --fa--fa: "\e5fd\e5fd";
}

.fa-maximize {
  --fa: "\f31e";
  --fa--fa: "\f31e\f31e";
}

.fa-expand-arrows-alt {
  --fa: "\f31e";
  --fa--fa: "\f31e\f31e";
}

.fa-charging-station {
  --fa: "\f5e7";
  --fa--fa: "\f5e7\f5e7";
}

.fa-shapes {
  --fa: "\f61f";
  --fa--fa: "\f61f\f61f";
}

.fa-triangle-circle-square {
  --fa: "\f61f";
  --fa--fa: "\f61f\f61f";
}

.fa-plane-tail {
  --fa: "\e22c";
  --fa--fa: "\e22c\e22c";
}

.fa-gauge-simple-max {
  --fa: "\f62b";
  --fa--fa: "\f62b\f62b";
}

.fa-tachometer-fastest {
  --fa: "\f62b";
  --fa--fa: "\f62b\f62b";
}

.fa-circle-u {
  --fa: "\e127";
  --fa--fa: "\e127\e127";
}

.fa-shield-slash {
  --fa: "\e24b";
  --fa--fa: "\e24b\e24b";
}

.fa-square-phone-hangup {
  --fa: "\e27a";
  --fa--fa: "\e27a\e27a";
}

.fa-phone-square-down {
  --fa: "\e27a";
  --fa--fa: "\e27a\e27a";
}

.fa-arrow-up-left {
  --fa: "\e09d";
  --fa--fa: "\e09d\e09d";
}

.fa-transporter-1 {
  --fa: "\e043";
  --fa--fa: "\e043\e043";
}

.fa-peanuts {
  --fa: "\e431";
  --fa--fa: "\e431\e431";
}

.fa-shuffle {
  --fa: "\f074";
  --fa--fa: "\f074\f074";
}

.fa-random {
  --fa: "\f074";
  --fa--fa: "\f074\f074";
}

.fa-person-running {
  --fa: "\f70c";
  --fa--fa: "\f70c\f70c";
}

.fa-running {
  --fa: "\f70c";
  --fa--fa: "\f70c\f70c";
}

.fa-mobile-retro {
  --fa: "\e527";
  --fa--fa: "\e527\e527";
}

.fa-grip-lines-vertical {
  --fa: "\f7a5";
  --fa--fa: "\f7a5\f7a5";
}

.fa-bin-bottles-recycle {
  --fa: "\e5f6";
  --fa--fa: "\e5f6\e5f6";
}

.fa-arrow-up-from-square {
  --fa: "\e09c";
  --fa--fa: "\e09c\e09c";
}

.fa-file-dashed-line {
  --fa: "\f877";
  --fa--fa: "\f877\f877";
}

.fa-page-break {
  --fa: "\f877";
  --fa--fa: "\f877\f877";
}

.fa-bracket-curly-right {
  --fa: "\}";
  --fa--fa: "\}\}";
}

.fa-spider {
  --fa: "\f717";
  --fa--fa: "\f717\f717";
}

.fa-clock-three {
  --fa: "\e356";
  --fa--fa: "\e356\e356";
}

.fa-hands-bound {
  --fa: "\e4f9";
  --fa--fa: "\e4f9\e4f9";
}

.fa-scalpel-line-dashed {
  --fa: "\f61e";
  --fa--fa: "\f61e\f61e";
}

.fa-scalpel-path {
  --fa: "\f61e";
  --fa--fa: "\f61e\f61e";
}

.fa-file-invoice-dollar {
  --fa: "\f571";
  --fa--fa: "\f571\f571";
}

.fa-pipe-smoking {
  --fa: "\e3c4";
  --fa--fa: "\e3c4\e3c4";
}

.fa-face-astonished {
  --fa: "\e36b";
  --fa--fa: "\e36b\e36b";
}

.fa-window {
  --fa: "\f40e";
  --fa--fa: "\f40e\f40e";
}

.fa-plane-circle-exclamation {
  --fa: "\e556";
  --fa--fa: "\e556\e556";
}

.fa-ear {
  --fa: "\f5f0";
  --fa--fa: "\f5f0\f5f0";
}

.fa-file-lock {
  --fa: "\e3a6";
  --fa--fa: "\e3a6\e3a6";
}

.fa-diagram-venn {
  --fa: "\e15a";
  --fa--fa: "\e15a\e15a";
}

.fa-arrow-down-from-bracket {
  --fa: "\e667";
  --fa--fa: "\e667\e667";
}

.fa-x-ray {
  --fa: "\f497";
  --fa--fa: "\f497\f497";
}

.fa-goal-net {
  --fa: "\e3ab";
  --fa--fa: "\e3ab\e3ab";
}

.fa-coffin-cross {
  --fa: "\e051";
  --fa--fa: "\e051\e051";
}

.fa-octopus {
  --fa: "\e688";
  --fa--fa: "\e688\e688";
}

.fa-spell-check {
  --fa: "\f891";
  --fa--fa: "\f891\f891";
}

.fa-location-xmark {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e";
}

.fa-map-marker-times {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e";
}

.fa-map-marker-xmark {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e";
}

.fa-circle-quarter-stroke {
  --fa: "\e5d3";
  --fa--fa: "\e5d3\e5d3";
}

.fa-lasso {
  --fa: "\f8c8";
  --fa--fa: "\f8c8\f8c8";
}

.fa-slash {
  --fa: "\f715";
  --fa--fa: "\f715\f715";
}

.fa-person-to-portal {
  --fa: "\e022";
  --fa--fa: "\e022\e022";
}

.fa-portal-enter {
  --fa: "\e022";
  --fa--fa: "\e022\e022";
}

.fa-calendar-star {
  --fa: "\f736";
  --fa--fa: "\f736\f736";
}

.fa-computer-mouse {
  --fa: "\f8cc";
  --fa--fa: "\f8cc\f8cc";
}

.fa-mouse {
  --fa: "\f8cc";
  --fa--fa: "\f8cc\f8cc";
}

.fa-arrow-right-to-bracket {
  --fa: "\f090";
  --fa--fa: "\f090\f090";
}

.fa-sign-in {
  --fa: "\f090";
  --fa--fa: "\f090\f090";
}

.fa-pegasus {
  --fa: "\f703";
  --fa--fa: "\f703\f703";
}

.fa-files-medical {
  --fa: "\f7fd";
  --fa--fa: "\f7fd\f7fd";
}

.fa-cannon {
  --fa: "\e642";
  --fa--fa: "\e642\e642";
}

.fa-nfc-lock {
  --fa: "\e1f8";
  --fa--fa: "\e1f8\e1f8";
}

.fa-person-ski-lift {
  --fa: "\f7c8";
  --fa--fa: "\f7c8\f7c8";
}

.fa-ski-lift {
  --fa: "\f7c8";
  --fa--fa: "\f7c8\f7c8";
}

.fa-square-6 {
  --fa: "\e25b";
  --fa--fa: "\e25b\e25b";
}

.fa-shop-slash {
  --fa: "\e070";
  --fa--fa: "\e070\e070";
}

.fa-store-alt-slash {
  --fa: "\e070";
  --fa--fa: "\e070\e070";
}

.fa-wind-turbine {
  --fa: "\f89b";
  --fa--fa: "\f89b\f89b";
}

.fa-sliders-simple {
  --fa: "\e253";
  --fa--fa: "\e253\e253";
}

.fa-grid-round {
  --fa: "\e5da";
  --fa--fa: "\e5da\e5da";
}

.fa-badge-sheriff {
  --fa: "\f8a2";
  --fa--fa: "\f8a2\f8a2";
}

.fa-server {
  --fa: "\f233";
  --fa--fa: "\f233\f233";
}

.fa-virus-covid-slash {
  --fa: "\e4a9";
  --fa--fa: "\e4a9\e4a9";
}

.fa-intersection {
  --fa: "\f668";
  --fa--fa: "\f668\f668";
}

.fa-shop-lock {
  --fa: "\e4a5";
  --fa--fa: "\e4a5\e4a5";
}

.fa-family {
  --fa: "\e300";
  --fa--fa: "\e300\e300";
}

.fa-hourglass-start {
  --fa: "\f251";
  --fa--fa: "\f251\f251";
}

.fa-hourglass-1 {
  --fa: "\f251";
  --fa--fa: "\f251\f251";
}

.fa-user-hair-buns {
  --fa: "\e3d3";
  --fa--fa: "\e3d3\e3d3";
}

.fa-blender-phone {
  --fa: "\f6b6";
  --fa--fa: "\f6b6\f6b6";
}

.fa-hourglass-clock {
  --fa: "\e41b";
  --fa--fa: "\e41b\e41b";
}

.fa-person-seat-reclined {
  --fa: "\e21f";
  --fa--fa: "\e21f\e21f";
}

.fa-paper-plane-top {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a";
}

.fa-paper-plane-alt {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a";
}

.fa-send {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a";
}

.fa-message-arrow-up {
  --fa: "\e1dc";
  --fa--fa: "\e1dc\e1dc";
}

.fa-comment-alt-arrow-up {
  --fa: "\e1dc";
  --fa--fa: "\e1dc\e1dc";
}

.fa-lightbulb-exclamation {
  --fa: "\f671";
  --fa--fa: "\f671\f671";
}

.fa-layer-minus {
  --fa: "\f5fe";
  --fa--fa: "\f5fe\f5fe";
}

.fa-layer-group-minus {
  --fa: "\f5fe";
  --fa--fa: "\f5fe\f5fe";
}

.fa-chart-pie-simple-circle-currency {
  --fa: "\e604";
  --fa--fa: "\e604\e604";
}

.fa-circle-e {
  --fa: "\e109";
  --fa--fa: "\e109\e109";
}

.fa-building-wheat {
  --fa: "\e4db";
  --fa--fa: "\e4db\e4db";
}

.fa-gauge-max {
  --fa: "\f626";
  --fa--fa: "\f626\f626";
}

.fa-tachometer-alt-fastest {
  --fa: "\f626";
  --fa--fa: "\f626\f626";
}

.fa-person-breastfeeding {
  --fa: "\e53a";
  --fa--fa: "\e53a\e53a";
}

.fa-apostrophe {
  --fa: "\'";
  --fa--fa: "\'\'";
}

.fa-file-png {
  --fa: "\e666";
  --fa--fa: "\e666\e666";
}

.fa-fire-hydrant {
  --fa: "\e17f";
  --fa--fa: "\e17f\e17f";
}

.fa-right-to-bracket {
  --fa: "\f2f6";
  --fa--fa: "\f2f6\f2f6";
}

.fa-sign-in-alt {
  --fa: "\f2f6";
  --fa--fa: "\f2f6\f2f6";
}

.fa-video-plus {
  --fa: "\f4e1";
  --fa--fa: "\f4e1\f4e1";
}

.fa-square-right {
  --fa: "\f352";
  --fa--fa: "\f352\f352";
}

.fa-arrow-alt-square-right {
  --fa: "\f352";
  --fa--fa: "\f352\f352";
}

.fa-comment-smile {
  --fa: "\f4b4";
  --fa--fa: "\f4b4\f4b4";
}

.fa-venus {
  --fa: "\f221";
  --fa--fa: "\f221\f221";
}

.fa-passport {
  --fa: "\f5ab";
  --fa--fa: "\f5ab\f5ab";
}

.fa-thumbtack-slash {
  --fa: "\e68f";
  --fa--fa: "\e68f\e68f";
}

.fa-thumb-tack-slash {
  --fa: "\e68f";
  --fa--fa: "\e68f\e68f";
}

.fa-inbox-in {
  --fa: "\f310";
  --fa--fa: "\f310\f310";
}

.fa-inbox-arrow-down {
  --fa: "\f310";
  --fa--fa: "\f310\f310";
}

.fa-heart-pulse {
  --fa: "\f21e";
  --fa--fa: "\f21e\f21e";
}

.fa-heartbeat {
  --fa: "\f21e";
  --fa--fa: "\f21e\f21e";
}

.fa-circle-8 {
  --fa: "\e0f5";
  --fa--fa: "\e0f5\e0f5";
}

.fa-clouds-moon {
  --fa: "\f745";
  --fa--fa: "\f745\f745";
}

.fa-clock-ten-thirty {
  --fa: "\e355";
  --fa--fa: "\e355\e355";
}

.fa-people-carry-box {
  --fa: "\f4ce";
  --fa--fa: "\f4ce\f4ce";
}

.fa-people-carry {
  --fa: "\f4ce";
  --fa--fa: "\f4ce\f4ce";
}

.fa-folder-user {
  --fa: "\e18e";
  --fa--fa: "\e18e\e18e";
}

.fa-trash-can-xmark {
  --fa: "\e2ae";
  --fa--fa: "\e2ae\e2ae";
}

.fa-temperature-high {
  --fa: "\f769";
  --fa--fa: "\f769\f769";
}

.fa-microchip {
  --fa: "\f2db";
  --fa--fa: "\f2db\f2db";
}

.fa-left-long-to-line {
  --fa: "\e41e";
  --fa--fa: "\e41e\e41e";
}

.fa-crown {
  --fa: "\f521";
  --fa--fa: "\f521\f521";
}

.fa-weight-hanging {
  --fa: "\f5cd";
  --fa--fa: "\f5cd\f5cd";
}

.fa-xmarks-lines {
  --fa: "\e59a";
  --fa--fa: "\e59a\e59a";
}

.fa-file-prescription {
  --fa: "\f572";
  --fa--fa: "\f572\f572";
}

.fa-table-cells-lock {
  --fa: "\e679";
  --fa--fa: "\e679\e679";
}

.fa-calendar-range {
  --fa: "\e0d6";
  --fa--fa: "\e0d6\e0d6";
}

.fa-flower-daffodil {
  --fa: "\f800";
  --fa--fa: "\f800\f800";
}

.fa-hand-back-point-up {
  --fa: "\e1a2";
  --fa--fa: "\e1a2\e1a2";
}

.fa-weight-scale {
  --fa: "\f496";
  --fa--fa: "\f496\f496";
}

.fa-weight {
  --fa: "\f496";
  --fa--fa: "\f496\f496";
}

.fa-arrow-up-to-arc {
  --fa: "\e617";
  --fa--fa: "\e617\e617";
}

.fa-star-exclamation {
  --fa: "\f2f3";
  --fa--fa: "\f2f3\f2f3";
}

.fa-books {
  --fa: "\f5db";
  --fa--fa: "\f5db\f5db";
}

.fa-user-group {
  --fa: "\f500";
  --fa--fa: "\f500\f500";
}

.fa-user-friends {
  --fa: "\f500";
  --fa--fa: "\f500\f500";
}

.fa-arrow-up-a-z {
  --fa: "\f15e";
  --fa--fa: "\f15e\f15e";
}

.fa-sort-alpha-up {
  --fa: "\f15e";
  --fa--fa: "\f15e\f15e";
}

.fa-layer-plus {
  --fa: "\f5ff";
  --fa--fa: "\f5ff\f5ff";
}

.fa-layer-group-plus {
  --fa: "\f5ff";
  --fa--fa: "\f5ff\f5ff";
}

.fa-play-pause {
  --fa: "\e22f";
  --fa--fa: "\e22f\e22f";
}

.fa-block-question {
  --fa: "\e3dd";
  --fa--fa: "\e3dd\e3dd";
}

.fa-snooze {
  --fa: "\f880";
  --fa--fa: "\f880\f880";
}

.fa-zzz {
  --fa: "\f880";
  --fa--fa: "\f880\f880";
}

.fa-scanner-image {
  --fa: "\f8f3";
  --fa--fa: "\f8f3\f8f3";
}

.fa-tv-retro {
  --fa: "\f401";
  --fa--fa: "\f401\f401";
}

.fa-square-t {
  --fa: "\e280";
  --fa--fa: "\e280\e280";
}

.fa-farm {
  --fa: "\f864";
  --fa--fa: "\f864\f864";
}

.fa-barn-silo {
  --fa: "\f864";
  --fa--fa: "\f864\f864";
}

.fa-chess-knight {
  --fa: "\f441";
  --fa--fa: "\f441\f441";
}

.fa-bars-sort {
  --fa: "\e0ae";
  --fa--fa: "\e0ae\e0ae";
}

.fa-pallet-boxes {
  --fa: "\f483";
  --fa--fa: "\f483\f483";
}

.fa-palette-boxes {
  --fa: "\f483";
  --fa--fa: "\f483\f483";
}

.fa-pallet-alt {
  --fa: "\f483";
  --fa--fa: "\f483\f483";
}

.fa-face-laugh-squint {
  --fa: "\f59b";
  --fa--fa: "\f59b\f59b";
}

.fa-laugh-squint {
  --fa: "\f59b";
  --fa--fa: "\f59b\f59b";
}

.fa-code-simple {
  --fa: "\e13d";
  --fa--fa: "\e13d\e13d";
}

.fa-bolt-slash {
  --fa: "\e0b8";
  --fa--fa: "\e0b8\e0b8";
}

.fa-panel-fire {
  --fa: "\e42f";
  --fa--fa: "\e42f\e42f";
}

.fa-binary-circle-check {
  --fa: "\e33c";
  --fa--fa: "\e33c\e33c";
}

.fa-comment-minus {
  --fa: "\f4b1";
  --fa--fa: "\f4b1\f4b1";
}

.fa-burrito {
  --fa: "\f7ed";
  --fa--fa: "\f7ed\f7ed";
}

.fa-violin {
  --fa: "\f8ed";
  --fa--fa: "\f8ed\f8ed";
}

.fa-objects-column {
  --fa: "\e3c1";
  --fa--fa: "\e3c1\e3c1";
}

.fa-square-chevron-down {
  --fa: "\f329";
  --fa--fa: "\f329\f329";
}

.fa-chevron-square-down {
  --fa: "\f329";
  --fa--fa: "\f329\f329";
}

.fa-comment-plus {
  --fa: "\f4b2";
  --fa--fa: "\f4b2\f4b2";
}

.fa-triangle-instrument {
  --fa: "\f8e2";
  --fa--fa: "\f8e2\f8e2";
}

.fa-triangle-music {
  --fa: "\f8e2";
  --fa--fa: "\f8e2\f8e2";
}

.fa-wheelchair {
  --fa: "\f193";
  --fa--fa: "\f193\f193";
}

.fa-user-pilot-tie {
  --fa: "\e2c1";
  --fa--fa: "\e2c1\e2c1";
}

.fa-piano-keyboard {
  --fa: "\f8d5";
  --fa--fa: "\f8d5\f8d5";
}

.fa-bed-empty {
  --fa: "\f8f9";
  --fa--fa: "\f8f9\f8f9";
}

.fa-circle-arrow-up {
  --fa: "\f0aa";
  --fa--fa: "\f0aa\f0aa";
}

.fa-arrow-circle-up {
  --fa: "\f0aa";
  --fa--fa: "\f0aa\f0aa";
}

.fa-toggle-on {
  --fa: "\f205";
  --fa--fa: "\f205\f205";
}

.fa-rectangle-vertical {
  --fa: "\f2fb";
  --fa--fa: "\f2fb\f2fb";
}

.fa-rectangle-portrait {
  --fa: "\f2fb";
  --fa--fa: "\f2fb\f2fb";
}

.fa-person-walking {
  --fa: "\f554";
  --fa--fa: "\f554\f554";
}

.fa-walking {
  --fa: "\f554";
  --fa--fa: "\f554\f554";
}

.fa-l {
  --fa: "L";
  --fa--fa: "LL";
}

.fa-signal-stream {
  --fa: "\f8dd";
  --fa--fa: "\f8dd\f8dd";
}

.fa-down-to-bracket {
  --fa: "\e4e7";
  --fa--fa: "\e4e7\e4e7";
}

.fa-circle-z {
  --fa: "\e130";
  --fa--fa: "\e130\e130";
}

.fa-stars {
  --fa: "\f762";
  --fa--fa: "\f762\f762";
}

.fa-fire {
  --fa: "\f06d";
  --fa--fa: "\f06d\f06d";
}

.fa-bed-pulse {
  --fa: "\f487";
  --fa--fa: "\f487\f487";
}

.fa-procedures {
  --fa: "\f487";
  --fa--fa: "\f487\f487";
}

.fa-house-day {
  --fa: "\e00e";
  --fa--fa: "\e00e\e00e";
}

.fa-shuttle-space {
  --fa: "\f197";
  --fa--fa: "\f197\f197";
}

.fa-space-shuttle {
  --fa: "\f197";
  --fa--fa: "\f197\f197";
}

.fa-shirt-long-sleeve {
  --fa: "\e3c7";
  --fa--fa: "\e3c7\e3c7";
}

.fa-chart-pie-simple {
  --fa: "\f64e";
  --fa--fa: "\f64e\f64e";
}

.fa-chart-pie-alt {
  --fa: "\f64e";
  --fa--fa: "\f64e\f64e";
}

.fa-face-laugh {
  --fa: "\f599";
  --fa--fa: "\f599\f599";
}

.fa-laugh {
  --fa: "\f599";
  --fa--fa: "\f599\f599";
}

.fa-folder-open {
  --fa: "\f07c";
  --fa--fa: "\f07c\f07c";
}

.fa-album-collection-circle-user {
  --fa: "\e48f";
  --fa--fa: "\e48f\e48f";
}

.fa-candy {
  --fa: "\e3e7";
  --fa--fa: "\e3e7\e3e7";
}

.fa-bowl-hot {
  --fa: "\f823";
  --fa--fa: "\f823\f823";
}

.fa-soup {
  --fa: "\f823";
  --fa--fa: "\f823\f823";
}

.fa-flatbread {
  --fa: "\e40b";
  --fa--fa: "\e40b\e40b";
}

.fa-heart-circle-plus {
  --fa: "\e500";
  --fa--fa: "\e500\e500";
}

.fa-code-fork {
  --fa: "\e13b";
  --fa--fa: "\e13b\e13b";
}

.fa-city {
  --fa: "\f64f";
  --fa--fa: "\f64f\f64f";
}

.fa-signal-bars-weak {
  --fa: "\f691";
  --fa--fa: "\f691\f691";
}

.fa-signal-alt-1 {
  --fa: "\f691";
  --fa--fa: "\f691\f691";
}

.fa-microphone-lines {
  --fa: "\f3c9";
  --fa--fa: "\f3c9\f3c9";
}

.fa-microphone-alt {
  --fa: "\f3c9";
  --fa--fa: "\f3c9\f3c9";
}

.fa-clock-twelve {
  --fa: "\e358";
  --fa--fa: "\e358\e358";
}

.fa-pepper-hot {
  --fa: "\f816";
  --fa--fa: "\f816\f816";
}

.fa-citrus-slice {
  --fa: "\e2f5";
  --fa--fa: "\e2f5\e2f5";
}

.fa-sheep {
  --fa: "\f711";
  --fa--fa: "\f711\f711";
}

.fa-unlock {
  --fa: "\f09c";
  --fa--fa: "\f09c\f09c";
}

.fa-colon-sign {
  --fa: "\e140";
  --fa--fa: "\e140\e140";
}

.fa-headset {
  --fa: "\f590";
  --fa--fa: "\f590\f590";
}

.fa-badger-honey {
  --fa: "\f6b4";
  --fa--fa: "\f6b4\f6b4";
}

.fa-h4 {
  --fa: "\f86a";
  --fa--fa: "\f86a\f86a";
}

.fa-store-slash {
  --fa: "\e071";
  --fa--fa: "\e071\e071";
}

.fa-road-circle-xmark {
  --fa: "\e566";
  --fa--fa: "\e566\e566";
}

.fa-signal-slash {
  --fa: "\f695";
  --fa--fa: "\f695\f695";
}

.fa-user-minus {
  --fa: "\f503";
  --fa--fa: "\f503\f503";
}

.fa-mars-stroke-up {
  --fa: "\f22a";
  --fa--fa: "\f22a\f22a";
}

.fa-mars-stroke-v {
  --fa: "\f22a";
  --fa--fa: "\f22a\f22a";
}

.fa-champagne-glasses {
  --fa: "\f79f";
  --fa--fa: "\f79f\f79f";
}

.fa-glass-cheers {
  --fa: "\f79f";
  --fa--fa: "\f79f\f79f";
}

.fa-taco {
  --fa: "\f826";
  --fa--fa: "\f826\f826";
}

.fa-hexagon-plus {
  --fa: "\f300";
  --fa--fa: "\f300\f300";
}

.fa-plus-hexagon {
  --fa: "\f300";
  --fa--fa: "\f300\f300";
}

.fa-clipboard {
  --fa: "\f328";
  --fa--fa: "\f328\f328";
}

.fa-house-circle-exclamation {
  --fa: "\e50a";
  --fa--fa: "\e50a\e50a";
}

.fa-file-arrow-up {
  --fa: "\f574";
  --fa--fa: "\f574\f574";
}

.fa-file-upload {
  --fa: "\f574";
  --fa--fa: "\f574\f574";
}

.fa-wifi {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb";
}

.fa-wifi-3 {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb";
}

.fa-wifi-strong {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb";
}

.fa-messages {
  --fa: "\f4b6";
  --fa--fa: "\f4b6\f4b6";
}

.fa-comments-alt {
  --fa: "\f4b6";
  --fa--fa: "\f4b6\f4b6";
}

.fa-bath {
  --fa: "\f2cd";
  --fa--fa: "\f2cd\f2cd";
}

.fa-bathtub {
  --fa: "\f2cd";
  --fa--fa: "\f2cd\f2cd";
}

.fa-umbrella-simple {
  --fa: "\e2bc";
  --fa--fa: "\e2bc\e2bc";
}

.fa-umbrella-alt {
  --fa: "\e2bc";
  --fa--fa: "\e2bc\e2bc";
}

.fa-rectangle-history-circle-plus {
  --fa: "\e4a3";
  --fa--fa: "\e4a3\e4a3";
}

.fa-underline {
  --fa: "\f0cd";
  --fa--fa: "\f0cd\f0cd";
}

.fa-prescription-bottle-pill {
  --fa: "\e5c0";
  --fa--fa: "\e5c0\e5c0";
}

.fa-user-pen {
  --fa: "\f4ff";
  --fa--fa: "\f4ff\f4ff";
}

.fa-user-edit {
  --fa: "\f4ff";
  --fa--fa: "\f4ff\f4ff";
}

.fa-binary-slash {
  --fa: "\e33e";
  --fa--fa: "\e33e\e33e";
}

.fa-square-o {
  --fa: "\e278";
  --fa--fa: "\e278\e278";
}

.fa-caduceus {
  --fa: "\e681";
  --fa--fa: "\e681\e681";
}

.fa-signature {
  --fa: "\f5b7";
  --fa--fa: "\f5b7\f5b7";
}

.fa-stroopwafel {
  --fa: "\f551";
  --fa--fa: "\f551\f551";
}

.fa-bold {
  --fa: "\f032";
  --fa--fa: "\f032\f032";
}

.fa-anchor-lock {
  --fa: "\e4ad";
  --fa--fa: "\e4ad\e4ad";
}

.fa-building-ngo {
  --fa: "\e4d7";
  --fa--fa: "\e4d7\e4d7";
}

.fa-transporter-3 {
  --fa: "\e045";
  --fa--fa: "\e045\e045";
}

.fa-engine-warning {
  --fa: "\f5f2";
  --fa--fa: "\f5f2\f5f2";
}

.fa-engine-exclamation {
  --fa: "\f5f2";
  --fa--fa: "\f5f2\f5f2";
}

.fa-circle-down-right {
  --fa: "\e108";
  --fa--fa: "\e108\e108";
}

.fa-square-k {
  --fa: "\e274";
  --fa--fa: "\e274\e274";
}

.fa-manat-sign {
  --fa: "\e1d5";
  --fa--fa: "\e1d5\e1d5";
}

.fa-money-check-pen {
  --fa: "\f872";
  --fa--fa: "\f872\f872";
}

.fa-money-check-edit {
  --fa: "\f872";
  --fa--fa: "\f872\f872";
}

.fa-not-equal {
  --fa: "\f53e";
  --fa--fa: "\f53e\f53e";
}

.fa-border-top-left {
  --fa: "\f853";
  --fa--fa: "\f853\f853";
}

.fa-border-style {
  --fa: "\f853";
  --fa--fa: "\f853\f853";
}

.fa-map-location-dot {
  --fa: "\f5a0";
  --fa--fa: "\f5a0\f5a0";
}

.fa-map-marked-alt {
  --fa: "\f5a0";
  --fa--fa: "\f5a0\f5a0";
}

.fa-tilde {
  --fa: "\~";
  --fa--fa: "\~\~";
}

.fa-jedi {
  --fa: "\f669";
  --fa--fa: "\f669\f669";
}

.fa-square-poll-vertical {
  --fa: "\f681";
  --fa--fa: "\f681\f681";
}

.fa-poll {
  --fa: "\f681";
  --fa--fa: "\f681\f681";
}

.fa-arrow-down-square-triangle {
  --fa: "\f889";
  --fa--fa: "\f889\f889";
}

.fa-sort-shapes-down-alt {
  --fa: "\f889";
  --fa--fa: "\f889\f889";
}

.fa-mug-hot {
  --fa: "\f7b6";
  --fa--fa: "\f7b6\f7b6";
}

.fa-dog-leashed {
  --fa: "\f6d4";
  --fa--fa: "\f6d4\f6d4";
}

.fa-car-battery {
  --fa: "\f5df";
  --fa--fa: "\f5df\f5df";
}

.fa-battery-car {
  --fa: "\f5df";
  --fa--fa: "\f5df\f5df";
}

.fa-face-downcast-sweat {
  --fa: "\e371";
  --fa--fa: "\e371\e371";
}

.fa-mailbox-flag-up {
  --fa: "\e5bb";
  --fa--fa: "\e5bb\e5bb";
}

.fa-memo-circle-info {
  --fa: "\e49a";
  --fa--fa: "\e49a\e49a";
}

.fa-gift {
  --fa: "\f06b";
  --fa--fa: "\f06b\f06b";
}

.fa-dice-two {
  --fa: "\f528";
  --fa--fa: "\f528\f528";
}

.fa-volume {
  --fa: "\f6a8";
  --fa--fa: "\f6a8\f6a8";
}

.fa-volume-medium {
  --fa: "\f6a8";
  --fa--fa: "\f6a8\f6a8";
}

.fa-transporter-5 {
  --fa: "\e2a6";
  --fa--fa: "\e2a6\e2a6";
}

.fa-gauge-circle-bolt {
  --fa: "\e496";
  --fa--fa: "\e496\e496";
}

.fa-coin-front {
  --fa: "\e3fc";
  --fa--fa: "\e3fc\e3fc";
}

.fa-file-slash {
  --fa: "\e3a7";
  --fa--fa: "\e3a7\e3a7";
}

.fa-message-arrow-up-right {
  --fa: "\e1dd";
  --fa--fa: "\e1dd\e1dd";
}

.fa-treasure-chest {
  --fa: "\f723";
  --fa--fa: "\f723\f723";
}

.fa-chess-queen {
  --fa: "\f445";
  --fa--fa: "\f445\f445";
}

.fa-paintbrush-fine {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-paint-brush-alt {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-paint-brush-fine {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-paintbrush-alt {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-glasses {
  --fa: "\f530";
  --fa--fa: "\f530\f530";
}

.fa-hood-cloak {
  --fa: "\f6ef";
  --fa--fa: "\f6ef\f6ef";
}

.fa-square-quote {
  --fa: "\e329";
  --fa--fa: "\e329\e329";
}

.fa-up-left {
  --fa: "\e2bd";
  --fa--fa: "\e2bd\e2bd";
}

.fa-bring-front {
  --fa: "\f857";
  --fa--fa: "\f857\f857";
}

.fa-chess-board {
  --fa: "\f43c";
  --fa--fa: "\f43c\f43c";
}

.fa-burger-cheese {
  --fa: "\f7f1";
  --fa--fa: "\f7f1\f7f1";
}

.fa-cheeseburger {
  --fa: "\f7f1";
  --fa--fa: "\f7f1\f7f1";
}

.fa-building-circle-check {
  --fa: "\e4d2";
  --fa--fa: "\e4d2\e4d2";
}

.fa-repeat-1 {
  --fa: "\f365";
  --fa--fa: "\f365\f365";
}

.fa-arrow-down-to-line {
  --fa: "\f33d";
  --fa--fa: "\f33d\f33d";
}

.fa-arrow-to-bottom {
  --fa: "\f33d";
  --fa--fa: "\f33d\f33d";
}

.fa-grid-5 {
  --fa: "\e199";
  --fa--fa: "\e199\e199";
}

.fa-swap-arrows {
  --fa: "\e60a";
  --fa--fa: "\e60a\e60a";
}

.fa-right-long-to-line {
  --fa: "\e444";
  --fa--fa: "\e444\e444";
}

.fa-person-chalkboard {
  --fa: "\e53d";
  --fa--fa: "\e53d\e53d";
}

.fa-mars-stroke-right {
  --fa: "\f22b";
  --fa--fa: "\f22b\f22b";
}

.fa-mars-stroke-h {
  --fa: "\f22b";
  --fa--fa: "\f22b\f22b";
}

.fa-hand-back-fist {
  --fa: "\f255";
  --fa--fa: "\f255\f255";
}

.fa-hand-rock {
  --fa: "\f255";
  --fa--fa: "\f255\f255";
}

.fa-grid-round-5 {
  --fa: "\e5de";
  --fa--fa: "\e5de\e5de";
}

.fa-tally {
  --fa: "\f69c";
  --fa--fa: "\f69c\f69c";
}

.fa-tally-5 {
  --fa: "\f69c";
  --fa--fa: "\f69c\f69c";
}

.fa-square-caret-up {
  --fa: "\f151";
  --fa--fa: "\f151\f151";
}

.fa-caret-square-up {
  --fa: "\f151";
  --fa--fa: "\f151\f151";
}

.fa-cloud-showers-water {
  --fa: "\e4e4";
  --fa--fa: "\e4e4\e4e4";
}

.fa-chart-bar {
  --fa: "\f080";
  --fa--fa: "\f080\f080";
}

.fa-bar-chart {
  --fa: "\f080";
  --fa--fa: "\f080\f080";
}

.fa-hands-bubbles {
  --fa: "\e05e";
  --fa--fa: "\e05e\e05e";
}

.fa-hands-wash {
  --fa: "\e05e";
  --fa--fa: "\e05e\e05e";
}

.fa-less-than-equal {
  --fa: "\f537";
  --fa--fa: "\f537\f537";
}

.fa-train {
  --fa: "\f238";
  --fa--fa: "\f238\f238";
}

.fa-up-from-dotted-line {
  --fa: "\e456";
  --fa--fa: "\e456\e456";
}

.fa-eye-low-vision {
  --fa: "\f2a8";
  --fa--fa: "\f2a8\f2a8";
}

.fa-low-vision {
  --fa: "\f2a8";
  --fa--fa: "\f2a8\f2a8";
}

.fa-traffic-light-go {
  --fa: "\f638";
  --fa--fa: "\f638\f638";
}

.fa-face-exhaling {
  --fa: "\e480";
  --fa--fa: "\e480\e480";
}

.fa-sensor-fire {
  --fa: "\e02a";
  --fa--fa: "\e02a\e02a";
}

.fa-user-unlock {
  --fa: "\e058";
  --fa--fa: "\e058\e058";
}

.fa-hexagon-divide {
  --fa: "\e1ad";
  --fa--fa: "\e1ad\e1ad";
}

.fa-00 {
  --fa: "\e467";
  --fa--fa: "\e467\e467";
}

.fa-crow {
  --fa: "\f520";
  --fa--fa: "\f520\f520";
}

.fa-cassette-betamax {
  --fa: "\f8a4";
  --fa--fa: "\f8a4\f8a4";
}

.fa-betamax {
  --fa: "\f8a4";
  --fa--fa: "\f8a4\f8a4";
}

.fa-sailboat {
  --fa: "\e445";
  --fa--fa: "\e445\e445";
}

.fa-window-restore {
  --fa: "\f2d2";
  --fa--fa: "\f2d2\f2d2";
}

.fa-nfc-magnifying-glass {
  --fa: "\e1f9";
  --fa--fa: "\e1f9\e1f9";
}

.fa-file-binary {
  --fa: "\e175";
  --fa--fa: "\e175\e175";
}

.fa-circle-v {
  --fa: "\e12a";
  --fa--fa: "\e12a\e12a";
}

.fa-square-plus {
  --fa: "\f0fe";
  --fa--fa: "\f0fe\f0fe";
}

.fa-plus-square {
  --fa: "\f0fe";
  --fa--fa: "\f0fe\f0fe";
}

.fa-bowl-scoops {
  --fa: "\e3df";
  --fa--fa: "\e3df\e3df";
}

.fa-mistletoe {
  --fa: "\f7b4";
  --fa--fa: "\f7b4\f7b4";
}

.fa-custard {
  --fa: "\e403";
  --fa--fa: "\e403\e403";
}

.fa-lacrosse-stick {
  --fa: "\e3b5";
  --fa--fa: "\e3b5\e3b5";
}

.fa-hockey-mask {
  --fa: "\f6ee";
  --fa--fa: "\f6ee\f6ee";
}

.fa-sunrise {
  --fa: "\f766";
  --fa--fa: "\f766\f766";
}

.fa-subtitles {
  --fa: "\e60f";
  --fa--fa: "\e60f\e60f";
}

.fa-panel-ews {
  --fa: "\e42e";
  --fa--fa: "\e42e\e42e";
}

.fa-torii-gate {
  --fa: "\f6a1";
  --fa--fa: "\f6a1\f6a1";
}

.fa-cloud-exclamation {
  --fa: "\e491";
  --fa--fa: "\e491\e491";
}

.fa-message-lines {
  --fa: "\f4a6";
  --fa--fa: "\f4a6\f4a6";
}

.fa-comment-alt-lines {
  --fa: "\f4a6";
  --fa--fa: "\f4a6\f4a6";
}

.fa-frog {
  --fa: "\f52e";
  --fa--fa: "\f52e\f52e";
}

.fa-bucket {
  --fa: "\e4cf";
  --fa--fa: "\e4cf\e4cf";
}

.fa-floppy-disk-pen {
  --fa: "\e182";
  --fa--fa: "\e182\e182";
}

.fa-image {
  --fa: "\f03e";
  --fa--fa: "\f03e\f03e";
}

.fa-window-frame {
  --fa: "\e04f";
  --fa--fa: "\e04f\e04f";
}

.fa-microphone {
  --fa: "\f130";
  --fa--fa: "\f130\f130";
}

.fa-cow {
  --fa: "\f6c8";
  --fa--fa: "\f6c8\f6c8";
}

.fa-file-zip {
  --fa: "\e5ee";
  --fa--fa: "\e5ee\e5ee";
}

.fa-square-ring {
  --fa: "\e44f";
  --fa--fa: "\e44f\e44f";
}

.fa-down-from-line {
  --fa: "\f349";
  --fa--fa: "\f349\f349";
}

.fa-arrow-alt-from-top {
  --fa: "\f349";
  --fa--fa: "\f349\f349";
}

.fa-caret-up {
  --fa: "\f0d8";
  --fa--fa: "\f0d8\f0d8";
}

.fa-shield-xmark {
  --fa: "\e24c";
  --fa--fa: "\e24c\e24c";
}

.fa-shield-times {
  --fa: "\e24c";
  --fa--fa: "\e24c\e24c";
}

.fa-screwdriver {
  --fa: "\f54a";
  --fa--fa: "\f54a\f54a";
}

.fa-circle-sort-down {
  --fa: "\e031";
  --fa--fa: "\e031\e031";
}

.fa-sort-circle-down {
  --fa: "\e031";
  --fa--fa: "\e031\e031";
}

.fa-folder-closed {
  --fa: "\e185";
  --fa--fa: "\e185\e185";
}

.fa-house-tsunami {
  --fa: "\e515";
  --fa--fa: "\e515\e515";
}

.fa-square-nfi {
  --fa: "\e576";
  --fa--fa: "\e576\e576";
}

.fa-forklift {
  --fa: "\f47a";
  --fa--fa: "\f47a\f47a";
}

.fa-arrow-up-from-ground-water {
  --fa: "\e4b5";
  --fa--fa: "\e4b5\e4b5";
}

.fa-bracket-square-right {
  --fa: "\]";
  --fa--fa: "\]\]";
}

.fa-martini-glass {
  --fa: "\f57b";
  --fa--fa: "\f57b\f57b";
}

.fa-glass-martini-alt {
  --fa: "\f57b";
  --fa--fa: "\f57b\f57b";
}

.fa-square-binary {
  --fa: "\e69b";
  --fa--fa: "\e69b\e69b";
}

.fa-rotate-left {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-rotate-back {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-rotate-backward {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-undo-alt {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-table-columns {
  --fa: "\f0db";
  --fa--fa: "\f0db\f0db";
}

.fa-columns {
  --fa: "\f0db";
  --fa--fa: "\f0db\f0db";
}

.fa-square-a {
  --fa: "\e25f";
  --fa--fa: "\e25f\e25f";
}

.fa-tick {
  --fa: "\e32f";
  --fa--fa: "\e32f\e32f";
}

.fa-lemon {
  --fa: "\f094";
  --fa--fa: "\f094\f094";
}

.fa-head-side-mask {
  --fa: "\e063";
  --fa--fa: "\e063\e063";
}

.fa-handshake {
  --fa: "\f2b5";
  --fa--fa: "\f2b5\f2b5";
}

.fa-gem {
  --fa: "\f3a5";
  --fa--fa: "\f3a5\f3a5";
}

.fa-dolly {
  --fa: "\f472";
  --fa--fa: "\f472\f472";
}

.fa-dolly-box {
  --fa: "\f472";
  --fa--fa: "\f472\f472";
}

.fa-smoking {
  --fa: "\f48d";
  --fa--fa: "\f48d\f48d";
}

.fa-minimize {
  --fa: "\f78c";
  --fa--fa: "\f78c\f78c";
}

.fa-compress-arrows-alt {
  --fa: "\f78c";
  --fa--fa: "\f78c\f78c";
}

.fa-refrigerator {
  --fa: "\e026";
  --fa--fa: "\e026\e026";
}

.fa-monument {
  --fa: "\f5a6";
  --fa--fa: "\f5a6\f5a6";
}

.fa-octagon-xmark {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0";
}

.fa-times-octagon {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0";
}

.fa-xmark-octagon {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0";
}

.fa-align-slash {
  --fa: "\f846";
  --fa--fa: "\f846\f846";
}

.fa-snowplow {
  --fa: "\f7d2";
  --fa--fa: "\f7d2\f7d2";
}

.fa-angles-right {
  --fa: "\f101";
  --fa--fa: "\f101\f101";
}

.fa-angle-double-right {
  --fa: "\f101";
  --fa--fa: "\f101\f101";
}

.fa-truck-ramp-couch {
  --fa: "\f4dd";
  --fa--fa: "\f4dd\f4dd";
}

.fa-truck-couch {
  --fa: "\f4dd";
  --fa--fa: "\f4dd\f4dd";
}

.fa-cannabis {
  --fa: "\f55f";
  --fa--fa: "\f55f\f55f";
}

.fa-circle-play {
  --fa: "\f144";
  --fa--fa: "\f144\f144";
}

.fa-play-circle {
  --fa: "\f144";
  --fa--fa: "\f144\f144";
}

.fa-arrow-up-right-and-arrow-down-left-from-center {
  --fa: "\e0a0";
  --fa--fa: "\e0a0\e0a0";
}

.fa-location-arrow-up {
  --fa: "\e63a";
  --fa--fa: "\e63a\e63a";
}

.fa-tablets {
  --fa: "\f490";
  --fa--fa: "\f490\f490";
}

.fa-360-degrees {
  --fa: "\e2dc";
  --fa--fa: "\e2dc\e2dc";
}

.fa-ethernet {
  --fa: "\f796";
  --fa--fa: "\f796\f796";
}

.fa-euro-sign {
  --fa: "\f153";
  --fa--fa: "\f153\f153";
}

.fa-eur {
  --fa: "\f153";
  --fa--fa: "\f153\f153";
}

.fa-euro {
  --fa: "\f153";
  --fa--fa: "\f153\f153";
}

.fa-chair {
  --fa: "\f6c0";
  --fa--fa: "\f6c0\f6c0";
}

.fa-circle-check {
  --fa: "\f058";
  --fa--fa: "\f058\f058";
}

.fa-check-circle {
  --fa: "\f058";
  --fa--fa: "\f058\f058";
}

.fa-square-dashed-circle-plus {
  --fa: "\e5c2";
  --fa--fa: "\e5c2\e5c2";
}

.fa-hand-holding-circle-dollar {
  --fa: "\e621";
  --fa--fa: "\e621\e621";
}

.fa-money-simple-from-bracket {
  --fa: "\e313";
  --fa--fa: "\e313\e313";
}

.fa-bat {
  --fa: "\f6b5";
  --fa--fa: "\f6b5\f6b5";
}

.fa-circle-stop {
  --fa: "\f28d";
  --fa--fa: "\f28d\f28d";
}

.fa-stop-circle {
  --fa: "\f28d";
  --fa--fa: "\f28d\f28d";
}

.fa-head-side-headphones {
  --fa: "\f8c2";
  --fa--fa: "\f8c2\f8c2";
}

.fa-phone-rotary {
  --fa: "\f8d3";
  --fa--fa: "\f8d3\f8d3";
}

.fa-arrow-up-to-bracket {
  --fa: "\e66a";
  --fa--fa: "\e66a\e66a";
}

.fa-compass-drafting {
  --fa: "\f568";
  --fa--fa: "\f568\f568";
}

.fa-drafting-compass {
  --fa: "\f568";
  --fa--fa: "\f568\f568";
}

.fa-plate-wheat {
  --fa: "\e55a";
  --fa--fa: "\e55a\e55a";
}

.fa-calendar-circle-minus {
  --fa: "\e46f";
  --fa--fa: "\e46f\e46f";
}

.fa-chopsticks {
  --fa: "\e3f7";
  --fa--fa: "\e3f7\e3f7";
}

.fa-car-wrench {
  --fa: "\f5e3";
  --fa--fa: "\f5e3\f5e3";
}

.fa-car-mechanic {
  --fa: "\f5e3";
  --fa--fa: "\f5e3\f5e3";
}

.fa-icicles {
  --fa: "\f7ad";
  --fa--fa: "\f7ad\f7ad";
}

.fa-person-shelter {
  --fa: "\e54f";
  --fa--fa: "\e54f\e54f";
}

.fa-neuter {
  --fa: "\f22c";
  --fa--fa: "\f22c\f22c";
}

.fa-id-badge {
  --fa: "\f2c1";
  --fa--fa: "\f2c1\f2c1";
}

.fa-kazoo {
  --fa: "\f8c7";
  --fa--fa: "\f8c7\f8c7";
}

.fa-marker {
  --fa: "\f5a1";
  --fa--fa: "\f5a1\f5a1";
}

.fa-bin-bottles {
  --fa: "\e5f5";
  --fa--fa: "\e5f5\e5f5";
}

.fa-face-laugh-beam {
  --fa: "\f59a";
  --fa--fa: "\f59a\f59a";
}

.fa-laugh-beam {
  --fa: "\f59a";
  --fa--fa: "\f59a\f59a";
}

.fa-square-arrow-down-left {
  --fa: "\e261";
  --fa--fa: "\e261\e261";
}

.fa-battery-bolt {
  --fa: "\f376";
  --fa--fa: "\f376\f376";
}

.fa-tree-large {
  --fa: "\f7dd";
  --fa--fa: "\f7dd\f7dd";
}

.fa-helicopter-symbol {
  --fa: "\e502";
  --fa--fa: "\e502\e502";
}

.fa-aperture {
  --fa: "\e2df";
  --fa--fa: "\e2df\e2df";
}

.fa-universal-access {
  --fa: "\f29a";
  --fa--fa: "\f29a\f29a";
}

.fa-gear-complex {
  --fa: "\e5e9";
  --fa--fa: "\e5e9\e5e9";
}

.fa-file-magnifying-glass {
  --fa: "\f865";
  --fa--fa: "\f865\f865";
}

.fa-file-search {
  --fa: "\f865";
  --fa--fa: "\f865\f865";
}

.fa-up-right {
  --fa: "\e2be";
  --fa--fa: "\e2be\e2be";
}

.fa-circle-chevron-up {
  --fa: "\f139";
  --fa--fa: "\f139\f139";
}

.fa-chevron-circle-up {
  --fa: "\f139";
  --fa--fa: "\f139\f139";
}

.fa-user-police {
  --fa: "\e333";
  --fa--fa: "\e333\e333";
}

.fa-lari-sign {
  --fa: "\e1c8";
  --fa--fa: "\e1c8\e1c8";
}

.fa-volcano {
  --fa: "\f770";
  --fa--fa: "\f770\f770";
}

.fa-teddy-bear {
  --fa: "\e3cf";
  --fa--fa: "\e3cf\e3cf";
}

.fa-stocking {
  --fa: "\f7d5";
  --fa--fa: "\f7d5\f7d5";
}

.fa-person-walking-dashed-line-arrow-right {
  --fa: "\e553";
  --fa--fa: "\e553\e553";
}

.fa-image-slash {
  --fa: "\e1b7";
  --fa--fa: "\e1b7\e1b7";
}

.fa-mask-snorkel {
  --fa: "\e3b7";
  --fa--fa: "\e3b7\e3b7";
}

.fa-smoke {
  --fa: "\f760";
  --fa--fa: "\f760\f760";
}

.fa-sterling-sign {
  --fa: "\f154";
  --fa--fa: "\f154\f154";
}

.fa-gbp {
  --fa: "\f154";
  --fa--fa: "\f154\f154";
}

.fa-pound-sign {
  --fa: "\f154";
  --fa--fa: "\f154\f154";
}

.fa-battery-exclamation {
  --fa: "\e0b0";
  --fa--fa: "\e0b0\e0b0";
}

.fa-viruses {
  --fa: "\e076";
  --fa--fa: "\e076\e076";
}

.fa-square-person-confined {
  --fa: "\e577";
  --fa--fa: "\e577\e577";
}

.fa-user-tie {
  --fa: "\f508";
  --fa--fa: "\f508\f508";
}

.fa-up-to-bracket {
  --fa: "\e66e";
  --fa--fa: "\e66e\e66e";
}

.fa-arrow-down-long {
  --fa: "\f175";
  --fa--fa: "\f175\f175";
}

.fa-long-arrow-down {
  --fa: "\f175";
  --fa--fa: "\f175\f175";
}

.fa-tent-arrow-down-to-line {
  --fa: "\e57e";
  --fa--fa: "\e57e\e57e";
}

.fa-certificate {
  --fa: "\f0a3";
  --fa--fa: "\f0a3\f0a3";
}

.fa-crystal-ball {
  --fa: "\e362";
  --fa--fa: "\e362\e362";
}

.fa-reply-all {
  --fa: "\f122";
  --fa--fa: "\f122\f122";
}

.fa-mail-reply-all {
  --fa: "\f122";
  --fa--fa: "\f122\f122";
}

.fa-suitcase {
  --fa: "\f0f2";
  --fa--fa: "\f0f2\f0f2";
}

.fa-person-skating {
  --fa: "\f7c5";
  --fa--fa: "\f7c5\f7c5";
}

.fa-skating {
  --fa: "\f7c5";
  --fa--fa: "\f7c5\f7c5";
}

.fa-star-shooting {
  --fa: "\e036";
  --fa--fa: "\e036\e036";
}

.fa-binary-lock {
  --fa: "\e33d";
  --fa--fa: "\e33d\e33d";
}

.fa-filter-circle-dollar {
  --fa: "\f662";
  --fa--fa: "\f662\f662";
}

.fa-funnel-dollar {
  --fa: "\f662";
  --fa--fa: "\f662\f662";
}

.fa-camera-retro {
  --fa: "\f083";
  --fa--fa: "\f083\f083";
}

.fa-circle-arrow-down {
  --fa: "\f0ab";
  --fa--fa: "\f0ab\f0ab";
}

.fa-arrow-circle-down {
  --fa: "\f0ab";
  --fa--fa: "\f0ab\f0ab";
}

.fa-comment-pen {
  --fa: "\f4ae";
  --fa--fa: "\f4ae\f4ae";
}

.fa-comment-edit {
  --fa: "\f4ae";
  --fa--fa: "\f4ae\f4ae";
}

.fa-file-import {
  --fa: "\f56f";
  --fa--fa: "\f56f\f56f";
}

.fa-arrow-right-to-file {
  --fa: "\f56f";
  --fa--fa: "\f56f\f56f";
}

.fa-banjo {
  --fa: "\f8a3";
  --fa--fa: "\f8a3\f8a3";
}

.fa-square-arrow-up-right {
  --fa: "\f14c";
  --fa--fa: "\f14c\f14c";
}

.fa-external-link-square {
  --fa: "\f14c";
  --fa--fa: "\f14c\f14c";
}

.fa-light-emergency-on {
  --fa: "\e420";
  --fa--fa: "\e420\e420";
}

.fa-kerning {
  --fa: "\f86f";
  --fa--fa: "\f86f\f86f";
}

.fa-box-open {
  --fa: "\f49e";
  --fa--fa: "\f49e\f49e";
}

.fa-square-f {
  --fa: "\e270";
  --fa--fa: "\e270\e270";
}

.fa-scroll {
  --fa: "\f70e";
  --fa--fa: "\f70e\f70e";
}

.fa-spa {
  --fa: "\f5bb";
  --fa--fa: "\f5bb\f5bb";
}

.fa-arrow-left-from-line {
  --fa: "\f344";
  --fa--fa: "\f344\f344";
}

.fa-arrow-from-right {
  --fa: "\f344";
  --fa--fa: "\f344\f344";
}

.fa-strawberry {
  --fa: "\e32b";
  --fa--fa: "\e32b\e32b";
}

.fa-location-pin-lock {
  --fa: "\e51f";
  --fa--fa: "\e51f\e51f";
}

.fa-pause {
  --fa: "\f04c";
  --fa--fa: "\f04c\f04c";
}

.fa-clock-eight-thirty {
  --fa: "\e346";
  --fa--fa: "\e346\e346";
}

.fa-plane-engines {
  --fa: "\f3de";
  --fa--fa: "\f3de\f3de";
}

.fa-plane-alt {
  --fa: "\f3de";
  --fa--fa: "\f3de\f3de";
}

.fa-hill-avalanche {
  --fa: "\e507";
  --fa--fa: "\e507\e507";
}

.fa-temperature-empty {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-temperature-0 {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-thermometer-0 {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-thermometer-empty {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-bomb {
  --fa: "\f1e2";
  --fa--fa: "\f1e2\f1e2";
}

.fa-gauge-low {
  --fa: "\f627";
  --fa--fa: "\f627\f627";
}

.fa-tachometer-alt-slow {
  --fa: "\f627";
  --fa--fa: "\f627\f627";
}

.fa-registered {
  --fa: "\f25d";
  --fa--fa: "\f25d\f25d";
}

.fa-trash-can-plus {
  --fa: "\e2ac";
  --fa--fa: "\e2ac\e2ac";
}

.fa-address-card {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb";
}

.fa-contact-card {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb";
}

.fa-vcard {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb";
}

.fa-chart-fft {
  --fa: "\e69e";
  --fa--fa: "\e69e\e69e";
}

.fa-scale-unbalanced-flip {
  --fa: "\f516";
  --fa--fa: "\f516\f516";
}

.fa-balance-scale-right {
  --fa: "\f516";
  --fa--fa: "\f516\f516";
}

.fa-globe-snow {
  --fa: "\f7a3";
  --fa--fa: "\f7a3\f7a3";
}

.fa-subscript {
  --fa: "\f12c";
  --fa--fa: "\f12c\f12c";
}

.fa-diamond-turn-right {
  --fa: "\f5eb";
  --fa--fa: "\f5eb\f5eb";
}

.fa-directions {
  --fa: "\f5eb";
  --fa--fa: "\f5eb\f5eb";
}

.fa-integral {
  --fa: "\f667";
  --fa--fa: "\f667\f667";
}

.fa-burst {
  --fa: "\e4dc";
  --fa--fa: "\e4dc\e4dc";
}

.fa-house-laptop {
  --fa: "\e066";
  --fa--fa: "\e066\e066";
}

.fa-laptop-house {
  --fa: "\e066";
  --fa--fa: "\e066\e066";
}

.fa-face-tired {
  --fa: "\f5c8";
  --fa--fa: "\f5c8\f5c8";
}

.fa-tired {
  --fa: "\f5c8";
  --fa--fa: "\f5c8\f5c8";
}

.fa-money-bills {
  --fa: "\e1f3";
  --fa--fa: "\e1f3\e1f3";
}

.fa-blinds-raised {
  --fa: "\f8fd";
  --fa--fa: "\f8fd\f8fd";
}

.fa-smog {
  --fa: "\f75f";
  --fa--fa: "\f75f\f75f";
}

.fa-ufo-beam {
  --fa: "\e048";
  --fa--fa: "\e048\e048";
}

.fa-hydra {
  --fa: "\e686";
  --fa--fa: "\e686\e686";
}

.fa-circle-caret-up {
  --fa: "\f331";
  --fa--fa: "\f331\f331";
}

.fa-caret-circle-up {
  --fa: "\f331";
  --fa--fa: "\f331\f331";
}

.fa-user-vneck-hair-long {
  --fa: "\e463";
  --fa--fa: "\e463\e463";
}

.fa-square-a-lock {
  --fa: "\e44d";
  --fa--fa: "\e44d\e44d";
}

.fa-crutch {
  --fa: "\f7f7";
  --fa--fa: "\f7f7\f7f7";
}

.fa-gas-pump-slash {
  --fa: "\f5f4";
  --fa--fa: "\f5f4\f5f4";
}

.fa-cloud-arrow-up {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee";
}

.fa-cloud-upload {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee";
}

.fa-cloud-upload-alt {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee";
}

.fa-palette {
  --fa: "\f53f";
  --fa--fa: "\f53f\f53f";
}

.fa-transporter-4 {
  --fa: "\e2a5";
  --fa--fa: "\e2a5\e2a5";
}

.fa-chart-mixed-up-circle-currency {
  --fa: "\e5d8";
  --fa--fa: "\e5d8\e5d8";
}

.fa-objects-align-right {
  --fa: "\e3bf";
  --fa--fa: "\e3bf\e3bf";
}

.fa-arrows-turn-right {
  --fa: "\e4c0";
  --fa--fa: "\e4c0\e4c0";
}

.fa-vest {
  --fa: "\e085";
  --fa--fa: "\e085\e085";
}

.fa-pig {
  --fa: "\f706";
  --fa--fa: "\f706\f706";
}

.fa-inbox-full {
  --fa: "\e1ba";
  --fa--fa: "\e1ba\e1ba";
}

.fa-circle-envelope {
  --fa: "\e10c";
  --fa--fa: "\e10c\e10c";
}

.fa-envelope-circle {
  --fa: "\e10c";
  --fa--fa: "\e10c\e10c";
}

.fa-triangle-person-digging {
  --fa: "\f85d";
  --fa--fa: "\f85d\f85d";
}

.fa-construction {
  --fa: "\f85d";
  --fa--fa: "\f85d\f85d";
}

.fa-ferry {
  --fa: "\e4ea";
  --fa--fa: "\e4ea\e4ea";
}

.fa-bullseye-arrow {
  --fa: "\f648";
  --fa--fa: "\f648\f648";
}

.fa-arrows-down-to-people {
  --fa: "\e4b9";
  --fa--fa: "\e4b9\e4b9";
}

.fa-seedling {
  --fa: "\f4d8";
  --fa--fa: "\f4d8\f4d8";
}

.fa-sprout {
  --fa: "\f4d8";
  --fa--fa: "\f4d8\f4d8";
}

.fa-clock-seven {
  --fa: "\e350";
  --fa--fa: "\e350\e350";
}

.fa-left-right {
  --fa: "\f337";
  --fa--fa: "\f337\f337";
}

.fa-arrows-alt-h {
  --fa: "\f337";
  --fa--fa: "\f337\f337";
}

.fa-boxes-packing {
  --fa: "\e4c7";
  --fa--fa: "\e4c7\e4c7";
}

.fa-circle-arrow-left {
  --fa: "\f0a8";
  --fa--fa: "\f0a8\f0a8";
}

.fa-arrow-circle-left {
  --fa: "\f0a8";
  --fa--fa: "\f0a8\f0a8";
}

.fa-flashlight {
  --fa: "\f8b8";
  --fa--fa: "\f8b8\f8b8";
}

.fa-file-jpg {
  --fa: "\e646";
  --fa--fa: "\e646\e646";
}

.fa-group-arrows-rotate {
  --fa: "\e4f6";
  --fa--fa: "\e4f6\e4f6";
}

.fa-bowl-food {
  --fa: "\e4c6";
  --fa--fa: "\e4c6\e4c6";
}

.fa-square-9 {
  --fa: "\e25e";
  --fa--fa: "\e25e\e25e";
}

.fa-candy-cane {
  --fa: "\f786";
  --fa--fa: "\f786\f786";
}

.fa-arrow-down-wide-short {
  --fa: "\f160";
  --fa--fa: "\f160\f160";
}

.fa-sort-amount-asc {
  --fa: "\f160";
  --fa--fa: "\f160\f160";
}

.fa-sort-amount-down {
  --fa: "\f160";
  --fa--fa: "\f160\f160";
}

.fa-square-dollar {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9";
}

.fa-dollar-square {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9";
}

.fa-usd-square {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9";
}

.fa-phone-arrow-right {
  --fa: "\e5be";
  --fa--fa: "\e5be\e5be";
}

.fa-hand-holding-seedling {
  --fa: "\f4bf";
  --fa--fa: "\f4bf\f4bf";
}

.fa-message-check {
  --fa: "\f4a2";
  --fa--fa: "\f4a2\f4a2";
}

.fa-comment-alt-check {
  --fa: "\f4a2";
  --fa--fa: "\f4a2\f4a2";
}

.fa-cloud-bolt {
  --fa: "\f76c";
  --fa--fa: "\f76c\f76c";
}

.fa-thunderstorm {
  --fa: "\f76c";
  --fa--fa: "\f76c\f76c";
}

.fa-chart-line-up-down {
  --fa: "\e5d7";
  --fa--fa: "\e5d7\e5d7";
}

.fa-text-slash {
  --fa: "\f87d";
  --fa--fa: "\f87d\f87d";
}

.fa-remove-format {
  --fa: "\f87d";
  --fa--fa: "\f87d\f87d";
}

.fa-watch {
  --fa: "\f2e1";
  --fa--fa: "\f2e1\f2e1";
}

.fa-circle-down-left {
  --fa: "\e107";
  --fa--fa: "\e107\e107";
}

.fa-text {
  --fa: "\f893";
  --fa--fa: "\f893\f893";
}

.fa-projector {
  --fa: "\f8d6";
  --fa--fa: "\f8d6\f8d6";
}

.fa-face-smile-wink {
  --fa: "\f4da";
  --fa--fa: "\f4da\f4da";
}

.fa-smile-wink {
  --fa: "\f4da";
  --fa--fa: "\f4da\f4da";
}

.fa-tombstone-blank {
  --fa: "\f721";
  --fa--fa: "\f721\f721";
}

.fa-tombstone-alt {
  --fa: "\f721";
  --fa--fa: "\f721\f721";
}

.fa-chess-king-piece {
  --fa: "\f440";
  --fa--fa: "\f440\f440";
}

.fa-chess-king-alt {
  --fa: "\f440";
  --fa--fa: "\f440\f440";
}

.fa-circle-6 {
  --fa: "\e0f3";
  --fa--fa: "\e0f3\e0f3";
}

.fa-waves-sine {
  --fa: "\e65d";
  --fa--fa: "\e65d\e65d";
}

.fa-left {
  --fa: "\f355";
  --fa--fa: "\f355\f355";
}

.fa-arrow-alt-left {
  --fa: "\f355";
  --fa--fa: "\f355\f355";
}

.fa-file-word {
  --fa: "\f1c2";
  --fa--fa: "\f1c2\f1c2";
}

.fa-file-powerpoint {
  --fa: "\f1c4";
  --fa--fa: "\f1c4\f1c4";
}

.fa-square-down {
  --fa: "\f350";
  --fa--fa: "\f350\f350";
}

.fa-arrow-alt-square-down {
  --fa: "\f350";
  --fa--fa: "\f350\f350";
}

.fa-objects-align-center-vertical {
  --fa: "\e3bd";
  --fa--fa: "\e3bd\e3bd";
}

.fa-arrows-left-right {
  --fa: "\f07e";
  --fa--fa: "\f07e\f07e";
}

.fa-arrows-h {
  --fa: "\f07e";
  --fa--fa: "\f07e\f07e";
}

.fa-house-lock {
  --fa: "\e510";
  --fa--fa: "\e510\e510";
}

.fa-cloud-arrow-down {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed";
}

.fa-cloud-download {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed";
}

.fa-cloud-download-alt {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed";
}

.fa-wreath {
  --fa: "\f7e2";
  --fa--fa: "\f7e2\f7e2";
}

.fa-children {
  --fa: "\e4e1";
  --fa--fa: "\e4e1\e4e1";
}

.fa-meter-droplet {
  --fa: "\e1ea";
  --fa--fa: "\e1ea\e1ea";
}

.fa-chalkboard {
  --fa: "\f51b";
  --fa--fa: "\f51b\f51b";
}

.fa-blackboard {
  --fa: "\f51b";
  --fa--fa: "\f51b\f51b";
}

.fa-user-large-slash {
  --fa: "\f4fa";
  --fa--fa: "\f4fa\f4fa";
}

.fa-user-alt-slash {
  --fa: "\f4fa";
  --fa--fa: "\f4fa\f4fa";
}

.fa-signal-strong {
  --fa: "\f68f";
  --fa--fa: "\f68f\f68f";
}

.fa-signal-4 {
  --fa: "\f68f";
  --fa--fa: "\f68f\f68f";
}

.fa-lollipop {
  --fa: "\e424";
  --fa--fa: "\e424\e424";
}

.fa-lollypop {
  --fa: "\e424";
  --fa--fa: "\e424\e424";
}

.fa-list-tree {
  --fa: "\e1d2";
  --fa--fa: "\e1d2\e1d2";
}

.fa-envelope-open {
  --fa: "\f2b6";
  --fa--fa: "\f2b6\f2b6";
}

.fa-draw-circle {
  --fa: "\f5ed";
  --fa--fa: "\f5ed\f5ed";
}

.fa-cat-space {
  --fa: "\e001";
  --fa--fa: "\e001\e001";
}

.fa-handshake-simple-slash {
  --fa: "\e05f";
  --fa--fa: "\e05f\e05f";
}

.fa-handshake-alt-slash {
  --fa: "\e05f";
  --fa--fa: "\e05f\e05f";
}

.fa-rabbit-running {
  --fa: "\f709";
  --fa--fa: "\f709\f709";
}

.fa-rabbit-fast {
  --fa: "\f709";
  --fa--fa: "\f709\f709";
}

.fa-memo-pad {
  --fa: "\e1da";
  --fa--fa: "\e1da\e1da";
}

.fa-mattress-pillow {
  --fa: "\e525";
  --fa--fa: "\e525\e525";
}

.fa-alarm-plus {
  --fa: "\f844";
  --fa--fa: "\f844\f844";
}

.fa-alicorn {
  --fa: "\f6b0";
  --fa--fa: "\f6b0\f6b0";
}

.fa-comment-question {
  --fa: "\e14b";
  --fa--fa: "\e14b\e14b";
}

.fa-gingerbread-man {
  --fa: "\f79d";
  --fa--fa: "\f79d\f79d";
}

.fa-guarani-sign {
  --fa: "\e19a";
  --fa--fa: "\e19a\e19a";
}

.fa-burger-fries {
  --fa: "\e0cd";
  --fa--fa: "\e0cd\e0cd";
}

.fa-mug-tea {
  --fa: "\f875";
  --fa--fa: "\f875\f875";
}

.fa-border-top {
  --fa: "\f855";
  --fa--fa: "\f855\f855";
}

.fa-arrows-rotate {
  --fa: "\f021";
  --fa--fa: "\f021\f021";
}

.fa-refresh {
  --fa: "\f021";
  --fa--fa: "\f021\f021";
}

.fa-sync {
  --fa: "\f021";
  --fa--fa: "\f021\f021";
}

.fa-circle-book-open {
  --fa: "\e0ff";
  --fa--fa: "\e0ff\e0ff";
}

.fa-book-circle {
  --fa: "\e0ff";
  --fa--fa: "\e0ff\e0ff";
}

.fa-arrows-to-dotted-line {
  --fa: "\e0a6";
  --fa--fa: "\e0a6\e0a6";
}

.fa-fire-extinguisher {
  --fa: "\f134";
  --fa--fa: "\f134\f134";
}

.fa-magnifying-glass-arrows-rotate {
  --fa: "\e65e";
  --fa--fa: "\e65e\e65e";
}

.fa-garage-open {
  --fa: "\e00b";
  --fa--fa: "\e00b\e00b";
}

.fa-shelves-empty {
  --fa: "\e246";
  --fa--fa: "\e246\e246";
}

.fa-cruzeiro-sign {
  --fa: "\e152";
  --fa--fa: "\e152\e152";
}

.fa-watch-apple {
  --fa: "\e2cb";
  --fa--fa: "\e2cb\e2cb";
}

.fa-watch-calculator {
  --fa: "\f8f0";
  --fa--fa: "\f8f0\f8f0";
}

.fa-list-dropdown {
  --fa: "\e1cf";
  --fa--fa: "\e1cf\e1cf";
}

.fa-cabinet-filing {
  --fa: "\f64b";
  --fa--fa: "\f64b\f64b";
}

.fa-burger-soda {
  --fa: "\f858";
  --fa--fa: "\f858\f858";
}

.fa-square-arrow-up {
  --fa: "\f33c";
  --fa--fa: "\f33c\f33c";
}

.fa-arrow-square-up {
  --fa: "\f33c";
  --fa--fa: "\f33c\f33c";
}

.fa-greater-than-equal {
  --fa: "\f532";
  --fa--fa: "\f532\f532";
}

.fa-pallet-box {
  --fa: "\e208";
  --fa--fa: "\e208\e208";
}

.fa-face-confounded {
  --fa: "\e36c";
  --fa--fa: "\e36c\e36c";
}

.fa-shield-halved {
  --fa: "\f3ed";
  --fa--fa: "\f3ed\f3ed";
}

.fa-shield-alt {
  --fa: "\f3ed";
  --fa--fa: "\f3ed\f3ed";
}

.fa-truck-plow {
  --fa: "\f7de";
  --fa--fa: "\f7de\f7de";
}

.fa-book-atlas {
  --fa: "\f558";
  --fa--fa: "\f558\f558";
}

.fa-atlas {
  --fa: "\f558";
  --fa--fa: "\f558\f558";
}

.fa-virus {
  --fa: "\e074";
  --fa--fa: "\e074\e074";
}

.fa-grid-round-2 {
  --fa: "\e5db";
  --fa--fa: "\e5db\e5db";
}

.fa-comment-middle-top {
  --fa: "\e14a";
  --fa--fa: "\e14a\e14a";
}

.fa-wave {
  --fa: "\e65b";
  --fa--fa: "\e65b\e65b";
}

.fa-envelope-circle-check {
  --fa: "\e4e8";
  --fa--fa: "\e4e8\e4e8";
}

.fa-layer-group {
  --fa: "\f5fd";
  --fa--fa: "\f5fd\f5fd";
}

.fa-restroom-simple {
  --fa: "\e23a";
  --fa--fa: "\e23a\e23a";
}

.fa-arrows-to-dot {
  --fa: "\e4be";
  --fa--fa: "\e4be\e4be";
}

.fa-border-outer {
  --fa: "\f851";
  --fa--fa: "\f851\f851";
}

.fa-hashtag-lock {
  --fa: "\e415";
  --fa--fa: "\e415\e415";
}

.fa-clock-two-thirty {
  --fa: "\e35b";
  --fa--fa: "\e35b\e35b";
}

.fa-archway {
  --fa: "\f557";
  --fa--fa: "\f557\f557";
}

.fa-heart-circle-check {
  --fa: "\e4fd";
  --fa--fa: "\e4fd\e4fd";
}

.fa-house-chimney-crack {
  --fa: "\f6f1";
  --fa--fa: "\f6f1\f6f1";
}

.fa-house-damage {
  --fa: "\f6f1";
  --fa--fa: "\f6f1\f6f1";
}

.fa-file-zipper {
  --fa: "\f1c6";
  --fa--fa: "\f1c6\f1c6";
}

.fa-file-archive {
  --fa: "\f1c6";
  --fa--fa: "\f1c6\f1c6";
}

.fa-ticket-perforated {
  --fa: "\e63e";
  --fa--fa: "\e63e\e63e";
}

.fa-heart-half {
  --fa: "\e1ab";
  --fa--fa: "\e1ab\e1ab";
}

.fa-comment-check {
  --fa: "\f4ac";
  --fa--fa: "\f4ac\f4ac";
}

.fa-square {
  --fa: "\f0c8";
  --fa--fa: "\f0c8\f0c8";
}

.fa-memo {
  --fa: "\e1d8";
  --fa--fa: "\e1d8\e1d8";
}

.fa-martini-glass-empty {
  --fa: "\f000";
  --fa--fa: "\f000\f000";
}

.fa-glass-martini {
  --fa: "\f000";
  --fa--fa: "\f000\f000";
}

.fa-couch {
  --fa: "\f4b8";
  --fa--fa: "\f4b8\f4b8";
}

.fa-cedi-sign {
  --fa: "\e0df";
  --fa--fa: "\e0df\e0df";
}

.fa-italic {
  --fa: "\f033";
  --fa--fa: "\f033\f033";
}

.fa-glass-citrus {
  --fa: "\f869";
  --fa--fa: "\f869\f869";
}

.fa-calendar-lines-pen {
  --fa: "\e472";
  --fa--fa: "\e472\e472";
}

.fa-table-cells-column-lock {
  --fa: "\e678";
  --fa--fa: "\e678\e678";
}

.fa-church {
  --fa: "\f51d";
  --fa--fa: "\f51d\f51d";
}

.fa-person-snowmobiling {
  --fa: "\f7d1";
  --fa--fa: "\f7d1\f7d1";
}

.fa-snowmobile {
  --fa: "\f7d1";
  --fa--fa: "\f7d1\f7d1";
}

.fa-face-hushed {
  --fa: "\e37b";
  --fa--fa: "\e37b\e37b";
}

.fa-comments-dollar {
  --fa: "\f653";
  --fa--fa: "\f653\f653";
}

.fa-tickets-simple {
  --fa: "\e659";
  --fa--fa: "\e659\e659";
}

.fa-pickaxe {
  --fa: "\e5bf";
  --fa--fa: "\e5bf\e5bf";
}

.fa-link-simple-slash {
  --fa: "\e1ce";
  --fa--fa: "\e1ce\e1ce";
}

.fa-democrat {
  --fa: "\f747";
  --fa--fa: "\f747\f747";
}

.fa-face-confused {
  --fa: "\e36d";
  --fa--fa: "\e36d\e36d";
}

.fa-pinball {
  --fa: "\e229";
  --fa--fa: "\e229\e229";
}

.fa-z {
  --fa: "Z";
  --fa--fa: "ZZ";
}

.fa-person-skiing {
  --fa: "\f7c9";
  --fa--fa: "\f7c9\f7c9";
}

.fa-skiing {
  --fa: "\f7c9";
  --fa--fa: "\f7c9\f7c9";
}

.fa-deer {
  --fa: "\f78e";
  --fa--fa: "\f78e\f78e";
}

.fa-input-pipe {
  --fa: "\e1be";
  --fa--fa: "\e1be\e1be";
}

.fa-road-lock {
  --fa: "\e567";
  --fa--fa: "\e567\e567";
}

.fa-a {
  --fa: "A";
  --fa--fa: "AA";
}

.fa-bookmark-slash {
  --fa: "\e0c2";
  --fa--fa: "\e0c2\e0c2";
}

.fa-temperature-arrow-down {
  --fa: "\e03f";
  --fa--fa: "\e03f\e03f";
}

.fa-temperature-down {
  --fa: "\e03f";
  --fa--fa: "\e03f\e03f";
}

.fa-mace {
  --fa: "\f6f8";
  --fa--fa: "\f6f8\f6f8";
}

.fa-feather-pointed {
  --fa: "\f56b";
  --fa--fa: "\f56b\f56b";
}

.fa-feather-alt {
  --fa: "\f56b";
  --fa--fa: "\f56b\f56b";
}

.fa-sausage {
  --fa: "\f820";
  --fa--fa: "\f820\f820";
}

.fa-trash-can-clock {
  --fa: "\e2aa";
  --fa--fa: "\e2aa\e2aa";
}

.fa-p {
  --fa: "P";
  --fa--fa: "PP";
}

.fa-broom-wide {
  --fa: "\e5d1";
  --fa--fa: "\e5d1\e5d1";
}

.fa-snowflake {
  --fa: "\f2dc";
  --fa--fa: "\f2dc\f2dc";
}

.fa-stomach {
  --fa: "\f623";
  --fa--fa: "\f623\f623";
}

.fa-newspaper {
  --fa: "\f1ea";
  --fa--fa: "\f1ea\f1ea";
}

.fa-rectangle-ad {
  --fa: "\f641";
  --fa--fa: "\f641\f641";
}

.fa-ad {
  --fa: "\f641";
  --fa--fa: "\f641\f641";
}

.fa-guitar-electric {
  --fa: "\f8be";
  --fa--fa: "\f8be\f8be";
}

.fa-arrow-turn-down-right {
  --fa: "\e3d6";
  --fa--fa: "\e3d6\e3d6";
}

.fa-moon-cloud {
  --fa: "\f754";
  --fa--fa: "\f754\f754";
}

.fa-bread-slice-butter {
  --fa: "\e3e1";
  --fa--fa: "\e3e1\e3e1";
}

.fa-circle-arrow-right {
  --fa: "\f0a9";
  --fa--fa: "\f0a9\f0a9";
}

.fa-arrow-circle-right {
  --fa: "\f0a9";
  --fa--fa: "\f0a9\f0a9";
}

.fa-user-group-crown {
  --fa: "\f6a5";
  --fa--fa: "\f6a5\f6a5";
}

.fa-users-crown {
  --fa: "\f6a5";
  --fa--fa: "\f6a5\f6a5";
}

.fa-circle-i {
  --fa: "\e111";
  --fa--fa: "\e111\e111";
}

.fa-toilet-paper-check {
  --fa: "\e5b2";
  --fa--fa: "\e5b2\e5b2";
}

.fa-filter-circle-xmark {
  --fa: "\e17b";
  --fa--fa: "\e17b\e17b";
}

.fa-locust {
  --fa: "\e520";
  --fa--fa: "\e520\e520";
}

.fa-sort {
  --fa: "\f0dc";
  --fa--fa: "\f0dc\f0dc";
}

.fa-unsorted {
  --fa: "\f0dc";
  --fa--fa: "\f0dc\f0dc";
}

.fa-list-ol {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb";
}

.fa-list-1-2 {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb";
}

.fa-list-numeric {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb";
}

.fa-chart-waterfall {
  --fa: "\e0eb";
  --fa--fa: "\e0eb\e0eb";
}

.fa-sparkle {
  --fa: "\e5d6";
  --fa--fa: "\e5d6\e5d6";
}

.fa-face-party {
  --fa: "\e383";
  --fa--fa: "\e383\e383";
}

.fa-kidneys {
  --fa: "\f5fb";
  --fa--fa: "\f5fb\f5fb";
}

.fa-wifi-exclamation {
  --fa: "\e2cf";
  --fa--fa: "\e2cf\e2cf";
}

.fa-chart-network {
  --fa: "\f78a";
  --fa--fa: "\f78a\f78a";
}

.fa-person-dress-burst {
  --fa: "\e544";
  --fa--fa: "\e544\e544";
}

.fa-dice-d4 {
  --fa: "\f6d0";
  --fa--fa: "\f6d0\f6d0";
}

.fa-money-check-dollar {
  --fa: "\f53d";
  --fa--fa: "\f53d\f53d";
}

.fa-money-check-alt {
  --fa: "\f53d";
  --fa--fa: "\f53d\f53d";
}

.fa-vector-square {
  --fa: "\f5cb";
  --fa--fa: "\f5cb\f5cb";
}

.fa-bread-slice {
  --fa: "\f7ec";
  --fa--fa: "\f7ec\f7ec";
}

.fa-language {
  --fa: "\f1ab";
  --fa--fa: "\f1ab\f1ab";
}

.fa-wheat-awn-slash {
  --fa: "\e338";
  --fa--fa: "\e338\e338";
}

.fa-face-kiss-wink-heart {
  --fa: "\f598";
  --fa--fa: "\f598\f598";
}

.fa-kiss-wink-heart {
  --fa: "\f598";
  --fa--fa: "\f598\f598";
}

.fa-dagger {
  --fa: "\f6cb";
  --fa--fa: "\f6cb\f6cb";
}

.fa-podium {
  --fa: "\f680";
  --fa--fa: "\f680\f680";
}

.fa-diamonds-4 {
  --fa: "\e68b";
  --fa--fa: "\e68b\e68b";
}

.fa-memo-circle-check {
  --fa: "\e1d9";
  --fa--fa: "\e1d9\e1d9";
}

.fa-route-highway {
  --fa: "\f61a";
  --fa--fa: "\f61a\f61a";
}

.fa-down-to-line {
  --fa: "\f34a";
  --fa--fa: "\f34a\f34a";
}

.fa-arrow-alt-to-bottom {
  --fa: "\f34a";
  --fa--fa: "\f34a\f34a";
}

.fa-filter {
  --fa: "\f0b0";
  --fa--fa: "\f0b0\f0b0";
}

.fa-square-g {
  --fa: "\e271";
  --fa--fa: "\e271\e271";
}

.fa-circle-phone {
  --fa: "\e11b";
  --fa--fa: "\e11b\e11b";
}

.fa-phone-circle {
  --fa: "\e11b";
  --fa--fa: "\e11b\e11b";
}

.fa-clipboard-prescription {
  --fa: "\f5e8";
  --fa--fa: "\f5e8\f5e8";
}

.fa-user-nurse-hair {
  --fa: "\e45d";
  --fa--fa: "\e45d\e45d";
}

.fa-question {
  --fa: "\?";
  --fa--fa: "\?\?";
}

.fa-file-signature {
  --fa: "\f573";
  --fa--fa: "\f573\f573";
}

.fa-toggle-large-on {
  --fa: "\e5b1";
  --fa--fa: "\e5b1\e5b1";
}

.fa-up-down-left-right {
  --fa: "\f0b2";
  --fa--fa: "\f0b2\f0b2";
}

.fa-arrows-alt {
  --fa: "\f0b2";
  --fa--fa: "\f0b2\f0b2";
}

.fa-dryer-heat {
  --fa: "\f862";
  --fa--fa: "\f862\f862";
}

.fa-dryer-alt {
  --fa: "\f862";
  --fa--fa: "\f862\f862";
}

.fa-house-chimney-user {
  --fa: "\e065";
  --fa--fa: "\e065\e065";
}

.fa-hand-holding-heart {
  --fa: "\f4be";
  --fa--fa: "\f4be\f4be";
}

.fa-arrow-up-small-big {
  --fa: "\f88f";
  --fa--fa: "\f88f\f88f";
}

.fa-sort-size-up-alt {
  --fa: "\f88f";
  --fa--fa: "\f88f\f88f";
}

.fa-train-track {
  --fa: "\e453";
  --fa--fa: "\e453\e453";
}

.fa-puzzle-piece {
  --fa: "\f12e";
  --fa--fa: "\f12e\f12e";
}

.fa-money-check {
  --fa: "\f53c";
  --fa--fa: "\f53c\f53c";
}

.fa-star-half-stroke {
  --fa: "\f5c0";
  --fa--fa: "\f5c0\f5c0";
}

.fa-star-half-alt {
  --fa: "\f5c0";
  --fa--fa: "\f5c0\f5c0";
}

.fa-file-exclamation {
  --fa: "\f31a";
  --fa--fa: "\f31a\f31a";
}

.fa-code {
  --fa: "\f121";
  --fa--fa: "\f121\f121";
}

.fa-whiskey-glass {
  --fa: "\f7a0";
  --fa--fa: "\f7a0\f7a0";
}

.fa-glass-whiskey {
  --fa: "\f7a0";
  --fa--fa: "\f7a0\f7a0";
}

.fa-moon-stars {
  --fa: "\f755";
  --fa--fa: "\f755\f755";
}

.fa-building-circle-exclamation {
  --fa: "\e4d3";
  --fa--fa: "\e4d3\e4d3";
}

.fa-clothes-hanger {
  --fa: "\e136";
  --fa--fa: "\e136\e136";
}

.fa-mobile-notch {
  --fa: "\e1ee";
  --fa--fa: "\e1ee\e1ee";
}

.fa-mobile-iphone {
  --fa: "\e1ee";
  --fa--fa: "\e1ee\e1ee";
}

.fa-magnifying-glass-chart {
  --fa: "\e522";
  --fa--fa: "\e522\e522";
}

.fa-arrow-up-right-from-square {
  --fa: "\f08e";
  --fa--fa: "\f08e\f08e";
}

.fa-external-link {
  --fa: "\f08e";
  --fa--fa: "\f08e\f08e";
}

.fa-cubes-stacked {
  --fa: "\e4e6";
  --fa--fa: "\e4e6\e4e6";
}

.fa-images-user {
  --fa: "\e1b9";
  --fa--fa: "\e1b9\e1b9";
}

.fa-won-sign {
  --fa: "\f159";
  --fa--fa: "\f159\f159";
}

.fa-krw {
  --fa: "\f159";
  --fa--fa: "\f159\f159";
}

.fa-won {
  --fa: "\f159";
  --fa--fa: "\f159\f159";
}

.fa-image-polaroid-user {
  --fa: "\e1b6";
  --fa--fa: "\e1b6\e1b6";
}

.fa-virus-covid {
  --fa: "\e4a8";
  --fa--fa: "\e4a8\e4a8";
}

.fa-square-ellipsis {
  --fa: "\e26e";
  --fa--fa: "\e26e\e26e";
}

.fa-pie {
  --fa: "\f705";
  --fa--fa: "\f705\f705";
}

.fa-chess-knight-piece {
  --fa: "\f442";
  --fa--fa: "\f442\f442";
}

.fa-chess-knight-alt {
  --fa: "\f442";
  --fa--fa: "\f442\f442";
}

.fa-austral-sign {
  --fa: "\e0a9";
  --fa--fa: "\e0a9\e0a9";
}

.fa-cloud-plus {
  --fa: "\e35e";
  --fa--fa: "\e35e\e35e";
}

.fa-f {
  --fa: "F";
  --fa--fa: "FF";
}

.fa-leaf {
  --fa: "\f06c";
  --fa--fa: "\f06c\f06c";
}

.fa-bed-bunk {
  --fa: "\f8f8";
  --fa--fa: "\f8f8\f8f8";
}

.fa-road {
  --fa: "\f018";
  --fa--fa: "\f018\f018";
}

.fa-taxi {
  --fa: "\f1ba";
  --fa--fa: "\f1ba\f1ba";
}

.fa-cab {
  --fa: "\f1ba";
  --fa--fa: "\f1ba\f1ba";
}

.fa-person-circle-plus {
  --fa: "\e541";
  --fa--fa: "\e541\e541";
}

.fa-chart-pie {
  --fa: "\f200";
  --fa--fa: "\f200\f200";
}

.fa-pie-chart {
  --fa: "\f200";
  --fa--fa: "\f200\f200";
}

.fa-bolt-lightning {
  --fa: "\e0b7";
  --fa--fa: "\e0b7\e0b7";
}

.fa-clock-eight {
  --fa: "\e345";
  --fa--fa: "\e345\e345";
}

.fa-sack-xmark {
  --fa: "\e56a";
  --fa--fa: "\e56a\e56a";
}

.fa-file-xls {
  --fa: "\e64d";
  --fa--fa: "\e64d\e64d";
}

.fa-file-excel {
  --fa: "\f1c3";
  --fa--fa: "\f1c3\f1c3";
}

.fa-file-contract {
  --fa: "\f56c";
  --fa--fa: "\f56c\f56c";
}

.fa-fish-fins {
  --fa: "\e4f2";
  --fa--fa: "\e4f2\e4f2";
}

.fa-circle-q {
  --fa: "\e11e";
  --fa--fa: "\e11e\e11e";
}

.fa-building-flag {
  --fa: "\e4d5";
  --fa--fa: "\e4d5\e4d5";
}

.fa-face-grin-beam {
  --fa: "\f582";
  --fa--fa: "\f582\f582";
}

.fa-grin-beam {
  --fa: "\f582";
  --fa--fa: "\f582\f582";
}

.fa-object-ungroup {
  --fa: "\f248";
  --fa--fa: "\f248\f248";
}

.fa-face-disguise {
  --fa: "\e370";
  --fa--fa: "\e370\e370";
}

.fa-circle-arrow-down-right {
  --fa: "\e0fa";
  --fa--fa: "\e0fa\e0fa";
}

.fa-alien-8bit {
  --fa: "\f8f6";
  --fa--fa: "\f8f6\f8f6";
}

.fa-alien-monster {
  --fa: "\f8f6";
  --fa--fa: "\f8f6\f8f6";
}

.fa-hand-point-ribbon {
  --fa: "\e1a6";
  --fa--fa: "\e1a6\e1a6";
}

.fa-poop {
  --fa: "\f619";
  --fa--fa: "\f619\f619";
}

.fa-object-exclude {
  --fa: "\e49c";
  --fa--fa: "\e49c\e49c";
}

.fa-telescope {
  --fa: "\e03e";
  --fa--fa: "\e03e\e03e";
}

.fa-location-pin {
  --fa: "\f041";
  --fa--fa: "\f041\f041";
}

.fa-map-marker {
  --fa: "\f041";
  --fa--fa: "\f041\f041";
}

.fa-square-list {
  --fa: "\e489";
  --fa--fa: "\e489\e489";
}

.fa-kaaba {
  --fa: "\f66b";
  --fa--fa: "\f66b\f66b";
}

.fa-toilet-paper {
  --fa: "\f71e";
  --fa--fa: "\f71e\f71e";
}

.fa-helmet-safety {
  --fa: "\f807";
  --fa--fa: "\f807\f807";
}

.fa-hard-hat {
  --fa: "\f807";
  --fa--fa: "\f807\f807";
}

.fa-hat-hard {
  --fa: "\f807";
  --fa--fa: "\f807\f807";
}

.fa-comment-code {
  --fa: "\e147";
  --fa--fa: "\e147\e147";
}

.fa-sim-cards {
  --fa: "\e251";
  --fa--fa: "\e251\e251";
}

.fa-starship {
  --fa: "\e039";
  --fa--fa: "\e039\e039";
}

.fa-eject {
  --fa: "\f052";
  --fa--fa: "\f052\f052";
}

.fa-circle-right {
  --fa: "\f35a";
  --fa--fa: "\f35a\f35a";
}

.fa-arrow-alt-circle-right {
  --fa: "\f35a";
  --fa--fa: "\f35a\f35a";
}

.fa-plane-circle-check {
  --fa: "\e555";
  --fa--fa: "\e555\e555";
}

.fa-seal {
  --fa: "\e241";
  --fa--fa: "\e241\e241";
}

.fa-user-cowboy {
  --fa: "\f8ea";
  --fa--fa: "\f8ea\f8ea";
}

.fa-hexagon-vertical-nft {
  --fa: "\e505";
  --fa--fa: "\e505\e505";
}

.fa-face-rolling-eyes {
  --fa: "\f5a5";
  --fa--fa: "\f5a5\f5a5";
}

.fa-meh-rolling-eyes {
  --fa: "\f5a5";
  --fa--fa: "\f5a5\f5a5";
}

.fa-bread-loaf {
  --fa: "\f7eb";
  --fa--fa: "\f7eb\f7eb";
}

.fa-rings-wedding {
  --fa: "\f81b";
  --fa--fa: "\f81b\f81b";
}

.fa-object-group {
  --fa: "\f247";
  --fa--fa: "\f247\f247";
}

.fa-french-fries {
  --fa: "\f803";
  --fa--fa: "\f803\f803";
}

.fa-chart-line {
  --fa: "\f201";
  --fa--fa: "\f201\f201";
}

.fa-line-chart {
  --fa: "\f201";
  --fa--fa: "\f201\f201";
}

.fa-calendar-arrow-down {
  --fa: "\e0d0";
  --fa--fa: "\e0d0\e0d0";
}

.fa-calendar-download {
  --fa: "\e0d0";
  --fa--fa: "\e0d0\e0d0";
}

.fa-send-back {
  --fa: "\f87e";
  --fa--fa: "\f87e\f87e";
}

.fa-mask-ventilator {
  --fa: "\e524";
  --fa--fa: "\e524\e524";
}

.fa-tickets {
  --fa: "\e658";
  --fa--fa: "\e658\e658";
}

.fa-signature-lock {
  --fa: "\e3ca";
  --fa--fa: "\e3ca\e3ca";
}

.fa-arrow-right {
  --fa: "\f061";
  --fa--fa: "\f061\f061";
}

.fa-signs-post {
  --fa: "\f277";
  --fa--fa: "\f277\f277";
}

.fa-map-signs {
  --fa: "\f277";
  --fa--fa: "\f277\f277";
}

.fa-octagon-plus {
  --fa: "\f301";
  --fa--fa: "\f301\f301";
}

.fa-plus-octagon {
  --fa: "\f301";
  --fa--fa: "\f301\f301";
}

.fa-cash-register {
  --fa: "\f788";
  --fa--fa: "\f788\f788";
}

.fa-person-circle-question {
  --fa: "\e542";
  --fa--fa: "\e542\e542";
}

.fa-melon-slice {
  --fa: "\e311";
  --fa--fa: "\e311\e311";
}

.fa-space-station-moon {
  --fa: "\e033";
  --fa--fa: "\e033\e033";
}

.fa-message-smile {
  --fa: "\f4aa";
  --fa--fa: "\f4aa\f4aa";
}

.fa-comment-alt-smile {
  --fa: "\f4aa";
  --fa--fa: "\f4aa\f4aa";
}

.fa-cup-straw {
  --fa: "\e363";
  --fa--fa: "\e363\e363";
}

.fa-left-from-line {
  --fa: "\f348";
  --fa--fa: "\f348\f348";
}

.fa-arrow-alt-from-right {
  --fa: "\f348";
  --fa--fa: "\f348\f348";
}

.fa-h {
  --fa: "H";
  --fa--fa: "HH";
}

.fa-basket-shopping-simple {
  --fa: "\e0af";
  --fa--fa: "\e0af\e0af";
}

.fa-shopping-basket-alt {
  --fa: "\e0af";
  --fa--fa: "\e0af\e0af";
}

.fa-hands-holding-heart {
  --fa: "\f4c3";
  --fa--fa: "\f4c3\f4c3";
}

.fa-hands-heart {
  --fa: "\f4c3";
  --fa--fa: "\f4c3\f4c3";
}

.fa-clock-nine {
  --fa: "\e34c";
  --fa--fa: "\e34c\e34c";
}

.fa-hammer-brush {
  --fa: "\e620";
  --fa--fa: "\e620\e620";
}

.fa-tarp {
  --fa: "\e57b";
  --fa--fa: "\e57b\e57b";
}

.fa-face-sleepy {
  --fa: "\e38e";
  --fa--fa: "\e38e\e38e";
}

.fa-hand-horns {
  --fa: "\e1a9";
  --fa--fa: "\e1a9\e1a9";
}

.fa-screwdriver-wrench {
  --fa: "\f7d9";
  --fa--fa: "\f7d9\f7d9";
}

.fa-tools {
  --fa: "\f7d9";
  --fa--fa: "\f7d9\f7d9";
}

.fa-arrows-to-eye {
  --fa: "\e4bf";
  --fa--fa: "\e4bf\e4bf";
}

.fa-circle-three-quarters {
  --fa: "\e125";
  --fa--fa: "\e125\e125";
}

.fa-trophy-star {
  --fa: "\f2eb";
  --fa--fa: "\f2eb\f2eb";
}

.fa-trophy-alt {
  --fa: "\f2eb";
  --fa--fa: "\f2eb\f2eb";
}

.fa-plug-circle-bolt {
  --fa: "\e55b";
  --fa--fa: "\e55b\e55b";
}

.fa-face-thermometer {
  --fa: "\e39a";
  --fa--fa: "\e39a\e39a";
}

.fa-grid-round-4 {
  --fa: "\e5dd";
  --fa--fa: "\e5dd\e5dd";
}

.fa-sign-posts-wrench {
  --fa: "\e626";
  --fa--fa: "\e626\e626";
}

.fa-shirt-running {
  --fa: "\e3c8";
  --fa--fa: "\e3c8\e3c8";
}

.fa-book-circle-arrow-up {
  --fa: "\e0bd";
  --fa--fa: "\e0bd\e0bd";
}

.fa-face-nauseated {
  --fa: "\e381";
  --fa--fa: "\e381\e381";
}

.fa-heart {
  --fa: "\f004";
  --fa--fa: "\f004\f004";
}

.fa-file-chart-pie {
  --fa: "\f65a";
  --fa--fa: "\f65a\f65a";
}

.fa-mars-and-venus {
  --fa: "\f224";
  --fa--fa: "\f224\f224";
}

.fa-house-user {
  --fa: "\e1b0";
  --fa--fa: "\e1b0\e1b0";
}

.fa-home-user {
  --fa: "\e1b0";
  --fa--fa: "\e1b0\e1b0";
}

.fa-circle-arrow-down-left {
  --fa: "\e0f9";
  --fa--fa: "\e0f9\e0f9";
}

.fa-dumpster-fire {
  --fa: "\f794";
  --fa--fa: "\f794\f794";
}

.fa-hexagon-minus {
  --fa: "\f307";
  --fa--fa: "\f307\f307";
}

.fa-minus-hexagon {
  --fa: "\f307";
  --fa--fa: "\f307\f307";
}

.fa-left-to-line {
  --fa: "\f34b";
  --fa--fa: "\f34b\f34b";
}

.fa-arrow-alt-to-left {
  --fa: "\f34b";
  --fa--fa: "\f34b\f34b";
}

.fa-house-crack {
  --fa: "\e3b1";
  --fa--fa: "\e3b1\e3b1";
}

.fa-paw-simple {
  --fa: "\f701";
  --fa--fa: "\f701\f701";
}

.fa-paw-alt {
  --fa: "\f701";
  --fa--fa: "\f701\f701";
}

.fa-arrow-left-long-to-line {
  --fa: "\e3d4";
  --fa--fa: "\e3d4\e3d4";
}

.fa-brackets-round {
  --fa: "\e0c5";
  --fa--fa: "\e0c5\e0c5";
}

.fa-parentheses {
  --fa: "\e0c5";
  --fa--fa: "\e0c5\e0c5";
}

.fa-martini-glass-citrus {
  --fa: "\f561";
  --fa--fa: "\f561\f561";
}

.fa-cocktail {
  --fa: "\f561";
  --fa--fa: "\f561\f561";
}

.fa-user-shakespeare {
  --fa: "\e2c2";
  --fa--fa: "\e2c2\e2c2";
}

.fa-arrow-right-to-arc {
  --fa: "\e4b2";
  --fa--fa: "\e4b2\e4b2";
}

.fa-face-surprise {
  --fa: "\f5c2";
  --fa--fa: "\f5c2\f5c2";
}

.fa-surprise {
  --fa: "\f5c2";
  --fa--fa: "\f5c2\f5c2";
}

.fa-bottle-water {
  --fa: "\e4c5";
  --fa--fa: "\e4c5\e4c5";
}

.fa-circle-pause {
  --fa: "\f28b";
  --fa--fa: "\f28b\f28b";
}

.fa-pause-circle {
  --fa: "\f28b";
  --fa--fa: "\f28b\f28b";
}

.fa-gauge-circle-plus {
  --fa: "\e498";
  --fa--fa: "\e498\e498";
}

.fa-folders {
  --fa: "\f660";
  --fa--fa: "\f660\f660";
}

.fa-angel {
  --fa: "\f779";
  --fa--fa: "\f779\f779";
}

.fa-value-absolute {
  --fa: "\f6a6";
  --fa--fa: "\f6a6\f6a6";
}

.fa-rabbit {
  --fa: "\f708";
  --fa--fa: "\f708\f708";
}

.fa-toilet-paper-slash {
  --fa: "\e072";
  --fa--fa: "\e072\e072";
}

.fa-circle-euro {
  --fa: "\e5ce";
  --fa--fa: "\e5ce\e5ce";
}

.fa-apple-whole {
  --fa: "\f5d1";
  --fa--fa: "\f5d1\f5d1";
}

.fa-apple-alt {
  --fa: "\f5d1";
  --fa--fa: "\f5d1\f5d1";
}

.fa-kitchen-set {
  --fa: "\e51a";
  --fa--fa: "\e51a\e51a";
}

.fa-diamond-half {
  --fa: "\e5b7";
  --fa--fa: "\e5b7\e5b7";
}

.fa-lock-keyhole {
  --fa: "\f30d";
  --fa--fa: "\f30d\f30d";
}

.fa-lock-alt {
  --fa: "\f30d";
  --fa--fa: "\f30d\f30d";
}

.fa-r {
  --fa: "R";
  --fa--fa: "RR";
}

.fa-temperature-quarter {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-temperature-1 {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-thermometer-1 {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-thermometer-quarter {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-square-info {
  --fa: "\f30f";
  --fa--fa: "\f30f\f30f";
}

.fa-info-square {
  --fa: "\f30f";
  --fa--fa: "\f30f\f30f";
}

.fa-wifi-slash {
  --fa: "\f6ac";
  --fa--fa: "\f6ac\f6ac";
}

.fa-toilet-paper-xmark {
  --fa: "\e5b3";
  --fa--fa: "\e5b3\e5b3";
}

.fa-hands-holding-dollar {
  --fa: "\f4c5";
  --fa--fa: "\f4c5\f4c5";
}

.fa-hands-usd {
  --fa: "\f4c5";
  --fa--fa: "\f4c5\f4c5";
}

.fa-cube {
  --fa: "\f1b2";
  --fa--fa: "\f1b2\f1b2";
}

.fa-arrow-down-triangle-square {
  --fa: "\f888";
  --fa--fa: "\f888\f888";
}

.fa-sort-shapes-down {
  --fa: "\f888";
  --fa--fa: "\f888\f888";
}

.fa-bitcoin-sign {
  --fa: "\e0b4";
  --fa--fa: "\e0b4\e0b4";
}

.fa-shutters {
  --fa: "\e449";
  --fa--fa: "\e449\e449";
}

.fa-shield-dog {
  --fa: "\e573";
  --fa--fa: "\e573\e573";
}

.fa-solar-panel {
  --fa: "\f5ba";
  --fa--fa: "\f5ba\f5ba";
}

.fa-lock-open {
  --fa: "\f3c1";
  --fa--fa: "\f3c1\f3c1";
}

.fa-table-tree {
  --fa: "\e293";
  --fa--fa: "\e293\e293";
}

.fa-house-chimney-heart {
  --fa: "\e1b2";
  --fa--fa: "\e1b2\e1b2";
}

.fa-tally-3 {
  --fa: "\e296";
  --fa--fa: "\e296\e296";
}

.fa-elevator {
  --fa: "\e16d";
  --fa--fa: "\e16d\e16d";
}

.fa-money-bill-transfer {
  --fa: "\e528";
  --fa--fa: "\e528\e528";
}

.fa-money-bill-trend-up {
  --fa: "\e529";
  --fa--fa: "\e529\e529";
}

.fa-house-flood-water-circle-arrow-right {
  --fa: "\e50f";
  --fa--fa: "\e50f\e50f";
}

.fa-square-poll-horizontal {
  --fa: "\f682";
  --fa--fa: "\f682\f682";
}

.fa-poll-h {
  --fa: "\f682";
  --fa--fa: "\f682\f682";
}

.fa-circle {
  --fa: "\f111";
  --fa--fa: "\f111\f111";
}

.fa-left-to-bracket {
  --fa: "\e66d";
  --fa--fa: "\e66d\e66d";
}

.fa-cart-circle-exclamation {
  --fa: "\e3f2";
  --fa--fa: "\e3f2\e3f2";
}

.fa-sword {
  --fa: "\f71c";
  --fa--fa: "\f71c\f71c";
}

.fa-backward-fast {
  --fa: "\f049";
  --fa--fa: "\f049\f049";
}

.fa-fast-backward {
  --fa: "\f049";
  --fa--fa: "\f049\f049";
}

.fa-recycle {
  --fa: "\f1b8";
  --fa--fa: "\f1b8\f1b8";
}

.fa-user-astronaut {
  --fa: "\f4fb";
  --fa--fa: "\f4fb\f4fb";
}

.fa-interrobang {
  --fa: "\e5ba";
  --fa--fa: "\e5ba\e5ba";
}

.fa-plane-slash {
  --fa: "\e069";
  --fa--fa: "\e069\e069";
}

.fa-circle-dashed {
  --fa: "\e105";
  --fa--fa: "\e105\e105";
}

.fa-trademark {
  --fa: "\f25c";
  --fa--fa: "\f25c\f25c";
}

.fa-basketball {
  --fa: "\f434";
  --fa--fa: "\f434\f434";
}

.fa-basketball-ball {
  --fa: "\f434";
  --fa--fa: "\f434\f434";
}

.fa-fork-knife {
  --fa: "\f2e6";
  --fa--fa: "\f2e6\f2e6";
}

.fa-utensils-alt {
  --fa: "\f2e6";
  --fa--fa: "\f2e6\f2e6";
}

.fa-satellite-dish {
  --fa: "\f7c0";
  --fa--fa: "\f7c0\f7c0";
}

.fa-badge-check {
  --fa: "\f336";
  --fa--fa: "\f336\f336";
}

.fa-circle-up {
  --fa: "\f35b";
  --fa--fa: "\f35b\f35b";
}

.fa-arrow-alt-circle-up {
  --fa: "\f35b";
  --fa--fa: "\f35b\f35b";
}

.fa-slider {
  --fa: "\e252";
  --fa--fa: "\e252\e252";
}

.fa-mobile-screen-button {
  --fa: "\f3cd";
  --fa--fa: "\f3cd\f3cd";
}

.fa-mobile-alt {
  --fa: "\f3cd";
  --fa--fa: "\f3cd\f3cd";
}

.fa-clock-one-thirty {
  --fa: "\e34f";
  --fa--fa: "\e34f\e34f";
}

.fa-inbox-out {
  --fa: "\f311";
  --fa--fa: "\f311\f311";
}

.fa-inbox-arrow-up {
  --fa: "\f311";
  --fa--fa: "\f311\f311";
}

.fa-cloud-slash {
  --fa: "\e137";
  --fa--fa: "\e137\e137";
}

.fa-volume-high {
  --fa: "\f028";
  --fa--fa: "\f028\f028";
}

.fa-volume-up {
  --fa: "\f028";
  --fa--fa: "\f028\f028";
}

.fa-users-rays {
  --fa: "\e593";
  --fa--fa: "\e593\e593";
}

.fa-wallet {
  --fa: "\f555";
  --fa--fa: "\f555\f555";
}

.fa-octagon-check {
  --fa: "\e426";
  --fa--fa: "\e426\e426";
}

.fa-flatbread-stuffed {
  --fa: "\e40c";
  --fa--fa: "\e40c\e40c";
}

.fa-clipboard-check {
  --fa: "\f46c";
  --fa--fa: "\f46c\f46c";
}

.fa-cart-circle-plus {
  --fa: "\e3f3";
  --fa--fa: "\e3f3\e3f3";
}

.fa-truck-clock {
  --fa: "\f48c";
  --fa--fa: "\f48c\f48c";
}

.fa-shipping-timed {
  --fa: "\f48c";
  --fa--fa: "\f48c\f48c";
}

.fa-pool-8-ball {
  --fa: "\e3c5";
  --fa--fa: "\e3c5\e3c5";
}

.fa-file-audio {
  --fa: "\f1c7";
  --fa--fa: "\f1c7\f1c7";
}

.fa-turn-down-left {
  --fa: "\e331";
  --fa--fa: "\e331\e331";
}

.fa-lock-hashtag {
  --fa: "\e423";
  --fa--fa: "\e423\e423";
}

.fa-chart-radar {
  --fa: "\e0e7";
  --fa--fa: "\e0e7\e0e7";
}

.fa-staff {
  --fa: "\f71b";
  --fa--fa: "\f71b\f71b";
}

.fa-burger {
  --fa: "\f805";
  --fa--fa: "\f805\f805";
}

.fa-hamburger {
  --fa: "\f805";
  --fa--fa: "\f805\f805";
}

.fa-utility-pole {
  --fa: "\e2c3";
  --fa--fa: "\e2c3\e2c3";
}

.fa-transporter-6 {
  --fa: "\e2a7";
  --fa--fa: "\e2a7\e2a7";
}

.fa-arrow-turn-left {
  --fa: "\e632";
  --fa--fa: "\e632\e632";
}

.fa-wrench {
  --fa: "\f0ad";
  --fa--fa: "\f0ad\f0ad";
}

.fa-bugs {
  --fa: "\e4d0";
  --fa--fa: "\e4d0\e4d0";
}

.fa-vector-polygon {
  --fa: "\e2c7";
  --fa--fa: "\e2c7\e2c7";
}

.fa-diagram-nested {
  --fa: "\e157";
  --fa--fa: "\e157\e157";
}

.fa-rupee-sign {
  --fa: "\f156";
  --fa--fa: "\f156\f156";
}

.fa-rupee {
  --fa: "\f156";
  --fa--fa: "\f156\f156";
}

.fa-file-image {
  --fa: "\f1c5";
  --fa--fa: "\f1c5\f1c5";
}

.fa-circle-question {
  --fa: "\f059";
  --fa--fa: "\f059\f059";
}

.fa-question-circle {
  --fa: "\f059";
  --fa--fa: "\f059\f059";
}

.fa-tickets-perforated {
  --fa: "\e63f";
  --fa--fa: "\e63f\e63f";
}

.fa-image-user {
  --fa: "\e1b8";
  --fa--fa: "\e1b8\e1b8";
}

.fa-buoy {
  --fa: "\e5b5";
  --fa--fa: "\e5b5\e5b5";
}

.fa-plane-departure {
  --fa: "\f5b0";
  --fa--fa: "\f5b0\f5b0";
}

.fa-handshake-slash {
  --fa: "\e060";
  --fa--fa: "\e060\e060";
}

.fa-book-bookmark {
  --fa: "\e0bb";
  --fa--fa: "\e0bb\e0bb";
}

.fa-border-center-h {
  --fa: "\f89c";
  --fa--fa: "\f89c\f89c";
}

.fa-can-food {
  --fa: "\e3e6";
  --fa--fa: "\e3e6\e3e6";
}

.fa-typewriter {
  --fa: "\f8e7";
  --fa--fa: "\f8e7\f8e7";
}

.fa-arrow-right-from-arc {
  --fa: "\e4b1";
  --fa--fa: "\e4b1\e4b1";
}

.fa-circle-k {
  --fa: "\e113";
  --fa--fa: "\e113\e113";
}

.fa-face-hand-over-mouth {
  --fa: "\e378";
  --fa--fa: "\e378\e378";
}

.fa-popcorn {
  --fa: "\f819";
  --fa--fa: "\f819\f819";
}

.fa-house-water {
  --fa: "\f74f";
  --fa--fa: "\f74f\f74f";
}

.fa-house-flood {
  --fa: "\f74f";
  --fa--fa: "\f74f\f74f";
}

.fa-object-subtract {
  --fa: "\e49e";
  --fa--fa: "\e49e\e49e";
}

.fa-code-branch {
  --fa: "\f126";
  --fa--fa: "\f126\f126";
}

.fa-warehouse-full {
  --fa: "\f495";
  --fa--fa: "\f495\f495";
}

.fa-warehouse-alt {
  --fa: "\f495";
  --fa--fa: "\f495\f495";
}

.fa-hat-cowboy {
  --fa: "\f8c0";
  --fa--fa: "\f8c0\f8c0";
}

.fa-bridge {
  --fa: "\e4c8";
  --fa--fa: "\e4c8\e4c8";
}

.fa-phone-flip {
  --fa: "\f879";
  --fa--fa: "\f879\f879";
}

.fa-phone-alt {
  --fa: "\f879";
  --fa--fa: "\f879\f879";
}

.fa-arrow-down-from-dotted-line {
  --fa: "\e090";
  --fa--fa: "\e090\e090";
}

.fa-file-doc {
  --fa: "\e5ed";
  --fa--fa: "\e5ed\e5ed";
}

.fa-square-quarters {
  --fa: "\e44e";
  --fa--fa: "\e44e\e44e";
}

.fa-truck-front {
  --fa: "\e2b7";
  --fa--fa: "\e2b7\e2b7";
}

.fa-cat {
  --fa: "\f6be";
  --fa--fa: "\f6be\f6be";
}

.fa-trash-xmark {
  --fa: "\e2b4";
  --fa--fa: "\e2b4\e2b4";
}

.fa-circle-caret-left {
  --fa: "\f32e";
  --fa--fa: "\f32e\f32e";
}

.fa-caret-circle-left {
  --fa: "\f32e";
  --fa--fa: "\f32e\f32e";
}

.fa-files {
  --fa: "\e178";
  --fa--fa: "\e178\e178";
}

.fa-anchor-circle-exclamation {
  --fa: "\e4ab";
  --fa--fa: "\e4ab\e4ab";
}

.fa-face-clouds {
  --fa: "\e47d";
  --fa--fa: "\e47d\e47d";
}

.fa-user-crown {
  --fa: "\f6a4";
  --fa--fa: "\f6a4\f6a4";
}

.fa-basket-shopping-plus {
  --fa: "\e653";
  --fa--fa: "\e653\e653";
}

.fa-truck-field {
  --fa: "\e58d";
  --fa--fa: "\e58d\e58d";
}

.fa-route {
  --fa: "\f4d7";
  --fa--fa: "\f4d7\f4d7";
}

.fa-cart-circle-check {
  --fa: "\e3f1";
  --fa--fa: "\e3f1\e3f1";
}

.fa-clipboard-question {
  --fa: "\e4e3";
  --fa--fa: "\e4e3\e4e3";
}

.fa-panorama {
  --fa: "\e209";
  --fa--fa: "\e209\e209";
}

.fa-comment-medical {
  --fa: "\f7f5";
  --fa--fa: "\f7f5\f7f5";
}

.fa-teeth-open {
  --fa: "\f62f";
  --fa--fa: "\f62f\f62f";
}

.fa-user-tie-hair-long {
  --fa: "\e460";
  --fa--fa: "\e460\e460";
}

.fa-file-circle-minus {
  --fa: "\e4ed";
  --fa--fa: "\e4ed\e4ed";
}

.fa-head-side-medical {
  --fa: "\f809";
  --fa--fa: "\f809\f809";
}

.fa-arrow-turn-right {
  --fa: "\e635";
  --fa--fa: "\e635\e635";
}

.fa-tags {
  --fa: "\f02c";
  --fa--fa: "\f02c\f02c";
}

.fa-wine-glass {
  --fa: "\f4e3";
  --fa--fa: "\f4e3\f4e3";
}

.fa-forward-fast {
  --fa: "\f050";
  --fa--fa: "\f050\f050";
}

.fa-fast-forward {
  --fa: "\f050";
  --fa--fa: "\f050\f050";
}

.fa-face-meh-blank {
  --fa: "\f5a4";
  --fa--fa: "\f5a4\f5a4";
}

.fa-meh-blank {
  --fa: "\f5a4";
  --fa--fa: "\f5a4\f5a4";
}

.fa-user-robot {
  --fa: "\e04b";
  --fa--fa: "\e04b\e04b";
}

.fa-square-parking {
  --fa: "\f540";
  --fa--fa: "\f540\f540";
}

.fa-parking {
  --fa: "\f540";
  --fa--fa: "\f540\f540";
}

.fa-card-diamond {
  --fa: "\e3ea";
  --fa--fa: "\e3ea\e3ea";
}

.fa-face-zipper {
  --fa: "\e3a5";
  --fa--fa: "\e3a5\e3a5";
}

.fa-face-raised-eyebrow {
  --fa: "\e388";
  --fa--fa: "\e388\e388";
}

.fa-house-signal {
  --fa: "\e012";
  --fa--fa: "\e012\e012";
}

.fa-square-chevron-up {
  --fa: "\f32c";
  --fa--fa: "\f32c\f32c";
}

.fa-chevron-square-up {
  --fa: "\f32c";
  --fa--fa: "\f32c\f32c";
}

.fa-bars-progress {
  --fa: "\f828";
  --fa--fa: "\f828\f828";
}

.fa-tasks-alt {
  --fa: "\f828";
  --fa--fa: "\f828\f828";
}

.fa-faucet-drip {
  --fa: "\e006";
  --fa--fa: "\e006\e006";
}

.fa-arrows-to-line {
  --fa: "\e0a7";
  --fa--fa: "\e0a7\e0a7";
}

.fa-dolphin {
  --fa: "\e168";
  --fa--fa: "\e168\e168";
}

.fa-arrow-up-right {
  --fa: "\e09f";
  --fa--fa: "\e09f\e09f";
}

.fa-circle-r {
  --fa: "\e120";
  --fa--fa: "\e120\e120";
}

.fa-cart-flatbed {
  --fa: "\f474";
  --fa--fa: "\f474\f474";
}

.fa-dolly-flatbed {
  --fa: "\f474";
  --fa--fa: "\f474\f474";
}

.fa-ban-smoking {
  --fa: "\f54d";
  --fa--fa: "\f54d\f54d";
}

.fa-smoking-ban {
  --fa: "\f54d";
  --fa--fa: "\f54d\f54d";
}

.fa-circle-sort-up {
  --fa: "\e032";
  --fa--fa: "\e032\e032";
}

.fa-sort-circle-up {
  --fa: "\e032";
  --fa--fa: "\e032\e032";
}

.fa-terminal {
  --fa: "\f120";
  --fa--fa: "\f120\f120";
}

.fa-mobile-button {
  --fa: "\f10b";
  --fa--fa: "\f10b\f10b";
}

.fa-house-medical-flag {
  --fa: "\e514";
  --fa--fa: "\e514\e514";
}

.fa-basket-shopping {
  --fa: "\f291";
  --fa--fa: "\f291\f291";
}

.fa-shopping-basket {
  --fa: "\f291";
  --fa--fa: "\f291\f291";
}

.fa-tape {
  --fa: "\f4db";
  --fa--fa: "\f4db\f4db";
}

.fa-chestnut {
  --fa: "\e3f6";
  --fa--fa: "\e3f6\e3f6";
}

.fa-bus-simple {
  --fa: "\f55e";
  --fa--fa: "\f55e\f55e";
}

.fa-bus-alt {
  --fa: "\f55e";
  --fa--fa: "\f55e\f55e";
}

.fa-eye {
  --fa: "\f06e";
  --fa--fa: "\f06e\f06e";
}

.fa-face-sad-cry {
  --fa: "\f5b3";
  --fa--fa: "\f5b3\f5b3";
}

.fa-sad-cry {
  --fa: "\f5b3";
  --fa--fa: "\f5b3\f5b3";
}

.fa-heat {
  --fa: "\e00c";
  --fa--fa: "\e00c\e00c";
}

.fa-ticket-airline {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a";
}

.fa-ticket-perforated-plane {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a";
}

.fa-ticket-plane {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a";
}

.fa-boot-heeled {
  --fa: "\e33f";
  --fa--fa: "\e33f\e33f";
}

.fa-arrows-minimize {
  --fa: "\e0a5";
  --fa--fa: "\e0a5\e0a5";
}

.fa-compress-arrows {
  --fa: "\e0a5";
  --fa--fa: "\e0a5\e0a5";
}

.fa-audio-description {
  --fa: "\f29e";
  --fa--fa: "\f29e\f29e";
}

.fa-person-military-to-person {
  --fa: "\e54c";
  --fa--fa: "\e54c\e54c";
}

.fa-file-shield {
  --fa: "\e4f0";
  --fa--fa: "\e4f0\e4f0";
}

.fa-hexagon {
  --fa: "\f312";
  --fa--fa: "\f312\f312";
}

.fa-manhole {
  --fa: "\e1d6";
  --fa--fa: "\e1d6\e1d6";
}

.fa-user-slash {
  --fa: "\f506";
  --fa--fa: "\f506\f506";
}

.fa-pen {
  --fa: "\f304";
  --fa--fa: "\f304\f304";
}

.fa-tower-observation {
  --fa: "\e586";
  --fa--fa: "\e586\e586";
}

.fa-floppy-disks {
  --fa: "\e183";
  --fa--fa: "\e183\e183";
}

.fa-toilet-paper-blank-under {
  --fa: "\e29f";
  --fa--fa: "\e29f\e29f";
}

.fa-toilet-paper-reverse-alt {
  --fa: "\e29f";
  --fa--fa: "\e29f\e29f";
}

.fa-file-code {
  --fa: "\f1c9";
  --fa--fa: "\f1c9\f1c9";
}

.fa-signal {
  --fa: "\f012";
  --fa--fa: "\f012\f012";
}

.fa-signal-5 {
  --fa: "\f012";
  --fa--fa: "\f012\f012";
}

.fa-signal-perfect {
  --fa: "\f012";
  --fa--fa: "\f012\f012";
}

.fa-pump {
  --fa: "\e442";
  --fa--fa: "\e442\e442";
}

.fa-bus {
  --fa: "\f207";
  --fa--fa: "\f207\f207";
}

.fa-heart-circle-xmark {
  --fa: "\e501";
  --fa--fa: "\e501\e501";
}

.fa-arrow-up-left-from-circle {
  --fa: "\e09e";
  --fa--fa: "\e09e\e09e";
}

.fa-house-chimney {
  --fa: "\e3af";
  --fa--fa: "\e3af\e3af";
}

.fa-home-lg {
  --fa: "\e3af";
  --fa--fa: "\e3af\e3af";
}

.fa-window-maximize {
  --fa: "\f2d0";
  --fa--fa: "\f2d0\f2d0";
}

.fa-dryer {
  --fa: "\f861";
  --fa--fa: "\f861\f861";
}

.fa-face-frown {
  --fa: "\f119";
  --fa--fa: "\f119\f119";
}

.fa-frown {
  --fa: "\f119";
  --fa--fa: "\f119\f119";
}

.fa-chess-bishop-piece {
  --fa: "\f43b";
  --fa--fa: "\f43b\f43b";
}

.fa-chess-bishop-alt {
  --fa: "\f43b";
  --fa--fa: "\f43b\f43b";
}

.fa-shirt-tank-top {
  --fa: "\e3c9";
  --fa--fa: "\e3c9\e3c9";
}

.fa-diploma {
  --fa: "\f5ea";
  --fa--fa: "\f5ea\f5ea";
}

.fa-scroll-ribbon {
  --fa: "\f5ea";
  --fa--fa: "\f5ea\f5ea";
}

.fa-screencast {
  --fa: "\e23e";
  --fa--fa: "\e23e\e23e";
}

.fa-walker {
  --fa: "\f831";
  --fa--fa: "\f831\f831";
}

.fa-prescription {
  --fa: "\f5b1";
  --fa--fa: "\f5b1\f5b1";
}

.fa-shop {
  --fa: "\f54f";
  --fa--fa: "\f54f\f54f";
}

.fa-store-alt {
  --fa: "\f54f";
  --fa--fa: "\f54f\f54f";
}

.fa-floppy-disk {
  --fa: "\f0c7";
  --fa--fa: "\f0c7\f0c7";
}

.fa-save {
  --fa: "\f0c7";
  --fa--fa: "\f0c7\f0c7";
}

.fa-vihara {
  --fa: "\f6a7";
  --fa--fa: "\f6a7\f6a7";
}

.fa-face-kiss-closed-eyes {
  --fa: "\e37d";
  --fa--fa: "\e37d\e37d";
}

.fa-scale-unbalanced {
  --fa: "\f515";
  --fa--fa: "\f515\f515";
}

.fa-balance-scale-left {
  --fa: "\f515";
  --fa--fa: "\f515\f515";
}

.fa-file-user {
  --fa: "\f65c";
  --fa--fa: "\f65c\f65c";
}

.fa-user-police-tie {
  --fa: "\e334";
  --fa--fa: "\e334\e334";
}

.fa-face-tongue-money {
  --fa: "\e39d";
  --fa--fa: "\e39d\e39d";
}

.fa-tennis-ball {
  --fa: "\f45e";
  --fa--fa: "\f45e\f45e";
}

.fa-square-l {
  --fa: "\e275";
  --fa--fa: "\e275\e275";
}

.fa-sort-up {
  --fa: "\f0de";
  --fa--fa: "\f0de\f0de";
}

.fa-sort-asc {
  --fa: "\f0de";
  --fa--fa: "\f0de\f0de";
}

.fa-calendar-arrow-up {
  --fa: "\e0d1";
  --fa--fa: "\e0d1\e0d1";
}

.fa-calendar-upload {
  --fa: "\e0d1";
  --fa--fa: "\e0d1\e0d1";
}

.fa-comment-dots {
  --fa: "\f4ad";
  --fa--fa: "\f4ad\f4ad";
}

.fa-commenting {
  --fa: "\f4ad";
  --fa--fa: "\f4ad\f4ad";
}

.fa-plant-wilt {
  --fa: "\e5aa";
  --fa--fa: "\e5aa\e5aa";
}

.fa-scarf {
  --fa: "\f7c1";
  --fa--fa: "\f7c1\f7c1";
}

.fa-album-circle-plus {
  --fa: "\e48c";
  --fa--fa: "\e48c\e48c";
}

.fa-user-nurse-hair-long {
  --fa: "\e45e";
  --fa--fa: "\e45e\e45e";
}

.fa-diamond {
  --fa: "\f219";
  --fa--fa: "\f219\f219";
}

.fa-square-left {
  --fa: "\f351";
  --fa--fa: "\f351\f351";
}

.fa-arrow-alt-square-left {
  --fa: "\f351";
  --fa--fa: "\f351\f351";
}

.fa-face-grin-squint {
  --fa: "\f585";
  --fa--fa: "\f585\f585";
}

.fa-grin-squint {
  --fa: "\f585";
  --fa--fa: "\f585\f585";
}

.fa-circle-ellipsis-vertical {
  --fa: "\e10b";
  --fa--fa: "\e10b\e10b";
}

.fa-hand-holding-dollar {
  --fa: "\f4c0";
  --fa--fa: "\f4c0\f4c0";
}

.fa-hand-holding-usd {
  --fa: "\f4c0";
  --fa--fa: "\f4c0\f4c0";
}

.fa-grid-dividers {
  --fa: "\e3ad";
  --fa--fa: "\e3ad\e3ad";
}

.fa-chart-diagram {
  --fa: "\e695";
  --fa--fa: "\e695\e695";
}

.fa-bacterium {
  --fa: "\e05a";
  --fa--fa: "\e05a\e05a";
}

.fa-hand-pointer {
  --fa: "\f25a";
  --fa--fa: "\f25a\f25a";
}

.fa-drum-steelpan {
  --fa: "\f56a";
  --fa--fa: "\f56a\f56a";
}

.fa-hand-scissors {
  --fa: "\f257";
  --fa--fa: "\f257\f257";
}

.fa-hands-praying {
  --fa: "\f684";
  --fa--fa: "\f684\f684";
}

.fa-praying-hands {
  --fa: "\f684";
  --fa--fa: "\f684\f684";
}

.fa-face-pensive {
  --fa: "\e384";
  --fa--fa: "\e384\e384";
}

.fa-user-music {
  --fa: "\f8eb";
  --fa--fa: "\f8eb\f8eb";
}

.fa-arrow-rotate-right {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-arrow-right-rotate {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-arrow-rotate-forward {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-redo {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-messages-dollar {
  --fa: "\f652";
  --fa--fa: "\f652\f652";
}

.fa-comments-alt-dollar {
  --fa: "\f652";
  --fa--fa: "\f652\f652";
}

.fa-sensor-on {
  --fa: "\e02b";
  --fa--fa: "\e02b\e02b";
}

.fa-balloon {
  --fa: "\e2e3";
  --fa--fa: "\e2e3\e2e3";
}

.fa-biohazard {
  --fa: "\f780";
  --fa--fa: "\f780\f780";
}

.fa-chess-queen-piece {
  --fa: "\f446";
  --fa--fa: "\f446\f446";
}

.fa-chess-queen-alt {
  --fa: "\f446";
  --fa--fa: "\f446\f446";
}

.fa-location-crosshairs {
  --fa: "\f601";
  --fa--fa: "\f601\f601";
}

.fa-location {
  --fa: "\f601";
  --fa--fa: "\f601\f601";
}

.fa-mars-double {
  --fa: "\f227";
  --fa--fa: "\f227\f227";
}

.fa-left-from-bracket {
  --fa: "\e66c";
  --fa--fa: "\e66c\e66c";
}

.fa-house-person-leave {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f";
}

.fa-house-leave {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f";
}

.fa-house-person-depart {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f";
}

.fa-ruler-triangle {
  --fa: "\f61c";
  --fa--fa: "\f61c\f61c";
}

.fa-card-club {
  --fa: "\e3e9";
  --fa--fa: "\e3e9\e3e9";
}

.fa-child-dress {
  --fa: "\e59c";
  --fa--fa: "\e59c\e59c";
}

.fa-users-between-lines {
  --fa: "\e591";
  --fa--fa: "\e591\e591";
}

.fa-lungs-virus {
  --fa: "\e067";
  --fa--fa: "\e067\e067";
}

.fa-spinner-third {
  --fa: "\f3f4";
  --fa--fa: "\f3f4\f3f4";
}

.fa-face-grin-tears {
  --fa: "\f588";
  --fa--fa: "\f588\f588";
}

.fa-grin-tears {
  --fa: "\f588";
  --fa--fa: "\f588\f588";
}

.fa-phone {
  --fa: "\f095";
  --fa--fa: "\f095\f095";
}

.fa-computer-mouse-scrollwheel {
  --fa: "\f8cd";
  --fa--fa: "\f8cd\f8cd";
}

.fa-mouse-alt {
  --fa: "\f8cd";
  --fa--fa: "\f8cd\f8cd";
}

.fa-calendar-xmark {
  --fa: "\f273";
  --fa--fa: "\f273\f273";
}

.fa-calendar-times {
  --fa: "\f273";
  --fa--fa: "\f273\f273";
}

.fa-child-reaching {
  --fa: "\e59d";
  --fa--fa: "\e59d\e59d";
}

.fa-table-layout {
  --fa: "\e290";
  --fa--fa: "\e290\e290";
}

.fa-narwhal {
  --fa: "\f6fe";
  --fa--fa: "\f6fe\f6fe";
}

.fa-ramp-loading {
  --fa: "\f4d4";
  --fa--fa: "\f4d4\f4d4";
}

.fa-calendar-circle-plus {
  --fa: "\e470";
  --fa--fa: "\e470\e470";
}

.fa-toothbrush {
  --fa: "\f635";
  --fa--fa: "\f635\f635";
}

.fa-border-inner {
  --fa: "\f84e";
  --fa--fa: "\f84e\f84e";
}

.fa-paw-claws {
  --fa: "\f702";
  --fa--fa: "\f702\f702";
}

.fa-kiwi-fruit {
  --fa: "\e30c";
  --fa--fa: "\e30c\e30c";
}

.fa-traffic-light-slow {
  --fa: "\f639";
  --fa--fa: "\f639\f639";
}

.fa-rectangle-code {
  --fa: "\e322";
  --fa--fa: "\e322\e322";
}

.fa-head-side-virus {
  --fa: "\e064";
  --fa--fa: "\e064\e064";
}

.fa-keyboard-brightness {
  --fa: "\e1c0";
  --fa--fa: "\e1c0\e1c0";
}

.fa-books-medical {
  --fa: "\f7e8";
  --fa--fa: "\f7e8\f7e8";
}

.fa-lightbulb-slash {
  --fa: "\f673";
  --fa--fa: "\f673\f673";
}

.fa-house-blank {
  --fa: "\e487";
  --fa--fa: "\e487\e487";
}

.fa-home-blank {
  --fa: "\e487";
  --fa--fa: "\e487\e487";
}

.fa-square-5 {
  --fa: "\e25a";
  --fa--fa: "\e25a\e25a";
}

.fa-square-heart {
  --fa: "\f4c8";
  --fa--fa: "\f4c8\f4c8";
}

.fa-heart-square {
  --fa: "\f4c8";
  --fa--fa: "\f4c8\f4c8";
}

.fa-puzzle {
  --fa: "\e443";
  --fa--fa: "\e443\e443";
}

.fa-user-gear {
  --fa: "\f4fe";
  --fa--fa: "\f4fe\f4fe";
}

.fa-user-cog {
  --fa: "\f4fe";
  --fa--fa: "\f4fe\f4fe";
}

.fa-pipe-circle-check {
  --fa: "\e436";
  --fa--fa: "\e436\e436";
}

.fa-arrow-up-1-9 {
  --fa: "\f163";
  --fa--fa: "\f163\f163";
}

.fa-sort-numeric-up {
  --fa: "\f163";
  --fa--fa: "\f163\f163";
}

.fa-octagon-exclamation {
  --fa: "\e204";
  --fa--fa: "\e204\e204";
}

.fa-dial-low {
  --fa: "\e15d";
  --fa--fa: "\e15d\e15d";
}

.fa-door-closed {
  --fa: "\f52a";
  --fa--fa: "\f52a\f52a";
}

.fa-laptop-mobile {
  --fa: "\f87a";
  --fa--fa: "\f87a\f87a";
}

.fa-phone-laptop {
  --fa: "\f87a";
  --fa--fa: "\f87a\f87a";
}

.fa-conveyor-belt-boxes {
  --fa: "\f46f";
  --fa--fa: "\f46f\f46f";
}

.fa-conveyor-belt-alt {
  --fa: "\f46f";
  --fa--fa: "\f46f\f46f";
}

.fa-shield-virus {
  --fa: "\e06c";
  --fa--fa: "\e06c\e06c";
}

.fa-starfighter-twin-ion-engine-advanced {
  --fa: "\e28e";
  --fa--fa: "\e28e\e28e";
}

.fa-starfighter-alt-advanced {
  --fa: "\e28e";
  --fa--fa: "\e28e\e28e";
}

.fa-dice-six {
  --fa: "\f526";
  --fa--fa: "\f526\f526";
}

.fa-starfighter-twin-ion-engine {
  --fa: "\e038";
  --fa--fa: "\e038\e038";
}

.fa-starfighter-alt {
  --fa: "\e038";
  --fa--fa: "\e038\e038";
}

.fa-rocket-launch {
  --fa: "\e027";
  --fa--fa: "\e027\e027";
}

.fa-mosquito-net {
  --fa: "\e52c";
  --fa--fa: "\e52c\e52c";
}

.fa-file-fragment {
  --fa: "\e697";
  --fa--fa: "\e697\e697";
}

.fa-vent-damper {
  --fa: "\e465";
  --fa--fa: "\e465\e465";
}

.fa-bridge-water {
  --fa: "\e4ce";
  --fa--fa: "\e4ce\e4ce";
}

.fa-ban-bug {
  --fa: "\f7f9";
  --fa--fa: "\f7f9\f7f9";
}

.fa-debug {
  --fa: "\f7f9";
  --fa--fa: "\f7f9\f7f9";
}

.fa-person-booth {
  --fa: "\f756";
  --fa--fa: "\f756\f756";
}

.fa-text-width {
  --fa: "\f035";
  --fa--fa: "\f035\f035";
}

.fa-garage-car {
  --fa: "\e00a";
  --fa--fa: "\e00a\e00a";
}

.fa-square-kanban {
  --fa: "\e488";
  --fa--fa: "\e488\e488";
}

.fa-hat-wizard {
  --fa: "\f6e8";
  --fa--fa: "\f6e8\f6e8";
}

.fa-chart-kanban {
  --fa: "\e64f";
  --fa--fa: "\e64f\e64f";
}

.fa-pen-fancy {
  --fa: "\f5ac";
  --fa--fa: "\f5ac\f5ac";
}

.fa-coffee-pot {
  --fa: "\e002";
  --fa--fa: "\e002\e002";
}

.fa-mouse-field {
  --fa: "\e5a8";
  --fa--fa: "\e5a8\e5a8";
}

.fa-person-digging {
  --fa: "\f85e";
  --fa--fa: "\f85e\f85e";
}

.fa-digging {
  --fa: "\f85e";
  --fa--fa: "\f85e\f85e";
}

.fa-shower-down {
  --fa: "\e24d";
  --fa--fa: "\e24d\e24d";
}

.fa-shower-alt {
  --fa: "\e24d";
  --fa--fa: "\e24d\e24d";
}

.fa-box-circle-check {
  --fa: "\e0c4";
  --fa--fa: "\e0c4\e0c4";
}

.fa-brightness {
  --fa: "\e0c9";
  --fa--fa: "\e0c9\e0c9";
}

.fa-car-side-bolt {
  --fa: "\e344";
  --fa--fa: "\e344\e344";
}

.fa-file-xml {
  --fa: "\e654";
  --fa--fa: "\e654\e654";
}

.fa-ornament {
  --fa: "\f7b8";
  --fa--fa: "\f7b8\f7b8";
}

.fa-phone-arrow-down-left {
  --fa: "\e223";
  --fa--fa: "\e223\e223";
}

.fa-phone-arrow-down {
  --fa: "\e223";
  --fa--fa: "\e223\e223";
}

.fa-phone-incoming {
  --fa: "\e223";
  --fa--fa: "\e223\e223";
}

.fa-cloud-word {
  --fa: "\e138";
  --fa--fa: "\e138\e138";
}

.fa-hand-fingers-crossed {
  --fa: "\e1a3";
  --fa--fa: "\e1a3\e1a3";
}

.fa-trash {
  --fa: "\f1f8";
  --fa--fa: "\f1f8\f1f8";
}

.fa-gauge-simple {
  --fa: "\f629";
  --fa--fa: "\f629\f629";
}

.fa-gauge-simple-med {
  --fa: "\f629";
  --fa--fa: "\f629\f629";
}

.fa-tachometer-average {
  --fa: "\f629";
  --fa--fa: "\f629\f629";
}

.fa-arrow-down-small-big {
  --fa: "\f88d";
  --fa--fa: "\f88d\f88d";
}

.fa-sort-size-down-alt {
  --fa: "\f88d";
  --fa--fa: "\f88d\f88d";
}

.fa-book-medical {
  --fa: "\f7e6";
  --fa--fa: "\f7e6\f7e6";
}

.fa-face-melting {
  --fa: "\e483";
  --fa--fa: "\e483\e483";
}

.fa-poo {
  --fa: "\f2fe";
  --fa--fa: "\f2fe\f2fe";
}

.fa-pen-clip-slash {
  --fa: "\e20f";
  --fa--fa: "\e20f\e20f";
}

.fa-pen-alt-slash {
  --fa: "\e20f";
  --fa--fa: "\e20f\e20f";
}

.fa-quote-right {
  --fa: "\f10e";
  --fa--fa: "\f10e\f10e";
}

.fa-quote-right-alt {
  --fa: "\f10e";
  --fa--fa: "\f10e\f10e";
}

.fa-scroll-old {
  --fa: "\f70f";
  --fa--fa: "\f70f\f70f";
}

.fa-guitars {
  --fa: "\f8bf";
  --fa--fa: "\f8bf\f8bf";
}

.fa-phone-xmark {
  --fa: "\e227";
  --fa--fa: "\e227\e227";
}

.fa-hose {
  --fa: "\e419";
  --fa--fa: "\e419\e419";
}

.fa-clock-six {
  --fa: "\e352";
  --fa--fa: "\e352\e352";
}

.fa-shirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553";
}

.fa-t-shirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553";
}

.fa-tshirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553";
}

.fa-billboard {
  --fa: "\e5cd";
  --fa--fa: "\e5cd\e5cd";
}

.fa-square-r {
  --fa: "\e27c";
  --fa--fa: "\e27c\e27c";
}

.fa-cubes {
  --fa: "\f1b3";
  --fa--fa: "\f1b3\f1b3";
}

.fa-envelope-open-dollar {
  --fa: "\f657";
  --fa--fa: "\f657\f657";
}

.fa-divide {
  --fa: "\f529";
  --fa--fa: "\f529\f529";
}

.fa-sun-cloud {
  --fa: "\f763";
  --fa--fa: "\f763\f763";
}

.fa-lamp-floor {
  --fa: "\e015";
  --fa--fa: "\e015\e015";
}

.fa-square-7 {
  --fa: "\e25c";
  --fa--fa: "\e25c\e25c";
}

.fa-tenge-sign {
  --fa: "\f7d7";
  --fa--fa: "\f7d7\f7d7";
}

.fa-tenge {
  --fa: "\f7d7";
  --fa--fa: "\f7d7\f7d7";
}

.fa-headphones {
  --fa: "\f025";
  --fa--fa: "\f025\f025";
}

.fa-hands-holding {
  --fa: "\f4c2";
  --fa--fa: "\f4c2\f4c2";
}

.fa-campfire {
  --fa: "\f6ba";
  --fa--fa: "\f6ba\f6ba";
}

.fa-circle-ampersand {
  --fa: "\e0f8";
  --fa--fa: "\e0f8\e0f8";
}

.fa-snowflakes {
  --fa: "\f7cf";
  --fa--fa: "\f7cf\f7cf";
}

.fa-hands-clapping {
  --fa: "\e1a8";
  --fa--fa: "\e1a8\e1a8";
}

.fa-republican {
  --fa: "\f75e";
  --fa--fa: "\f75e\f75e";
}

.fa-leaf-maple {
  --fa: "\f6f6";
  --fa--fa: "\f6f6\f6f6";
}

.fa-arrow-left {
  --fa: "\f060";
  --fa--fa: "\f060\f060";
}

.fa-person-circle-xmark {
  --fa: "\e543";
  --fa--fa: "\e543\e543";
}

.fa-ruler {
  --fa: "\f545";
  --fa--fa: "\f545\f545";
}

.fa-arrow-left-from-bracket {
  --fa: "\e668";
  --fa--fa: "\e668\e668";
}

.fa-cup-straw-swoosh {
  --fa: "\e364";
  --fa--fa: "\e364\e364";
}

.fa-temperature-sun {
  --fa: "\f76a";
  --fa--fa: "\f76a\f76a";
}

.fa-temperature-hot {
  --fa: "\f76a";
  --fa--fa: "\f76a\f76a";
}

.fa-align-left {
  --fa: "\f036";
  --fa--fa: "\f036\f036";
}

.fa-dice-d6 {
  --fa: "\f6d1";
  --fa--fa: "\f6d1\f6d1";
}

.fa-restroom {
  --fa: "\f7bd";
  --fa--fa: "\f7bd\f7bd";
}

.fa-high-definition {
  --fa: "\e1ae";
  --fa--fa: "\e1ae\e1ae";
}

.fa-rectangle-hd {
  --fa: "\e1ae";
  --fa--fa: "\e1ae\e1ae";
}

.fa-j {
  --fa: "J";
  --fa--fa: "JJ";
}

.fa-galaxy {
  --fa: "\e008";
  --fa--fa: "\e008\e008";
}

.fa-users-viewfinder {
  --fa: "\e595";
  --fa--fa: "\e595\e595";
}

.fa-file-video {
  --fa: "\f1c8";
  --fa--fa: "\f1c8\f1c8";
}

.fa-cherries {
  --fa: "\e0ec";
  --fa--fa: "\e0ec\e0ec";
}

.fa-up-right-from-square {
  --fa: "\f35d";
  --fa--fa: "\f35d\f35d";
}

.fa-external-link-alt {
  --fa: "\f35d";
  --fa--fa: "\f35d\f35d";
}

.fa-circle-sort {
  --fa: "\e030";
  --fa--fa: "\e030\e030";
}

.fa-sort-circle {
  --fa: "\e030";
  --fa--fa: "\e030\e030";
}

.fa-table-cells {
  --fa: "\f00a";
  --fa--fa: "\f00a\f00a";
}

.fa-th {
  --fa: "\f00a";
  --fa--fa: "\f00a\f00a";
}

.fa-bag-shopping-minus {
  --fa: "\e650";
  --fa--fa: "\e650\e650";
}

.fa-file-pdf {
  --fa: "\f1c1";
  --fa--fa: "\f1c1\f1c1";
}

.fa-siren {
  --fa: "\e02d";
  --fa--fa: "\e02d\e02d";
}

.fa-arrow-up-to-dotted-line {
  --fa: "\e0a1";
  --fa--fa: "\e0a1\e0a1";
}

.fa-image-landscape {
  --fa: "\e1b5";
  --fa--fa: "\e1b5\e1b5";
}

.fa-landscape {
  --fa: "\e1b5";
  --fa--fa: "\e1b5\e1b5";
}

.fa-tank-water {
  --fa: "\e452";
  --fa--fa: "\e452\e452";
}

.fa-curling-stone {
  --fa: "\f44a";
  --fa--fa: "\f44a\f44a";
}

.fa-curling {
  --fa: "\f44a";
  --fa--fa: "\f44a\f44a";
}

.fa-gamepad-modern {
  --fa: "\e5a2";
  --fa--fa: "\e5a2\e5a2";
}

.fa-gamepad-alt {
  --fa: "\e5a2";
  --fa--fa: "\e5a2\e5a2";
}

.fa-messages-question {
  --fa: "\e1e7";
  --fa--fa: "\e1e7\e1e7";
}

.fa-book-bible {
  --fa: "\f647";
  --fa--fa: "\f647\f647";
}

.fa-bible {
  --fa: "\f647";
  --fa--fa: "\f647\f647";
}

.fa-o {
  --fa: "O";
  --fa--fa: "OO";
}

.fa-suitcase-medical {
  --fa: "\f0fa";
  --fa--fa: "\f0fa\f0fa";
}

.fa-medkit {
  --fa: "\f0fa";
  --fa--fa: "\f0fa\f0fa";
}

.fa-briefcase-arrow-right {
  --fa: "\e2f2";
  --fa--fa: "\e2f2\e2f2";
}

.fa-expand-wide {
  --fa: "\f320";
  --fa--fa: "\f320\f320";
}

.fa-clock-eleven-thirty {
  --fa: "\e348";
  --fa--fa: "\e348\e348";
}

.fa-rv {
  --fa: "\f7be";
  --fa--fa: "\f7be\f7be";
}

.fa-user-secret {
  --fa: "\f21b";
  --fa--fa: "\f21b\f21b";
}

.fa-otter {
  --fa: "\f700";
  --fa--fa: "\f700\f700";
}

.fa-dreidel {
  --fa: "\f792";
  --fa--fa: "\f792\f792";
}

.fa-person-dress {
  --fa: "\f182";
  --fa--fa: "\f182\f182";
}

.fa-female {
  --fa: "\f182";
  --fa--fa: "\f182\f182";
}

.fa-comment-dollar {
  --fa: "\f651";
  --fa--fa: "\f651\f651";
}

.fa-business-time {
  --fa: "\f64a";
  --fa--fa: "\f64a\f64a";
}

.fa-briefcase-clock {
  --fa: "\f64a";
  --fa--fa: "\f64a\f64a";
}

.fa-flower-tulip {
  --fa: "\f801";
  --fa--fa: "\f801\f801";
}

.fa-people-pants-simple {
  --fa: "\e21a";
  --fa--fa: "\e21a\e21a";
}

.fa-cloud-drizzle {
  --fa: "\f738";
  --fa--fa: "\f738\f738";
}

.fa-table-cells-large {
  --fa: "\f009";
  --fa--fa: "\f009\f009";
}

.fa-th-large {
  --fa: "\f009";
  --fa--fa: "\f009\f009";
}

.fa-book-tanakh {
  --fa: "\f827";
  --fa--fa: "\f827\f827";
}

.fa-tanakh {
  --fa: "\f827";
  --fa--fa: "\f827\f827";
}

.fa-solar-system {
  --fa: "\e02f";
  --fa--fa: "\e02f\e02f";
}

.fa-seal-question {
  --fa: "\e243";
  --fa--fa: "\e243\e243";
}

.fa-phone-volume {
  --fa: "\f2a0";
  --fa--fa: "\f2a0\f2a0";
}

.fa-volume-control-phone {
  --fa: "\f2a0";
  --fa--fa: "\f2a0\f2a0";
}

.fa-disc-drive {
  --fa: "\f8b5";
  --fa--fa: "\f8b5\f8b5";
}

.fa-hat-cowboy-side {
  --fa: "\f8c1";
  --fa--fa: "\f8c1\f8c1";
}

.fa-table-rows {
  --fa: "\e292";
  --fa--fa: "\e292\e292";
}

.fa-rows {
  --fa: "\e292";
  --fa--fa: "\e292\e292";
}

.fa-location-exclamation {
  --fa: "\f608";
  --fa--fa: "\f608\f608";
}

.fa-map-marker-exclamation {
  --fa: "\f608";
  --fa--fa: "\f608\f608";
}

.fa-face-fearful {
  --fa: "\e375";
  --fa--fa: "\e375\e375";
}

.fa-clipboard-user {
  --fa: "\f7f3";
  --fa--fa: "\f7f3\f7f3";
}

.fa-bus-school {
  --fa: "\f5dd";
  --fa--fa: "\f5dd\f5dd";
}

.fa-film-slash {
  --fa: "\e179";
  --fa--fa: "\e179\e179";
}

.fa-square-arrow-down-right {
  --fa: "\e262";
  --fa--fa: "\e262\e262";
}

.fa-book-sparkles {
  --fa: "\f6b8";
  --fa--fa: "\f6b8\f6b8";
}

.fa-book-spells {
  --fa: "\f6b8";
  --fa--fa: "\f6b8\f6b8";
}

.fa-washing-machine {
  --fa: "\f898";
  --fa--fa: "\f898\f898";
}

.fa-washer {
  --fa: "\f898";
  --fa--fa: "\f898\f898";
}

.fa-child {
  --fa: "\f1ae";
  --fa--fa: "\f1ae\f1ae";
}

.fa-lira-sign {
  --fa: "\f195";
  --fa--fa: "\f195\f195";
}

.fa-user-visor {
  --fa: "\e04c";
  --fa--fa: "\e04c\e04c";
}

.fa-file-plus-minus {
  --fa: "\e177";
  --fa--fa: "\e177\e177";
}

.fa-chess-clock-flip {
  --fa: "\f43e";
  --fa--fa: "\f43e\f43e";
}

.fa-chess-clock-alt {
  --fa: "\f43e";
  --fa--fa: "\f43e\f43e";
}

.fa-satellite {
  --fa: "\f7bf";
  --fa--fa: "\f7bf\f7bf";
}

.fa-truck-fire {
  --fa: "\e65a";
  --fa--fa: "\e65a\e65a";
}

.fa-plane-lock {
  --fa: "\e558";
  --fa--fa: "\e558\e558";
}

.fa-steering-wheel {
  --fa: "\f622";
  --fa--fa: "\f622\f622";
}

.fa-tag {
  --fa: "\f02b";
  --fa--fa: "\f02b\f02b";
}

.fa-stretcher {
  --fa: "\f825";
  --fa--fa: "\f825\f825";
}

.fa-book-section {
  --fa: "\e0c1";
  --fa--fa: "\e0c1\e0c1";
}

.fa-book-law {
  --fa: "\e0c1";
  --fa--fa: "\e0c1\e0c1";
}

.fa-inboxes {
  --fa: "\e1bb";
  --fa--fa: "\e1bb\e1bb";
}

.fa-coffee-bean {
  --fa: "\e13e";
  --fa--fa: "\e13e\e13e";
}

.fa-circle-yen {
  --fa: "\e5d0";
  --fa--fa: "\e5d0\e5d0";
}

.fa-brackets-curly {
  --fa: "\f7ea";
  --fa--fa: "\f7ea\f7ea";
}

.fa-ellipsis-stroke-vertical {
  --fa: "\f39c";
  --fa--fa: "\f39c\f39c";
}

.fa-ellipsis-v-alt {
  --fa: "\f39c";
  --fa--fa: "\f39c\f39c";
}

.fa-comment {
  --fa: "\f075";
  --fa--fa: "\f075\f075";
}

.fa-square-1 {
  --fa: "\e256";
  --fa--fa: "\e256\e256";
}

.fa-cake-candles {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd";
}

.fa-birthday-cake {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd";
}

.fa-cake {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd";
}

.fa-head-side {
  --fa: "\f6e9";
  --fa--fa: "\f6e9\f6e9";
}

.fa-truck-ladder {
  --fa: "\e657";
  --fa--fa: "\e657\e657";
}

.fa-envelope {
  --fa: "\f0e0";
  --fa--fa: "\f0e0\f0e0";
}

.fa-dolly-empty {
  --fa: "\f473";
  --fa--fa: "\f473\f473";
}

.fa-face-tissue {
  --fa: "\e39c";
  --fa--fa: "\e39c\e39c";
}

.fa-angles-up {
  --fa: "\f102";
  --fa--fa: "\f102\f102";
}

.fa-angle-double-up {
  --fa: "\f102";
  --fa--fa: "\f102\f102";
}

.fa-bin-recycle {
  --fa: "\e5f7";
  --fa--fa: "\e5f7\e5f7";
}

.fa-paperclip {
  --fa: "\f0c6";
  --fa--fa: "\f0c6\f0c6";
}

.fa-chart-line-down {
  --fa: "\f64d";
  --fa--fa: "\f64d\f64d";
}

.fa-arrow-right-to-city {
  --fa: "\e4b3";
  --fa--fa: "\e4b3\e4b3";
}

.fa-lock-a {
  --fa: "\e422";
  --fa--fa: "\e422\e422";
}

.fa-ribbon {
  --fa: "\f4d6";
  --fa--fa: "\f4d6\f4d6";
}

.fa-lungs {
  --fa: "\f604";
  --fa--fa: "\f604\f604";
}

.fa-person-pinball {
  --fa: "\e21d";
  --fa--fa: "\e21d\e21d";
}

.fa-arrow-up-9-1 {
  --fa: "\f887";
  --fa--fa: "\f887\f887";
}

.fa-sort-numeric-up-alt {
  --fa: "\f887";
  --fa--fa: "\f887\f887";
}

.fa-apple-core {
  --fa: "\e08f";
  --fa--fa: "\e08f\e08f";
}

.fa-circle-y {
  --fa: "\e12f";
  --fa--fa: "\e12f\e12f";
}

.fa-h6 {
  --fa: "\e413";
  --fa--fa: "\e413\e413";
}

.fa-litecoin-sign {
  --fa: "\e1d3";
  --fa--fa: "\e1d3\e1d3";
}

.fa-bottle-baby {
  --fa: "\e673";
  --fa--fa: "\e673\e673";
}

.fa-circle-small {
  --fa: "\e122";
  --fa--fa: "\e122\e122";
}

.fa-border-none {
  --fa: "\f850";
  --fa--fa: "\f850\f850";
}

.fa-arrow-turn-down-left {
  --fa: "\e2e1";
  --fa--fa: "\e2e1\e2e1";
}

.fa-circle-wifi-circle-wifi {
  --fa: "\e67e";
  --fa--fa: "\e67e\e67e";
}

.fa-circle-wifi-group {
  --fa: "\e67e";
  --fa--fa: "\e67e\e67e";
}

.fa-circle-nodes {
  --fa: "\e4e2";
  --fa--fa: "\e4e2\e4e2";
}

.fa-parachute-box {
  --fa: "\f4cd";
  --fa--fa: "\f4cd\f4cd";
}

.fa-reflect-horizontal {
  --fa: "\e664";
  --fa--fa: "\e664\e664";
}

.fa-message-medical {
  --fa: "\f7f4";
  --fa--fa: "\f7f4\f7f4";
}

.fa-comment-alt-medical {
  --fa: "\f7f4";
  --fa--fa: "\f7f4\f7f4";
}

.fa-rugby-ball {
  --fa: "\e3c6";
  --fa--fa: "\e3c6\e3c6";
}

.fa-comment-music {
  --fa: "\f8b0";
  --fa--fa: "\f8b0\f8b0";
}

.fa-indent {
  --fa: "\f03c";
  --fa--fa: "\f03c\f03c";
}

.fa-tree-deciduous {
  --fa: "\f400";
  --fa--fa: "\f400\f400";
}

.fa-tree-alt {
  --fa: "\f400";
  --fa--fa: "\f400\f400";
}

.fa-puzzle-piece-simple {
  --fa: "\e231";
  --fa--fa: "\e231\e231";
}

.fa-puzzle-piece-alt {
  --fa: "\e231";
  --fa--fa: "\e231\e231";
}

.fa-truck-field-un {
  --fa: "\e58e";
  --fa--fa: "\e58e\e58e";
}

.fa-nfc-trash {
  --fa: "\e1fd";
  --fa--fa: "\e1fd\e1fd";
}

.fa-hourglass {
  --fa: "\f254";
  --fa--fa: "\f254\f254";
}

.fa-hourglass-empty {
  --fa: "\f254";
  --fa--fa: "\f254\f254";
}

.fa-mountain {
  --fa: "\f6fc";
  --fa--fa: "\f6fc\f6fc";
}

.fa-file-xmark {
  --fa: "\f317";
  --fa--fa: "\f317\f317";
}

.fa-file-times {
  --fa: "\f317";
  --fa--fa: "\f317\f317";
}

.fa-house-heart {
  --fa: "\f4c9";
  --fa--fa: "\f4c9\f4c9";
}

.fa-home-heart {
  --fa: "\f4c9";
  --fa--fa: "\f4c9\f4c9";
}

.fa-house-chimney-blank {
  --fa: "\e3b0";
  --fa--fa: "\e3b0\e3b0";
}

.fa-meter-bolt {
  --fa: "\e1e9";
  --fa--fa: "\e1e9\e1e9";
}

.fa-user-doctor {
  --fa: "\f0f0";
  --fa--fa: "\f0f0\f0f0";
}

.fa-user-md {
  --fa: "\f0f0";
  --fa--fa: "\f0f0\f0f0";
}

.fa-slash-back {
  --fa: "\\";
  --fa--fa: "\\\\";
}

.fa-circle-info {
  --fa: "\f05a";
  --fa--fa: "\f05a\f05a";
}

.fa-info-circle {
  --fa: "\f05a";
  --fa--fa: "\f05a\f05a";
}

.fa-fishing-rod {
  --fa: "\e3a8";
  --fa--fa: "\e3a8\e3a8";
}

.fa-hammer-crash {
  --fa: "\e414";
  --fa--fa: "\e414\e414";
}

.fa-message-heart {
  --fa: "\e5c9";
  --fa--fa: "\e5c9\e5c9";
}

.fa-cloud-meatball {
  --fa: "\f73b";
  --fa--fa: "\f73b\f73b";
}

.fa-camera-polaroid {
  --fa: "\f8aa";
  --fa--fa: "\f8aa\f8aa";
}

.fa-camera {
  --fa: "\f030";
  --fa--fa: "\f030\f030";
}

.fa-camera-alt {
  --fa: "\f030";
  --fa--fa: "\f030\f030";
}

.fa-square-virus {
  --fa: "\e578";
  --fa--fa: "\e578\e578";
}

.fa-cart-arrow-up {
  --fa: "\e3ee";
  --fa--fa: "\e3ee\e3ee";
}

.fa-meteor {
  --fa: "\f753";
  --fa--fa: "\f753\f753";
}

.fa-car-on {
  --fa: "\e4dd";
  --fa--fa: "\e4dd\e4dd";
}

.fa-sleigh {
  --fa: "\f7cc";
  --fa--fa: "\f7cc\f7cc";
}

.fa-arrow-down-1-9 {
  --fa: "\f162";
  --fa--fa: "\f162\f162";
}

.fa-sort-numeric-asc {
  --fa: "\f162";
  --fa--fa: "\f162\f162";
}

.fa-sort-numeric-down {
  --fa: "\f162";
  --fa--fa: "\f162\f162";
}

.fa-buoy-mooring {
  --fa: "\e5b6";
  --fa--fa: "\e5b6\e5b6";
}

.fa-square-4 {
  --fa: "\e259";
  --fa--fa: "\e259\e259";
}

.fa-hand-holding-droplet {
  --fa: "\f4c1";
  --fa--fa: "\f4c1\f4c1";
}

.fa-hand-holding-water {
  --fa: "\f4c1";
  --fa--fa: "\f4c1\f4c1";
}

.fa-file-eps {
  --fa: "\e644";
  --fa--fa: "\e644\e644";
}

.fa-tricycle-adult {
  --fa: "\e5c4";
  --fa--fa: "\e5c4\e5c4";
}

.fa-waveform {
  --fa: "\f8f1";
  --fa--fa: "\f8f1\f8f1";
}

.fa-water {
  --fa: "\f773";
  --fa--fa: "\f773\f773";
}

.fa-star-sharp-half-stroke {
  --fa: "\e28d";
  --fa--fa: "\e28d\e28d";
}

.fa-star-sharp-half-alt {
  --fa: "\e28d";
  --fa--fa: "\e28d\e28d";
}

.fa-nfc-signal {
  --fa: "\e1fb";
  --fa--fa: "\e1fb\e1fb";
}

.fa-plane-prop {
  --fa: "\e22b";
  --fa--fa: "\e22b\e22b";
}

.fa-calendar-check {
  --fa: "\f274";
  --fa--fa: "\f274\f274";
}

.fa-clock-desk {
  --fa: "\e134";
  --fa--fa: "\e134\e134";
}

.fa-calendar-clock {
  --fa: "\e0d2";
  --fa--fa: "\e0d2\e0d2";
}

.fa-calendar-time {
  --fa: "\e0d2";
  --fa--fa: "\e0d2\e0d2";
}

.fa-braille {
  --fa: "\f2a1";
  --fa--fa: "\f2a1\f2a1";
}

.fa-prescription-bottle-medical {
  --fa: "\f486";
  --fa--fa: "\f486\f486";
}

.fa-prescription-bottle-alt {
  --fa: "\f486";
  --fa--fa: "\f486\f486";
}

.fa-plate-utensils {
  --fa: "\e43b";
  --fa--fa: "\e43b\e43b";
}

.fa-family-pants {
  --fa: "\e302";
  --fa--fa: "\e302\e302";
}

.fa-hose-reel {
  --fa: "\e41a";
  --fa--fa: "\e41a\e41a";
}

.fa-house-window {
  --fa: "\e3b3";
  --fa--fa: "\e3b3\e3b3";
}

.fa-landmark {
  --fa: "\f66f";
  --fa--fa: "\f66f\f66f";
}

.fa-truck {
  --fa: "\f0d1";
  --fa--fa: "\f0d1\f0d1";
}

.fa-music-magnifying-glass {
  --fa: "\e662";
  --fa--fa: "\e662\e662";
}

.fa-crosshairs {
  --fa: "\f05b";
  --fa--fa: "\f05b\f05b";
}

.fa-cloud-rainbow {
  --fa: "\f73e";
  --fa--fa: "\f73e\f73e";
}

.fa-person-cane {
  --fa: "\e53c";
  --fa--fa: "\e53c\e53c";
}

.fa-alien {
  --fa: "\f8f5";
  --fa--fa: "\f8f5\f8f5";
}

.fa-tent {
  --fa: "\e57d";
  --fa--fa: "\e57d\e57d";
}

.fa-laptop-binary {
  --fa: "\e5e7";
  --fa--fa: "\e5e7\e5e7";
}

.fa-vest-patches {
  --fa: "\e086";
  --fa--fa: "\e086\e086";
}

.fa-people-dress-simple {
  --fa: "\e218";
  --fa--fa: "\e218\e218";
}

.fa-check-double {
  --fa: "\f560";
  --fa--fa: "\f560\f560";
}

.fa-arrow-down-a-z {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d";
}

.fa-sort-alpha-asc {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d";
}

.fa-sort-alpha-down {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d";
}

.fa-bowling-ball-pin {
  --fa: "\e0c3";
  --fa--fa: "\e0c3\e0c3";
}

.fa-bell-school-slash {
  --fa: "\f5d6";
  --fa--fa: "\f5d6\f5d6";
}

.fa-plus-large {
  --fa: "\e59e";
  --fa--fa: "\e59e\e59e";
}

.fa-money-bill-wheat {
  --fa: "\e52a";
  --fa--fa: "\e52a\e52a";
}

.fa-camera-viewfinder {
  --fa: "\e0da";
  --fa--fa: "\e0da\e0da";
}

.fa-screenshot {
  --fa: "\e0da";
  --fa--fa: "\e0da\e0da";
}

.fa-message-music {
  --fa: "\f8af";
  --fa--fa: "\f8af\f8af";
}

.fa-comment-alt-music {
  --fa: "\f8af";
  --fa--fa: "\f8af\f8af";
}

.fa-car-building {
  --fa: "\f859";
  --fa--fa: "\f859\f859";
}

.fa-border-bottom-right {
  --fa: "\f854";
  --fa--fa: "\f854\f854";
}

.fa-border-style-alt {
  --fa: "\f854";
  --fa--fa: "\f854\f854";
}

.fa-octagon {
  --fa: "\f306";
  --fa--fa: "\f306\f306";
}

.fa-comment-arrow-up-right {
  --fa: "\e145";
  --fa--fa: "\e145\e145";
}

.fa-octagon-divide {
  --fa: "\e203";
  --fa--fa: "\e203\e203";
}

.fa-cookie {
  --fa: "\f563";
  --fa--fa: "\f563\f563";
}

.fa-arrow-rotate-left {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-arrow-left-rotate {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-arrow-rotate-back {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-arrow-rotate-backward {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-undo {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-tv-music {
  --fa: "\f8e6";
  --fa--fa: "\f8e6\f8e6";
}

.fa-hard-drive {
  --fa: "\f0a0";
  --fa--fa: "\f0a0\f0a0";
}

.fa-hdd {
  --fa: "\f0a0";
  --fa--fa: "\f0a0\f0a0";
}

.fa-reel {
  --fa: "\e238";
  --fa--fa: "\e238\e238";
}

.fa-face-grin-squint-tears {
  --fa: "\f586";
  --fa--fa: "\f586\f586";
}

.fa-grin-squint-tears {
  --fa: "\f586";
  --fa--fa: "\f586\f586";
}

.fa-dumbbell {
  --fa: "\f44b";
  --fa--fa: "\f44b\f44b";
}

.fa-rectangle-list {
  --fa: "\f022";
  --fa--fa: "\f022\f022";
}

.fa-list-alt {
  --fa: "\f022";
  --fa--fa: "\f022\f022";
}

.fa-tarp-droplet {
  --fa: "\e57c";
  --fa--fa: "\e57c\e57c";
}

.fa-alarm-exclamation {
  --fa: "\f843";
  --fa--fa: "\f843\f843";
}

.fa-house-medical-circle-check {
  --fa: "\e511";
  --fa--fa: "\e511\e511";
}

.fa-traffic-cone {
  --fa: "\f636";
  --fa--fa: "\f636\f636";
}

.fa-grate {
  --fa: "\e193";
  --fa--fa: "\e193\e193";
}

.fa-arrow-down-right {
  --fa: "\e093";
  --fa--fa: "\e093\e093";
}

.fa-person-skiing-nordic {
  --fa: "\f7ca";
  --fa--fa: "\f7ca\f7ca";
}

.fa-skiing-nordic {
  --fa: "\f7ca";
  --fa--fa: "\f7ca\f7ca";
}

.fa-calendar-plus {
  --fa: "\f271";
  --fa--fa: "\f271\f271";
}

.fa-person-from-portal {
  --fa: "\e023";
  --fa--fa: "\e023\e023";
}

.fa-portal-exit {
  --fa: "\e023";
  --fa--fa: "\e023\e023";
}

.fa-plane-arrival {
  --fa: "\f5af";
  --fa--fa: "\f5af\f5af";
}

.fa-cowbell-circle-plus {
  --fa: "\f8b4";
  --fa--fa: "\f8b4\f8b4";
}

.fa-cowbell-more {
  --fa: "\f8b4";
  --fa--fa: "\f8b4\f8b4";
}

.fa-circle-left {
  --fa: "\f359";
  --fa--fa: "\f359\f359";
}

.fa-arrow-alt-circle-left {
  --fa: "\f359";
  --fa--fa: "\f359\f359";
}

.fa-distribute-spacing-vertical {
  --fa: "\e366";
  --fa--fa: "\e366\e366";
}

.fa-signal-bars-fair {
  --fa: "\f692";
  --fa--fa: "\f692\f692";
}

.fa-signal-alt-2 {
  --fa: "\f692";
  --fa--fa: "\f692\f692";
}

.fa-sportsball {
  --fa: "\e44b";
  --fa--fa: "\e44b\e44b";
}

.fa-game-console-handheld-crank {
  --fa: "\e5b9";
  --fa--fa: "\e5b9\e5b9";
}

.fa-train-subway {
  --fa: "\f239";
  --fa--fa: "\f239\f239";
}

.fa-subway {
  --fa: "\f239";
  --fa--fa: "\f239\f239";
}

.fa-chart-gantt {
  --fa: "\e0e4";
  --fa--fa: "\e0e4\e0e4";
}

.fa-face-smile-upside-down {
  --fa: "\e395";
  --fa--fa: "\e395\e395";
}

.fa-ball-pile {
  --fa: "\f77e";
  --fa--fa: "\f77e\f77e";
}

.fa-badge-dollar {
  --fa: "\f645";
  --fa--fa: "\f645\f645";
}

.fa-money-bills-simple {
  --fa: "\e1f4";
  --fa--fa: "\e1f4\e1f4";
}

.fa-money-bills-alt {
  --fa: "\e1f4";
  --fa--fa: "\e1f4\e1f4";
}

.fa-list-timeline {
  --fa: "\e1d1";
  --fa--fa: "\e1d1\e1d1";
}

.fa-indian-rupee-sign {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc";
}

.fa-indian-rupee {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc";
}

.fa-inr {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc";
}

.fa-crop-simple {
  --fa: "\f565";
  --fa--fa: "\f565\f565";
}

.fa-crop-alt {
  --fa: "\f565";
  --fa--fa: "\f565\f565";
}

.fa-money-bill-1 {
  --fa: "\f3d1";
  --fa--fa: "\f3d1\f3d1";
}

.fa-money-bill-alt {
  --fa: "\f3d1";
  --fa--fa: "\f3d1\f3d1";
}

.fa-left-long {
  --fa: "\f30a";
  --fa--fa: "\f30a\f30a";
}

.fa-long-arrow-alt-left {
  --fa: "\f30a";
  --fa--fa: "\f30a\f30a";
}

.fa-keyboard-down {
  --fa: "\e1c2";
  --fa--fa: "\e1c2\e1c2";
}

.fa-circle-up-right {
  --fa: "\e129";
  --fa--fa: "\e129\e129";
}

.fa-cloud-bolt-moon {
  --fa: "\f76d";
  --fa--fa: "\f76d\f76d";
}

.fa-thunderstorm-moon {
  --fa: "\f76d";
  --fa--fa: "\f76d\f76d";
}

.fa-turn-left-up {
  --fa: "\e638";
  --fa--fa: "\e638\e638";
}

.fa-dna {
  --fa: "\f471";
  --fa--fa: "\f471\f471";
}

.fa-virus-slash {
  --fa: "\e075";
  --fa--fa: "\e075\e075";
}

.fa-bracket-round-right {
  --fa: "\)";
  --fa--fa: "\)\)";
}

.fa-circle-sterling {
  --fa: "\e5cf";
  --fa--fa: "\e5cf\e5cf";
}

.fa-circle-5 {
  --fa: "\e0f2";
  --fa--fa: "\e0f2\e0f2";
}

.fa-minus {
  --fa: "\f068";
  --fa--fa: "\f068\f068";
}

.fa-subtract {
  --fa: "\f068";
  --fa--fa: "\f068\f068";
}

.fa-fire-flame {
  --fa: "\f6df";
  --fa--fa: "\f6df\f6df";
}

.fa-flame {
  --fa: "\f6df";
  --fa--fa: "\f6df\f6df";
}

.fa-right-to-line {
  --fa: "\f34c";
  --fa--fa: "\f34c\f34c";
}

.fa-arrow-alt-to-right {
  --fa: "\f34c";
  --fa--fa: "\f34c\f34c";
}

.fa-gif {
  --fa: "\e190";
  --fa--fa: "\e190\e190";
}

.fa-chess {
  --fa: "\f439";
  --fa--fa: "\f439\f439";
}

.fa-trash-slash {
  --fa: "\e2b3";
  --fa--fa: "\e2b3\e2b3";
}

.fa-arrow-left-long {
  --fa: "\f177";
  --fa--fa: "\f177\f177";
}

.fa-long-arrow-left {
  --fa: "\f177";
  --fa--fa: "\f177\f177";
}

.fa-plug-circle-check {
  --fa: "\e55c";
  --fa--fa: "\e55c\e55c";
}

.fa-font-case {
  --fa: "\f866";
  --fa--fa: "\f866\f866";
}

.fa-street-view {
  --fa: "\f21d";
  --fa--fa: "\f21d\f21d";
}

.fa-arrow-down-left {
  --fa: "\e091";
  --fa--fa: "\e091\e091";
}

.fa-franc-sign {
  --fa: "\e18f";
  --fa--fa: "\e18f\e18f";
}

.fa-flask-round-poison {
  --fa: "\f6e0";
  --fa--fa: "\f6e0\f6e0";
}

.fa-flask-poison {
  --fa: "\f6e0";
  --fa--fa: "\f6e0\f6e0";
}

.fa-volume-off {
  --fa: "\f026";
  --fa--fa: "\f026\f026";
}

.fa-book-circle-arrow-right {
  --fa: "\e0bc";
  --fa--fa: "\e0bc\e0bc";
}

.fa-chart-user {
  --fa: "\f6a3";
  --fa--fa: "\f6a3\f6a3";
}

.fa-user-chart {
  --fa: "\f6a3";
  --fa--fa: "\f6a3\f6a3";
}

.fa-hands-asl-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-american-sign-language-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-asl-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-hands-american-sign-language-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-presentation-screen {
  --fa: "\f685";
  --fa--fa: "\f685\f685";
}

.fa-presentation {
  --fa: "\f685";
  --fa--fa: "\f685\f685";
}

.fa-circle-bolt {
  --fa: "\e0fe";
  --fa--fa: "\e0fe\e0fe";
}

.fa-face-smile-halo {
  --fa: "\e38f";
  --fa--fa: "\e38f\e38f";
}

.fa-cart-circle-arrow-down {
  --fa: "\e3ef";
  --fa--fa: "\e3ef\e3ef";
}

.fa-house-person-return {
  --fa: "\e011";
  --fa--fa: "\e011\e011";
}

.fa-house-person-arrive {
  --fa: "\e011";
  --fa--fa: "\e011\e011";
}

.fa-house-return {
  --fa: "\e011";
  --fa--fa: "\e011\e011";
}

.fa-message-xmark {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab";
}

.fa-comment-alt-times {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab";
}

.fa-message-times {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab";
}

.fa-file-certificate {
  --fa: "\f5f3";
  --fa--fa: "\f5f3\f5f3";
}

.fa-file-award {
  --fa: "\f5f3";
  --fa--fa: "\f5f3\f5f3";
}

.fa-user-doctor-hair-long {
  --fa: "\e459";
  --fa--fa: "\e459\e459";
}

.fa-camera-security {
  --fa: "\f8fe";
  --fa--fa: "\f8fe\f8fe";
}

.fa-camera-home {
  --fa: "\f8fe";
  --fa--fa: "\f8fe\f8fe";
}

.fa-gear {
  --fa: "\f013";
  --fa--fa: "\f013\f013";
}

.fa-cog {
  --fa: "\f013";
  --fa--fa: "\f013\f013";
}

.fa-droplet-slash {
  --fa: "\f5c7";
  --fa--fa: "\f5c7\f5c7";
}

.fa-tint-slash {
  --fa: "\f5c7";
  --fa--fa: "\f5c7\f5c7";
}

.fa-book-heart {
  --fa: "\f499";
  --fa--fa: "\f499\f499";
}

.fa-mosque {
  --fa: "\f678";
  --fa--fa: "\f678\f678";
}

.fa-duck {
  --fa: "\f6d8";
  --fa--fa: "\f6d8\f6d8";
}

.fa-mosquito {
  --fa: "\e52b";
  --fa--fa: "\e52b\e52b";
}

.fa-star-of-david {
  --fa: "\f69a";
  --fa--fa: "\f69a\f69a";
}

.fa-flag-swallowtail {
  --fa: "\f74c";
  --fa--fa: "\f74c\f74c";
}

.fa-flag-alt {
  --fa: "\f74c";
  --fa--fa: "\f74c\f74c";
}

.fa-person-military-rifle {
  --fa: "\e54b";
  --fa--fa: "\e54b\e54b";
}

.fa-car-garage {
  --fa: "\f5e2";
  --fa--fa: "\f5e2\f5e2";
}

.fa-cart-shopping {
  --fa: "\f07a";
  --fa--fa: "\f07a\f07a";
}

.fa-shopping-cart {
  --fa: "\f07a";
  --fa--fa: "\f07a\f07a";
}

.fa-book-font {
  --fa: "\e0bf";
  --fa--fa: "\e0bf\e0bf";
}

.fa-shield-plus {
  --fa: "\e24a";
  --fa--fa: "\e24a\e24a";
}

.fa-vials {
  --fa: "\f493";
  --fa--fa: "\f493\f493";
}

.fa-eye-dropper-full {
  --fa: "\e172";
  --fa--fa: "\e172\e172";
}

.fa-distribute-spacing-horizontal {
  --fa: "\e365";
  --fa--fa: "\e365\e365";
}

.fa-tablet-rugged {
  --fa: "\f48f";
  --fa--fa: "\f48f\f48f";
}

.fa-temperature-snow {
  --fa: "\f768";
  --fa--fa: "\f768\f768";
}

.fa-temperature-frigid {
  --fa: "\f768";
  --fa--fa: "\f768\f768";
}

.fa-moped {
  --fa: "\e3b9";
  --fa--fa: "\e3b9\e3b9";
}

.fa-face-smile-plus {
  --fa: "\f5b9";
  --fa--fa: "\f5b9\f5b9";
}

.fa-smile-plus {
  --fa: "\f5b9";
  --fa--fa: "\f5b9\f5b9";
}

.fa-radio-tuner {
  --fa: "\f8d8";
  --fa--fa: "\f8d8\f8d8";
}

.fa-radio-alt {
  --fa: "\f8d8";
  --fa--fa: "\f8d8\f8d8";
}

.fa-face-swear {
  --fa: "\e399";
  --fa--fa: "\e399\e399";
}

.fa-water-arrow-down {
  --fa: "\f774";
  --fa--fa: "\f774\f774";
}

.fa-water-lower {
  --fa: "\f774";
  --fa--fa: "\f774\f774";
}

.fa-scanner-touchscreen {
  --fa: "\f48a";
  --fa--fa: "\f48a\f48a";
}

.fa-circle-7 {
  --fa: "\e0f4";
  --fa--fa: "\e0f4\e0f4";
}

.fa-plug-circle-plus {
  --fa: "\e55f";
  --fa--fa: "\e55f\e55f";
}

.fa-person-ski-jumping {
  --fa: "\f7c7";
  --fa--fa: "\f7c7\f7c7";
}

.fa-ski-jump {
  --fa: "\f7c7";
  --fa--fa: "\f7c7\f7c7";
}

.fa-place-of-worship {
  --fa: "\f67f";
  --fa--fa: "\f67f\f67f";
}

.fa-water-arrow-up {
  --fa: "\f775";
  --fa--fa: "\f775\f775";
}

.fa-water-rise {
  --fa: "\f775";
  --fa--fa: "\f775\f775";
}

.fa-waveform-lines {
  --fa: "\f8f2";
  --fa--fa: "\f8f2\f8f2";
}

.fa-waveform-path {
  --fa: "\f8f2";
  --fa--fa: "\f8f2\f8f2";
}

.fa-split {
  --fa: "\e254";
  --fa--fa: "\e254\e254";
}

.fa-film-canister {
  --fa: "\f8b7";
  --fa--fa: "\f8b7\f8b7";
}

.fa-film-cannister {
  --fa: "\f8b7";
  --fa--fa: "\f8b7\f8b7";
}

.fa-folder-xmark {
  --fa: "\f65f";
  --fa--fa: "\f65f\f65f";
}

.fa-folder-times {
  --fa: "\f65f";
  --fa--fa: "\f65f\f65f";
}

.fa-toilet-paper-blank {
  --fa: "\f71f";
  --fa--fa: "\f71f\f71f";
}

.fa-toilet-paper-alt {
  --fa: "\f71f";
  --fa--fa: "\f71f\f71f";
}

.fa-tablet-screen {
  --fa: "\f3fc";
  --fa--fa: "\f3fc\f3fc";
}

.fa-tablet-android-alt {
  --fa: "\f3fc";
  --fa--fa: "\f3fc\f3fc";
}

.fa-hexagon-vertical-nft-slanted {
  --fa: "\e506";
  --fa--fa: "\e506\e506";
}

.fa-folder-music {
  --fa: "\e18d";
  --fa--fa: "\e18d\e18d";
}

.fa-display-medical {
  --fa: "\e166";
  --fa--fa: "\e166\e166";
}

.fa-desktop-medical {
  --fa: "\e166";
  --fa--fa: "\e166\e166";
}

.fa-share-all {
  --fa: "\f367";
  --fa--fa: "\f367\f367";
}

.fa-peapod {
  --fa: "\e31c";
  --fa--fa: "\e31c\e31c";
}

.fa-chess-clock {
  --fa: "\f43d";
  --fa--fa: "\f43d\f43d";
}

.fa-axe {
  --fa: "\f6b2";
  --fa--fa: "\f6b2\f6b2";
}

.fa-square-d {
  --fa: "\e268";
  --fa--fa: "\e268\e268";
}

.fa-grip-vertical {
  --fa: "\f58e";
  --fa--fa: "\f58e\f58e";
}

.fa-mobile-signal-out {
  --fa: "\e1f0";
  --fa--fa: "\e1f0\e1f0";
}

.fa-hexagon-nodes {
  --fa: "\e699";
  --fa--fa: "\e699\e699";
}

.fa-arrow-turn-up {
  --fa: "\f148";
  --fa--fa: "\f148\f148";
}

.fa-level-up {
  --fa: "\f148";
  --fa--fa: "\f148\f148";
}

.fa-u {
  --fa: "U";
  --fa--fa: "UU";
}

.fa-arrow-up-from-dotted-line {
  --fa: "\e09b";
  --fa--fa: "\e09b\e09b";
}

.fa-square-root-variable {
  --fa: "\f698";
  --fa--fa: "\f698\f698";
}

.fa-square-root-alt {
  --fa: "\f698";
  --fa--fa: "\f698\f698";
}

.fa-light-switch-on {
  --fa: "\e019";
  --fa--fa: "\e019\e019";
}

.fa-arrow-down-arrow-up {
  --fa: "\f883";
  --fa--fa: "\f883\f883";
}

.fa-sort-alt {
  --fa: "\f883";
  --fa--fa: "\f883\f883";
}

.fa-raindrops {
  --fa: "\f75c";
  --fa--fa: "\f75c\f75c";
}

.fa-dash {
  --fa: "\e404";
  --fa--fa: "\e404\e404";
}

.fa-minus-large {
  --fa: "\e404";
  --fa--fa: "\e404\e404";
}

.fa-clock {
  --fa: "\f017";
  --fa--fa: "\f017\f017";
}

.fa-clock-four {
  --fa: "\f017";
  --fa--fa: "\f017\f017";
}

.fa-input-numeric {
  --fa: "\e1bd";
  --fa--fa: "\e1bd\e1bd";
}

.fa-truck-tow {
  --fa: "\e2b8";
  --fa--fa: "\e2b8\e2b8";
}

.fa-backward-step {
  --fa: "\f048";
  --fa--fa: "\f048\f048";
}

.fa-step-backward {
  --fa: "\f048";
  --fa--fa: "\f048\f048";
}

.fa-pallet {
  --fa: "\f482";
  --fa--fa: "\f482\f482";
}

.fa-car-bolt {
  --fa: "\e341";
  --fa--fa: "\e341\e341";
}

.fa-arrows-maximize {
  --fa: "\f31d";
  --fa--fa: "\f31d\f31d";
}

.fa-expand-arrows {
  --fa: "\f31d";
  --fa--fa: "\f31d\f31d";
}

.fa-faucet {
  --fa: "\e005";
  --fa--fa: "\e005\e005";
}

.fa-cloud-sleet {
  --fa: "\f741";
  --fa--fa: "\f741\f741";
}

.fa-lamp-street {
  --fa: "\e1c5";
  --fa--fa: "\e1c5\e1c5";
}

.fa-list-radio {
  --fa: "\e1d0";
  --fa--fa: "\e1d0\e1d0";
}

.fa-pen-nib-slash {
  --fa: "\e4a1";
  --fa--fa: "\e4a1\e4a1";
}

.fa-baseball-bat-ball {
  --fa: "\f432";
  --fa--fa: "\f432\f432";
}

.fa-square-up-left {
  --fa: "\e282";
  --fa--fa: "\e282\e282";
}

.fa-overline {
  --fa: "\f876";
  --fa--fa: "\f876\f876";
}

.fa-s {
  --fa: "S";
  --fa--fa: "SS";
}

.fa-timeline {
  --fa: "\e29c";
  --fa--fa: "\e29c\e29c";
}

.fa-keyboard {
  --fa: "\f11c";
  --fa--fa: "\f11c\f11c";
}

.fa-arrows-from-dotted-line {
  --fa: "\e0a3";
  --fa--fa: "\e0a3\e0a3";
}

.fa-usb-drive {
  --fa: "\f8e9";
  --fa--fa: "\f8e9\f8e9";
}

.fa-ballot {
  --fa: "\f732";
  --fa--fa: "\f732\f732";
}

.fa-caret-down {
  --fa: "\f0d7";
  --fa--fa: "\f0d7\f0d7";
}

.fa-location-dot-slash {
  --fa: "\f605";
  --fa--fa: "\f605\f605";
}

.fa-map-marker-alt-slash {
  --fa: "\f605";
  --fa--fa: "\f605\f605";
}

.fa-cards {
  --fa: "\e3ed";
  --fa--fa: "\e3ed\e3ed";
}

.fa-house-chimney-medical {
  --fa: "\f7f2";
  --fa--fa: "\f7f2\f7f2";
}

.fa-clinic-medical {
  --fa: "\f7f2";
  --fa--fa: "\f7f2\f7f2";
}

.fa-boxing-glove {
  --fa: "\f438";
  --fa--fa: "\f438\f438";
}

.fa-glove-boxing {
  --fa: "\f438";
  --fa--fa: "\f438\f438";
}

.fa-temperature-three-quarters {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-temperature-3 {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-thermometer-3 {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-thermometer-three-quarters {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-bell-school {
  --fa: "\f5d5";
  --fa--fa: "\f5d5\f5d5";
}

.fa-mobile-screen {
  --fa: "\f3cf";
  --fa--fa: "\f3cf\f3cf";
}

.fa-mobile-android-alt {
  --fa: "\f3cf";
  --fa--fa: "\f3cf\f3cf";
}

.fa-plane-up {
  --fa: "\e22d";
  --fa--fa: "\e22d\e22d";
}

.fa-folder-heart {
  --fa: "\e189";
  --fa--fa: "\e189\e189";
}

.fa-circle-location-arrow {
  --fa: "\f602";
  --fa--fa: "\f602\f602";
}

.fa-location-circle {
  --fa: "\f602";
  --fa--fa: "\f602\f602";
}

.fa-face-head-bandage {
  --fa: "\e37a";
  --fa--fa: "\e37a\e37a";
}

.fa-sushi-roll {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b";
}

.fa-maki-roll {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b";
}

.fa-makizushi {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b";
}

.fa-car-bump {
  --fa: "\f5e0";
  --fa--fa: "\f5e0\f5e0";
}

.fa-piggy-bank {
  --fa: "\f4d3";
  --fa--fa: "\f4d3\f4d3";
}

.fa-racquet {
  --fa: "\f45a";
  --fa--fa: "\f45a\f45a";
}

.fa-car-mirrors {
  --fa: "\e343";
  --fa--fa: "\e343\e343";
}

.fa-industry-windows {
  --fa: "\f3b3";
  --fa--fa: "\f3b3\f3b3";
}

.fa-industry-alt {
  --fa: "\f3b3";
  --fa--fa: "\f3b3\f3b3";
}

.fa-bolt-auto {
  --fa: "\e0b6";
  --fa--fa: "\e0b6\e0b6";
}

.fa-battery-half {
  --fa: "\f242";
  --fa--fa: "\f242\f242";
}

.fa-battery-3 {
  --fa: "\f242";
  --fa--fa: "\f242\f242";
}

.fa-flux-capacitor {
  --fa: "\f8ba";
  --fa--fa: "\f8ba\f8ba";
}

.fa-mountain-city {
  --fa: "\e52e";
  --fa--fa: "\e52e\e52e";
}

.fa-coins {
  --fa: "\f51e";
  --fa--fa: "\f51e\f51e";
}

.fa-honey-pot {
  --fa: "\e418";
  --fa--fa: "\e418\e418";
}

.fa-olive {
  --fa: "\e316";
  --fa--fa: "\e316\e316";
}

.fa-khanda {
  --fa: "\f66d";
  --fa--fa: "\f66d\f66d";
}

.fa-filter-list {
  --fa: "\e17c";
  --fa--fa: "\e17c\e17c";
}

.fa-outlet {
  --fa: "\e01c";
  --fa--fa: "\e01c\e01c";
}

.fa-sliders {
  --fa: "\f1de";
  --fa--fa: "\f1de\f1de";
}

.fa-sliders-h {
  --fa: "\f1de";
  --fa--fa: "\f1de\f1de";
}

.fa-cauldron {
  --fa: "\f6bf";
  --fa--fa: "\f6bf\f6bf";
}

.fa-people {
  --fa: "\e216";
  --fa--fa: "\e216\e216";
}

.fa-folder-tree {
  --fa: "\f802";
  --fa--fa: "\f802\f802";
}

.fa-network-wired {
  --fa: "\f6ff";
  --fa--fa: "\f6ff\f6ff";
}

.fa-croissant {
  --fa: "\f7f6";
  --fa--fa: "\f7f6\f7f6";
}

.fa-map-pin {
  --fa: "\f276";
  --fa--fa: "\f276\f276";
}

.fa-hamsa {
  --fa: "\f665";
  --fa--fa: "\f665\f665";
}

.fa-cent-sign {
  --fa: "\e3f5";
  --fa--fa: "\e3f5\e3f5";
}

.fa-swords-laser {
  --fa: "\e03d";
  --fa--fa: "\e03d\e03d";
}

.fa-flask {
  --fa: "\f0c3";
  --fa--fa: "\f0c3\f0c3";
}

.fa-person-pregnant {
  --fa: "\e31e";
  --fa--fa: "\e31e\e31e";
}

.fa-square-u {
  --fa: "\e281";
  --fa--fa: "\e281\e281";
}

.fa-wand-sparkles {
  --fa: "\f72b";
  --fa--fa: "\f72b\f72b";
}

.fa-router {
  --fa: "\f8da";
  --fa--fa: "\f8da\f8da";
}

.fa-ellipsis-vertical {
  --fa: "\f142";
  --fa--fa: "\f142\f142";
}

.fa-ellipsis-v {
  --fa: "\f142";
  --fa--fa: "\f142\f142";
}

.fa-sword-laser-alt {
  --fa: "\e03c";
  --fa--fa: "\e03c\e03c";
}

.fa-ticket {
  --fa: "\f145";
  --fa--fa: "\f145\f145";
}

.fa-power-off {
  --fa: "\f011";
  --fa--fa: "\f011\f011";
}

.fa-coin {
  --fa: "\f85c";
  --fa--fa: "\f85c\f85c";
}

.fa-laptop-slash {
  --fa: "\e1c7";
  --fa--fa: "\e1c7\e1c7";
}

.fa-right-long {
  --fa: "\f30b";
  --fa--fa: "\f30b\f30b";
}

.fa-long-arrow-alt-right {
  --fa: "\f30b";
  --fa--fa: "\f30b\f30b";
}

.fa-circle-b {
  --fa: "\e0fd";
  --fa--fa: "\e0fd\e0fd";
}

.fa-person-dress-simple {
  --fa: "\e21c";
  --fa--fa: "\e21c\e21c";
}

.fa-pipe-collar {
  --fa: "\e437";
  --fa--fa: "\e437\e437";
}

.fa-lights-holiday {
  --fa: "\f7b2";
  --fa--fa: "\f7b2\f7b2";
}

.fa-citrus {
  --fa: "\e2f4";
  --fa--fa: "\e2f4\e2f4";
}

.fa-flag-usa {
  --fa: "\f74d";
  --fa--fa: "\f74d\f74d";
}

.fa-laptop-file {
  --fa: "\e51d";
  --fa--fa: "\e51d\e51d";
}

.fa-tty {
  --fa: "\f1e4";
  --fa--fa: "\f1e4\f1e4";
}

.fa-teletype {
  --fa: "\f1e4";
  --fa--fa: "\f1e4\f1e4";
}

.fa-chart-tree-map {
  --fa: "\e0ea";
  --fa--fa: "\e0ea\e0ea";
}

.fa-diagram-next {
  --fa: "\e476";
  --fa--fa: "\e476\e476";
}

.fa-person-rifle {
  --fa: "\e54e";
  --fa--fa: "\e54e\e54e";
}

.fa-clock-five-thirty {
  --fa: "\e34a";
  --fa--fa: "\e34a\e34a";
}

.fa-pipe-valve {
  --fa: "\e439";
  --fa--fa: "\e439\e439";
}

.fa-lightbulb-message {
  --fa: "\e687";
  --fa--fa: "\e687\e687";
}

.fa-arrow-up-from-arc {
  --fa: "\e4b4";
  --fa--fa: "\e4b4\e4b4";
}

.fa-face-spiral-eyes {
  --fa: "\e485";
  --fa--fa: "\e485\e485";
}

.fa-compress-wide {
  --fa: "\f326";
  --fa--fa: "\f326\f326";
}

.fa-circle-phone-hangup {
  --fa: "\e11d";
  --fa--fa: "\e11d\e11d";
}

.fa-phone-circle-down {
  --fa: "\e11d";
  --fa--fa: "\e11d\e11d";
}

.fa-gear-complex-code {
  --fa: "\e5eb";
  --fa--fa: "\e5eb\e5eb";
}

.fa-house-medical-circle-exclamation {
  --fa: "\e512";
  --fa--fa: "\e512\e512";
}

.fa-badminton {
  --fa: "\e33a";
  --fa--fa: "\e33a\e33a";
}

.fa-closed-captioning {
  --fa: "\f20a";
  --fa--fa: "\f20a\f20a";
}

.fa-person-hiking {
  --fa: "\f6ec";
  --fa--fa: "\f6ec\f6ec";
}

.fa-hiking {
  --fa: "\f6ec";
  --fa--fa: "\f6ec\f6ec";
}

.fa-right-from-line {
  --fa: "\f347";
  --fa--fa: "\f347\f347";
}

.fa-arrow-alt-from-left {
  --fa: "\f347";
  --fa--fa: "\f347\f347";
}

.fa-venus-double {
  --fa: "\f226";
  --fa--fa: "\f226\f226";
}

.fa-images {
  --fa: "\f302";
  --fa--fa: "\f302\f302";
}

.fa-calculator {
  --fa: "\f1ec";
  --fa--fa: "\f1ec\f1ec";
}

.fa-shuttlecock {
  --fa: "\f45b";
  --fa--fa: "\f45b\f45b";
}

.fa-user-hair {
  --fa: "\e45a";
  --fa--fa: "\e45a\e45a";
}

.fa-eye-evil {
  --fa: "\f6db";
  --fa--fa: "\f6db\f6db";
}

.fa-people-pulling {
  --fa: "\e535";
  --fa--fa: "\e535\e535";
}

.fa-n {
  --fa: "N";
  --fa--fa: "NN";
}

.fa-swap {
  --fa: "\e609";
  --fa--fa: "\e609\e609";
}

.fa-garage {
  --fa: "\e009";
  --fa--fa: "\e009\e009";
}

.fa-cable-car {
  --fa: "\f7da";
  --fa--fa: "\f7da\f7da";
}

.fa-tram {
  --fa: "\f7da";
  --fa--fa: "\f7da\f7da";
}

.fa-shovel-snow {
  --fa: "\f7c3";
  --fa--fa: "\f7c3\f7c3";
}

.fa-cloud-rain {
  --fa: "\f73d";
  --fa--fa: "\f73d\f73d";
}

.fa-face-lying {
  --fa: "\e37e";
  --fa--fa: "\e37e\e37e";
}

.fa-sprinkler {
  --fa: "\e035";
  --fa--fa: "\e035\e035";
}

.fa-building-circle-xmark {
  --fa: "\e4d4";
  --fa--fa: "\e4d4\e4d4";
}

.fa-person-sledding {
  --fa: "\f7cb";
  --fa--fa: "\f7cb\f7cb";
}

.fa-sledding {
  --fa: "\f7cb";
  --fa--fa: "\f7cb\f7cb";
}

.fa-game-console-handheld {
  --fa: "\f8bb";
  --fa--fa: "\f8bb\f8bb";
}

.fa-ship {
  --fa: "\f21a";
  --fa--fa: "\f21a\f21a";
}

.fa-clock-six-thirty {
  --fa: "\e353";
  --fa--fa: "\e353\e353";
}

.fa-battery-slash {
  --fa: "\f377";
  --fa--fa: "\f377\f377";
}

.fa-tugrik-sign {
  --fa: "\e2ba";
  --fa--fa: "\e2ba\e2ba";
}

.fa-arrows-down-to-line {
  --fa: "\e4b8";
  --fa--fa: "\e4b8\e4b8";
}

.fa-download {
  --fa: "\f019";
  --fa--fa: "\f019\f019";
}

.fa-angles-up-down {
  --fa: "\e60d";
  --fa--fa: "\e60d\e60d";
}

.fa-shelves {
  --fa: "\f480";
  --fa--fa: "\f480\f480";
}

.fa-inventory {
  --fa: "\f480";
  --fa--fa: "\f480\f480";
}

.fa-cloud-snow {
  --fa: "\f742";
  --fa--fa: "\f742\f742";
}

.fa-face-grin {
  --fa: "\f580";
  --fa--fa: "\f580\f580";
}

.fa-grin {
  --fa: "\f580";
  --fa--fa: "\f580\f580";
}

.fa-delete-left {
  --fa: "\f55a";
  --fa--fa: "\f55a\f55a";
}

.fa-backspace {
  --fa: "\f55a";
  --fa--fa: "\f55a\f55a";
}

.fa-oven {
  --fa: "\e01d";
  --fa--fa: "\e01d\e01d";
}

.fa-cloud-binary {
  --fa: "\e601";
  --fa--fa: "\e601\e601";
}

.fa-eye-dropper {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb";
}

.fa-eye-dropper-empty {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb";
}

.fa-eyedropper {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb";
}

.fa-comment-captions {
  --fa: "\e146";
  --fa--fa: "\e146\e146";
}

.fa-comments-question {
  --fa: "\e14e";
  --fa--fa: "\e14e\e14e";
}

.fa-scribble {
  --fa: "\e23f";
  --fa--fa: "\e23f\e23f";
}

.fa-rotate-exclamation {
  --fa: "\e23c";
  --fa--fa: "\e23c\e23c";
}

.fa-file-circle-check {
  --fa: "\e5a0";
  --fa--fa: "\e5a0\e5a0";
}

.fa-glass {
  --fa: "\f804";
  --fa--fa: "\f804\f804";
}

.fa-loader {
  --fa: "\e1d4";
  --fa--fa: "\e1d4\e1d4";
}

.fa-forward {
  --fa: "\f04e";
  --fa--fa: "\f04e\f04e";
}

.fa-user-pilot {
  --fa: "\e2c0";
  --fa--fa: "\e2c0\e2c0";
}

.fa-mobile {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce";
}

.fa-mobile-android {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce";
}

.fa-mobile-phone {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce";
}

.fa-code-pull-request-closed {
  --fa: "\e3f9";
  --fa--fa: "\e3f9\e3f9";
}

.fa-face-meh {
  --fa: "\f11a";
  --fa--fa: "\f11a\f11a";
}

.fa-meh {
  --fa: "\f11a";
  --fa--fa: "\f11a\f11a";
}

.fa-align-center {
  --fa: "\f037";
  --fa--fa: "\f037\f037";
}

.fa-book-skull {
  --fa: "\f6b7";
  --fa--fa: "\f6b7\f6b7";
}

.fa-book-dead {
  --fa: "\f6b7";
  --fa--fa: "\f6b7\f6b7";
}

.fa-id-card {
  --fa: "\f2c2";
  --fa--fa: "\f2c2\f2c2";
}

.fa-drivers-license {
  --fa: "\f2c2";
  --fa--fa: "\f2c2\f2c2";
}

.fa-face-dotted {
  --fa: "\e47f";
  --fa--fa: "\e47f\e47f";
}

.fa-face-worried {
  --fa: "\e3a3";
  --fa--fa: "\e3a3\e3a3";
}

.fa-outdent {
  --fa: "\f03b";
  --fa--fa: "\f03b\f03b";
}

.fa-dedent {
  --fa: "\f03b";
  --fa--fa: "\f03b\f03b";
}

.fa-court-sport {
  --fa: "\e643";
  --fa--fa: "\e643\e643";
}

.fa-heart-circle-exclamation {
  --fa: "\e4fe";
  --fa--fa: "\e4fe\e4fe";
}

.fa-house {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-home {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-home-alt {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-home-lg-alt {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-vector-circle {
  --fa: "\e2c6";
  --fa--fa: "\e2c6\e2c6";
}

.fa-car-circle-bolt {
  --fa: "\e342";
  --fa--fa: "\e342\e342";
}

.fa-calendar-week {
  --fa: "\f784";
  --fa--fa: "\f784\f784";
}

.fa-flying-disc {
  --fa: "\e3a9";
  --fa--fa: "\e3a9\e3a9";
}

.fa-laptop-medical {
  --fa: "\f812";
  --fa--fa: "\f812\f812";
}

.fa-square-down-right {
  --fa: "\e26c";
  --fa--fa: "\e26c\e26c";
}

.fa-b {
  --fa: "B";
  --fa--fa: "BB";
}

.fa-seat-airline {
  --fa: "\e244";
  --fa--fa: "\e244\e244";
}

.fa-moon-over-sun {
  --fa: "\f74a";
  --fa--fa: "\f74a\f74a";
}

.fa-eclipse-alt {
  --fa: "\f74a";
  --fa--fa: "\f74a\f74a";
}

.fa-pipe {
  --fa: "\|";
  --fa--fa: "\|\|";
}

.fa-file-medical {
  --fa: "\f477";
  --fa--fa: "\f477\f477";
}

.fa-potato {
  --fa: "\e440";
  --fa--fa: "\e440\e440";
}

.fa-dice-one {
  --fa: "\f525";
  --fa--fa: "\f525\f525";
}

.fa-circle-a {
  --fa: "\e0f7";
  --fa--fa: "\e0f7\e0f7";
}

.fa-helmet-battle {
  --fa: "\f6eb";
  --fa--fa: "\f6eb\f6eb";
}

.fa-butter {
  --fa: "\e3e4";
  --fa--fa: "\e3e4\e3e4";
}

.fa-blanket-fire {
  --fa: "\e3da";
  --fa--fa: "\e3da\e3da";
}

.fa-kiwi-bird {
  --fa: "\f535";
  --fa--fa: "\f535\f535";
}

.fa-castle {
  --fa: "\e0de";
  --fa--fa: "\e0de\e0de";
}

.fa-golf-club {
  --fa: "\f451";
  --fa--fa: "\f451\f451";
}

.fa-arrow-right-arrow-left {
  --fa: "\f0ec";
  --fa--fa: "\f0ec\f0ec";
}

.fa-exchange {
  --fa: "\f0ec";
  --fa--fa: "\f0ec\f0ec";
}

.fa-rotate-right {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9";
}

.fa-redo-alt {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9";
}

.fa-rotate-forward {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9";
}

.fa-utensils {
  --fa: "\f2e7";
  --fa--fa: "\f2e7\f2e7";
}

.fa-cutlery {
  --fa: "\f2e7";
  --fa--fa: "\f2e7\f2e7";
}

.fa-arrow-up-wide-short {
  --fa: "\f161";
  --fa--fa: "\f161\f161";
}

.fa-sort-amount-up {
  --fa: "\f161";
  --fa--fa: "\f161\f161";
}

.fa-chart-pie-simple-circle-dollar {
  --fa: "\e605";
  --fa--fa: "\e605\e605";
}

.fa-balloons {
  --fa: "\e2e4";
  --fa--fa: "\e2e4\e2e4";
}

.fa-mill-sign {
  --fa: "\e1ed";
  --fa--fa: "\e1ed\e1ed";
}

.fa-bowl-rice {
  --fa: "\e2eb";
  --fa--fa: "\e2eb\e2eb";
}

.fa-timeline-arrow {
  --fa: "\e29d";
  --fa--fa: "\e29d\e29d";
}

.fa-skull {
  --fa: "\f54c";
  --fa--fa: "\f54c\f54c";
}

.fa-game-board-simple {
  --fa: "\f868";
  --fa--fa: "\f868\f868";
}

.fa-game-board-alt {
  --fa: "\f868";
  --fa--fa: "\f868\f868";
}

.fa-circle-video {
  --fa: "\e12b";
  --fa--fa: "\e12b\e12b";
}

.fa-video-circle {
  --fa: "\e12b";
  --fa--fa: "\e12b\e12b";
}

.fa-chart-scatter-bubble {
  --fa: "\e0e9";
  --fa--fa: "\e0e9\e0e9";
}

.fa-house-turret {
  --fa: "\e1b4";
  --fa--fa: "\e1b4\e1b4";
}

.fa-banana {
  --fa: "\e2e5";
  --fa--fa: "\e2e5\e2e5";
}

.fa-hand-holding-skull {
  --fa: "\e1a4";
  --fa--fa: "\e1a4\e1a4";
}

.fa-people-dress {
  --fa: "\e217";
  --fa--fa: "\e217\e217";
}

.fa-loveseat {
  --fa: "\f4cc";
  --fa--fa: "\f4cc\f4cc";
}

.fa-couch-small {
  --fa: "\f4cc";
  --fa--fa: "\f4cc\f4cc";
}

.fa-tower-broadcast {
  --fa: "\f519";
  --fa--fa: "\f519\f519";
}

.fa-broadcast-tower {
  --fa: "\f519";
  --fa--fa: "\f519\f519";
}

.fa-truck-pickup {
  --fa: "\f63c";
  --fa--fa: "\f63c\f63c";
}

.fa-block-quote {
  --fa: "\e0b5";
  --fa--fa: "\e0b5\e0b5";
}

.fa-up-long {
  --fa: "\f30c";
  --fa--fa: "\f30c\f30c";
}

.fa-long-arrow-alt-up {
  --fa: "\f30c";
  --fa--fa: "\f30c\f30c";
}

.fa-stop {
  --fa: "\f04d";
  --fa--fa: "\f04d\f04d";
}

.fa-code-merge {
  --fa: "\f387";
  --fa--fa: "\f387\f387";
}

.fa-money-check-dollar-pen {
  --fa: "\f873";
  --fa--fa: "\f873\f873";
}

.fa-money-check-edit-alt {
  --fa: "\f873";
  --fa--fa: "\f873\f873";
}

.fa-up-from-line {
  --fa: "\f346";
  --fa--fa: "\f346\f346";
}

.fa-arrow-alt-from-bottom {
  --fa: "\f346";
  --fa--fa: "\f346\f346";
}

.fa-upload {
  --fa: "\f093";
  --fa--fa: "\f093\f093";
}

.fa-hurricane {
  --fa: "\f751";
  --fa--fa: "\f751\f751";
}

.fa-grid-round-2-plus {
  --fa: "\e5dc";
  --fa--fa: "\e5dc\e5dc";
}

.fa-people-pants {
  --fa: "\e219";
  --fa--fa: "\e219\e219";
}

.fa-mound {
  --fa: "\e52d";
  --fa--fa: "\e52d\e52d";
}

.fa-windsock {
  --fa: "\f777";
  --fa--fa: "\f777\f777";
}

.fa-circle-half {
  --fa: "\e110";
  --fa--fa: "\e110\e110";
}

.fa-brake-warning {
  --fa: "\e0c7";
  --fa--fa: "\e0c7\e0c7";
}

.fa-toilet-portable {
  --fa: "\e583";
  --fa--fa: "\e583\e583";
}

.fa-compact-disc {
  --fa: "\f51f";
  --fa--fa: "\f51f\f51f";
}

.fa-file-arrow-down {
  --fa: "\f56d";
  --fa--fa: "\f56d\f56d";
}

.fa-file-download {
  --fa: "\f56d";
  --fa--fa: "\f56d\f56d";
}

.fa-saxophone-fire {
  --fa: "\f8db";
  --fa--fa: "\f8db\f8db";
}

.fa-sax-hot {
  --fa: "\f8db";
  --fa--fa: "\f8db\f8db";
}

.fa-camera-web-slash {
  --fa: "\f833";
  --fa--fa: "\f833\f833";
}

.fa-webcam-slash {
  --fa: "\f833";
  --fa--fa: "\f833\f833";
}

.fa-folder-medical {
  --fa: "\e18c";
  --fa--fa: "\e18c\e18c";
}

.fa-folder-gear {
  --fa: "\e187";
  --fa--fa: "\e187\e187";
}

.fa-folder-cog {
  --fa: "\e187";
  --fa--fa: "\e187\e187";
}

.fa-hand-wave {
  --fa: "\e1a7";
  --fa--fa: "\e1a7\e1a7";
}

.fa-arrow-up-arrow-down {
  --fa: "\e099";
  --fa--fa: "\e099\e099";
}

.fa-sort-up-down {
  --fa: "\e099";
  --fa--fa: "\e099\e099";
}

.fa-caravan {
  --fa: "\f8ff";
  --fa--fa: "\f8ff\f8ff";
}

.fa-shield-cat {
  --fa: "\e572";
  --fa--fa: "\e572\e572";
}

.fa-message-slash {
  --fa: "\f4a9";
  --fa--fa: "\f4a9\f4a9";
}

.fa-comment-alt-slash {
  --fa: "\f4a9";
  --fa--fa: "\f4a9\f4a9";
}

.fa-bolt {
  --fa: "\f0e7";
  --fa--fa: "\f0e7\f0e7";
}

.fa-zap {
  --fa: "\f0e7";
  --fa--fa: "\f0e7\f0e7";
}

.fa-trash-can-check {
  --fa: "\e2a9";
  --fa--fa: "\e2a9\e2a9";
}

.fa-glass-water {
  --fa: "\e4f4";
  --fa--fa: "\e4f4\e4f4";
}

.fa-oil-well {
  --fa: "\e532";
  --fa--fa: "\e532\e532";
}

.fa-table-cells-column-unlock {
  --fa: "\e690";
  --fa--fa: "\e690\e690";
}

.fa-person-simple {
  --fa: "\e220";
  --fa--fa: "\e220\e220";
}

.fa-arrow-turn-left-up {
  --fa: "\e634";
  --fa--fa: "\e634\e634";
}

.fa-vault {
  --fa: "\e2c5";
  --fa--fa: "\e2c5\e2c5";
}

.fa-mars {
  --fa: "\f222";
  --fa--fa: "\f222\f222";
}

.fa-toilet {
  --fa: "\f7d8";
  --fa--fa: "\f7d8\f7d8";
}

.fa-plane-circle-xmark {
  --fa: "\e557";
  --fa--fa: "\e557\e557";
}

.fa-yen-sign {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-cny {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-jpy {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-rmb {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-yen {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-gear-code {
  --fa: "\e5e8";
  --fa--fa: "\e5e8\e5e8";
}

.fa-notes {
  --fa: "\e202";
  --fa--fa: "\e202\e202";
}

.fa-ruble-sign {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-rouble {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-rub {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-ruble {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-trash-undo {
  --fa: "\f895";
  --fa--fa: "\f895\f895";
}

.fa-trash-arrow-turn-left {
  --fa: "\f895";
  --fa--fa: "\f895\f895";
}

.fa-champagne-glass {
  --fa: "\f79e";
  --fa--fa: "\f79e\f79e";
}

.fa-glass-champagne {
  --fa: "\f79e";
  --fa--fa: "\f79e\f79e";
}

.fa-objects-align-center-horizontal {
  --fa: "\e3bc";
  --fa--fa: "\e3bc\e3bc";
}

.fa-sun {
  --fa: "\f185";
  --fa--fa: "\f185\f185";
}

.fa-trash-can-slash {
  --fa: "\e2ad";
  --fa--fa: "\e2ad\e2ad";
}

.fa-trash-alt-slash {
  --fa: "\e2ad";
  --fa--fa: "\e2ad\e2ad";
}

.fa-screen-users {
  --fa: "\f63d";
  --fa--fa: "\f63d\f63d";
}

.fa-users-class {
  --fa: "\f63d";
  --fa--fa: "\f63d\f63d";
}

.fa-guitar {
  --fa: "\f7a6";
  --fa--fa: "\f7a6\f7a6";
}

.fa-square-arrow-left {
  --fa: "\f33a";
  --fa--fa: "\f33a\f33a";
}

.fa-arrow-square-left {
  --fa: "\f33a";
  --fa--fa: "\f33a\f33a";
}

.fa-square-8 {
  --fa: "\e25d";
  --fa--fa: "\e25d\e25d";
}

.fa-face-smile-hearts {
  --fa: "\e390";
  --fa--fa: "\e390\e390";
}

.fa-brackets-square {
  --fa: "\f7e9";
  --fa--fa: "\f7e9\f7e9";
}

.fa-brackets {
  --fa: "\f7e9";
  --fa--fa: "\f7e9\f7e9";
}

.fa-laptop-arrow-down {
  --fa: "\e1c6";
  --fa--fa: "\e1c6\e1c6";
}

.fa-hockey-stick-puck {
  --fa: "\e3ae";
  --fa--fa: "\e3ae\e3ae";
}

.fa-house-tree {
  --fa: "\e1b3";
  --fa--fa: "\e1b3\e1b3";
}

.fa-signal-fair {
  --fa: "\f68d";
  --fa--fa: "\f68d\f68d";
}

.fa-signal-2 {
  --fa: "\f68d";
  --fa--fa: "\f68d\f68d";
}

.fa-face-laugh-wink {
  --fa: "\f59c";
  --fa--fa: "\f59c\f59c";
}

.fa-laugh-wink {
  --fa: "\f59c";
  --fa--fa: "\f59c\f59c";
}

.fa-circle-dollar {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8";
}

.fa-dollar-circle {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8";
}

.fa-usd-circle {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8";
}

.fa-horse-head {
  --fa: "\f7ab";
  --fa--fa: "\f7ab\f7ab";
}

.fa-arrows-repeat {
  --fa: "\f364";
  --fa--fa: "\f364\f364";
}

.fa-repeat-alt {
  --fa: "\f364";
  --fa--fa: "\f364\f364";
}

.fa-bore-hole {
  --fa: "\e4c3";
  --fa--fa: "\e4c3\e4c3";
}

.fa-industry {
  --fa: "\f275";
  --fa--fa: "\f275\f275";
}

.fa-image-polaroid {
  --fa: "\f8c4";
  --fa--fa: "\f8c4\f8c4";
}

.fa-wave-triangle {
  --fa: "\f89a";
  --fa--fa: "\f89a\f89a";
}

.fa-turn-left-down {
  --fa: "\e637";
  --fa--fa: "\e637\e637";
}

.fa-person-running-fast {
  --fa: "\e5ff";
  --fa--fa: "\e5ff\e5ff";
}

.fa-circle-down {
  --fa: "\f358";
  --fa--fa: "\f358\f358";
}

.fa-arrow-alt-circle-down {
  --fa: "\f358";
  --fa--fa: "\f358\f358";
}

.fa-grill {
  --fa: "\e5a3";
  --fa--fa: "\e5a3\e5a3";
}

.fa-arrows-turn-to-dots {
  --fa: "\e4c1";
  --fa--fa: "\e4c1\e4c1";
}

.fa-chart-mixed {
  --fa: "\f643";
  --fa--fa: "\f643\f643";
}

.fa-analytics {
  --fa: "\f643";
  --fa--fa: "\f643\f643";
}

.fa-florin-sign {
  --fa: "\e184";
  --fa--fa: "\e184\e184";
}

.fa-arrow-down-short-wide {
  --fa: "\f884";
  --fa--fa: "\f884\f884";
}

.fa-sort-amount-desc {
  --fa: "\f884";
  --fa--fa: "\f884\f884";
}

.fa-sort-amount-down-alt {
  --fa: "\f884";
  --fa--fa: "\f884\f884";
}

.fa-less-than {
  --fa: "\<";
  --fa--fa: "\<\<";
}

.fa-display-code {
  --fa: "\e165";
  --fa--fa: "\e165\e165";
}

.fa-desktop-code {
  --fa: "\e165";
  --fa--fa: "\e165\e165";
}

.fa-face-drooling {
  --fa: "\e372";
  --fa--fa: "\e372\e372";
}

.fa-oil-temperature {
  --fa: "\f614";
  --fa--fa: "\f614\f614";
}

.fa-oil-temp {
  --fa: "\f614";
  --fa--fa: "\f614\f614";
}

.fa-square-question {
  --fa: "\f2fd";
  --fa--fa: "\f2fd\f2fd";
}

.fa-question-square {
  --fa: "\f2fd";
  --fa--fa: "\f2fd\f2fd";
}

.fa-air-conditioner {
  --fa: "\f8f4";
  --fa--fa: "\f8f4\f8f4";
}

.fa-angle-down {
  --fa: "\f107";
  --fa--fa: "\f107\f107";
}

.fa-mountains {
  --fa: "\f6fd";
  --fa--fa: "\f6fd\f6fd";
}

.fa-omega {
  --fa: "\f67a";
  --fa--fa: "\f67a\f67a";
}

.fa-car-tunnel {
  --fa: "\e4de";
  --fa--fa: "\e4de\e4de";
}

.fa-person-dolly-empty {
  --fa: "\f4d1";
  --fa--fa: "\f4d1\f4d1";
}

.fa-pan-food {
  --fa: "\e42b";
  --fa--fa: "\e42b\e42b";
}

.fa-head-side-cough {
  --fa: "\e061";
  --fa--fa: "\e061\e061";
}

.fa-grip-lines {
  --fa: "\f7a4";
  --fa--fa: "\f7a4\f7a4";
}

.fa-thumbs-down {
  --fa: "\f165";
  --fa--fa: "\f165\f165";
}

.fa-user-lock {
  --fa: "\f502";
  --fa--fa: "\f502\f502";
}

.fa-arrow-right-long {
  --fa: "\f178";
  --fa--fa: "\f178\f178";
}

.fa-long-arrow-right {
  --fa: "\f178";
  --fa--fa: "\f178\f178";
}

.fa-tickets-airline {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b";
}

.fa-tickets-perforated-plane {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b";
}

.fa-tickets-plane {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b";
}

.fa-tent-double-peak {
  --fa: "\e627";
  --fa--fa: "\e627\e627";
}

.fa-anchor-circle-xmark {
  --fa: "\e4ac";
  --fa--fa: "\e4ac\e4ac";
}

.fa-ellipsis {
  --fa: "\f141";
  --fa--fa: "\f141\f141";
}

.fa-ellipsis-h {
  --fa: "\f141";
  --fa--fa: "\f141\f141";
}

.fa-nfc-slash {
  --fa: "\e1fc";
  --fa--fa: "\e1fc\e1fc";
}

.fa-chess-pawn {
  --fa: "\f443";
  --fa--fa: "\f443\f443";
}

.fa-kit-medical {
  --fa: "\f479";
  --fa--fa: "\f479\f479";
}

.fa-first-aid {
  --fa: "\f479";
  --fa--fa: "\f479\f479";
}

.fa-grid-2-plus {
  --fa: "\e197";
  --fa--fa: "\e197\e197";
}

.fa-bells {
  --fa: "\f77f";
  --fa--fa: "\f77f\f77f";
}

.fa-person-through-window {
  --fa: "\e5a9";
  --fa--fa: "\e5a9\e5a9";
}

.fa-toolbox {
  --fa: "\f552";
  --fa--fa: "\f552\f552";
}

.fa-globe-wifi {
  --fa: "\e685";
  --fa--fa: "\e685\e685";
}

.fa-envelope-dot {
  --fa: "\e16f";
  --fa--fa: "\e16f\e16f";
}

.fa-envelope-badge {
  --fa: "\e16f";
  --fa--fa: "\e16f\e16f";
}

.fa-magnifying-glass-waveform {
  --fa: "\e661";
  --fa--fa: "\e661\e661";
}

.fa-hands-holding-circle {
  --fa: "\e4fb";
  --fa--fa: "\e4fb\e4fb";
}

.fa-bug {
  --fa: "\f188";
  --fa--fa: "\f188\f188";
}

.fa-bowl-chopsticks {
  --fa: "\e2e9";
  --fa--fa: "\e2e9\e2e9";
}

.fa-credit-card {
  --fa: "\f09d";
  --fa--fa: "\f09d\f09d";
}

.fa-credit-card-alt {
  --fa: "\f09d";
  --fa--fa: "\f09d\f09d";
}

.fa-circle-s {
  --fa: "\e121";
  --fa--fa: "\e121\e121";
}

.fa-box-ballot {
  --fa: "\f735";
  --fa--fa: "\f735\f735";
}

.fa-car {
  --fa: "\f1b9";
  --fa--fa: "\f1b9\f1b9";
}

.fa-automobile {
  --fa: "\f1b9";
  --fa--fa: "\f1b9\f1b9";
}

.fa-hand-holding-hand {
  --fa: "\e4f7";
  --fa--fa: "\e4f7\e4f7";
}

.fa-user-tie-hair {
  --fa: "\e45f";
  --fa--fa: "\e45f\e45f";
}

.fa-podium-star {
  --fa: "\f758";
  --fa--fa: "\f758\f758";
}

.fa-user-hair-mullet {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-business-front {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-party-back {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-trian-balbot {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-microphone-stand {
  --fa: "\f8cb";
  --fa--fa: "\f8cb\f8cb";
}

.fa-book-open-reader {
  --fa: "\f5da";
  --fa--fa: "\f5da\f5da";
}

.fa-book-reader {
  --fa: "\f5da";
  --fa--fa: "\f5da\f5da";
}

.fa-family-dress {
  --fa: "\e301";
  --fa--fa: "\e301\e301";
}

.fa-circle-x {
  --fa: "\e12e";
  --fa--fa: "\e12e\e12e";
}

.fa-cabin {
  --fa: "\e46d";
  --fa--fa: "\e46d\e46d";
}

.fa-mountain-sun {
  --fa: "\e52f";
  --fa--fa: "\e52f\e52f";
}

.fa-chart-simple-horizontal {
  --fa: "\e474";
  --fa--fa: "\e474\e474";
}

.fa-arrows-left-right-to-line {
  --fa: "\e4ba";
  --fa--fa: "\e4ba\e4ba";
}

.fa-hand-back-point-left {
  --fa: "\e19f";
  --fa--fa: "\e19f\e19f";
}

.fa-message-dots {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3";
}

.fa-comment-alt-dots {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3";
}

.fa-messaging {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3";
}

.fa-file-heart {
  --fa: "\e176";
  --fa--fa: "\e176\e176";
}

.fa-beer-mug {
  --fa: "\e0b3";
  --fa--fa: "\e0b3\e0b3";
}

.fa-beer-foam {
  --fa: "\e0b3";
  --fa--fa: "\e0b3\e0b3";
}

.fa-dice-d20 {
  --fa: "\f6cf";
  --fa--fa: "\f6cf\f6cf";
}

.fa-drone {
  --fa: "\f85f";
  --fa--fa: "\f85f\f85f";
}

.fa-truck-droplet {
  --fa: "\e58c";
  --fa--fa: "\e58c\e58c";
}

.fa-file-circle-xmark {
  --fa: "\e5a1";
  --fa--fa: "\e5a1\e5a1";
}

.fa-temperature-arrow-up {
  --fa: "\e040";
  --fa--fa: "\e040\e040";
}

.fa-temperature-up {
  --fa: "\e040";
  --fa--fa: "\e040\e040";
}

.fa-medal {
  --fa: "\f5a2";
  --fa--fa: "\f5a2\f5a2";
}

.fa-person-fairy {
  --fa: "\e608";
  --fa--fa: "\e608\e608";
}

.fa-bed {
  --fa: "\f236";
  --fa--fa: "\f236\f236";
}

.fa-book-copy {
  --fa: "\e0be";
  --fa--fa: "\e0be\e0be";
}

.fa-square-h {
  --fa: "\f0fd";
  --fa--fa: "\f0fd\f0fd";
}

.fa-h-square {
  --fa: "\f0fd";
  --fa--fa: "\f0fd\f0fd";
}

.fa-square-c {
  --fa: "\e266";
  --fa--fa: "\e266\e266";
}

.fa-clock-two {
  --fa: "\e35a";
  --fa--fa: "\e35a\e35a";
}

.fa-square-ellipsis-vertical {
  --fa: "\e26f";
  --fa--fa: "\e26f\e26f";
}

.fa-calendar-users {
  --fa: "\e5e2";
  --fa--fa: "\e5e2\e5e2";
}

.fa-podcast {
  --fa: "\f2ce";
  --fa--fa: "\f2ce\f2ce";
}

.fa-bee {
  --fa: "\e0b2";
  --fa--fa: "\e0b2\e0b2";
}

.fa-temperature-full {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-temperature-4 {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-thermometer-4 {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-thermometer-full {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-bell {
  --fa: "\f0f3";
  --fa--fa: "\f0f3\f0f3";
}

.fa-candy-bar {
  --fa: "\e3e8";
  --fa--fa: "\e3e8\e3e8";
}

.fa-chocolate-bar {
  --fa: "\e3e8";
  --fa--fa: "\e3e8\e3e8";
}

.fa-xmark-large {
  --fa: "\e59b";
  --fa--fa: "\e59b\e59b";
}

.fa-pinata {
  --fa: "\e3c3";
  --fa--fa: "\e3c3\e3c3";
}

.fa-file-ppt {
  --fa: "\e64a";
  --fa--fa: "\e64a\e64a";
}

.fa-arrows-from-line {
  --fa: "\e0a4";
  --fa--fa: "\e0a4\e0a4";
}

.fa-superscript {
  --fa: "\f12b";
  --fa--fa: "\f12b\f12b";
}

.fa-bowl-spoon {
  --fa: "\e3e0";
  --fa--fa: "\e3e0\e3e0";
}

.fa-hexagon-check {
  --fa: "\e416";
  --fa--fa: "\e416\e416";
}

.fa-plug-circle-xmark {
  --fa: "\e560";
  --fa--fa: "\e560\e560";
}

.fa-star-of-life {
  --fa: "\f621";
  --fa--fa: "\f621\f621";
}

.fa-phone-slash {
  --fa: "\f3dd";
  --fa--fa: "\f3dd\f3dd";
}

.fa-traffic-light-stop {
  --fa: "\f63a";
  --fa--fa: "\f63a\f63a";
}

.fa-paint-roller {
  --fa: "\f5aa";
  --fa--fa: "\f5aa\f5aa";
}

.fa-accent-grave {
  --fa: "\`";
  --fa--fa: "\`\`";
}

.fa-handshake-angle {
  --fa: "\f4c4";
  --fa--fa: "\f4c4\f4c4";
}

.fa-hands-helping {
  --fa: "\f4c4";
  --fa--fa: "\f4c4\f4c4";
}

.fa-circle-0 {
  --fa: "\e0ed";
  --fa--fa: "\e0ed\e0ed";
}

.fa-dial-med-low {
  --fa: "\e160";
  --fa--fa: "\e160\e160";
}

.fa-location-dot {
  --fa: "\f3c5";
  --fa--fa: "\f3c5\f3c5";
}

.fa-map-marker-alt {
  --fa: "\f3c5";
  --fa--fa: "\f3c5\f3c5";
}

.fa-crab {
  --fa: "\e3ff";
  --fa--fa: "\e3ff\e3ff";
}

.fa-box-open-full {
  --fa: "\f49c";
  --fa--fa: "\f49c\f49c";
}

.fa-box-full {
  --fa: "\f49c";
  --fa--fa: "\f49c\f49c";
}

.fa-file {
  --fa: "\f15b";
  --fa--fa: "\f15b\f15b";
}

.fa-greater-than {
  --fa: "\>";
  --fa--fa: "\>\>";
}

.fa-quotes {
  --fa: "\e234";
  --fa--fa: "\e234\e234";
}

.fa-pretzel {
  --fa: "\e441";
  --fa--fa: "\e441\e441";
}

.fa-t-rex {
  --fa: "\e629";
  --fa--fa: "\e629\e629";
}

.fa-person-swimming {
  --fa: "\f5c4";
  --fa--fa: "\f5c4\f5c4";
}

.fa-swimmer {
  --fa: "\f5c4";
  --fa--fa: "\f5c4\f5c4";
}

.fa-arrow-down {
  --fa: "\f063";
  --fa--fa: "\f063\f063";
}

.fa-user-robot-xmarks {
  --fa: "\e4a7";
  --fa--fa: "\e4a7\e4a7";
}

.fa-message-quote {
  --fa: "\e1e4";
  --fa--fa: "\e1e4\e1e4";
}

.fa-comment-alt-quote {
  --fa: "\e1e4";
  --fa--fa: "\e1e4\e1e4";
}

.fa-candy-corn {
  --fa: "\f6bd";
  --fa--fa: "\f6bd\f6bd";
}

.fa-folder-magnifying-glass {
  --fa: "\e18b";
  --fa--fa: "\e18b\e18b";
}

.fa-folder-search {
  --fa: "\e18b";
  --fa--fa: "\e18b\e18b";
}

.fa-notebook {
  --fa: "\e201";
  --fa--fa: "\e201\e201";
}

.fa-circle-wifi {
  --fa: "\e67d";
  --fa--fa: "\e67d\e67d";
}

.fa-droplet {
  --fa: "\f043";
  --fa--fa: "\f043\f043";
}

.fa-tint {
  --fa: "\f043";
  --fa--fa: "\f043\f043";
}

.fa-bullseye-pointer {
  --fa: "\f649";
  --fa--fa: "\f649\f649";
}

.fa-eraser {
  --fa: "\f12d";
  --fa--fa: "\f12d\f12d";
}

.fa-hexagon-image {
  --fa: "\e504";
  --fa--fa: "\e504\e504";
}

.fa-earth-americas {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-earth {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-earth-america {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-globe-americas {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-file-svg {
  --fa: "\e64b";
  --fa--fa: "\e64b\e64b";
}

.fa-crate-apple {
  --fa: "\f6b1";
  --fa--fa: "\f6b1\f6b1";
}

.fa-apple-crate {
  --fa: "\f6b1";
  --fa--fa: "\f6b1\f6b1";
}

.fa-person-burst {
  --fa: "\e53b";
  --fa--fa: "\e53b\e53b";
}

.fa-game-board {
  --fa: "\f867";
  --fa--fa: "\f867\f867";
}

.fa-hat-chef {
  --fa: "\f86b";
  --fa--fa: "\f86b\f86b";
}

.fa-hand-back-point-right {
  --fa: "\e1a1";
  --fa--fa: "\e1a1\e1a1";
}

.fa-dove {
  --fa: "\f4ba";
  --fa--fa: "\f4ba\f4ba";
}

.fa-snowflake-droplets {
  --fa: "\e5c1";
  --fa--fa: "\e5c1\e5c1";
}

.fa-battery-empty {
  --fa: "\f244";
  --fa--fa: "\f244\f244";
}

.fa-battery-0 {
  --fa: "\f244";
  --fa--fa: "\f244\f244";
}

.fa-grid-4 {
  --fa: "\e198";
  --fa--fa: "\e198\e198";
}

.fa-socks {
  --fa: "\f696";
  --fa--fa: "\f696\f696";
}

.fa-face-sunglasses {
  --fa: "\e398";
  --fa--fa: "\e398\e398";
}

.fa-inbox {
  --fa: "\f01c";
  --fa--fa: "\f01c\f01c";
}

.fa-square-0 {
  --fa: "\e255";
  --fa--fa: "\e255\e255";
}

.fa-section {
  --fa: "\e447";
  --fa--fa: "\e447\e447";
}

.fa-square-this-way-up {
  --fa: "\f49f";
  --fa--fa: "\f49f\f49f";
}

.fa-box-up {
  --fa: "\f49f";
  --fa--fa: "\f49f\f49f";
}

.fa-gauge-high {
  --fa: "\f625";
  --fa--fa: "\f625\f625";
}

.fa-tachometer-alt {
  --fa: "\f625";
  --fa--fa: "\f625\f625";
}

.fa-tachometer-alt-fast {
  --fa: "\f625";
  --fa--fa: "\f625\f625";
}

.fa-square-ampersand {
  --fa: "\e260";
  --fa--fa: "\e260\e260";
}

.fa-envelope-open-text {
  --fa: "\f658";
  --fa--fa: "\f658\f658";
}

.fa-lamp-desk {
  --fa: "\e014";
  --fa--fa: "\e014\e014";
}

.fa-hospital {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8";
}

.fa-hospital-alt {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8";
}

.fa-hospital-wide {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8";
}

.fa-poll-people {
  --fa: "\f759";
  --fa--fa: "\f759\f759";
}

.fa-whiskey-glass-ice {
  --fa: "\f7a1";
  --fa--fa: "\f7a1\f7a1";
}

.fa-glass-whiskey-rocks {
  --fa: "\f7a1";
  --fa--fa: "\f7a1\f7a1";
}

.fa-wine-bottle {
  --fa: "\f72f";
  --fa--fa: "\f72f\f72f";
}

.fa-chess-rook {
  --fa: "\f447";
  --fa--fa: "\f447\f447";
}

.fa-user-bounty-hunter {
  --fa: "\e2bf";
  --fa--fa: "\e2bf\e2bf";
}

.fa-bars-staggered {
  --fa: "\f550";
  --fa--fa: "\f550\f550";
}

.fa-reorder {
  --fa: "\f550";
  --fa--fa: "\f550\f550";
}

.fa-stream {
  --fa: "\f550";
  --fa--fa: "\f550\f550";
}

.fa-diagram-sankey {
  --fa: "\e158";
  --fa--fa: "\e158\e158";
}

.fa-cloud-hail-mixed {
  --fa: "\f73a";
  --fa--fa: "\f73a\f73a";
}

.fa-circle-up-left {
  --fa: "\e128";
  --fa--fa: "\e128\e128";
}

.fa-dharmachakra {
  --fa: "\f655";
  --fa--fa: "\f655\f655";
}

.fa-objects-align-left {
  --fa: "\e3be";
  --fa--fa: "\e3be\e3be";
}

.fa-oil-can-drip {
  --fa: "\e205";
  --fa--fa: "\e205\e205";
}

.fa-face-smiling-hands {
  --fa: "\e396";
  --fa--fa: "\e396\e396";
}

.fa-broccoli {
  --fa: "\e3e2";
  --fa--fa: "\e3e2\e3e2";
}

.fa-route-interstate {
  --fa: "\f61b";
  --fa--fa: "\f61b\f61b";
}

.fa-ear-muffs {
  --fa: "\f795";
  --fa--fa: "\f795\f795";
}

.fa-hotdog {
  --fa: "\f80f";
  --fa--fa: "\f80f\f80f";
}

.fa-transporter-empty {
  --fa: "\e046";
  --fa--fa: "\e046\e046";
}

.fa-person-walking-with-cane {
  --fa: "\f29d";
  --fa--fa: "\f29d\f29d";
}

.fa-blind {
  --fa: "\f29d";
  --fa--fa: "\f29d\f29d";
}

.fa-angle-90 {
  --fa: "\e08d";
  --fa--fa: "\e08d\e08d";
}

.fa-rectangle-terminal {
  --fa: "\e236";
  --fa--fa: "\e236\e236";
}

.fa-kite {
  --fa: "\f6f4";
  --fa--fa: "\f6f4\f6f4";
}

.fa-drum {
  --fa: "\f569";
  --fa--fa: "\f569\f569";
}

.fa-scrubber {
  --fa: "\f2f8";
  --fa--fa: "\f2f8\f2f8";
}

.fa-ice-cream {
  --fa: "\f810";
  --fa--fa: "\f810\f810";
}

.fa-heart-circle-bolt {
  --fa: "\e4fc";
  --fa--fa: "\e4fc\e4fc";
}

.fa-fish-bones {
  --fa: "\e304";
  --fa--fa: "\e304\e304";
}

.fa-deer-rudolph {
  --fa: "\f78f";
  --fa--fa: "\f78f\f78f";
}

.fa-fax {
  --fa: "\f1ac";
  --fa--fa: "\f1ac\f1ac";
}

.fa-paragraph {
  --fa: "\f1dd";
  --fa--fa: "\f1dd\f1dd";
}

.fa-head-side-heart {
  --fa: "\e1aa";
  --fa--fa: "\e1aa\e1aa";
}

.fa-square-e {
  --fa: "\e26d";
  --fa--fa: "\e26d\e26d";
}

.fa-meter-fire {
  --fa: "\e1eb";
  --fa--fa: "\e1eb\e1eb";
}

.fa-cloud-hail {
  --fa: "\f739";
  --fa--fa: "\f739\f739";
}

.fa-check-to-slot {
  --fa: "\f772";
  --fa--fa: "\f772\f772";
}

.fa-vote-yea {
  --fa: "\f772";
  --fa--fa: "\f772\f772";
}

.fa-money-from-bracket {
  --fa: "\e312";
  --fa--fa: "\e312\e312";
}

.fa-star-half {
  --fa: "\f089";
  --fa--fa: "\f089\f089";
}

.fa-car-bus {
  --fa: "\f85a";
  --fa--fa: "\f85a\f85a";
}

.fa-speaker {
  --fa: "\f8df";
  --fa--fa: "\f8df\f8df";
}

.fa-timer {
  --fa: "\e29e";
  --fa--fa: "\e29e\e29e";
}

.fa-boxes-stacked {
  --fa: "\f468";
  --fa--fa: "\f468\f468";
}

.fa-boxes {
  --fa: "\f468";
  --fa--fa: "\f468\f468";
}

.fa-boxes-alt {
  --fa: "\f468";
  --fa--fa: "\f468\f468";
}

.fa-landmark-magnifying-glass {
  --fa: "\e622";
  --fa--fa: "\e622\e622";
}

.fa-grill-hot {
  --fa: "\e5a5";
  --fa--fa: "\e5a5\e5a5";
}

.fa-ballot-check {
  --fa: "\f733";
  --fa--fa: "\f733\f733";
}

.fa-link {
  --fa: "\f0c1";
  --fa--fa: "\f0c1\f0c1";
}

.fa-chain {
  --fa: "\f0c1";
  --fa--fa: "\f0c1\f0c1";
}

.fa-ear-listen {
  --fa: "\f2a2";
  --fa--fa: "\f2a2\f2a2";
}

.fa-assistive-listening-systems {
  --fa: "\f2a2";
  --fa--fa: "\f2a2\f2a2";
}

.fa-file-minus {
  --fa: "\f318";
  --fa--fa: "\f318\f318";
}

.fa-tree-city {
  --fa: "\e587";
  --fa--fa: "\e587\e587";
}

.fa-play {
  --fa: "\f04b";
  --fa--fa: "\f04b\f04b";
}

.fa-font {
  --fa: "\f031";
  --fa--fa: "\f031\f031";
}

.fa-cup-togo {
  --fa: "\f6c5";
  --fa--fa: "\f6c5\f6c5";
}

.fa-coffee-togo {
  --fa: "\f6c5";
  --fa--fa: "\f6c5\f6c5";
}

.fa-square-down-left {
  --fa: "\e26b";
  --fa--fa: "\e26b\e26b";
}

.fa-burger-lettuce {
  --fa: "\e3e3";
  --fa--fa: "\e3e3\e3e3";
}

.fa-table-cells-row-lock {
  --fa: "\e67a";
  --fa--fa: "\e67a\e67a";
}

.fa-rupiah-sign {
  --fa: "\e23d";
  --fa--fa: "\e23d\e23d";
}

.fa-magnifying-glass {
  --fa: "\f002";
  --fa--fa: "\f002\f002";
}

.fa-search {
  --fa: "\f002";
  --fa--fa: "\f002\f002";
}

.fa-table-tennis-paddle-ball {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d";
}

.fa-ping-pong-paddle-ball {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d";
}

.fa-table-tennis {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d";
}

.fa-person-dots-from-line {
  --fa: "\f470";
  --fa--fa: "\f470\f470";
}

.fa-diagnoses {
  --fa: "\f470";
  --fa--fa: "\f470\f470";
}

.fa-chevrons-down {
  --fa: "\f322";
  --fa--fa: "\f322\f322";
}

.fa-chevron-double-down {
  --fa: "\f322";
  --fa--fa: "\f322\f322";
}

.fa-trash-can-arrow-up {
  --fa: "\f82a";
  --fa--fa: "\f82a\f82a";
}

.fa-trash-restore-alt {
  --fa: "\f82a";
  --fa--fa: "\f82a\f82a";
}

.fa-signal-good {
  --fa: "\f68e";
  --fa--fa: "\f68e\f68e";
}

.fa-signal-3 {
  --fa: "\f68e";
  --fa--fa: "\f68e\f68e";
}

.fa-location-question {
  --fa: "\f60b";
  --fa--fa: "\f60b\f60b";
}

.fa-map-marker-question {
  --fa: "\f60b";
  --fa--fa: "\f60b\f60b";
}

.fa-floppy-disk-circle-xmark {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-floppy-disk-times {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-save-circle-xmark {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-save-times {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-naira-sign {
  --fa: "\e1f6";
  --fa--fa: "\e1f6\e1f6";
}

.fa-peach {
  --fa: "\e20b";
  --fa--fa: "\e20b\e20b";
}

.fa-circles-overlap-3 {
  --fa: "\e6a1";
  --fa--fa: "\e6a1\e6a1";
}

.fa-pronoun {
  --fa: "\e6a1";
  --fa--fa: "\e6a1\e6a1";
}

.fa-taxi-bus {
  --fa: "\e298";
  --fa--fa: "\e298\e298";
}

.fa-bracket-curly {
  --fa: "\{";
  --fa--fa: "\{\{";
}

.fa-bracket-curly-left {
  --fa: "\{";
  --fa--fa: "\{\{";
}

.fa-lobster {
  --fa: "\e421";
  --fa--fa: "\e421\e421";
}

.fa-cart-flatbed-empty {
  --fa: "\f476";
  --fa--fa: "\f476\f476";
}

.fa-dolly-flatbed-empty {
  --fa: "\f476";
  --fa--fa: "\f476\f476";
}

.fa-colon {
  --fa: "\:";
  --fa--fa: "\:\:";
}

.fa-cart-arrow-down {
  --fa: "\f218";
  --fa--fa: "\f218\f218";
}

.fa-wand {
  --fa: "\f72a";
  --fa--fa: "\f72a\f72a";
}

.fa-walkie-talkie {
  --fa: "\f8ef";
  --fa--fa: "\f8ef\f8ef";
}

.fa-file-pen {
  --fa: "\f31c";
  --fa--fa: "\f31c\f31c";
}

.fa-file-edit {
  --fa: "\f31c";
  --fa--fa: "\f31c\f31c";
}

.fa-receipt {
  --fa: "\f543";
  --fa--fa: "\f543\f543";
}

.fa-table-picnic {
  --fa: "\e32d";
  --fa--fa: "\e32d\e32d";
}

.fa-square-pen {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b";
}

.fa-pen-square {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b";
}

.fa-pencil-square {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b";
}

.fa-circle-microphone-lines {
  --fa: "\e117";
  --fa--fa: "\e117\e117";
}

.fa-microphone-circle-alt {
  --fa: "\e117";
  --fa--fa: "\e117\e117";
}

.fa-display-slash {
  --fa: "\e2fa";
  --fa--fa: "\e2fa\e2fa";
}

.fa-desktop-slash {
  --fa: "\e2fa";
  --fa--fa: "\e2fa\e2fa";
}

.fa-suitcase-rolling {
  --fa: "\f5c1";
  --fa--fa: "\f5c1\f5c1";
}

.fa-person-circle-exclamation {
  --fa: "\e53f";
  --fa--fa: "\e53f\e53f";
}

.fa-transporter-2 {
  --fa: "\e044";
  --fa--fa: "\e044\e044";
}

.fa-user-hoodie {
  --fa: "\e68a";
  --fa--fa: "\e68a\e68a";
}

.fa-hands-holding-diamond {
  --fa: "\f47c";
  --fa--fa: "\f47c\f47c";
}

.fa-hand-receiving {
  --fa: "\f47c";
  --fa--fa: "\f47c\f47c";
}

.fa-money-bill-simple-wave {
  --fa: "\e1f2";
  --fa--fa: "\e1f2\e1f2";
}

.fa-chevron-down {
  --fa: "\f078";
  --fa--fa: "\f078\f078";
}

.fa-battery-full {
  --fa: "\f240";
  --fa--fa: "\f240\f240";
}

.fa-battery {
  --fa: "\f240";
  --fa--fa: "\f240\f240";
}

.fa-battery-5 {
  --fa: "\f240";
  --fa--fa: "\f240\f240";
}

.fa-bell-plus {
  --fa: "\f849";
  --fa--fa: "\f849\f849";
}

.fa-book-arrow-right {
  --fa: "\e0b9";
  --fa--fa: "\e0b9\e0b9";
}

.fa-hospitals {
  --fa: "\f80e";
  --fa--fa: "\f80e\f80e";
}

.fa-club {
  --fa: "\f327";
  --fa--fa: "\f327\f327";
}

.fa-skull-crossbones {
  --fa: "\f714";
  --fa--fa: "\f714\f714";
}

.fa-droplet-degree {
  --fa: "\f748";
  --fa--fa: "\f748\f748";
}

.fa-dewpoint {
  --fa: "\f748";
  --fa--fa: "\f748\f748";
}

.fa-code-compare {
  --fa: "\e13a";
  --fa--fa: "\e13a\e13a";
}

.fa-list-ul {
  --fa: "\f0ca";
  --fa--fa: "\f0ca\f0ca";
}

.fa-list-dots {
  --fa: "\f0ca";
  --fa--fa: "\f0ca\f0ca";
}

.fa-hand-holding-magic {
  --fa: "\f6e5";
  --fa--fa: "\f6e5\f6e5";
}

.fa-watermelon-slice {
  --fa: "\e337";
  --fa--fa: "\e337\e337";
}

.fa-circle-ellipsis {
  --fa: "\e10a";
  --fa--fa: "\e10a\e10a";
}

.fa-school-lock {
  --fa: "\e56f";
  --fa--fa: "\e56f\e56f";
}

.fa-tower-cell {
  --fa: "\e585";
  --fa--fa: "\e585\e585";
}

.fa-sd-cards {
  --fa: "\e240";
  --fa--fa: "\e240\e240";
}

.fa-jug-bottle {
  --fa: "\e5fb";
  --fa--fa: "\e5fb\e5fb";
}

.fa-down-long {
  --fa: "\f309";
  --fa--fa: "\f309\f309";
}

.fa-long-arrow-alt-down {
  --fa: "\f309";
  --fa--fa: "\f309\f309";
}

.fa-envelopes {
  --fa: "\e170";
  --fa--fa: "\e170\e170";
}

.fa-phone-office {
  --fa: "\f67d";
  --fa--fa: "\f67d\f67d";
}

.fa-ranking-star {
  --fa: "\e561";
  --fa--fa: "\e561\e561";
}

.fa-chess-king {
  --fa: "\f43f";
  --fa--fa: "\f43f\f43f";
}

.fa-nfc-pen {
  --fa: "\e1fa";
  --fa--fa: "\e1fa\e1fa";
}

.fa-person-harassing {
  --fa: "\e549";
  --fa--fa: "\e549\e549";
}

.fa-magnifying-glass-play {
  --fa: "\e660";
  --fa--fa: "\e660\e660";
}

.fa-hat-winter {
  --fa: "\f7a8";
  --fa--fa: "\f7a8\f7a8";
}

.fa-brazilian-real-sign {
  --fa: "\e46c";
  --fa--fa: "\e46c\e46c";
}

.fa-landmark-dome {
  --fa: "\f752";
  --fa--fa: "\f752\f752";
}

.fa-landmark-alt {
  --fa: "\f752";
  --fa--fa: "\f752\f752";
}

.fa-bone-break {
  --fa: "\f5d8";
  --fa--fa: "\f5d8\f5d8";
}

.fa-arrow-up {
  --fa: "\f062";
  --fa--fa: "\f062\f062";
}

.fa-down-from-dotted-line {
  --fa: "\e407";
  --fa--fa: "\e407\e407";
}

.fa-tv {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c";
}

.fa-television {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c";
}

.fa-tv-alt {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c";
}

.fa-border-left {
  --fa: "\f84f";
  --fa--fa: "\f84f\f84f";
}

.fa-circle-divide {
  --fa: "\e106";
  --fa--fa: "\e106\e106";
}

.fa-shrimp {
  --fa: "\e448";
  --fa--fa: "\e448\e448";
}

.fa-list-check {
  --fa: "\f0ae";
  --fa--fa: "\f0ae\f0ae";
}

.fa-tasks {
  --fa: "\f0ae";
  --fa--fa: "\f0ae\f0ae";
}

.fa-diagram-subtask {
  --fa: "\e479";
  --fa--fa: "\e479\e479";
}

.fa-jug-detergent {
  --fa: "\e519";
  --fa--fa: "\e519\e519";
}

.fa-circle-user {
  --fa: "\f2bd";
  --fa--fa: "\f2bd\f2bd";
}

.fa-user-circle {
  --fa: "\f2bd";
  --fa--fa: "\f2bd\f2bd";
}

.fa-square-y {
  --fa: "\e287";
  --fa--fa: "\e287\e287";
}

.fa-user-doctor-hair {
  --fa: "\e458";
  --fa--fa: "\e458\e458";
}

.fa-planet-ringed {
  --fa: "\e020";
  --fa--fa: "\e020\e020";
}

.fa-mushroom {
  --fa: "\e425";
  --fa--fa: "\e425\e425";
}

.fa-user-shield {
  --fa: "\f505";
  --fa--fa: "\f505\f505";
}

.fa-megaphone {
  --fa: "\f675";
  --fa--fa: "\f675\f675";
}

.fa-wreath-laurel {
  --fa: "\e5d2";
  --fa--fa: "\e5d2\e5d2";
}

.fa-circle-exclamation-check {
  --fa: "\e10d";
  --fa--fa: "\e10d\e10d";
}

.fa-wind {
  --fa: "\f72e";
  --fa--fa: "\f72e\f72e";
}

.fa-box-dollar {
  --fa: "\f4a0";
  --fa--fa: "\f4a0\f4a0";
}

.fa-box-usd {
  --fa: "\f4a0";
  --fa--fa: "\f4a0\f4a0";
}

.fa-car-burst {
  --fa: "\f5e1";
  --fa--fa: "\f5e1\f5e1";
}

.fa-car-crash {
  --fa: "\f5e1";
  --fa--fa: "\f5e1\f5e1";
}

.fa-y {
  --fa: "Y";
  --fa--fa: "YY";
}

.fa-user-headset {
  --fa: "\f82d";
  --fa--fa: "\f82d\f82d";
}

.fa-arrows-retweet {
  --fa: "\f361";
  --fa--fa: "\f361\f361";
}

.fa-retweet-alt {
  --fa: "\f361";
  --fa--fa: "\f361\f361";
}

.fa-person-snowboarding {
  --fa: "\f7ce";
  --fa--fa: "\f7ce\f7ce";
}

.fa-snowboarding {
  --fa: "\f7ce";
  --fa--fa: "\f7ce\f7ce";
}

.fa-square-chevron-right {
  --fa: "\f32b";
  --fa--fa: "\f32b\f32b";
}

.fa-chevron-square-right {
  --fa: "\f32b";
  --fa--fa: "\f32b\f32b";
}

.fa-lacrosse-stick-ball {
  --fa: "\e3b6";
  --fa--fa: "\e3b6\e3b6";
}

.fa-truck-fast {
  --fa: "\f48b";
  --fa--fa: "\f48b\f48b";
}

.fa-shipping-fast {
  --fa: "\f48b";
  --fa--fa: "\f48b\f48b";
}

.fa-user-magnifying-glass {
  --fa: "\e5c5";
  --fa--fa: "\e5c5\e5c5";
}

.fa-star-sharp {
  --fa: "\e28b";
  --fa--fa: "\e28b\e28b";
}

.fa-comment-heart {
  --fa: "\e5c8";
  --fa--fa: "\e5c8\e5c8";
}

.fa-circle-1 {
  --fa: "\e0ee";
  --fa--fa: "\e0ee\e0ee";
}

.fa-circle-star {
  --fa: "\e123";
  --fa--fa: "\e123\e123";
}

.fa-star-circle {
  --fa: "\e123";
  --fa--fa: "\e123\e123";
}

.fa-fish {
  --fa: "\f578";
  --fa--fa: "\f578\f578";
}

.fa-cloud-fog {
  --fa: "\f74e";
  --fa--fa: "\f74e\f74e";
}

.fa-fog {
  --fa: "\f74e";
  --fa--fa: "\f74e\f74e";
}

.fa-waffle {
  --fa: "\e466";
  --fa--fa: "\e466\e466";
}

.fa-music-note {
  --fa: "\f8cf";
  --fa--fa: "\f8cf\f8cf";
}

.fa-music-alt {
  --fa: "\f8cf";
  --fa--fa: "\f8cf\f8cf";
}

.fa-hexagon-exclamation {
  --fa: "\e417";
  --fa--fa: "\e417\e417";
}

.fa-cart-shopping-fast {
  --fa: "\e0dc";
  --fa--fa: "\e0dc\e0dc";
}

.fa-object-union {
  --fa: "\e49f";
  --fa--fa: "\e49f\e49f";
}

.fa-user-graduate {
  --fa: "\f501";
  --fa--fa: "\f501\f501";
}

.fa-starfighter {
  --fa: "\e037";
  --fa--fa: "\e037\e037";
}

.fa-circle-half-stroke {
  --fa: "\f042";
  --fa--fa: "\f042\f042";
}

.fa-adjust {
  --fa: "\f042";
  --fa--fa: "\f042\f042";
}

.fa-arrow-right-long-to-line {
  --fa: "\e3d5";
  --fa--fa: "\e3d5\e3d5";
}

.fa-square-arrow-down {
  --fa: "\f339";
  --fa--fa: "\f339\f339";
}

.fa-arrow-square-down {
  --fa: "\f339";
  --fa--fa: "\f339\f339";
}

.fa-diamond-half-stroke {
  --fa: "\e5b8";
  --fa--fa: "\e5b8\e5b8";
}

.fa-clapperboard {
  --fa: "\e131";
  --fa--fa: "\e131\e131";
}

.fa-square-chevron-left {
  --fa: "\f32a";
  --fa--fa: "\f32a\f32a";
}

.fa-chevron-square-left {
  --fa: "\f32a";
  --fa--fa: "\f32a\f32a";
}

.fa-phone-intercom {
  --fa: "\e434";
  --fa--fa: "\e434\e434";
}

.fa-link-horizontal {
  --fa: "\e1cb";
  --fa--fa: "\e1cb\e1cb";
}

.fa-chain-horizontal {
  --fa: "\e1cb";
  --fa--fa: "\e1cb\e1cb";
}

.fa-mango {
  --fa: "\e30f";
  --fa--fa: "\e30f\e30f";
}

.fa-music-note-slash {
  --fa: "\f8d0";
  --fa--fa: "\f8d0\f8d0";
}

.fa-music-alt-slash {
  --fa: "\f8d0";
  --fa--fa: "\f8d0\f8d0";
}

.fa-circle-radiation {
  --fa: "\f7ba";
  --fa--fa: "\f7ba\f7ba";
}

.fa-radiation-alt {
  --fa: "\f7ba";
  --fa--fa: "\f7ba\f7ba";
}

.fa-face-tongue-sweat {
  --fa: "\e39e";
  --fa--fa: "\e39e\e39e";
}

.fa-globe-stand {
  --fa: "\f5f6";
  --fa--fa: "\f5f6\f5f6";
}

.fa-baseball {
  --fa: "\f433";
  --fa--fa: "\f433\f433";
}

.fa-baseball-ball {
  --fa: "\f433";
  --fa--fa: "\f433\f433";
}

.fa-circle-p {
  --fa: "\e11a";
  --fa--fa: "\e11a\e11a";
}

.fa-award-simple {
  --fa: "\e0ab";
  --fa--fa: "\e0ab\e0ab";
}

.fa-jet-fighter-up {
  --fa: "\e518";
  --fa--fa: "\e518\e518";
}

.fa-diagram-project {
  --fa: "\f542";
  --fa--fa: "\f542\f542";
}

.fa-project-diagram {
  --fa: "\f542";
  --fa--fa: "\f542\f542";
}

.fa-pedestal {
  --fa: "\e20d";
  --fa--fa: "\e20d\e20d";
}

.fa-chart-pyramid {
  --fa: "\e0e6";
  --fa--fa: "\e0e6\e0e6";
}

.fa-sidebar {
  --fa: "\e24e";
  --fa--fa: "\e24e\e24e";
}

.fa-snowman-head {
  --fa: "\f79b";
  --fa--fa: "\f79b\f79b";
}

.fa-frosty-head {
  --fa: "\f79b";
  --fa--fa: "\f79b\f79b";
}

.fa-copy {
  --fa: "\f0c5";
  --fa--fa: "\f0c5\f0c5";
}

.fa-burger-glass {
  --fa: "\e0ce";
  --fa--fa: "\e0ce\e0ce";
}

.fa-volume-xmark {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9";
}

.fa-volume-mute {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9";
}

.fa-volume-times {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9";
}

.fa-hand-sparkles {
  --fa: "\e05d";
  --fa--fa: "\e05d\e05d";
}

.fa-bars-filter {
  --fa: "\e0ad";
  --fa--fa: "\e0ad\e0ad";
}

.fa-paintbrush-pencil {
  --fa: "\e206";
  --fa--fa: "\e206\e206";
}

.fa-party-bell {
  --fa: "\e31a";
  --fa--fa: "\e31a\e31a";
}

.fa-user-vneck-hair {
  --fa: "\e462";
  --fa--fa: "\e462\e462";
}

.fa-jack-o-lantern {
  --fa: "\f30e";
  --fa--fa: "\f30e\f30e";
}

.fa-grip {
  --fa: "\f58d";
  --fa--fa: "\f58d\f58d";
}

.fa-grip-horizontal {
  --fa: "\f58d";
  --fa--fa: "\f58d\f58d";
}

.fa-share-from-square {
  --fa: "\f14d";
  --fa--fa: "\f14d\f14d";
}

.fa-share-square {
  --fa: "\f14d";
  --fa--fa: "\f14d\f14d";
}

.fa-keynote {
  --fa: "\f66c";
  --fa--fa: "\f66c\f66c";
}

.fa-child-combatant {
  --fa: "\e4e0";
  --fa--fa: "\e4e0\e4e0";
}

.fa-child-rifle {
  --fa: "\e4e0";
  --fa--fa: "\e4e0\e4e0";
}

.fa-gun {
  --fa: "\e19b";
  --fa--fa: "\e19b\e19b";
}

.fa-square-phone {
  --fa: "\f098";
  --fa--fa: "\f098\f098";
}

.fa-phone-square {
  --fa: "\f098";
  --fa--fa: "\f098\f098";
}

.fa-hat-beach {
  --fa: "\e606";
  --fa--fa: "\e606\e606";
}

.fa-plus {
  --fa: "\+";
  --fa--fa: "\+\+";
}

.fa-add {
  --fa: "\+";
  --fa--fa: "\+\+";
}

.fa-expand {
  --fa: "\f065";
  --fa--fa: "\f065\f065";
}

.fa-computer {
  --fa: "\e4e5";
  --fa--fa: "\e4e5\e4e5";
}

.fa-fort {
  --fa: "\e486";
  --fa--fa: "\e486\e486";
}

.fa-cloud-check {
  --fa: "\e35c";
  --fa--fa: "\e35c\e35c";
}

.fa-xmark {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-close {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-multiply {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-remove {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-times {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-face-smirking {
  --fa: "\e397";
  --fa--fa: "\e397\e397";
}

.fa-arrows-up-down-left-right {
  --fa: "\f047";
  --fa--fa: "\f047\f047";
}

.fa-arrows {
  --fa: "\f047";
  --fa--fa: "\f047\f047";
}

.fa-chalkboard-user {
  --fa: "\f51c";
  --fa--fa: "\f51c\f51c";
}

.fa-chalkboard-teacher {
  --fa: "\f51c";
  --fa--fa: "\f51c\f51c";
}

.fa-rhombus {
  --fa: "\e23b";
  --fa--fa: "\e23b\e23b";
}

.fa-claw-marks {
  --fa: "\f6c2";
  --fa--fa: "\f6c2\f6c2";
}

.fa-peso-sign {
  --fa: "\e222";
  --fa--fa: "\e222\e222";
}

.fa-face-smile-tongue {
  --fa: "\e394";
  --fa--fa: "\e394\e394";
}

.fa-cart-circle-xmark {
  --fa: "\e3f4";
  --fa--fa: "\e3f4\e3f4";
}

.fa-building-shield {
  --fa: "\e4d8";
  --fa--fa: "\e4d8\e4d8";
}

.fa-circle-phone-flip {
  --fa: "\e11c";
  --fa--fa: "\e11c\e11c";
}

.fa-phone-circle-alt {
  --fa: "\e11c";
  --fa--fa: "\e11c\e11c";
}

.fa-baby {
  --fa: "\f77c";
  --fa--fa: "\f77c\f77c";
}

.fa-users-line {
  --fa: "\e592";
  --fa--fa: "\e592\e592";
}

.fa-quote-left {
  --fa: "\f10d";
  --fa--fa: "\f10d\f10d";
}

.fa-quote-left-alt {
  --fa: "\f10d";
  --fa--fa: "\f10d\f10d";
}

.fa-tractor {
  --fa: "\f722";
  --fa--fa: "\f722\f722";
}

.fa-down-from-bracket {
  --fa: "\e66b";
  --fa--fa: "\e66b\e66b";
}

.fa-key-skeleton {
  --fa: "\f6f3";
  --fa--fa: "\f6f3\f6f3";
}

.fa-trash-arrow-up {
  --fa: "\f829";
  --fa--fa: "\f829\f829";
}

.fa-trash-restore {
  --fa: "\f829";
  --fa--fa: "\f829\f829";
}

.fa-arrow-down-up-lock {
  --fa: "\e4b0";
  --fa--fa: "\e4b0\e4b0";
}

.fa-arrow-down-to-bracket {
  --fa: "\e094";
  --fa--fa: "\e094\e094";
}

.fa-lines-leaning {
  --fa: "\e51e";
  --fa--fa: "\e51e\e51e";
}

.fa-square-q {
  --fa: "\e27b";
  --fa--fa: "\e27b\e27b";
}

.fa-ruler-combined {
  --fa: "\f546";
  --fa--fa: "\f546\f546";
}

.fa-symbols {
  --fa: "\f86e";
  --fa--fa: "\f86e\f86e";
}

.fa-icons-alt {
  --fa: "\f86e";
  --fa--fa: "\f86e\f86e";
}

.fa-copyright {
  --fa: "\f1f9";
  --fa--fa: "\f1f9\f1f9";
}

.fa-flask-gear {
  --fa: "\e5f1";
  --fa--fa: "\e5f1\e5f1";
}

.fa-highlighter-line {
  --fa: "\e1af";
  --fa--fa: "\e1af\e1af";
}

.fa-bracket-square {
  --fa: "\[";
  --fa--fa: "\[\[";
}

.fa-bracket {
  --fa: "\[";
  --fa--fa: "\[\[";
}

.fa-bracket-left {
  --fa: "\[";
  --fa--fa: "\[\[";
}

.fa-island-tropical {
  --fa: "\f811";
  --fa--fa: "\f811\f811";
}

.fa-island-tree-palm {
  --fa: "\f811";
  --fa--fa: "\f811\f811";
}

.fa-arrow-right-from-line {
  --fa: "\f343";
  --fa--fa: "\f343\f343";
}

.fa-arrow-from-left {
  --fa: "\f343";
  --fa--fa: "\f343\f343";
}

.fa-h2 {
  --fa: "\f314";
  --fa--fa: "\f314\f314";
}

.fa-equals {
  --fa: "\=";
  --fa--fa: "\=\=";
}

.fa-cake-slice {
  --fa: "\e3e5";
  --fa--fa: "\e3e5\e3e5";
}

.fa-shortcake {
  --fa: "\e3e5";
  --fa--fa: "\e3e5\e3e5";
}

.fa-building-magnifying-glass {
  --fa: "\e61c";
  --fa--fa: "\e61c\e61c";
}

.fa-peanut {
  --fa: "\e430";
  --fa--fa: "\e430\e430";
}

.fa-wrench-simple {
  --fa: "\e2d1";
  --fa--fa: "\e2d1\e2d1";
}

.fa-blender {
  --fa: "\f517";
  --fa--fa: "\f517\f517";
}

.fa-teeth {
  --fa: "\f62e";
  --fa--fa: "\f62e\f62e";
}

.fa-tally-2 {
  --fa: "\e295";
  --fa--fa: "\e295\e295";
}

.fa-shekel-sign {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-ils {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-shekel {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-sheqel {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-sheqel-sign {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-cars {
  --fa: "\f85b";
  --fa--fa: "\f85b\f85b";
}

.fa-axe-battle {
  --fa: "\f6b3";
  --fa--fa: "\f6b3\f6b3";
}

.fa-user-hair-long {
  --fa: "\e45b";
  --fa--fa: "\e45b\e45b";
}

.fa-map {
  --fa: "\f279";
  --fa--fa: "\f279\f279";
}

.fa-arrow-left-from-arc {
  --fa: "\e615";
  --fa--fa: "\e615\e615";
}

.fa-file-circle-info {
  --fa: "\e493";
  --fa--fa: "\e493\e493";
}

.fa-face-disappointed {
  --fa: "\e36f";
  --fa--fa: "\e36f\e36f";
}

.fa-lasso-sparkles {
  --fa: "\e1c9";
  --fa--fa: "\e1c9\e1c9";
}

.fa-clock-eleven {
  --fa: "\e347";
  --fa--fa: "\e347\e347";
}

.fa-rocket {
  --fa: "\f135";
  --fa--fa: "\f135\f135";
}

.fa-siren-on {
  --fa: "\e02e";
  --fa--fa: "\e02e\e02e";
}

.fa-clock-ten {
  --fa: "\e354";
  --fa--fa: "\e354\e354";
}

.fa-candle-holder {
  --fa: "\f6bc";
  --fa--fa: "\f6bc\f6bc";
}

.fa-video-arrow-down-left {
  --fa: "\e2c8";
  --fa--fa: "\e2c8\e2c8";
}

.fa-photo-film {
  --fa: "\f87c";
  --fa--fa: "\f87c\f87c";
}

.fa-photo-video {
  --fa: "\f87c";
  --fa--fa: "\f87c\f87c";
}

.fa-floppy-disk-circle-arrow-right {
  --fa: "\e180";
  --fa--fa: "\e180\e180";
}

.fa-save-circle-arrow-right {
  --fa: "\e180";
  --fa--fa: "\e180\e180";
}

.fa-folder-minus {
  --fa: "\f65d";
  --fa--fa: "\f65d\f65d";
}

.fa-hexagon-nodes-bolt {
  --fa: "\e69a";
  --fa--fa: "\e69a\e69a";
}

.fa-planet-moon {
  --fa: "\e01f";
  --fa--fa: "\e01f\e01f";
}

.fa-face-eyes-xmarks {
  --fa: "\e374";
  --fa--fa: "\e374\e374";
}

.fa-chart-scatter {
  --fa: "\f7ee";
  --fa--fa: "\f7ee\f7ee";
}

.fa-circle-gf {
  --fa: "\e67f";
  --fa--fa: "\e67f\e67f";
}

.fa-display-arrow-down {
  --fa: "\e164";
  --fa--fa: "\e164\e164";
}

.fa-store {
  --fa: "\f54e";
  --fa--fa: "\f54e\f54e";
}

.fa-arrow-trend-up {
  --fa: "\e098";
  --fa--fa: "\e098\e098";
}

.fa-plug-circle-minus {
  --fa: "\e55e";
  --fa--fa: "\e55e\e55e";
}

.fa-olive-branch {
  --fa: "\e317";
  --fa--fa: "\e317\e317";
}

.fa-angle {
  --fa: "\e08c";
  --fa--fa: "\e08c\e08c";
}

.fa-vacuum-robot {
  --fa: "\e04e";
  --fa--fa: "\e04e\e04e";
}

.fa-sign-hanging {
  --fa: "\f4d9";
  --fa--fa: "\f4d9\f4d9";
}

.fa-sign {
  --fa: "\f4d9";
  --fa--fa: "\f4d9\f4d9";
}

.fa-square-divide {
  --fa: "\e26a";
  --fa--fa: "\e26a\e26a";
}

.fa-folder-check {
  --fa: "\e64e";
  --fa--fa: "\e64e\e64e";
}

.fa-signal-stream-slash {
  --fa: "\e250";
  --fa--fa: "\e250\e250";
}

.fa-bezier-curve {
  --fa: "\f55b";
  --fa--fa: "\f55b\f55b";
}

.fa-eye-dropper-half {
  --fa: "\e173";
  --fa--fa: "\e173\e173";
}

.fa-store-lock {
  --fa: "\e4a6";
  --fa--fa: "\e4a6\e4a6";
}

.fa-bell-slash {
  --fa: "\f1f6";
  --fa--fa: "\f1f6\f1f6";
}

.fa-cloud-bolt-sun {
  --fa: "\f76e";
  --fa--fa: "\f76e\f76e";
}

.fa-thunderstorm-sun {
  --fa: "\f76e";
  --fa--fa: "\f76e\f76e";
}

.fa-camera-slash {
  --fa: "\e0d9";
  --fa--fa: "\e0d9\e0d9";
}

.fa-comment-quote {
  --fa: "\e14c";
  --fa--fa: "\e14c\e14c";
}

.fa-tablet {
  --fa: "\f3fb";
  --fa--fa: "\f3fb\f3fb";
}

.fa-tablet-android {
  --fa: "\f3fb";
  --fa--fa: "\f3fb\f3fb";
}

.fa-school-flag {
  --fa: "\e56e";
  --fa--fa: "\e56e\e56e";
}

.fa-message-code {
  --fa: "\e1df";
  --fa--fa: "\e1df\e1df";
}

.fa-glass-half {
  --fa: "\e192";
  --fa--fa: "\e192\e192";
}

.fa-glass-half-empty {
  --fa: "\e192";
  --fa--fa: "\e192\e192";
}

.fa-glass-half-full {
  --fa: "\e192";
  --fa--fa: "\e192\e192";
}

.fa-fill {
  --fa: "\f575";
  --fa--fa: "\f575\f575";
}

.fa-message-minus {
  --fa: "\f4a7";
  --fa--fa: "\f4a7\f4a7";
}

.fa-comment-alt-minus {
  --fa: "\f4a7";
  --fa--fa: "\f4a7\f4a7";
}

.fa-angle-up {
  --fa: "\f106";
  --fa--fa: "\f106\f106";
}

.fa-dinosaur {
  --fa: "\e5fe";
  --fa--fa: "\e5fe\e5fe";
}

.fa-drumstick-bite {
  --fa: "\f6d7";
  --fa--fa: "\f6d7\f6d7";
}

.fa-link-horizontal-slash {
  --fa: "\e1cc";
  --fa--fa: "\e1cc\e1cc";
}

.fa-chain-horizontal-slash {
  --fa: "\e1cc";
  --fa--fa: "\e1cc\e1cc";
}

.fa-holly-berry {
  --fa: "\f7aa";
  --fa--fa: "\f7aa\f7aa";
}

.fa-nose {
  --fa: "\e5bd";
  --fa--fa: "\e5bd\e5bd";
}

.fa-arrow-left-to-arc {
  --fa: "\e616";
  --fa--fa: "\e616\e616";
}

.fa-chevron-left {
  --fa: "\f053";
  --fa--fa: "\f053\f053";
}

.fa-bacteria {
  --fa: "\e059";
  --fa--fa: "\e059\e059";
}

.fa-clouds {
  --fa: "\f744";
  --fa--fa: "\f744\f744";
}

.fa-money-bill-simple {
  --fa: "\e1f1";
  --fa--fa: "\e1f1\e1f1";
}

.fa-hand-lizard {
  --fa: "\f258";
  --fa--fa: "\f258\f258";
}

.fa-table-pivot {
  --fa: "\e291";
  --fa--fa: "\e291\e291";
}

.fa-filter-slash {
  --fa: "\e17d";
  --fa--fa: "\e17d\e17d";
}

.fa-trash-can-undo {
  --fa: "\f896";
  --fa--fa: "\f896\f896";
}

.fa-trash-can-arrow-turn-left {
  --fa: "\f896";
  --fa--fa: "\f896\f896";
}

.fa-trash-undo-alt {
  --fa: "\f896";
  --fa--fa: "\f896\f896";
}

.fa-notdef {
  --fa: "\e1fe";
  --fa--fa: "\e1fe\e1fe";
}

.fa-disease {
  --fa: "\f7fa";
  --fa--fa: "\f7fa\f7fa";
}

.fa-person-to-door {
  --fa: "\e433";
  --fa--fa: "\e433\e433";
}

.fa-turntable {
  --fa: "\f8e4";
  --fa--fa: "\f8e4\f8e4";
}

.fa-briefcase-medical {
  --fa: "\f469";
  --fa--fa: "\f469\f469";
}

.fa-genderless {
  --fa: "\f22d";
  --fa--fa: "\f22d\f22d";
}

.fa-chevron-right {
  --fa: "\f054";
  --fa--fa: "\f054\f054";
}

.fa-signal-weak {
  --fa: "\f68c";
  --fa--fa: "\f68c\f68c";
}

.fa-signal-1 {
  --fa: "\f68c";
  --fa--fa: "\f68c\f68c";
}

.fa-clock-five {
  --fa: "\e349";
  --fa--fa: "\e349\e349";
}

.fa-retweet {
  --fa: "\f079";
  --fa--fa: "\f079\f079";
}

.fa-car-rear {
  --fa: "\f5de";
  --fa--fa: "\f5de\f5de";
}

.fa-car-alt {
  --fa: "\f5de";
  --fa--fa: "\f5de\f5de";
}

.fa-pump-soap {
  --fa: "\e06b";
  --fa--fa: "\e06b\e06b";
}

.fa-computer-classic {
  --fa: "\f8b1";
  --fa--fa: "\f8b1\f8b1";
}

.fa-frame {
  --fa: "\e495";
  --fa--fa: "\e495\e495";
}

.fa-video-slash {
  --fa: "\f4e2";
  --fa--fa: "\f4e2\f4e2";
}

.fa-battery-quarter {
  --fa: "\f243";
  --fa--fa: "\f243\f243";
}

.fa-battery-2 {
  --fa: "\f243";
  --fa--fa: "\f243\f243";
}

.fa-ellipsis-stroke {
  --fa: "\f39b";
  --fa--fa: "\f39b\f39b";
}

.fa-ellipsis-h-alt {
  --fa: "\f39b";
  --fa--fa: "\f39b\f39b";
}

.fa-radio {
  --fa: "\f8d7";
  --fa--fa: "\f8d7\f8d7";
}

.fa-baby-carriage {
  --fa: "\f77d";
  --fa--fa: "\f77d\f77d";
}

.fa-carriage-baby {
  --fa: "\f77d";
  --fa--fa: "\f77d\f77d";
}

.fa-face-expressionless {
  --fa: "\e373";
  --fa--fa: "\e373\e373";
}

.fa-down-to-dotted-line {
  --fa: "\e408";
  --fa--fa: "\e408\e408";
}

.fa-cloud-music {
  --fa: "\f8ae";
  --fa--fa: "\f8ae\f8ae";
}

.fa-traffic-light {
  --fa: "\f637";
  --fa--fa: "\f637\f637";
}

.fa-cloud-minus {
  --fa: "\e35d";
  --fa--fa: "\e35d\e35d";
}

.fa-thermometer {
  --fa: "\f491";
  --fa--fa: "\f491\f491";
}

.fa-shield-minus {
  --fa: "\e249";
  --fa--fa: "\e249\e249";
}

.fa-vr-cardboard {
  --fa: "\f729";
  --fa--fa: "\f729\f729";
}

.fa-car-tilt {
  --fa: "\f5e5";
  --fa--fa: "\f5e5\f5e5";
}

.fa-gauge-circle-minus {
  --fa: "\e497";
  --fa--fa: "\e497\e497";
}

.fa-brightness-low {
  --fa: "\e0ca";
  --fa--fa: "\e0ca\e0ca";
}

.fa-hand-middle-finger {
  --fa: "\f806";
  --fa--fa: "\f806\f806";
}

.fa-percent {
  --fa: "\%";
  --fa--fa: "\%\%";
}

.fa-percentage {
  --fa: "\%";
  --fa--fa: "\%\%";
}

.fa-truck-moving {
  --fa: "\f4df";
  --fa--fa: "\f4df\f4df";
}

.fa-glass-water-droplet {
  --fa: "\e4f5";
  --fa--fa: "\e4f5\e4f5";
}

.fa-conveyor-belt {
  --fa: "\f46e";
  --fa--fa: "\f46e\f46e";
}

.fa-location-check {
  --fa: "\f606";
  --fa--fa: "\f606\f606";
}

.fa-map-marker-check {
  --fa: "\f606";
  --fa--fa: "\f606\f606";
}

.fa-coin-vertical {
  --fa: "\e3fd";
  --fa--fa: "\e3fd\e3fd";
}

.fa-display {
  --fa: "\e163";
  --fa--fa: "\e163\e163";
}

.fa-person-sign {
  --fa: "\f757";
  --fa--fa: "\f757\f757";
}

.fa-face-smile {
  --fa: "\f118";
  --fa--fa: "\f118\f118";
}

.fa-smile {
  --fa: "\f118";
  --fa--fa: "\f118\f118";
}

.fa-phone-hangup {
  --fa: "\e225";
  --fa--fa: "\e225\e225";
}

.fa-signature-slash {
  --fa: "\e3cb";
  --fa--fa: "\e3cb\e3cb";
}

.fa-thumbtack {
  --fa: "\f08d";
  --fa--fa: "\f08d\f08d";
}

.fa-thumb-tack {
  --fa: "\f08d";
  --fa--fa: "\f08d\f08d";
}

.fa-wheat-slash {
  --fa: "\e339";
  --fa--fa: "\e339\e339";
}

.fa-trophy {
  --fa: "\f091";
  --fa--fa: "\f091\f091";
}

.fa-clouds-sun {
  --fa: "\f746";
  --fa--fa: "\f746\f746";
}

.fa-person-praying {
  --fa: "\f683";
  --fa--fa: "\f683\f683";
}

.fa-pray {
  --fa: "\f683";
  --fa--fa: "\f683\f683";
}

.fa-hammer {
  --fa: "\f6e3";
  --fa--fa: "\f6e3\f6e3";
}

.fa-face-vomit {
  --fa: "\e3a0";
  --fa--fa: "\e3a0\e3a0";
}

.fa-speakers {
  --fa: "\f8e0";
  --fa--fa: "\f8e0\f8e0";
}

.fa-tty-answer {
  --fa: "\e2b9";
  --fa--fa: "\e2b9\e2b9";
}

.fa-teletype-answer {
  --fa: "\e2b9";
  --fa--fa: "\e2b9\e2b9";
}

.fa-mug-tea-saucer {
  --fa: "\e1f5";
  --fa--fa: "\e1f5\e1f5";
}

.fa-diagram-lean-canvas {
  --fa: "\e156";
  --fa--fa: "\e156\e156";
}

.fa-alt {
  --fa: "\e08a";
  --fa--fa: "\e08a\e08a";
}

.fa-dial {
  --fa: "\e15b";
  --fa--fa: "\e15b\e15b";
}

.fa-dial-med-high {
  --fa: "\e15b";
  --fa--fa: "\e15b\e15b";
}

.fa-hand-peace {
  --fa: "\f25b";
  --fa--fa: "\f25b\f25b";
}

.fa-circle-trash {
  --fa: "\e126";
  --fa--fa: "\e126\e126";
}

.fa-trash-circle {
  --fa: "\e126";
  --fa--fa: "\e126\e126";
}

.fa-rotate {
  --fa: "\f2f1";
  --fa--fa: "\f2f1\f2f1";
}

.fa-sync-alt {
  --fa: "\f2f1";
  --fa--fa: "\f2f1\f2f1";
}

.fa-circle-quarters {
  --fa: "\e3f8";
  --fa--fa: "\e3f8\e3f8";
}

.fa-spinner {
  --fa: "\f110";
  --fa--fa: "\f110\f110";
}

.fa-tower-control {
  --fa: "\e2a2";
  --fa--fa: "\e2a2\e2a2";
}

.fa-arrow-up-triangle-square {
  --fa: "\f88a";
  --fa--fa: "\f88a\f88a";
}

.fa-sort-shapes-up {
  --fa: "\f88a";
  --fa--fa: "\f88a\f88a";
}

.fa-whale {
  --fa: "\f72c";
  --fa--fa: "\f72c\f72c";
}

.fa-robot {
  --fa: "\f544";
  --fa--fa: "\f544\f544";
}

.fa-peace {
  --fa: "\f67c";
  --fa--fa: "\f67c\f67c";
}

.fa-party-horn {
  --fa: "\e31b";
  --fa--fa: "\e31b\e31b";
}

.fa-gears {
  --fa: "\f085";
  --fa--fa: "\f085\f085";
}

.fa-cogs {
  --fa: "\f085";
  --fa--fa: "\f085\f085";
}

.fa-sun-bright {
  --fa: "\e28f";
  --fa--fa: "\e28f\e28f";
}

.fa-sun-alt {
  --fa: "\e28f";
  --fa--fa: "\e28f\e28f";
}

.fa-warehouse {
  --fa: "\f494";
  --fa--fa: "\f494\f494";
}

.fa-conveyor-belt-arm {
  --fa: "\e5f8";
  --fa--fa: "\e5f8\e5f8";
}

.fa-lock-keyhole-open {
  --fa: "\f3c2";
  --fa--fa: "\f3c2\f3c2";
}

.fa-lock-open-alt {
  --fa: "\f3c2";
  --fa--fa: "\f3c2\f3c2";
}

.fa-square-fragile {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b";
}

.fa-box-fragile {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b";
}

.fa-square-wine-glass-crack {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b";
}

.fa-arrow-up-right-dots {
  --fa: "\e4b7";
  --fa--fa: "\e4b7\e4b7";
}

.fa-square-n {
  --fa: "\e277";
  --fa--fa: "\e277\e277";
}

.fa-splotch {
  --fa: "\f5bc";
  --fa--fa: "\f5bc\f5bc";
}

.fa-face-grin-hearts {
  --fa: "\f584";
  --fa--fa: "\f584\f584";
}

.fa-grin-hearts {
  --fa: "\f584";
  --fa--fa: "\f584\f584";
}

.fa-meter {
  --fa: "\e1e8";
  --fa--fa: "\e1e8\e1e8";
}

.fa-mandolin {
  --fa: "\f6f9";
  --fa--fa: "\f6f9\f6f9";
}

.fa-dice-four {
  --fa: "\f524";
  --fa--fa: "\f524\f524";
}

.fa-sim-card {
  --fa: "\f7c4";
  --fa--fa: "\f7c4\f7c4";
}

.fa-transgender {
  --fa: "\f225";
  --fa--fa: "\f225\f225";
}

.fa-transgender-alt {
  --fa: "\f225";
  --fa--fa: "\f225\f225";
}

.fa-mercury {
  --fa: "\f223";
  --fa--fa: "\f223\f223";
}

.fa-up-from-bracket {
  --fa: "\e590";
  --fa--fa: "\e590\e590";
}

.fa-knife-kitchen {
  --fa: "\f6f5";
  --fa--fa: "\f6f5\f6f5";
}

.fa-border-right {
  --fa: "\f852";
  --fa--fa: "\f852\f852";
}

.fa-arrow-turn-down {
  --fa: "\f149";
  --fa--fa: "\f149\f149";
}

.fa-level-down {
  --fa: "\f149";
  --fa--fa: "\f149\f149";
}

.fa-spade {
  --fa: "\f2f4";
  --fa--fa: "\f2f4\f2f4";
}

.fa-card-spade {
  --fa: "\e3ec";
  --fa--fa: "\e3ec\e3ec";
}

.fa-line-columns {
  --fa: "\f870";
  --fa--fa: "\f870\f870";
}

.fa-ant {
  --fa: "\e680";
  --fa--fa: "\e680\e680";
}

.fa-arrow-right-to-line {
  --fa: "\f340";
  --fa--fa: "\f340\f340";
}

.fa-arrow-to-right {
  --fa: "\f340";
  --fa--fa: "\f340\f340";
}

.fa-person-falling-burst {
  --fa: "\e547";
  --fa--fa: "\e547\e547";
}

.fa-flag-pennant {
  --fa: "\f456";
  --fa--fa: "\f456\f456";
}

.fa-pennant {
  --fa: "\f456";
  --fa--fa: "\f456\f456";
}

.fa-conveyor-belt-empty {
  --fa: "\e150";
  --fa--fa: "\e150\e150";
}

.fa-user-group-simple {
  --fa: "\e603";
  --fa--fa: "\e603\e603";
}

.fa-award {
  --fa: "\f559";
  --fa--fa: "\f559\f559";
}

.fa-ticket-simple {
  --fa: "\f3ff";
  --fa--fa: "\f3ff\f3ff";
}

.fa-ticket-alt {
  --fa: "\f3ff";
  --fa--fa: "\f3ff\f3ff";
}

.fa-building {
  --fa: "\f1ad";
  --fa--fa: "\f1ad\f1ad";
}

.fa-angles-left {
  --fa: "\f100";
  --fa--fa: "\f100\f100";
}

.fa-angle-double-left {
  --fa: "\f100";
  --fa--fa: "\f100\f100";
}

.fa-camcorder {
  --fa: "\f8a8";
  --fa--fa: "\f8a8\f8a8";
}

.fa-video-handheld {
  --fa: "\f8a8";
  --fa--fa: "\f8a8\f8a8";
}

.fa-pancakes {
  --fa: "\e42d";
  --fa--fa: "\e42d\e42d";
}

.fa-album-circle-user {
  --fa: "\e48d";
  --fa--fa: "\e48d\e48d";
}

.fa-subtitles-slash {
  --fa: "\e610";
  --fa--fa: "\e610\e610";
}

.fa-qrcode {
  --fa: "\f029";
  --fa--fa: "\f029\f029";
}

.fa-dice-d10 {
  --fa: "\f6cd";
  --fa--fa: "\f6cd\f6cd";
}

.fa-fireplace {
  --fa: "\f79a";
  --fa--fa: "\f79a\f79a";
}

.fa-browser {
  --fa: "\f37e";
  --fa--fa: "\f37e\f37e";
}

.fa-pen-paintbrush {
  --fa: "\f618";
  --fa--fa: "\f618\f618";
}

.fa-pencil-paintbrush {
  --fa: "\f618";
  --fa--fa: "\f618\f618";
}

.fa-fish-cooked {
  --fa: "\f7fe";
  --fa--fa: "\f7fe\f7fe";
}

.fa-chair-office {
  --fa: "\f6c1";
  --fa--fa: "\f6c1\f6c1";
}

.fa-magnifying-glass-music {
  --fa: "\e65f";
  --fa--fa: "\e65f\e65f";
}

.fa-nesting-dolls {
  --fa: "\e3ba";
  --fa--fa: "\e3ba\e3ba";
}

.fa-clock-rotate-left {
  --fa: "\f1da";
  --fa--fa: "\f1da\f1da";
}

.fa-history {
  --fa: "\f1da";
  --fa--fa: "\f1da\f1da";
}

.fa-trumpet {
  --fa: "\f8e3";
  --fa--fa: "\f8e3\f8e3";
}

.fa-face-grin-beam-sweat {
  --fa: "\f583";
  --fa--fa: "\f583\f583";
}

.fa-grin-beam-sweat {
  --fa: "\f583";
  --fa--fa: "\f583\f583";
}

.fa-fire-smoke {
  --fa: "\f74b";
  --fa--fa: "\f74b\f74b";
}

.fa-phone-missed {
  --fa: "\e226";
  --fa--fa: "\e226\e226";
}

.fa-file-export {
  --fa: "\f56e";
  --fa--fa: "\f56e\f56e";
}

.fa-arrow-right-from-file {
  --fa: "\f56e";
  --fa--fa: "\f56e\f56e";
}

.fa-shield {
  --fa: "\f132";
  --fa--fa: "\f132\f132";
}

.fa-shield-blank {
  --fa: "\f132";
  --fa--fa: "\f132\f132";
}

.fa-arrow-up-short-wide {
  --fa: "\f885";
  --fa--fa: "\f885\f885";
}

.fa-sort-amount-up-alt {
  --fa: "\f885";
  --fa--fa: "\f885\f885";
}

.fa-arrows-repeat-1 {
  --fa: "\f366";
  --fa--fa: "\f366\f366";
}

.fa-repeat-1-alt {
  --fa: "\f366";
  --fa--fa: "\f366\f366";
}

.fa-gun-slash {
  --fa: "\e19c";
  --fa--fa: "\e19c\e19c";
}

.fa-avocado {
  --fa: "\e0aa";
  --fa--fa: "\e0aa\e0aa";
}

.fa-binary {
  --fa: "\e33b";
  --fa--fa: "\e33b\e33b";
}

.fa-comment-nodes {
  --fa: "\e696";
  --fa--fa: "\e696\e696";
}

.fa-glasses-round {
  --fa: "\f5f5";
  --fa--fa: "\f5f5\f5f5";
}

.fa-glasses-alt {
  --fa: "\f5f5";
  --fa--fa: "\f5f5\f5f5";
}

.fa-phone-plus {
  --fa: "\f4d2";
  --fa--fa: "\f4d2\f4d2";
}

.fa-ditto {
  --fa: "\"";
  --fa--fa: "\"\"";
}

.fa-person-seat {
  --fa: "\e21e";
  --fa--fa: "\e21e\e21e";
}

.fa-house-medical {
  --fa: "\e3b2";
  --fa--fa: "\e3b2\e3b2";
}

.fa-golf-ball-tee {
  --fa: "\f450";
  --fa--fa: "\f450\f450";
}

.fa-golf-ball {
  --fa: "\f450";
  --fa--fa: "\f450\f450";
}

.fa-circle-chevron-left {
  --fa: "\f137";
  --fa--fa: "\f137\f137";
}

.fa-chevron-circle-left {
  --fa: "\f137";
  --fa--fa: "\f137\f137";
}

.fa-house-chimney-window {
  --fa: "\e00d";
  --fa--fa: "\e00d\e00d";
}

.fa-scythe {
  --fa: "\f710";
  --fa--fa: "\f710\f710";
}

.fa-pen-nib {
  --fa: "\f5ad";
  --fa--fa: "\f5ad\f5ad";
}

.fa-ban-parking {
  --fa: "\f616";
  --fa--fa: "\f616\f616";
}

.fa-parking-circle-slash {
  --fa: "\f616";
  --fa--fa: "\f616\f616";
}

.fa-tent-arrow-turn-left {
  --fa: "\e580";
  --fa--fa: "\e580\e580";
}

.fa-face-diagonal-mouth {
  --fa: "\e47e";
  --fa--fa: "\e47e\e47e";
}

.fa-diagram-cells {
  --fa: "\e475";
  --fa--fa: "\e475\e475";
}

.fa-cricket-bat-ball {
  --fa: "\f449";
  --fa--fa: "\f449\f449";
}

.fa-cricket {
  --fa: "\f449";
  --fa--fa: "\f449\f449";
}

.fa-tents {
  --fa: "\e582";
  --fa--fa: "\e582\e582";
}

.fa-wand-magic {
  --fa: "\f0d0";
  --fa--fa: "\f0d0\f0d0";
}

.fa-magic {
  --fa: "\f0d0";
  --fa--fa: "\f0d0\f0d0";
}

.fa-dog {
  --fa: "\f6d3";
  --fa--fa: "\f6d3\f6d3";
}

.fa-pen-line {
  --fa: "\e212";
  --fa--fa: "\e212\e212";
}

.fa-atom-simple {
  --fa: "\f5d3";
  --fa--fa: "\f5d3\f5d3";
}

.fa-atom-alt {
  --fa: "\f5d3";
  --fa--fa: "\f5d3\f5d3";
}

.fa-ampersand {
  --fa: "\&";
  --fa--fa: "\&\&";
}

.fa-carrot {
  --fa: "\f787";
  --fa--fa: "\f787\f787";
}

.fa-arrow-up-from-line {
  --fa: "\f342";
  --fa--fa: "\f342\f342";
}

.fa-arrow-from-bottom {
  --fa: "\f342";
  --fa--fa: "\f342\f342";
}

.fa-moon {
  --fa: "\f186";
  --fa--fa: "\f186\f186";
}

.fa-pen-slash {
  --fa: "\e213";
  --fa--fa: "\e213\e213";
}

.fa-wine-glass-empty {
  --fa: "\f5ce";
  --fa--fa: "\f5ce\f5ce";
}

.fa-wine-glass-alt {
  --fa: "\f5ce";
  --fa--fa: "\f5ce\f5ce";
}

.fa-square-star {
  --fa: "\e27f";
  --fa--fa: "\e27f\e27f";
}

.fa-cheese {
  --fa: "\f7ef";
  --fa--fa: "\f7ef\f7ef";
}

.fa-send-backward {
  --fa: "\f87f";
  --fa--fa: "\f87f\f87f";
}

.fa-yin-yang {
  --fa: "\f6ad";
  --fa--fa: "\f6ad\f6ad";
}

.fa-music {
  --fa: "\f001";
  --fa--fa: "\f001\f001";
}

.fa-compass-slash {
  --fa: "\f5e9";
  --fa--fa: "\f5e9\f5e9";
}

.fa-clock-one {
  --fa: "\e34e";
  --fa--fa: "\e34e\e34e";
}

.fa-file-music {
  --fa: "\f8b6";
  --fa--fa: "\f8b6\f8b6";
}

.fa-code-commit {
  --fa: "\f386";
  --fa--fa: "\f386\f386";
}

.fa-temperature-low {
  --fa: "\f76b";
  --fa--fa: "\f76b\f76b";
}

.fa-person-biking {
  --fa: "\f84a";
  --fa--fa: "\f84a\f84a";
}

.fa-biking {
  --fa: "\f84a";
  --fa--fa: "\f84a\f84a";
}

.fa-display-chart-up-circle-currency {
  --fa: "\e5e5";
  --fa--fa: "\e5e5\e5e5";
}

.fa-skeleton {
  --fa: "\f620";
  --fa--fa: "\f620\f620";
}

.fa-circle-g {
  --fa: "\e10f";
  --fa--fa: "\e10f\e10f";
}

.fa-circle-arrow-up-left {
  --fa: "\e0fb";
  --fa--fa: "\e0fb\e0fb";
}

.fa-coin-blank {
  --fa: "\e3fb";
  --fa--fa: "\e3fb\e3fb";
}

.fa-broom {
  --fa: "\f51a";
  --fa--fa: "\f51a\f51a";
}

.fa-vacuum {
  --fa: "\e04d";
  --fa--fa: "\e04d\e04d";
}

.fa-shield-heart {
  --fa: "\e574";
  --fa--fa: "\e574\e574";
}

.fa-card-heart {
  --fa: "\e3eb";
  --fa--fa: "\e3eb\e3eb";
}

.fa-lightbulb-cfl-on {
  --fa: "\e5a7";
  --fa--fa: "\e5a7\e5a7";
}

.fa-melon {
  --fa: "\e310";
  --fa--fa: "\e310\e310";
}

.fa-gopuram {
  --fa: "\f664";
  --fa--fa: "\f664\f664";
}

.fa-earth-oceania {
  --fa: "\e47b";
  --fa--fa: "\e47b\e47b";
}

.fa-globe-oceania {
  --fa: "\e47b";
  --fa--fa: "\e47b\e47b";
}

.fa-container-storage {
  --fa: "\f4b7";
  --fa--fa: "\f4b7\f4b7";
}

.fa-face-pouting {
  --fa: "\e387";
  --fa--fa: "\e387\e387";
}

.fa-square-xmark {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3";
}

.fa-times-square {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3";
}

.fa-xmark-square {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3";
}

.fa-face-explode {
  --fa: "\e2fe";
  --fa--fa: "\e2fe\e2fe";
}

.fa-exploding-head {
  --fa: "\e2fe";
  --fa--fa: "\e2fe\e2fe";
}

.fa-hashtag {
  --fa: "\#";
  --fa--fa: "\#\#";
}

.fa-up-right-and-down-left-from-center {
  --fa: "\f424";
  --fa--fa: "\f424\f424";
}

.fa-expand-alt {
  --fa: "\f424";
  --fa--fa: "\f424\f424";
}

.fa-oil-can {
  --fa: "\f613";
  --fa--fa: "\f613\f613";
}

.fa-t {
  --fa: "T";
  --fa--fa: "TT";
}

.fa-transformer-bolt {
  --fa: "\e2a4";
  --fa--fa: "\e2a4\e2a4";
}

.fa-hippo {
  --fa: "\f6ed";
  --fa--fa: "\f6ed\f6ed";
}

.fa-chart-column {
  --fa: "\e0e3";
  --fa--fa: "\e0e3\e0e3";
}

.fa-cassette-vhs {
  --fa: "\f8ec";
  --fa--fa: "\f8ec\f8ec";
}

.fa-vhs {
  --fa: "\f8ec";
  --fa--fa: "\f8ec\f8ec";
}

.fa-infinity {
  --fa: "\f534";
  --fa--fa: "\f534\f534";
}

.fa-vial-circle-check {
  --fa: "\e596";
  --fa--fa: "\e596\e596";
}

.fa-chimney {
  --fa: "\f78b";
  --fa--fa: "\f78b\f78b";
}

.fa-object-intersect {
  --fa: "\e49d";
  --fa--fa: "\e49d\e49d";
}

.fa-person-arrow-down-to-line {
  --fa: "\e538";
  --fa--fa: "\e538\e538";
}

.fa-voicemail {
  --fa: "\f897";
  --fa--fa: "\f897\f897";
}

.fa-block-brick {
  --fa: "\e3db";
  --fa--fa: "\e3db\e3db";
}

.fa-wall-brick {
  --fa: "\e3db";
  --fa--fa: "\e3db\e3db";
}

.fa-fan {
  --fa: "\f863";
  --fa--fa: "\f863\f863";
}

.fa-bags-shopping {
  --fa: "\f847";
  --fa--fa: "\f847\f847";
}

.fa-paragraph-left {
  --fa: "\f878";
  --fa--fa: "\f878\f878";
}

.fa-paragraph-rtl {
  --fa: "\f878";
  --fa--fa: "\f878\f878";
}

.fa-person-walking-luggage {
  --fa: "\e554";
  --fa--fa: "\e554\e554";
}

.fa-caravan-simple {
  --fa: "\e000";
  --fa--fa: "\e000\e000";
}

.fa-caravan-alt {
  --fa: "\e000";
  --fa--fa: "\e000\e000";
}

.fa-turtle {
  --fa: "\f726";
  --fa--fa: "\f726\f726";
}

.fa-pencil-mechanical {
  --fa: "\e5ca";
  --fa--fa: "\e5ca\e5ca";
}

.fa-up-down {
  --fa: "\f338";
  --fa--fa: "\f338\f338";
}

.fa-arrows-alt-v {
  --fa: "\f338";
  --fa--fa: "\f338\f338";
}

.fa-cloud-moon-rain {
  --fa: "\f73c";
  --fa--fa: "\f73c\f73c";
}

.fa-booth-curtain {
  --fa: "\f734";
  --fa--fa: "\f734\f734";
}

.fa-calendar {
  --fa: "\f133";
  --fa--fa: "\f133\f133";
}

.fa-box-heart {
  --fa: "\f49d";
  --fa--fa: "\f49d\f49d";
}

.fa-trailer {
  --fa: "\e041";
  --fa--fa: "\e041\e041";
}

.fa-user-doctor-message {
  --fa: "\f82e";
  --fa--fa: "\f82e\f82e";
}

.fa-user-md-chat {
  --fa: "\f82e";
  --fa--fa: "\f82e\f82e";
}

.fa-bahai {
  --fa: "\f666";
  --fa--fa: "\f666\f666";
}

.fa-haykal {
  --fa: "\f666";
  --fa--fa: "\f666\f666";
}

.fa-lighthouse {
  --fa: "\e612";
  --fa--fa: "\e612\e612";
}

.fa-amp-guitar {
  --fa: "\f8a1";
  --fa--fa: "\f8a1\f8a1";
}

.fa-sd-card {
  --fa: "\f7c2";
  --fa--fa: "\f7c2\f7c2";
}

.fa-volume-slash {
  --fa: "\f2e2";
  --fa--fa: "\f2e2\f2e2";
}

.fa-border-bottom {
  --fa: "\f84d";
  --fa--fa: "\f84d\f84d";
}

.fa-wifi-weak {
  --fa: "\f6aa";
  --fa--fa: "\f6aa\f6aa";
}

.fa-wifi-1 {
  --fa: "\f6aa";
  --fa--fa: "\f6aa\f6aa";
}

.fa-dragon {
  --fa: "\f6d5";
  --fa--fa: "\f6d5\f6d5";
}

.fa-shoe-prints {
  --fa: "\f54b";
  --fa--fa: "\f54b\f54b";
}

.fa-circle-plus {
  --fa: "\f055";
  --fa--fa: "\f055\f055";
}

.fa-plus-circle {
  --fa: "\f055";
  --fa--fa: "\f055\f055";
}

.fa-face-grin-tongue-wink {
  --fa: "\f58b";
  --fa--fa: "\f58b\f58b";
}

.fa-grin-tongue-wink {
  --fa: "\f58b";
  --fa--fa: "\f58b\f58b";
}

.fa-hand-holding {
  --fa: "\f4bd";
  --fa--fa: "\f4bd\f4bd";
}

.fa-plug-circle-exclamation {
  --fa: "\e55d";
  --fa--fa: "\e55d\e55d";
}

.fa-link-slash {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-chain-broken {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-chain-slash {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-unlink {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-clone {
  --fa: "\f24d";
  --fa--fa: "\f24d\f24d";
}

.fa-person-walking-arrow-loop-left {
  --fa: "\e551";
  --fa--fa: "\e551\e551";
}

.fa-arrow-up-z-a {
  --fa: "\f882";
  --fa--fa: "\f882\f882";
}

.fa-sort-alpha-up-alt {
  --fa: "\f882";
  --fa--fa: "\f882\f882";
}

.fa-fire-flame-curved {
  --fa: "\f7e4";
  --fa--fa: "\f7e4\f7e4";
}

.fa-fire-alt {
  --fa: "\f7e4";
  --fa--fa: "\f7e4\f7e4";
}

.fa-tornado {
  --fa: "\f76f";
  --fa--fa: "\f76f\f76f";
}

.fa-file-circle-plus {
  --fa: "\e494";
  --fa--fa: "\e494\e494";
}

.fa-delete-right {
  --fa: "\e154";
  --fa--fa: "\e154\e154";
}

.fa-book-quran {
  --fa: "\f687";
  --fa--fa: "\f687\f687";
}

.fa-quran {
  --fa: "\f687";
  --fa--fa: "\f687\f687";
}

.fa-circle-quarter {
  --fa: "\e11f";
  --fa--fa: "\e11f\e11f";
}

.fa-anchor {
  --fa: "\f13d";
  --fa--fa: "\f13d\f13d";
}

.fa-border-all {
  --fa: "\f84c";
  --fa--fa: "\f84c\f84c";
}

.fa-function {
  --fa: "\f661";
  --fa--fa: "\f661\f661";
}

.fa-face-angry {
  --fa: "\f556";
  --fa--fa: "\f556\f556";
}

.fa-angry {
  --fa: "\f556";
  --fa--fa: "\f556\f556";
}

.fa-people-simple {
  --fa: "\e21b";
  --fa--fa: "\e21b\e21b";
}

.fa-cookie-bite {
  --fa: "\f564";
  --fa--fa: "\f564\f564";
}

.fa-arrow-trend-down {
  --fa: "\e097";
  --fa--fa: "\e097\e097";
}

.fa-rss {
  --fa: "\f09e";
  --fa--fa: "\f09e\f09e";
}

.fa-feed {
  --fa: "\f09e";
  --fa--fa: "\f09e\f09e";
}

.fa-face-monocle {
  --fa: "\e380";
  --fa--fa: "\e380\e380";
}

.fa-draw-polygon {
  --fa: "\f5ee";
  --fa--fa: "\f5ee\f5ee";
}

.fa-scale-balanced {
  --fa: "\f24e";
  --fa--fa: "\f24e\f24e";
}

.fa-balance-scale {
  --fa: "\f24e";
  --fa--fa: "\f24e\f24e";
}

.fa-calendar-lines {
  --fa: "\e0d5";
  --fa--fa: "\e0d5\e0d5";
}

.fa-calendar-note {
  --fa: "\e0d5";
  --fa--fa: "\e0d5\e0d5";
}

.fa-arrow-down-big-small {
  --fa: "\f88c";
  --fa--fa: "\f88c\f88c";
}

.fa-sort-size-down {
  --fa: "\f88c";
  --fa--fa: "\f88c\f88c";
}

.fa-gauge-simple-high {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a";
}

.fa-tachometer {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a";
}

.fa-tachometer-fast {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a";
}

.fa-do-not-enter {
  --fa: "\f5ec";
  --fa--fa: "\f5ec\f5ec";
}

.fa-shower {
  --fa: "\f2cc";
  --fa--fa: "\f2cc\f2cc";
}

.fa-dice-d8 {
  --fa: "\f6d2";
  --fa--fa: "\f6d2\f6d2";
}

.fa-desktop {
  --fa: "\f390";
  --fa--fa: "\f390\f390";
}

.fa-desktop-alt {
  --fa: "\f390";
  --fa--fa: "\f390\f390";
}

.fa-m {
  --fa: "M";
  --fa--fa: "MM";
}

.fa-spinner-scale {
  --fa: "\e62a";
  --fa--fa: "\e62a\e62a";
}

.fa-grip-dots-vertical {
  --fa: "\e411";
  --fa--fa: "\e411\e411";
}

.fa-face-viewfinder {
  --fa: "\e2ff";
  --fa--fa: "\e2ff\e2ff";
}

.fa-soft-serve {
  --fa: "\e400";
  --fa--fa: "\e400\e400";
}

.fa-creemee {
  --fa: "\e400";
  --fa--fa: "\e400\e400";
}

.fa-h5 {
  --fa: "\e412";
  --fa--fa: "\e412\e412";
}

.fa-hand-back-point-down {
  --fa: "\e19e";
  --fa--fa: "\e19e\e19e";
}

.fa-table-list {
  --fa: "\f00b";
  --fa--fa: "\f00b\f00b";
}

.fa-th-list {
  --fa: "\f00b";
  --fa--fa: "\f00b\f00b";
}

.fa-basket-shopping-minus {
  --fa: "\e652";
  --fa--fa: "\e652\e652";
}

.fa-comment-sms {
  --fa: "\f7cd";
  --fa--fa: "\f7cd\f7cd";
}

.fa-sms {
  --fa: "\f7cd";
  --fa--fa: "\f7cd\f7cd";
}

.fa-rectangle {
  --fa: "\f2fa";
  --fa--fa: "\f2fa\f2fa";
}

.fa-rectangle-landscape {
  --fa: "\f2fa";
  --fa--fa: "\f2fa\f2fa";
}

.fa-clipboard-list-check {
  --fa: "\f737";
  --fa--fa: "\f737\f737";
}

.fa-turkey {
  --fa: "\f725";
  --fa--fa: "\f725\f725";
}

.fa-book {
  --fa: "\f02d";
  --fa--fa: "\f02d\f02d";
}

.fa-user-plus {
  --fa: "\f234";
  --fa--fa: "\f234\f234";
}

.fa-ice-skate {
  --fa: "\f7ac";
  --fa--fa: "\f7ac\f7ac";
}

.fa-check {
  --fa: "\f00c";
  --fa--fa: "\f00c\f00c";
}

.fa-battery-three-quarters {
  --fa: "\f241";
  --fa--fa: "\f241\f241";
}

.fa-battery-4 {
  --fa: "\f241";
  --fa--fa: "\f241\f241";
}

.fa-tomato {
  --fa: "\e330";
  --fa--fa: "\e330\e330";
}

.fa-sword-laser {
  --fa: "\e03b";
  --fa--fa: "\e03b\e03b";
}

.fa-house-circle-check {
  --fa: "\e509";
  --fa--fa: "\e509\e509";
}

.fa-buildings {
  --fa: "\e0cc";
  --fa--fa: "\e0cc\e0cc";
}

.fa-angle-left {
  --fa: "\f104";
  --fa--fa: "\f104\f104";
}

.fa-cart-flatbed-boxes {
  --fa: "\f475";
  --fa--fa: "\f475\f475";
}

.fa-dolly-flatbed-alt {
  --fa: "\f475";
  --fa--fa: "\f475\f475";
}

.fa-diagram-successor {
  --fa: "\e47a";
  --fa--fa: "\e47a\e47a";
}

.fa-truck-arrow-right {
  --fa: "\e58b";
  --fa--fa: "\e58b\e58b";
}

.fa-square-w {
  --fa: "\e285";
  --fa--fa: "\e285\e285";
}

.fa-arrows-split-up-and-left {
  --fa: "\e4bc";
  --fa--fa: "\e4bc\e4bc";
}

.fa-lamp {
  --fa: "\f4ca";
  --fa--fa: "\f4ca\f4ca";
}

.fa-airplay {
  --fa: "\e089";
  --fa--fa: "\e089\e089";
}

.fa-hand-fist {
  --fa: "\f6de";
  --fa--fa: "\f6de\f6de";
}

.fa-fist-raised {
  --fa: "\f6de";
  --fa--fa: "\f6de\f6de";
}

.fa-shield-quartered {
  --fa: "\e575";
  --fa--fa: "\e575\e575";
}

.fa-slash-forward {
  --fa: "\/";
  --fa--fa: "\/\/";
}

.fa-location-pen {
  --fa: "\f607";
  --fa--fa: "\f607\f607";
}

.fa-map-marker-edit {
  --fa: "\f607";
  --fa--fa: "\f607\f607";
}

.fa-cloud-moon {
  --fa: "\f6c3";
  --fa--fa: "\f6c3\f6c3";
}

.fa-pot-food {
  --fa: "\e43f";
  --fa--fa: "\e43f\e43f";
}

.fa-briefcase {
  --fa: "\f0b1";
  --fa--fa: "\f0b1\f0b1";
}

.fa-person-falling {
  --fa: "\e546";
  --fa--fa: "\e546\e546";
}

.fa-image-portrait {
  --fa: "\f3e0";
  --fa--fa: "\f3e0\f3e0";
}

.fa-portrait {
  --fa: "\f3e0";
  --fa--fa: "\f3e0\f3e0";
}

.fa-user-tag {
  --fa: "\f507";
  --fa--fa: "\f507\f507";
}

.fa-rug {
  --fa: "\e569";
  --fa--fa: "\e569\e569";
}

.fa-print-slash {
  --fa: "\f686";
  --fa--fa: "\f686\f686";
}

.fa-earth-europe {
  --fa: "\f7a2";
  --fa--fa: "\f7a2\f7a2";
}

.fa-globe-europe {
  --fa: "\f7a2";
  --fa--fa: "\f7a2\f7a2";
}

.fa-cart-flatbed-suitcase {
  --fa: "\f59d";
  --fa--fa: "\f59d\f59d";
}

.fa-luggage-cart {
  --fa: "\f59d";
  --fa--fa: "\f59d\f59d";
}

.fa-hand-back-point-ribbon {
  --fa: "\e1a0";
  --fa--fa: "\e1a0\e1a0";
}

.fa-rectangle-xmark {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-rectangle-times {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-times-rectangle {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-window-close {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-tire-rugged {
  --fa: "\f634";
  --fa--fa: "\f634\f634";
}

.fa-lightbulb-dollar {
  --fa: "\f670";
  --fa--fa: "\f670\f670";
}

.fa-cowbell {
  --fa: "\f8b3";
  --fa--fa: "\f8b3\f8b3";
}

.fa-baht-sign {
  --fa: "\e0ac";
  --fa--fa: "\e0ac\e0ac";
}

.fa-corner {
  --fa: "\e3fe";
  --fa--fa: "\e3fe\e3fe";
}

.fa-chevrons-right {
  --fa: "\f324";
  --fa--fa: "\f324\f324";
}

.fa-chevron-double-right {
  --fa: "\f324";
  --fa--fa: "\f324\f324";
}

.fa-book-open {
  --fa: "\f518";
  --fa--fa: "\f518\f518";
}

.fa-book-journal-whills {
  --fa: "\f66a";
  --fa--fa: "\f66a\f66a";
}

.fa-journal-whills {
  --fa: "\f66a";
  --fa--fa: "\f66a\f66a";
}

.fa-inhaler {
  --fa: "\f5f9";
  --fa--fa: "\f5f9\f5f9";
}

.fa-handcuffs {
  --fa: "\e4f8";
  --fa--fa: "\e4f8\e4f8";
}

.fa-snake {
  --fa: "\f716";
  --fa--fa: "\f716\f716";
}

.fa-triangle-exclamation {
  --fa: "\f071";
  --fa--fa: "\f071\f071";
}

.fa-exclamation-triangle {
  --fa: "\f071";
  --fa--fa: "\f071\f071";
}

.fa-warning {
  --fa: "\f071";
  --fa--fa: "\f071\f071";
}

.fa-note-medical {
  --fa: "\e200";
  --fa--fa: "\e200\e200";
}

.fa-database {
  --fa: "\f1c0";
  --fa--fa: "\f1c0\f1c0";
}

.fa-down-left {
  --fa: "\e16a";
  --fa--fa: "\e16a\e16a";
}

.fa-share {
  --fa: "\f064";
  --fa--fa: "\f064\f064";
}

.fa-mail-forward {
  --fa: "\f064";
  --fa--fa: "\f064\f064";
}

.fa-face-thinking {
  --fa: "\e39b";
  --fa--fa: "\e39b\e39b";
}

.fa-turn-down-right {
  --fa: "\e455";
  --fa--fa: "\e455\e455";
}

.fa-bottle-droplet {
  --fa: "\e4c4";
  --fa--fa: "\e4c4\e4c4";
}

.fa-mask-face {
  --fa: "\e1d7";
  --fa--fa: "\e1d7\e1d7";
}

.fa-hill-rockslide {
  --fa: "\e508";
  --fa--fa: "\e508\e508";
}

.fa-scanner-keyboard {
  --fa: "\f489";
  --fa--fa: "\f489\f489";
}

.fa-circle-o {
  --fa: "\e119";
  --fa--fa: "\e119\e119";
}

.fa-grid-horizontal {
  --fa: "\e307";
  --fa--fa: "\e307\e307";
}

.fa-message-dollar {
  --fa: "\f650";
  --fa--fa: "\f650\f650";
}

.fa-comment-alt-dollar {
  --fa: "\f650";
  --fa--fa: "\f650\f650";
}

.fa-right-left {
  --fa: "\f362";
  --fa--fa: "\f362\f362";
}

.fa-exchange-alt {
  --fa: "\f362";
  --fa--fa: "\f362\f362";
}

.fa-columns-3 {
  --fa: "\e361";
  --fa--fa: "\e361\e361";
}

.fa-paper-plane {
  --fa: "\f1d8";
  --fa--fa: "\f1d8\f1d8";
}

.fa-road-circle-exclamation {
  --fa: "\e565";
  --fa--fa: "\e565\e565";
}

.fa-dungeon {
  --fa: "\f6d9";
  --fa--fa: "\f6d9\f6d9";
}

.fa-hand-holding-box {
  --fa: "\f47b";
  --fa--fa: "\f47b\f47b";
}

.fa-input-text {
  --fa: "\e1bf";
  --fa--fa: "\e1bf\e1bf";
}

.fa-window-flip {
  --fa: "\f40f";
  --fa--fa: "\f40f\f40f";
}

.fa-window-alt {
  --fa: "\f40f";
  --fa--fa: "\f40f\f40f";
}

.fa-align-right {
  --fa: "\f038";
  --fa--fa: "\f038\f038";
}

.fa-scanner-gun {
  --fa: "\f488";
  --fa--fa: "\f488\f488";
}

.fa-scanner {
  --fa: "\f488";
  --fa--fa: "\f488\f488";
}

.fa-tire {
  --fa: "\f631";
  --fa--fa: "\f631\f631";
}

.fa-engine {
  --fa: "\e16e";
  --fa--fa: "\e16e\e16e";
}

.fa-money-bill-1-wave {
  --fa: "\f53b";
  --fa--fa: "\f53b\f53b";
}

.fa-money-bill-wave-alt {
  --fa: "\f53b";
  --fa--fa: "\f53b\f53b";
}

.fa-life-ring {
  --fa: "\f1cd";
  --fa--fa: "\f1cd\f1cd";
}

.fa-hands {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7";
}

.fa-sign-language {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7";
}

.fa-signing {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7";
}

.fa-circle-caret-right {
  --fa: "\f330";
  --fa--fa: "\f330\f330";
}

.fa-caret-circle-right {
  --fa: "\f330";
  --fa--fa: "\f330\f330";
}

.fa-turn-left {
  --fa: "\e636";
  --fa--fa: "\e636\e636";
}

.fa-wheat {
  --fa: "\f72d";
  --fa--fa: "\f72d\f72d";
}

.fa-file-spreadsheet {
  --fa: "\f65b";
  --fa--fa: "\f65b\f65b";
}

.fa-audio-description-slash {
  --fa: "\e0a8";
  --fa--fa: "\e0a8\e0a8";
}

.fa-bell-ring {
  --fa: "\e62c";
  --fa--fa: "\e62c\e62c";
}

.fa-calendar-day {
  --fa: "\f783";
  --fa--fa: "\f783\f783";
}

.fa-water-ladder {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5";
}

.fa-ladder-water {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5";
}

.fa-swimming-pool {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5";
}

.fa-arrows-up-down {
  --fa: "\f07d";
  --fa--fa: "\f07d\f07d";
}

.fa-arrows-v {
  --fa: "\f07d";
  --fa--fa: "\f07d\f07d";
}

.fa-chess-pawn-piece {
  --fa: "\f444";
  --fa--fa: "\f444\f444";
}

.fa-chess-pawn-alt {
  --fa: "\f444";
  --fa--fa: "\f444\f444";
}

.fa-face-grimace {
  --fa: "\f57f";
  --fa--fa: "\f57f\f57f";
}

.fa-grimace {
  --fa: "\f57f";
  --fa--fa: "\f57f\f57f";
}

.fa-wheelchair-move {
  --fa: "\e2ce";
  --fa--fa: "\e2ce\e2ce";
}

.fa-wheelchair-alt {
  --fa: "\e2ce";
  --fa--fa: "\e2ce\e2ce";
}

.fa-turn-down {
  --fa: "\f3be";
  --fa--fa: "\f3be\f3be";
}

.fa-level-down-alt {
  --fa: "\f3be";
  --fa--fa: "\f3be\f3be";
}

.fa-square-s {
  --fa: "\e27d";
  --fa--fa: "\e27d\e27d";
}

.fa-rectangle-barcode {
  --fa: "\f463";
  --fa--fa: "\f463\f463";
}

.fa-barcode-alt {
  --fa: "\f463";
  --fa--fa: "\f463\f463";
}

.fa-person-walking-arrow-right {
  --fa: "\e552";
  --fa--fa: "\e552\e552";
}

.fa-square-envelope {
  --fa: "\f199";
  --fa--fa: "\f199\f199";
}

.fa-envelope-square {
  --fa: "\f199";
  --fa--fa: "\f199\f199";
}

.fa-dice {
  --fa: "\f522";
  --fa--fa: "\f522\f522";
}

.fa-unicorn {
  --fa: "\f727";
  --fa--fa: "\f727\f727";
}

.fa-bowling-ball {
  --fa: "\f436";
  --fa--fa: "\f436\f436";
}

.fa-pompebled {
  --fa: "\e43d";
  --fa--fa: "\e43d\e43d";
}

.fa-brain {
  --fa: "\f5dc";
  --fa--fa: "\f5dc\f5dc";
}

.fa-watch-smart {
  --fa: "\e2cc";
  --fa--fa: "\e2cc\e2cc";
}

.fa-book-user {
  --fa: "\f7e7";
  --fa--fa: "\f7e7\f7e7";
}

.fa-sensor-cloud {
  --fa: "\e02c";
  --fa--fa: "\e02c\e02c";
}

.fa-sensor-smoke {
  --fa: "\e02c";
  --fa--fa: "\e02c\e02c";
}

.fa-clapperboard-play {
  --fa: "\e132";
  --fa--fa: "\e132\e132";
}

.fa-bandage {
  --fa: "\f462";
  --fa--fa: "\f462\f462";
}

.fa-band-aid {
  --fa: "\f462";
  --fa--fa: "\f462\f462";
}

.fa-calendar-minus {
  --fa: "\f272";
  --fa--fa: "\f272\f272";
}

.fa-circle-xmark {
  --fa: "\f057";
  --fa--fa: "\f057\f057";
}

.fa-times-circle {
  --fa: "\f057";
  --fa--fa: "\f057\f057";
}

.fa-xmark-circle {
  --fa: "\f057";
  --fa--fa: "\f057\f057";
}

.fa-circle-4 {
  --fa: "\e0f1";
  --fa--fa: "\e0f1\e0f1";
}

.fa-gifts {
  --fa: "\f79c";
  --fa--fa: "\f79c\f79c";
}

.fa-album-collection {
  --fa: "\f8a0";
  --fa--fa: "\f8a0\f8a0";
}

.fa-hotel {
  --fa: "\f594";
  --fa--fa: "\f594\f594";
}

.fa-earth-asia {
  --fa: "\f57e";
  --fa--fa: "\f57e\f57e";
}

.fa-globe-asia {
  --fa: "\f57e";
  --fa--fa: "\f57e\f57e";
}

.fa-id-card-clip {
  --fa: "\f47f";
  --fa--fa: "\f47f\f47f";
}

.fa-id-card-alt {
  --fa: "\f47f";
  --fa--fa: "\f47f\f47f";
}

.fa-magnifying-glass-plus {
  --fa: "\f00e";
  --fa--fa: "\f00e\f00e";
}

.fa-search-plus {
  --fa: "\f00e";
  --fa--fa: "\f00e\f00e";
}

.fa-thumbs-up {
  --fa: "\f164";
  --fa--fa: "\f164\f164";
}

.fa-cloud-showers {
  --fa: "\f73f";
  --fa--fa: "\f73f\f73f";
}

.fa-user-clock {
  --fa: "\f4fd";
  --fa--fa: "\f4fd\f4fd";
}

.fa-onion {
  --fa: "\e427";
  --fa--fa: "\e427\e427";
}

.fa-clock-twelve-thirty {
  --fa: "\e359";
  --fa--fa: "\e359\e359";
}

.fa-arrow-down-to-dotted-line {
  --fa: "\e095";
  --fa--fa: "\e095\e095";
}

.fa-hand-dots {
  --fa: "\f461";
  --fa--fa: "\f461\f461";
}

.fa-allergies {
  --fa: "\f461";
  --fa--fa: "\f461\f461";
}

.fa-file-invoice {
  --fa: "\f570";
  --fa--fa: "\f570\f570";
}

.fa-window-minimize {
  --fa: "\f2d1";
  --fa--fa: "\f2d1\f2d1";
}

.fa-rectangle-wide {
  --fa: "\f2fc";
  --fa--fa: "\f2fc\f2fc";
}

.fa-comment-arrow-up {
  --fa: "\e144";
  --fa--fa: "\e144\e144";
}

.fa-garlic {
  --fa: "\e40e";
  --fa--fa: "\e40e\e40e";
}

.fa-mug-saucer {
  --fa: "\f0f4";
  --fa--fa: "\f0f4\f0f4";
}

.fa-coffee {
  --fa: "\f0f4";
  --fa--fa: "\f0f4\f0f4";
}

.fa-brush {
  --fa: "\f55d";
  --fa--fa: "\f55d\f55d";
}

.fa-file-half-dashed {
  --fa: "\e698";
  --fa--fa: "\e698\e698";
}

.fa-tree-decorated {
  --fa: "\f7dc";
  --fa--fa: "\f7dc\f7dc";
}

.fa-mask {
  --fa: "\f6fa";
  --fa--fa: "\f6fa\f6fa";
}

.fa-calendar-heart {
  --fa: "\e0d3";
  --fa--fa: "\e0d3\e0d3";
}

.fa-magnifying-glass-minus {
  --fa: "\f010";
  --fa--fa: "\f010\f010";
}

.fa-search-minus {
  --fa: "\f010";
  --fa--fa: "\f010\f010";
}

.fa-flower {
  --fa: "\f7ff";
  --fa--fa: "\f7ff\f7ff";
}

.fa-arrow-down-from-arc {
  --fa: "\e614";
  --fa--fa: "\e614\e614";
}

.fa-right-left-large {
  --fa: "\e5e1";
  --fa--fa: "\e5e1\e5e1";
}

.fa-ruler-vertical {
  --fa: "\f548";
  --fa--fa: "\f548\f548";
}

.fa-circles-overlap {
  --fa: "\e600";
  --fa--fa: "\e600\e600";
}

.fa-user-large {
  --fa: "\f406";
  --fa--fa: "\f406\f406";
}

.fa-user-alt {
  --fa: "\f406";
  --fa--fa: "\f406\f406";
}

.fa-starship-freighter {
  --fa: "\e03a";
  --fa--fa: "\e03a\e03a";
}

.fa-train-tram {
  --fa: "\e5b4";
  --fa--fa: "\e5b4\e5b4";
}

.fa-bridge-suspension {
  --fa: "\e4cd";
  --fa--fa: "\e4cd\e4cd";
}

.fa-trash-check {
  --fa: "\e2af";
  --fa--fa: "\e2af\e2af";
}

.fa-user-nurse {
  --fa: "\f82f";
  --fa--fa: "\f82f\f82f";
}

.fa-boombox {
  --fa: "\f8a5";
  --fa--fa: "\f8a5\f8a5";
}

.fa-syringe {
  --fa: "\f48e";
  --fa--fa: "\f48e\f48e";
}

.fa-cloud-sun {
  --fa: "\f6c4";
  --fa--fa: "\f6c4\f6c4";
}

.fa-shield-exclamation {
  --fa: "\e247";
  --fa--fa: "\e247\e247";
}

.fa-stopwatch-20 {
  --fa: "\e06f";
  --fa--fa: "\e06f\e06f";
}

.fa-square-full {
  --fa: "\f45c";
  --fa--fa: "\f45c\f45c";
}

.fa-grip-dots {
  --fa: "\e410";
  --fa--fa: "\e410\e410";
}

.fa-comment-exclamation {
  --fa: "\f4af";
  --fa--fa: "\f4af\f4af";
}

.fa-pen-swirl {
  --fa: "\e214";
  --fa--fa: "\e214\e214";
}

.fa-falafel {
  --fa: "\e40a";
  --fa--fa: "\e40a\e40a";
}

.fa-circle-2 {
  --fa: "\e0ef";
  --fa--fa: "\e0ef\e0ef";
}

.fa-magnet {
  --fa: "\f076";
  --fa--fa: "\f076\f076";
}

.fa-jar {
  --fa: "\e516";
  --fa--fa: "\e516\e516";
}

.fa-gramophone {
  --fa: "\f8bd";
  --fa--fa: "\f8bd\f8bd";
}

.fa-dice-d12 {
  --fa: "\f6ce";
  --fa--fa: "\f6ce\f6ce";
}

.fa-note-sticky {
  --fa: "\f249";
  --fa--fa: "\f249\f249";
}

.fa-sticky-note {
  --fa: "\f249";
  --fa--fa: "\f249\f249";
}

.fa-down {
  --fa: "\f354";
  --fa--fa: "\f354\f354";
}

.fa-arrow-alt-down {
  --fa: "\f354";
  --fa--fa: "\f354\f354";
}

.fa-hundred-points {
  --fa: "\e41c";
  --fa--fa: "\e41c\e41c";
}

.fa-100 {
  --fa: "\e41c";
  --fa--fa: "\e41c\e41c";
}

.fa-paperclip-vertical {
  --fa: "\e3c2";
  --fa--fa: "\e3c2\e3c2";
}

.fa-wind-warning {
  --fa: "\f776";
  --fa--fa: "\f776\f776";
}

.fa-wind-circle-exclamation {
  --fa: "\f776";
  --fa--fa: "\f776\f776";
}

.fa-location-pin-slash {
  --fa: "\f60c";
  --fa--fa: "\f60c\f60c";
}

.fa-map-marker-slash {
  --fa: "\f60c";
  --fa--fa: "\f60c\f60c";
}

.fa-face-sad-sweat {
  --fa: "\e38a";
  --fa--fa: "\e38a\e38a";
}

.fa-bug-slash {
  --fa: "\e490";
  --fa--fa: "\e490\e490";
}

.fa-cupcake {
  --fa: "\e402";
  --fa--fa: "\e402\e402";
}

.fa-light-switch-off {
  --fa: "\e018";
  --fa--fa: "\e018\e018";
}

.fa-toggle-large-off {
  --fa: "\e5b0";
  --fa--fa: "\e5b0\e5b0";
}

.fa-pen-fancy-slash {
  --fa: "\e210";
  --fa--fa: "\e210\e210";
}

.fa-truck-container {
  --fa: "\f4dc";
  --fa--fa: "\f4dc\f4dc";
}

.fa-boot {
  --fa: "\f782";
  --fa--fa: "\f782\f782";
}

.fa-arrow-up-from-water-pump {
  --fa: "\e4b6";
  --fa--fa: "\e4b6\e4b6";
}

.fa-file-check {
  --fa: "\f316";
  --fa--fa: "\f316\f316";
}

.fa-bone {
  --fa: "\f5d7";
  --fa--fa: "\f5d7\f5d7";
}

.fa-cards-blank {
  --fa: "\e4df";
  --fa--fa: "\e4df\e4df";
}

.fa-circle-3 {
  --fa: "\e0f0";
  --fa--fa: "\e0f0\e0f0";
}

.fa-bench-tree {
  --fa: "\e2e7";
  --fa--fa: "\e2e7\e2e7";
}

.fa-keyboard-brightness-low {
  --fa: "\e1c1";
  --fa--fa: "\e1c1\e1c1";
}

.fa-ski-boot-ski {
  --fa: "\e3cd";
  --fa--fa: "\e3cd\e3cd";
}

.fa-brain-circuit {
  --fa: "\e0c6";
  --fa--fa: "\e0c6\e0c6";
}

.fa-table-cells-row-unlock {
  --fa: "\e691";
  --fa--fa: "\e691\e691";
}

.fa-user-injured {
  --fa: "\f728";
  --fa--fa: "\f728\f728";
}

.fa-block-brick-fire {
  --fa: "\e3dc";
  --fa--fa: "\e3dc\e3dc";
}

.fa-firewall {
  --fa: "\e3dc";
  --fa--fa: "\e3dc\e3dc";
}

.fa-face-sad-tear {
  --fa: "\f5b4";
  --fa--fa: "\f5b4\f5b4";
}

.fa-sad-tear {
  --fa: "\f5b4";
  --fa--fa: "\f5b4\f5b4";
}

.fa-plane {
  --fa: "\f072";
  --fa--fa: "\f072\f072";
}

.fa-tent-arrows-down {
  --fa: "\e581";
  --fa--fa: "\e581\e581";
}

.fa-exclamation {
  --fa: "\!";
  --fa--fa: "\!\!";
}

.fa-arrows-spin {
  --fa: "\e4bb";
  --fa--fa: "\e4bb\e4bb";
}

.fa-face-smile-relaxed {
  --fa: "\e392";
  --fa--fa: "\e392\e392";
}

.fa-comment-xmark {
  --fa: "\f4b5";
  --fa--fa: "\f4b5\f4b5";
}

.fa-comment-times {
  --fa: "\f4b5";
  --fa--fa: "\f4b5\f4b5";
}

.fa-print {
  --fa: "\f02f";
  --fa--fa: "\f02f\f02f";
}

.fa-turkish-lira-sign {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb";
}

.fa-try {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb";
}

.fa-turkish-lira {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb";
}

.fa-face-nose-steam {
  --fa: "\e382";
  --fa--fa: "\e382\e382";
}

.fa-circle-waveform-lines {
  --fa: "\e12d";
  --fa--fa: "\e12d\e12d";
}

.fa-waveform-circle {
  --fa: "\e12d";
  --fa--fa: "\e12d\e12d";
}

.fa-dollar-sign {
  --fa: "\$";
  --fa--fa: "\$\$";
}

.fa-dollar {
  --fa: "\$";
  --fa--fa: "\$\$";
}

.fa-usd {
  --fa: "\$";
  --fa--fa: "\$\$";
}

.fa-ferris-wheel {
  --fa: "\e174";
  --fa--fa: "\e174\e174";
}

.fa-computer-speaker {
  --fa: "\f8b2";
  --fa--fa: "\f8b2\f8b2";
}

.fa-skull-cow {
  --fa: "\f8de";
  --fa--fa: "\f8de\f8de";
}

.fa-x {
  --fa: "X";
  --fa--fa: "XX";
}

.fa-magnifying-glass-dollar {
  --fa: "\f688";
  --fa--fa: "\f688\f688";
}

.fa-search-dollar {
  --fa: "\f688";
  --fa--fa: "\f688\f688";
}

.fa-users-gear {
  --fa: "\f509";
  --fa--fa: "\f509\f509";
}

.fa-users-cog {
  --fa: "\f509";
  --fa--fa: "\f509\f509";
}

.fa-person-military-pointing {
  --fa: "\e54a";
  --fa--fa: "\e54a\e54a";
}

.fa-building-columns {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-bank {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-institution {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-museum {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-university {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-circle-t {
  --fa: "\e124";
  --fa--fa: "\e124\e124";
}

.fa-sack {
  --fa: "\f81c";
  --fa--fa: "\f81c\f81c";
}

.fa-grid-2 {
  --fa: "\e196";
  --fa--fa: "\e196\e196";
}

.fa-camera-cctv {
  --fa: "\f8ac";
  --fa--fa: "\f8ac\f8ac";
}

.fa-cctv {
  --fa: "\f8ac";
  --fa--fa: "\f8ac\f8ac";
}

.fa-umbrella {
  --fa: "\f0e9";
  --fa--fa: "\f0e9\f0e9";
}

.fa-trowel {
  --fa: "\e589";
  --fa--fa: "\e589\e589";
}

.fa-horizontal-rule {
  --fa: "\f86c";
  --fa--fa: "\f86c\f86c";
}

.fa-bed-front {
  --fa: "\f8f7";
  --fa--fa: "\f8f7\f8f7";
}

.fa-bed-alt {
  --fa: "\f8f7";
  --fa--fa: "\f8f7\f8f7";
}

.fa-d {
  --fa: "D";
  --fa--fa: "DD";
}

.fa-stapler {
  --fa: "\e5af";
  --fa--fa: "\e5af\e5af";
}

.fa-masks-theater {
  --fa: "\f630";
  --fa--fa: "\f630\f630";
}

.fa-theater-masks {
  --fa: "\f630";
  --fa--fa: "\f630\f630";
}

.fa-file-gif {
  --fa: "\e645";
  --fa--fa: "\e645\e645";
}

.fa-kip-sign {
  --fa: "\e1c4";
  --fa--fa: "\e1c4\e1c4";
}

.fa-face-woozy {
  --fa: "\e3a2";
  --fa--fa: "\e3a2\e3a2";
}

.fa-cloud-question {
  --fa: "\e492";
  --fa--fa: "\e492\e492";
}

.fa-pineapple {
  --fa: "\e31f";
  --fa--fa: "\e31f\e31f";
}

.fa-hand-point-left {
  --fa: "\f0a5";
  --fa--fa: "\f0a5\f0a5";
}

.fa-gallery-thumbnails {
  --fa: "\e3aa";
  --fa--fa: "\e3aa\e3aa";
}

.fa-circle-j {
  --fa: "\e112";
  --fa--fa: "\e112\e112";
}

.fa-eyes {
  --fa: "\e367";
  --fa--fa: "\e367\e367";
}

.fa-handshake-simple {
  --fa: "\f4c6";
  --fa--fa: "\f4c6\f4c6";
}

.fa-handshake-alt {
  --fa: "\f4c6";
  --fa--fa: "\f4c6\f4c6";
}

.fa-page-caret-up {
  --fa: "\e42a";
  --fa--fa: "\e42a\e42a";
}

.fa-file-caret-up {
  --fa: "\e42a";
  --fa--fa: "\e42a\e42a";
}

.fa-jet-fighter {
  --fa: "\f0fb";
  --fa--fa: "\f0fb\f0fb";
}

.fa-fighter-jet {
  --fa: "\f0fb";
  --fa--fa: "\f0fb\f0fb";
}

.fa-comet {
  --fa: "\e003";
  --fa--fa: "\e003\e003";
}

.fa-square-share-nodes {
  --fa: "\f1e1";
  --fa--fa: "\f1e1\f1e1";
}

.fa-share-alt-square {
  --fa: "\f1e1";
  --fa--fa: "\f1e1\f1e1";
}

.fa-reflect-vertical {
  --fa: "\e665";
  --fa--fa: "\e665\e665";
}

.fa-shield-keyhole {
  --fa: "\e248";
  --fa--fa: "\e248\e248";
}

.fa-file-mp4 {
  --fa: "\e649";
  --fa--fa: "\e649\e649";
}

.fa-barcode {
  --fa: "\f02a";
  --fa--fa: "\f02a\f02a";
}

.fa-bulldozer {
  --fa: "\e655";
  --fa--fa: "\e655\e655";
}

.fa-plus-minus {
  --fa: "\e43c";
  --fa--fa: "\e43c\e43c";
}

.fa-square-sliders-vertical {
  --fa: "\f3f2";
  --fa--fa: "\f3f2\f3f2";
}

.fa-sliders-v-square {
  --fa: "\f3f2";
  --fa--fa: "\f3f2\f3f2";
}

.fa-video {
  --fa: "\f03d";
  --fa--fa: "\f03d\f03d";
}

.fa-video-camera {
  --fa: "\f03d";
  --fa--fa: "\f03d\f03d";
}

.fa-message-middle {
  --fa: "\e1e1";
  --fa--fa: "\e1e1\e1e1";
}

.fa-comment-middle-alt {
  --fa: "\e1e1";
  --fa--fa: "\e1e1\e1e1";
}

.fa-graduation-cap {
  --fa: "\f19d";
  --fa--fa: "\f19d\f19d";
}

.fa-mortar-board {
  --fa: "\f19d";
  --fa--fa: "\f19d\f19d";
}

.fa-hand-holding-medical {
  --fa: "\e05c";
  --fa--fa: "\e05c\e05c";
}

.fa-person-circle-check {
  --fa: "\e53e";
  --fa--fa: "\e53e\e53e";
}

.fa-square-z {
  --fa: "\e288";
  --fa--fa: "\e288\e288";
}

.fa-message-text {
  --fa: "\e1e6";
  --fa--fa: "\e1e6\e1e6";
}

.fa-comment-alt-text {
  --fa: "\e1e6";
  --fa--fa: "\e1e6\e1e6";
}

.fa-turn-up {
  --fa: "\f3bf";
  --fa--fa: "\f3bf\f3bf";
}

.fa-level-up-alt {
  --fa: "\f3bf";
  --fa--fa: "\f3bf\f3bf";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-weight: 300;
}

/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-weight: 400;
}

.fa-monero {
  --fa: "\f3d0";
}

.fa-hooli {
  --fa: "\f427";
}

.fa-yelp {
  --fa: "\f1e9";
}

.fa-cc-visa {
  --fa: "\f1f0";
}

.fa-lastfm {
  --fa: "\f202";
}

.fa-shopware {
  --fa: "\f5b5";
}

.fa-creative-commons-nc {
  --fa: "\f4e8";
}

.fa-aws {
  --fa: "\f375";
}

.fa-redhat {
  --fa: "\f7bc";
}

.fa-yoast {
  --fa: "\f2b1";
}

.fa-cloudflare {
  --fa: "\e07d";
}

.fa-ups {
  --fa: "\f7e0";
}

.fa-pixiv {
  --fa: "\e640";
}

.fa-wpexplorer {
  --fa: "\f2de";
}

.fa-dyalog {
  --fa: "\f399";
}

.fa-bity {
  --fa: "\f37a";
}

.fa-stackpath {
  --fa: "\f842";
}

.fa-buysellads {
  --fa: "\f20d";
}

.fa-first-order {
  --fa: "\f2b0";
}

.fa-modx {
  --fa: "\f285";
}

.fa-guilded {
  --fa: "\e07e";
}

.fa-vnv {
  --fa: "\f40b";
}

.fa-square-js {
  --fa: "\f3b9";
}

.fa-js-square {
  --fa: "\f3b9";
}

.fa-microsoft {
  --fa: "\f3ca";
}

.fa-qq {
  --fa: "\f1d6";
}

.fa-orcid {
  --fa: "\f8d2";
}

.fa-java {
  --fa: "\f4e4";
}

.fa-invision {
  --fa: "\f7b0";
}

.fa-creative-commons-pd-alt {
  --fa: "\f4ed";
}

.fa-centercode {
  --fa: "\f380";
}

.fa-glide-g {
  --fa: "\f2a6";
}

.fa-drupal {
  --fa: "\f1a9";
}

.fa-jxl {
  --fa: "\e67b";
}

.fa-dart-lang {
  --fa: "\e693";
}

.fa-hire-a-helper {
  --fa: "\f3b0";
}

.fa-creative-commons-by {
  --fa: "\f4e7";
}

.fa-unity {
  --fa: "\e049";
}

.fa-whmcs {
  --fa: "\f40d";
}

.fa-rocketchat {
  --fa: "\f3e8";
}

.fa-vk {
  --fa: "\f189";
}

.fa-untappd {
  --fa: "\f405";
}

.fa-mailchimp {
  --fa: "\f59e";
}

.fa-css3-alt {
  --fa: "\f38b";
}

.fa-square-reddit {
  --fa: "\f1a2";
}

.fa-reddit-square {
  --fa: "\f1a2";
}

.fa-vimeo-v {
  --fa: "\f27d";
}

.fa-contao {
  --fa: "\f26d";
}

.fa-square-font-awesome {
  --fa: "\e5ad";
}

.fa-deskpro {
  --fa: "\f38f";
}

.fa-brave {
  --fa: "\e63c";
}

.fa-sistrix {
  --fa: "\f3ee";
}

.fa-square-instagram {
  --fa: "\e055";
}

.fa-instagram-square {
  --fa: "\e055";
}

.fa-battle-net {
  --fa: "\f835";
}

.fa-the-red-yeti {
  --fa: "\f69d";
}

.fa-square-hacker-news {
  --fa: "\f3af";
}

.fa-hacker-news-square {
  --fa: "\f3af";
}

.fa-edge {
  --fa: "\f282";
}

.fa-threads {
  --fa: "\e618";
}

.fa-napster {
  --fa: "\f3d2";
}

.fa-square-snapchat {
  --fa: "\f2ad";
}

.fa-snapchat-square {
  --fa: "\f2ad";
}

.fa-google-plus-g {
  --fa: "\f0d5";
}

.fa-artstation {
  --fa: "\f77a";
}

.fa-markdown {
  --fa: "\f60f";
}

.fa-sourcetree {
  --fa: "\f7d3";
}

.fa-google-plus {
  --fa: "\f2b3";
}

.fa-diaspora {
  --fa: "\f791";
}

.fa-foursquare {
  --fa: "\f180";
}

.fa-stack-overflow {
  --fa: "\f16c";
}

.fa-github-alt {
  --fa: "\f113";
}

.fa-phoenix-squadron {
  --fa: "\f511";
}

.fa-pagelines {
  --fa: "\f18c";
}

.fa-algolia {
  --fa: "\f36c";
}

.fa-red-river {
  --fa: "\f3e3";
}

.fa-creative-commons-sa {
  --fa: "\f4ef";
}

.fa-safari {
  --fa: "\f267";
}

.fa-google {
  --fa: "\f1a0";
}

.fa-square-font-awesome-stroke {
  --fa: "\f35c";
}

.fa-font-awesome-alt {
  --fa: "\f35c";
}

.fa-atlassian {
  --fa: "\f77b";
}

.fa-linkedin-in {
  --fa: "\f0e1";
}

.fa-digital-ocean {
  --fa: "\f391";
}

.fa-nimblr {
  --fa: "\f5a8";
}

.fa-chromecast {
  --fa: "\f838";
}

.fa-evernote {
  --fa: "\f839";
}

.fa-hacker-news {
  --fa: "\f1d4";
}

.fa-creative-commons-sampling {
  --fa: "\f4f0";
}

.fa-adversal {
  --fa: "\f36a";
}

.fa-creative-commons {
  --fa: "\f25e";
}

.fa-watchman-monitoring {
  --fa: "\e087";
}

.fa-fonticons {
  --fa: "\f280";
}

.fa-weixin {
  --fa: "\f1d7";
}

.fa-shirtsinbulk {
  --fa: "\f214";
}

.fa-codepen {
  --fa: "\f1cb";
}

.fa-git-alt {
  --fa: "\f841";
}

.fa-lyft {
  --fa: "\f3c3";
}

.fa-rev {
  --fa: "\f5b2";
}

.fa-windows {
  --fa: "\f17a";
}

.fa-wizards-of-the-coast {
  --fa: "\f730";
}

.fa-square-viadeo {
  --fa: "\f2aa";
}

.fa-viadeo-square {
  --fa: "\f2aa";
}

.fa-meetup {
  --fa: "\f2e0";
}

.fa-centos {
  --fa: "\f789";
}

.fa-adn {
  --fa: "\f170";
}

.fa-cloudsmith {
  --fa: "\f384";
}

.fa-opensuse {
  --fa: "\e62b";
}

.fa-pied-piper-alt {
  --fa: "\f1a8";
}

.fa-square-dribbble {
  --fa: "\f397";
}

.fa-dribbble-square {
  --fa: "\f397";
}

.fa-codiepie {
  --fa: "\f284";
}

.fa-node {
  --fa: "\f419";
}

.fa-mix {
  --fa: "\f3cb";
}

.fa-steam {
  --fa: "\f1b6";
}

.fa-cc-apple-pay {
  --fa: "\f416";
}

.fa-scribd {
  --fa: "\f28a";
}

.fa-debian {
  --fa: "\e60b";
}

.fa-openid {
  --fa: "\f19b";
}

.fa-instalod {
  --fa: "\e081";
}

.fa-files-pinwheel {
  --fa: "\e69f";
}

.fa-expeditedssl {
  --fa: "\f23e";
}

.fa-sellcast {
  --fa: "\f2da";
}

.fa-square-twitter {
  --fa: "\f081";
}

.fa-twitter-square {
  --fa: "\f081";
}

.fa-r-project {
  --fa: "\f4f7";
}

.fa-delicious {
  --fa: "\f1a5";
}

.fa-freebsd {
  --fa: "\f3a4";
}

.fa-vuejs {
  --fa: "\f41f";
}

.fa-accusoft {
  --fa: "\f369";
}

.fa-ioxhost {
  --fa: "\f208";
}

.fa-fonticons-fi {
  --fa: "\f3a2";
}

.fa-app-store {
  --fa: "\f36f";
}

.fa-cc-mastercard {
  --fa: "\f1f1";
}

.fa-itunes-note {
  --fa: "\f3b5";
}

.fa-golang {
  --fa: "\e40f";
}

.fa-kickstarter {
  --fa: "\f3bb";
}

.fa-square-kickstarter {
  --fa: "\f3bb";
}

.fa-grav {
  --fa: "\f2d6";
}

.fa-weibo {
  --fa: "\f18a";
}

.fa-uncharted {
  --fa: "\e084";
}

.fa-firstdraft {
  --fa: "\f3a1";
}

.fa-square-youtube {
  --fa: "\f431";
}

.fa-youtube-square {
  --fa: "\f431";
}

.fa-wikipedia-w {
  --fa: "\f266";
}

.fa-wpressr {
  --fa: "\f3e4";
}

.fa-rendact {
  --fa: "\f3e4";
}

.fa-angellist {
  --fa: "\f209";
}

.fa-galactic-republic {
  --fa: "\f50c";
}

.fa-nfc-directional {
  --fa: "\e530";
}

.fa-skype {
  --fa: "\f17e";
}

.fa-joget {
  --fa: "\f3b7";
}

.fa-fedora {
  --fa: "\f798";
}

.fa-stripe-s {
  --fa: "\f42a";
}

.fa-meta {
  --fa: "\e49b";
}

.fa-laravel {
  --fa: "\f3bd";
}

.fa-hotjar {
  --fa: "\f3b1";
}

.fa-bluetooth-b {
  --fa: "\f294";
}

.fa-square-letterboxd {
  --fa: "\e62e";
}

.fa-sticker-mule {
  --fa: "\f3f7";
}

.fa-creative-commons-zero {
  --fa: "\f4f3";
}

.fa-hips {
  --fa: "\f452";
}

.fa-css {
  --fa: "\e6a2";
}

.fa-behance {
  --fa: "\f1b4";
}

.fa-reddit {
  --fa: "\f1a1";
}

.fa-discord {
  --fa: "\f392";
}

.fa-chrome {
  --fa: "\f268";
}

.fa-app-store-ios {
  --fa: "\f370";
}

.fa-cc-discover {
  --fa: "\f1f2";
}

.fa-wpbeginner {
  --fa: "\f297";
}

.fa-confluence {
  --fa: "\f78d";
}

.fa-shoelace {
  --fa: "\e60c";
}

.fa-mdb {
  --fa: "\f8ca";
}

.fa-dochub {
  --fa: "\f394";
}

.fa-accessible-icon {
  --fa: "\f368";
}

.fa-ebay {
  --fa: "\f4f4";
}

.fa-amazon {
  --fa: "\f270";
}

.fa-unsplash {
  --fa: "\e07c";
}

.fa-yarn {
  --fa: "\f7e3";
}

.fa-square-steam {
  --fa: "\f1b7";
}

.fa-steam-square {
  --fa: "\f1b7";
}

.fa-500px {
  --fa: "\f26e";
}

.fa-square-vimeo {
  --fa: "\f194";
}

.fa-vimeo-square {
  --fa: "\f194";
}

.fa-asymmetrik {
  --fa: "\f372";
}

.fa-font-awesome {
  --fa: "\f2b4";
}

.fa-font-awesome-flag {
  --fa: "\f2b4";
}

.fa-font-awesome-logo-full {
  --fa: "\f2b4";
}

.fa-gratipay {
  --fa: "\f184";
}

.fa-apple {
  --fa: "\f179";
}

.fa-hive {
  --fa: "\e07f";
}

.fa-gitkraken {
  --fa: "\f3a6";
}

.fa-keybase {
  --fa: "\f4f5";
}

.fa-apple-pay {
  --fa: "\f415";
}

.fa-padlet {
  --fa: "\e4a0";
}

.fa-amazon-pay {
  --fa: "\f42c";
}

.fa-square-github {
  --fa: "\f092";
}

.fa-github-square {
  --fa: "\f092";
}

.fa-stumbleupon {
  --fa: "\f1a4";
}

.fa-fedex {
  --fa: "\f797";
}

.fa-phoenix-framework {
  --fa: "\f3dc";
}

.fa-shopify {
  --fa: "\e057";
}

.fa-neos {
  --fa: "\f612";
}

.fa-square-threads {
  --fa: "\e619";
}

.fa-hackerrank {
  --fa: "\f5f7";
}

.fa-researchgate {
  --fa: "\f4f8";
}

.fa-swift {
  --fa: "\f8e1";
}

.fa-angular {
  --fa: "\f420";
}

.fa-speakap {
  --fa: "\f3f3";
}

.fa-angrycreative {
  --fa: "\f36e";
}

.fa-y-combinator {
  --fa: "\f23b";
}

.fa-empire {
  --fa: "\f1d1";
}

.fa-envira {
  --fa: "\f299";
}

.fa-google-scholar {
  --fa: "\e63b";
}

.fa-square-gitlab {
  --fa: "\e5ae";
}

.fa-gitlab-square {
  --fa: "\e5ae";
}

.fa-studiovinari {
  --fa: "\f3f8";
}

.fa-pied-piper {
  --fa: "\f2ae";
}

.fa-wordpress {
  --fa: "\f19a";
}

.fa-product-hunt {
  --fa: "\f288";
}

.fa-firefox {
  --fa: "\f269";
}

.fa-linode {
  --fa: "\f2b8";
}

.fa-goodreads {
  --fa: "\f3a8";
}

.fa-square-odnoklassniki {
  --fa: "\f264";
}

.fa-odnoklassniki-square {
  --fa: "\f264";
}

.fa-jsfiddle {
  --fa: "\f1cc";
}

.fa-sith {
  --fa: "\f512";
}

.fa-themeisle {
  --fa: "\f2b2";
}

.fa-page4 {
  --fa: "\f3d7";
}

.fa-hashnode {
  --fa: "\e499";
}

.fa-react {
  --fa: "\f41b";
}

.fa-cc-paypal {
  --fa: "\f1f4";
}

.fa-squarespace {
  --fa: "\f5be";
}

.fa-cc-stripe {
  --fa: "\f1f5";
}

.fa-creative-commons-share {
  --fa: "\f4f2";
}

.fa-bitcoin {
  --fa: "\f379";
}

.fa-keycdn {
  --fa: "\f3ba";
}

.fa-opera {
  --fa: "\f26a";
}

.fa-itch-io {
  --fa: "\f83a";
}

.fa-umbraco {
  --fa: "\f8e8";
}

.fa-galactic-senate {
  --fa: "\f50d";
}

.fa-ubuntu {
  --fa: "\f7df";
}

.fa-draft2digital {
  --fa: "\f396";
}

.fa-stripe {
  --fa: "\f429";
}

.fa-houzz {
  --fa: "\f27c";
}

.fa-gg {
  --fa: "\f260";
}

.fa-dhl {
  --fa: "\f790";
}

.fa-square-pinterest {
  --fa: "\f0d3";
}

.fa-pinterest-square {
  --fa: "\f0d3";
}

.fa-xing {
  --fa: "\f168";
}

.fa-blackberry {
  --fa: "\f37b";
}

.fa-creative-commons-pd {
  --fa: "\f4ec";
}

.fa-playstation {
  --fa: "\f3df";
}

.fa-quinscape {
  --fa: "\f459";
}

.fa-less {
  --fa: "\f41d";
}

.fa-blogger-b {
  --fa: "\f37d";
}

.fa-opencart {
  --fa: "\f23d";
}

.fa-vine {
  --fa: "\f1ca";
}

.fa-signal-messenger {
  --fa: "\e663";
}

.fa-paypal {
  --fa: "\f1ed";
}

.fa-gitlab {
  --fa: "\f296";
}

.fa-typo3 {
  --fa: "\f42b";
}

.fa-reddit-alien {
  --fa: "\f281";
}

.fa-yahoo {
  --fa: "\f19e";
}

.fa-dailymotion {
  --fa: "\e052";
}

.fa-affiliatetheme {
  --fa: "\f36b";
}

.fa-pied-piper-pp {
  --fa: "\f1a7";
}

.fa-bootstrap {
  --fa: "\f836";
}

.fa-odnoklassniki {
  --fa: "\f263";
}

.fa-nfc-symbol {
  --fa: "\e531";
}

.fa-mintbit {
  --fa: "\e62f";
}

.fa-ethereum {
  --fa: "\f42e";
}

.fa-speaker-deck {
  --fa: "\f83c";
}

.fa-creative-commons-nc-eu {
  --fa: "\f4e9";
}

.fa-patreon {
  --fa: "\f3d9";
}

.fa-avianex {
  --fa: "\f374";
}

.fa-ello {
  --fa: "\f5f1";
}

.fa-gofore {
  --fa: "\f3a7";
}

.fa-bimobject {
  --fa: "\f378";
}

.fa-brave-reverse {
  --fa: "\e63d";
}

.fa-facebook-f {
  --fa: "\f39e";
}

.fa-square-google-plus {
  --fa: "\f0d4";
}

.fa-google-plus-square {
  --fa: "\f0d4";
}

.fa-web-awesome {
  --fa: "\e682";
}

.fa-mandalorian {
  --fa: "\f50f";
}

.fa-first-order-alt {
  --fa: "\f50a";
}

.fa-osi {
  --fa: "\f41a";
}

.fa-google-wallet {
  --fa: "\f1ee";
}

.fa-d-and-d-beyond {
  --fa: "\f6ca";
}

.fa-periscope {
  --fa: "\f3da";
}

.fa-fulcrum {
  --fa: "\f50b";
}

.fa-cloudscale {
  --fa: "\f383";
}

.fa-forumbee {
  --fa: "\f211";
}

.fa-mizuni {
  --fa: "\f3cc";
}

.fa-schlix {
  --fa: "\f3ea";
}

.fa-square-xing {
  --fa: "\f169";
}

.fa-xing-square {
  --fa: "\f169";
}

.fa-bandcamp {
  --fa: "\f2d5";
}

.fa-wpforms {
  --fa: "\f298";
}

.fa-cloudversify {
  --fa: "\f385";
}

.fa-usps {
  --fa: "\f7e1";
}

.fa-megaport {
  --fa: "\f5a3";
}

.fa-magento {
  --fa: "\f3c4";
}

.fa-spotify {
  --fa: "\f1bc";
}

.fa-optin-monster {
  --fa: "\f23c";
}

.fa-fly {
  --fa: "\f417";
}

.fa-aviato {
  --fa: "\f421";
}

.fa-itunes {
  --fa: "\f3b4";
}

.fa-cuttlefish {
  --fa: "\f38c";
}

.fa-blogger {
  --fa: "\f37c";
}

.fa-flickr {
  --fa: "\f16e";
}

.fa-viber {
  --fa: "\f409";
}

.fa-soundcloud {
  --fa: "\f1be";
}

.fa-digg {
  --fa: "\f1a6";
}

.fa-tencent-weibo {
  --fa: "\f1d5";
}

.fa-letterboxd {
  --fa: "\e62d";
}

.fa-symfony {
  --fa: "\f83d";
}

.fa-maxcdn {
  --fa: "\f136";
}

.fa-etsy {
  --fa: "\f2d7";
}

.fa-facebook-messenger {
  --fa: "\f39f";
}

.fa-audible {
  --fa: "\f373";
}

.fa-think-peaks {
  --fa: "\f731";
}

.fa-bilibili {
  --fa: "\e3d9";
}

.fa-erlang {
  --fa: "\f39d";
}

.fa-x-twitter {
  --fa: "\e61b";
}

.fa-cotton-bureau {
  --fa: "\f89e";
}

.fa-dashcube {
  --fa: "\f210";
}

.fa-42-group {
  --fa: "\e080";
}

.fa-innosoft {
  --fa: "\e080";
}

.fa-stack-exchange {
  --fa: "\f18d";
}

.fa-elementor {
  --fa: "\f430";
}

.fa-square-pied-piper {
  --fa: "\e01e";
}

.fa-pied-piper-square {
  --fa: "\e01e";
}

.fa-creative-commons-nd {
  --fa: "\f4eb";
}

.fa-palfed {
  --fa: "\f3d8";
}

.fa-superpowers {
  --fa: "\f2dd";
}

.fa-resolving {
  --fa: "\f3e7";
}

.fa-xbox {
  --fa: "\f412";
}

.fa-square-web-awesome-stroke {
  --fa: "\e684";
}

.fa-searchengin {
  --fa: "\f3eb";
}

.fa-tiktok {
  --fa: "\e07b";
}

.fa-square-facebook {
  --fa: "\f082";
}

.fa-facebook-square {
  --fa: "\f082";
}

.fa-renren {
  --fa: "\f18b";
}

.fa-linux {
  --fa: "\f17c";
}

.fa-glide {
  --fa: "\f2a5";
}

.fa-linkedin {
  --fa: "\f08c";
}

.fa-hubspot {
  --fa: "\f3b2";
}

.fa-deploydog {
  --fa: "\f38e";
}

.fa-twitch {
  --fa: "\f1e8";
}

.fa-flutter {
  --fa: "\e694";
}

.fa-ravelry {
  --fa: "\f2d9";
}

.fa-mixer {
  --fa: "\e056";
}

.fa-square-lastfm {
  --fa: "\f203";
}

.fa-lastfm-square {
  --fa: "\f203";
}

.fa-vimeo {
  --fa: "\f40a";
}

.fa-mendeley {
  --fa: "\f7b3";
}

.fa-uniregistry {
  --fa: "\f404";
}

.fa-figma {
  --fa: "\f799";
}

.fa-creative-commons-remix {
  --fa: "\f4ee";
}

.fa-cc-amazon-pay {
  --fa: "\f42d";
}

.fa-dropbox {
  --fa: "\f16b";
}

.fa-instagram {
  --fa: "\f16d";
}

.fa-cmplid {
  --fa: "\e360";
}

.fa-upwork {
  --fa: "\e641";
}

.fa-facebook {
  --fa: "\f09a";
}

.fa-gripfire {
  --fa: "\f3ac";
}

.fa-jedi-order {
  --fa: "\f50e";
}

.fa-uikit {
  --fa: "\f403";
}

.fa-fort-awesome-alt {
  --fa: "\f3a3";
}

.fa-phabricator {
  --fa: "\f3db";
}

.fa-ussunnah {
  --fa: "\f407";
}

.fa-earlybirds {
  --fa: "\f39a";
}

.fa-trade-federation {
  --fa: "\f513";
}

.fa-autoprefixer {
  --fa: "\f41c";
}

.fa-whatsapp {
  --fa: "\f232";
}

.fa-square-upwork {
  --fa: "\e67c";
}

.fa-slideshare {
  --fa: "\f1e7";
}

.fa-google-play {
  --fa: "\f3ab";
}

.fa-viadeo {
  --fa: "\f2a9";
}

.fa-line {
  --fa: "\f3c0";
}

.fa-google-drive {
  --fa: "\f3aa";
}

.fa-servicestack {
  --fa: "\f3ec";
}

.fa-simplybuilt {
  --fa: "\f215";
}

.fa-bitbucket {
  --fa: "\f171";
}

.fa-imdb {
  --fa: "\f2d8";
}

.fa-deezer {
  --fa: "\e077";
}

.fa-raspberry-pi {
  --fa: "\f7bb";
}

.fa-jira {
  --fa: "\f7b1";
}

.fa-docker {
  --fa: "\f395";
}

.fa-screenpal {
  --fa: "\e570";
}

.fa-bluetooth {
  --fa: "\f293";
}

.fa-gitter {
  --fa: "\f426";
}

.fa-d-and-d {
  --fa: "\f38d";
}

.fa-microblog {
  --fa: "\e01a";
}

.fa-cc-diners-club {
  --fa: "\f24c";
}

.fa-gg-circle {
  --fa: "\f261";
}

.fa-pied-piper-hat {
  --fa: "\f4e5";
}

.fa-kickstarter-k {
  --fa: "\f3bc";
}

.fa-yandex {
  --fa: "\f413";
}

.fa-readme {
  --fa: "\f4d5";
}

.fa-html5 {
  --fa: "\f13b";
}

.fa-sellsy {
  --fa: "\f213";
}

.fa-square-web-awesome {
  --fa: "\e683";
}

.fa-sass {
  --fa: "\f41e";
}

.fa-wirsindhandwerk {
  --fa: "\e2d0";
}

.fa-wsh {
  --fa: "\e2d0";
}

.fa-buromobelexperte {
  --fa: "\f37f";
}

.fa-salesforce {
  --fa: "\f83b";
}

.fa-octopus-deploy {
  --fa: "\e082";
}

.fa-medapps {
  --fa: "\f3c6";
}

.fa-ns8 {
  --fa: "\f3d5";
}

.fa-pinterest-p {
  --fa: "\f231";
}

.fa-apper {
  --fa: "\f371";
}

.fa-fort-awesome {
  --fa: "\f286";
}

.fa-waze {
  --fa: "\f83f";
}

.fa-bluesky {
  --fa: "\e671";
}

.fa-cc-jcb {
  --fa: "\f24b";
}

.fa-snapchat {
  --fa: "\f2ab";
}

.fa-snapchat-ghost {
  --fa: "\f2ab";
}

.fa-fantasy-flight-games {
  --fa: "\f6dc";
}

.fa-rust {
  --fa: "\e07a";
}

.fa-wix {
  --fa: "\f5cf";
}

.fa-square-behance {
  --fa: "\f1b5";
}

.fa-behance-square {
  --fa: "\f1b5";
}

.fa-supple {
  --fa: "\f3f9";
}

.fa-webflow {
  --fa: "\e65c";
}

.fa-rebel {
  --fa: "\f1d0";
}

.fa-css3 {
  --fa: "\f13c";
}

.fa-staylinked {
  --fa: "\f3f5";
}

.fa-kaggle {
  --fa: "\f5fa";
}

.fa-space-awesome {
  --fa: "\e5ac";
}

.fa-deviantart {
  --fa: "\f1bd";
}

.fa-cpanel {
  --fa: "\f388";
}

.fa-goodreads-g {
  --fa: "\f3a9";
}

.fa-square-git {
  --fa: "\f1d2";
}

.fa-git-square {
  --fa: "\f1d2";
}

.fa-square-tumblr {
  --fa: "\f174";
}

.fa-tumblr-square {
  --fa: "\f174";
}

.fa-trello {
  --fa: "\f181";
}

.fa-creative-commons-nc-jp {
  --fa: "\f4ea";
}

.fa-get-pocket {
  --fa: "\f265";
}

.fa-perbyte {
  --fa: "\e083";
}

.fa-grunt {
  --fa: "\f3ad";
}

.fa-weebly {
  --fa: "\f5cc";
}

.fa-connectdevelop {
  --fa: "\f20e";
}

.fa-leanpub {
  --fa: "\f212";
}

.fa-black-tie {
  --fa: "\f27e";
}

.fa-themeco {
  --fa: "\f5c6";
}

.fa-python {
  --fa: "\f3e2";
}

.fa-android {
  --fa: "\f17b";
}

.fa-bots {
  --fa: "\e340";
}

.fa-free-code-camp {
  --fa: "\f2c5";
}

.fa-hornbill {
  --fa: "\f592";
}

.fa-js {
  --fa: "\f3b8";
}

.fa-ideal {
  --fa: "\e013";
}

.fa-git {
  --fa: "\f1d3";
}

.fa-dev {
  --fa: "\f6cc";
}

.fa-sketch {
  --fa: "\f7c6";
}

.fa-yandex-international {
  --fa: "\f414";
}

.fa-cc-amex {
  --fa: "\f1f3";
}

.fa-uber {
  --fa: "\f402";
}

.fa-github {
  --fa: "\f09b";
}

.fa-php {
  --fa: "\f457";
}

.fa-alipay {
  --fa: "\f642";
}

.fa-youtube {
  --fa: "\f167";
}

.fa-skyatlas {
  --fa: "\f216";
}

.fa-firefox-browser {
  --fa: "\e007";
}

.fa-replyd {
  --fa: "\f3e6";
}

.fa-suse {
  --fa: "\f7d6";
}

.fa-jenkins {
  --fa: "\f3b6";
}

.fa-twitter {
  --fa: "\f099";
}

.fa-rockrms {
  --fa: "\f3e9";
}

.fa-pinterest {
  --fa: "\f0d2";
}

.fa-buffer {
  --fa: "\f837";
}

.fa-npm {
  --fa: "\f3d4";
}

.fa-yammer {
  --fa: "\f840";
}

.fa-btc {
  --fa: "\f15a";
}

.fa-dribbble {
  --fa: "\f17d";
}

.fa-stumbleupon-circle {
  --fa: "\f1a3";
}

.fa-internet-explorer {
  --fa: "\f26b";
}

.fa-stubber {
  --fa: "\e5c7";
}

.fa-telegram {
  --fa: "\f2c6";
}

.fa-telegram-plane {
  --fa: "\f2c6";
}

.fa-old-republic {
  --fa: "\f510";
}

.fa-odysee {
  --fa: "\e5c6";
}

.fa-square-whatsapp {
  --fa: "\f40c";
}

.fa-whatsapp-square {
  --fa: "\f40c";
}

.fa-node-js {
  --fa: "\f3d3";
}

.fa-edge-legacy {
  --fa: "\e078";
}

.fa-slack {
  --fa: "\f198";
}

.fa-slack-hash {
  --fa: "\f198";
}

.fa-medrt {
  --fa: "\f3c8";
}

.fa-usb {
  --fa: "\f287";
}

.fa-tumblr {
  --fa: "\f173";
}

.fa-vaadin {
  --fa: "\f408";
}

.fa-quora {
  --fa: "\f2c4";
}

.fa-square-x-twitter {
  --fa: "\e61a";
}

.fa-reacteurope {
  --fa: "\f75d";
}

.fa-medium {
  --fa: "\f23a";
}

.fa-medium-m {
  --fa: "\f23a";
}

.fa-amilia {
  --fa: "\f36d";
}

.fa-mixcloud {
  --fa: "\f289";
}

.fa-flipboard {
  --fa: "\f44d";
}

.fa-viacoin {
  --fa: "\f237";
}

.fa-critical-role {
  --fa: "\f6c9";
}

.fa-sitrox {
  --fa: "\e44a";
}

.fa-discourse {
  --fa: "\f393";
}

.fa-joomla {
  --fa: "\f1aa";
}

.fa-mastodon {
  --fa: "\f4f6";
}

.fa-airbnb {
  --fa: "\f834";
}

.fa-wolf-pack-battalion {
  --fa: "\f514";
}

.fa-buy-n-large {
  --fa: "\f8a6";
}

.fa-gulp {
  --fa: "\f3ae";
}

.fa-creative-commons-sampling-plus {
  --fa: "\f4f1";
}

.fa-strava {
  --fa: "\f428";
}

.fa-ember {
  --fa: "\f423";
}

.fa-canadian-maple-leaf {
  --fa: "\f785";
}

.fa-teamspeak {
  --fa: "\f4f9";
}

.fa-pushed {
  --fa: "\f3e1";
}

.fa-wordpress-simple {
  --fa: "\f411";
}

.fa-nutritionix {
  --fa: "\f3d6";
}

.fa-wodu {
  --fa: "\e088";
}

.fa-google-pay {
  --fa: "\e079";
}

.fa-intercom {
  --fa: "\f7af";
}

.fa-zhihu {
  --fa: "\f63f";
}

.fa-korvue {
  --fa: "\f42f";
}

.fa-pix {
  --fa: "\e43a";
}

.fa-steam-symbol {
  --fa: "\f3f6";
}

/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-duotone: "Font Awesome 6 Duotone";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-duotone-900.woff2") format("woff2"), url("../webfonts/fa-duotone-900.ttf") format("truetype");
}
.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal;
}

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
}

.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute;
}

/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-sharp-solid-900.woff2") format("woff2"), url("../webfonts/fa-sharp-solid-900.ttf") format("truetype");
}
.fass,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.7.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../webfonts/fa-thin-100.woff2") format("woff2"), url("../webfonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-weight: 100;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

p,
li {
  font-size: 1.6rem;
  color: #454e50;
}

.copy-s {
  font-size: 1.4rem;
}
.copy-s p, .copy-s li {
  font-size: 1.4rem;
}

.copy-xs {
  font-size: 1.2rem;
}
.copy-xs p, .copy-xs li {
  margin-bottom: 20px;
  font-size: 1.2rem;
}
.copy-xs__terms {
  font-weight: 300;
  opacity: 0.7;
}

.copy-l {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .copy-l {
    font-size: 1.6rem;
  }
}
.copy-l--normal {
  font-weight: 400;
}
.copy-l--strong {
  font-weight: bold;
}
.copy-l p, .copy-l li {
  font-size: 2rem;
}
@media (max-width: 767.98px) {
  .copy-l p, .copy-l li {
    font-size: 1.6rem;
  }
}

.copy-xl {
  line-height: 1.5;
  font-size: 2.4rem;
  font-weight: 500;
}
.copy-xl p, .copy-xl li {
  font-size: 2.4rem;
}

.font-weight-medium {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 25px;
  line-height: 1.75;
  font-weight: 500;
  color: #13243a;
}

h1 {
  line-height: 1.125;
  font-size: 3.6rem;
}
@media (max-width: 767.98px) {
  h1 {
    font-size: 3.2rem;
  }
}
h1.larger {
  line-height: 1.2;
  font-size: 5rem;
}
@media (max-width: 767.98px) {
  h1.larger {
    font-size: 3.2rem;
  }
}

h2 {
  line-height: 1.25;
  font-size: 3.2rem;
}

h3 {
  line-height: 1.42;
  font-size: 2.8rem;
  font-weight: bold;
}

h4 {
  line-height: 1.1666;
  font-size: 2.4rem;
}

h5 {
  line-height: 1.4;
  font-size: 2rem;
  font-weight: bold;
}

h6 {
  margin-bottom: 20px;
  font-size: 1.6rem;
}

.base {
  font-size: 1.6rem;
}

.milli {
  font-size: 1.4rem;
}

.micro {
  font-size: 1.2rem;
}

.nano {
  font-size: 1rem;
}

@keyframes loadingFadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}
.page-loading-animation {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background: #fff;
}

html.loaded .page-loading-animation--fadeout {
  animation-name: loadingFadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

a {
  color: #454e50;
  text-decoration: underline;
}

a:hover,
a:focus {
  color: #f7aa00;
  text-decoration: underline;
}

.btn,
.wp-block-button__link {
  display: inline-block;
  position: relative;
  min-width: 145px;
  padding: 18px 35px;
  border-radius: 30px;
  border: 2px solid #fff;
  text-align: center;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: bold;
  background: #f7aa00;
  color: #13243a;
  text-decoration: none;
  cursor: pointer;
  transition: 300ms;
  outline: 0 !important;
}
.btn:hover, .btn:focus,
.wp-block-button__link:hover,
.wp-block-button__link:focus {
  text-decoration: none;
  background: #13243a;
  color: #fff;
}
.btn--small,
.wp-block-button__link--small {
  padding: 11px 20px;
  border-radius: 22px;
}
.btn--secondary,
.wp-block-button__link--secondary {
  color: #fff;
  background: #13243a;
}
.btn--secondary:hover, .btn--secondary:focus,
.wp-block-button__link--secondary:hover,
.wp-block-button__link--secondary:focus {
  background: #f7aa00;
}
.btn--hero-secondary:hover, .btn--hero-secondary:focus,
.wp-block-button__link--hero-secondary:hover,
.wp-block-button__link--hero-secondary:focus {
  background: #13243a;
}
.btn--hollow,
.wp-block-button__link--hollow {
  background: transparent;
  color: #fff;
}
.btn--hollow:hover, .btn--hollow:focus,
.wp-block-button__link--hollow:hover,
.wp-block-button__link--hollow:focus {
  background: #f7aa00;
}
.btn--hollow-secondary,
.wp-block-button__link--hollow-secondary {
  background: transparent;
  border: 2px solid #f7aa00;
  color: #f7aa00;
}
.btn--hollow-secondary:hover, .btn--hollow-secondary:focus,
.wp-block-button__link--hollow-secondary:hover,
.wp-block-button__link--hollow-secondary:focus {
  border-color: #13243a;
  background: #13243a;
}
.btn--no-border,
.wp-block-button__link--no-border {
  padding: 20px 35px;
  border: none;
}
.btn--no-border.btn--small,
.wp-block-button__link--no-border.btn--small {
  padding: 13px 20px;
}
.btn--black-border,
.wp-block-button__link--black-border {
  border: 2px solid #13243a;
  color: #13243a;
}
.btn--black-border:hover, .btn--black-border:focus,
.wp-block-button__link--black-border:hover,
.wp-block-button__link--black-border:focus {
  border-color: #ffffff;
  background-color: #13243a !important;
  color: #fff;
}
.btn:disabled,
.wp-block-button__link:disabled {
  background-color: grey;
  cursor: not-allowed;
}
.btn.loading,
.wp-block-button__link.loading {
  cursor: wait !important;
}

.link {
  display: inline-flex;
  position: relative;
  padding-left: 35px;
  font-weight: 500;
  line-height: 1.5;
  color: #454e50;
  transition: 300ms;
}
.link span {
  transition: none;
  text-decoration: underline !important;
}
.link:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background: url(../images/arrow-solid.svg) center no-repeat;
  background-size: contain;
}
.link--after:before {
  display: none;
}
.link--after:after {
  content: "";
  display: inline-block;
  position: relative;
  top: -1px;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  vertical-align: middle;
  background: url(../images/arrow-solid.svg) center no-repeat;
  background-size: contain;
}
.link--hollow:before {
  background: url(../images/arrow-hollow.svg) center no-repeat;
  background-size: contain;
}
.link--hollow.link--after:after {
  background: url(../images/arrow-hollow.svg) center no-repeat;
  background-size: contain;
}
.link--prev:before {
  background: url(../images/arrow-solid-left.svg) center no-repeat;
  background-size: contain;
}
.link--prev.link--hollow:before {
  background: url(../images/arrow-hollow-left.svg) center no-repeat;
  background-size: contain;
}
.link--outline:before {
  border-radius: 50%;
  border: 2px solid #fff;
}
.link:hover, .link:focus {
  color: #f7aa00;
}

.tag-btn {
  display: inline-block;
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.2;
  border-radius: 12px;
  padding: 5px 10px;
  border: 1px solid #d9f0fb;
  background: #d9f0fb;
}
.tag-btn:hover, .tag-btn:focus {
  text-decoration: none;
  background: #87d0f1;
  border-color: #87d0f1;
  color: #fff;
}
.tag-btn--hollow {
  border-color: #f7aa00;
  background: transparent;
}
.tag-btn--hollow:hover, .tag-btn--hollow:focus {
  border-color: #f7aa00;
  background: #f7aa00;
}
.tag-btn.active {
  background: #87d0f1;
  border-color: #87d0f1;
  color: #fff;
}

a:hover .link, a:focus .link,
.card-team:hover .link,
.card-team:focus .link {
  color: #f7aa00;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 15px;
  margin: auto;
  display: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-top: 4px solid #000;
  border-right: 4px solid #000;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation: spin 500ms infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  padding: 100px 35px;
  background: rgba(69, 78, 80, 0.9);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.modal__inner {
  position: relative;
  max-width: 900px;
  padding: 55px 100px 55px 55px;
  margin: 0 auto;
  background: #fff6e5;
  box-shadow: 0 0 40px rgba(34, 34, 34, 0.5);
}
@media (max-width: 991.98px) {
  .modal__inner {
    padding: 60px 30px 30px;
  }
}
.modal__close {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: 300ms;
}
.modal__close:before, .modal__close:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: #454e50;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transition: 300ms;
}
.modal__close:after {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.modal__close:hover:before, .modal__close:hover:after, .modal__close:focus:before, .modal__close:focus:after {
  background: #f7aa00;
}

.close-btn {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: 300ms;
}
.close-btn:before, .close-btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: #454e50;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transition: 300ms;
}
.close-btn:after {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.close-btn:hover:before, .close-btn:hover:after, .close-btn:focus:before, .close-btn:focus:after {
  background: #f7aa00;
}

.outer {
  max-width: 1440px;
  padding: 0 80px;
  margin: 0 auto;
}
@media only screen and (max-width: 1350px) {
  .outer {
    padding: 0 40px;
  }
}
@media (max-width: 1149.98px) {
  .outer {
    padding: 0 15px;
  }
}

.inner {
  max-width: 920px;
  margin: 0 auto;
}

.centred-width {
  max-width: 540px;
  margin: 0 auto;
}

.column-content-width {
  max-width: 630px;
}

.plus {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}
.plus:before, .plus:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #13243a;
  transform: translateX(-50%) translateY(-50%);
  transition: 300ms;
}
.plus:after {
  width: 100%;
  height: 2px;
}

.no-results {
  padding: 60px 0;
}

/* Google Maps
============== */
.acf-map {
  width: 100%;
  height: 630px;
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}

.site-outer {
  position: relative;
}

.stepped-border {
  display: block;
  border-top: 1px solid #454e50;
}
.stepped-border:before {
  content: "";
  display: block;
  width: 100%;
  max-width: 80px;
  margin: 10px auto;
  border-bottom: 1px solid #454e50;
}
.stepped-border:after {
  content: "";
  display: block;
  width: 100%;
  max-width: 40px;
  margin: 0 auto;
  border-bottom: 1px solid #454e50;
}
.stepped-border--up {
  border-bottom: 1px solid #454e50;
  border-top: none;
}
.stepped-border--up:before {
  max-width: 40px;
  margin: 0 auto;
  border-top: 1px solid #454e50;
  border-bottom: none;
}
.stepped-border--up:after {
  max-width: 80px;
  margin: 10px auto;
  border-top: 1px solid #454e50;
  border-bottom: none;
}

.slick-slider * {
  outline: 0;
}

.slick-slide {
  transition: opacity 0.3s;
}

.slick-arrow {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid #454e50;
  transition: 300ms;
}
@media (max-width: 575.98px) {
  .slick-arrow {
    width: 42px;
    height: 42px;
    border-width: 1px;
  }
}
@media only screen and (max-width: 370px) {
  .slick-arrow {
    width: 35px;
    height: 35px;
  }
}
.slick-arrow:hover {
  background: #f7aa00;
}
.slick-arrow:before {
  display: none;
}
.slick-arrow.slick-next:after, .slick-arrow.slick-prev:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 53%;
  width: 26px;
  height: 26px;
  background: url(../images/chevron-right-large.svg) center no-repeat;
  background-size: contain;
  transform: translateX(-50%) translateY(-50%);
}
@media only screen and (max-width: 370px) {
  .slick-arrow.slick-next:after, .slick-arrow.slick-prev:after {
    width: 20px;
    height: 20px;
  }
}
.slick-arrow.slick-prev:after {
  left: 47%;
  background: url(../images/chevron-left-large.svg) center no-repeat;
  background-size: contain;
}
.slick-arrow--product-card-carousel, .slick-arrow--article-carousel {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  margin: 0 12rem;
  transform: translateY(0) translateX(0);
}
@media (max-width: 767.98px) {
  .slick-arrow--product-card-carousel, .slick-arrow--article-carousel {
    margin: 0 2rem;
  }
}
@media (max-width: 575.98px) {
  .slick-arrow--product-card-carousel, .slick-arrow--article-carousel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .slick-arrow--product-card-carousel.slick-prev, .slick-arrow--article-carousel.slick-prev {
    left: 2rem;
  }
}
@media only screen and (max-width: 575.98px) and (max-width: 400px) {
  .slick-arrow--product-card-carousel.slick-prev, .slick-arrow--article-carousel.slick-prev {
    left: 0;
  }
}
@media (max-width: 575.98px) {
  .slick-arrow--product-card-carousel.slick-next, .slick-arrow--article-carousel.slick-next {
    right: 2rem;
    left: auto;
  }
}
@media only screen and (max-width: 575.98px) and (max-width: 400px) {
  .slick-arrow--product-card-carousel.slick-next, .slick-arrow--article-carousel.slick-next {
    right: 0;
  }
}

.slick-dots li button:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 1;
  border: 1px solid #707070;
}
.slick-dots li.slick-active {
  opacity: 1;
}
.slick-dots li.slick-active button:before {
  background: #87d0f1;
}

.label {
  display: block;
  margin-bottom: 25px;
  font-size: 2rem;
  font-weight: bold;
  color: #13243a;
}
.label:after {
  content: "";
  display: block;
  width: 100%;
  max-width: 25px;
  height: 1px;
  margin-top: 5px;
  background: #454e50;
}

.post-image img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.border-underline {
  font-weight: 500;
  padding: 3px 0;
  border-bottom: 4px solid #87d0f1;
}
.border-underline:hover, .border-underline:focus {
  color: #87d0f1;
}

.section-heading {
  margin-bottom: 50px;
}

.wrap-match {
  font-weight: bold;
}

.sidebar {
  position: relative;
  left: 45px;
}
@media (max-width: 1149.98px) {
  .sidebar {
    left: 0;
  }
}
@media (max-width: 767.98px) {
  .sidebar {
    padding-top: 40px;
  }
}
.sidebar__panel {
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .sidebar__panel {
    margin-bottom: 45px;
  }
}
.sidebar__panel:last-child {
  margin-bottom: 0;
}

.wp-block-gallery {
  padding: 15px 0;
}

.round-top-borders {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.round-image-borders {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.round-image-borders img {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.wp-block-table {
  max-width: 470px;
  margin: 0 auto;
  text-align: center;
}
.wp-block-table table {
  width: auto;
  margin: 0 auto;
  text-align: center;
}
.wp-block-table table td {
  min-width: 175px;
  padding: 5px 0;
  border-bottom: 1px solid #707070;
}
@media (max-width: 575.98px) {
  .wp-block-table table td {
    min-width: 135px;
  }
}
@media only screen and (max-width: 370px) {
  .wp-block-table table td {
    min-width: 105px;
  }
}
.wp-block-table table tr:last-child td {
  border-bottom: none;
}
.wp-block-table td:first-child {
  text-align: left;
}
.wp-block-table td:last-child {
  text-align: right;
}
.wp-block-table figcaption {
  padding-bottom: 60px;
  padding-top: 40px;
  text-align: center;
}

.has-text-align-center {
  text-align: center;
}

.wp-block-social-links {
  margin: 0 -2px;
  text-align: center;
  padding-left: 0;
}

.wp-social-link {
  text-align: center;
  margin: 0 2px 10px;
  background: none !important;
  list-style-type: none;
  display: inline;
}
.wp-social-link svg {
  width: 26px;
  height: 26px;
}
.wp-social-link a {
  color: #13243a;
  background: none;
}
.wp-social-link a:hover, .wp-social-link a:focus {
  color: #f7aa00;
}

.buttons {
  font-size: 0;
  margin: 0 -7px;
}
.buttons > * {
  display: inline-block;
  padding: 0 7px;
  margin-bottom: 10px;
}
@media (max-width: 575.98px) {
  .buttons > * .btn {
    min-width: 240px;
  }
}

.tick-bullets ul {
  padding-left: 0;
  list-style: none;
  text-align: left !important;
}
.tick-bullets ul li {
  position: relative;
  font-weight: 500;
  padding-left: 40px;
  margin-bottom: 12px;
}
.tick-bullets ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 25px;
  height: 25px;
  background: url(../images/tick-bullet.svg) center no-repeat;
  background-size: contain;
}
.tick-bullets ul .tick-bullets--location::before {
  background: url(../images/location.svg) center no-repeat;
}
.tick-bullets ul .tick-bullets--mobile::before {
  background: url(../images/mobile-phone.svg) center no-repeat;
}
.tick-bullets ul .tick-bullets--what3words::before {
  background: url(../images/location-what3words.svg) center no-repeat;
}
.tick-bullets--grey ul li:before {
  background: url(../images/tick-bullet-grey.svg) center no-repeat;
  background-size: contain;
}
.tick-bullets--orange ul li:before {
  background: url(../images/tick-bullet-orange.svg) center no-repeat;
  background-size: contain;
}
.tick-bullets--large ul li {
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .tick-bullets--large ul li {
    font-size: 1.6rem;
  }
}
.tick-bullets--large ul li:before {
  top: 6px;
}
@media (max-width: 767.98px) {
  .tick-bullets--large ul li:before {
    top: 2px;
  }
}
.tick-bullets--product-card ul li {
  font-weight: 400;
  padding-top: 0.9rem;
  border-top: 1px solid #707070;
}
.tick-bullets--product-card ul li:before {
  top: 1.4rem;
  width: 2rem;
  height: 2rem;
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24.746' height='24.746' viewBox='0 0 24.746 24.746'><g id='Tick_icon' data-name='Tick icon' transform='translate(-662 -558.5)'><g id='Ellipse_52' data-name='Ellipse 52' transform='translate(662 558.5)' fill='none' stroke='#454E50' stroke-width='1.3'><circle cx='12.373' cy='12.373' r='12.373' stroke='none'/><circle cx='12.373' cy='12.373' r='11.723' fill='none'/></g><path id='Tick' d='M6884.319-13723.594l3.736,4.656,6.542-10.153' transform='translate(-6214.839 14294.862)' fill='none' stroke='#454E50' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/></g></svg>") center no-repeat;
  background-size: contain;
}

.join {
  display: block;
  position: relative;
  margin: 0 -15px;
}
.join__dot {
  display: block;
  position: relative;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid #f7aa00;
}
.join__line {
  display: block;
  position: absolute;
  top: 50%;
  left: 60%;
  width: 80%;
  height: 1px;
  background: #13243a;
  transform: translateY(-50%);
}
@media (max-width: 767.98px) {
  .join__line {
    left: 50%;
  }
}

.wide-copy {
  max-width: 720px;
  margin: 0 auto;
}

.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(../images/tick-bullet-dark.svg) center no-repeat;
  background-size: contain;
}
.icon--tick {
  background: url(../images/tick-bullet-dark.svg) center no-repeat;
  background-size: contain;
}
.icon--cross {
  background: url(../images/cross.svg) center no-repeat;
  background-size: contain;
}

.link-list {
  max-width: 380px;
  margin: 0 auto;
  text-align: left;
}

.breadcrumbs {
  margin: 0 0 25px;
}
.breadcrumbs a,
.breadcrumbs span {
  color: rgba(69, 78, 80, 0.5);
}
.breadcrumbs a:hover, .breadcrumbs a:focus {
  color: #f7aa00;
}
.breadcrumbs__chevron {
  padding: 0 8px;
}

.download-link {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  margin-bottom: 10px;
  font-weight: 500;
}
.download-link:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 33px;
  height: 41px;
  background: url(../images/pdf-icon-grey.svg) center no-repeat;
  background-size: contain;
  transform: translateY(-50%);
}

.list-two-col {
  columns: 2;
  column-gap: 50px;
  text-align: left;
}
@media (max-width: 767.98px) {
  .list-two-col {
    columns: 1;
  }
}
.list-two-col > * {
  break-inside: avoid-column;
}

.block-heading {
  text-align: center;
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .block-heading {
    margin-bottom: 35px;
  }
}

.arrow:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background: url(../images/arrow-solid.svg) center no-repeat;
  background-size: contain;
  border-radius: 50%;
  border: 2px solid #fff;
}
.arrow--down:before {
  transform: rotate(90deg);
}

@media (max-width: 575.98px) {
  .dataTables_wrapper {
    margin-right: -25px;
  }
}

@media (max-width: 575.98px) {
  .dataTables_scroll {
    position: relative;
  }
  .dataTables_scroll:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    right: 0;
    z-index: 3;
    width: 30px;
    height: calc(100% + 2px);
    border-right: 10px solid #fff;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
  }
}

.dataTables_scrollHead {
  position: relative;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.dataTables_scrollHead table.tablepress {
  position: static;
  overflow: visible;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.dataTables_scrollHead table.tablepress thead th {
  font-size: 1.6rem;
}
.dataTables_scrollHead table.tablepress th:first-child,
.dataTables_scrollHead table.tablepress td:first-child {
  font-weight: bold;
}

.dataTables_scrollHeadInner,
.dataTables_scrollBody {
  position: relative;
  min-width: 100%;
}

table.tablepress {
  width: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
table.tablepress.highlight-row-one tbody tr:first-child td {
  border-bottom: 0;
  font-weight: 500;
  background: #f7aa00;
  color: #fff;
}
table.tablepress.highlight-row-two tbody tr:nth-child(2) td {
  border-bottom: 0;
  font-weight: 500;
  background: #f7aa00;
  color: #fff;
}
table.tablepress.highlight-row-three tbody tr:nth-child(3) td {
  border-bottom: 0;
  font-weight: 500;
  background: #f7aa00;
  color: #fff;
}
table.tablepress.highlight-row-four tbody tr:nth-child(4) td {
  border-bottom: 0;
  font-weight: 500;
  background: #f7aa00;
  color: #fff;
}
table.tablepress.highlight-row-five tbody tr:nth-child(5) td {
  border-bottom: 0;
  font-weight: 500;
  background: #f7aa00;
  color: #fff;
}
table.tablepress thead th {
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 0;
  white-space: nowrap;
  background-color: #13243a;
  color: #fff;
}
@media (max-width: 991.98px) {
  table.tablepress thead th {
    white-space: normal;
    font-size: 1.6rem;
  }
}
table.tablepress th,
table.tablepress td {
  line-height: 1.5;
  padding: 10px 15px;
  min-width: 130px;
}
@media (max-width: 991.98px) {
  table.tablepress th,
table.tablepress td {
    min-width: 90px;
    padding: 10px;
  }
}
table.tablepress tbody td,
table.tablepress tfoot th {
  border-top: 0;
  border-bottom: 1px solid #707070;
}
table.tablepress tbody tr:last-child td {
  border-bottom: 0;
}
table.tablepress.table-3-1 .column-1 {
  width: 70%;
}

.application-wrap {
  max-width: 450px;
  margin: 0 auto;
}

@media (max-width: 575.98px) {
  .container {
    padding-right: 35px;
    padding-left: 35px;
  }
}

@media (max-width: 767.98px) {
  .after-sub.block-space--no-top {
    padding-top: 35px;
  }
}
.after-sub:not(.block-space) {
  padding-top: 35px;
}

@media (max-width: 575.98px) {
  .blocks-gallery-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.overflow-hidden {
  overflow: hidden;
}

.site-outer {
  opacity: 0;
  transition: 300ms;
}
.site-outer.loaded {
  opacity: 1;
}

.LoginContainer {
  padding: 40px;
  background-color: #ffffff;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
}

.LoginContainer form {
  height: 100%;
  position: relative;
}

.LoginContainer h2 {
  font-family: "Brandon Text", Arial, sans-serif !important;
  color: #13243a !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  margin-top: 0px !important;
  margin-bottom: 30px !important;
  text-align: center;
}

.LoginContainer button[type=submit] {
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
}

.LoginContainer label {
  font-family: "Brandon Text", Arial, sans-serif;
  color: #454e50;
  font-size: 16px;
  font-weight: bold;
}

.LoginContainer a {
  font-family: "Brandon Text", Arial, sans-serif;
  color: #454e50;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 30px;
  display: inline-block;
}

.LoginContainer input {
  border: 1px solid #454e50 !important;
  border-top-color: rgb(69, 78, 80) !important;
  border-top-style: solid !important;
  border-top-width: 1px !important;
  border-right-color: rgb(69, 78, 80) !important;
  border-right-style: solid !important;
  border-right-width: 1px !important;
  border-bottom-color: rgb(69, 78, 80) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-left-color: rgb(69, 78, 80) !important;
  border-left-style: solid !important;
  border-left-width: 1px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
  border-radius: 5px !important;
  margin-bottom: 30px;
  color: #454e50 !important;
  font-family: "Brandon Text", Arial, sans-serif !important;
  font-size: 16px !important;
}

.LoginContainer .Error {
  color: red;
}

.flex-container-white {
  padding: 40px;
  background-color: #ffffff;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  border-radius: 10px;
}

.flex-container-white div {
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}

.flex-row {
  flex-direction: row;
}

.flex-space-between {
  justify-content: space-between;
}

.before-arrow-right {
  position: relative;
  padding-left: 50px;
}

.before-arrow-right::before {
  background: url("https://shepherdsfriendly.blob.core.windows.net/image-media/icon-arrow-right-filled.svg") no-repeat;
  width: 26px;
  height: 26px;
  line-height: 26px;
  position: absolute;
  left: 0;
  content: "    ";
}

.white-block {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center !important;
  font-weight: bold;
}

.shadow {
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1019607843);
}

.no-margin-bottom-list li {
  margin-bottom: 0;
}

/* Gravity Forms
================ */
.gform_wrapper {
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
}
.gform_wrapper ul,
.gform_wrapper li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.gform_wrapper .gform_body {
  position: relative;
}
.gform_wrapper .gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.gform_wrapper .gfield {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 8px;
  margin-bottom: 16px;
}
.gform_wrapper .gfield.field-medium {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 767.98px) {
  .gform_wrapper .gfield.field-medium {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.gform_wrapper .gfield.field-small {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
@media (max-width: 767.98px) {
  .gform_wrapper .gfield.field-small {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.gform_wrapper .gfield.show-label .gfield_label {
  display: block;
}
.gform_wrapper .gfield_label {
  display: none;
  font-size: 1.6rem;
  font-weight: bold;
  color: #13243a;
}
.gform_wrapper .gfield_required {
  display: none;
}
.gform_wrapper .gfield_radio > li,
.gform_wrapper .gfield_checkbox > li {
  margin-bottom: 10px;
}
.gform_wrapper .gform_footer {
  padding-top: 20px;
  text-align: center;
}
.gform_wrapper .gform_validation_container {
  display: none;
}
.gform_wrapper .validation_error {
  display: block;
  text-align: center;
  padding: 10px 0;
  font-size: 1.6rem;
  font-weight: 700;
  color: #ba2b2b;
}
.gform_wrapper .validation_error i {
  margin-right: 10px;
  vertical-align: middle;
  font-size: 2rem;
  color: #fff;
}
.gform_wrapper .validation_message {
  display: block;
  position: relative;
  z-index: 1;
  margin-top: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1px 20px;
  border-radius: 5px;
  border: 2px solid #ba2b2b;
  color: #fff;
  background: #d96d22;
}
.gform_wrapper .validation_message:before {
  content: "";
  display: block;
  position: absolute;
  top: -7px;
  left: 20px;
  z-index: -1;
  width: 12px;
  height: 12px;
  border-top: 2px solid #ba2b2b;
  border-right: 2px solid #ba2b2b;
  background: #d96d22;
  transform: rotate(-45deg);
}

.gform_confirmation_wrapper {
  max-width: 280px;
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
  line-height: 1.4;
  font-size: 2rem;
  font-weight: bold;
  color: #13243a;
}
.gform_confirmation_wrapper:before {
  content: "";
  display: block;
  width: 42px;
  height: 42px;
  margin: 0 auto 20px;
  background: url(../images/tick.svg) center no-repeat;
  background-size: contain;
}

.bg-primary .gform_confirmation_wrapper {
  color: #fff;
}

.bg-tertiary .gform_confirmation_wrapper {
  color: #454e50;
}

.gform_ajax_spinner {
  display: none;
}

input {
  width: 100%;
  height: 45px;
  padding: 0 20px;
  border-radius: 5px !important;
  border: 2px solid #fff;
  font-size: 1.6rem;
  box-sizing: border-box;
  outline: 0 !important;
  box-shadow: none !important;
  background: #fff;
  appearance: none;
  color: #454e50;
}

textarea {
  width: 100%;
  height: 135px;
  padding: 12px 20px;
  border-radius: 5px !important;
  border: 2px solid #fff;
  font-size: 1.6rem;
  box-shadow: none !important;
  box-sizing: border-box;
  outline: 0 !important;
  background: #fff;
  appearance: none;
  color: #454e50;
}

input[type=radio] {
  position: absolute;
  opacity: 0;
  left: -999999px;
  cursor: pointer;
  height: auto;
  appearance: none;
}
input[type=radio] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  box-sizing: border-box;
  line-height: 1.4;
  font-size: 1.6rem;
  color: #13243a;
  cursor: pointer;
}
input[type=radio] + label:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 21px;
  height: 21px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
input[type=radio]:checked + label:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 7px;
  top: 7px;
  margin: 0;
  width: 7px;
  height: 7px;
  background: #f7aa00;
  border-radius: 50%;
  vertical-align: middle;
}
input[type=radio]:checked + label:before {
  border-color: #fff;
}

input[type=checkbox] {
  position: absolute;
  opacity: 0;
  left: -999999px;
  cursor: pointer;
  height: auto;
}
input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.4;
  color: #13243a;
  cursor: pointer;
}
input[type=checkbox] + label:not(.input-helper):before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 21px;
  height: 21px;
  border: 1px solid #fff;
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
}
input[type=checkbox]:checked + label:not(.input-helper):after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: auto;
  margin: auto 0;
  left: 7px;
  top: 3px;
  width: 7px;
  height: 13px;
  border: solid #f7aa00;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
input[type=checkbox]:checked + label:before {
  border-color: #fff;
}

select {
  padding: 0 45px 0 15px;
  margin: 0;
  text-overflow: "";
  font-size: 1.6rem;
  border: 2px solid #fff;
  border-radius: 5px !important;
  width: 100%;
  height: 45px;
  outline: 0 !important;
  appearance: none;
  background-image: url(../images/select-arrow.svg);
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-color: #fff;
  color: #454e50;
}

select::-ms-expand {
  display: none;
}

.bounty-form-container {
  padding: 40px;
  background-color: #ffffff;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
}
@media (max-width: 767.98px) {
  .bounty-form-container {
    border-radius: 0 0 10px 10px;
  }
}
.bounty-form-container.form-width {
  max-width: 450px;
}
@media (max-width: 991.98px) {
  .bounty-form-container.form-width {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .bounty-form-container.form-width {
    padding: 10px;
  }
}
.bounty-form-container form {
  height: 100%;
  position: relative;
}
.bounty-form-container button[type=submit] {
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
}
.bounty-form-container label {
  font-family: "Brandon Text", Arial, sans-serif;
  color: #454e50;
  font-size: 16px;
  font-weight: bold;
}
.bounty-form-container label.input-helper {
  display: inline;
  float: right;
  cursor: pointer;
}
.bounty-form-container label.input-helper::after {
  border: none;
}
.bounty-form-container label.input-helper i {
  font-size: 21px;
}
.bounty-form-container input.input-helper {
  display: none;
}
.bounty-form-container input.input-helper ~ label.input-helper div {
  display: none;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background-color: white;
  border: 1px solid black;
}
.bounty-form-container input.input-helper:checked ~ label.input-helper div {
  display: block;
}
.bounty-form-container a {
  font-family: "Brandon Text", Arial, sans-serif;
  color: #454e50;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
  display: inline-block;
}
.bounty-form-container a.btn {
  color: white;
}
.bounty-form-container input {
  border: 1px solid #454e50;
  border-top-color: rgb(69, 78, 80);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(69, 78, 80);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(69, 78, 80);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(69, 78, 80);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 5px;
  margin-bottom: 30px;
  color: #454e50;
  font-family: "Brandon Text", Arial, sans-serif;
  font-size: 16px;
}
.bounty-form-container .Error {
  color: red;
}
.bounty-form-container .helptext {
  font-weight: 500;
}
.bounty-form-container .legal img {
  padding-right: 20px;
}

.wp-block-columns {
  align-items: normal !important;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap !important;
}

@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: nowrap !important;
  }
}
.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start;
}

.wp-block-columns.are-vertically-aligned-center {
  align-items: center;
}

.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end;
}

@media (max-width: 781px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 100% !important;
  }
}
@media (min-width: 782px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column[style*=flex-basis] {
    flex-grow: 0;
  }
}
.wp-block-columns.is-not-stacked-on-mobile {
  flex-wrap: nowrap !important;
}

.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column {
  flex-basis: 0;
  flex-grow: 1;
}

.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column[style*=flex-basis] {
  flex-grow: 0;
}

:where(.wp-block-columns) {
  margin-bottom: 1.75em;
}

:where(.wp-block-columns.has-background) {
  padding: 1.25em 2.375em;
}

.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start;
}

.wp-block-column.is-vertically-aligned-center {
  align-self: center;
}

.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end;
}

.wp-block-column.is-vertically-aligned-stretch {
  align-self: stretch;
}

.wp-block-column.is-vertically-aligned-bottom, .wp-block-column.is-vertically-aligned-center, .wp-block-column.is-vertically-aligned-top {
  width: 100%;
}

.masthead {
  position: relative;
  z-index: 400;
  width: 100%;
}
@media (max-width: 991.98px) {
  .masthead .container {
    max-width: 100%;
    padding-right: 35px;
    padding-left: 35px;
  }
}
.masthead.loaded {
  position: relative;
  top: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .masthead.loaded {
    position: relative;
  }
  .masthead.loaded.masthead--page-top {
    position: relative;
  }
}
.masthead:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 300ms;
}
.masthead__inner {
  padding: 15px 0 0 0;
  background: #fff;
}
@media (max-width: 991.98px) {
  .masthead__inner {
    padding: 12px 0;
  }
}
.masthead.active-hovered:before {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1149.98px) {
  .masthead__logo {
    max-width: 155px;
  }
}
@media (max-width: 991.98px) {
  .masthead__logo {
    max-width: 165px;
  }
}
.masthead__top {
  padding-bottom: 15px;
}
.masthead__top ul {
  margin: 0 -8px;
  font-size: 0;
}
.masthead__top ul li {
  display: inline-block;
  margin: 0 8px;
}
.masthead__top--divide ul {
  margin: 0 -15px;
}
.masthead__top--divide ul li {
  position: relative;
  padding: 0 15px;
  margin: 0;
}
.masthead__top--divide ul li:first-child:before {
  display: none;
}
.masthead__top--divide ul li:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
  vertical-align: middle;
  width: 2px;
  height: 20px;
  background: #707070;
}
@media (max-width: 575.98px) {
  .masthead__top--divide ul li:before {
    display: none;
  }
}
.masthead__main {
  text-align: right;
}
.masthead__navigation .label {
  line-height: 1.1;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: left;
}
.masthead__navigation .label:after {
  margin-top: 10px;
}
.masthead__navigation .mobile-item {
  display: none !important;
}
.masthead__navigation > ul {
  margin: 0 -10px;
}
@media (max-width: 1149.98px) {
  .masthead__navigation > ul {
    margin: 0 -7px;
  }
}
.masthead__navigation > ul > li {
  margin: 0 10px;
}
@media (max-width: 1149.98px) {
  .masthead__navigation > ul > li {
    margin: 0 7px;
  }
}
.masthead__navigation > ul > li > .featured-menu-item {
  display: none;
}
.masthead__navigation > ul > li.current-menu-ancestor > a:after, .masthead__navigation > ul > li.current-page-ancestor > a:after, .masthead__navigation > ul > li.current-page-parent > a:after, .masthead__navigation > ul > li.current-menu-parent > a:after, .masthead__navigation > ul > li.current-menu-item > a:after {
  bottom: 15px !important;
  opacity: 1 !important;
}
@media (max-width: 1149.98px) {
  .masthead__navigation > ul > li.current-menu-ancestor > a:after, .masthead__navigation > ul > li.current-page-ancestor > a:after, .masthead__navigation > ul > li.current-page-parent > a:after, .masthead__navigation > ul > li.current-menu-parent > a:after, .masthead__navigation > ul > li.current-menu-item > a:after {
    bottom: 10px !important;
  }
}
.masthead__navigation > ul > li.active > a:after {
  opacity: 1 !important;
  bottom: 0 !important;
}
.masthead__navigation > ul > li.active .sub-menu-wrapper {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.masthead__navigation > ul > li.menu-item-has-children > a {
  position: static;
}
.masthead__navigation > ul > li > a {
  text-decoration: none;
}
.masthead__navigation > ul > li > a:before {
  display: none;
}
.masthead__navigation > ul > li > a:not(.btn) {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-top: 4px;
  padding-bottom: 30px;
}
@media (max-width: 1149.98px) {
  .masthead__navigation > ul > li > a:not(.btn) {
    padding-bottom: 20px;
  }
}
.masthead__navigation > ul > li > a:not(.btn):after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #87d0f1;
  opacity: 0;
  pointer-events: none;
  transition: 300ms;
}
.masthead__navigation > ul > li > a:not(.btn):hover, .masthead__navigation > ul > li > a:not(.btn):focus {
  text-decoration: none;
  color: #13243a;
}
.masthead__navigation > ul > li > a:not(.btn):hover:after, .masthead__navigation > ul > li > a:not(.btn):focus:after {
  opacity: 1;
}
.masthead__navigation > ul > li.masthead__login {
  vertical-align: top;
}
.masthead__navigation > ul > li.separator > a {
  margin-left: 20px;
}
@media (max-width: 1149.98px) {
  .masthead__navigation > ul > li.separator > a {
    margin-left: 14px;
  }
}
.masthead__navigation > ul > li.separator > a:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 3px;
  left: -20px;
  vertical-align: middle;
  width: 1px;
  height: 30px;
  margin-right: 20px;
  background: #707070;
}
@media (max-width: 1149.98px) {
  .masthead__navigation > ul > li.separator > a:before {
    left: -14px;
  }
}
.masthead__navigation ul {
  padding-left: 0;
  list-style: none;
  font-size: 0;
}
.masthead__navigation ul li {
  display: inline-block;
  font-weight: 500;
}
.masthead__navigation .btn {
  min-width: 120px;
  padding: 9px 20px 9px 40px;
}
.masthead__navigation .btn i {
  position: absolute;
  top: 52%;
  left: 20px;
  margin-right: 6px;
  font-size: 2.2rem;
  transform: translateY(-50%);
}
.masthead__navigation .sub-menu-wrapper {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  width: 100%;
  background: #fff6e5;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 300ms;
}
.masthead__navigation .sub-menu-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 15px;
  margin: 0 auto;
}
.masthead__navigation .sub-menu-inner > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .masthead__navigation .sub-menu-inner {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .masthead__navigation .sub-menu-inner {
    max-width: 960px;
  }
}
@media (min-width: 1150px) {
  .masthead__navigation .sub-menu-inner {
    max-width: 1110px;
  }
}
.masthead__navigation .sub-menu-main-links {
  flex: 1 0;
}
.masthead__navigation .nav-unstyled-link {
  text-decoration: none;
}
.masthead__navigation .nav-unstyled-link:hover, .masthead__navigation .nav-unstyled-link:focus {
  color: #13243a !important;
}
.masthead__navigation .sub-menu-0 {
  columns: 2;
}
.masthead__navigation .sub-menu-0 > li {
  display: block;
  break-inside: avoid-column;
}
.masthead__navigation .sub-menu-0 > li.nav-subtitle, .masthead__navigation .sub-menu-0 > li.nav-subtitle > a {
  cursor: default;
  height: -moz-fit-content;
}
.masthead__navigation .sub-menu-0 a {
  color: #454e50;
  text-decoration: none;
}
.masthead__navigation .sub-menu-0 li {
  margin-bottom: 15px;
}
.masthead__navigation .sub-menu-1 {
  columns: 1;
}
.masthead__navigation .sub-menu-1 > li {
  display: block;
  break-inside: avoid-column;
}
.masthead__navigation .sub-menu-1 > li > a {
  cursor: pointer;
  text-decoration: none;
}
.masthead__navigation .featured-menu-item {
  flex: 0 0 400px;
  max-width: 400px;
  padding-left: 50px;
  border-left: 1px solid #454e50;
}
@media (min-width: 1150px) {
  .masthead__navigation .featured-menu-item {
    margin-left: 10%;
  }
}
.masthead__navigation .featured-menu-item a {
  text-decoration: none;
}
.masthead__navigation .featured-menu-item__header {
  color: #f7aa00;
  line-height: 1.25;
  font-size: 3.2rem;
}
.masthead__navigation .featured-menu-item .copy-l {
  margin-bottom: 40px;
  line-height: 1.3;
  font-weight: bold;
}
.masthead__navigation .featured-menu-item .copy-l * {
  line-height: 1.3;
  font-weight: bold;
}

.blog .blog-item > a:after {
  bottom: 15px !important;
  opacity: 1 !important;
}
@media (max-width: 1149.98px) {
  .blog .blog-item > a:after {
    bottom: 10px !important;
  }
}

.post-type-archive-news .news-item > a:after,
.single-news .news-item > a:after {
  bottom: 15px !important;
  opacity: 1 !important;
}
@media (max-width: 1149.98px) {
  .post-type-archive-news .news-item > a:after,
.single-news .news-item > a:after {
    bottom: 10px !important;
  }
}

.mastfoot {
  padding: 55px 0;
}
@media (max-width: 575.98px) {
  .mastfoot {
    padding: 40px 0;
  }
}
@media (max-width: 991.98px) {
  .mastfoot--sticky-footer {
    padding-bottom: 100px;
  }
}
@media (max-width: 991.98px) {
  .mastfoot__top-links {
    margin-bottom: 10px;
  }
}
@media (max-width: 575.98px) {
  .mastfoot__top-links {
    margin-bottom: 0;
  }
}
.mastfoot__contact {
  padding: 15px 0;
}
.mastfoot__contact ul {
  margin: 0;
}
.mastfoot__contact li {
  margin: 0 0 5px;
}
.mastfoot__contact strong {
  margin-right: 10px;
}
.mastfoot__links {
  padding-top: 5px;
}
.mastfoot__nav > ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: space-between;
}
.mastfoot__nav > ul > li {
  padding: 0 10px;
}
@media (max-width: 767.98px) {
  .mastfoot__nav > ul > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 575.98px) {
  .mastfoot__nav > ul > li {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
}
.mastfoot__nav > ul > li:first-child {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 767.98px) {
  .mastfoot__nav > ul > li:first-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.mastfoot__nav > ul > li:first-child .sub-menu {
  columns: 2;
  column-gap: 20px;
}
@media (max-width: 575.98px) {
  .mastfoot__nav > ul > li:first-child .sub-menu {
    columns: 1;
    column-gap: 0;
  }
}
@media (max-width: 575.98px) {
  .mastfoot__nav > ul > li.clicked > a:after {
    background: url(../images/close-button.svg) center no-repeat;
    background-size: contain;
    transform: translateY(-50%) rotate(0);
  }
}
@media (max-width: 575.98px) and (max-width: 575.98px) {
  .mastfoot__nav > ul > li.clicked .sub-menu {
    display: block;
    padding-top: 15px;
  }
}
.mastfoot__nav > ul > li > a {
  display: inline-block;
  margin-bottom: 18px;
  font-size: 2rem;
  font-weight: bold;
  color: #f7aa00 !important;
}
@media (max-width: 575.98px) {
  .mastfoot__nav > ul > li > a {
    display: block;
    position: relative;
    padding: 10px 40px 10px 0;
    border-bottom: 1px solid #fff;
    margin-bottom: 0;
    line-height: 1.5;
    color: #fff !important;
    cursor: pointer;
  }
  .mastfoot__nav > ul > li > a:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 24px;
    height: 24px;
    margin-left: 10px;
    vertical-align: middle;
    background: url(../images/arrow-hollow.svg) center no-repeat;
    background-size: contain;
    transform: translateY(-50%) rotate(90deg);
    transition: 300ms;
  }
}
@media (max-width: 575.98px) {
  .mastfoot__nav > ul > li .sub-menu {
    display: none;
  }
}
.mastfoot__nav a[href="#"] {
  cursor: default;
}
.mastfoot__nav ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.mastfoot__nav .sub-menu li {
  line-height: 1.5;
  margin-bottom: 8px;
}
.mastfoot__nav .sub-menu li a {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}
.mastfoot__nav .sub-menu li a:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 12px;
  margin-right: 10px;
  background: url(../images/chevron-right-secondary.svg) center no-repeat;
  background-size: contain;
}
.mastfoot__bottom {
  opacity: 0.7;
  padding-top: 35px;
}
.mastfoot__bottom .mastfoot__copyright {
  margin-bottom: 20px;
}
@media (max-width: 575.98px) {
  .mastfoot__bottom .mastfoot__copyright {
    padding-top: 20px;
  }
}
.mastfoot__app-store-buttons {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (max-width: 991.98px) {
  .mastfoot__app-store-buttons {
    padding-top: 17px;
  }
}
@media (max-width: 575.98px) {
  .mastfoot__app-store-buttons {
    flex-direction: row;
    gap: 16px;
  }
}
@media only screen and (max-width: 375px) {
  .mastfoot__app-store-buttons {
    flex-direction: column;
  }
}
.mastfoot__app-store-buttons--apple-store {
  margin-bottom: 16px;
}
@media (max-width: 575.98px) {
  .mastfoot__app-store-buttons--apple-store {
    margin-bottom: 0;
  }
}
.mastfoot__app-store-buttons--apple-store svg {
  border-radius: 6px;
}
.mastfoot__app-store-buttons--google-play svg {
  border-radius: 6px;
}

/* Burger Menu
============== */
.burger {
  display: none;
  cursor: pointer;
}
@media (max-width: 991.98px) {
  .burger {
    display: block;
  }
}
.burger.open .burger__lines {
  background: rgba(255, 255, 255, 0);
}
.burger.open .burger__lines:after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  bottom: 0;
}
.burger.open .burger__lines:before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 0;
}
.burger__text {
  margin-right: 10px;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.burger__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 60px;
}
.burger__menu {
  display: block;
  position: relative;
  margin: 0;
  width: 30px;
  height: 20px;
  z-index: 27;
  cursor: pointer;
}
.burger__lines {
  position: absolute;
  background: #454e50;
  width: 100%;
  height: 3px;
  top: 8px;
  right: 0;
  opacity: 1;
  border-radius: 3px;
}
.burger__lines:before {
  position: absolute;
  background: #454e50;
  width: 100%;
  height: 3px;
  top: 8px;
  left: 0;
  content: "";
  display: block;
  border-radius: 3px;
}
.burger__lines:after {
  position: absolute;
  background: #454e50;
  width: 100%;
  height: 3px;
  bottom: 8px;
  left: 0;
  content: "";
  display: block;
  border-radius: 3px;
}
.burger__lines, .burger__lines::after, .burger__lines::before {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.o-modal--benefit {
  background: #fff6e5;
  border-radius: 1rem;
  box-shadow: none;
  text-align: left;
  flex-direction: row;
  width: 100%;
  max-width: 90.4rem;
  height: auto;
  padding: 0 9.4rem 4.5rem 3.3rem;
  display: flex;
  flex-direction: column;
}
.o-modal__content--benefit {
  width: 100%;
  max-width: 62.8rem;
  padding-bottom: 5.8rem;
}
.o-modal__heading--benefit {
  color: #f7aa00;
  border-bottom: 0.1rem solid #707070;
  padding-bottom: 1rem;
  padding-top: 10.7rem;
}

.benefit-accordion__list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  gap: 2.5rem;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.benefit-accordion__item {
  font-weight: bold;
  margin-bottom: 0;
}
.benefit-accordion__item-link {
  text-decoration: none;
  cursor: pointer;
}
.benefit-accordion__item-link:hover {
  color: unset;
  text-decoration: none;
}
.benefit-accordion__item-link--active {
  padding-bottom: 0.3rem;
  border-bottom: 0.5rem solid #87d0f1;
}

.mobile-navigation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 450;
  width: 100%;
  height: 100%;
  padding: 35px 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 300ms;
}
@media (max-width: 991.98px) {
  .mobile-navigation {
    display: block;
  }
}
.mobile-navigation.active {
  transform: translateX(0);
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
}
.mobile-navigation.sub-open {
  overflow: hidden;
}
.mobile-navigation .burger {
  position: absolute;
  top: 15px;
  right: 25px;
}
.mobile-navigation__header-links {
  padding: 0 35px;
  margin: 0 -15px;
  font-size: 0;
}
.mobile-navigation__header-links li {
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 15px;
}
.mobile-navigation__header-links a {
  text-decoration: none;
}
.mobile-navigation__nav {
  padding-top: 60px;
}
.mobile-navigation__nav .mobile-item > a {
  color: #13243a;
}
.mobile-navigation__nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 991.98px) {
  .mobile-navigation__nav .hide-on-mobile {
    display: none;
  }
}
.mobile-navigation__nav li {
  margin: 0;
  padding: 0 35px;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.mobile-navigation__nav li.separator:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin: 15px 0;
  background: #454e50;
}
.mobile-navigation__nav li.separator.menu-item-has-children:before {
  width: calc(100% + 45px);
}
.mobile-navigation__nav li.separator.menu-item-has-children:after {
  top: 45px;
}
.mobile-navigation__nav li > a {
  padding-right: 40px;
}
.mobile-navigation__nav li > a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 24px;
  height: 24px;
  background: url(../images/arrow-hollow.svg) center no-repeat;
  background-size: contain;
  pointer-events: none;
  transform: translateY(-50%);
}
.mobile-navigation__nav li.menu-item-has-children {
  padding-right: 80px;
}
.mobile-navigation__nav li.menu-item-has-children:after {
  content: "";
  position: absolute;
  top: 32px;
  right: 35px;
  display: block;
  width: 24px;
  height: 24px;
  background: url(../images/arrow-hollow.svg) center no-repeat;
  background-size: contain;
  pointer-events: none;
  transform: rotate(90deg) translateY(0) translateX(-50%);
  z-index: 9999;
}
.mobile-navigation__nav li.menu-item-has-children.clicked:after {
  background: url(../images/close-button.svg) center no-repeat;
  background-size: contain;
  transform: rotate(0) translateY(-50%) translateX(0);
}
.mobile-navigation__nav li.menu-item-has-children > a:after {
  display: none;
  transform: rotate(90deg) translateY(0) translateX(-50%);
}
.mobile-navigation__nav .sub-menu__toggle {
  position: absolute;
  top: 50%;
  right: 35px;
  display: block;
  width: 24px;
  height: 24px;
  background: url(../images/arrow-hollow.svg) 50% no-repeat;
  background-size: contain;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  webkit-transform: rotate(90deg) translateY(0) translateX(-50%);
  transform: rotate(90deg) translateY(0) translateX(-50%);
  z-index: 9999;
}
.mobile-navigation__nav a {
  display: block;
  position: relative;
  padding: 15px 0;
  color: #454e50;
  text-decoration: none;
}
.mobile-navigation__nav a:hover, .mobile-navigation__nav a:focus {
  color: #13243a;
}
.mobile-navigation__nav .sub-menu {
  display: none;
  position: relative;
  left: -35px;
  z-index: 10;
  width: calc(100% + 115px);
  pointer-events: none;
  padding: 15px 0;
  background: rgba(247, 170, 0, 0.1);
}
.mobile-navigation__nav .sub-menu li > a:after {
  background: url(../images/arrow-solid.svg) center no-repeat;
  background-size: contain;
}
.mobile-navigation__nav .sub-menu.active {
  display: block;
  pointer-events: auto;
}
.mobile-navigation__nav .sub-menu.current {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.mobile-navigation__nav .menu-back {
  position: relative;
  padding-left: 30px;
}
.mobile-navigation__nav .menu-back:hover > a, .mobile-navigation__nav .menu-back:focus > a {
  color: #13243a;
}
.mobile-navigation__nav .menu-back:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 12px;
  height: 21px;
  transform: translateX(0) translateY(-50%);
  background: url(../images/chevron-left.svg) center no-repeat;
  background-size: contain;
  pointer-events: none;
}
.mobile-navigation .mobile-navigation__login {
  text-align: center;
}
.mobile-navigation .mobile-navigation__login a {
  display: inline-block;
  min-width: 185px;
  padding: 16px 20px 16px 20px;
  color: #13243a;
}
.mobile-navigation .mobile-navigation__login a:after {
  display: none;
}
.mobile-navigation .mobile-navigation__login a > * {
  display: inline-block;
  vertical-align: middle;
  transition: none;
}
.mobile-navigation .mobile-navigation__login a i {
  margin-right: 6px;
  font-size: 2.2rem;
}
.mobile-navigation__details {
  padding: 30px 35px;
  text-align: center;
}
.mobile-navigation__number {
  margin: 35px 0;
}
.mobile-navigation__number img {
  margin-right: 10px;
  max-width: 2.766rem;
}
.mobile-navigation__link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-heading {
  max-width: 540px;
  margin: 0 auto 50px;
}
.section-heading__inner {
  padding: 20px 5px;
}
@media (max-width: 767.98px) {
  .section-heading__inner {
    padding: 20px 0;
  }
}
.section-heading__inner * {
  margin: 0;
}
.section-heading h4 {
  line-height: 1.5;
}
.section-heading--left {
  margin: 0 0 50px;
}
.section-heading--left > div {
  padding: 0;
}
.section-heading--left h2 {
  text-align: left;
}

.signpost {
  position: relative;
}
@media (max-width: 991.98px) {
  .signpost {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 767.98px) {
  .signpost {
    justify-content: center;
    text-align: center;
  }
}
.signpost__heading {
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .signpost__heading {
    margin-bottom: 0px;
  }
}
.signpost__image {
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  max-width: 240px;
  transform: translateY(-50%);
  text-align: right;
}
@media (max-width: 991.98px) {
  .signpost__image {
    order: -1;
    position: relative;
    top: 0;
    transform: translateY(0);
    margin-bottom: 20px;
    text-align: center;
  }
}
.signpost__image img {
  max-width: 180px;
}
.signpost__copy {
  width: 75%;
  max-width: 280px;
  min-height: 85px;
}
@media (max-width: 991.98px) {
  .signpost__copy {
    display: none;
  }
}
.signpost .buttons {
  padding-top: 20px;
}
@media (max-width: 767.98px) {
  .signpost .buttons {
    padding-top: 0;
  }
}

.social ul {
  padding-left: 0;
  list-style: none;
  font-size: 0;
  margin: 0 -10px;
}
.social ul li {
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  font-size: 2.6rem;
}
.social ul a {
  text-decoration: none;
}

.sidebar-cta {
  max-width: 220px;
  margin-left: auto;
  text-align: center;
}
@media (max-width: 767.98px) {
  .sidebar-cta {
    margin: 0 auto;
  }
}
.sidebar-cta__heading {
  margin-bottom: 15px;
}
.sidebar-cta__icon {
  margin-bottom: 10px;
}
.sidebar-cta__links {
  text-align: left;
}
.sidebar-cta__links li {
  margin-bottom: 15px;
}
.sidebar-cta__highlighted {
  padding: 10px 0;
  margin: 0;
  border-top: 1px solid #454e50;
  border-bottom: 1px solid #454e50;
}
.sidebar-cta__link {
  font-weight: bold;
  margin-bottom: 10px;
}
.sidebar-cta__link a {
  color: #f7aa00;
}
.sidebar-cta__link a:hover, .sidebar-cta__link a:focus {
  color: #13243a;
}

.card {
  max-width: 450px;
  margin: 0 auto;
}
.card.card--small .card__title {
  padding-top: 15px;
  line-height: 1.2;
}
.card.card--small .link-wrap {
  padding-top: 0;
}
@media (min-width: 768px) {
  .card__thumbnail {
    height: auto;
    max-height: 120px;
  }
}
@media (min-width: 992px) {
  .card__thumbnail {
    max-height: 200px;
  }
}
.card__thumbnail img {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .card__thumbnail img {
    height: 120px;
    max-height: 120px;
  }
}
@media (min-width: 992px) {
  .card__thumbnail img {
    height: 145px;
    max-height: 145px;
  }
}
@media (min-width: 1150px) {
  .card__thumbnail img {
    height: 200px;
    max-height: 200px;
  }
}
.card__thumbnail:hover + .card__content .link, .card__thumbnail:hover + .card__details + .card__content .link {
  color: #f7aa00;
}
.card__title {
  margin-bottom: 15px;
}
.card__content .link-wrap {
  padding-top: 10px;
}
.card__content a {
  text-decoration: none;
}
.card__details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0 10px;
  margin: 0 -5px;
  line-height: 1.4;
}
.card__details > * {
  padding: 0 5px;
}
.card__categories {
  margin: 0 -5px;
  font-size: 0;
}
.card__categories > .btn-wrap {
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 10px;
}

.filter {
  position: relative;
  padding: 25px 0;
}
.filter:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  width: 30px;
  height: 100%;
  border-right: 10px solid #fff;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}
@media (max-width: 991.98px) {
  .filter__col:last-child {
    margin-bottom: 15px;
  }
}
.filter__categories {
  margin: 0 -8px;
  font-size: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .filter__categories {
    margin: 0 -35px;
    padding: 0 27px;
  }
}
.filter__categories li {
  display: inline-block;
  font-size: 0;
  padding: 0 8px;
  margin-bottom: 5px;
}
.filter__input-wrapper {
  position: relative;
}
.filter__search-form {
  position: relative;
  border-radius: 5px;
  margin-bottom: 5px;
}
.filter__search-form label {
  display: none;
}
.filter__search-form input {
  flex: 1 0;
  padding: 0 40px 0 12px;
  border: 1px solid #454e50;
}
.filter__search-form input:focus {
  border: 2px solid #87d0f1;
}
.filter__search-form button {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  transition: 300ms;
}
.filter__search-form button:hover {
  opacity: 0.75;
}

.live-search-wrapper {
  position: relative;
}
.live-search-wrapper .search-results-wrapper {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 15;
  width: 100%;
  padding: 25px 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #f3fbfd;
}
@media (max-width: 575.98px) {
  .live-search-wrapper .search-results-wrapper {
    padding: 25px 15px;
  }
}
.live-search-wrapper .search-results-wrapper.active {
  display: block;
}
.live-search-wrapper .search-results-wrapper .link-wrap {
  padding-top: 20px;
}
.live-search-wrapper .live-search-results {
  padding: 0;
  margin: 0;
  list-style: none;
}
.live-search-wrapper .live-search-results__title {
  display: block;
  margin-bottom: 10px;
}
.live-search-wrapper .live-search-results li {
  margin-bottom: 15px;
  line-height: 1.5;
}
.live-search-wrapper .live-search-results li a span {
  transition: none;
}
.live-search-wrapper .live-search-results__not-found {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 15;
  width: 100%;
  padding: 25px 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #f3fbfd;
  font-weight: bold;
}
.live-search-wrapper .live-search-results__not-found.active {
  display: block;
}

.pagination {
  padding-top: 80px;
  text-align: center;
}
.pagination__prev-next, .pagination .next, .pagination .prev {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
  margin-right: 30px;
}
@media (max-width: 767.98px) {
  .pagination__prev-next, .pagination .next, .pagination .prev {
    margin-right: 15px;
  }
}
@media (max-width: 575.98px) {
  .pagination__prev-next, .pagination .next, .pagination .prev {
    margin-right: 5px;
  }
}
.pagination__prev-next:before, .pagination .next:before, .pagination .prev:before {
  content: "";
  display: block;
  width: 34px;
  height: 34px;
  background: url(../images/arrow-hollow-thin-left.svg) center no-repeat;
  background-size: contain;
}
.pagination__prev-next.next, .pagination__prev-next--next, .pagination .next.next, .pagination .next--next, .pagination .prev.next, .pagination .prev--next {
  margin-right: 0;
  margin-left: 30px;
}
@media (max-width: 767.98px) {
  .pagination__prev-next.next, .pagination__prev-next--next, .pagination .next.next, .pagination .next--next, .pagination .prev.next, .pagination .prev--next {
    margin-left: 15px;
  }
}
@media (max-width: 575.98px) {
  .pagination__prev-next.next, .pagination__prev-next--next, .pagination .next.next, .pagination .next--next, .pagination .prev.next, .pagination .prev--next {
    margin-left: 5px;
  }
}
.pagination__prev-next.next:before, .pagination__prev-next--next:before, .pagination .next.next:before, .pagination .next--next:before, .pagination .prev.next:before, .pagination .prev--next:before {
  background: url(../images/arrow-hollow-thin-right.svg) center no-repeat;
  background-size: contain;
}
.pagination__links {
  display: inline-block;
  line-height: 1;
  font-size: 0;
  vertical-align: middle;
}
.pagination__links > * {
  font-size: 1.6rem;
}
.pagination__links a {
  text-decoration: none;
}
.pagination__links .page-numbers {
  display: block;
  position: relative;
  min-width: 20px;
  padding: 10px 4px;
  margin: 0 8px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .pagination__links .page-numbers {
    padding: 10px 2px;
  }
}
@media (max-width: 575.98px) {
  .pagination__links .page-numbers {
    margin: 0 4px;
  }
}
.pagination__links .page-numbers:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: transparent;
}
.pagination__links .page-numbers.dots + .page-numbers:not(.next) {
  display: none;
}
.pagination__links .page-numbers.next, .pagination__links .page-numbers.prev {
  padding: 0;
}
.pagination__links .page-numbers.next:after, .pagination__links .page-numbers.prev:after {
  display: none;
}
.pagination__links .page-numbers.next:hover, .pagination__links .page-numbers.next:focus, .pagination__links .page-numbers.prev:hover, .pagination__links .page-numbers.prev:focus {
  opacity: 0.75;
}
.pagination__links .page-numbers.current {
  font-weight: bold;
}
.pagination__links .page-numbers.current:after {
  background: #87d0f1;
}
.pagination__links-inner {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
}

.search-header {
  padding: 40px 0;
  background: #f3fbfd;
}
.search-header__title {
  margin-bottom: 0;
  max-width: 420px;
}
@media (max-width: 767.98px) {
  .search-header__title {
    margin-bottom: 15px;
  }
}
.search-header .live-search-wrapper form label {
  display: block;
}
.search-header .live-search-wrapper form input {
  flex: 0 0 100%;
  max-width: 100%;
}

.search-item a {
  text-decoration: none;
}
.search-item__heading {
  margin-bottom: 5px;
}
.search-item__date {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  color: #454e50;
}
.search-item__excerpt p, .search-item__excerpt li {
  margin-bottom: 15px;
}

.share__label {
  margin-bottom: 0;
}
.share__list {
  margin: 0;
  font-size: 0;
}
.share__item {
  display: inline-block;
  vertical-align: middle;
  margin: 2px 9px 4px;
  font-size: 2.5rem;
}

.single-post__share .share__label {
  max-width: 300px;
  padding-bottom: 10px;
  margin: 0 auto 10px;
  border-bottom: 1px solid #454e50;
}

.post-header {
  margin-top: 4rem;
  margin-bottom: 35px;
}
.post-header--no-thumb {
  margin-top: 40px;
}
@media (max-width: 767.98px) {
  .post-header {
    margin-top: 40px;
  }
}
.post-header__thumbnail {
  margin-bottom: 15px;
}
.post-header__thumbnail img {
  width: 100%;
}
.post-header__date {
  padding-top: 5px;
}
.post-header__categories {
  margin: 0 -7px;
  font-size: 0;
}
@media (max-width: 767.98px) {
  .post-header__categories {
    padding-top: 0;
    padding-bottom: 25px;
  }
}
.post-header__categories > * {
  display: inline-block;
  padding: 0 7px;
}

.page-link {
  max-width: 400px;
  margin: 0 auto 30px;
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.email-resend {
  background-color: #e1f3fc;
  border-radius: 1rem;
  padding: 1rem 2rem 2rem 1.5rem;
  margin: 6.5rem 0;
  text-align: left;
  display: flex;
}
@media (max-width: 992px) {
  .email-resend {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.email-resend__content {
  margin-right: 1.5rem;
}
@media (max-width: 768px) {
  .email-resend__content {
    margin: 0;
  }
}
.email-resend__icon {
  margin: 0.2rem 0.5rem 0 0;
}
@media (max-width: 768px) {
  .email-resend__icon {
    margin: 0.5rem 0 0 0;
    width: 3.5rem;
  }
}
.email-resend__header {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .email-resend__header {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .email-resend__header {
    position: relative;
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex: 1;
  }
}
.email-resend__header-text {
  font-weight: 500;
  font-size: 2.4rem;
  padding-top: 0;
  padding-left: 1rem;
  text-align: left;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .email-resend__header-text {
    padding: 1rem 0 0 1rem;
    width: 85%;
  }
}
.email-resend__copy {
  font-size: 1.6rem;
  text-align: left;
  padding-left: 4.2rem;
  margin: 0.5rem 0 0 0;
}
@media (max-width: 992px) {
  .email-resend__copy {
    margin: 1rem 0 0 0;
  }
}
@media (max-width: 768px) {
  .email-resend__copy {
    padding: 0 3.5rem 0 1rem;
  }
}

.email-resend-button__row {
  flex-grow: 1;
  margin: 0.5rem 0 0 1rem;
}
@media (max-width: 992px) {
  .email-resend-button__row {
    margin: 1rem 0 0 1rem;
    text-align: center;
  }
}
.email-resend-button__link {
  padding: 0.5rem 1rem;
  width: fit-content;
  border-radius: 3rem !important;
}
@media (max-width: 992px) {
  .email-resend-button__link {
    padding: 0.5rem 0;
    width: 323px;
  }
}
@media (max-width: 768px) {
  .email-resend-button__link {
    padding: 0.5rem 0;
    max-width: 323px;
    width: 100% !important;
  }
}
.email-resend-button__group {
  flex-direction: row;
  flex: 1;
}
.email-resend-button__message {
  color: #454e50;
  font-size: 1.7rem;
  font-weight: 500;
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.dropdown--large {
  padding: 2.8rem 0;
}
.dropdown.active .dropdown__trigger:after {
  background: url(../images/close-button-solid.svg) center no-repeat;
  background-size: contain;
  transform: rotate(0) translateY(-50%) translateX(0);
}
.dropdown.active .dropdown__trigger--white:after {
  background: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="%23F8AB00"/><path d="M9 16H23" stroke="%2313243A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center no-repeat;
}
.dropdown.active .dropdown__trigger--large::after {
  background: url(https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/02/close-button-solid-large.svg) center no-repeat;
}
.dropdown__trigger {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  padding: 10px 40px 10px 0;
  cursor: pointer;
}
@media (min-width: 992px) {
  .dropdown__trigger {
    flex-wrap: wrap;
  }
}
.dropdown__trigger * {
  margin: 0;
}
.dropdown__trigger:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  vertical-align: middle;
  background: url(../images/arrow-solid.svg) center no-repeat;
  background-size: contain;
  transform: translateY(-50%) rotate(90deg);
  transition: 0.2s;
}
.dropdown__trigger--large::after {
  right: -2rem !important;
  height: 4rem;
  width: 4rem;
  background: url(https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/02/arrow-solid-large.svg) center no-repeat;
}
@media (min-width: 992px) {
  .dropdown__trigger--large::after {
    right: 0;
  }
}
.dropdown__content {
  display: none;
}
.dropdown__content-copy {
  margin: 0 auto 2.2rem;
  max-width: 72rem;
  font-size: 2rem;
  font-weight: 500;
}
.dropdown__heading {
  font-size: 1.6rem;
}
.dropdown__heading--large {
  display: flex;
  flex-direction: column;
  margin-top: 2.8rem;
  font-size: 3.2rem;
}
@media (min-width: 992px) {
  .dropdown__heading--large {
    margin-top: 0;
  }
}
.dropdown__heading-superscript {
  font-size: 1.6rem;
}
.dropdown__heading-image {
  display: none;
}
@media (min-width: 992px) {
  .dropdown__heading-image {
    display: block;
  }
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  padding: 15px 0;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-100%);
  transition: 300ms;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media (max-width: 991.98px) {
  .sticky-header {
    top: auto;
    bottom: 0;
    transform: translateY(100%);
  }
}
.sticky-header.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: translateY(0);
}
.sticky-header__logo {
  max-width: 150px;
}
.sticky-header .buttons {
  margin: 0 -10px;
}
.sticky-header .buttons > * {
  padding: 0 10px;
  margin: 0;
}
@media (max-width: 575.98px) {
  .sticky-header .container {
    padding: 0 15px;
  }
  .sticky-header .container .btn-wrap {
    padding: 0 5px;
  }
  .sticky-header .container .btn {
    min-width: 120px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.card-team {
  max-width: 260px;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
@media (max-width: 575.98px) {
  .card-team {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 330px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 370px) {
  .card-team {
    display: block;
  }
}
.card-team__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (max-width: 575.98px) {
  .card-team__content {
    flex: 1 0;
    padding: 0 5px;
    line-height: 1.5;
  }
}
.card-team__thumbnail {
  margin-bottom: 25px;
}
.card-team__thumbnail img {
  width: 100%;
}
@media (max-width: 575.98px) {
  .card-team__thumbnail {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 5px;
    margin: 0;
  }
}
@media only screen and (max-width: 370px) {
  .card-team__thumbnail {
    margin-bottom: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.card-team__heading {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .card-team__heading {
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
}
.card-team__copy {
  margin-bottom: auto;
}
.card-team .link-wrap {
  margin-top: 1rem;
}

.benefit-card {
  width: 100%;
  max-width: 31.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.benefit-card__link {
  cursor: pointer;
}
.benefit-card__link::before {
  content: "";
  display: inline-block;
  width: 100%;
  max-width: 2.4rem;
  height: 2.4rem;
  vertical-align: bottom;
  padding-left: 3.5rem;
  background: url(../images/arrow-solid.svg) 50% no-repeat;
  background-size: contain;
}
.benefit-card__heading {
  margin-bottom: 1.2rem;
}
.benefit-card__icon {
  padding-bottom: 1.5rem;
}

.c-card--signpost-product {
  flex: 1 0;
  min-width: 28.6rem;
  max-width: 35rem;
}
@media (min-width: 992px) {
  .c-card--signpost-product {
    max-width: 44rem;
  }
}

@media (max-width: 575.98px) {
  .team-modal {
    position: relative;
    background: none;
    padding: 30px 0 0;
    width: auto;
    margin: 0 -35px;
  }
  .team-modal .modal__inner {
    padding: 35px 65px 35px 35px;
    box-shadow: none;
  }
}
.team-modal__thumbnail {
  margin-bottom: 45px;
}
@media (max-width: 767.98px) {
  .team-modal__thumbnail {
    margin-bottom: 20px;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .team-modal__thumbnail {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .team-modal__heading {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .team-modal hr {
    display: none;
  }
}
.team-modal__copy {
  padding-top: 25px;
}
@media (max-width: 575.98px) {
  .team-modal__copy {
    padding-top: 0;
  }
}
.team-modal .btn-wrap {
  margin-bottom: 15px;
}

.expand-tab {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  padding: 15px;
  min-height: 140px;
  max-width: 168px;
  text-align: center;
  background: #E1F3FB;
  cursor: pointer;
}
@media (max-width: 991.98px) {
  .expand-tab {
    display: inline-block;
    min-height: 0;
    min-width: 235px;
    max-width: 100%;
    text-align: left;
    padding: 15px 60px 15px 20px;
    justify-content: flex-start;
    height: auto !important;
  }
}
@media only screen and (max-width: 370px) {
  .expand-tab {
    min-width: 200px;
  }
}
.expand-tab.active {
  background: #87d0f1;
}
.expand-tab.active .expand-tab__heading {
  color: #fff;
}
.expand-tab.active + .expand-content {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.expand-tab--join-line:before {
  content: "";
  display: block;
  position: absolute;
  top: -25px;
  left: 50%;
  width: calc(100% + 30px);
  height: 1px;
  background: #454e50;
  z-index: -1;
}
@media (max-width: 991.98px) {
  .expand-tab--join-line:before {
    top: -7.5px;
    left: 0;
    width: 1px;
    height: calc(100% + 15px);
    transform: translateX(-50%);
  }
}
@media (max-width: 991.98px) {
  .expand-tab--link-to-tab:before {
    height: calc(50% + 7.5px);
    left: -29px !important;
  }
}
@media (max-width: 991.98px) {
  .expand-tab--arrow-bottom:before {
    height: calc(50% + 7.5px);
    left: -29px !important;
  }
}
@media (max-width: 991.98px) {
  .expand-tab--connect-previous-row:before {
    content: "";
    display: block;
    position: absolute;
    top: -7.5px;
    left: 0;
    width: 1px;
    height: calc(100% + 15px);
    background: #454e50;
    z-index: -1;
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) {
  .expand-tab--connect-previous-row .expand-tab__arrow--top:after {
    display: none;
  }
}
.expand-tab__heading {
  line-height: 1.5;
  min-height: 60px;
  margin-bottom: 10px;
}
@media (max-width: 991.98px) {
  .expand-tab__heading {
    min-height: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .expand-tab__heading {
    padding-right: 15px;
  }
}
.expand-tab__row-line {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  width: 1px;
  background: #454e50;
  transform: translateY(-100%) translateX(-50%);
}
@media (max-width: 991.98px) {
  .expand-tab__row-line {
    display: none;
  }
}
.expand-tab__col-line {
  display: block;
  position: absolute;
  top: -24px;
  z-index: -1;
  height: 1px;
  background: #454e50;
  transform: translateY(-100%);
}
@media (max-width: 991.98px) {
  .expand-tab__col-line {
    display: none;
  }
}
.expand-tab__arrow {
  position: absolute;
  top: 0;
  left: 50%;
  height: 25px;
  transform: translateX(-50%) translateY(-100%);
}
@media (max-width: 991.98px) {
  .expand-tab__arrow {
    left: 6px;
  }
}
.expand-tab__arrow:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  border-top: 10px solid #454e50;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  transform: translateX(-50%);
}
.expand-tab__arrow:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #454e50;
  transform: translateX(-50%);
}
@media (max-width: 991.98px) {
  .expand-tab__arrow--top {
    top: 50%;
    left: 0;
    width: 29px;
    transform: translateY(-50%);
  }
  .expand-tab__arrow--top:before {
    top: 50%;
    bottom: auto;
    left: 0;
    border-top: 5px solid transparent;
    border-left: 10px solid #454e50;
    border-right: none;
    border-bottom: 5px solid transparent;
    transform: translateY(-50%) translateX(-100%);
  }
  .expand-tab__arrow--top:after {
    top: 50%;
    left: 0;
    bottom: auto;
    width: 100%;
    height: 1px;
    transform: translateY(-50%) translateX(-100%);
  }
}
.expand-tab__arrow--bottom {
  top: auto;
  bottom: 0;
  transform: translateX(-50%) translateY(100%);
}
.expand-tab__arrow--bottom:after {
  top: 0;
  bottom: auto;
  transform: translateX(-50%);
}
@media (max-width: 991.98px) {
  .expand-tab__arrow--bottom:after {
    display: none;
  }
}
.expand-tab__arrow--bottom:before {
  top: 0;
  bottom: auto;
  border-top: none;
  border-bottom: 10px solid #454e50;
}
@media (max-width: 991.98px) {
  .expand-tab .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-55%);
  }
}

.expand-content {
  position: absolute;
  width: 100%;
  left: 50%;
  z-index: 2;
  max-width: 900px;
  padding: 50px 90px;
  margin: 25px auto;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background: #fff6e5;
}
@media (max-width: 991.98px) {
  .expand-content {
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .expand-content {
    padding: 25px 35px 35px;
  }
}

.application-steps {
  padding: 50px 0 0;
}
@media (max-width: 767.98px) {
  .application-steps {
    padding: 30px 0 0;
  }
}
.application-steps__container {
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 1rem;
}
@media (max-width: 1149.98px) {
  .application-steps__container {
    max-width: 1060px;
  }
}
@media (max-width: 991.98px) {
  .application-steps__container {
    max-width: 800px;
  }
}
.application-steps__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  .application-steps__items {
    margin: 0 -15px;
  }
}
@media (max-width: 575.98px) {
  .application-steps__items--isajisa {
    margin: 0 0;
  }
}
.application-steps__item {
  display: flex;
  align-items: center;
  color: #13243a;
}
.application-steps__item.active .application-steps__number {
  border-color: #87d0f1;
  background: #87d0f1;
  color: #fff;
}
@media (max-width: 991.98px) {
  .application-steps__item.active .application-steps__number {
    margin-right: 5px;
  }
}
.application-steps__item.active .application-steps__text {
  font-weight: bold;
}
@media (max-width: 991.98px) {
  .application-steps__item.active .application-steps__text {
    display: block;
  }
}
@media (max-width: 1149.98px) {
  .application-steps__item.active .application-steps__text--isajisa {
    display: block;
  }
}
.application-steps .application-steps__number {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 0;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  border: 1px solid #13243a;
}
@media (max-width: 991.98px) {
  .application-steps .application-steps__number {
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .application-steps .application-steps__number {
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }
}
.application-steps .step-line {
  flex: 1 0;
  height: 1px;
  margin: 0 20px;
  background: #13243a;
}
@media (max-width: 991.98px) {
  .application-steps .step-line {
    margin: 0 5px;
  }
}
.application-steps .step-line-isajisa {
  margin: 0 0.8rem;
  background: #13243a;
  flex: 1 0;
  height: 0.1rem;
}
@media (max-width: 991.98px) {
  .application-steps .step-line-isajisa {
    margin: 0 5px;
  }
}
@media (max-width: 991.98px) {
  .application-steps .step-line-isajisa {
    margin: 0 5px;
  }
}
@media (max-width: 991.98px) {
  .application-steps__text {
    display: none;
  }
}
@media (max-width: 1149.98px) {
  .application-steps__text--isajisa {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .application-steps__text {
    font-size: 1.2rem;
  }
}

@media (max-width: 991.98px) {
  .page-tabs .container {
    max-width: 100%;
    padding: 0;
  }
}
.page-tabs ul {
  margin: 0 -3px;
  font-size: 0;
}
.page-tabs__tab {
  display: inline-block;
  padding: 0 3px;
  margin: 0;
}
@media (max-width: 991.98px) {
  .page-tabs__tab {
    display: block;
    padding: 0;
    margin-bottom: 5px;
  }
}
.page-tabs__tab.active {
  font-weight: bold;
}
.page-tabs__tab.active a {
  background: rgba(135, 208, 241, 0.25);
}
.page-tabs__tab.active .page-tabs__number {
  border-color: #fff;
  background: #87d0f1;
  color: #fff;
}
.page-tabs__tab a {
  display: block;
  padding: 11px 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: rgba(69, 78, 80, 0.1);
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .page-tabs__tab a {
    border-radius: 0;
  }
}
.page-tabs__tab a:hover, .page-tabs__tab a:focus {
  color: #13243a;
  background: rgba(135, 208, 241, 0.25);
}
.page-tabs__tab a * {
  transition: none;
}
.page-tabs__item-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 991.98px) {
  .page-tabs__item-inner {
    max-width: 720px;
    padding: 0 15px;
    margin: 0 auto;
  }
}
.page-tabs__number {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  line-height: 1;
  margin-right: 5px;
  border: 1px solid #454e50;
  border-radius: 50%;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  color: #454e50;
}

.file-box {
  padding: 50px 60px;
  margin-bottom: 50px;
  border-radius: 10px;
  background: #fff;
}
@media (max-width: 767.98px) {
  .file-box {
    padding: 30px 25px;
  }
}
.file-box__items {
  border-bottom: 1px solid #454e50;
}
.file-box__input {
  padding-top: 55px;
}
@media (max-width: 767.98px) {
  .file-box__input {
    height: auto !important;
    padding-top: 30px;
  }
}
.file-box__input-checkbox:before {
  border: 1px solid #707070 !important;
  background: rgba(247, 170, 0, 0.25) !important;
}
.file-box .downloads__list {
  justify-content: flex-start;
  padding: 0;
}
@media (max-width: 767.98px) {
  .file-box .downloads__list {
    max-width: 100%;
    margin: 0 -15px;
  }
}
.file-box .downloads__list li {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 767.98px) {
  .file-box .downloads__list li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.file-box input + label:before {
  border: 1px solid #707070;
  background: rgba(247, 170, 0, 0.25);
}
.file-box input:checked + label:before {
  border-color: #454e50;
}
.file-box .row .btn-wrap {
  margin-bottom: 0;
}

.downloads-box {
  padding: 30px 40px;
  margin-bottom: 50px;
  border-radius: 10px;
  background: #fff;
}
@media (max-width: 767.98px) {
  .downloads-box {
    padding: 30px 25px;
  }
}
.downloads-box .downloads__list {
  padding: 20px 0 0;
  justify-content: flex-start;
}
@media (max-width: 767.98px) {
  .downloads-box .downloads__list {
    max-width: 100%;
    margin: 0 -15px;
  }
}

.tab-filter-menu {
  padding: 0px 0px 45px 0px;
}
.tab-filter-menu .toggle-menu {
  display: none;
}
@media (max-width: 991.98px) {
  .tab-filter-menu .toggle-menu {
    display: block;
    position: absolute;
    right: 0%;
    width: 60px;
    height: 48px;
    opacity: 1;
    z-index: 1;
  }
}
.tab-filter-menu .tab-content {
  display: flex;
  width: 100%;
}
.tab-filter-menu .tab-content p {
  font-weight: bold;
  flex-grow: 1;
  text-align: right;
}
.tab-filter-menu .tab-content p,
.tab-filter-menu .tab-content .tab-link {
  margin: 0px 5%;
  color: #13243a;
  padding: 0px;
  text-decoration: none;
}
.tab-filter-menu .tab-content .tab-link {
  padding: 5px;
}
.tab-filter-menu .tab-content .tab-link--active {
  font-weight: bold;
  position: relative;
}
.tab-filter-menu .tab-content .tab-link--active::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #87d0f1;
}
.tab-filter-menu .tab-content .tab-link:not(.first-tab):hover {
  color: #13243a;
  text-shadow: 0px 0px 1px #13243a;
}
.tab-filter-menu .tab-content .tab-link.mobile {
  display: none;
}
.tab-filter-menu .tab-content .tabs {
  flex-grow: 2;
}
.tab-filter-menu .tab-content .first-tab {
  position: relative;
  font-weight: bold;
}
.tab-filter-menu .tab-content .first-tab:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: #87d0f1;
}
@media (max-width: 991.98px) {
  .tab-filter-menu .tab-content {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    height: 40px;
  }
  .tab-filter-menu .tab-content .tabs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 5px;
  }
  .tab-filter-menu .tab-content p,
.tab-filter-menu .tab-content .tab-link {
    display: none;
    padding: 10px 0px;
  }
  .tab-filter-menu .tab-content .tab-link.desktop {
    display: none;
  }
  .tab-filter-menu .tab-content .tab-link.mobile.visible {
    display: block;
  }
  .tab-filter-menu .tab-content .first-tab:before {
    content: none;
  }
  .tab-filter-menu .tab-content .tab-link.mobile.first-tab {
    display: block;
    font-weight: bold;
    position: relative;
  }
  .tab-filter-menu .tab-content .tab-link.mobile.first-tab:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 24px;
    height: 24px;
    background: url(../images/arrow-hollow.svg) center no-repeat;
    background-size: contain;
    pointer-events: none;
    transform: translateY(-50%);
    transform: rotate(90deg) translateY(0) translateX(-50%);
  }
  .tab-filter-menu .tab-content .tab-link.mobile.first-tab.clicked:after {
    background: url(../images/close-button.svg) center no-repeat;
    transform: rotate(0) translateY(-50%) translateX(0);
  }
}

.error-block__content {
  max-width: 430px;
}
.error-block__heading {
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .error-block__heading {
    text-align: center;
    margin-bottom: 30px;
  }
}
.error-block__copy p, .error-block__copy li {
  margin-bottom: 10px;
}
@media (max-width: 767.98px) {
  .error-block__copy {
    text-align: center;
  }
  .error-block__copy ul {
    text-align: left;
  }
}
.error-block .btn-wrap {
  padding-top: 25px;
}
@media (max-width: 767.98px) {
  .error-block .btn-wrap {
    text-align: center;
  }
}
.error-block__image {
  margin-bottom: -60px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .error-block__image {
    margin-bottom: 20px;
  }
}

.search-listing {
  padding: 15px 0 80px;
}
.search-listing__content {
  max-width: 630px;
}
.search-listing__found {
  display: block;
  margin-bottom: 40px;
}
.search-listing .search-item {
  margin-bottom: 45px;
}
.search-listing .search-item:last-child {
  margin-bottom: 0;
}
.search-listing__back-button {
  padding-top: 50px;
}

.app-content {
  padding-top: 75px;
}
@media (max-width: 767.98px) {
  .app-content {
    padding-top: 10px;
  }
}
.app-content__body {
  padding: 60px 0;
  background: rgba(135, 208, 241, 0.25);
}
.app-content__intro {
  margin-bottom: 50px;
}
.app-content__text-box {
  padding: 35px 40px;
  margin-bottom: 50px;
  border-radius: 10px;
  background: #fff;
}
@media (max-width: 767.98px) {
  .app-content__text-box {
    padding: 30px 25px;
  }
}
.app-content__text-box h1, .app-content__text-box h2, .app-content__text-box h3, .app-content__text-box h4 {
  color: #f7aa00;
}
.app-content__logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  margin: 0 -10px;
}
@media (max-width: 767.98px) {
  .app-content__logos {
    justify-content: center;
  }
}
.app-content__logo {
  padding: 10px;
}
@media (max-width: 767.98px) {
  .app-content__logo {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
}
.app-content .btn-wrap {
  margin-bottom: 35px;
}
.app-content__copy {
  margin-bottom: 40px;
}
.app-content hr {
  margin: 45px 0;
}

.hero {
  position: relative;
  overflow-x: clip;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .hero__hide-mobile {
    display: none;
  }
}
.hero__heading--short {
  max-width: 400px;
}
@media (max-width: 767.98px) {
  .hero__heading {
    margin-right: auto;
    margin-left: auto;
  }
}
.hero__copy {
  max-width: 450px;
}
.hero--overflow-image .hero__image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 991.98px) {
  .hero--overflow-image .hero__image {
    right: -60px;
  }
}
@media (max-width: 767.98px) {
  .hero--overflow-image .hero__image {
    position: relative;
    right: 0;
  }
}
.hero .buttons {
  margin-bottom: 65px;
}
@media (max-width: 767.98px) {
  .hero .buttons {
    margin-bottom: 25px;
  }
}
.hero .buttons.buttons-smaller-margin {
  margin-bottom: 25px;
}
.hero .copy-under-buttons {
  margin-bottom: 45px;
}
.hero .white-border-box {
  border: 3px solid #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}
.hero .white-border-box p {
  margin-bottom: 0px;
}
.hero__image {
  text-align: center;
}
@media (max-width: 767.98px) {
  .hero__image {
    max-width: 260px;
    margin: 0 auto 20px;
  }
  .hero__image img {
    max-height: 250px;
  }
}
.hero__awards {
  display: flex;
  margin: 0 -7px;
  font-size: 0;
}
.hero__award {
  padding: 0 7px;
}
.hero__award img {
  display: block;
  max-height: 100%;
}
.hero__links {
  padding-top: 35px;
  text-align: left;
}
.hero__links ul {
  margin-bottom: 0;
}
.hero__links li {
  margin-bottom: 15px;
}
.hero__links li:last-child {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .hero__links {
    padding: 35px;
    margin: 40px -35px -50px;
    background: #fff6e5;
  }
  .hero__links .link:before {
    border: none;
    background: url(../images/arrow-hollow.svg) center no-repeat;
    background-size: contain;
  }
}
.hero__details {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
@media (max-width: 767.98px) {
  .hero__details {
    justify-content: center;
  }
}
.hero__details > * {
  padding: 0 15px;
}
.hero__review, .hero__full-review {
  position: relative;
  z-index: 10;
}
@media (max-width: 767px) {
  .hero__review {
    padding: 0;
  }
}

.bounty--hero .inner {
  max-width: unset;
}
@media (max-width: 991.98px) {
  .bounty--hero .row > div:first-child {
    width: 60%;
  }
}
@media (max-width: 767.98px) {
  .bounty--hero .row {
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }
  .bounty--hero .row > div:first-child {
    width: unset;
    padding: 5px;
  }
}
.bounty--hero .hero__heading--short {
  max-width: unset;
  font-size: 4.4rem;
}
@media (max-width: 991.98px) {
  .bounty--hero .hero__heading--short {
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .bounty--hero .hero__heading--short {
    font-size: 3rem;
  }
}
.bounty--hero .hero__copy {
  max-width: 481px;
}
@media (max-width: 991.98px) {
  .bounty--hero .hero__copy {
    text-align: left;
  }
}
.bounty--hero .copy-l p {
  font-size: 2.4rem;
}
@media (max-width: 767.98px) {
  .bounty--hero .copy-l p {
    font-size: 1.5rem;
  }
}
.bounty--hero .hero__image {
  top: -60px;
}
@media (max-width: 1149.98px) {
  .bounty--hero .hero__image {
    top: -80px;
    right: -130px;
  }
}
@media (max-width: 767.98px) {
  .bounty--hero .hero__image {
    position: absolute;
    right: 0;
    top: -30px;
  }
}
@media (max-width: 575.98px) {
  .bounty--hero .hero__image {
    right: -60px;
    top: -30px;
  }
}

.copy-background .container {
  position: relative;
}
.copy-background--fix-image-top .copy-background__image {
  width: 50%;
}
.copy-background--centre-image .copy-background__image {
  top: 50%;
  transform: translateY(-50%);
}
.copy-background--centre-content .copy-background__background {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 730px;
}
.copy-background__background {
  min-height: 1000px;
}
@media (max-width: 991.98px) {
  .copy-background__background {
    padding-bottom: 55vw;
    min-height: 0;
    background-size: 150% auto;
    background-position: right bottom;
  }
}
.copy-background__background:before {
  content: "";
  font-size: 0;
  min-height: inherit;
}
@media (min-width: 1400px) {
  .copy-background__background--lg {
    min-height: 1100px;
  }
}
.copy-background__image {
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  .copy-background__image {
    display: none;
  }
}
.copy-background__content .btn-wrap {
  padding-top: 25px;
  text-align: center;
}
@media (max-width: 991.98px) {
  .copy-background__content {
    text-align: center;
  }
}

.key-info--copy .section-heading {
  margin-bottom: 30px;
}
.key-info.white-card .container {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}
.key-info.white-card .container .stepped-border {
  display: none;
}
.key-info.white-card .container h2 {
  color: #f7aa00;
}
.key-info.white-card .container .key-info__copy {
  max-width: 600px;
}
.key-info.white-card .container .key-info__copy p {
  font-weight: bold;
}
.key-info.white-card .container ul li {
  list-style: none;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
  padding-left: 40px;
}
.key-info.white-card .container ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  background: url("https://shepherdsfriendly.blob.core.windows.net/image-media/icon-tick.svg") 50% no-repeat;
}
.key-info.white-card .container .read-more-button,
.key-info.white-card .container .read-more-text {
  display: none;
}
@media (max-width: 767.98px) {
  .key-info.white-card .container {
    max-width: 95%;
    position: relative;
    padding-bottom: 60px;
    margin: 0 auto;
  }
  .key-info.white-card .container .row.half-gutters {
    display: none;
  }
  .key-info.white-card .container .read-more-text {
    display: block;
    font-weight: bold;
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
  .key-info.white-card .container .read-more-text:before {
    content: "Read more +";
  }
  .key-info.white-card .container .read-more-button {
    display: block;
    width: 100px;
    height: 20px;
    opacity: 0;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 1;
  }
  .key-info.white-card .container .read-more-button:checked + .read-more-text:before {
    content: "Read less -";
  }
  .key-info.white-card .container .read-more-button:checked ~ .row.half-gutters {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .key-info--joined .key-info__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .key-info--joined .key-info__content .key-info__text {
    position: relative;
    flex: 1 0;
    padding: 0 10px 0 40px;
    text-align: left;
  }
  .key-info--joined .key-info__content .key-info__heading {
    line-height: 1.2;
  }
  .key-info--joined .key-info__content .key-info__icon {
    position: relative;
    top: -15px;
    height: 100% !important;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 767.98px) and (max-width: 370px) {
  .key-info--joined .key-info__content .key-info__icon {
    max-width: 65px;
  }
}
@media (max-width: 767.98px) {
  .key-info--joined .key-info__content .join {
    position: absolute;
    top: 5px;
    left: 12px;
    height: 100%;
    margin: 0;
  }
  .key-info--joined .key-info__content .join__dot {
    width: 17px;
    height: 17px;
  }
  .key-info--joined .key-info__content .join__line {
    top: 25px;
    width: 1px;
    height: calc(100% - 15px);
    transform: translateY(0) translateX(-50%);
  }
  .key-info--joined .key-info__content .join__line:before {
    display: none;
  }
  .key-info--joined .key-info__content .join__mark {
    display: none;
  }
}
.key-info__col {
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .key-info__col {
    margin-bottom: 20px;
  }
}
.key-info__col:last-child .join__line {
  display: none;
}
.key-info__col--without-margin {
  margin-bottom: 0px;
}
@media (max-width: 767.98px) {
  .key-info__col--without-margin:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.key-info__content {
  max-width: 320px;
  margin: 0 auto;
}
.key-info__content.grouped {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.key-info__content.grouped p {
  font-size: 14px;
}
.key-info__content.grouped .key-info__card-icon {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 0;
}
.key-info__content.grouped .key-info__card-icon img {
  max-width: 15rem;
}
.key-info__content.grouped .key-info__text {
  margin-bottom: auto;
}
.key-info__icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin-bottom: 15px;
}
.key-info__heading {
  max-width: 265px;
  margin: 0 auto 10px;
}
@media (max-width: 767.98px) {
  .key-info__heading {
    height: auto !important;
  }
}
.key-info__footer {
  text-align: center;
}
@media (min-width: 992px) {
  .key-info__link {
    margin-top: 2.5rem;
  }
}
.key-info .join {
  margin: 25px -7.5px;
}
@media (max-width: 767.98px) {
  .key-info .join {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.key-info__copy {
  margin-bottom: 40px;
}
.key-info__copy--full {
  max-width: 920px;
  margin: 0 auto;
}
.key-info .row--slider {
  display: block;
}
.key-info .row--slider .key-info__col {
  flex: 0 0 100%;
  max-width: 100%;
}
.key-info .slick-arrow {
  top: auto;
  bottom: -35px;
  z-index: 10;
  transform: translateY(0);
}
.key-info .slick-arrow.slick-next {
  right: 0;
}
.key-info .slick-arrow.slick-prev {
  left: 0;
}

.signposts.signposts--has-copy .section-heading {
  margin-bottom: 30px;
}
.signposts__copy {
  margin-bottom: 75px;
}
@media only screen and (max-width: 992px) {
  .signposts.signposts--one .signpost {
    justify-content: center;
  }
}
@media (max-width: 991.98px) {
  .signposts.signposts--one .signpost__content,
.signposts.signposts--one .signposts__col {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .signposts.signposts--two .signpost {
    justify-content: center;
  }
}
.signposts.signposts--two .signpost__content {
  flex: 1 0;
}
@media (max-width: 991.98px) {
  .signposts.signposts--two .signpost__content {
    order: 1;
  }
}
@media (max-width: 767.98px) {
  .signposts.signposts--two .signpost__content {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
  }
}
.signposts.signposts--two .signpost__image {
  max-width: 300px;
}
@media (max-width: 991.98px) {
  .signposts.signposts--two .signpost__image {
    order: 2;
  }
}
@media (max-width: 767.98px) {
  .signposts.signposts--two .signpost__image {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    margin-bottom: 15px;
  }
}
.signposts.signposts--two .signpost__image img {
  width: 100%;
  max-width: 230px;
  max-height: 230px;
}
.signposts.signposts--two .signpost__copy {
  max-width: 100%;
}
@media (max-width: 991.98px) {
  .signposts.signposts--two .signpost__copy {
    display: block;
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .signposts.signposts--two .signpost__copy {
    width: 100%;
    max-width: 320px;
    margin: 15px auto 30px;
  }
}
.signposts.signposts--two .signposts__col {
  margin-bottom: 75px;
}
.signposts.signposts--two .signposts__col:last-child {
  margin-bottom: 0;
}
.signposts > .container {
  position: relative;
  z-index: 1;
}
.signposts .section-heading {
  margin-bottom: 75px;
}
.signposts .subtitle {
  padding: 20px 0px 30px 0px;
}
.signposts .boxed-content {
  margin: 50px auto;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgb(194, 194, 194);
}
@media (min-width: 768px) {
  .signposts .boxed-content {
    width: 70%;
  }
  .signposts .boxed-content p {
    width: 65%;
  }
}
.signposts .boxed-content p {
  margin: 0 auto;
}
.signposts__col {
  margin-bottom: 75px;
}
@media (max-width: 767.98px) {
  .signposts__col {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .signposts__col:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .signposts__col:last-child {
    margin-bottom: 0;
  }
}
.signposts__link-wrapper {
  padding-top: 75px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .signposts__link-wrapper {
    padding-top: 30px;
    padding-bottom: 100px;
  }
}
.signposts__image {
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: -1;
  transform: translateY(50%);
}
.signposts__card-container {
  flex-direction: column;
}
@media (max-width: 767.98px) {
  .signposts__card-container {
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .signposts__section-introduction {
    max-width: 100%;
  }
}

.logo-banner {
  position: relative;
}
.logo-banner > .container {
  position: relative;
}
.logo-banner__slider {
  z-index: 2;
}
@media (max-width: 575.98px) {
  .logo-banner__slider {
    margin-bottom: 40px;
    padding-right: 60px;
    padding-left: 60px;
  }
}
@media only screen and (max-width: 400px) {
  .logo-banner__slider {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media only screen and (max-width: 370px) {
  .logo-banner__slider {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.logo-banner__slide-content {
  visibility: visible;
  will-change: transform;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 30px 15px 60px;
  text-align: center;
  height: 175px;
  width: 21.6rem;
}
@media only screen and (max-width: 400px) {
  .logo-banner__slide-content {
    padding-bottom: 30px;
  }
}
.logo-banner__slide-content img {
  margin: 0 auto;
  width: auto;
  max-height: 85px;
  transform: scale(0.8);
  transition: 300ms;
}
@media only screen and (max-width: 370px) {
  .logo-banner__slide-content img {
    transform: scale(0.9);
  }
}
.logo-banner__slide-content span {
  display: block;
  position: absolute;
  top: 90%;
  left: 50%;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 300ms;
  line-height: 1.5;
  transform: translateX(-50%) translateY(-100%);
}
@media only screen and (max-width: 370px) {
  .logo-banner__slide-content span {
    left: 0;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    white-space: normal;
    font-size: 1.2rem;
    transform: translateX(0) translateY(-100%);
    text-align: center;
  }
}
.logo-banner__arrows {
  position: relative;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}
@media (max-width: 575.98px) {
  .logo-banner__arrows {
    position: static;
    padding: 0;
  }
}
.logo-banner .slick-arrow {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  margin: 0 120px;
  transform: translateY(0) translateX(0);
}
@media (max-width: 767.98px) {
  .logo-banner .slick-arrow {
    margin: 0 20px;
  }
}
@media (max-width: 575.98px) {
  .logo-banner .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .logo-banner .slick-arrow.slick-prev {
    left: 20px;
  }
}
@media only screen and (max-width: 575.98px) and (max-width: 400px) {
  .logo-banner .slick-arrow.slick-prev {
    left: 0;
  }
}
@media (max-width: 575.98px) {
  .logo-banner .slick-arrow.slick-next {
    right: 20px;
    left: auto;
  }
}
@media only screen and (max-width: 575.98px) and (max-width: 400px) {
  .logo-banner .slick-arrow.slick-next {
    right: 0;
  }
}
.logo-banner .slick-current .logo-banner__slide-content img, .logo-banner .slick-current-clone-animate .logo-banner__slide-content img {
  transform: scale(1.1);
}
@media only screen and (max-width: 370px) {
  .logo-banner .slick-current .logo-banner__slide-content img, .logo-banner .slick-current-clone-animate .logo-banner__slide-content img {
    transform: scale(1);
  }
}
.logo-banner .slick-current .logo-banner__slide-content span, .logo-banner .slick-current-clone-animate .logo-banner__slide-content span {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.cta {
  position: relative;
  overflow-x: clip;
}
.cta .section-heading {
  margin-bottom: 40px;
}
.cta .container {
  position: relative;
}
@media (max-width: 767.98px) {
  .cta--no-form .section-heading {
    max-width: 400px;
  }
  .cta--no-form .section-heading .stepped-border--up {
    border-bottom: 0;
  }
  .cta--no-form .section-heading .stepped-border--up:after {
    display: none;
  }
  .cta--no-form .section-heading .stepped-border--down {
    border-top: 0;
  }
  .cta--no-form .section-heading .stepped-border--down:before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .cta--has-form .cta__image {
    display: none;
  }
}
.cta--has-form .cta__image--left {
  left: 75px;
  bottom: -100px;
}
.cta--offset .section-heading h4 {
  font-weight: 400;
}
.cta--offset .cta__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cta--offset .cta__image {
  flex: 0 0 300px;
  max-width: 300px;
  text-align: right;
}
.cta--offset .cta__image--right {
  position: relative;
  left: 0 !important;
  top: 0 !important;
}
.cta--offset .cta__content {
  flex: 1 0;
}
@media (max-width: 767.98px) {
  .cta--offset .cta__content {
    width: 100%;
  }
}
.cta--offset .section-heading {
  margin-bottom: 10px;
}
.cta--offset .cta__text,
.cta--offset .section-heading {
  max-width: 630px;
}
@media (max-width: 767.98px) {
  .cta--offset .cta__text,
.cta--offset .section-heading {
    word-break: break-word;
  }
}
.cta--offset .cta__copy {
  max-width: 450px;
  margin: 0 auto;
}
.cta--offset .cta__copy p,
.cta--offset .cta__copy li {
  font-size: 2rem;
}
.cta__content-image {
  margin: 25px auto;
  max-width: 160px;
}
.cta__image {
  position: absolute;
  bottom: 0;
  pointer-events: none;
}
.cta__image--left {
  left: 90px;
}
@media (max-width: 767.98px) {
  .cta__image--left {
    left: -10px;
    bottom: 70px;
  }
}
.cta__image--right {
  position: absolute;
  right: 15px;
}
@media (max-width: 991.98px) {
  .cta__image--right {
    width: 100%;
    max-width: 140px;
    right: -80px;
    bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .cta__image--right {
    display: none;
  }
}
.cta__copy {
  margin-bottom: 35px;
}
.cta__form {
  padding-top: 15px;
}

.page-header__outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0;
  min-height: 140px;
}
@media (max-width: 767.98px) {
  .page-header__outer {
    min-height: 90px;
    padding: 20px 0;
  }
}
@media (max-width: 767.98px) {
  .page-header--breadcrumbs + section {
    padding-top: 50px;
  }
}
@media (max-width: 767.98px) {
  .page-header__title {
    font-size: 2.4rem;
  }
}
@media (max-width: 767.98px) {
  .page-header__icon {
    max-width: 68px;
  }
}
.page-header .breadcrumbs {
  padding: 20px 0;
}
@media (max-width: 767.98px) {
  .page-header .breadcrumbs {
    display: none;
  }
}

.single .page-header__title {
  display: block;
  margin-bottom: 0;
}

.sub-navigation {
  padding: 40px 0;
}
.sub-navigation ul {
  margin: 0 -15px;
  font-size: 0;
}
@media (max-width: 767.98px) {
  .sub-navigation ul {
    text-align: center;
  }
}
.sub-navigation li {
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 15px;
}
@media (max-width: 767.98px) {
  .sub-navigation li {
    display: block;
  }
}
.sub-navigation a {
  border-color: transparent;
}
.sub-navigation a:hover, .sub-navigation a:focus, .sub-navigation a.active {
  color: #13243a !important;
  border-color: #87d0f1;
}

.long-form-content__heading {
  margin-bottom: 30px;
}
.long-form-content__quick-links {
  padding-top: 10px;
  margin-bottom: 45px;
}
.long-form-content__quick-links li {
  margin-bottom: 15px;
}
.long-form-content__body {
  max-width: 630px;
}
.long-form-content__copy h4 {
  color: #f7aa00;
}
.long-form-content__footer {
  padding-top: 80px;
}
.long-form-content__footer ul {
  padding-top: 10px;
}
.long-form-content__footer ul li {
  margin-bottom: 18px;
}
@media (max-width: 767.98px) {
  .long-form-content .sidebar {
    position: static !important;
  }
}

.featured-posts__heading {
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .featured-posts__heading {
    margin-bottom: 30px;
  }
}
.featured-posts__col {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .featured-posts__col:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .featured-posts__col:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .featured-posts .card > .card__thumbnail {
    height: 195px;
  }
  .featured-posts .card > .card__thumbnail img {
    height: 195px;
  }
}
@media (min-width: 992px) {
  .featured-posts .card > .card__thumbnail {
    height: 250px;
    max-height: 340px;
  }
  .featured-posts .card > .card__thumbnail img {
    height: 250px;
    max-height: 340px;
    object-fit: cover;
  }
}

.post-loop .featured-posts {
  padding: 25px 0 100px;
}
.post-loop__col {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .post-loop__col:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .post-loop__col:last-child {
    margin-bottom: 0;
  }
}

.post-content__content {
  max-width: 630px;
}
.post-content__content img {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.post-content__content h5 {
  color: #454e50;
}
.post-content__content li.blocks-gallery-item {
  list-style-type: none;
}
.post-content__content .articleSubheader {
  font-size: 24px;
}
.post-content .sidebar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 170px;
  height: 100%;
  padding-top: 180px;
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .post-content .sidebar .share__item {
    margin: 0 6px;
  }
}
@media (max-width: 767.98px) {
  .post-content .sidebar {
    display: none;
  }
}
.post-content .sidebar__item--sticky {
  flex: 1 0;
}
.post-content .sidebar__posts {
  padding-top: 60px;
  padding-bottom: 40px;
}
.post-content .sidebar__posts .card {
  margin-bottom: 35px;
}
.post-content__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 75px;
  margin: 0 -10px;
}
@media (max-width: 767.98px) {
  .post-content__nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .post-content__nav .link {
    padding: 0;
    padding-left: 0px;
  }
  .post-content__nav .link:before {
    left: -10px;
    top: 0px;
    position: relative;
  }
  .post-content__nav .link--after {
    text-align: right;
  }
  .post-content__nav .link--after:before {
    margin-left: auto;
  }
  .post-content__nav .link--after:after {
    display: none;
  }
}
.post-content__nav > * {
  padding: 0 10px;
}
@media (max-width: 767.98px) {
  .post-content .post-content__nav {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .contact-info__col {
    margin-bottom: 15px;
  }
  .contact-info__col:last-child {
    margin-bottom: 0;
  }
}
.contact-info__icon {
  margin-bottom: 10px;
}
.contact-info__footnote {
  padding-top: 45px;
}
@media (max-width: 991.98px) {
  .contact-info a[href^="mailto:"] {
    word-break: break-all;
  }
}

.page-links__copy {
  padding-top: 30px;
}

.expandable-content .copy-l {
  font-weight: 400;
}
.expandable-content li {
  margin-bottom: 5px;
}
.expandable-content .column-content-width, .expandable-content .dropdown__trigger {
  max-width: 540px;
}
.expandable-content .dropdown__trigger {
  border-top: 1px solid #454e50;
  border-bottom: 1px solid #454e50;
}
.expandable-content__row {
  margin-bottom: 25px;
}
.expandable-content hr {
  margin-bottom: 45px;
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.c-content-links__content-column {
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .c-content-links__content-column {
    flex-direction: row;
  }
}
.c-content-links__column-left--additional-margin {
  margin-left: 0 !important;
}
@media (min-width: 992px) {
  .c-content-links__column-left--additional-margin {
    margin-left: 8rem !important;
  }
}
.c-content-links__column-right--additional-margin {
  margin-left: 0 !important;
}
@media (min-width: 992px) {
  .c-content-links__column-right--additional-margin {
    margin-left: 15rem !important;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.accordion {
  position: relative;
  overflow: hidden;
}
.accordion--two .accordion__dropdowns {
  columns: 1;
}
.accordion--two .accordion__content {
  max-width: 540px;
}
@media (max-width: 991.98px) {
  .accordion--two .accordion__content {
    margin: 0 auto;
  }
}
.accordion--two .accordion__list {
  flex: 0 0 100%;
  max-width: 100%;
}
.accordion--three .accordion__list {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
  font-size: 0;
  padding: 0 15px;
}
@media (max-width: 991.98px) {
  .accordion--three .accordion__list {
    padding: 0 10px;
  }
}
@media (max-width: 767.98px) {
  .accordion--three .accordion__list {
    padding: 0 5px;
  }
}
.accordion--three .dropdown {
  border-color: #f7aa00 !important;
}
.accordion--three .dropdown__trigger {
  border-bottom: none;
}
.accordion--three .dropdown.active {
  border-bottom: none !important;
}
.accordion--three .accordion__dropdowns > ul:first-child {
  border-bottom: none;
}
.accordion.display-full-width .accordion__content {
  max-width: 100%;
}
.accordion.display-full-width .section-heading {
  max-width: fit-content;
  margin: 0px;
}
.accordion.display-full-width .section-heading .section-heading__inner {
  padding: 0px;
}
.accordion.display-full-width .section-heading .section-heading__inner h2 {
  color: #f7aa00;
  margin-bottom: 30px;
  font-size: 24px;
}
.accordion.display-full-width .section-heading .stepped-border {
  display: none;
}
.accordion__outer {
  position: relative;
  z-index: 1;
}
.accordion__heading {
  margin-bottom: 35px;
}
.accordion__dropdowns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -35px;
}
.accordion__dropdowns > ul > li {
  border-top: 1px solid #454e50;
}
.accordion__dropdowns > ul > li:last-child {
  border-bottom: 1px solid #454e50;
}
@media (max-width: 1149.98px) {
  .accordion__dropdowns {
    margin: 0 -15px;
  }
}
@media (max-width: 767.98px) {
  .accordion__dropdowns > ul:nth-child(2) > li:first-child {
    border-top: none;
  }
}
.accordion__list {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 35px;
  margin: 0;
  font-size: 0;
}
.accordion__list--full-width {
  max-width: 100%;
  min-width: 100%;
}
@media (max-width: 1149.98px) {
  .accordion__list {
    padding: 0 15px;
  }
}
@media (max-width: 767.98px) {
  .accordion__list {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.accordion__list li {
  margin-bottom: 0;
}
.accordion__list li:last-child .dropdown {
  border-bottom: none;
}
.accordion__image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 991.98px) {
  .accordion__image {
    display: none;
  }
}
.accordion__copy {
  padding-top: 45px;
}

.c-content-list--accordion {
  margin: 0 auto;
  padding: 0;
}
@media (min-width: 992px) {
  .c-content-list--accordion {
    padding: 0 3.6rem;
  }
}

.dropdown--accordion {
  padding: 0 4rem 0 0;
  margin-bottom: 0;
}
.dropdown--accordion--white {
  border-bottom: none;
  border-radius: 0.6rem;
  padding: 1.2rem 1.6rem;
  width: 100%;
  background: #fff;
}
.dropdown--accordion--white h2 {
  font-size: 1.8rem;
}
.dropdown__trigger--accordion {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  border-top: none;
  border-bottom: none;
}
.dropdown__trigger--accordion--white {
  justify-content: space-between;
  padding: 0;
}
.dropdown__trigger--accordion--white:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="%23F5F6F6"/><path d="M16 10.1667V21.8334" stroke="%2313243A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.1665 16H21.8332" stroke="%2313243A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-size: contain;
  transform: translateY(-50%) rotate(90deg);
  transition: 0.2s;
}
.dropdown__trigger--accordion:after {
  right: 0;
}
.dropdown__copy--accordion {
  padding-bottom: 15px;
  transition: 0.5s ease opacity;
}
.dropdown__copy--accordion--white {
  padding-top: 1.2rem;
  padding-bottom: 0;
}

.downloads__content {
  max-width: 700px;
  margin: 0 auto;
}
.downloads__content--max-width {
  max-width: 900px;
  margin: 0 auto;
}
.downloads__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 0 30px;
  margin: 0 -15px;
}
@media (max-width: 767.98px) {
  .downloads__list {
    max-width: 300px;
    margin: 0 auto;
  }
}
.downloads__list > li {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  padding: 0 15px;
}
@media (max-width: 767.98px) {
  .downloads__list > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.downloads .btn-wrap {
  text-align: center;
}

.download > a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.download__icon {
  margin-right: 15px;
}
.download__text {
  line-height: 1.5;
  flex: 1 0;
  font-weight: 500;
  transition: none;
}
.download__checkbox {
  display: inline-block;
  margin-bottom: 50px;
}
.download__checkbox label {
  text-align: left;
}

.column-content__copy--box {
  padding: 25px;
  border-radius: 10px;
  background: rgba(135, 208, 241, 0.25);
}
@media (max-width: 575.98px) {
  .column-content__copy--box {
    margin: 0 -12px;
  }
}
.column-content__copy--no-border-radius {
  border-radius: 0;
}
.column-content__copy--less-padding {
  padding: 1.7rem;
}

.simple-content img {
  margin: 10px 0;
}
.simple-content .container {
  position: relative;
  padding-bottom: 45px;
}
.simple-content .collapsed-copy {
  display: none;
}
.simple-content .read-more-text {
  display: block;
  font-weight: bold;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
}
.simple-content .read-more-text:before {
  content: "Read more +";
}
.simple-content .read-more-button {
  display: block;
  width: 100px;
  height: 20px;
  opacity: 0;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 1;
}
.simple-content .read-more-button:checked + .read-more-text:before {
  content: "Read less -";
}
.simple-content .read-more-button:checked ~ .collapsed-copy {
  display: block;
}

.styled-table__inner {
  position: relative;
  padding-left: 15px;
}
@media (max-width: 767.98px) {
  .styled-table__inner:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    right: 0;
    z-index: 3;
    width: 30px;
    height: calc(100% + 2px);
    border-right: 10px solid #fff6e5;
    background: linear-gradient(to left, #fff6e5, rgba(255, 246, 229, 0));
  }
}
.styled-table__outer {
  position: relative;
  border-radius: 10px;
  overflow-x: hidden;
}
.styled-table__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 0 15px 0 200px;
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .styled-table__wrapper {
    padding: 0 15px 0 130px;
  }
}
.styled-table table {
  width: 100%;
  border-radius: 10px;
}
.styled-table table th, .styled-table table td {
  min-width: 160px;
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
  background: #fff6e5;
}
@media (max-width: 767.98px) {
  .styled-table table th, .styled-table table td {
    min-width: 130px;
    text-align: left;
  }
  .styled-table table th:last-child, .styled-table table td:last-child {
    padding-right: 50px;
  }
}
.styled-table table th:first-child, .styled-table table td:first-child {
  position: absolute;
  left: 0;
  z-index: 2;
  width: calc(100% + 1px);
  max-width: 200px;
  font-weight: bold;
  text-align: left;
}
@media (max-width: 767.98px) {
  .styled-table table th:first-child, .styled-table table td:first-child {
    max-width: 130px;
  }
}
.styled-table table th .icon, .styled-table table td .icon {
  display: block;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .styled-table table th .icon, .styled-table table td .icon {
    margin: 0;
  }
}
.styled-table table thead th {
  font-weight: bold;
  background: #13243a;
  color: #fff;
}
.styled-table table thead th:first-child {
  top: 0;
  border-top-left-radius: 10px;
}
.styled-table table thead th:last-child {
  border-top-right-radius: 10px;
}
.styled-table table thead th .icon--tick {
  background: url(../images/tick-bullet.svg) center no-repeat;
  background-size: contain;
}
.styled-table table thead th .icon--cross {
  background: url(../images/cross-white.svg) center no-repeat;
  background-size: contain;
}
.styled-table table tbody tr td:first-child {
  top: auto;
}
.styled-table table tbody tr:not(.highlight-row):not(:last-child) td {
  border-bottom: 1px solid #707070;
}
.styled-table table tr.highlight-row td {
  font-size: 2rem;
  font-weight: bold;
  background: #f7aa00;
  color: #fff;
}
.styled-table table tr.highlight-row td .icon {
  width: 35px;
  height: 35px;
}
.styled-table table tr.highlight-row td .icon--tick {
  background: url(../images/tick-bullet.svg) center no-repeat;
  background-size: contain;
}
.styled-table table tr.highlight-row td .icon--cross {
  background: url(../images/cross-white.svg) center no-repeat;
  background-size: contain;
}

/* cookies policy table */
@media (max-width: 767.98px) {
  #tablepress-10 {
    display: block;
    overflow-x: auto;
    border-radius: 0px;
  }
  #tablepress-10 th:first-of-type {
    border-top-left-radius: 10px;
  }
  #tablepress-10 th:last-of-type {
    border-top-right-radius: 10px;
  }
}

.image-content {
  position: relative;
  z-index: 1;
}
.image-content--reverse .image-content__image {
  text-align: right;
  right: 0;
}
@media (max-width: 767.98px) {
  .image-content--reverse .image-content__image {
    text-align: center;
  }
}
.image-content--overflow-image .image-content__image {
  position: absolute;
  bottom: -110px;
}
@media (max-width: 767.98px) {
  .image-content--overflow-image .image-content__image {
    display: none;
    position: relative;
    bottom: 0;
  }
}
@media (min-width: 992px) {
  .image-content.large-gutter .flex-row-reverse .col-md-5 {
    padding-left: 40px;
  }
}
@media (min-width: 992px) {
  .image-content.large-gutter .flex-row-reverse .col-md-7 {
    padding-right: 40px;
  }
}
@media (min-width: 992px) {
  .image-content.large-gutter .col-md-5 {
    padding-right: 40px;
  }
}
@media (min-width: 992px) {
  .image-content.large-gutter .col-md-7 {
    padding-left: 40px;
  }
}
.image-content.large-gutter p {
  text-align: left;
}
.image-content.large-gutter ul:not(.list-unstyled) {
  padding-left: 0px;
}
.image-content.large-gutter ul:not(.list-unstyled) li {
  list-style: none;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
  padding-left: 40px;
}
.image-content.large-gutter ul:not(.list-unstyled) li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  background: url("https://shepherdsfriendly.blob.core.windows.net/image-media/icon-tick.svg") 50% no-repeat;
}
@media (max-width: 767.98px) {
  .image-content__col:first-child {
    order: 2;
    text-align: center;
  }
}
.image-content__image {
  width: 100%;
}
@media (max-width: 767.98px) {
  .image-content__image {
    padding-top: 40px;
    max-width: 260px;
    margin: 0 auto;
  }
}
.image-content .section-heading {
  margin-bottom: 30px;
}
.image-content .btn-wrap {
  padding-top: 25px;
}
.image-content .link-list {
  padding-top: 15px;
}
.image-content.white-card .container {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}
.image-content.image-content-card .container {
  padding: 50px;
}
.image-content.image-content-card .container .inner {
  margin: unset;
  max-width: unset;
}
.image-content.image-content-card .container .btn-wrap {
  text-align: left;
}
.image-content.image-content-card.block-space {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pagename-about #putting-members-first {
  background: #fff6e5 !important;
}

.pagename-income-protection #dont-edit {
  background: #fff6e5 !important;
}

@media (max-width: 767.98px) {
  .split-content {
    text-align: center;
  }
}
.split-content .wide-copy {
  padding-bottom: 25px;
}
.split-content figure figcaption {
  padding-top: 10px;
}
@media (max-width: 767.98px) {
  .split-content figure {
    margin-bottom: 20px;
    text-align: center;
  }
  .split-content figure img {
    width: 100%;
  }
}
.split-content .btn-wrap {
  padding-top: 30px;
  text-align: center;
}

.team__posts {
  padding-top: 40px;
}
.team__row {
  justify-content: space-between;
}
.team__col {
  max-width: 290px;
  margin-bottom: 45px;
}
@media (max-width: 767.98px) {
  .team__col {
    max-width: 100%;
  }
  .team__col:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .team__col {
    padding: 0 10px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .company-structure .block-heading {
    text-align: left;
  }
}
.company-structure .link-list {
  padding-left: 40px;
}
@media (max-width: 767.98px) {
  .company-structure .link-list {
    padding-left: 0;
    max-width: 100%;
  }
}
.company-structure__tabs {
  position: relative;
  z-index: 1;
  padding: 40px 15px 0;
}
.company-structure__tabs > h4 {
  margin-bottom: 50px;
}
.company-structure__tabs--mobile .company-structure__tab-row {
  max-width: 420px;
  margin: 0 auto;
}
.company-structure__tab-row {
  justify-content: center;
}
.company-structure__tab-col {
  position: static;
  flex: 0 0 20%;
  max-width: 200px;
  margin-bottom: 50px;
}
@media (max-width: 991.98px) {
  .company-structure__tab-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
}
.company-structure__tab-col.indent-2 {
  padding-left: 35px;
}
@media (max-width: 991.98px) {
  .company-structure__tab-col.indent-2 .expand-tab:not(.expand-tab--link-to-tab) .expand-tab__arrow--top:after {
    width: 30px;
  }
  .company-structure__tab-col.indent-2 .expand-tab:before {
    left: -29px;
  }
}
.company-structure__tab-col.indent-3 {
  padding-left: 70px;
}
@media (max-width: 991.98px) {
  .company-structure__tab-col.indent-3 .expand-tab:not(.expand-tab--link-to-tab) .expand-tab__arrow--top:after {
    width: 65px;
  }
  .company-structure__tab-col.indent-3 .expand-tab:before {
    left: -64px;
  }
}
@media (min-width: 992px) {
  .company-structure__tab-col:last-child .expand-tab--join-line:before {
    display: none;
  }
}

.reviews .section-heading {
  margin-bottom: 30px;
}
.reviews__embed {
  /*Imported CSS style from reviews.io (<link rel="stylesheet" href="https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2022011214">)*/
}
.reviews__embed .CarouselWidget-prefix .CarouselWidget {
  --base-font-size: 16px;
  --base-maxwidth: 100%;
  --reviewsio-logo-style: var(--logo-normal);
  --common-star-color: #f7aa00;
  --common-star-disabled-color: #ffffff;
  --medium-star-size: 26px;
  --small-star-size: 19px;
  --x-small-star-size: 16px;
  --x-small-star-display: inline-flex;
  --header-order: 1;
  --header-width: 250px;
  --header-bg-start-color: transparent;
  --header-bg-end-color: transparent;
  --header-gradient-direction: 135deg;
  --header-padding: 1em;
  --header-border-width: 0px;
  --header-border-color: rgba(0, 0, 0, 0.1);
  --header-border-radius: 0px;
  --header-shadow-size: 0px;
  --header-shadow-color: rgba(0, 0, 0, 0.1);
  --header-star-color: #f7aa00;
  --header-disabled-star-color: #ffffff;
  --header-heading-text-color: #454e50;
  --header-heading-font-size: 26px;
  --header-heading-mobile-font-size: 30px;
  --header-heading-font-weight: inherit;
  --header-heading-line-height: inherit;
  --header-heading-text-transform: inherit;
  --header-subheading-text-color: #454e50;
  --header-subheading-font-size: 14px;
  --header-subheading-font-weight: ;
  --header-subheading-line-height: 1.4;
  --header-subheading-text-transform: capitalize;
  --item-maximum-columns: 3;
  --item-background-start-color: transparent;
  --item-background-end-color: transparent;
  --item-gradient-direction: 135deg;
  --item-padding: 0.5em;
  --item-border-width: 0px;
  --item-border-color: rgba(0, 0, 0, 0.1);
  --item-border-radius: 0px;
  --item-shadow-size: 0px;
  --item-shadow-color: rgba(0, 0, 0, 0.1);
  --heading-text-color: #454e50;
  --heading-text-font-weight: 600;
  --heading-text-font-family: inherit;
  --heading-text-line-height: 1.4;
  --heading-text-letter-spacing: 0;
  --heading-text-transform: none;
  --body-text-color: #454e50;
  --body-text-font-weight: 400;
  --body-text-font-family: inherit;
  --body-text-line-height: 1.4;
  --body-text-letter-spacing: 0;
  --body-text-transform: none;
  --scroll-button-icon-color: #9b9b9b;
  --scroll-button-icon-size: 10px;
  --scroll-button-bg-color: transparent;
  --scroll-button-border-width: 1px;
  --scroll-button-border-color: #9b9b9b;
  --scroll-button-border-radius: 50px;
  --scroll-button-shadow-size: 0px;
  --scroll-button-shadow-color: rgba(0, 0, 0, 0.1);
  --scroll-button-horizontal-position: 0px;
  --scroll-button-vertical-position: 0px;
  --badge-icon-color: #0e1311;
  --badge-icon-font-size: 15px;
  --badge-text-color: #0e1311;
  --badge-text-font-size: inherit;
  --badge-text-letter-spacing: inherit;
  --badge-text-transform: inherit;
  --author-font-size: 14px;
  --author-font-weight: inherit;
  --author-text-transform: inherit;
  --photo-video-thumbnail-size: 60px;
  --photo-video-thumbnail-border-radius: 0px;
  --popup-backdrop-color: rgba(0, 0, 0, 0.75);
  --popup-color: #ffffff;
  --popup-star-color: inherit;
  --popup-disabled-star-color: inherit;
  --popup-heading-text-color: inherit;
  --popup-body-text-color: inherit;
  --popup-badge-icon-color: inherit;
  --popup-badge-icon-font-size: 19px;
  --popup-badge-text-color: inherit;
  --popup-badge-text-font-size: 14px;
  --popup-border-width: 0px;
  --popup-border-color: rgba(0, 0, 0, 0.1);
  --popup-border-radius: 0px;
  --popup-shadow-size: 0px;
  --popup-shadow-color: rgba(0, 0, 0, 0.1);
  --popup-icon-color: #0e1311;
  --tooltip-bg-color: #0e1311;
  --tooltip-text-color: #ffffff;
}
.reviews__embed .R-ReviewsList__item--body {
  -webkit-line-clamp: var(--body-max-lines, 6) !important;
}
.reviews__embed .CarouselWidget-prefix {
  text-align: center;
}
.reviews__embed .CarouselWidget-prefix .R-flex-wrapper {
  box-sizing: border-box;
  max-width: 1240px;
  margin: 0 auto;
}
.reviews__embed .CarouselWidget-prefix .R-flex-container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}
.reviews__embed .CarouselWidget-prefix .R-flex-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.reviews__embed .CarouselWidget-prefix .R-flex-row--noMargin {
  margin-left: 0;
  margin-right: 0;
}
.reviews__embed .CarouselWidget-prefix .R-flex-row.R-flex-reverse {
  flex-direction: row-reverse;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col.R-flex-reverse {
  flex-direction: column-reverse;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: auto;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 8.33333%;
  max-width: 8.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 16.66667%;
  max-width: 16.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 25%;
  max-width: 25%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 33.33333%;
  max-width: 33.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 41.66667%;
  max-width: 41.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 50%;
  max-width: 50%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 58.33333%;
  max-width: 58.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 66.66667%;
  max-width: 66.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 75%;
  max-width: 75%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 83.33333%;
  max-width: 83.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 91.66667%;
  max-width: 91.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 100%;
  max-width: 100%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-0 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 0;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.33333%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.66667%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.reviews__embed .CarouselWidget-prefix .R-flex-start-xs {
  justify-content: flex-start;
  text-align: left;
}
.reviews__embed .CarouselWidget-prefix .R-flex-center-xs {
  justify-content: center;
  text-align: center;
}
.reviews__embed .CarouselWidget-prefix .R-flex-end-xs {
  justify-content: flex-end;
  text-align: right;
}
.reviews__embed .CarouselWidget-prefix .R-flex-top-xs {
  align-items: flex-start;
}
.reviews__embed .CarouselWidget-prefix .R-flex-middle-xs {
  align-items: center;
}
.reviews__embed .CarouselWidget-prefix .R-flex-bottom-xs {
  align-items: flex-end;
}
.reviews__embed .CarouselWidget-prefix .R-flex-baseline-xs {
  align-items: baseline;
}
.reviews__embed .CarouselWidget-prefix .R-flex-around-xs {
  justify-content: space-around;
}
.reviews__embed .CarouselWidget-prefix .R-flex-between-xs {
  justify-content: space-between;
}
.reviews__embed .CarouselWidget-prefix .R-flex-first-xs {
  order: -1;
}
.reviews__embed .CarouselWidget-prefix .R-flex-last-xs {
  order: 1;
}
@media only screen and (min-width: 12.5em) {
  .reviews__embed .CarouselWidget-prefix .R-flex-container {
    width: 11.98rem;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xxs-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-start-xxs {
    justify-content: flex-start;
    text-align: left;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-center-xxs {
    justify-content: center;
    text-align: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-end-xxs {
    justify-content: flex-end;
    text-align: right;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-top-xxs {
    align-items: flex-start;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-middle-xxs {
    align-items: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-bottom-xxs {
    align-items: flex-end;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-baseline-xxs {
    align-items: baseline;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-around-xxs {
    justify-content: space-around;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-between-xxs {
    justify-content: space-between;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-first-xxs {
    order: -1;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-last-xxs {
    order: 1;
  }
}
@media only screen and (min-width: 30em) {
  .reviews__embed .CarouselWidget-prefix .R-flex-container {
    width: 28.75rem;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-xs-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-start-xs {
    justify-content: flex-start;
    text-align: left;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-center-xs {
    justify-content: center;
    text-align: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-end-xs {
    justify-content: flex-end;
    text-align: right;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-top-xs {
    align-items: flex-start;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-middle-xs {
    align-items: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-bottom-xs {
    align-items: flex-end;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-baseline-xs {
    align-items: baseline;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-around-xs {
    justify-content: space-around;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-between-xs {
    justify-content: space-between;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-first-xs {
    order: -1;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-last-xs {
    order: 1;
  }
}
@media only screen and (min-width: 48em) {
  .reviews__embed .CarouselWidget-prefix .R-flex-container {
    width: 46rem;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-sm-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-start-sm {
    justify-content: flex-start;
    text-align: left;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-center-sm {
    justify-content: center;
    text-align: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-end-sm {
    justify-content: flex-end;
    text-align: right;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-top-sm {
    align-items: flex-start;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-middle-sm {
    align-items: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-bottom-sm {
    align-items: flex-end;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-baseline-sm {
    align-items: baseline;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-around-sm {
    justify-content: space-around;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-between-sm {
    justify-content: space-between;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-first-sm {
    order: -1;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 62em) {
  .reviews__embed .CarouselWidget-prefix .R-flex-container {
    width: 61rem;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-md-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-start-md {
    justify-content: flex-start;
    text-align: left;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-center-md {
    justify-content: center;
    text-align: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-end-md {
    justify-content: flex-end;
    text-align: right;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-top-md {
    align-items: flex-start;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-middle-md {
    align-items: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-bottom-md {
    align-items: flex-end;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-baseline-md {
    align-items: baseline;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-around-md {
    justify-content: space-around;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-between-md {
    justify-content: space-between;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-first-md {
    order: -1;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-last-md {
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .reviews__embed .CarouselWidget-prefix .R-flex-container {
    width: 71rem;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-col-lg-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-start-lg {
    justify-content: flex-start;
    text-align: left;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-center-lg {
    justify-content: center;
    text-align: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-end-lg {
    justify-content: flex-end;
    text-align: right;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-top-lg {
    align-items: flex-start;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-middle-lg {
    align-items: center;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-bottom-lg {
    align-items: flex-end;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-baseline-lg {
    align-items: baseline;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-around-lg {
    justify-content: space-around;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-between-lg {
    justify-content: space-between;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-first-lg {
    order: -1;
  }
  .reviews__embed .CarouselWidget-prefix .R-flex-last-lg {
    order: 1;
  }
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--none {
  margin-bottom: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--xs {
  margin-bottom: 5px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--sm {
  margin-bottom: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--md {
  margin-bottom: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--lg {
  margin-bottom: 30px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--xlg {
  margin-bottom: 50px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--xxlg {
  margin-bottom: 70px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginBottom--xxxlg {
  margin-bottom: 100px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--none {
  margin-top: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--xs {
  margin-top: 5px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--sm {
  margin-top: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--md {
  margin-top: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--lg {
  margin-top: 30px !important;
}
@media screen and (min-width: 993px) {
  .reviews__embed .CarouselWidget-prefix .u-marginTop--lg--desktop--sm {
    margin-top: 30px !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--xlg {
  margin-top: 50px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--xxlg {
  margin-top: 70px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginTop--xxxlg {
  margin-top: 100px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginRight--none {
  margin-right: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginRight--xs {
  margin-right: 5px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginRight--sm {
  margin-right: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginRight--md {
  margin-right: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginRight--lg {
  margin-right: 30px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginLeft--neg--xxs {
  margin-left: -3px;
}
.reviews__embed .CarouselWidget-prefix .u-marginLeft--neg--xs {
  margin-left: -5px;
}
.reviews__embed .CarouselWidget-prefix .u-marginLeft--none {
  margin-left: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginLeft--xs {
  margin-left: 5px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginLeft--sm {
  margin-left: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginLeft--md {
  margin-left: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-marginLeft--lg {
  margin-left: 30px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingBottom--none {
  padding-bottom: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingBottom--sm {
  padding-bottom: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingBottom--md {
  padding-bottom: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingBottom--lg {
  padding-bottom: 30px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingBottom--xlg {
  padding-bottom: 50px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingBottom--xxlg {
  padding-bottom: 70px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingBottom--xxxlg {
  padding-bottom: 100px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingTop--none {
  padding-top: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingTop--sm {
  padding-top: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingTop--md {
  padding-top: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingTop--lg {
  padding-top: 30px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingTop--xlg {
  padding-top: 50px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingTop--xxlg {
  padding-top: 70px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingTop--xxxlg {
  padding-top: 100px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--none {
  padding-right: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--xxs {
  padding-right: 2px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--xs {
  padding-right: 5px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--sm {
  padding-right: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--md {
  padding-right: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--lg {
  padding-right: 30px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--xlg {
  padding-right: 50px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--xxlg {
  padding-right: 70px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingRight--xxxlg {
  padding-right: 100px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--none {
  padding-left: 0 !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--xxs {
  padding-left: 2px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--xs {
  padding-left: 5px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--sm {
  padding-left: 10px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--md {
  padding-left: 20px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--lg {
  padding-left: 30px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--xlg {
  padding-left: 50px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--xxlg {
  padding-left: 70px !important;
}
.reviews__embed .CarouselWidget-prefix .u-paddingLeft--xxxlg {
  padding-left: 100px !important;
}
.reviews__embed .CarouselWidget-prefix .u-textLeft {
  text-align: left !important;
}
.reviews__embed .CarouselWidget-prefix .u-textCenter {
  text-align: center !important;
}
.reviews__embed .CarouselWidget-prefix .u-textCenter--all {
  text-align: center !important;
}
@media (min-width: 993px) {
  .reviews__embed .CarouselWidget-prefix .u-textCenter--desktop--sm {
    text-align: center !important;
  }
}
@media (max-width: 992px) {
  .reviews__embed .CarouselWidget-prefix .u-textCenter--tablet--lg {
    text-align: center !important;
  }
}
@media (max-width: 768px) {
  .reviews__embed .CarouselWidget-prefix .u-textCenter--tablet--md {
    text-align: center !important;
  }
}
@media (max-width: 767px) {
  .reviews__embed .CarouselWidget-prefix .u-textCenter--tablet--sm {
    text-align: center !important;
  }
}
@media (max-width: 640px) {
  .reviews__embed .CarouselWidget-prefix .u-textCenter--phone--lg {
    text-align: center !important;
  }
}
@media (max-width: 480px) {
  .reviews__embed .CarouselWidget-prefix .u-textCenter--phone--md {
    text-align: center !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-textRight {
  text-align: right !important;
}
.reviews__embed .CarouselWidget-prefix .u-textRight--all {
  text-align: right !important;
}
.reviews__embed .CarouselWidget-prefix .u-textLeft--all {
  text-align: left !important;
}
.reviews__embed .CarouselWidget-prefix .u-hidden--all {
  display: none !important;
}
@media (max-width: 640px) {
  .reviews__embed .CarouselWidget-prefix .u-hidden--phone--lg {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .reviews__embed .CarouselWidget-prefix .u-hidden--tablet--md {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .reviews__embed .CarouselWidget-prefix .u-hidden--tablet--lg {
    display: none !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-visible--phone--lg {
  display: none !important;
}
@media (max-width: 640px) {
  .reviews__embed .CarouselWidget-prefix .u-visible--phone--lg {
    display: inline-block !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-visible--phone--lg--block {
  display: none !important;
}
@media (max-width: 640px) {
  .reviews__embed .CarouselWidget-prefix .u-visible--phone--lg--block {
    display: block !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-visible--tablet--md {
  display: none !important;
}
@media (max-width: 768px) {
  .reviews__embed .CarouselWidget-prefix .u-visible--tablet--md {
    display: inline-block !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-visible--tablet--lg {
  display: none !important;
}
@media (max-width: 992px) {
  .reviews__embed .CarouselWidget-prefix .u-visible--tablet--lg {
    display: inline-block !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-visible--desktop--sm {
  display: none !important;
}
@media (min-width: 993px) {
  .reviews__embed .CarouselWidget-prefix .u-visible--desktop--sm {
    display: inline-block !important;
  }
}
.reviews__embed .CarouselWidget-prefix .u-whiteSpace--prewrap {
  white-space: pre-wrap;
  word-break: break-word;
}
.reviews__embed .CarouselWidget-prefix .u-overflow--hidden {
  overflow: hidden !important;
}
.reviews__embed .CarouselWidget-prefix .u-textCapitalized {
  text-transform: capitalize;
}
.reviews__embed .CarouselWidget-prefix .u-textLowercase {
  text-transform: lowercase;
}
.reviews__embed .CarouselWidget-prefix .u-cursorPointer {
  cursor: pointer !important;
}
.reviews__embed .CarouselWidget-prefix .u-cursorDefault {
  cursor: default !important;
}
.reviews__embed .CarouselWidget-prefix .u-wordBreak--wordBreak {
  word-break: break-word !important;
}
.reviews__embed .CarouselWidget-prefix .u-wordBreak--wordAll {
  word-break: break-all !important;
}
.reviews__embed .CarouselWidget-prefix .u-textOverflow--elipsis {
  text-overflow: ellipsis;
}
.reviews__embed .CarouselWidget-prefix .u-positionRelative {
  position: relative !important;
}
.reviews__embed .CarouselWidget-prefix .u-positionStatic {
  position: static !important;
}
.reviews__embed .CarouselWidget-prefix .u-hr {
  display: block;
  height: 1px;
  border-width: var(--common-border-width, 1px);
  border-style: solid;
  border-color: var(--common-border-color, rgba(0, 0, 0, 0.15));
  margin-top: 10px;
  margin-bottom: 10px;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews__embed .CarouselWidget-prefix .u-hr {
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.15);
  }
}
.reviews__embed .CarouselWidget-prefix .u-hr--dashed {
  border-width: var(--common-border-width, 1px);
  border-style: dashed;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews__embed .CarouselWidget-prefix .u-hr--dashed {
    border-width: 1px;
  }
}
.reviews__embed .CarouselWidget-prefix .u-hr--margin-sm {
  margin-top: 20px;
  margin-bottom: 20px;
}
.reviews__embed .CarouselWidget-prefix .u-hr--margin-md {
  margin-top: 30px;
  margin-bottom: 30px;
}
.reviews__embed .CarouselWidget-prefix .u-hr--margin-lg {
  margin-top: 50px;
  margin-bottom: 50px;
}
.reviews__embed .CarouselWidget-prefix .u-displayFlex {
  display: flex;
}
.reviews__embed .CarouselWidget-prefix .u-displayInlineFlex {
  display: inline-flex;
}
.reviews__embed .CarouselWidget-prefix .u-flexJustifyContent--center,
.reviews__embed .CarouselWidget-prefix .u-justifyContent--center {
  justify-content: center !important;
}
.reviews__embed .CarouselWidget-prefix .u-flexJustifyContent--between {
  justify-content: space-between !important;
}
.reviews__embed .CarouselWidget-prefix .u-flexJustifyContent--start {
  justify-content: flex-start !important;
}
.reviews__embed .CarouselWidget-prefix .u-flexAlignItems--stretch {
  align-items: stretch !important;
}
.reviews__embed .CarouselWidget-prefix .u-flexAlignItems--center {
  align-items: center !important;
}
.reviews__embed .CarouselWidget-prefix .u-flexDirection--row {
  flex-direction: row !important;
}
.reviews__embed .CarouselWidget-prefix .u-flexDirection--column {
  flex-direction: column !important;
}
.reviews__embed .CarouselWidget-prefix .u-flexWrap--wrap {
  flex-wrap: wrap;
}
.reviews__embed .CarouselWidget-prefix .u-flexWrap--noWrap {
  flex-wrap: nowrap;
}
.reviews__embed .CarouselWidget-prefix .u-flexGrow {
  flex-grow: 1;
}
.reviews__embed .CarouselWidget-prefix .u-height--100 {
  height: 100%;
}
.reviews__embed .CarouselWidget-prefix .u-width--100 {
  width: 100%;
}
.reviews__embed .CarouselWidget-prefix .u-maxWidth--240 {
  width: 100%;
  max-width: 240px;
}
.reviews__embed .CarouselWidget-prefix .u-maxWidth--320 {
  width: 100%;
  max-width: 320px;
}
.reviews__embed .CarouselWidget-prefix .u-maxWidth--380 {
  width: 100%;
  max-width: 380px;
}
.reviews__embed .CarouselWidget-prefix .u-maxWidth--480 {
  width: 100%;
  max-width: 480px;
}
.reviews__embed .CarouselWidget-prefix .u-maxWidth--640 {
  width: 100%;
  max-width: 640px;
}
.reviews__embed .CarouselWidget-prefix .u-maxWidth--768 {
  width: 100%;
  max-width: 768px;
}
@media screen and (min-width: 768px) {
  .reviews__embed .CarouselWidget-prefix .u-scrollableMaxHeight--480--desktop {
    max-height: 480px;
    overflow-y: auto;
  }
}
.reviews__embed .CarouselWidget-prefix .u-displayBlock {
  display: block !important;
}
.reviews__embed .CarouselWidget-prefix .u-inlineBlock,
.reviews__embed .CarouselWidget-prefix .u-displayInlineBlock {
  display: inline-block;
}
.reviews__embed .CarouselWidget-prefix .u-verticalAlign--middle {
  vertical-align: middle !important;
}
.reviews__embed .CarouselWidget-prefix .u-verticalAlign--top {
  vertical-align: top !important;
}
.reviews__embed .CarouselWidget-prefix .u-pointerEvents--none {
  pointer-events: none !important;
}
.reviews__embed .CarouselWidget-prefix .u-floatRight {
  float: right;
}
.reviews__embed .CarouselWidget-prefix .u-floatLeft {
  float: left;
}
.reviews__embed .CarouselWidget-prefix .u-removeWhitespace {
  font-size: 0;
}
.reviews__embed .CarouselWidget-prefix .u-isDisabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: default;
}
.reviews__embed .CarouselWidget-prefix .u-textDecoration--underline {
  text-decoration: underline;
}
@keyframes GlobalLoaderAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.reviews .CarouselWidget-prefix .R-GlobalLoader {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 0;
  border-radius: 100%;
  border: 3px solid;
  border-top-color: rgba(0, 0, 0, 0.15);
  border-bottom-color: transparent;
  border-left-color: rgba(0, 0, 0, 0.15);
  border-right-color: transparent;
  animation: GlobalLoaderAnimation 0.7s ease-in-out infinite;
}
.reviews .CarouselWidget-prefix .R-GlobalLoader--sm {
  width: 25px;
  height: 25px;
  border-width: 3px;
}
.reviews .CarouselWidget-prefix .R-StarLoader {
  display: inline-block;
  max-width: 320px;
  width: 100%;
  position: relative;
  text-align: center;
  display: none;
  margin-top: 50px;
  min-height: 170px;
}
.reviews .CarouselWidget-prefix .R-StarLoader.isActive {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__svg {
  width: 60px;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__description {
  margin-top: -5px;
  display: none;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__messageSlider .messageSlider__item {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.25s, opacity 0.25s;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__messageSlider .messageSlider__item.isVisible {
  max-height: 80px;
  opacity: 1;
  transition: max-height 0.25s, opacity 1s;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link {
  position: relative;
  display: inline-block;
  background-color: rgba(17, 177, 167, 0.2);
  font-weight: 500;
  color: #11b1a7;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: -1px;
  margin-bottom: -1px;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link .link__icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 3px;
  margin-left: 0;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link--underline {
  padding-bottom: 2px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__link--underline:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #11b1a7;
}
.reviews .CarouselWidget-prefix .R-StarLoader .R-StarLoader__emoji {
  font-size: 18px;
  position: relative;
  top: 2px;
}
.reviews .CarouselWidget-prefix .R-StarLoader .outer {
  fill: transparent;
  stroke: #fff;
  stroke-width: 6px;
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: R-draw 4s infinite;
}
.reviews .CarouselWidget-prefix .R-StarLoader .inner {
  fill: #fff;
  transform-origin: center;
  animation: R-fadeIn 4s infinite;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-StarLoader .inner {
    display: none;
  }
}
@keyframes R-fadeIn {
  0% {
    opacity: 1;
    transform: scale(4.95) translate(44%, 43.9%);
  }
  50% {
    opacity: 0.8;
    transform: scale(4.25) translate(44%, 43.9%);
  }
  100% {
    opacity: 1;
    transform: scale(4.95) translate(44%, 43.9%);
  }
}
@keyframes R-draw {
  0% {
    transform: scale(0.9);
    stroke-dashoffset: 0;
  }
  50% {
    opacity: 0.8;
    transform: scale(0.8);
    stroke-dashoffset: 1000;
  }
  100% {
    transform: scale(0.9);
    stroke-dashoffset: 0;
  }
}
.reviews .CarouselWidget-prefix .R-StarLoader--dark .outer {
  stroke: #0e1311;
}
.reviews .CarouselWidget-prefix .R-StarLoader--dark .inner {
  fill: #0e1311;
}
.reviews .CarouselWidget-prefix .R-StarLoader--autoHeight {
  min-height: auto;
  margin-top: 0;
}
@keyframes ColouredProgressBar {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}
.reviews .CarouselWidget-prefix .R-ColouredProgressBar {
  width: 100%;
  max-width: 260px;
  height: 8px;
  background: rgba(22, 23, 43, 0.15);
  border-radius: 3px;
  overflow: hidden;
}
.reviews .CarouselWidget-prefix .R-ColouredProgressBar__inner {
  display: block;
  height: 100%;
  background: linear-gradient(90deg, #5ddab1, #18c1b6 34%, #7563e1 51%, #5ddab1 68%, #18c1b6);
  background-size: 300% 100%;
  animation: ColouredProgressBar 2s linear infinite;
}
.reviews .CarouselWidget-prefix .R-TextHeading {
  display: block;
  font-family: var(--heading-text-font-family, inherit);
  font-size: 1.75em;
  line-height: var(--heading-text-line-height, 1.4);
  font-weight: var(--heading-text-font-weight, 600);
  letter-spacing: var(--heading-text-letter-spacing, 0);
  text-transform: var(--heading-text-transform, none);
  color: var(--heading-text-color, #0e1311);
  text-align: left;
  margin-top: 0;
  margin-bottom: 1.25em;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-TextHeading {
    font-family: inherit;
    font-weight: 600;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-TextHeading--xxxxxs {
  font-size: 0.6875em;
  margin-bottom: 0.3125em;
}
.reviews .CarouselWidget-prefix .R-TextHeading--xxxxs {
  font-size: 0.875em;
  margin-bottom: 0.3125em;
}
.reviews .CarouselWidget-prefix .R-TextHeading--xxxs {
  font-size: 0.875em;
  margin-bottom: 0.3125em;
}
.reviews .CarouselWidget-prefix .R-TextHeading--xxs {
  font-size: 1.5em;
  margin-bottom: 0.3125em;
}
.reviews .CarouselWidget-prefix .R-TextHeading--xs {
  font-size: 1.125em;
  margin-bottom: 0.625em;
}
@media (max-width: 640px) {
  .reviews .CarouselWidget-prefix .R-TextHeading--xs {
    font-size: 1em;
  }
}
.reviews .CarouselWidget-prefix .R-TextHeading--sm {
  font-size: 1.25em;
}
@media (max-width: 640px) {
  .reviews .CarouselWidget-prefix .R-TextHeading--sm {
    font-size: 1.125em;
  }
}
.reviews .CarouselWidget-prefix .R-TextHeading--md {
  font-size: 1.625em;
}
@media (max-width: 640px) {
  .reviews .CarouselWidget-prefix .R-TextHeading--md {
    font-size: 1.375em;
  }
}
.reviews .CarouselWidget-prefix .R-TextHeading--lg {
  font-size: 2.25em;
}
@media (max-width: 640px) {
  .reviews .CarouselWidget-prefix .R-TextHeading--lg {
    font-size: 1.75em;
  }
}
.reviews .CarouselWidget-prefix .R-TextHeading--xlg {
  font-size: 2.625em;
}
.reviews .CarouselWidget-prefix .R-TextHeading--inline {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-TextHeading--white {
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-TextHeading--light {
  color: rgba(255, 255, 255, 0.85);
}
.reviews .CarouselWidget-prefix .R-TextHeading--semiBold {
  font-weight: 600;
}
.reviews .CarouselWidget-prefix .R-TextBody {
  display: block;
  font-family: var(--body-text-font-family, inherit);
  font-size: 1.125em;
  line-height: var(--body-text-line-height, 1.4);
  font-weight: var(--body-text-font-weight, 400);
  letter-spacing: var(--body-text-letter-spacing, 0);
  color: var(--body-text-color, #0e1311);
  text-transform: var(--body-text-transform, none);
  text-align: left;
  margin-top: 0;
  margin-bottom: 1.25em;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-TextBody {
    font-family: inherit;
    font-weight: 400;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-TextBody--xxxxs {
  font-size: 0.6875em;
  margin-bottom: 0.25em;
}
.reviews .CarouselWidget-prefix .R-TextBody--xxxs {
  font-size: 0.75em;
  margin-bottom: 0.3125em;
}
.reviews .CarouselWidget-prefix .R-TextBody--xxs {
  font-size: 0.875em;
  margin-bottom: 0.625em;
  text-transform: capitalize;
}
.reviews .CarouselWidget-prefix .R-TextBody--xs {
  font-size: 0.9375em;
  margin-bottom: 0.9375em;
}
.reviews .CarouselWidget-prefix .R-TextBody--sm {
  font-size: 1em;
}
.reviews .CarouselWidget-prefix .R-TextBody--md {
  font-size: 1.125em;
}
.reviews .CarouselWidget-prefix .R-TextBody--lg {
  font-size: 1.375em;
}
.reviews .CarouselWidget-prefix .R-TextBody--xlg {
  font-size: 1.625em;
}
.reviews .CarouselWidget-prefix .R-TextBody--inline {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-TextBody--white {
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-TextBody--light {
  color: rgba(255, 255, 255, 0.85);
}
.reviews .CarouselWidget-prefix .R-TextBody--colourDanger {
  color: #e7656e;
}
.reviews .CarouselWidget-prefix .TextList {
  list-style-type: disc;
  padding: 0 0 0 20px;
}
.reviews .CarouselWidget-prefix .TextList .TextList__item {
  position: relative;
}
.reviews .CarouselWidget-prefix .TextList .TextList__item--noBullet {
  list-style-type: none;
}
.reviews .CarouselWidget-prefix .TextList .R-TextBody--sm {
  margin-bottom: 15px;
}
.reviews .CarouselWidget-prefix .TextList--withCheckmarks {
  padding-left: 0;
}
.reviews .CarouselWidget-prefix .TextList--withCheckmarks .TextList__item {
  padding-left: 30px;
  list-style-type: none;
}
.reviews .CarouselWidget-prefix .TextList--withCheckmarks .TextList__item .item__icon {
  position: absolute;
  left: 3px;
  top: 3px;
  color: #fff;
  background-color: #11b1a7;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 60px;
}
.reviews .CarouselWidget-prefix .TextList--numbered {
  list-style-type: decimal;
}
.reviews .CarouselWidget-prefix .UnderlineText {
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
}
.reviews .CarouselWidget-prefix .UnderlineText:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: #11b1a7;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.reviews .CarouselWidget-prefix .UnderlineText--black:after {
  background-color: #0e1311;
}
.reviews .CarouselWidget-prefix .R-Button {
  display: inline-block;
  vertical-align: top;
  font-family: var(--common-button-font-family, inherit);
  font-size: var(--common-button-font-size, 16px);
  font-weight: var(--common-button-font-weight, 500);
  line-height: 1.6;
  letter-spacing: var(--common-button-letter-spacing, 0);
  color: var(--primary-button-text-color, #fff);
  background-color: var(--primary-button-bg-color, #0e1311);
  padding: var(--common-button-vertical-padding) var(--common-button-horizontal-padding);
  border: var(--common-button-border-width) solid var(--primary-button-border-color);
  border-radius: var(--common-button-border-radius);
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  text-transform: var(--common-button-text-transform);
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-Button {
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0;
    color: #fff;
    background-color: #0e1311;
    padding: 10px 25px;
    border: 2px solid #0e1311;
    border-radius: 0;
    text-transform: none;
  }
}
.reviews .CarouselWidget-prefix .R-Button:hover,
.reviews .CarouselWidget-prefix .R-Button:active,
.reviews .CarouselWidget-prefix .R-Button:focus {
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-text-color);
  text-decoration: none;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-Button:hover,
.reviews .CarouselWidget-prefix .R-Button:active,
.reviews .CarouselWidget-prefix .R-Button:focus {
    background-color: #0e1311;
    color: #fff;
  }
}
.reviews .CarouselWidget-prefix .R-Button .R-Button__text {
  display: inline-block;
  vertical-align: top;
}
.reviews .CarouselWidget-prefix .R-Button .R-Button__icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
}
.reviews .CarouselWidget-prefix .R-Button .R-Button__icon--left {
  margin-left: 0;
  margin-right: 3px;
}
.reviews .CarouselWidget-prefix .R-Button--xxs {
  font-size: 11px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
.reviews .CarouselWidget-prefix .R-Button--xs {
  font-size: 12px;
  padding: 8px 15px;
  margin-bottom: 10px;
}
.reviews .CarouselWidget-prefix .R-Button--sm {
  font-size: 14px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.reviews .CarouselWidget-prefix .R-Button--md {
  font-size: 16px;
  font-size: var(--common-button-font-size);
  padding: 10px 25px;
  padding: var(--common-button-vertical-padding) var(--common-button-horizontal-padding);
  margin-bottom: 20px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-Button--md {
    font-size: 16px;
    padding: 10px 25px;
  }
}
.reviews .CarouselWidget-prefix .R-Button--lg {
  font-size: 20px;
  padding: 12px 30px;
  margin-bottom: 20px;
}
.reviews .CarouselWidget-prefix .R-Button--primary {
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-text-color);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-Button--primary {
    background-color: #0e1311;
    color: #fff;
  }
}
.reviews .CarouselWidget-prefix .R-Button--secondary {
  background-color: var(--secondary-button-bg-color);
  border-color: var(--secondary-button-border-color);
  color: var(--secondary-button-text-color);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-Button--secondary {
    background-color: transparent;
    border-color: #0e1311;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-Button--secondary:hover,
.reviews .CarouselWidget-prefix .R-Button--secondary:active,
.reviews .CarouselWidget-prefix .R-Button--secondary:focus {
  background-color: var(--secondary-button-bg-color);
  color: var(--secondary-button-text-color);
  border-color: var(--secondary-button-border-color);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-Button--secondary:hover,
.reviews .CarouselWidget-prefix .R-Button--secondary:active,
.reviews .CarouselWidget-prefix .R-Button--secondary:focus {
    background-color: transparent;
    border-color: #0e1311;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-Button--secondary--thin {
  border-width: 1px;
}
.reviews .CarouselWidget-prefix .R-Button--secondary--light {
  border-color: rgba(255, 255, 255, 0.85);
  color: rgba(255, 255, 255, 0.85);
}
.reviews .CarouselWidget-prefix .R-Button--secondary--light:hover,
.reviews .CarouselWidget-prefix .R-Button--secondary--light:active,
.reviews .CarouselWidget-prefix .R-Button--secondary--light:focus {
  border-color: #fff;
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-Button--fw,
.reviews .CarouselWidget-prefix .R-Button--fullwidth {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 480px) {
  .reviews .CarouselWidget-prefix .R-Button--fw-phone-md {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
@media (max-width: 640px) {
  .reviews .CarouselWidget-prefix .R-Button--fw-phone-lg {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .reviews .CarouselWidget-prefix .R-Button--fw-tablet-md {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 992px) {
  .reviews .CarouselWidget-prefix .R-Button--fw-tablet-lg {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 993px) {
  .reviews .CarouselWidget-prefix .R-Button--fw-desktop-sm {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
.reviews .CarouselWidget-prefix .R-Button--desaturated {
  filter: grayscale(100%);
}
.reviews .CarouselWidget-prefix .R-Button--isLoading,
.reviews .CarouselWidget-prefix .R-Button.isLoading {
  position: relative;
  padding-right: 30px;
  opacity: 0.5;
  cursor: not-allowed;
}
.reviews .CarouselWidget-prefix .R-Button--isLoading:before,
.reviews .CarouselWidget-prefix .R-Button.isLoading:before {
  content: "";
  position: absolute;
  display: inline-block;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  border-top: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  height: 12px;
  width: 12px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  animation: CircleSpin 0.4s infinite linear;
}
.reviews .CarouselWidget-prefix .R-Button--isLoading.R-Button--secondary:before,
.reviews .CarouselWidget-prefix .R-Button.isLoading.R-Button--secondary:before {
  border-right: 2px solid rgba(0, 0, 0, 0.7);
  border-top: 2px solid rgba(0, 0, 0, 0.7);
}
.reviews .CarouselWidget-prefix .R-Button--disabled,
.reviews .CarouselWidget-prefix .R-Button.isDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.reviews .CarouselWidget-prefix .R-Button:disabled,
.reviews .CarouselWidget-prefix .R-Button[disabled] {
  filter: grayscale(1);
  opacity: 0.5;
  cursor: not-allowed;
}
.reviews .CarouselWidget-prefix .R-IconButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #0e1311;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-IconButton .ricon-thumbsup {
  color: var(--badge-icon-color, #0e1311);
}
.reviews .CarouselWidget-prefix .R-IconButton .R-IconButton__icon {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-IconButton .R-IconButton__icon--image {
  max-width: 100%;
}
.reviews .CarouselWidget-prefix .R-IconButton--xs {
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 18px;
}
.reviews .CarouselWidget-prefix .R-IconButton--sm {
  font-size: 14px;
  height: 20px;
  width: 20px;
  line-height: 22px;
}
.reviews .CarouselWidget-prefix .R-IconButton--sm .R-IconButton__icon--close {
  font-size: 12px;
  font-weight: 800;
}
.reviews .CarouselWidget-prefix .R-IconButton--md {
  font-size: 16px;
  height: 22px;
  width: 22px;
  line-height: 24px;
}
.reviews .CarouselWidget-prefix .R-IconButton--lg {
  font-size: 20px;
  height: 26px;
  width: 26px;
  line-height: 28px;
}
.reviews .CarouselWidget-prefix .R-IconButton--xlg {
  font-size: 24px;
  height: 30px;
  width: 30px;
  line-height: 32px;
}
.reviews .CarouselWidget-prefix .R-IconButton--xxlg {
  font-size: 28px;
  height: 34px;
  width: 34px;
  line-height: 36px;
}
.reviews .CarouselWidget-prefix .R-IconButton--xxxxlg {
  font-size: 36px;
  height: 46px;
  width: 46px;
  line-height: 52px;
}
.reviews .CarouselWidget-prefix .R-IconButton--xxxxlg.R-IconButton--play {
  font-size: 32px;
}
.reviews .CarouselWidget-prefix .R-IconButton--xxxxlg.R-IconButton--play .R-IconButton__icon {
  margin-left: -1px;
}
.reviews .CarouselWidget-prefix .R-IconButton--xxxxlg.R-IconButton--pause {
  font-size: 28px;
}
.reviews .CarouselWidget-prefix .R-IconButton--black {
  background-color: #0e1311;
  color: #fff;
  border-radius: 50px;
}
.reviews .CarouselWidget-prefix .R-IconButton--outline {
  border-radius: 50px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #0e1311;
  color: #0e1311;
}
.reviews .CarouselWidget-prefix .R-IconButton--lightIcon {
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-IconButton--matchTextColor {
  color: var(--heading-text-color, #0e1311);
}
.reviews .CarouselWidget-prefix .R-IconButton--noRadius {
  border-radius: 0;
}
.reviews .CarouselWidget-prefix .R-IconButton--isDisabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: default;
}
.reviews .CarouselWidget-prefix .R-IconButton.isActive {
  background-color: rgba(0, 0, 0, 0.1);
}
.reviews .CarouselWidget-prefix .R-ToggleControl {
  position: relative;
  display: inline-block;
  max-width: 40px;
  height: 20px;
  width: 100%;
  transition: all 0.25s ease-in-out;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  cursor: pointer;
}
.reviews .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__circle {
  position: absolute;
  top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  left: calc(100% - 18px);
  transition: all 0.25s ease-in-out;
  background-color: #fff;
  text-align: center;
  line-height: 16px;
}
.reviews .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__icon {
  font-size: 10px;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.25s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__icon--active {
  transform: scale(0.6, 0.6);
}
.reviews .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
}
.reviews .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__tooltip--active {
  display: none;
}
.reviews .CarouselWidget-prefix .R-ToggleControl .R-ToggleControl__tooltip--inactive {
  display: block;
}
.reviews .CarouselWidget-prefix .R-ToggleControl--md {
  max-width: 55px;
  height: 25px;
}
.reviews .CarouselWidget-prefix .R-ToggleControl--md .R-ToggleControl__circle {
  top: 2px;
  width: 20px;
  height: 20px;
  left: calc(100% - 22px);
  line-height: 20px;
}
.reviews .CarouselWidget-prefix .R-ToggleControl--md .R-ToggleControl__icon {
  font-size: 12px;
}
.reviews .CarouselWidget-prefix .R-ToggleControl.isActive .R-ToggleControl__tooltip--active,
.reviews .CarouselWidget-prefix .R-ToggleControl.isSelected .R-ToggleControl__tooltip--active {
  display: block;
}
.reviews .CarouselWidget-prefix .R-ToggleControl.isActive .R-ToggleControl__tooltip--inactive,
.reviews .CarouselWidget-prefix .R-ToggleControl.isSelected .R-ToggleControl__tooltip--inactive {
  display: none;
}
.reviews .CarouselWidget-prefix .R-ToggleControl.isActive .R-ToggleControl__circle,
.reviews .CarouselWidget-prefix .R-ToggleControl.isSelected .R-ToggleControl__circle {
  left: 2px;
  background-color: #11b1a7;
}
.reviews .CarouselWidget-prefix .R-ToggleControl.isActive .R-ToggleControl__icon,
.reviews .CarouselWidget-prefix .R-ToggleControl.isSelected .R-ToggleControl__icon {
  opacity: 1;
  color: #fff;
  font-weight: 500;
}
.reviews .CarouselWidget-prefix .R-ToggleControl.isActive .R-ToggleControl__icon--active,
.reviews .CarouselWidget-prefix .R-ToggleControl.isSelected .R-ToggleControl__icon--active {
  transform: scale(1, 1);
}
.reviews .CarouselWidget-prefix .R-ToggleControl--darkActive.isActive,
.reviews .CarouselWidget-prefix .R-ToggleControl--darkActive.isSelected {
  background-color: rgba(255, 255, 255, 0.95);
}
.reviews .CarouselWidget-prefix .R-ToggleControl--darkActive.isActive .R-ToggleControl__circle,
.reviews .CarouselWidget-prefix .R-ToggleControl--darkActive.isSelected .R-ToggleControl__circle {
  background-color: rgba(14, 19, 17, 0.6);
}
.reviews .CarouselWidget-prefix * {
  box-sizing: border-box;
}
.reviews .CarouselWidget-prefix a {
  text-decoration: none;
}
.reviews .CarouselWidget-prefix .R-PlatformIcon {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  width: auto;
}
.reviews .CarouselWidget-prefix .R-PlatformIcon--xxs {
  height: 20px;
}
.reviews .CarouselWidget-prefix .R-PlatformIcon--xs {
  height: 25px;
}
.reviews .CarouselWidget-prefix .R-PlatformIcon--sm {
  height: 30px;
}
.reviews .CarouselWidget-prefix .R-PlatformIcon--md {
  height: 40px;
}
.reviews .CarouselWidget-prefix .R-PlatformLogo {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  width: auto;
}
.reviews .CarouselWidget-prefix .R-PlatformLogo--xxs {
  height: 20px;
}
.reviews .CarouselWidget-prefix .R-PlatformLogo--xs {
  height: 25px;
}
.reviews .CarouselWidget-prefix .R-PlatformLogo--sm {
  height: 30px;
}
.reviews .CarouselWidget-prefix .R-PlatformLogo--md {
  height: 40px;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail {
  position: relative;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  width: auto;
  max-height: var(--photo-video-thumbnail-size, 80px);
  border-radius: var(--photo-video-thumbnail-border-radius, 0);
  overflow: hidden;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail {
    height: 80px;
    border-radius: 0;
  }
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail .R-PhotoVideoThumbnail__image {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  max-height: var(--photo-video-thumbnail-size, 80px);
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail .R-PhotoVideoThumbnail__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--photo-video-thumbnail-size) * 0.28);
  line-height: calc(var(--photo-video-thumbnail-size) * 0.28);
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail .R-PhotoVideoThumbnail__overlay {
    font-size: 24px;
    line-height: 24px;
  }
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--xs {
  height: 25px;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--sm {
  height: 30px;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--md {
  height: 40px;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--lg {
  height: 60px;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--xlg {
  height: 80px;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--xxlg {
  height: 100px;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--fullwidth {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.reviews .CarouselWidget-prefix .R-PhotoVideoThumbnail--fullwidth .R-PhotoVideoThumbnail__image {
  height: auto;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail {
  position: relative;
  display: inline-block;
  max-width: var(--avatar-thumbnail-size, 60px);
  max-height: var(--avatar-thumbnail-size, 60px);
  width: 100%;
  border-radius: var(--avatar-thumbnail-border-radius, 0);
  overflow: hidden;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-AvatarThumbnail {
    height: 60px;
    width: 60px;
    border-radius: 100px;
  }
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 100%;
  background-color: var(--avatar-thumbnail-bg-color, rgba(0, 0, 0, 0.1));
  color: var(--avatar-thumbnail-text-color, #0e1311);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__inner {
    background-color: rgba(0, 0, 0, 0.1);
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__inner .R-TextBody,
.reviews .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__inner .R-TextHeading {
  color: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 30px;
  line-height: 30px;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail .R-AvatarThumbnail__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-size: cover;
  background-position: center center;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--xs {
  width: 25px;
  height: 25px;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--sm {
  width: 30px;
  height: 30px;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--md {
  width: 40px;
  height: 40px;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--lg {
  width: 60px;
  height: 60px;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--xlg {
  width: 80px;
  height: 80px;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--xxlg {
  width: 100px;
  height: 100px;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--fullwidth {
  max-width: 100%;
}
.reviews .CarouselWidget-prefix .R-AvatarThumbnail--round {
  border-radius: 200px;
}
.reviews .CarouselWidget-prefix .R-TabControls {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item {
  display: inline-block;
  font-size: var(--pagination-tab-text-font-size, 16px);
  font-weight: var(--pagination-tab-text-font-weight, 600);
  text-transform: var(--pagination-tab-text-transform, none);
  letter-spacing: var(--pagination-tab-text-letter-spacing, 0);
  color: var(--pagination-tab-text-color, #0e1311);
  border-bottom: var(--pagination-tab-border-width, 3px) solid transparent;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item {
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0;
    color: #0e1311;
    border-bottom: 3px solid transparent;
  }
}
.reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item > .R-TextBody,
.reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item > .R-TextHeading {
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
  margin-bottom: 5px;
}
.reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item.isActive {
  font-weight: var(--pagination-tab-active-text-font-weight, 600);
  color: var(--pagination-tab-active-text-color, #0e1311);
  border-bottom-color: var(--pagination-tab-active-border-color, #0e1311);
  cursor: default;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item.isActive {
    font-weight: 600;
    color: #0e1311;
    border-bottom-color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item.isActive > .R-TextBody,
.reviews .CarouselWidget-prefix .R-TabControls .R-TabControls__item.isActive > .R-TextHeading {
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
}
.reviews .CarouselWidget-prefix .R-PaginationControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.reviews .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item {
  display: inline-flex;
  font-size: var(--pagination-tab-text-font-size, 16px);
  font-weight: var(--pagination-tab-text-font-weight, 600);
  color: var(--pagination-tab-text-color, #0e1311);
  text-transform: var(--pagination-tab-text-transform, none);
  letter-spacing: var(--pagination-tab-text-letter-spacing, 0);
  border-top: var(--pagination-tab-border-width, 3px) solid transparent;
  border-bottom: var(--pagination-tab-border-width, 3px) solid transparent;
  padding: 2px 8px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item {
    font-size: 16px;
    font-weight: 600;
    color: #0e1311;
    text-transform: none;
    letter-spacing: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
}
.reviews .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item > .R-TextBody,
.reviews .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item > .R-TextHeading {
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
  margin-bottom: 5px;
}
.reviews .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item.isActive {
  font-weight: var(--pagination-tab-active-text-font-weight, 600);
  color: var(--pagination-tab-active-text-color, #0e1311);
  border-bottom-color: var(--pagination-tab-active-border-color, #0e1311);
  cursor: default;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item.isActive {
    font-weight: 600;
    color: #0e1311;
    border-bottom-color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-PaginationControls .R-PaginationControls__item .item__arrowIcon {
  line-height: 1.5;
}
.reviews .CarouselWidget-prefix .R-ContentSlider {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  min-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.reviews .CarouselWidget-prefix .R-ContentSlider::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.reviews .CarouselWidget-prefix .R-ContentSlider .R-ContentSlider__item {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-ContentSlider .R-ContentSlider__item div {
    flex: 1 1 auto !important;
  }
}
.reviews .CarouselWidget-prefix .R-DotIndicator {
  position: relative;
  display: inline-flex;
}
.reviews .CarouselWidget-prefix .R-DotIndicator .R-DotIndicator__item {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  vertical-align: middle;
}
.reviews .CarouselWidget-prefix .R-DotIndicator .R-DotIndicator__item:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.reviews .CarouselWidget-prefix .R-DotIndicator .R-DotIndicator__item.isActive {
  background-color: #0e1311;
  cursor: default;
}
.reviews .CarouselWidget-prefix .R-DotIndicator--sm .R-DotIndicator__item {
  width: 5px;
  height: 5px;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu-container {
  display: inline-block;
  position: relative;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu {
  position: absolute;
  padding: 10px 0;
  left: 0;
  top: 90%;
  z-index: 15;
  background-color: var(--dropupdown-bg-color, #fff);
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transform: scale(0.94, 0.94) translateY(20px);
  transition: all 0.3s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__inner {
  padding: 5px 0;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__column--width-md {
  width: 180px;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__item {
  padding: 7px 15px;
  cursor: pointer;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__item .item__activeIcon {
  display: none;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__item.isActive .item__activeIcon {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu .R-DropUpDownMenu__item.isDisabled {
  cursor: default;
  opacity: 0.2;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu--sm {
  padding: 5px 0;
  width: 140px;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu--sm .R-DropUpDownMenu__item {
  padding: 5px 10px;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu--md {
  width: 200px;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu--lg {
  width: 240px;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu--auto {
  width: auto;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu--above {
  top: auto;
  bottom: 100%;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu--maxHeight380 .R-DropUpDownMenu__inner {
  max-height: 380px;
  overflow-y: auto;
}
.reviews .CarouselWidget-prefix .R-DropUpDownMenu.isActive {
  opacity: 1;
  transform: scale(1, 1) translateY(0px);
  pointer-events: auto;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon {
  display: inline-block;
  width: 30px;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg {
  width: 100%;
  vertical-align: top;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color1,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color2,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color3,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color4,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-color5 {
  fill: var(--icon-accent-color) !important;
  transform-origin: center;
  transition: all 0.15s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color1,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color2,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color3,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color4,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color5,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color6,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color7,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color8,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color9,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color10,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color11,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color12,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color13,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color14,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color15,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color16,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon svg #dynamic-stroke-color17 {
  stroke: var(--icon-base-color) !important;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon img {
  width: 100%;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon--xxs {
  width: 25px;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon--xs {
  width: 30px;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon--sm {
  width: 35px;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon--md {
  width: 40px;
}
.reviews .CarouselWidget-prefix .R-EmbeddedIcon-animationTrigger:hover .R-EmbeddedIcon svg #dynamic-color,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon-animationTrigger:hover .R-EmbeddedIcon svg #dynamic-color1,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon-animationTrigger:hover .R-EmbeddedIcon svg #dynamic-color3,
.reviews .CarouselWidget-prefix .R-EmbeddedIcon-animationTrigger:hover .R-EmbeddedIcon svg #dynamic-color4 {
  animation-name: R-shakeAnimation--md;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 2;
}
.reviews .CarouselWidget-prefix .R-VideoPlayer {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 100%;
  max-height: 480px;
  background-color: rgba(15, 13, 24, 0.015);
  outline: none;
}
.reviews .CarouselWidget-prefix .R-VideoPlayer video {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 100%;
  max-height: 480px;
  outline: none;
}
.reviews .CarouselWidget-prefix .R-VideoPlayer--nativeSize {
  max-height: none;
}
.reviews .CarouselWidget-prefix .R-VideoPlayer--nativeSize video {
  max-height: none;
}
.reviews .CarouselWidget-prefix .R-VideoPlayer--rounded {
  border-radius: 20px;
}
.reviews .CarouselWidget-prefix .R-MaskedVideoPhoto {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 320px;
}
.reviews .CarouselWidget-prefix .R-MaskedVideoPhoto .R-MaskedVideoPhoto__video {
  display: inline-block;
  position: absolute;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  outline: none;
  left: 0;
  top: 0;
}
.reviews .CarouselWidget-prefix .R-MaskedVideoPhoto .R-MaskedVideoPhoto__overlay {
  --photo-video-thumbnail-size: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--photo-video-thumbnail-size) * 0.56);
  line-height: calc(var(--photo-video-thumbnail-size) * 0.56);
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MaskedVideoPhoto .R-MaskedVideoPhoto__overlay {
    font-size: 24px;
    line-height: 24px;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__score {
  display: block;
  font-family: var(--heading-text-font-family, inherit);
  font-size: 1.75em;
  line-height: var(--heading-text-line-height, 1.4);
  font-weight: var(--heading-text-font-weight, 600);
  letter-spacing: var(--heading-text-letter-spacing, 0);
  text-transform: var(--heading-text-transform, none);
  color: var(--heading-text-color, #0e1311);
  text-align: left;
  margin-top: 0;
  margin-bottom: 1.25em;
  font-size: 1em;
  margin-bottom: 0.3125em;
  margin-right: 5px;
  margin-bottom: 5px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__score {
    font-family: inherit;
    font-weight: 600;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 5px;
  margin-bottom: 5px;
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon {
  color: var(--common-star-color);
  font-size: var(--medium-star-size, 22px);
  line-height: 1;
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--disabled {
  color: var(--common-star-disabled-color);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon {
    color: #0e1311;
    font-size: 22px;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--0 {
  color: var(--common-star-disabled-color);
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--25 {
  background-image: linear-gradient(to right, var(--common-star-color) 38%, var(--common-star-disabled-color) 38.1%);
  background-size: 100%;
  -webkit-background-clip: text;
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--50 {
  background-image: linear-gradient(to right, var(--common-star-color) 50%, var(--common-star-disabled-color) 50.1%);
  background-size: 100%;
  -webkit-background-clip: text;
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--75 {
  background-image: linear-gradient(to right, var(--common-star-color) 60%, var(--common-star-disabled-color) 60.1%);
  background-size: 100%;
  -webkit-background-clip: text;
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon--100 {
  background-image: linear-gradient(to right, var(--common-star-color) 99.9%, var(--common-star-color) 100%);
  background-size: 100%;
  -webkit-background-clip: text;
}
.reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__count {
  display: block;
  font-family: var(--body-text-font-family, inherit);
  font-size: 1.125em;
  line-height: var(--body-text-line-height, 1.4);
  font-weight: var(--body-text-font-weight, 400);
  letter-spacing: var(--body-text-letter-spacing, 0);
  color: var(--body-text-color, #0e1311);
  text-transform: var(--body-text-transform, none);
  text-align: left;
  margin-top: 0;
  margin-bottom: 1.25em;
  font-size: 0.875em;
  margin-bottom: 0.625em;
  margin-bottom: 5px;
  margin-right: 5px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__count {
    font-family: inherit;
    font-weight: 400;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars--xlg .R-RatingStars__score {
  font-size: 1.375em;
  line-height: 1.5em;
}
.reviews .CarouselWidget-prefix .R-RatingStars--xlg .R-RatingStars__stars .stars__icon {
  font-size: var(--x-large-star-size, 28px);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars--xlg .R-RatingStars__stars .stars__icon {
    font-size: 28px;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars--xlg .R-RatingStars__count {
  font-size: 1.375em;
  line-height: 1.5em;
}
.reviews .CarouselWidget-prefix .R-RatingStars--lg .R-RatingStars__score {
  font-size: 1.125em;
  line-height: 1.5em;
}
.reviews .CarouselWidget-prefix .R-RatingStars--lg .R-RatingStars__stars .stars__icon {
  font-size: var(--large-star-size, 24px);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars--lg .R-RatingStars__stars .stars__icon {
    font-size: 24px;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars--lg .R-RatingStars__count {
  font-size: 1.125em;
  line-height: 1.5em;
}
.reviews .CarouselWidget-prefix .R-RatingStars--md .R-RatingStars__score {
  font-size: 1em;
  line-height: 1.7em;
}
.reviews .CarouselWidget-prefix .R-RatingStars--md .R-RatingStars__stars .stars__icon {
  font-size: var(--medium-star-size, 22px);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars--md .R-RatingStars__stars .stars__icon {
    font-size: 22px;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars--md .R-RatingStars__count {
  font-size: 1em;
  line-height: 1.7em;
}
.reviews .CarouselWidget-prefix .R-RatingStars--sm .R-RatingStars__score {
  font-size: 0.875em;
  line-height: 1.6;
  margin-right: 3px;
}
.reviews .CarouselWidget-prefix .R-RatingStars--sm .R-RatingStars__stars .stars__icon {
  font-size: var(--small-star-size, 19px);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars--sm .R-RatingStars__stars .stars__icon {
    font-size: 19px;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars--sm .R-RatingStars__count {
  font-size: 0.875em;
  line-height: 1.6;
}
.reviews .CarouselWidget-prefix .R-RatingStars--xs .R-RatingStars__score {
  font-size: 0.75em;
  line-height: 1.5;
  margin-right: 4px;
}
.reviews .CarouselWidget-prefix .R-RatingStars--xs .R-RatingStars__stars .stars__icon {
  font-size: var(--x-small-star-size, 16px);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingStars--xs .R-RatingStars__stars .stars__icon {
    font-size: 16px;
  }
}
.reviews .CarouselWidget-prefix .R-RatingStars--xs .R-RatingStars__count {
  font-size: 0.75em;
  line-height: 1.5;
}
.reviews .CarouselWidget-prefix .R-RatingStars--inlineBlock {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-RatingStars--white .R-RatingStars__score,
.reviews .CarouselWidget-prefix .R-RatingStars--white .R-RatingStars__count {
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-RatingStars--white .R-RatingStars__stars .stars__icon {
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-RatingStars--black .R-RatingStars__score,
.reviews .CarouselWidget-prefix .R-RatingStars--black .R-RatingStars__count {
  color: #0e1311;
}
.reviews .CarouselWidget-prefix .R-RatingStars--black .R-RatingStars__stars .stars__icon {
  color: #0e1311;
}
.reviews .CarouselWidget-prefix .R-ReviewsioLogo {
  display: inline-block;
  color: transparent;
}
.reviews .CarouselWidget-prefix .R-ReviewsioLogo .R-ReviewsioLogo__image {
  display: inline-block;
  max-width: 100%;
  width: 100%;
  vertical-align: top;
}
.reviews .CarouselWidget-prefix .R-ReviewsioLogo--xs {
  width: 100px;
}
.reviews .CarouselWidget-prefix .R-ReviewsioLogo--sm {
  width: 120px;
}
.reviews .CarouselWidget-prefix .R-RatingSelection {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item {
  text-align: center;
  padding: 0;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item .R-RatingSelection__button {
  display: inline-block;
  color: var(--common-star-color, #0e1311);
  font-size: 32px;
  line-height: 26px;
  padding: 7px 2px;
  line-height: 14px;
  cursor: pointer;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item .R-RatingSelection__button {
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item .star__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item .star__icon--empty {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item .star__icon--full {
  display: none;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item .R-RatingSelection__button:hover .star__icon--empty,
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item.isSelected .R-RatingSelection__button .star__icon--empty {
  display: none;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item .R-RatingSelection__button:hover .star__icon--full,
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item.isSelected .R-RatingSelection__button .star__icon--full {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-RatingSelection .R-RatingSelection__item--small .R-RatingSelection__button {
  padding: 0 2px;
  font-size: 24px;
  line-height: 22px;
}
.reviews .CarouselWidget-prefix .R-BadgeElement {
  font-size: var(--base-font-size, 16px);
  flex-wrap: nowrap;
}
.reviews .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__icon {
  font-size: 1.25em;
  height: calc(var(--badge-icon-font-size, 20px) * 1.2);
  width: calc(var(--badge-icon-font-size, 20px) * 1.2);
  line-height: var(--badge-icon-font-size, 28px);
  margin-right: 0.1em;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__icon {
    font-size: 20px;
    height: 26px;
    width: 26px;
    line-height: 28px;
  }
}
.reviews .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__icon .cssVar-badgeElement__icon {
  font-size: var(--badge-icon-font-size, inherit);
  color: var(--badge-icon-color, #0e1311);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__icon .cssVar-badgeElement__icon {
    font-size: inherit;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__text .cssVar-badgeElement__text {
  font-size: var(--badge-text-font-size, inherit);
  color: var(--badge-text-color, #0e1311);
  letter-spacing: var(--badge-text-letter-spacing, inherit);
  text-transform: var(--badge-text-transform, inherit);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-BadgeElement .R-BadgeElement__text .cssVar-badgeElement__text {
    font-size: inherit;
    color: #0e1311;
    letter-spacing: 0;
    text-transform: none;
  }
}
.reviews .CarouselWidget-prefix .R-SliderIndicator-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: -10px;
  margin-right: -10px;
}
.reviews .CarouselWidget-prefix .R-SliderIndicator-group .R-SliderIndicator {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 0;
}
.reviews .CarouselWidget-prefix .R-SliderIndicator {
  display: inline-block;
  vertical-align: bottom;
  font-size: var(--base-font-size, 16px);
  max-width: var(--slider-indicator-width, 170px);
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 30px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-SliderIndicator {
    max-width: 170px;
    font-size: 16px;
  }
}
.reviews .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__inner {
  position: relative;
  margin-bottom: 4px;
}
.reviews .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__bg {
  background-color: var(--slider-indicator-bg-color, rgba(0, 0, 0, 0.1));
  height: 4px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__bg {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.reviews .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__button {
  position: absolute;
  width: 5px;
  height: 10px;
  background-color: var(--slider-indicator-button-color, #0e1311);
  top: -3px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-SliderIndicator .R-SliderIndicator__button {
    background-color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-SliderIndicator .R-TextBody {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.reviews .CarouselWidget-prefix .R-SliderIndicator--sm {
  max-width: calc(var(--slider-indicator-width, 190px) - 10px);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-SliderIndicator--sm {
    max-width: 140px;
  }
}
.reviews .CarouselWidget-prefix .R-SliderIndicator--style-filledbar .R-SliderIndicator__inner {
  overflow: hidden;
}
.reviews .CarouselWidget-prefix .R-SliderIndicator--style-filledbar .R-SliderIndicator__bg {
  background-color: var(--slider-indicator-button-color, #0e1311);
  height: 5px;
}
.reviews .CarouselWidget-prefix .R-SliderIndicator--style-filledbar .R-SliderIndicator__button {
  width: 120%;
  background-color: var(--slider-indicator-bg-color, rgba(0, 0, 0, 0.1));
}
.reviews .CarouselWidget-prefix .R-MediaSlider {
  position: relative;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--mediaslider-scroll-button-icon-color, #0e1311);
  text-align: center;
  cursor: pointer;
  z-index: 3;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 24px;
  height: 100%;
  width: 30px;
  line-height: 32px;
  background-color: var(--mediaslider-scroll-button-bg-color, rgba(255, 255, 255, 0.85));
  backdrop-filter: blur(3px);
  transition: all 0.25s ease-in-out;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton {
    color: #0e1311;
    background-color: rgba(255, 255, 255, 0.85);
  }
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton .scrollButton__icon {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton--left {
  left: 0;
  right: auto;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__scrollButton--right {
  right: 0;
  left: auto;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  min-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__inner::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: var(--mediaslider-item-size, 110px);
  width: var(--mediaslider-item-size, 110px);
  height: var(--mediaslider-item-size, 110px);
  flex-shrink: 0;
  scroll-snap-align: start;
  margin-right: 10px;
  box-sizing: border-box;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item {
    flex-basis: 110px;
    width: 110px;
    height: 110px;
  }
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__image {
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  background-repeat: no-repeat;
  overflow: hidden;
  padding: 5px;
  background-size: cover;
  background-position: center center;
  border-radius: var(--photo-video-thumbnail-border-radius, 0);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__image {
    border-radius: 0;
  }
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .image__src {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  object-fit: cover;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  text-align: center;
  color: var(--mediaslider-overlay-text-color, #fff);
  background-color: var(--mediaslider-overlay-bg-color, rgba(0, 0, 0, 0.8));
  padding: 5px;
  margin: 0;
  border-radius: 0;
  transition: all 0.2s ease-in-out 0s;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__overlay {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__overlay .R-TextBody {
  font-size: calc(var(--mediaslider-item-size) * 0.1);
  color: inherit;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__overlay .R-TextBody {
    font-size: 11px;
  }
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .item__playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: none;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .playOverlay__button {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: var(--mediaslider-overlay-text-color, #fff);
  font-size: calc(var(--mediaslider-item-size) * 0.2333333333);
  line-height: calc(var(--mediaslider-item-size) * 0.3888888889);
  text-align: center;
  background-color: transparent;
  width: calc(var(--mediaslider-item-size) * 0.35);
  height: calc(var(--mediaslider-item-size) * 0.35);
  border-radius: var(--photo-video-thumbnail-border-radius, 0);
  transition: all 0.3s ease-in-out;
  opacity: 0.9;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .playOverlay__button {
    font-size: 26px;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 0;
  }
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .playOverlay__button .button__icon {
  display: inline-block;
  margin-right: -1px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__review {
  display: none;
  transform: scale(0.9, 0.9);
  opacity: 0;
  transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__stars {
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__stars .stars__icon {
  font-size: calc(var(--mediaslider-item-size) * 0.1166666667);
  margin: 0 1px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__stars .stars__icon {
    font-size: 14px;
  }
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__instagram {
  display: none;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item .overlay__instagram .instagram__icon {
  font-size: 16px;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item--reviewPhoto .overlay__review {
  display: block;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item--reviewVideo .item__playOverlay {
  display: flex;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item--reviewVideo .overlay__review {
  display: block;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item--reviewVideo:hover .item__playOverlay .playOverlay__button {
  transform: scale(5);
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item--reviewVideo:hover .item__playOverlay .playOverlay__button .button__icon {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item--instagramPhoto .overlay__instagram {
  display: block;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item:hover .item__overlay {
  opacity: 1;
  pointer-events: auto;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item:hover .overlay__instagram {
  transform: scale(1, 1);
  opacity: 1;
}
.reviews .CarouselWidget-prefix .R-MediaSlider .R-MediaSlider__item:hover .overlay__review {
  transform: scale(1, 1);
  opacity: 1;
}
.reviews .CarouselWidget-prefix .R-ProductPreviewList {
  margin-bottom: 20px;
}
.reviews .CarouselWidget-prefix .R-ProductPreviewList .R-ProductPreviewList__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: -5px;
  margin-right: -5px;
}
.reviews .CarouselWidget-prefix .R-ProductPreviewList .R-ProductPreviewList__inner .R-ProductPreviewList__item {
  max-width: 170px;
  width: 100%;
  padding: 0 5px;
  margin-bottom: 10px;
}
.reviews .CarouselWidget-prefix .R-ProductPreviewList .R-ProductPreviewList__inner .R-ProductPreviewList__item .item__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border: var(--common-border-width, 1px) solid var(--common-border-color, rgba(0, 0, 0, 0.15));
  padding: 10px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-ProductPreviewList .R-ProductPreviewList__inner .R-ProductPreviewList__item .item__content {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}
.reviews .CarouselWidget-prefix .R-XSM .R-ProductPreviewList .R-ProductPreviewList__inner .R-ProductPreviewList__item {
  max-width: 50%;
}
.reviews .CarouselWidget-prefix .R-ContentCard {
  background-color: var(--card-bg-color, #fff);
  box-shadow: 0 calc(var(--card-shadow-size) / 4) var(--card-shadow-size) -1px var(--card-shadow-color);
  padding-top: var(--card-vertical-padding, 15px);
  padding-bottom: var(--card-vertical-padding, 15px);
  padding-left: var(--card-horizontal-padding, 10px);
  padding-right: var(--card-horizontal-padding, 10px);
  border-radius: var(--card-border-radius, 6px);
  text-align: left;
  border: var(--card-border-width, 1px) solid var(--card-border-color, rgba(0, 0, 0, 0.1));
}
.reviews .CarouselWidget-prefix .R-LabelTag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--heading-text-font-family, inherit);
  font-size: 0.75em;
  line-height: 1.4;
  font-weight: 400;
  text-transform: none;
  color: var(--body-text-color, #0e1311);
  padding: 2px 6px;
  border-radius: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--common-border-color, rgba(0, 0, 0, 0.15));
  vertical-align: middle;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-LabelTag {
    color: #0e1311;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-family: inherit;
  }
}
.reviews .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon {
  display: inline-block;
  margin-right: 2px;
}
.reviews .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon--left {
  margin-left: 0;
  margin-right: 2px;
}
.reviews .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon--clickable {
  cursor: pointer;
}
.reviews .CarouselWidget-prefix .R-LabelTag .R-LabelTag__icon--selectedIcon {
  display: none;
}
.reviews .CarouselWidget-prefix .R-LabelTag .R-LabelTag__text + .R-LabelTag__icon {
  margin-right: 0;
  margin-left: 2px;
}
.reviews .CarouselWidget-prefix .R-LabelTag .R-LabelTag__iconImage {
  max-width: 16px;
  width: 100%;
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-LabelTag--xs {
  font-size: 0.6875em;
  padding: 1px 4px;
  margin-right: 3px;
  margin-bottom: 3px;
}
.reviews .CarouselWidget-prefix .R-LabelTag--sm {
  font-size: 0.75em;
  padding: 2px 6px;
}
.reviews .CarouselWidget-prefix .R-LabelTag--md {
  font-size: 0.875em;
  padding: 3px 8px;
}
.reviews .CarouselWidget-prefix .R-LabelTag--lg {
  font-size: 1em;
  padding: 5px 10px;
}
.reviews .CarouselWidget-prefix .R-LabelTag--success {
  border-color: #11b1a7;
  color: #11b1a7;
}
.reviews .CarouselWidget-prefix .R-LabelTag--danger {
  border-color: #e7656e;
  color: #e7656e;
}
.reviews .CarouselWidget-prefix .R-LabelTag--warning {
  border-color: #ffb846;
  color: #ffb846;
}
.reviews .CarouselWidget-prefix .R-LabelTag--neutral {
  border-color: rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.4);
}
.reviews .CarouselWidget-prefix .R-LabelTag--black {
  border-color: #0e1311;
  color: #0e1311;
}
.reviews .CarouselWidget-prefix .R-LabelTag--filled-success {
  background-color: #11b1a7;
  border-color: #11b1a7;
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-LabelTag--filled-danger {
  background-color: #e7656e;
  border-color: #e7656e;
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-LabelTag--filled-warning {
  background-color: #ffb846;
  border-color: #ffb846;
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-LabelTag--filled-neutral {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.05);
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-LabelTag--filled-black {
  background-color: #0e1311;
  border-color: #0e1311;
  color: #fff;
}
.reviews .CarouselWidget-prefix .R-LabelTag.isSelected {
  border-color: var(--primary-button-bg-color, #0e1311);
  color: var(--primary-button-bg-color, #0e1311);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-LabelTag.isSelected {
    border-color: #0e1311;
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-LabelTag.isSelected .R-LabelTag__icon--selectedIcon {
  display: inline-block;
}
.reviews .CarouselWidget-prefix .R-IconLabel {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  margin-bottom: 5px;
}
.reviews .CarouselWidget-prefix .R-IconLabel .R-IconLabel__iconImage {
  max-width: 16px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.reviews .CarouselWidget-prefix .R-IconLabel--sm .R-IconLabel__iconImage {
  max-width: 18px;
  height: 18px;
}
.reviews .CarouselWidget-prefix .R-IconLabel--md .R-IconLabel__iconImage {
  max-width: 25px;
  height: 25px;
}
.reviews .CarouselWidget-prefix .R-GlobalModal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 5990;
  text-align: center;
  background-color: var(--popup-backdrop-color, rgba(0, 0, 0, 0.75));
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  max-height: 0;
  transition: max-height 0.8s ease-in-out, opacity 0.1s ease-in-out;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-GlobalModal {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
  position: relative;
  top: 5%;
  background-color: var(--popup-color, #fff);
  border: var(--popup-border-width, 0px) solid var(--popup-border-color, rgba(0, 0, 0, 0.1));
  border-radius: var(--popup-border-radius, 0px);
  box-shadow: 0 0 var(--popup-shadow-size, 0px) 0 var(--popup-shadow-color, rgba(0, 0, 0, 0.1));
  transform: scale(0.8, 0.8);
  transition: transform 0.25s ease-in-out;
  padding: 20px;
  margin-bottom: 40px;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
    background-color: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
}
@media screen and (min-width: 1640px) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
    top: 8%;
  }
}
@media (max-width: 640px) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog {
    top: 0;
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__inner {
  padding: 0 20px;
}
@media (max-width: 768px) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__inner--spaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .R-TextBody.u-whiteSpace--prewrap.u-scrollableMaxHeight--480--desktop {
    max-height: none !important;
    overflow-y: visible !important;
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .controls__button,
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog a.controls__button {
  color: var(--popup-icon-color, #0e1311);
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .controls__button,
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog a.controls__button {
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .controls__button .button__icon,
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog a.controls__button .button__icon {
  font-size: 24px;
  line-height: 32px;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .controls__button .button__icon--sm,
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog a.controls__button .button__icon--sm {
  font-size: 18px;
  line-height: 32px;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .controls__button:hover,
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog a.controls__button:hover {
  color: var(--popup-icon-color, #0e1311);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .controls__button:hover,
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog a.controls__button:hover {
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__header {
  margin-bottom: 20px;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__closeButton {
  display: inline-block;
  background-color: #000;
  position: absolute;
  opacity: 1;
  color: #fff;
  text-shadow: none;
  width: 32px;
  height: 32px;
  line-height: 38px;
  font-size: 22px;
  border-radius: 100px;
  right: -15px;
  top: -15px;
  box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog .dialog__controls {
  margin-bottom: 20px;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width1140 {
  max-width: 1140px;
  width: 100%;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width1040 {
  max-width: 1040px;
  width: 100%;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width960 {
  max-width: 960px;
  width: 100%;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width768 {
  max-width: 768px;
  width: 100%;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-GlobalModal__dialog--width640 {
  max-width: 640px;
  width: 100%;
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-RatingStars .R-RatingStars__stars .stars__icon {
  --override-popup-star-color: var(
    --popup-star-color,
    var(--common-star-color)
  );
  --override-popup-star-disabled-color: var(
    --popup-disabled-star-color,
    var(--common-star-disabled-color)
  );
  color: var(--override-popup-star-color);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-RatingStars .R-RatingStars__stars .stars__icon--disabled {
  color: var(--override-popup-star-disabled-color);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .R-RatingStars .R-RatingStars__stars .stars__icon {
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-RatingStars .R-RatingStars__stars .stars__icon--0 {
  color: var(--override-popup-star-disabled-color);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-RatingStars .R-RatingStars__stars .stars__icon--25 {
  background-image: linear-gradient(to right, var(--override-popup-star-color) 38%, var(--override-popup-star-disabled-color) 38.1%);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-RatingStars .R-RatingStars__stars .stars__icon--50 {
  background-image: linear-gradient(to right, var(--override-popup-star-color) 50%, var(--override-popup-star-disabled-color) 50.1%);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-RatingStars .R-RatingStars__stars .stars__icon--75 {
  background-image: linear-gradient(to right, var(--override-popup-star-color) 60%, var(--override-popup-star-disabled-color) 60.1%);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-TextHeading .cssVar-popup-heading-text {
  color: var(--popup-heading-text-color, inherit);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-TextBody .cssVar-popup-body-text {
  color: var(--popup-body-text-color, inherit);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-IconButton .cssVar-popup-message-icon {
  color: var(--popup-heading-text-color, inherit);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-BadgeElement .R-BadgeElement__icon .cssVar-popup-badgeElement__icon:before {
  font-size: var(--popup-badge-icon-font-size, inherit);
  color: var(--popup-badge-icon-color, inherit);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .R-BadgeElement .R-BadgeElement__text .cssVar-popup-badgeElement__text {
  font-size: var(--popup-badge-text-font-size, inherit);
  color: var(--popup-badge-text-color, inherit);
}
.reviews .CarouselWidget-prefix .R-GlobalModal .u-hr {
  border-color: var(--popup-body-text-color, inherit);
  opacity: 0.15;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-GlobalModal .u-hr {
    border-color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .R-GlobalModal.hideScroll .controls__button--scrollLeft,
.reviews .CarouselWidget-prefix .R-GlobalModal.hideScroll .controls__button--scrollRight {
  display: none !important;
}
.reviews .CarouselWidget-prefix .R-GlobalModal.isActive {
  overflow: auto;
  pointer-events: auto;
  opacity: 1;
  max-height: 1600px;
  transition: max-height 0.01s ease-in-out, opacity 0.5s ease-in-out;
}
.reviews .CarouselWidget-prefix .R-GlobalModal.isActive .R-GlobalModal__dialog {
  transform: scale(1, 1);
}
.reviews .CarouselWidget-prefix .R-ImageContainer {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 400px;
  transition: 0.5s filter linear;
}
.reviews .CarouselWidget-prefix .R-ImageContainer.isLoading {
  filter: blur(4px);
}
.reviews .CarouselWidget-prefix .R-ImageContainer.isLoaded {
  filter: blur(0px);
}
@media (max-width: 768px) {
  .reviews .CarouselWidget-prefix .R-ImageContainer {
    min-height: auto;
    height: auto;
  }
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-ImageContainer {
    height: auto;
    min-height: auto;
  }
}
.reviews .CarouselWidget-prefix .R-ImageContainer .R-ImageContainer__src {
  max-width: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  outline: none;
}
@media (max-width: 768px) {
  .reviews .CarouselWidget-prefix .R-ImageContainer .R-ImageContainer__src {
    position: relative;
    height: auto;
  }
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .R-ImageContainer .R-ImageContainer__src {
    position: relative;
    height: auto;
  }
}
.reviews .CarouselWidget-prefix [r-tooltip] {
  position: relative;
}
.reviews .CarouselWidget-prefix [r-tooltip]::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0;
  border-style: solid;
  border-color: var(--tooltip-bg-color, #0e1311) transparent transparent transparent;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  cursor: default;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix [r-tooltip]::before {
    border-color: #0e1311 transparent transparent transparent;
  }
}
.reviews .CarouselWidget-prefix [r-tooltip]::after {
  content: attr(r-tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: var(--tooltip-bg-color, #0e1311);
  text-align: center;
  color: var(--tooltip-text-color, #fff);
  padding: 8px 10px;
  font-size: 12px;
  line-height: 16px;
  font-family: inherit;
  font-weight: 400;
  text-transform: none;
  min-width: 80px;
  border-radius: 5px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  z-index: 120;
  cursor: default;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix [r-tooltip]::after {
    background: #0e1311;
  }
}
.reviews .CarouselWidget-prefix [r-tooltip]:hover::after,
.reviews .CarouselWidget-prefix [r-tooltip]:hover::before {
  opacity: 1;
  transition-delay: 0s;
  pointer-events: auto;
}
.reviews .CarouselWidget-prefix [r-tooltip-position=left]::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg);
}
.reviews .CarouselWidget-prefix [r-tooltip-position=left]::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}
.reviews .CarouselWidget-prefix [r-tooltip-position=top]::before {
  left: 50%;
}
.reviews .CarouselWidget-prefix [r-tooltip-position=top]::after {
  left: 50%;
}
.reviews .CarouselWidget-prefix [r-tooltip-position=bottom]::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}
.reviews .CarouselWidget-prefix [r-tooltip-position=bottom]::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}
.reviews .CarouselWidget-prefix [r-tooltip-position=right]::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}
.reviews .CarouselWidget-prefix [r-tooltip-position=right]::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}
.reviews .CarouselWidget-prefix [r-tooltip-align=left]::after {
  text-align: left;
  left: 0;
  transform: translateX(0%) translateY(-100%);
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=right]::after {
  left: auto;
  right: -6px;
  transform: none;
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=left]::after {
  left: -6px;
  right: auto;
  transform: none;
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=topRight]::before {
  left: auto;
  right: 10%;
  transform: none;
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=topRight]::after {
  left: auto;
  right: -6px;
  transform: translateX(0%) translateY(-100%);
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=topLeft]::before {
  left: 10%;
  transform: none;
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=topLeft]::after {
  left: -6px;
  right: auto;
  transform: translateX(0%) translateY(-100%);
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=bottomRight]::after {
  left: auto;
  right: -6px;
  transform: translateX(0%) translateY(-100%);
}
.reviews .CarouselWidget-prefix [r-tooltip-position-align=bottomLeft]::after {
  left: -6px;
  right: auto;
  transform: translateX(0%) translateY(-100%);
}
.reviews .CarouselWidget-prefix [r-tooltip-size="280"]::after {
  min-width: 280px;
}
.reviews .CarouselWidget-prefix [r-tooltip-size="180"]::after {
  min-width: 180px;
}
.reviews .CarouselWidget-prefix [r-tooltip-size="160"]::after {
  min-width: 160px;
}
.reviews .CarouselWidget-prefix [r-tooltip-size="140"]::after {
  min-width: 140px;
}
.reviews .CarouselWidget-prefix [r-tooltip-size="100"]::after {
  min-width: 100px;
}
.reviews .CarouselWidget-prefix [r-tooltip-size="80"]::after {
  min-width: 80px;
}
.reviews .CarouselWidget-prefix [r-tooltip-size="60"]::after {
  min-width: 60px;
}
.reviews .CarouselWidget-prefix [r-tooltip-fontsize=xxxxs]::after {
  font-size: 11px;
  line-height: 16px;
}
.reviews .CarouselWidget-prefix [r-tooltip-fontsize=xxxs]::after {
  font-size: 12px;
  line-height: 18px;
}
.reviews .CarouselWidget-prefix .isTooltipHidden:after,
.reviews .CarouselWidget-prefix .isTooltipHidden:before {
  display: none;
}
@keyframes CircleSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes CircleSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes staticZoomInOut--sm {
  0% {
    transform: translatey(0px) scale(0.92);
  }
  50% {
    transform: translatey(0px) scale(1);
  }
  100% {
    transform: translatey(0px) scale(0.92);
  }
}
@keyframes staticZoomInOut--md {
  0% {
    transform: translatey(0px) scale(0.8);
  }
  50% {
    transform: translatey(0px) scale(1);
  }
  100% {
    transform: translatey(0px) scale(0.8);
  }
}
@keyframes R-shakeAnimation--md {
  0% {
    transform: rotateZ(-6deg);
  }
  25% {
    transform: rotateZ(6deg);
  }
  50% {
    transform: rotateZ(-6deg);
  }
  75% {
    transform: rotateZ(6deg);
  }
  100% {
    transform: rotateZ(0);
  }
}
.reviews .CarouselWidget-prefix .CarouselWidget {
  font-size: var(--base-font-size, 16px);
  display: inline-block;
  max-width: var(--base-maxwidth, 100%);
  width: 100%;
  margin-bottom: 20px;
  --logo-normal: url(https://assets.reviews.io/img/all-global-assets/logo/reviewsio-logo.svg);
  --logo-inverted: url(https://assets.reviews.io/img/all-global-assets/logo/reviewsio-logo--inverted.svg);
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsioLogo .R-ReviewsioLogo__image {
  content: var(--reviewsio-logo-style);
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__inner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header {
  flex-basis: var(--header-width, 160px);
  width: var(--header-width, 160px);
  flex-shrink: 0;
  order: var(--header-order, 1);
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header {
    flex-basis: 160px;
    width: 160px;
    order: 1;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .header__inner {
  background-color: var(--header-bg-start-color, transparent);
  background-image: linear-gradient(var(--header-gradient-direction, 135deg), var(--header-bg-start-color, transparent), var(--header-bg-end-color, transparent));
  padding: var(--header-padding, 0.5em);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: var(--header-border-width, 0) solid var(--header-border-color, rgba(0, 0, 0, 0.1));
  border-radius: var(--header-border-radius, 0px);
  box-shadow: 0 calc(var(--header-shadow-size, 0px) * 0.5) var(--header-shadow-size, 0px) -4px var(--header-shadow-color, rgba(0, 0, 0, 0.1));
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .header__inner {
    background-color: transparent;
    padding: 0.5em;
    box-shadow: 0;
    border-radius: 0;
  }
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .R-RatingStars .R-RatingStars__stars .stars__icon {
  --override-header-star-color: var(
    --header-star-color,
    var(--common-star-color)
  );
  --override-header-star-disabled-color: var(
    --header-disabled-star-color,
    var(--common-star-disabled-color)
  );
  color: var(--override-header-star-color);
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .R-RatingStars .R-RatingStars__stars .stars__icon--disabled {
  color: var(--override-header-star-disabled-color);
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .R-RatingStars .R-RatingStars__stars .stars__icon {
    color: #0e1311;
  }
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .R-RatingStars .R-RatingStars__stars .stars__icon--0 {
  color: var(--override-header-star-disabled-color);
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .R-RatingStars .R-RatingStars__stars .stars__icon--25 {
  background-image: linear-gradient(to right, var(--override-header-star-color) 38%, var(--override-header-star-disabled-color) 38.1%);
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .R-RatingStars .R-RatingStars__stars .stars__icon--50 {
  background-image: linear-gradient(to right, var(--override-header-star-color) 50%, var(--override-header-star-disabled-color) 50.1%);
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__header .R-RatingStars .R-RatingStars__stars .stars__icon--75 {
  background-image: linear-gradient(to right, var(--override-header-star-color) 60%, var(--override-header-star-disabled-color) 60.1%);
}
.reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__list {
  flex-basis: calc(100% - var(--header-width, 160px));
  width: calc(100% - var(--header-width, 160px));
  order: 1;
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--right {
  right: var(--scroll-button-horizontal-position, 0px);
  left: auto;
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--left {
  right: var(--scroll-button-horizontal-position, 0px);
  left: 235px;
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton {
  position: absolute;
  display: inline-flex;
  height: calc(var(--scroll-button-icon-size, 24px) * 2);
  width: calc(var(--scroll-button-icon-size, 24px) * 2);
  line-height: calc(var(--scroll-button-icon-size, 24px) * 2);
  top: var(--scroll-button-vertical-position, 0);
  bottom: -100px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: var(--scroll-button-icon-size, 24px);
  color: var(--scroll-button-icon-color, #0e1311);
  text-align: center;
  text-decoration: none;
  background-color: var(--scroll-button-bg-color, transparent);
  justify-content: center;
  align-items: center;
  border: var(--scroll-button-border-width, 0px) solid var(--scroll-button-border-color, rgba(0, 0, 0, 0.1));
  border-radius: var(--scroll-button-border-radius, 60px);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  z-index: 5;
  box-shadow: 0 0 var(--scroll-button-shadow-size, 0px) 0 var(--scroll-button-shadow-color, rgba(0, 0, 0, 0.2));
  cursor: pointer;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__list {
    flex-basis: calc(100% - 160px);
    width: calc(100% - 160px);
  }
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  min-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  flex-basis: calc(100% / var(--item-maximum-columns, 5));
  width: calc(100% / var(--item-maximum-columns, 5));
  flex-shrink: 0;
  scroll-snap-align: start;
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item--body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: var(--body-max-lines, 3);
  -webkit-box-orient: vertical;
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 20%;
    width: 20%;
    max-width: 20%;
  }
}
.reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item .item__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-basis: 100%;
  padding: var(--item-padding, 0.5em);
  background-color: var(--item-background-start-color, transparent);
  background-image: linear-gradient(var(--item-gradient-direction, 135deg), var(--item-background-start-color, transparent), var(--item-background-end-color, transparent));
  border: var(--item-border-width, 0) solid var(--item-border-color, rgba(0, 0, 0, 0.1));
  border-radius: var(--item-border-radius, 0px);
  box-shadow: 0 calc(var(--item-shadow-size, 0px) * 0.25) var(--item-shadow-size, 0px) -4px var(--item-shadow-color, rgba(0, 0, 0, 0.2));
}
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item .item__inner {
    padding: 0.5em;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 20%;
    width: 20%;
    max-width: 20%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 1440px), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 1440px), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 1440px), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 1440px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 1140px), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 1140px), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 1140px), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 1140px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 992px), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 992px), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 992px), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 992px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 50%;
    width: 50%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 768px), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 768px), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active) and (max-width: 768px), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) and (max-width: 768px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList .R-ReviewsList__item--fullwidth {
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container {
    position: relative;
    padding-left: calc(var(--scroll-button-icon-size, 24px) * 2 - (var(--scroll-button-horizontal-position, 0px) + 5px));
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 2 - (var(--scroll-button-horizontal-position, 0px) + 5px));
    margin-right: -10px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton {
    position: absolute;
    display: inline-flex;
    height: calc(var(--scroll-button-icon-size, 24px) * 2);
    width: calc(var(--scroll-button-icon-size, 24px) * 2);
    line-height: calc(var(--scroll-button-icon-size, 24px) * 2);
    top: var(--scroll-button-vertical-position, 0);
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    font-size: var(--scroll-button-icon-size, 24px);
    color: var(--scroll-button-icon-color, #0e1311);
    text-align: center;
    text-decoration: none;
    background-color: var(--scroll-button-bg-color, transparent);
    justify-content: center;
    align-items: center;
    border: var(--scroll-button-border-width, 0px) solid var(--scroll-button-border-color, rgba(0, 0, 0, 0.1));
    border-radius: var(--scroll-button-border-radius, 60px);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    z-index: 5;
    box-shadow: 0 0 var(--scroll-button-shadow-size, 0px) 0 var(--scroll-button-shadow-color, rgba(0, 0, 0, 0.2));
    cursor: pointer;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton {
    height: 48px;
    width: 48px;
    line-height: 48px;
    top: 0;
    font-size: 24px;
    color: #0e1311;
    background-color: transparent;
    border: 0;
    border-radius: 60px;
    box-shadow: none;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton .controlButton__icon {
    display: inline-block;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton:hover {
    transform: scale(1.15, 1.15);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--left {
    left: var(--scroll-button-horizontal-position, 0px);
    right: auto;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--left {
    left: 0;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--left .controlButton__icon {
    margin-left: -0.1em;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--right {
    right: var(--scroll-button-horizontal-position, 0px);
    left: auto;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--right {
    right: 0;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-ReviewsList-container .R-ReviewsList__controlButton--right .controlButton__icon {
    margin-right: -0.1em;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-starDisplay {
    display: var(--x-small-star-display, inline-flex);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-starDisplay {
    display: inline-flex;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-authorName {
    font-size: var(--author-font-size, inherit);
    font-weight: var(--author-font-weight, inherit);
    text-transform: var(--author-text-transform, inherit);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-authorName {
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-header__heading {
    color: var(--header-heading-text-color, inherit);
    font-size: var(--header-heading-font-size, inherit);
    font-weight: var(--header-heading-font-weight, inherit);
    line-height: var(--header-heading-line-height, inherit);
    text-transform: var(--header-heading-text-transform, inherit);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-header__heading {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    text-transform: none;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-header__subheading {
    color: var(--header-subheading-text-color, inherit);
    font-size: var(--header-subheading-font-size, inherit);
    font-weight: var(--header-subheading-font-weight, inherit);
    line-height: var(--header-subheading-line-height, inherit);
    text-transform: var(--header-subheading-text-transform, inherit);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget .cssVar-header__subheading .cssVar-subheading__number {
    font-weight: var(--header-subheading-font-weight, inherit);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget .R-PhotoVideoThumbnail {
    max-height: 60px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--sideHeader--withcards {
    --reviewsio-logo-style: var(--logo-inverted);
    --header-width: 280px;
    --header-bg-start-color: #0e1311;
    --header-bg-end-color: #0e1311;
    --header-padding: 1.5em;
    --header-shadow-size: 10px;
    --header-shadow-color: rgba(0, 0, 0, 0.05);
    --header-star-color: #ffffff;
    --header-heading-text-color: #ffffff;
    --header-subheading-text-color: #ffffff;
    --header-subheading-font-weight: 300;
    --item-maximum-columns: 5;
    --item-background-start-color: #ffffff;
    --item-background-end-color: #ffffff;
    --item-padding: 1.5em;
    --item-shadow-size: 10px;
    --item-shadow-color: rgba(0, 0, 0, 0.05);
    --scroll-button-horizontal-position: 3px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__inner {
    flex-direction: column;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__header {
    flex-basis: 100%;
    width: 100%;
    flex-shrink: 1;
    padding: 0;
    margin-bottom: 20px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__header {
    flex: 1 1 auto !important;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__header .header__inner {
    padding: 0;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__header .R-RatingStars {
    position: relative;
    bottom: calc(var(--medium-star-size, 22px) / 9 * -1);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__list {
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .CarouselWidget__list {
    flex: 1 1 auto !important;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .R-ReviewsList-container {
    margin-left: -10px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader .R-ReviewsList {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--topHeader--withcards {
    --item-maximum-columns: 5;
    --item-background-start-color: #ffffff;
    --item-background-end-color: #ffffff;
    --item-padding: 1.5em;
    --item-shadow-size: 10px;
    --item-shadow-color: rgba(0, 0, 0, 0.05);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget:not(.CarouselWidget--scrollButtons-top) .CarouselWidget__footer {
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 2 - (var(--scroll-button-horizontal-position, 0px) + 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-coloured {
    --scroll-button-bg-color: #ffffff;
    --scroll-button-shadow-size: 20px;
    --scroll-button-shadow-color: rgba(0, 0, 0, 0.05);
    --scroll-button-horizontal-position: 12px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--sideHeader--withcards):not(.CarouselWidget--scrollButtons-top) .R-ReviewsList-container {
    padding-left: calc(var(--scroll-button-icon-size, 24px) * 3.5 - (var(--scroll-button-horizontal-position, 0px) + 5px));
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 3.5 - (var(--scroll-button-horizontal-position, 0px) + 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--sideHeader--withcards):not(.CarouselWidget--scrollButtons-top) .CarouselWidget__footer {
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 3.5 - (var(--scroll-button-horizontal-position, 0px) + 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top {
    --scroll-button-vertical-position: -15px;
    --scroll-button-horizontal-position: 0px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top .R-ReviewsList-container {
    padding-left: 10px;
    padding-right: 0;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top .R-ReviewsList-container .R-ReviewsList__controlButton {
    font-size: calc(var(--scroll-button-icon-size, 24px) * 0.8);
    height: calc(var(--scroll-button-icon-size, 24px) * 1.2);
    width: calc(var(--scroll-button-icon-size, 24px) * 1.2);
    line-height: calc(var(--scroll-button-icon-size, 24px) * 1.2);
    bottom: auto;
    top: var(--scroll-button-vertical-position, -15px);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top .R-ReviewsList-container .R-ReviewsList__controlButton {
    top: -15px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top .R-ReviewsList-container .R-ReviewsList__controlButton--left {
    left: auto;
    right: calc(var(--scroll-button-icon-size, 24px) * 1.4 + 10px + var(--scroll-button-horizontal-position, 0));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: active), only screen and (-ms-high-contrast: active) and (max-width: 1919px) and (-ms-high-contrast: none), only screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: 1919px) and (-ms-high-contrast: none) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top .R-ReviewsList-container .R-ReviewsList__controlButton--left {
    right: 44px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top .R-ReviewsList-container .R-ReviewsList__controlButton--right {
    right: 10px;
    left: auto;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--scrollButtons-top.CarouselWidget--topHeader .R-ReviewsList-container {
    padding-left: 0;
    padding-right: 0;
    margin-left: -10px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--bulky {
    --base-font-size: 18px;
    --base-maxwidth: 768px;
    --medium-star-size: 28px;
    --x-small-star-size: 16px;
    --header-heading-font-size: 1.3em;
    --item-maximum-columns: 1;
    --badge-icon-font-size: 20px;
    --badge-text-font-size: 1.2em;
    --author-font-size: 1.2em;
    flex-basis: calc(100% / (var(--item-maximum-columns, 1)));
    width: calc(100% / (var(--item-maximum-columns, 1)));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--bulky--withcards {
    --item-background-start-color: #ffffff;
    --item-background-end-color: #ffffff;
    --item-padding: 1.5em;
    --item-shadow-size: 10px;
    --item-shadow-color: rgba(0, 0, 0, 0.05);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--photocarousel {
    --item-maximum-columns: 8;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--videocarousel {
    --item-maximum-columns: 5;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-XXXXLG .R-ReviewsList .R-ReviewsList__item {
    flex-basis: calc(100% / (var(--item-maximum-columns, 5) - 1));
    width: calc(100% / (var(--item-maximum-columns, 5) - 1));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-XXXLG .R-ReviewsList .R-ReviewsList__item {
    flex-basis: calc(100% / (var(--item-maximum-columns, 5) - 1));
    width: calc(100% / (var(--item-maximum-columns, 5) - 1));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-XXLG .R-ReviewsList .R-ReviewsList__item {
    flex-basis: calc(100% / (var(--item-maximum-columns, 5) - 2));
    width: calc(100% / (var(--item-maximum-columns, 5) - 2));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-XLG .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 50%;
    width: 50%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-XLG.CarouselWidget--bulky .R-ReviewsList .R-ReviewsList__item,
.reviews .CarouselWidget-prefix .R-XLG.CarouselWidget--bulky--withcards .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-LG .R-ReviewsList .R-ReviewsList__item {
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD .CarouselWidget__inner {
    flex-direction: column;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD .CarouselWidget__header {
    flex-basis: 100%;
    width: 100%;
    flex-shrink: 1;
    margin-bottom: 20px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: calc(var(--scroll-button-icon-size, 24px) * 2 - (var(--scroll-button-horizontal-position, 0px) - 5px));
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 2 - (var(--scroll-button-horizontal-position, 0px) - 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD .CarouselWidget__list {
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD .R-ReviewsList-container {
    margin-right: 0;
    margin-left: 0;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD.CarouselWidget--scrollButtons-top .CarouselWidget__header {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD.CarouselWidget--scrollButtons-top .R-ReviewsList-container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top) .CarouselWidget__header {
    padding-left: calc(var(--scroll-button-icon-size, 24px) * 2.2 - (var(--scroll-button-horizontal-position, 0px) - 5px));
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 2.2 - (var(--scroll-button-horizontal-position, 0px) - 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top) .R-ReviewsList-container {
    padding-left: calc(var(--scroll-button-icon-size, 24px) * 2.2 - (var(--scroll-button-horizontal-position, 0px) + 5px));
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 2.2 - (var(--scroll-button-horizontal-position, 0px) + 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top):not(.CarouselWidget--sideHeader--withcards) .CarouselWidget__header {
    padding-left: calc(var(--scroll-button-icon-size, 24px) * 3.5 - (var(--scroll-button-horizontal-position, 0px) - 5px));
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 3.5 - (var(--scroll-button-horizontal-position, 0px) - 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .R-MD.CarouselWidget--scrollButtons-coloured:not(.CarouselWidget--scrollButtons-top):not(.CarouselWidget--sideHeader--withcards) .R-ReviewsList-container {
    padding-left: calc(var(--scroll-button-icon-size, 24px) * 3.5 - (var(--scroll-button-horizontal-position, 0px) + 5px));
    padding-right: calc(var(--scroll-button-icon-size, 24px) * 3.5 - (var(--scroll-button-horizontal-position, 0px) + 5px));
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList__controlButton {
    top: -10px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList {
    padding-top: 40px;
    padding-bottom: 40px;
    perspective: 1440px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item .SocialProofBanner {
    width: 100%;
    box-shadow: 0 4px 15px -4px rgba(0, 0, 0, 0.1);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item.isLeftThird {
    z-index: 14;
    transform: rotateY(75deg) scale(0.7, 0.7);
    margin-right: -160px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item.isLeftSecond {
    z-index: 16;
    transform: rotateY(60deg) scale(0.8, 0.8);
    margin-right: -120px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item.isLeftFirst {
    z-index: 18;
    transform: rotateY(45deg) scale(0.9, 0.9);
    margin-right: -80px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item.isCenter {
    z-index: 20;
    transform: rotateY(0deg);
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item.isRightFirst {
    z-index: 18;
    transform: rotateY(-45deg) scale(0.9, 0.9);
    margin-left: -80px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item.isRightSecond {
    z-index: 16;
    transform: rotateY(-60deg) scale(0.8, 0.8);
    margin-left: -120px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1919px), screen and (-ms-high-contrast: none) and (max-width: 1919px) {
  .reviews .CarouselWidget-prefix .CarouselWidget--socialProofBanners .CarouselWidget__list .R-ReviewsList-container .R-ReviewsList .R-ReviewsList__item.isRightThird {
    z-index: 14;
    transform: rotateY(-75deg) scale(0.7, 0.7);
    margin-left: -160px;
  }
}

/* CSS for tablet and mobile */
@media screen and (max-width: 768px) {
  .R-ReviewsList-container {
    display: none;
  }
  .reviews .CarouselWidget-prefix .CarouselWidget .CarouselWidget__inner {
    flex-direction: column;
    align-items: center;
  }
  .cssVar-header__heading {
    font-size: 30px;
  }
  .reviews .CarouselWidget-prefix .R-RatingStars .R-RatingStars__stars .stars__icon {
    font-size: 34px;
  }
  .reviews .CarouselWidget-prefix .R-TextBody--xxs {
    font-size: 16px;
  }
  .reviews .CarouselWidget-prefix .R-ReviewsioLogo .R-ReviewsioLogo__image {
    margin-top: 10px;
  }
}
.features__item {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 55px;
}
@media (max-width: 767.98px) {
  .features__item {
    display: block;
    text-align: center;
  }
}
.features__item:last-child {
  margin-bottom: 0;
}
.features__icon, .features__content {
  padding: 0 10px;
}
.features__icon {
  flex: 0 0 120px;
  max-width: 120px;
}
@media (max-width: 767.98px) {
  .features__icon {
    margin: 0 auto 15px;
  }
}
.features__icon img {
  position: relative;
  top: -35px;
}
@media (max-width: 767.98px) {
  .features__icon img {
    top: 0;
  }
}
.features__content {
  flex: 1 0;
}
.features__heading {
  margin-bottom: 10px;
}

@media (max-width: 767.98px) {
  .steps--stack-mobile .steps__items {
    padding-top: 20px;
  }
}
@media (max-width: 767.98px) {
  .steps--stack-mobile .steps__items:before {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .steps--stack-mobile .steps__items li {
    padding: 0;
    margin-bottom: 40px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .steps--stack-mobile .steps__items li:before {
    position: relative;
    top: 0;
    margin: 0 auto 15px;
  }
}
@media (max-width: 767.98px) {
  .steps--stack-mobile .steps__items li:after {
    display: none;
  }
}
.steps__items:before {
  content: "Steps";
  display: block;
  font-weight: 500;
  margin-bottom: 20px;
}
.steps__items ul {
  counter-reset: li;
}
.steps__items li {
  position: relative;
  counter-increment: li;
  min-height: 65px;
  padding-left: 65px;
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .steps__items li {
    padding-left: 50px;
  }
}
.steps__items li:last-child {
  margin-bottom: 0;
}
.steps__items li:last-child:after {
  display: none;
}
.steps__items li:before {
  content: counter(li);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 2rem;
  font-weight: bold;
  background: #13243a;
  color: #fff;
}
.steps__items li:after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: 18px;
  width: 1px;
  height: calc(100% - 15px);
  background: #707070;
}

@media (max-width: 767.98px) {
  .logo-content {
    text-align: center;
  }
}
.logo-content__copy {
  padding-top: 70px;
}
@media (max-width: 767.98px) {
  .logo-content__copy {
    padding-top: 45px;
  }
}
@media (max-width: 767.98px) {
  .logo-content__logo {
    text-align: center;
    margin-bottom: 20px;
  }
}
.logo-content__buttons {
  display: flex;
  gap: 1rem;
}
@media (max-width: 767.98px) {
  .logo-content__buttons {
    justify-content: center;
  }
}
@media only screen and (max-width: 407px) {
  .logo-content__buttons {
    flex-direction: column;
  }
}

.how-it-works__col {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .how-it-works__col:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .how-it-works__col {
    margin-bottom: 80px;
  }
  .how-it-works__col:last-child {
    margin-bottom: 0;
  }
}
.how-it-works__panels {
  padding-top: 20px;
}
.how-it-works__panel {
  height: 100%;
  max-width: 510px;
  padding: 35px;
  margin: 0 auto;
  border-radius: 10px;
  background: #fff;
}
@media (max-width: 575.98px) {
  .how-it-works__panel {
    text-align: center;
  }
}
.how-it-works__panel table {
  width: 100%;
  margin-bottom: 15px;
}
.how-it-works__panel table tr:last-child td {
  border-bottom: 0;
}
.how-it-works__panel table td {
  padding: 10px 5px;
  line-height: 1.2;
  border-bottom: 1px solid #707070;
}
@media (max-width: 575.98px) {
  .how-it-works__panel table td {
    display: block;
    width: 100%;
    border-bottom: none;
  }
  .how-it-works__panel table td:first-child {
    padding-top: 20px;
  }
  .how-it-works__panel table td:last-child {
    padding-top: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #707070;
  }
}
.how-it-works__panel table td:first-child {
  padding-left: 0;
  font-weight: bold;
}
.how-it-works__panel table td:last-child {
  max-width: 65px;
  padding-right: 0;
  font-weight: bold;
  color: #f7aa00;
}
@media (max-width: 575.98px) {
  .how-it-works__panel table td:last-child {
    max-width: 100%;
  }
}

.pagename-top-up-prize-draw .how-it-works__col:last-child table {
  border-top: 28px solid white;
}

.document-select {
  margin-bottom: 25px;
}
.document-select .dropdown {
  border-radius: 10px;
  border: 1px solid #454e50;
}
.document-select .dropdown__trigger {
  padding-left: 20px;
  border: none;
}
.document-select .dropdown__copy {
  padding: 20px 40px;
}

.suggested-posts__col {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .suggested-posts__col:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .suggested-posts__col:last-child {
    margin-bottom: 0;
  }
}

.page-banner {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}
.page-banner.block-space--no-bottom {
  padding-bottom: 0px;
}
.page-banner.block-space--no-top {
  padding-top: 0px;
}
.page-banner.space-between > .page-banner-message {
  justify-content: space-between;
}
.page-banner > .page-banner-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 16px 0;
  align-items: center;
}
.page-banner > .page-banner-message .right-logo {
  display: flex;
  align-items: center;
}
.page-banner > .page-banner-message .right-logo__image-wrapper {
  text-align: center;
}
.page-banner > .page-banner-message .left-logo, .page-banner > .page-banner-message .right-logo {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #13243A;
}
@media (max-width: 767.98px) {
  .page-banner > .page-banner-message {
    padding: 0px;
  }
}
@media (max-width: 575.98px) {
  .page-banner > .page-banner-message--mobile-row .left-logo, .page-banner > .page-banner-message--mobile-row .right-logo {
    font-size: 12px;
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  .page-banner > .page-banner-message--mobile-row .right-logo {
    flex-direction: column;
    padding: 20px 0;
  }
  .page-banner > .page-banner-message--mobile-row .right-logo .page-banner-logo {
    margin-top: -13px;
    padding: 0;
    max-height: 59px;
  }
  .page-banner > .page-banner-message--mobile-row .left-logo {
    min-width: 54%;
  }
  .page-banner > .page-banner-message--mobile-row .left-logo .page-banner-logo {
    padding-right: 0;
  }
}
.page-banner > .page-banner-message p {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}
.page-banner > .page-banner-message img {
  max-height: 120px;
  padding: 0 20px;
}
.page-banner .page-banner-content > .page-banner-title h2 {
  margin: 0%;
}
.page-banner > .page-banner-message .page-banner__link-wrapper {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .page-banner .imgListWrapper {
    margin: 0;
  }
  .page-banner > .page-banner-message:not(.page-banner-message--mobile-row) {
    flex-direction: column;
  }
  .page-banner > .page-banner-message:not(.page-banner-message--mobile-row) .left-logo, .page-banner > .page-banner-message:not(.page-banner-message--mobile-row) .right-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page-banner > .page-banner-message .page-banner-content {
    margin-bottom: 2em;
  }
  .page-banner .page-banner-message img {
    max-height: 100px;
  }
  .page-banner .page-banner-message img.hidden-xs {
    display: none;
  }
  .page-banner .img-alignment {
    width: 50%;
  }
}

.page-id-1038 .countdown-timer {
  margin-top: 0px;
  background-color: #f7aa00;
}

.page-id-1038 .countdown-timer.expired {
  display: none;
}

.countdown-timer {
  margin-top: 30px;
  padding: 10px 0px;
  background-color: #13243a;
}
.countdown-timer.expired {
  opacity: 0;
  margin-top: 0px;
  padding: 0px;
}
@media only screen and (min-width: 992px) {
  .countdown-timer {
    padding: 20px 0px 5px 0px;
    margin-top: 60px;
  }
}
.countdown-timer .countdown-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.countdown-timer .countdown-container > div {
  margin: 0px 5px;
}
@media only screen and (min-width: 992px) {
  .countdown-timer .countdown-container {
    flex-direction: row;
  }
}
.countdown-timer .countdown-row {
  display: flex;
  justify-content: center;
}
.countdown-timer .text {
  font-size: 1.7rem;
  color: white;
  font-weight: 400;
  line-height: 2;
  margin: 0px;
}
@media only screen and (min-width: 992px) {
  .countdown-timer .text {
    font-size: 2.5rem;
    margin-bottom: 25px;
  }
}
.countdown-timer .slot > div:first-of-type span {
  width: 3rem;
  height: 3rem;
  background-color: white;
  display: block;
  margin: 2px;
  border-radius: 5px;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (min-width: 992px) {
  .countdown-timer .slot > div:first-of-type span {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 3rem;
    line-height: 1.15;
    font-weight: 500;
  }
}
.countdown-timer .smalltext {
  font-size: 12px;
  color: white;
}
.countdown-timer .space {
  line-height: 1.4;
  font-size: 2rem;
  color: white;
}
@media only screen and (min-width: 992px) {
  .countdown-timer .space {
    font-size: 30px;
  }
}
.countdown-timer .sm-days:before {
  content: "D";
}
@media only screen and (min-width: 992px) {
  .countdown-timer .sm-days:before {
    content: "Days";
  }
}
.countdown-timer .sm-hours:before {
  content: "H";
}
@media only screen and (min-width: 992px) {
  .countdown-timer .sm-hours:before {
    content: "Hours";
  }
}
.countdown-timer .sm-minutes:before {
  content: "M";
}
@media only screen and (min-width: 992px) {
  .countdown-timer .sm-minutes:before {
    content: "Minutes";
  }
}
.countdown-timer .sm-seconds:before {
  content: "S";
}
@media only screen and (min-width: 992px) {
  .countdown-timer .sm-seconds:before {
    content: "Seconds";
  }
}

.sticky-content-block-checkbox {
  display: none;
}

input.sticky-content-block-checkbox[type=checkbox]:checked ~ .sticky-content-block {
  display: none;
}

.sticky-content-block {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 200;
  top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sticky-content-block h4 {
  margin-bottom: 0px;
}
.sticky-content-block label.sticky-content-block-checkbox-label {
  display: none;
  padding-left: 40px;
  align-self: center;
  font-size: 2.4rem;
  line-height: 0px;
  cursor: pointer;
}
.sticky-content-block.dismissable label.sticky-content-block-checkbox-label {
  display: block;
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.containers-background .invest-sustainably__row {
  align-items: stretch;
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: -8rem;
  padding: 2rem;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
@media (min-width: 992px) {
  .containers-background .invest-sustainably__row {
    flex-direction: row;
  }
}
.containers-background .invest-sustainably__container {
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
}
@media (min-width: 576px) {
  .containers-background .invest-sustainably__container {
    padding: 3.5rem 3.2rem;
  }
}
@media (min-width: 992px) {
  .containers-background .invest-sustainably__container {
    width: 53.6rem;
  }
}
.containers-background .invest-sustainably__heading {
  color: #454e50 !important;
  margin: 0 auto;
  width: 85%;
}
.containers-background .invest-sustainably__title {
  color: #f7aa00;
  font-size: 3.2rem;
  line-height: 1.25;
}
.containers-background .invest-sustainably__tick-bullets ul {
  padding-left: 0;
}
.containers-background .invest-sustainably__tick-bullets ul li {
  list-style: none;
  margin-bottom: 1rem;
  padding-left: 5rem;
  position: relative;
  text-align: left;
}
.containers-background .invest-sustainably__tick-bullets ul li:before {
  background: url("https://shepherdsfriendly.blob.core.windows.net/wp-media/2021/10/tick-icon-black.png") 50% no-repeat;
  content: "";
  height: 3rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 3rem;
}
.containers-background .invest-sustainably__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 576px) {
  .containers-background .invest-sustainably__btn {
    justify-content: space-evenly;
    flex-direction: row;
  }
}
.containers-background .invest-sustainably__btn a {
  margin-bottom: 2rem;
}
.containers-background .image_container {
  align-items: center;
  display: flex;
  height: 33.2rem;
  justify-content: center;
}
@media (min-width: 576px) {
  .containers-background .image_container {
    height: 63rem;
  }
}
@media (min-width: 768px) {
  .containers-background .image_container {
    margin-top: -24rem;
  }
}
@media (min-width: 2560px) {
  .containers-background .image_container {
    height: 95rem;
    margin-top: -40rem;
  }
}
.containers-background .image_container__text {
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(69, 78, 80, 0.5);
  padding: 0 5rem;
  margin-top: -20rem;
  width: 80%;
}
@media (min-width: 576px) {
  .containers-background .image_container__text {
    padding: 0 2rem;
    margin-top: -30rem;
    width: 60%;
  }
}
@media (min-width: 768px) {
  .containers-background .image_container__text {
    margin-top: -5rem;
  }
}
@media (min-width: 992px) {
  .containers-background .image_container__text {
    margin-top: 0;
    width: 30%;
  }
}
@media (max-width: 992px) {
  .containers-background .bg-contain {
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.product-select {
  padding: 0 20px !important;
  /* Can be simplified with SASS */
  /* The same classes with the main css file just updated to fit for this project */
  /* The same classes with the main css file just updated to fit for this project */
  /* Class for change background on click */
}
.product-select .hidden {
  display: none;
}
.product-select .choose-investment {
  margin-top: 70px;
  width: 100%;
}
.product-select .choose-investment__title {
  margin-bottom: 70px;
  text-align: center;
}
.product-select .choose-product {
  align-items: center;
  border-radius: 5px;
  border: 1px solid #f7aa00;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 70px;
  text-align: center;
  width: 463px;
  cursor: pointer;
}
.product-select .choose-product__title {
  color: #13243a;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}
.product-select .found-choice {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
}
.product-select input[type=radio] {
  left: 0px;
  margin: 10px 0 !important;
  opacity: 1;
  position: relative;
}
.product-select .checkbox-round {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 50% !important;
  border: 1px solid #f7aa00;
  height: 2.5em !important;
  margin-bottom: 20px;
  outline: none;
  vertical-align: middle;
  width: 2.5em;
}
.product-select .checkbox-round:checked {
  content: none;
  padding: 0;
}
.product-select .checkbox-round:checked::before {
  align-items: center;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.217' height='21.861' viewBox='0 0 22.217 21.861'%3E%3Cpath id='Path_2074' data-name='Path 2074' d='M-2463.385-6119.135l7.054,8.962,12.378-19.481' transform='translate(2464.789 6131.034)' fill='none' stroke='%2313243a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
  display: flex;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
}
.product-select #product_details,
.product-select #product_details--active {
  margin: 1rem 0;
}
.product-select .important-information-section {
  margin-top: -30px;
}
.product-select .documents {
  border-radius: 5px;
  border: 1px solid #454e50;
  margin-bottom: 25px;
  opacity: 1;
  padding: 20px 40px;
}
.product-select .documents__header {
  color: #454e50;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}
.product-select .documents__content {
  margin-bottom: 0;
}
.product-select .documents__key-info {
  margin-bottom: 15px;
}
.product-select .list-unstyled {
  padding-top: 30px;
}
.product-select .error-message {
  display: none;
  position: relative;
  padding: 1rem 1rem !important;
  border-radius: 5px;
  background: #ba2b2b;
  border: 2px solid #ba2b2b;
  text-align: left;
  font-family: "Brandon Text", Arial;
  color: #ffffff;
  font-size: 1.6rem;
}
.product-select .error-message p {
  margin-bottom: 0 !important;
  color: #ffffff;
}
.product-select .disabled,
.product-select .disabled:hover {
  background: #ECEDED !important;
  color: #454e50 !important;
}
.product-select .highlight {
  background: rgba(247, 170, 0, 0.2);
}
@media screen and (max-width: 980px) {
  .product-select .choose-product {
    align-items: stretch;
    justify-content: space-between;
  }
  .product-select input[type=radio] {
    margin: 10px auto !important;
  }
}
@media screen and (max-width: 768px) {
  .product-select .choose-product {
    padding: 0 10px;
  }
  .product-select .found-choice {
    align-items: stretch;
  }
  .product-select .important-information-section {
    margin-top: -10px;
  }
}
@media (max-width: 575.98px) {
  .product-select {
    /* The same classes with the main css file just updated to fit for this project */
  }
  .product-select .container {
    padding: 0 20px;
  }
  .product-select .choose-investment {
    padding: 0 0px !important;
    width: 100% !important;
    margin-top: 50px;
  }
  .product-select .choose-investment__title {
    margin-bottom: 50px;
  }
  .product-select .found-choice {
    align-items: stretch;
  }
  .product-select .choose-product {
    align-items: stretch;
    justify-content: space-between;
    padding: 0 7px !important;
    width: 50%;
  }
  .product-select .choose-product__title {
    font-size: 20px !important;
  }
  .product-select input[type=radio] {
    margin: 10px auto !important;
  }
  .product-select .choose-product p {
    font-size: 14px;
  }
  .product-select .documents {
    padding: 10px;
  }
  .product-select .download > a {
    justify-content: center;
  }
  .product-select .download__icon {
    margin-right: 0;
    width: 100%;
  }
  .product-select .list-unstyled {
    display: flex;
    text-align: center;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.tab {
  display: flex;
  justify-content: space-between;
  margin: -1px auto;
  overflow: hidden;
  width: 1088px;
  z-index: 1;
}
.tab--bare {
  position: relative;
  padding: 0;
}
@media only screen and (max-width: 1100px) {
  .tab--bare {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .tab--bare::-webkit-scrollbar {
    display: none;
  }
}

.tab-list-item {
  background-color: inherit;
  border: none;
  cursor: pointer;
  float: left;
  font-size: 2rem;
  list-style-type: none;
  margin-bottom: 0;
  outline: none;
  padding: 1.3rem 5.8rem;
  transition: 0.3s;
}
.tab-list-item.active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: none;
}
.tab-list-item--bare {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 1.3rem 2rem;
  font-weight: 500;
}
@media only screen and (max-width: 1100px) {
  .tab-list-item--bare {
    font-size: 1.5rem;
    min-width: 15rem;
    text-align: center;
  }
}
.tab-list-item--bare:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background: #87d0f1;
  opacity: 1;
}
.tab-list-item--bare:hover:after {
  height: 0.5rem;
}
.tab-list-item--bare.active {
  background-color: transparent;
  border: none;
}
.tab-list-item--bare.active:after {
  height: 0.5rem;
}

.tab-link {
  color: #13243a;
  text-decoration: none;
  display: inline-block;
  padding: 1.3rem 5.8rem;
  margin: -1.3rem -5.8rem;
}
@media only screen and (max-width: 1100px) {
  .tab-link {
    padding: 0;
    margin: 0;
  }
}
.tab-link--bare {
  background-color: transparent;
  border: none;
  font-weight: 700;
}
.tab-link--bare:after {
  opacity: 1;
}

.tabcontent {
  display: none;
  margin: -2px auto;
  padding: 71px 57px;
  width: 1088px;
}
@media only screen and (max-width: 1100px) {
  .tabcontent {
    width: 100%;
  }
}
.tabcontent--bare {
  padding: 0 !important;
  background: none;
  border: none;
}

@media (max-width: 1100px) {
  .tabbed-element:not(.tabbed-element--show-on-mobile) {
    display: none;
  }
}
@media (min-width: 1200px) {
  #tab-1:checked ~ .c-tabbed-element__tabs #tab-1-label {
    background-color: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #f7aa00;
    border-bottom: 1px solid #fff;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  #tab-1:checked ~ .c-tabbed-element__tabs #tab-1-panel {
    display: block;
  }
}
@media (min-width: 1200px) {
  #tab-2:checked ~ .c-tabbed-element__tabs #tab-2-label {
    background-color: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #f7aa00;
    border-bottom: 1px solid #fff;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  #tab-2:checked ~ .c-tabbed-element__tabs #tab-2-panel {
    display: block;
  }
}
@media (min-width: 1200px) {
  #tab-3:checked ~ .c-tabbed-element__tabs #tab-3-label {
    background-color: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #f7aa00;
    border-bottom: 1px solid #fff;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  #tab-3:checked ~ .c-tabbed-element__tabs #tab-3-panel {
    display: block;
  }
}
@media (min-width: 1200px) {
  #tab-4:checked ~ .c-tabbed-element__tabs #tab-4-label {
    background-color: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #f7aa00;
    border-bottom: 1px solid #fff;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  #tab-4:checked ~ .c-tabbed-element__tabs #tab-4-panel {
    display: block;
  }
}
@media (min-width: 1200px) {
  #tab-5:checked ~ .c-tabbed-element__tabs #tab-5-label {
    background-color: #fff;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #f7aa00;
    border-bottom: 1px solid #fff;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  #tab-5:checked ~ .c-tabbed-element__tabs #tab-5-panel {
    display: block;
  }
}
@media (max-width: 1200px) {
  .c-tabbed-element__information--reverse {
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 1200px) {
  .c-tabbed-element__image--is-hidden {
    display: none;
  }
}
@media (max-width: 1200px) {
  .c-tabbed-element__toggle {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .c-tabbed-element__toggle::after {
    content: "";
    width: 24px;
    height: 24px;
    margin-left: auto;
    background: url(../images/arrow-solid.svg) 50% no-repeat;
    background-size: contain;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
}
@media (max-width: 1200px) {
  .c-tabbed-element__toggle.active::after {
    background: url(../images/close-button-solid.svg) 50% no-repeat;
    background-size: contain;
    -webkit-transform: rotate(0) translateX(0);
    transform: rotate(0) translateX(0);
  }
}
@media (max-width: 1200px) {
  .c-tabbed-element__flex-tabs #tab-1-label {
    order: 1;
  }
  .c-tabbed-element__flex-tabs #tab-1-panel {
    order: 2;
  }
  .c-tabbed-element__flex-tabs #tab-2-label {
    order: 3;
  }
  .c-tabbed-element__flex-tabs #tab-2-panel {
    order: 4;
  }
  .c-tabbed-element__flex-tabs #tab-3-label {
    order: 5;
  }
  .c-tabbed-element__flex-tabs #tab-3-panel {
    order: 6;
  }
  .c-tabbed-element__flex-tabs #tab-4-label {
    order: 7;
  }
  .c-tabbed-element__flex-tabs #tab-4-panel {
    order: 8;
  }
  .c-tabbed-element__flex-tabs #tab-5-label {
    order: 9;
  }
  .c-tabbed-element__flex-tabs #tab-5-panel {
    order: 10;
  }
  .c-tabbed-element__flex-tabs .c-tabbed-element__panel.active {
    display: block;
  }
}

.state {
  position: absolute;
  left: -10000px;
}

.important-information__copy {
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(69, 78, 80, 0.5019607843);
  text-align: center;
  margin: 4rem auto 0;
  max-width: 63.6rem;
  min-height: 8.5rem;
  padding: 1rem;
  width: 100%;
}
.important-information__copy p {
  margin: 0;
  padding: 0;
}
@media (min-width: 576px) {
  .important-information__copy {
    padding: 1rem 3.8rem 0.8rem;
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .important-information__copy {
    width: 70%;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.c-trust-markers {
  /* This applies from 768px */
  /* This applies from 992px */
}
.c-trust-markers__logos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2.4rem;
}
.c-trust-markers__logo-image {
  width: 116px;
}
@media (min-width: 768px) {
  .c-trust-markers__logos {
    width: 100%;
  }
  .c-trust-markers__logo-image {
    width: 131px;
  }
}
@media (min-width: 992px) {
  .c-trust-markers__list {
    list-style-type: none;
    padding-left: 1.6rem;
    margin-bottom: 0;
  }
  .c-trust-markers__list-item {
    font-family: "Brandon Text", serif;
    font-size: 1.4rem;
    line-height: 1.5;
    position: relative;
    font-weight: 500;
    padding-left: 1.6rem;
    margin-bottom: 2rem;
  }
  .c-trust-markers__list-item:last-child {
    margin-bottom: 0;
  }
  .c-trust-markers__list-item::before {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: -20px;
    width: 20px;
    height: 20px;
    background: url("https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/02/tick-bullet-yellow.svg") 50% no-repeat;
    background-size: contain;
  }
  .c-trust-markers__logos {
    width: 360px;
  }
}

.c-card--trust-markers-card {
  display: none;
  /* This applies from 992px */
}
@media (min-width: 992px) {
  .c-card--trust-markers-card {
    display: flex;
    width: 360px;
    height: 361px;
  }
}

.landing-form {
  position: relative;
  z-index: 4;
}
@media only screen and (max-width: 980px) {
  .landing-form .right-content {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .landing-form .full-row {
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
  }
  .landing-form .left-content {
    margin-top: -50px;
  }
}

.reviews-container {
  background-color: rgba(255, 255, 255, 0.5019607843);
  border-radius: 10px;
  padding: 10px 20px;
  max-width: 316px;
  min-width: 29rem;
  margin-bottom: 20px;
}
.reviews-container__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reviews-container__row img {
  width: 108px;
  height: 17px;
}
.reviews-container__content {
  margin-bottom: 0px;
  margin-left: auto;
  text-transform: uppercase;
  font-size: 12px;
  color: #13243a;
  max-width: 40%;
}
.reviews-container__content--number {
  padding-left: 10px;
  font-size: 16px !important;
  min-width: 40px;
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.c-vulnerable-user-information__content-item {
  margin-bottom: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .c-vulnerable-user-information__content-item {
    height: 134px;
    width: 670px;
  }
}
.c-vulnerable-user-information__icon {
  text-align: center;
}
.c-vulnerable-user-information__icon-item {
  height: 130px;
  width: 130px;
}

.c-card--vulnerable-user-information {
  /* This applies from 992px */
}
@media (min-width: 992px) {
  .c-card--vulnerable-user-information {
    width: 904px;
    height: 235px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.c-breadcrumbs {
  border-top: 0.1rem solid rgba(19, 36, 58, 0.2);
}
@media (max-width: 992px) {
  .c-breadcrumbs__container {
    max-width: 100%;
    padding: 0 3.5rem;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.c-vacancies__card-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 5.8rem;
}
@media (min-width: 992px) {
  .c-vacancies__card-row {
    width: 100%;
    max-width: 108.8rem;
    flex-direction: row;
    gap: 1rem;
    margin-top: 7.9rem;
  }
}
.c-vacancies__card-link {
  padding-bottom: 2.4rem;
  text-decoration: underline !important;
}
.c-vacancies__card-link::before {
  content: "";
  display: inline-block;
  width: 100%;
  max-width: 2.4rem;
  height: 2.4rem;
  vertical-align: bottom;
  padding-left: 3.5rem;
  background: url(../images/arrow-solid.svg) 50% no-repeat;
  background-size: contain;
}
.c-vacancies__no-suitable {
  width: 100%;
  max-width: 75.2rem;
  margin: 0 auto;
  padding: 5.9rem 1rem;
}
@media (min-width: 992px) {
  .c-vacancies__no-suitable {
    text-align: center;
    padding: 8.8rem 0;
  }
}
.c-vacancies__no-suitable-header {
  margin-bottom: 0;
}

.c-card--vacancies {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: #fff;
  border: 0.1rem solid #f7aa00;
  border-radius: 1rem;
  text-align: center;
  width: 100%;
}
@media (min-width: 992px) {
  .c-card--vacancies {
    max-width: 35.2rem;
    min-height: 31.5rem;
  }
}
.c-card__header--vacancies {
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  padding: 1.7rem 1.1rem 2.7rem 1.1rem;
}
@media (min-width: 992px) {
  .c-card__header--vacancies {
    padding: 0 3.5rem 1.8rem 3.5rem;
  }
}
.c-card__header--no-vacancies {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.c-card__content--vacancies {
  display: none;
}
@media (min-width: 768px) {
  .c-card__content--vacancies {
    display: block;
    margin-bottom: 0;
    padding-bottom: 3.1rem;
  }
}
.c-card__content--no-vacancies {
  margin-bottom: 0;
}
.c-card--no-vacancies {
  background: #fff;
  border: 0.1rem solid #f7aa00;
  border-radius: 1rem;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .c-card--no-vacancies {
    max-width: 55.4rem;
    min-height: 27.1rem;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.member-showcase__copy {
  margin: 0 auto;
  width: 100%;
  max-width: 72rem;
  text-align: center;
  font-weight: 500;
}
@media (min-width: 576px) {
  .member-showcase__copy {
    font-size: 2rem;
  }
}
.member-showcase__row {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 7rem;
  padding-bottom: 6.3rem;
  width: 100%;
  gap: 6.5rem;
}
@media (min-width: 576px) {
  .member-showcase__row {
    flex-direction: row;
  }
}
.member-showcase__button {
  display: flex;
  justify-content: center;
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.c-statistics__content {
  width: 100%;
  max-width: 54rem;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}
.c-statistics__row {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .c-statistics__row {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
.c-statistics__column {
  display: flex;
  flex-direction: column;
}
.c-statistics__statistics {
  font-size: 6.2rem;
  font-weight: 300;
  color: #f7aa00;
  text-align: center;
}
@media (min-width: 768px) {
  .c-statistics__statistics {
    font-size: 10.2rem;
  }
  .c-statistics__statistics::after {
    display: block;
    margin: -2rem auto 0;
    content: " ";
    height: 0.1rem;
    width: 100%;
    max-width: 10rem;
    background-color: #13243a;
  }
}
.c-statistics__title {
  margin: 0 0 1rem;
  font-size: 2.4rem;
  text-align: center;
  font-weight: 500;
}

.benefits__container {
  position: relative;
  overflow-x: clip;
  z-index: 1;
}

.benefits-row {
  position: relative;
  margin-bottom: 3.6rem;
  z-index: 1;
}
.benefits-row__copy {
  margin: 0 auto;
  width: 100%;
  max-width: 72rem;
  font-weight: 500;
  text-align: center;
  font-size: 2rem;
}
.benefits-row__image {
  display: none;
}
@media (min-width: 992px) {
  .benefits-row__image {
    display: block;
    position: absolute;
    bottom: -5rem;
    right: 0rem;
    z-index: 2;
    transform: translateY(50%);
  }
}
.benefits-row .benefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 4.7rem;
}
@media (min-width: 992px) {
  .benefits-row .benefits {
    width: 100%;
    max-width: 108.8rem;
    flex-direction: row;
    gap: 5.4rem;
  }
}

.product-card-carousel {
  z-index: 1;
}
.product-card-carousel__container {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 14rem;
}
.product-card-carousel__container--no-bottom-padding {
  padding-bottom: 0;
}
.product-card-carousel__tabs-container {
  margin-top: 2rem;
  display: block;
}
.product-card-carousel__tab-container {
  padding-left: 0;
  padding-right: 0;
}
.product-card-carousel__slider {
  z-index: 2;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.product-card-carousel__slider:not(.slick-initialized) {
  justify-content: center;
}
@media (max-width: 575.98px) {
  .product-card-carousel__slider {
    margin-bottom: 4rem;
    padding-right: 6rem;
    padding-left: 6rem;
  }
}
@media only screen and (max-width: 400px) {
  .product-card-carousel__slider {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
@media only screen and (max-width: 370px) {
  .product-card-carousel__slider {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media only screen and (min-width: 720px) {
  .product-card-carousel__slider--mobile {
    display: none;
  }
}
@media only screen and (max-width: 720px) {
  .product-card-carousel__slider--large {
    display: none;
  }
}
.product-card-carousel__slide {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.product-card-carousel__slide--no-slider {
  margin-left: 0;
  margin-right: 0;
}
.product-card-carousel__slide-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 3rem 0 6rem;
  margin-left: 1.8rem;
  margin-right: 1.8rem;
  text-align: center;
  width: 326px;
  height: 100%;
}
@media only screen and (min-width: 720px) {
  .product-card-carousel__slide-content {
    width: 300px;
  }
}
@media only screen and (min-width: 992px) {
  .product-card-carousel__slide-content {
    width: 280px;
  }
}
@media only screen and (min-width: 1150px) {
  .product-card-carousel__slide-content {
    width: 326px;
  }
}
@media only screen and (max-width: 400px) {
  .product-card-carousel__slide-content {
    padding-bottom: 3rem;
  }
}
.product-card-carousel__slide-content--signpost {
  width: 32rem;
}
.product-card-carousel__slide--no-slider .product-card-carousel__slide-content--signpost {
  width: 44rem;
}
.product-card-carousel__slide--no-slider .product-card-carousel__slide-content--signpost margin {
  left: 0;
  right: 0;
}
.product-card-carousel__arrows {
  position: relative;
  text-align: center;
}
@media only screen and (max-width: 720px) {
  .product-card-carousel__arrows {
    display: none;
  }
}
.product-card-carousel .slick-slide--product-card-carousel {
  height: inherit;
}
.product-card-carousel .slick-slide--product-card-carousel > div {
  height: 100%;
}
.product-card-carousel .slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.product-card-carousel .accordion {
  width: 100% !important;
}
.product-card-carousel__image {
  position: absolute;
  bottom: 1.5rem;
  right: 0;
  transform: translateY(50%);
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
.c-product-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  /* This applies from 768px */
}
@media (min-width: 992px) {
  .c-product-cards {
    flex-direction: row;
  }
}
.c-product-cards .btn-wrap {
  margin-top: auto;
  margin-bottom: 20px;
}

.sitemap-section {
  z-index: 1;
}
.sitemap-section__container {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 3rem;
}
.sitemap-section__title {
  font-weight: 400;
  border-bottom: 1px solid #707070;
  padding-bottom: 2rem;
}
.sitemap-section__title-link {
  text-decoration: none;
}
.sitemap-section__sub-title {
  font-weight: 900;
}
.sitemap-section__sub-title-link {
  text-decoration: none;
}
.sitemap-section__sub-section-links {
  list-style-type: none;
  padding-left: 0;
}
.sitemap-section__sub-section-link {
  margin-bottom: 2.5rem;
}

.recommended-articles {
  z-index: 1;
}
.recommended-articles__container {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 992px) {
  .recommended-articles__container {
    max-width: 93rem;
  }
}
@media (min-width: 1150px) {
  .recommended-articles__container {
    max-width: 111rem;
  }
}
.recommended-articles__articles {
  display: grid;
  justify-content: center;
}
@media (min-width: 768px) {
  .recommended-articles__articles {
    grid-template-columns: 100%;
  }
}
@media (min-width: 992px) {
  .recommended-articles__articles {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}
.recommended-articles__article {
  min-width: 32rem;
  margin-left: 0;
  margin-right: 0;
}
.recommended-articles__article .c-card__image--article {
  width: 100%;
  height: 100%;
  max-height: 20rem;
  object-fit: cover;
}
@media (min-width: 992px) {
  .recommended-articles__article {
    max-width: 28rem;
    min-width: 28rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    justify-self: center;
  }
}
@media (min-width: 1150px) {
  .recommended-articles__article {
    max-width: 34rem;
    min-width: 34rem;
  }
}
.recommended-articles__arrows {
  position: relative;
  text-align: center;
  margin-top: 3rem;
}
@media only screen and (max-width: 720px) {
  .recommended-articles__arrows {
    display: none;
  }
}

/* ==========================================================================
   #BREAKPOINT-SETTINGS
   ========================================================================== */
@media (max-width: 576px) {
  .c-login-content .container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
@media (max-width: 420px) {
  .c-login-content__left-mobile-images {
    flex-direction: column;
  }
}
.c-login-content__left-image-right {
  position: relative;
  z-index: 4;
  right: -100px;
}
@media (min-width: 992px) {
  .c-login-content__left-image-right {
    position: absolute;
    right: -50px;
    bottom: -8px;
  }
}
@media (min-width: 1200px) {
  .c-login-content__left-image-right {
    right: 14px;
    bottom: 0;
  }
}
.c-login-content__left-image {
  position: absolute;
  bottom: -1px;
  z-index: 3;
}
@media (min-width: 992px) {
  .c-login-content__left-image {
    bottom: 0;
  }
}