.reviews-container {
  background-color: #ffffff80;
  border-radius: 10px;
  padding: 10px 20px;
  max-width: 316px;
  min-width: 29rem;
  margin-bottom: 20px;

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 108px;
      height: 17px;
    }
  }

  &__content {
    margin-bottom: 0px;
    margin-left: auto;
    text-transform: uppercase;
    font-size: 12px;
    color: #13243a;
    max-width: 40%;

    &--number {
      padding-left: 10px;
      font-size: 16px !important;
      min-width: 40px;
    }
  }
}
