@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-content-links {
  &__content-column {
    flex-direction: column-reverse;

    @media (min-width: map-get($sizes, lg)) {
      flex-direction: row;
    }
  }

  &__column-left {
    &--additional-margin {
      margin-left: 0 !important;

      @media (min-width: map-get($sizes, lg)) {
        margin-left: 8rem !important;
      }
    }
  }

  &__column-right {
    &--additional-margin {
      margin-left: 0 !important;

      @media (min-width: map-get($sizes, lg)) {
        margin-left: 15rem !important;
      }
    }
  }
}
