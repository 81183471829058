.recommended-articles {
    z-index: 1;
    &__container {
        position: relative;
        padding: {
            left: 0;
            right: 0;
        }    
        @include media-breakpoint-up(lg) {
            max-width: 93rem;
        }
        @include media-breakpoint-up(xl) {
            max-width: 111rem;
        }
    }

    &__articles {
        display: grid;
        justify-content: center;
        
        @include media-breakpoint-up(md) {
             grid-template-columns: 100%;
        }
        
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        }
    }

    &__article {
        min-width: 32rem;
        margin: {
            left: 0;
            right: 0;
        }

        .c-card__image--article {
            width: 100%;
            height: 100%;
            max-height: 20rem;
            object-fit: cover;
        }

        @include media-breakpoint-up(lg) {
            max-width: 28rem;
            min-width: 28rem;
            margin: {
                left: 1.5rem;
                right: 1.5rem;
            }
            justify-self: center;
            }
        @include media-breakpoint-up(xl) {
            max-width: 34rem;
            min-width: 34rem;
        }
    }
    &__arrows {
        position: relative;
        text-align: center;
        margin-top: 3rem;

        @media only screen and (max-width: 720px) {
            display: none;
        }    
    }
}