@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-statistics {

  &__content {
    width: 100%;
    max-width: 54rem;
    margin: 0 auto;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }

  &__row {
    display: flex;
    flex-direction: column;
    @media (min-width: map-get($sizes, md)) {
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__statistics {
    font-size: 6.2rem;
    font-weight: 300;
    color: $color-secondary;
    text-align: center;
    @media (min-width: map-get($sizes, md)) {
      font-size: 10.2rem;
      &::after {
        display: block;
        margin: -2rem auto 0;
        content: ' ';
        height: 0.1rem;
        width: 100%;
        max-width: 10rem;
        background-color: $color-primary;
      }
    }
  }

  &__title {
    margin: 0 0 1rem;
    font-size: 2.4rem;
    text-align: center;
    font-weight: 500;
  }
}
