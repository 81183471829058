.full-width {
  width: calc(100vw);
  margin-left: calc((100% - 100vw) / 2);
}

.block-space,
.wp-block-group__inner-container {
  padding-top: 85px;
  padding-bottom: 85px;

  @include media-breakpoint-down(sm) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &--no-top {
    padding-top: 0;
  }

  &--no-bottom {
    padding-bottom: 0;
  }

  &--2x-bottom {
    @include media-breakpoint-up(md) {
      padding-bottom: 170px;
    }
  }

  &--2x-top {
    @include media-breakpoint-up(md) {
      padding-top: 170px;
    }
  }
}

.bg-cover {
  @include bg-cover;
}

.bg-contain {
  background-size: contain;
  background-position: 100%;
  background-repeat: no-repeat;
}

.bg-white {
  background: $white;
}

.bg-grey {
  background: $lightgrey;
}

.bg-black {
  background: $black;
}

.bg-primary {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: $white;
  }

  a {
    &:not(.btn) {
      color: $white;

      &:hover,
      &:focus {
        color: $color-secondary;
      }
    }
  }

  .btn--secondary {
    &:not(.btn--hollow) {
      background-color: $color-secondary;
    }

    &:hover,
    &:focus {
      background-color: $color-primary;
    }
  }

  .stepped-border,
  .stepped-border:before,
  .stepped-border:after {
    border-color: $white;
  }
}

.bg-secondary {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: $color-primary;
  }

  a {
    &:not(.btn) {
      color: $color-primary;

      &:hover,
      &:focus {
        color: rgba($color-primary, 0.75);
      }
    }
  }

  .btn {
    &:not(.btn--hollow) {
      background-color: $color-primary;
      color: $white;
    }

    &:hover,
    &:focus {
      background-color: $color-secondary;
    }
  }

  .link {
    &:before {
      background: url(../images/arrow-solid-white.svg) center no-repeat;
      background-size: contain;
    }
  }

  .stepped-border,
  .stepped-border:before,
  .stepped-border:after {
    border-color: $color-primary;
  }
}

.bg-tertiary {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }

  a {
    @media only screen and (min-width: 600px) {
      &:not(.btn) {
        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
  }

  .btn {
    &:not(.btn--hollow) {
      background-color: $color-secondary;
      color: $color-primary;
    }

    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $white;
    }
  }
}

.bg-secondary-fade {
  background-color: $color-quinary;
}

.bg-secondary-fade-darker {
  background-color: rgba($color-secondary, 0.25);
}

.bg-tertiary {
  background-color: $color-tertiary;
}

.bg-tertiary-fade {
  background-color: $color-senary;
}

.bg-tertiary-fade-darker {
  background-color: rgba($color-tertiary, 0.25);
}

.bg-tertiary-fade-light {
  background-color: $color-tertiary-light;
}

.bg-form {
  background-color: $color-form;
}

.bg-form-fade {
  background-color: $color-form;
}

.bg-form-fade-darker {
  background-color: rgba($color-form, 0.25);
}

.bg-quaternary {
  background-color: $color-quaternary;
}

.half-margin {
  margin-bottom: 10px;
}

.theme-dark {
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: $white;
  }

  a {
    &:not(.btn) {
      color: $white;

      &:hover,
      &:focus {
        color: $color-secondary;
      }
    }
  }
}

.remove-underlines {
  text-decoration: none !important;

  * {
    text-decoration: none !important;
  }
}

.remove-bullets {
  li {
    &:before {
      display: none;
    }
  }
}

.last-margin {
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

.desktop-copy {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.no-padding {
  padding: 0 !important;
}

.primary-links {
  a {
    &:not(.btn) {
      color: $color-primary;

      &:hover,
      &:focus {
        color: $color-secondary;
      }
    }
  }
}

.half-gutters {
  margin: 0 calc($grid-gutter-width / 4 * -1);

  > * {
    padding: 0 calc($grid-gutter-width / 4);
  }
}

.btn-no-style {
  border: none;
  background: transparent;
}

.no-margin {
  margin: 0;
}

.u-margin-top-medium-n {
  margin-top: -30px;
}

.reduce-margin {
  margin-bottom: 20px;
}

.reduce-margin-bottom {
  margin-bottom: 25px;
}

.sf-border-radius {
  border-radius: 0.6rem;
}

.bold-links {
  a {
    &:not(.btn) {
      font-weight: bold;
    }
  }
}

.reduce-list-margin {
  li {
    margin-bottom: 20px;
  }
}

.hidden {
  display: none;
}

.white-space-prewrap {
  white-space: pre-wrap;
}
