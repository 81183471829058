.long-form-content {

    &__heading {
        margin-bottom: 30px;
    }

    &__quick-links {
        padding-top: 10px;
        margin-bottom: 45px;

        li {
            margin-bottom: 15px;
        }
    }

    &__body {
        max-width: $column-content-width;
    }

    &__copy {

        h4 {
            color: $color-secondary;
        }
    }

    &__footer {
        padding-top: 80px;

        ul {
            padding-top: 10px;

            li {
                margin-bottom: 18px;
            }
        }
    }

    .sidebar {
        @include media-breakpoint-down(sm) {
            position: static!important;
        }
    }
}
