@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-breadcrumbs {
  border-top: 0.1rem solid rgba($color-primary, 0.2);

  &__container {
    @media (max-width: map-get($sizes, lg)) {
      max-width: 100%;
      padding: 0 3.5rem;
    }
  }
}
