.error-block {

    &__content {
        max-width: 430px;
    }

    &__heading {
        margin-bottom: 60px;

        @include media-breakpoint-down(sm) {
            text-align: center;
            margin-bottom: 30px;
        }
    }

    &__copy {
        p,li {
            margin-bottom: 10px;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;

            ul {
                text-align: left;
            }
        }
    }

    .btn-wrap {
        padding-top: 25px;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    &__image {
        margin-bottom: -60px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
    }
}
