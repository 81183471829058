.team-modal {

    @include media-breakpoint-down(xs) {
        position: relative;
        background: none;
        padding: 30px 0 0;
        width: auto;
        margin: 0 -35px;

        .modal__inner {
            padding: 35px 65px 35px 35px;
            box-shadow: none;
        }
    }

    &__thumbnail {
        margin-bottom: 45px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
            text-align: center;
        }

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &__heading {
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }


    hr {
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }
    &__copy {
        padding-top: 25px;

        @include media-breakpoint-down(xs) {
            padding-top: 0;
        }
    }

    .btn-wrap {
        margin-bottom: 15px;
    }
}
