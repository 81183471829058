.mobile-navigation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 450;
  width: 100%;
  height: 100%;
  padding: 35px 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: $white;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $transition;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &.active {
    transform: translateX(0);
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
  }

  &.sub-open {
    overflow: hidden;
  }

  .burger {
    position: absolute;
    top: 15px;
    right: 25px;
  }

  &__header-links {
    padding: 0 35px;
    margin: 0 -15px;
    font-size: 0;

    li {
      display: inline-block;
      padding: 0 15px;
      margin-bottom: 15px;
    }

    a {
      text-decoration: none;
    }
  }

  &__nav {
    padding-top: 60px;

    .mobile-item {
      > a {
        color: $color-primary;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .hide-on-mobile {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    li {
      margin: 0;
      padding: 0 35px;
      font-size: 1.6rem;
      font-weight: 500;
      cursor: pointer;
      position: relative;

      &.separator {
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          margin: 15px 0;
          background: $color-quaternary;
        }

        &.menu-item-has-children {
          &:before {
            width: calc(100% + 45px);
          }

          &:after {
            top: 45px;
          }
        }
      }

      > a {
        padding-right: 40px;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          display: block;
          width: 24px;
          height: 24px;
          background: url(../images/arrow-hollow.svg) center no-repeat;
          background-size: contain;
          pointer-events: none;
          transform: translateY(-50%);
        }
      }

      &.menu-item-has-children {
        padding-right: 80px;

        &:after {
          content: "";
          position: absolute;
          top: 32px;
          right: 35px;
          display: block;
          width: 24px;
          height: 24px;
          background: url(../images/arrow-hollow.svg) center no-repeat;
          background-size: contain;
          pointer-events: none;
          transform: rotate(90deg) translateY(0) translateX(-50%);
          z-index: 9999;
        }

        &.clicked {
          &:after {
            background: url(../images/close-button.svg) center no-repeat;
            background-size: contain;
            transform: rotate(0) translateY(-50%) translateX(0);
          }
        }

        > a {
          &:after {
            display: none;
            transform: rotate(90deg) translateY(0) translateX(-50%);
          }
        }
      }
    }

    .sub-menu__toggle {
      position: absolute;
      top: 50%;
      right: 35px;
      //font-size:0;
      // font-color:transparent;
      display: block;
      width: 24px;
      height: 24px;
      background: url(../images/arrow-hollow.svg) 50% no-repeat;
      background-size: contain;
      pointer-events: none;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      webkit-transform: rotate(90deg) translateY(0) translateX(-50%);
      transform: rotate(90deg) translateY(0) translateX(-50%);
      z-index: 9999;
    }

    a {
      display: block;
      position: relative;
      padding: 15px 0;
      color: $color-quaternary;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $color-primary;
      }
    }

    .sub-menu {
      display: none;
      position: relative;
      left: -35px;
      z-index: 10;
      width: calc(100% + 115px);
      pointer-events: none;
      padding: 15px 0;
      background: rgba($color-secondary, 0.1);

      li {
        > a {
          &:after {
            background: url(../images/arrow-solid.svg) center no-repeat;
            background-size: contain;
          }
        }
      }

      &.active {
        display: block;
        pointer-events: auto;
      }

      &.current {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }
    }

    .menu-back {
      position: relative;
      padding-left: 30px;

      &:hover,
      &:focus {
        > a {
          color: $color-primary;
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 12px;
        height: 21px;
        transform: translateX(0) translateY(-50%);
        background: url(../images/chevron-left.svg) center no-repeat;
        background-size: contain;
        pointer-events: none;
      }
    }
  }

  .mobile-navigation__login {
    text-align: center;

    a {
      display: inline-block;
      min-width: 185px;
      padding: 16px 20px 16px 20px;
      color: $color-primary;

      &:after {
        display: none;
      }

      > * {
        display: inline-block;
        vertical-align: middle;
        transition: none;
      }

      i {
        margin-right: 6px;
        font-size: 2.2rem;
      }
    }
  }

  &__details {
    padding: 30px 35px;
    text-align: center;
  }

  &__number {
    margin: 35px 0;
    img {
      margin-right: 10px;
      max-width: 2.766rem;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
