@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-trust-markers {
  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2.4rem;
  }
  &__logo-image {
    width: 116px;
  }
  /* This applies from 768px */
  @media (min-width: map-get($sizes, md)) {
    &__logos {
      width: 100%;
    }
    &__logo-image {
      width: 131px;
    }
  }

  /* This applies from 992px */
  @media (min-width: map-get($sizes, lg)) {
    &__list {
      list-style-type: none;
      padding-left: 1.6rem;
      margin-bottom: 0;
    }
    &__list-item {
      font-family: $font-heading;
      font-size: 1.4rem;
      line-height: 1.5;
      position: relative;
      font-weight: 500;
      padding-left: 1.6rem;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: -20px;
        width: 20px;
        height: 20px;
        background: url("https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/02/tick-bullet-yellow.svg")
          50% no-repeat;
        background-size: contain;
      }
    }
    &__logos {
      width: 360px;
    }
  }
}

.c-card {
  &--trust-markers-card {
    display: none;

    /* This applies from 992px */
    @media (min-width: map-get($sizes, lg)) {
      display: flex;
      width: 360px;
      height: 361px;
    }
  }
}
