.expand-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    padding: 15px;
    min-height: 140px;
    max-width: 168px;
    text-align: center;
    background: #E1F3FB;
    cursor: pointer;

    @include media-breakpoint-down(md) {
        display: inline-block;
        min-height: 0;
        min-width: 235px;
        max-width: 100%;
        text-align: left;
        padding: 15px 60px 15px 20px;
        justify-content: flex-start;
        height: auto!important;
    }

    @media only screen and (max-width: 370px) {
        min-width: 200px;
    }

    &.active {
        background: $color-tertiary;

        .expand-tab__heading {
            color: $white;
        }
    }

    &.active {
        + .expand-content {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }


    &--join-line {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -25px;
            left: 50%;
            width: calc(100% + 30px);
            height: 1px;
            background: $color-quaternary;
            z-index: -1;

            @include media-breakpoint-down(md) {
                top: -7.5px;
                left: 0;
                width: 1px;
                height: calc(100% + 15px);
                transform: translateX(-50%);
            }
        }
    }


    &--link-to-tab {


        @include media-breakpoint-down(md) {
            &:before {
                height: calc(50% + 7.5px);
                left: -29px!important;
            }
        }
    }

    &--arrow-bottom {
        @include media-breakpoint-down(md) {
            &:before {
                height: calc(50% + 7.5px);
                left: -29px!important;
            }
        }
    }

    &--connect-previous-row {
        @include media-breakpoint-down(md) {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -7.5px;
                left: 0;
                width: 1px;
                height: calc(100% + 15px);
                background: $color-quaternary;
                z-index: -1;
                transform: translateX(-50%);
            }
        }

        .expand-tab__arrow--top {
            @include media-breakpoint-up(lg) {
                &:after {
                    display: none;
                }
            }
        }
    }


    &__heading {
        line-height: 1.5;
        min-height: 60px;
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
            min-height: 0;
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm) {
            padding-right: 15px;
        }
    }

    &__row-line {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        width: 1px;
        background: $color-quaternary;
        transform: translateY(-100%) translateX(-50%);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__col-line {
        display: block;
        position: absolute;
        top: -24px;
        z-index: -1;
        height: 1px;
        background: $color-quaternary;
        transform: translateY(-100%);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__arrow {
        position: absolute;
        top: 0;
        left: 50%;
        height: 25px;
        transform: translateX(-50%) translateY(-100%);

        @include media-breakpoint-down(md) {
            left: 6px;
        }


        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            border-top: 10px solid $color-quaternary;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            transform: translateX(-50%);
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: $color-quaternary;
            transform: translateX(-50%);
        }

        &--top {
            @include media-breakpoint-down(md) {
                top: 50%;
                left: 0;
                width: 29px;
                transform: translateY(-50%);

                &:before {

                    top: 50%;
                    bottom: auto;
                    left: 0;
                    border-top: 5px solid transparent;
                    border-left: 10px solid $color-quaternary;
                    border-right: none;
                    border-bottom: 5px solid transparent;
                    transform: translateY(-50%) translateX(-100%);
                }

                &:after {
                    top: 50%;
                    left: 0;
                    bottom: auto;
                    width: 100%;
                    height: 1px;
                    transform: translateY(-50%) translateX(-100%);
                }
            }
        }

        &--bottom {
            top: auto;
            bottom: 0;
            transform: translateX(-50%) translateY(100%);

            &:after {
                top: 0;
                bottom: auto;
                transform: translateX(-50%);

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &:before {
                top: 0;
                bottom: auto;
                border-top: none;
                border-bottom: 10px solid $color-quaternary;
            }
        }
    }

    .arrow {
        @include media-breakpoint-down(md) {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-55%);
        }
    }
}

.expand-content {
    position: absolute;
    width: 100%;
    left: 50%;
    z-index: 2;
    max-width: 900px;
    padding: 50px 90px;
    margin: 25px auto;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background: $color-quinary;

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 25px 35px 35px;
    }
}
