@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.tab {
  display: flex;
  justify-content: space-between;
  margin: -1px auto;
  overflow: hidden;
  width: 1088px;
  z-index: 1;
  &--bare {
    position: relative;
    padding: 0;
    @media only screen and (max-width: 1100px) {
      width: 100%;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.tab-list-item {
  background-color: inherit;
  border: none;
  cursor: pointer;
  float: left;
  font-size: 2rem;
  list-style-type: none;
  margin-bottom: 0;
  outline: none;
  padding: 1.3rem 5.8rem;
  transition: 0.3s;

  &.active {
    background-color: $white;
    border-bottom: 1px solid $white;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: none;
  }

  &--bare {
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 1.3rem 2rem;
    font-weight: 500;

    @media only screen and (max-width: 1100px) {
      font-size: 1.5rem;
      min-width: 15rem;
      text-align: center;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background: $color-tertiary;
      opacity: 1;
    }

    &:hover {
      &:after {
        height: 0.5rem;
      }
    }

    &.active {
      background-color: transparent;
      border: none;
      &:after {
        height: 0.5rem;
      }
    }
  }
}

.tab-link {
  color: $color-primary;
  text-decoration: none;
  display: inline-block;
  padding: 1.3rem 5.8rem;
  margin: -1.3rem -5.8rem;

  @media only screen and (max-width: 1100px) {
    padding: 0;
    margin: 0;
  }

  &--bare {
    background-color: transparent;
    border: none;
    font-weight: 700;

    &:after {
      opacity: 1;
    }
  }
}

.tabcontent {
  display: none;
  margin: -2px auto;
  padding: 71px 57px;
  width: 1088px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  &--bare {
    padding: 0 !important;
    background: none;
    border: none;
  }
}

@media (max-width: 1100px) {
  .tabbed-element {
    &:not(.tabbed-element--show-on-mobile) {
      display: none;
    }
  }
}

.c-tabbed-element {
  @for $i from 1 through 5 {
    #tab-#{$i}:checked ~ &__tabs #tab-#{$i}-label {
      @media (min-width: map-get($sizes, xl)) {
        background-color: $white;
        border-radius: 0.5rem 0.5rem 0 0;
        border: 1px solid $color-secondary;
        border-bottom: 1px solid $white;
        margin: 0;
      }
    }

    #tab-#{$i}:checked ~ &__tabs #tab-#{$i}-panel {
      @media (min-width: map-get($sizes, xl)) {
        display: block;
      }
    }
  }

  &__information {
    &--reverse {
      @media (max-width: map-get($sizes, xl)) {
        flex-direction: column-reverse !important; // remove important after styles issue is fixed
      }
    }
  }

  &__image {
    &--is-hidden {
      @media (max-width: map-get($sizes, xl)) {
        display: none;
      }
    }
  }

  &__toggle {
    @media (max-width: map-get($sizes, xl)) {
      display: flex;
      align-items: center;
    }

    &::after {
      @media (max-width: map-get($sizes, xl)) {
        content: "";
        width: 24px;
        height: 24px;
        margin-left: auto;
        background: url(../images/arrow-solid.svg) 50% no-repeat;
        background-size: contain;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transition: 0.3s;
        transition: 0.3s;
      }
    }

    &.active::after {
      @media (max-width: map-get($sizes, xl)) {
        background: url(../images/close-button-solid.svg) 50% no-repeat;
        background-size: contain;
        -webkit-transform: rotate(0) translateX(0);
        transform: rotate(0) translateX(0);
      }
    }
  }

  $tabs: (1, 2, 3, 4, 5);
  &__flex-tabs {
    @media (max-width: map-get($sizes, xl)) {
      @each $tab in $tabs {
        $i: index($tabs, $tab);

        #tab-#{$i}-label {
          order: $i * 2-1;
        }
        #tab-#{$i}-panel {
          order: $i * 2;
        }
      }

      .c-tabbed-element__panel.active {
        display: block;
      }
    }
  }
}

.state {
  position: absolute;
  left: -10000px;
}

.important-information__copy {
  p {
    margin: 0;
    padding: 0;
  }

  border-radius: 0.8rem;
  border: 0.1rem solid rgba(69, 78, 80, 0.5019607843);
  text-align: center;
  margin: 4rem auto 0;
  max-width: 63.6rem;
  min-height: 8.5rem;
  padding: 1rem;
  width: 100%;

  @media (min-width: map-get($sizes, sm)) {
    padding: 1rem 3.8rem 0.8rem;
    width: 100%;
  }

  @media (max-width: map-get($sizes, xl)) {
    width: 70%;
  }
}
