.simple-content {
    
    img {
        margin: 10px 0;
    }

    .container {
        position: relative;
        padding-bottom: 45px;
    }

    .collapsed-copy {
        display: none;
    }

    .read-more-text {
        display: block;
        font-weight: bold;
        position: absolute;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        &:before {
            content: 'Read more +';
        }
    }
        
    .read-more-button {
        display: block;
        width: 100px;
        height: 20px;
        opacity: 0;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        z-index: 1;
    }

    .read-more-button:checked + .read-more-text {
        &:before {
            content: 'Read less -';
        }
    }
    .read-more-button:checked ~ .collapsed-copy {
        display: block;
    }
}
