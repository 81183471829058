@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.card-team {
  max-width: 260px;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(xs) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 330px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 370px) {
    display: block;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include media-breakpoint-down(xs) {
      flex: 1 0;
      padding: 0 5px;
      line-height: 1.5;
    }
  }

  &__thumbnail {
    margin-bottom: 25px;

    img {
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 5px;
      margin: 0;
    }

    @media only screen and (max-width: 370px) {
      margin-bottom: 15px;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__heading {
    margin-bottom: 0;

    @include media-breakpoint-down(xs) {
      font-size: 1.6rem;
      margin-bottom: 5px;
    }
  }

  &__copy {
    margin-bottom: auto;
  }

  .link-wrap {
    margin-top: 1rem;
  }
}
