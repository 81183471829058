.logo-banner {
    position: relative;

    > .container {
        position: relative;
    }

    &__slider {
        z-index: 2;
        @include media-breakpoint-down(xs) {
            margin-bottom: 40px;
            padding-right: 60px;
            padding-left: 60px;
        }


        @media only screen and (max-width: 400px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media only screen and (max-width: 370px) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &__slide-content {
        visibility: visible;
        will-change: transform;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding: 30px 15px 60px;
        text-align: center;
        height: 175px;
        width: 21.6rem;

        @media only screen and (max-width: 400px) {
            padding-bottom: 30px;
        }


        img {
            margin: 0 auto;
            width: auto;
            max-height: 85px;
            transform: scale(0.8);
            transition: $transition;

            @media only screen and (max-width: 370px) {
                transform: scale(0.9);
            }
        }

        span {
            display: block;
            position: absolute;
            top: 90%;
            left: 50%;
            opacity: 0;
            white-space: nowrap;
            pointer-events: none;
            transition: $transition;
            line-height: 1.5;
            transform: translateX(-50%) translateY(-100%);

            @media only screen and (max-width: 370px) {
                left: 0;
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
                white-space: normal;
                font-size: 1.2rem;
                transform: translateX(0) translateY(-100%);
                text-align: center;
            }
        }
    }

    &__arrows {
        position: relative;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 30px;

        @include media-breakpoint-down(xs) {
            position: static;
            padding: 0;
        }
    }


    .slick-arrow {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        z-index: 3;
        margin: 0 120px;
        transform: translateY(0) translateX(0);

        @include media-breakpoint-down(sm) {
            margin: 0 20px;
        }

        @include media-breakpoint-down(xs) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.slick-prev {
                left: 20px;

                @media only screen and (max-width: 400px) {
                    left: 0;
                }
            }

            &.slick-next {
                right: 20px;
                left: auto;

                @media only screen and (max-width: 400px) {
                    right: 0;
                }
            }
        }
    }

    .slick-current, .slick-current-clone-animate {
        .logo-banner__slide-content {
            img {
                transform: scale(1.1);

                @media only screen and (max-width: 370px) {
                    transform: scale(1);
                }
            }

            span {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }
        }
    }

}
