@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.containers-background {
  .invest-sustainably__row {
    align-items: stretch;
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: -8rem;
    padding: 2rem;
    flex-direction: column;
    position: relative;
    z-index: 1;

    @media (min-width: map-get($sizes, lg)) {
      flex-direction: row;
    }
  }

  .invest-sustainably__container {
    background-color: $white;
    border-radius: 1rem;
    padding: 2rem;
    width: 100%;

    @media (min-width: map-get($sizes, sm)) {
      padding: 3.5rem 3.2rem;
    }

    @media (min-width: map-get($sizes, lg)) {
      width: 53.6rem;
    }
  }

  .invest-sustainably__heading {
    color: $color-quaternary !important;
    margin: 0 auto;
    width: 85%;
  }

  .invest-sustainably__title {
    color: $color-secondary;
    font-size: 3.2rem;
    line-height: 1.25;
  }

  .invest-sustainably__tick-bullets ul {
    padding-left: 0;
  }

  .invest-sustainably__tick-bullets ul li {
    list-style: none;
    margin-bottom: 1rem;
    padding-left: 5rem;
    position: relative;
    text-align: left;
  }

  .invest-sustainably__tick-bullets ul li:before {
    background: url("https://shepherdsfriendly.blob.core.windows.net/wp-media/2021/10/tick-icon-black.png")
      50% no-repeat;
    content: "";
    height: 3rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 3rem;
  }

  .invest-sustainably__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: map-get($sizes, sm)) {
      justify-content: space-evenly;
      flex-direction: row;
    }
  }

  .invest-sustainably__btn a {
    margin-bottom: 2rem;
  }

  .image_container {
    align-items: center;
    display: flex;
    height: 33.2rem;
    justify-content: center;

    @media (min-width: map-get($sizes, sm)) {
      height: 63rem;
    }

    @media (min-width: map-get($sizes, md)) {
      margin-top: -24rem;
    }

    @media (min-width: (2560px)) {
      height: 95rem;
      margin-top: -40rem;
    }
  }

  .image_container__text {
    border-radius: 0.8rem;
    border: 0.1rem solid rgba(69, 78, 80, 0.5);
    padding: 0 5rem;
    margin-top: -20rem;
    width: 80%;

    @media (min-width: map-get($sizes, sm)) {
      padding: 0 2rem;
      margin-top: -30rem;
      width: 60%;
    }
    @media (min-width: map-get($sizes, md)) {
      margin-top: -5rem;
    }

    @media (min-width: map-get($sizes, lg)) {
      margin-top: 0;
      width: 30%;
    }
  }

  .bg-contain {
    @media (max-width: map-get($sizes, lg)) {
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
