.steps {

    &--stack-mobile {

        .steps__items {
            @include media-breakpoint-down(sm) {
                padding-top: 20px;
            }

            &:before {

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            li {
                @include media-breakpoint-down(sm) {
                    padding: 0;
                    margin-bottom: 40px;
                    text-align: center;
                }

                &:before {
                    @include media-breakpoint-down(sm) {
                        position: relative;
                        top: 0;
                        margin: 0 auto 15px;
                    }
                }

                &:after {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
    }


    &__items {


        &:before {
            content: 'Steps';
            display: block;
            font-weight: 500;
            margin-bottom: 20px;



        }

        ul {
            counter-reset: li;
        }

        li {
            position: relative;
            counter-increment: li;
            min-height: 65px;
            padding-left: 65px;
            margin-bottom: 60px;

            @include media-breakpoint-down(sm) {
                padding-left: 50px;
            }


            &:last-child {
                margin-bottom: 0;

                &:after {
                    display: none;
                }
            }

            &:before {
                content: counter(li);
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -5px;
                left: 0;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                font-size: 2rem;
                font-weight: bold;
                background: $color-primary;
                color: $white;


            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50px;
                left: 18px;
                width: 1px;
                height: calc(100% - 15px);
                background: $grey;


            }
        }
    }

}
