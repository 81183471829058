.post-header {
    margin-top: 4rem;
    margin-bottom: 35px;

    &--no-thumb {
        margin-top: 40px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 40px;
    }

    &__thumbnail {
        margin-bottom: 15px;

        img {
            width: 100%;
        }
    }


    &__date {
        padding-top: 5px;
    }

    &__categories {
        margin: 0 -7px;
        font-size: 0;

        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 25px;
        }

        > * {
            display: inline-block;
            padding: 0 7px;
        }
    }
}
