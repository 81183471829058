.product-card-carousel {
    z-index: 1;
    &__container {
        position: relative;
        padding: {
            left: 0;
            right: 0;
            bottom: 14rem;
        }
        &--no-bottom-padding {
            padding-bottom: 0;
        }
    }

    &__tabs-container {
        margin-top: 2rem;    
        display: block;
    }

    &__tab-container {
        padding: {
            left: 0;
            right: 0;
        }
    }

    &__slider{
        z-index: 2;
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;

    &:not(.slick-initialized) {
      justify-content: center;
    }

        @include media-breakpoint-down(xs) {
            margin-bottom: 4rem;
            padding-right: 6rem;
            padding-left: 6rem;
        }

        @media only screen and (max-width: 400px) {
            padding-right: 4rem;
            padding-left: 4rem;
        }

        @media only screen and (max-width: 370px) {
            padding-right: 2rem;
            padding-left: 2rem;
        }
        &--mobile {
            @media only screen and (min-width: 720px) {
                display: none;
            }    
        }
        &--large {
            @media only screen and (max-width: 720px) {
                display: none;
            }    
        }

    }
    &__slide {
        height: 100%;
        margin: {
            left: auto;
            right: auto;
        }
    &--no-slider {
            margin: {
                left: 0;
                right: 0;
            }
        }
    }

    &__slide-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding: 3rem 0 6rem;
        margin-left: 1.8rem;
        margin-right: 1.8rem;
        text-align: center;
        width: 326px;
        height: 100%;

        @media only screen and (min-width: 720px) {
            width: 300px;
        }    

        @media only screen and (min-width: 992px) {
            width: 280px;
        }    

        @media only screen and (min-width: 1150px) {
            width: 326px;
        }    

        @media only screen and (max-width: 400px) {
            padding-bottom: 3rem;
        }

    &--signpost {
      width: 32rem;
    }

    .product-card-carousel__slide--no-slider & {
      &--signpost {
        margin {
          left: 0;
          right: 0;
        }
        width: 44rem;
      }
    }
  }
  &__arrows {
    position: relative;
    text-align: center;

        @media only screen and (max-width: 720px) {
            display: none;
        }    
    }

    .slick-slide--product-card-carousel {
        height: inherit;
        > div {
            height: 100%;
        }
    }
    
    .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
    }
    
    .accordion {
        width: 100% !important;
    }
    &__image {
        position: absolute;
        bottom: 1.5rem;
        right: 0;
        transform: translateY(50%);
      }

}