.share {

    &__label {
        margin-bottom: 0;
    }

    &__list {
        margin: 0;
        font-size: 0;
    }

    &__item {
        display: inline-block;
        vertical-align: middle;
        margin: 2px 9px 4px;
        font-size: 2.5rem;
    }

}


.single-post__share {
    .share__label {
        max-width: 300px;
        padding-bottom: 10px;
        margin: 0 auto 10px;
        border-bottom: 1px solid $color-quaternary;
    }
}
