.downloads {

    &__content {
        max-width: 700px;
        margin: 0 auto;

        &--max-width {
            max-width: 900px;
            margin: 0 auto;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 40px 0 30px;
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
            max-width: 300px;
            margin: 0 auto;
        }

        > li {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);
            padding: 0 15px;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .btn-wrap {
        text-align: center;
    }

}

.download {
    > a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__icon {
        margin-right: 15px;
    }

    &__text {
        line-height: 1.5;
        flex: 1 0;
        font-weight: 500;
        transition: none;
    }

    &__checkbox {
        display: inline-block;
        margin-bottom: 50px;

        label {
            text-align: left;
        }
    }
}
