.cta {
  position: relative;
  overflow-x: clip;
  .section-heading {
    margin-bottom: 40px;
  }

  .container {
    position: relative;
  }

  &--no-form {
    @include media-breakpoint-down(sm) {
      .section-heading {
        max-width: 400px;
        .stepped-border--up {
          border-bottom: 0;
          &:after {
            display: none;
          }
        }
        .stepped-border--down {
          border-top: 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &--has-form {
    .cta__image {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .cta__image--left {
      left: 75px;
      bottom: -100px;
    }
  }

  &--offset {
    .section-heading {
      h4 {
        font-weight: 400;
      }
    }

    .cta__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .cta__image {
      flex: 0 0 300px;
      max-width: 300px;
      text-align: right;
      &--right {
        position: relative;
        left: 0 !important;
        top: 0 !important;
      }
    }

    .cta__content {
      flex: 1 0;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .section-heading {
      margin-bottom: 10px;
    }

    .cta__text,
    .section-heading {
      max-width: 630px;
      @include media-breakpoint-down(sm) {
        word-break: break-word;
      }
    }

    .cta__copy {
      max-width: 450px;
      margin: 0 auto;

      p,
      li {
        font-size: 2rem;
      }
    }
  }

  &__content-image {
    margin: 25px auto;
    max-width: 160px;
  }

  &__image {
    position: absolute;
    bottom: 0;
    pointer-events: none;

    &--left {
      left: 90px;

      @include media-breakpoint-down(sm) {
        left: -10px;
        bottom: 70px;
      }
    }

    &--right {
      position: absolute;
      right: 15px;

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 140px;
        right: -80px;
        bottom: 20px;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__copy {
    margin-bottom: 35px;
  }

  &__form {
    padding-top: 15px;
  }
}
