.signposts {
  &.signposts--has-copy {
    .section-heading {
      margin-bottom: 30px;
    }
  }

  &__copy {
    margin-bottom: 75px;
  }

  &.signposts--one {
    .signpost {
      @media only screen and (max-width: 992px) {
        justify-content: center;
      }
    }

    .signpost__content,
    .signposts__col {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }

  &.signposts--two {
    .signpost {
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }

      &__content {
        flex: 1 0;

        @include media-breakpoint-down(md) {
          order: 1;
        }

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          max-width: 100%;
          order: 2;
        }
      }

      &__image {
        max-width: 300px;

        @include media-breakpoint-down(md) {
          order: 2;
        }

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          max-width: 100%;
          order: 1;
          margin-bottom: 15px;
        }

        img {
          width: 100%;
          max-width: 230px;
          max-height: 230px;
        }
      }

      &__copy {
        max-width: 100%;

        @include media-breakpoint-down(md) {
          display: block;
          text-align: left;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          max-width: 320px;
          margin: 15px auto 30px;
        }
      }
    }

    .signposts__col {
      margin-bottom: 75px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > .container {
    position: relative;
    z-index: 1;
  }

  .section-heading {
    margin-bottom: 75px;
  }

  .subtitle {
    padding: 20px 0px 30px 0px;
  }

  .boxed-content {
    margin: 50px auto;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgb(194, 194, 194);
    @include media-breakpoint-up(md) {
      width: 70%;
      p {
        width: 65%;
      }
    }
    p {
      margin: 0 auto;
    }
  }

  &__col {
    margin-bottom: 75px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(md) {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__link-wrapper {
    padding-top: 75px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      padding-bottom: 100px;
    }
  }

  &__image {
    position: absolute;
    bottom: 15px;
    right: 0;
    z-index: -1;
    transform: translateY(50%);
  }
  
  &__card-container {	
    flex-direction: column;	
    @include media-breakpoint-down(sm) {	
      flex-direction: column;	
      justify-content: space-between;	
    }	
  }	
  &__section-introduction {	
    @include media-breakpoint-up(md) {	
      max-width: 100%;	
    }	
  }
}
