.split-content {

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    .wide-copy {
        padding-bottom: 25px;

    }

    figure {
        figcaption {
            padding-top: 10px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
            text-align: center;
            img {
                width: 100%;
            }
        }
    }

    .btn-wrap {
        padding-top: 30px;
        text-align: center;
    }
}
