/* Burger Menu
============== */

.burger {
  display: none;

  cursor: pointer;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &.open {

    .burger__lines {
      background: rgba($white, 0);

      &:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        bottom: 0;
      }

      &:before {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 0;
      }
    }
  }

  &__text {
    margin-right: 10px;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 60px;
  }

  &__menu {
    display: block;
    position: relative;
    margin: 0;
    width: 30px;
    height: 20px;
    z-index: 27;
    cursor: pointer;
  }

  &__lines {
    position: absolute;
    background: rgba($color-quaternary, 1);
    width: 100%;
    height: 3px;
    top: 8px;
    right: 0;
    opacity: 1;
    border-radius: 3px;

    &:before {
      position: absolute;
      background: rgba($color-quaternary, 1);
      width: 100%;
      height: 3px;
      top: 8px;
      left: 0;
      content: "";
      display: block;
      border-radius: 3px;
    }

    &:after {
      position: absolute;
      background: rgba($color-quaternary, 1);
      width: 100%;
      height: 3px;
      bottom: 8px;
      left: 0;
      content: "";
      display: block;
      border-radius: 3px;
    }
  }

  &__lines,
  &__lines::after,
  &__lines::before {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
}
