/* base styles: opinionated defaults */
* {
  box-sizing: border-box;
}

html,
body {
  background: $white;
}

html {
  color: $color-quaternary;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-size: 1.6em;
  font-weight: 400;
  font-family: $font-body;
  line-height: 1.75;
  color: $color-quaternary;
}

::-moz-selection {
  background: $selection;
  text-shadow: none;
}

::selection {
  background: $selection;
  text-shadow: none;
}

::placeholder {
  color: $color-quaternary;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $color-quaternary;
}

::-ms-input-placeholder {
  color: $color-quaternary;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $color-quaternary;
  margin: 15px 0;
  padding: 0;
}

audio,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

a,
input[type="submit"],
a span,
.button {
  transition: 300ms;
  border-radius: 0;
}


p {
  margin: 0 0 25px;
}

ul,
ol {
  padding-left: 18px;
  margin: 0 0 25px;
}

li {
  margin-bottom: 25px;
}

a,
ul,
li {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
  height: auto;
  aspect-ratio: auto;
}


strong {
  font-weight: 700;
}

blockquote {
  position: relative;
  margin: 60px 0;
  padding: 30px 50px;
  border-top: 1px solid $color-secondary;
  border-bottom: 1px solid $color-secondary;
  color: $color-secondary;

  @include media-breakpoint-down(xs) {
    padding: 30px;
  }

  p,li {
    line-height: 1.25;
    font-size: 2.4rem;
    font-weight: 500;
    color: $color-secondary;

    @include media-breakpoint-down(xs) {
      font-size: 2rem;
    }
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:before {
    content: '\201C';
    position: absolute;
    top: 30px;
    left: 15px;
    font-size: 4rem;
    line-height: 1;

    @include media-breakpoint-down(xs) {
      left: 5px;
    }
  }

  &:after {
    content: '\201D';
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 4rem;
    line-height: 1;

    @include media-breakpoint-down(xs) {
      right: 5px;
    }
  }
}
