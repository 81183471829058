.document-select {
    margin-bottom: 25px;

    .dropdown {
        border-radius: 10px;
        border: 1px solid $color-quaternary;

        &__trigger {
            padding-left: 20px;
            border: none;
        }

        &__copy {
            padding: 20px 40px;
        }
    }

}
