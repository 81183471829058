.logo-content {

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    &__copy {
        padding-top: 70px;

        @include media-breakpoint-down(sm) {
            padding-top: 45px;
        }
    }

    &__logo {
        @include media-breakpoint-down(sm) {
            text-align: center;
            margin-bottom: 20px;
        }
    }

  &__buttons {
    display: flex;
    gap: 1rem;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    @media only screen and (max-width: 407px) {
      flex-direction: column;
    }
  }
}
