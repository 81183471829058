.sidebar-cta {
    max-width: 220px;
    margin-left: auto;
    text-align: center;

    @include media-breakpoint-down(sm) {
        margin: 0 auto;
    }

    &__heading {
        margin-bottom: 15px;
    }

    &__icon {
        margin-bottom: 10px;
    }

    &__links {
        text-align: left;

        li {
            margin-bottom: 15px;
        }
    }

    &__highlighted {
        padding: 10px 0;
        margin: 0;
        border-top: 1px solid $color-quaternary;
        border-bottom: 1px solid $color-quaternary;
    }

    &__link {
        font-weight: bold;
        margin-bottom: 10px;

        a {
            color: $color-secondary;

            &:hover, &:focus {
                color: $color-primary;
            }
        }
    }
}
