@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.dropdown {
  &--large {
    padding: 2.8rem 0;
  }

  &.active {
    .dropdown__trigger {
      &:after {
        background: url(../images/close-button-solid.svg) center no-repeat;
        background-size: contain;
        transform: rotate(0) translateY(-50%) translateX(0);
      }

      &--white {
        &:after {
          background: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="%23F8AB00"/><path d="M9 16H23" stroke="%2313243A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
            center no-repeat;
        }
      }

      &--large {
        &::after {
                    background: url(https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/02/close-button-solid-large.svg) center no-repeat;
        }
      }
    }
  }

  &__trigger {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    padding: 10px 40px 10px 0;

    cursor: pointer;

    @media (min-width: map-get($sizes, lg)) {
      flex-wrap: wrap;
    }

    * {
      margin: 0;
    }

    &:after {
            content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      width: 24px;
      height: 24px;
      margin-left: 10px;
      vertical-align: middle;
      background: url(../images/arrow-solid.svg) center no-repeat;
      background-size: contain;
      transform: translateY(-50%) rotate(90deg);
      transition: 0.2s;
    }

    &--large {
      &::after {
        right: -2rem !important;
        height: 4rem;
        width: 4rem;
                background: url(https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/02/arrow-solid-large.svg) center no-repeat;
        @media (min-width: map-get($sizes, lg)) {
          right: 0;
        }
      }
    }

  }

  &__content {
    display: none;
  }

  &__content-copy {
    margin: 0 auto 2.2rem;
    max-width: 72rem;
    font-size: 2rem;
    font-weight: 500;
  }

  &__heading {
    font-size: 1.6rem;

    &--large {
      display: flex;
      flex-direction: column;
      margin-top: 2.8rem;
      font-size: 3.2rem;
      @media (min-width: map-get($sizes, lg)) {
        margin-top: 0;
      }

    }
  }

  &__heading-superscript {
    font-size: 1.6rem;
  }

  &__heading-image {
    display: none;
    @media (min-width: map-get($sizes, lg)) {
      display: block;
    }

  }
}
