.application-steps {
  padding: 50px 0 0;

  @include media-breakpoint-down(sm) {
    padding: 30px 0 0;
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
    padding: 0 1rem;

    @include media-breakpoint-down(lg) {
      max-width: 1060px;
    }

    @include media-breakpoint-down(md) {
      max-width: 800px;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(xs) {
      margin: 0 -15px;
    }

    &--isajisa {
      @include media-breakpoint-down(xs) {
        margin: 0 0;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    color: $color-primary;

    &.active {
      .application-steps__number {
        border-color: $color-tertiary;
        background: $color-tertiary;
        color: $white;

        @include media-breakpoint-down(md) {
          margin-right: 5px;
        }
      }

      .application-steps__text {
        font-weight: bold;
        @include media-breakpoint-down(md) {
          display: block;
        }

        &--isajisa {
          @include media-breakpoint-down(lg) {
            display: block;
          }
        }
      }
    }
  }

  .application-steps__number {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 0;
    margin-right: 8px;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    border: 1px solid $color-primary;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }

    @include media-breakpoint-down(xs) {
      width: 20px;
      height: 20px;
      font-size: 1rem;
    }
  }

  .step-line {
    flex: 1 0;
    height: 1px;
    margin: 0 20px;
    background: $color-primary;

    @include media-breakpoint-down(md) {
      margin: 0 5px;
    }
  }
  .step-line-isajisa {
    margin: 0 0.8rem;
    background: $color-primary;
    flex: 1 0;
    height: 0.1rem;

    @include media-breakpoint-down(md) {
      margin: 0 5px;
    }

    @include media-breakpoint-down(md) {
      margin: 0 5px;
    }
  }

  &__text {
    @include media-breakpoint-down(md) {
      display: none;
    }

    &--isajisa {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.2rem;
    }
  }
}
