.file-box {
  padding: 50px 60px;
  margin-bottom: 50px;
  border-radius: 10px;
  background: $white;

  @include media-breakpoint-down(sm) {
    padding: 30px 25px;
  }

  &__items {
    border-bottom: 1px solid $color-quaternary;
  }

  &__input {
    padding-top: 55px;

    @include media-breakpoint-down(sm) {
      height: auto !important;
      padding-top: 30px;
    }

    &-checkbox {
      &:before {
        border: 1px solid $grey !important;
        background: rgba($color-secondary, 0.25) !important;
      }
    }
  }

  .downloads__list {
    justify-content: flex-start;
    padding: 0;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin: 0 -15px;
    }

    li {
      flex: 0 0 50%;
      max-width: 50%;

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  input {
    + label {
      &:before {
        border: 1px solid $grey;
        background: rgba($color-secondary, 0.25);
      }
    }

    &:checked {
      + label {
        &:before {
          border-color: $color-quaternary;
        }
      }
    }
  }

  .row {
    .btn-wrap {
      margin-bottom: 0;
    }
  }
}
