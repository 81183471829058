.page-banner {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;

    &.block-space--no-bottom
    {
        padding-bottom:0px;
    }

    &.block-space--no-top
    {
        padding-top:0px;
    }

    &.space-between
    {
        > .page-banner-message {
            justify-content: space-between;
        }
    }

    > .page-banner-message {
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-content:space-between;
        width: 100%;
        margin: 0 auto;
        padding: 16px 0; 
        align-items: center;
        
        .right-logo {
            display: flex;
            align-items: center;
            &__image-wrapper {
                text-align: center;
            }
        }

        .left-logo,.right-logo
        {
            font-size: 18px;
            font-weight:bold;
            letter-spacing: 0px;
            color: #13243A;
        }

        @include media-breakpoint-down(sm) {
            padding: 0px;
          }

          &--mobile-row {
            @include media-breakpoint-down(xs) {
                .left-logo, .right-logo {
                    font-size: 12px;
                    text-align: center;
                }
            }
            @include media-breakpoint-down(md) {
                .right-logo {
                    flex-direction: column;
                    padding: 20px 0;
                    .page-banner-logo {
                        margin-top: -13px;
                        padding: 0;
                        max-height: 59px;
                    }
                }
                .left-logo {
                    min-width: 54%;
                    .page-banner-logo {
                        padding-right: 0;
                    }
                }
            }
    
        }
    }

    
        
    > .page-banner-message p {
        font-size: 2rem;
        font-weight: 400;
        text-align: center;
    }
    
    > .page-banner-message img {
        max-height: 120px;
        padding: 0 20px;
    }
    
    .page-banner-content > .page-banner-title h2{
        margin: 0%;
    }

    > .page-banner-message .page-banner__link-wrapper{
        text-align: center;
    }   
    
@media only screen and (max-width: 767px) {

    .imgListWrapper {
        margin: 0;
    }
    > .page-banner-message:not(.page-banner-message--mobile-row) {
        flex-direction: column;
        
        .left-logo,.right-logo
        {
            display:flex;
            flex-direction:column;
            align-items:center;
        }
    }



    > .page-banner-message .page-banner-content {
        margin-bottom: 2em;
    }
 

  .page-banner-message img {
        max-height: 100px;
    }

    .page-banner-message img.hidden-xs{
        display: none;
    }

    .img-alignment {
        width: 50%;
    }
}

}
