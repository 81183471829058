@import "../../node_modules/@shepherdsfriendlysociety/theme/dist/src/scss/01-settings/settings.breakpoints";

.c-product-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;

  /* This applies from 768px */
  @media (min-width: map-get($sizes, lg)) {
    flex-direction: row;
  }

  .btn-wrap {
    margin-top: auto;
    margin-bottom: 20px;
  }
}
